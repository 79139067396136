// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Case from "../../bs-case/src/case.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as AvoModel from "./avoModel.mjs";
import * as AvoLimits from "./AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as NameUtils from "../../shared/utils/NameUtils.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as AppFeatureFlag from "./AppFeatureFlag.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import LodashDebounce from "lodash.debounce";
import * as DiscrepancyUtils from "./DiscrepancyUtils.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as BeltListExtensions from "./BeltListExtensions.mjs";
import * as ValidationConfigV2 from "./guardrails/ValidationConfigV2.mjs";
import * as EventNameComponentCollection from "./EventNameComponentCollection.mjs";

var emptyDiscrepanciesData_discrepancies = [];

var emptyDiscrepanciesData = {
  discrepancies: emptyDiscrepanciesData_discrepancies,
  config: ValidationConfigV2.empty,
  warningCount: 0,
  errorCount: 0,
  expectedEventCase: "None",
  expectedPropertyCase: "None",
  eventComponentsWithNames: undefined
};

var context = React.createContext(emptyDiscrepanciesData);

function DiscrepancyContext$Provider(Props) {
  var value = Props.value;
  var children = Props.children;
  var provider = context.Provider;
  return React.createElement(provider, {
              value: value,
              children: children
            });
}

var Provider = {
  make: DiscrepancyContext$Provider
};

var debounceDiscrepencyCheck = LodashDebounce((function (fn, param) {
        return Curry._3(fn, param[0], param[1], param[2]);
      }), 5000, {
      leading: false,
      trailing: true
    });

function getDiscrepancies(hasGuardrailsForAll, eventComponentsWithNames, config, model) {
  var sendAsToString = function (sendAs) {
    if (typeof sendAs === "number") {
      switch (sendAs) {
        case /* SystemProperty */0 :
            return "system";
        case /* EventProperty */1 :
            return "event";
        case /* UserProperty */2 :
            return "user";
        
      }
    } else {
      var groupName = Belt_Option.getWithDefault(ModelUtils.getGroupTypeName(sendAs._0, model), "Untitled");
      return groupName + " group";
    }
  };
  var propertyConflicts = Belt_List.keepMapU(model.events, (function ($$event) {
          var __x = AvoModel.resolveProperties(model, $$event.properties);
          var hasPropertyConflicts = Belt_List.someU(BeltListExtensions.group((function (a, b) {
                      var a$1 = sendAsToString(a.sendAs) + a.name;
                      var b$1 = sendAsToString(b.sendAs) + b.name;
                      return Caml.caml_string_compare(a$1, b$1);
                    }), __x), (function (props) {
                  return Belt_List.length(props) > 1;
                }));
          if (hasPropertyConflicts) {
            return $$event;
          }
          
        }));
  var match = ValidationConfigV2.EventsV2.getCase(config.events);
  var expectedEventCase;
  if (typeof match === "object") {
    var $$case = match.VAL;
    expectedEventCase = $$case === "None" ? NameUtils.getCommonCase(ModelUtils.getEventNames(model.events)) : $$case;
  } else {
    expectedEventCase = Curry._1(ValidationConfigV2.Value.getCase, ValidationConfigV2.EventsV2.getSimplestNameCasing(config.events).value);
  }
  var eventCaseWarnings = Belt_List.keepMapU(model.events, (function ($$event) {
          var notExpectedCase = $$event.name !== Case.toWithSeperators(expectedEventCase, $$event.name) && Case.of_($$event.name) !== expectedEventCase;
          if (notExpectedCase) {
            return $$event;
          }
          
        }));
  var eventsWithoutExpectedValuesWarnings = hasGuardrailsForAll && ValidationConfigV2.EventsV2.hasValueChecking(config.events) ? Belt_List.keepMapU(model.events, (function ($$event) {
            var unexpectedValues = DiscrepancyUtils.getUnexpectedValues(eventComponentsWithNames, ValidationConfigV2.EventsV2.getNameComponents(config.events), $$event.name);
            if (unexpectedValues.blocked.length !== 0 || unexpectedValues.predefined.length !== 0) {
              return [
                      $$event,
                      unexpectedValues
                    ];
            }
            
          })) : /* [] */0;
  var match$1 = config.properties.case.value;
  var expectedPropertyCase;
  if (typeof match$1 === "object" && match$1.NAME === "case") {
    var $$case$1 = match$1.VAL;
    expectedPropertyCase = $$case$1 === "None" ? NameUtils.getCommonCase(ModelUtils.getPropertyNames(model.properties)) : $$case$1;
  } else {
    expectedPropertyCase = NameUtils.getCommonCase(ModelUtils.getPropertyNames(model.properties));
  }
  var propertyCaseWarnings = Belt_List.keepMapU(model.properties, (function (property) {
          if (property.TAG === /* PropertyRef */0) {
            return ;
          }
          var property$1 = property._0;
          var notExpectedCase = property$1.name !== Case.to_(expectedPropertyCase, property$1.name) && Case.of_(property$1.name) !== expectedPropertyCase;
          if (notExpectedCase) {
            return property$1;
          }
          
        }));
  var propertyTypeWarnings = Belt_List.keepMapU(model.properties, (function (property) {
          if (property.TAG === /* PropertyRef */0) {
            return ;
          }
          var property$1 = property._0;
          var hasAnyType = property$1.type_ === "any";
          if (hasAnyType) {
            return property$1;
          }
          
        }));
  var eventNameConflicts = Belt_List.flatten(Belt_List.keepMapU(BeltListExtensions.group((function (a, b) {
                  var a$1 = Case.camel(a.name);
                  var b$1 = Case.camel(b.name);
                  if (a$1 === b$1) {
                    return 0;
                  } else if (a$1 > b$1) {
                    return -1;
                  } else {
                    return 1;
                  }
                }), model.events), (function (events) {
              if (Belt_List.length(events) > 1) {
                return events;
              }
              
            })));
  var __x = Belt_List.keepMapU(model.properties, (function (property) {
          if (property.TAG === /* PropertyRef */0) {
            return ;
          } else {
            return property._0;
          }
        }));
  var propertyNameConflicts = Belt_List.flatten(Belt_List.keepMapU(BeltListExtensions.group((function (a, b) {
                  var a$1 = Case.camel(a.name);
                  var b$1 = Case.camel(b.name);
                  if (a$1 === b$1) {
                    return 0;
                  } else if (a$1 > b$1) {
                    return -1;
                  } else {
                    return 1;
                  }
                }), __x), (function (properties) {
              if (Belt_List.length(properties) > 1) {
                return properties;
              }
              
            })));
  var globalPropertyDescriptions = Belt_Option.mapWithDefault(model.globalRequirements, undefined, (function (param) {
          return Belt_MapString.fromArray(Belt_Array.keepMapU(Belt_List.toArray(param.properties), (function (property) {
                            if (property.TAG === /* PropertyRef */0) {
                              return ;
                            }
                            var match = property._0;
                            var match$1 = match.globalRequirementsMetadata;
                            if (match$1 !== undefined) {
                              return [
                                      match$1.fromLocalItemId,
                                      match.description
                                    ];
                            }
                            
                          })));
        }));
  var eventsWithoutDescription = Belt_List.keepU(model.events, (function ($$event) {
          if ($$event.description.trim() === "") {
            return Belt_Option.mapWithDefault(model.globalRequirements, true, (function (param) {
                          var match = Belt_List.getByU(param.events, (function (globalEvent) {
                                  return globalEvent.id === $$event.id;
                                }));
                          if (match !== undefined) {
                            return match.description.trim() === "";
                          } else {
                            return true;
                          }
                        }));
          } else {
            return false;
          }
        }));
  var propertiesWithoutDescription = Belt_List.keepMapU(model.properties, (function (property) {
          if (property.TAG === /* PropertyRef */0) {
            return ;
          }
          var property$1 = property._0;
          var tmp = false;
          if (property$1.description.trim() === "") {
            var description = Belt_MapString.get(globalPropertyDescriptions, property$1.id);
            tmp = description !== undefined ? description.trim() === "" : true;
          }
          if (tmp) {
            return property$1;
          }
          
        }));
  var discrepancies = [
    {
      NAME: "PropertyConflictOnEvent",
      VAL: [
        Curry._1(ValidationConfigV2.Rule.isChecked, config.properties.conflictsOnEvent),
        Curry._1(ValidationConfigV2.Rule.isEnforced, config.properties.conflictsOnEvent),
        propertyConflicts
      ]
    },
    {
      NAME: "SameEventName",
      VAL: [
        Curry._1(ValidationConfigV2.Rule.isChecked, config.events.uniqueName),
        Curry._1(ValidationConfigV2.Rule.isEnforced, config.events.uniqueName),
        eventNameConflicts
      ]
    },
    {
      NAME: "EventsWithUnexpectedCase",
      VAL: [
        ValidationConfigV2.EventsV2.hasConsistentNameCasing(config.events),
        ValidationConfigV2.EventsV2.hasEnforcedNameCasing(config.events),
        eventCaseWarnings
      ]
    },
    {
      NAME: "EventsWithoutExpectedValues",
      VAL: [
        (ValidationConfigV2.EventsV2.getAllowedValues(config.events).length !== 0 || ValidationConfigV2.EventsV2.getBlockedValues(config.events).length !== 0) && hasGuardrailsForAll,
        ValidationConfigV2.EventsV2.hasEnforcedNameCasing(config.events) && hasGuardrailsForAll,
        eventsWithoutExpectedValuesWarnings
      ]
    },
    {
      NAME: "SamePropertyName",
      VAL: [
        Curry._1(ValidationConfigV2.Rule.isChecked, config.properties.uniqueName),
        Curry._1(ValidationConfigV2.Rule.isEnforced, config.properties.uniqueName),
        propertyNameConflicts
      ]
    },
    {
      NAME: "PropertiesWithUnexpectedCase",
      VAL: [
        Curry._1(ValidationConfigV2.Rule.isChecked, config.properties.case),
        Curry._1(ValidationConfigV2.Rule.isEnforced, config.properties.case),
        propertyCaseWarnings
      ]
    },
    {
      NAME: "PropertiesWithAnyType",
      VAL: [
        Curry._1(ValidationConfigV2.Rule.isChecked, config.properties.anyType),
        Curry._1(ValidationConfigV2.Rule.isEnforced, config.properties.anyType),
        propertyTypeWarnings
      ]
    },
    {
      NAME: "EventsWithoutDescription",
      VAL: [
        Curry._1(ValidationConfigV2.Rule.isChecked, config.events.description),
        Curry._1(ValidationConfigV2.Rule.isEnforced, config.events.description),
        eventsWithoutDescription
      ]
    },
    {
      NAME: "PropertiesWithoutDescription",
      VAL: [
        Curry._1(ValidationConfigV2.Rule.isChecked, config.properties.description),
        Curry._1(ValidationConfigV2.Rule.isEnforced, config.properties.description),
        propertiesWithoutDescription
      ]
    }
  ];
  var match$2 = ValidationConfigV2.EventsV2.getCase(config.events);
  var hasSimpleNameCasing = typeof match$2 === "object" ? true : false;
  var hasValueChecking = ValidationConfigV2.EventsV2.hasValueChecking(config.events);
  return {
          discrepancies: discrepancies,
          config: config,
          warningCount: DiscrepancyUtils.getTotalWarningsCount(hasSimpleNameCasing, hasValueChecking, discrepancies),
          errorCount: DiscrepancyUtils.getTotalErrorsCount(hasSimpleNameCasing, hasValueChecking, discrepancies),
          expectedEventCase: expectedEventCase,
          expectedPropertyCase: expectedPropertyCase,
          eventComponentsWithNames: eventComponentsWithNames
        };
}

function checkDiscrepancies(hasGuardrailsForAll, eventComponentsWithNames, config, model, setState) {
  return Curry._1(setState, (function (param) {
                return getDiscrepancies(hasGuardrailsForAll, eventComponentsWithNames, config, model);
              }));
}

function DiscrepancyContext(Props) {
  var config = Props.config;
  var configV2 = Props.configV2;
  var model = Props.model;
  var forceAuditV1 = Props.forceAuditV1;
  var children = Props.children;
  var workspace = WorkspaceContext.use(undefined);
  var hasGuardrailsForAll = AppFeatureFlag.useFeatureFlag("GuardrailsForAll") || Curry._1(AvoLimits.Guardrails.AdvancedNamingConvention_deprecated.isAvailable, workspace);
  var match = React.useState(function () {
        return emptyDiscrepanciesData;
      });
  var setValue = match[1];
  var parsedConfig = React.useMemo((function () {
          return ValidationConfigV2.Utils.parseWithV1(config, configV2, forceAuditV1);
        }), [
        config,
        configV2,
        forceAuditV1
      ]);
  var eventComponentsWithNames = EventNameComponentCollection.useEventNameComponents(workspace.id, ValidationConfigV2.EventsV2.getNameConfigAsString(parsedConfig.events));
  React.useEffect((function () {
          var timeoutId = setTimeout((function (param) {
                  return checkDiscrepancies(hasGuardrailsForAll, eventComponentsWithNames, parsedConfig, model, setValue);
                }), 0);
          return (function (param) {
                    clearTimeout(timeoutId);
                    
                  });
        }), []);
  React.useEffect((function () {
          debounceDiscrepencyCheck((function (param, param$1, param$2) {
                  return checkDiscrepancies(hasGuardrailsForAll, eventComponentsWithNames, param, param$1, param$2);
                }), [
                parsedConfig,
                model,
                setValue
              ]);
          
        }), [
        hasGuardrailsForAll,
        eventComponentsWithNames,
        model,
        parsedConfig
      ]);
  React.useEffect((function () {
          checkDiscrepancies(hasGuardrailsForAll, eventComponentsWithNames, parsedConfig, model, setValue);
          
        }), [
        hasGuardrailsForAll,
        eventComponentsWithNames,
        model,
        config,
        configV2
      ]);
  return React.createElement(DiscrepancyContext$Provider, {
              value: match[0],
              children: children
            });
}

var make = DiscrepancyContext;

export {
  emptyDiscrepanciesData ,
  context ,
  Provider ,
  debounceDiscrepencyCheck ,
  getDiscrepancies ,
  checkDiscrepancies ,
  make ,
  
}
/* context Not a pure module */
