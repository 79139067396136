// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import QuillDelta from "quill-delta";
import * as NameDuplicator from "./NameDuplicator.mjs";
import * as BeltListExtensions from "../../app/src/BeltListExtensions.mjs";

var _map = {"EQ":"EQ","NEQ":"NEQ","LE":"LE","LEQ":"LEQ","GT":"GT","GTE":"GTE"};

function binOpToJs(param) {
  return param;
}

function binOpFromJs(param) {
  return _map[param];
}

function encodeBinOp(binOp) {
  if (binOp === "GT") {
    return "greater";
  } else if (binOp === "LE") {
    return "less";
  } else if (binOp === "GTE") {
    return "greaterOrEquals";
  } else if (binOp === "LEQ") {
    return "lessOrEquals";
  } else if (binOp === "NEQ") {
    return "notEquals";
  } else {
    return "equals";
  }
}

var _map$1 = {"Any":"any","String":"string","Int":"int","Long":"long","Float":"float","Bool":"bool","Object":"object"};

var _revMap = {"any":"Any","string":"String","int":"Int","long":"Long","float":"Float","bool":"Bool","object":"Object"};

function tToJs(param) {
  return _map$1[param];
}

function tFromJs(param) {
  return _revMap[param];
}

var Type = {
  tToJs: tToJs,
  tFromJs: tFromJs
};

function tToJs$1(param) {
  return {
          id: param.id,
          description: param.description,
          pinnedValue: param.pinnedValue
        };
}

function tFromJs$1(param) {
  return {
          id: param.id,
          description: param.description,
          pinnedValue: param.pinnedValue
        };
}

var PropertyRef = {
  tToJs: tToJs$1,
  tFromJs: tFromJs$1
};

var EventSpecificPropertyValueActions = {};

function decodeType(str) {
  switch (str) {
    case "any" :
        return "Any";
    case "bool" :
        return "Bool";
    case "float" :
        return "Float";
    case "int" :
        return "Int";
    case "long" :
        return "Long";
    case "object" :
        return "Object";
    case "string" :
        return "String";
    default:
      return Pervasives.failwith("Unknown type " + str);
  }
}

var DataType = {};

var _map$2 = {"Identify":"Identify","Revenue":"Revenue","Page":"Page","Server":"Server","NotBuiltIn":"NotBuiltIn"};

function builtInTypesToJs(param) {
  return param;
}

function builtInTypesFromJs(param) {
  return _map$2[param];
}

var _map$3 = {"Set":"Set","SetOnce":"SetOnce","Increment":"Increment","Prepend":"Prepend","Append":"Append","SnowplowContext":"SnowplowContext"};

function operationToJs(param) {
  return param;
}

function operationFromJs(param) {
  return _map$3[param];
}

var GlobalRequirementsMetadata = {};

function tToJs$2(param) {
  return {
          id: param.id,
          name: param.name,
          uniqueName: param.uniqueName,
          description: param.description,
          type_: param.type_,
          sendAs: param.sendAs,
          validations: param.validations,
          eventSpecificAllowedPropertyValues: param.eventSpecificAllowedPropertyValues,
          auto: param.auto,
          builtIn: param.builtIn,
          analyticsTools: param.analyticsTools,
          devPlatforms: param.devPlatforms,
          list: param.list,
          operation: param.operation,
          optionalDeprecated: param.optionalDeprecated,
          excludedSourcesDeprecated: param.excludedSourcesDeprecated,
          optionalWhenInObject: param.optionalWhenInObject,
          absence: param.absence,
          globalRequirementsMetadata: param.globalRequirementsMetadata
        };
}

function tFromJs$2(param) {
  return {
          id: param.id,
          name: param.name,
          uniqueName: param.uniqueName,
          description: param.description,
          type_: param.type_,
          sendAs: param.sendAs,
          validations: param.validations,
          eventSpecificAllowedPropertyValues: param.eventSpecificAllowedPropertyValues,
          auto: param.auto,
          builtIn: param.builtIn,
          analyticsTools: param.analyticsTools,
          devPlatforms: param.devPlatforms,
          list: param.list,
          operation: param.operation,
          optionalDeprecated: param.optionalDeprecated,
          excludedSourcesDeprecated: param.excludedSourcesDeprecated,
          optionalWhenInObject: param.optionalWhenInObject,
          absence: param.absence,
          globalRequirementsMetadata: param.globalRequirementsMetadata
        };
}

var Property = {
  tToJs: tToJs$2,
  tFromJs: tFromJs$2
};

function tToJs$3(param) {
  return {
          id: param.id,
          name: param.name,
          description: param.description,
          properties: param.properties,
          globalRequirementsMetadata: param.globalRequirementsMetadata
        };
}

function tFromJs$3(param) {
  return {
          id: param.id,
          name: param.name,
          description: param.description,
          properties: param.properties,
          globalRequirementsMetadata: param.globalRequirementsMetadata
        };
}

var PropertyBundle = {
  tToJs: tToJs$3,
  tFromJs: tFromJs$3
};

var PropertyBundleRef = {};

var _map$4 = {"Identify":"Identify","Unidentify":"Unidentify","Alias":"Alias","Revenue":"Revenue","Page":"Page","SkipLogEvent":"SkipLogEvent","UpdateGroups":"UpdateGroups"};

function eventTypeToJs(param) {
  return param;
}

function eventTypeFromJs(param) {
  return _map$4[param];
}

function eventTypeToCamelCase(eventType) {
  if (eventType === "Revenue") {
    return "revenue";
  } else if (eventType === "SkipLogEvent") {
    return "skipLogEvent";
  } else if (eventType === "Identify") {
    return "identify";
  } else if (eventType === "Alias") {
    return "alias";
  } else if (eventType === "Page") {
    return "page";
  } else if (eventType === "Unidentify") {
    return "unidentify";
  } else {
    return "updateGroups";
  }
}

var Trigger = {};

function makeVariantIdentifier(variant) {
  return {
          baseEventId: variant.baseEventId,
          variantId: variant.id
        };
}

var EventVariant = {
  makeVariantIdentifier: makeVariantIdentifier
};

var _map$5 = {"ExplicitIncludeSource":"ExplicitIncludeSource","SourceDestinationMigration":"SourceDestinationMigration","PropertyOptionalAndExcludedSourcesToAbsence":"PropertyOptionalAndExcludedSourcesToAbsence","SkipWebFnTagToIncludeInCodegen":"SkipWebFnTagToIncludeInCodegen","RemoveUnarchivedItemsFromArchive":"RemoveUnarchivedItemsFromArchive","EventSpecificAllowedPropertyValues":"EventSpecificAllowedPropertyValues"};

function migrationToJs(param) {
  return param;
}

function migrationFromJs(param) {
  return _map$5[param];
}

var _map$6 = {"Matches":"Matches","Min":"Min","Max":"Max"};

function constraint_ToJs(param) {
  return param;
}

function constraint_FromJs(param) {
  return _map$6[param];
}

function hasConfig(config, sourceConfig) {
  return Belt_List.some(config, (function (config) {
                return Caml_obj.caml_equal(config, sourceConfig);
              }));
}

function makeValid(source) {
  return {
          id: source.id,
          name: Belt_Option.getExn(source.name),
          language: Belt_Option.getExn(source.language),
          platform: Belt_Option.getExn(source.platform),
          destinations: source.destinations,
          filename: source.filename,
          path: source.path,
          segmentDestinationOptions: source.segmentDestinationOptions,
          config: source.config
        };
}

function makeValidWithDefaultValues(source, defaultName, defaultLanguage, defaultPlatform) {
  return {
          id: source.id,
          name: Belt_Option.getWithDefault(source.name, defaultName),
          language: Belt_Option.getWithDefault(source.language, defaultLanguage),
          platform: Belt_Option.getWithDefault(source.platform, defaultPlatform),
          destinations: source.destinations,
          filename: source.filename,
          path: source.path,
          segmentDestinationOptions: source.segmentDestinationOptions,
          config: source.config
        };
}

function makeT(source) {
  return {
          id: source.id,
          name: source.name,
          language: source.language,
          platform: source.platform,
          destinations: source.destinations,
          filename: source.filename,
          path: source.path,
          segmentDestinationOptions: source.segmentDestinationOptions,
          config: source.config
        };
}

var Source = {
  hasConfig: hasConfig,
  makeValid: makeValid,
  makeValidWithDefaultValues: makeValidWithDefaultValues,
  makeT: makeT
};

function makeValid$1(destination) {
  var dev = Belt_Option.getWithDefault(destination.apiKeys.dev, "");
  return {
          id: destination.id,
          name: Belt_Option.getExn(destination.name),
          type_: Belt_Option.getExn(destination.type_),
          apiKeys: {
            dev: dev,
            prod: Belt_Option.getWithDefault(destination.apiKeys.prod, ""),
            other: destination.apiKeys.other
          },
          includeUserPropsWithEventProps: destination.includeUserPropsWithEventProps,
          disabledByDefault: destination.disabledByDefault
        };
}

function makeT$1(destination) {
  return {
          id: destination.id,
          name: destination.name,
          type_: destination.type_,
          apiKeys: {
            dev: destination.apiKeys.dev,
            prod: destination.apiKeys.prod,
            other: destination.apiKeys.other
          },
          includeUserPropsWithEventProps: destination.includeUserPropsWithEventProps,
          disabledByDefault: destination.disabledByDefault
        };
}

var Destination = {
  makeValid: makeValid$1,
  makeT: makeT$1
};

var _map$7 = {"Taxonomy":"Taxonomy","Lexicon":"Lexicon","MParticleDataMaster":"MParticleDataMaster","Protocols":"Protocols","Webhook":"Webhook","RudderstackTrackingPlans":"RudderstackTrackingPlans","SnowplowDataStructures":"SnowplowDataStructures"};

function integrationTypeToJs(param) {
  return param;
}

function integrationTypeFromJs(param) {
  return _map$7[param];
}

function integrationTypeHumanReadableName(integrationType) {
  if (integrationType === "RudderstackTrackingPlans") {
    return "RudderStack";
  } else if (integrationType === "SnowplowDataStructures") {
    return "Snowplow";
  } else if (integrationType === "MParticleDataMaster") {
    return "mParticle";
  } else {
    return integrationType;
  }
}

var integrationTypes = [
  "Taxonomy",
  "Lexicon",
  "MParticleDataMaster",
  "Protocols",
  "Webhook",
  "RudderstackTrackingPlans",
  "SnowplowDataStructures"
];

var ProtocolsIntegration = {};

var RudderstackTrackingPlansIntegration = {};

var MParticleDataMasterIntegration = {};

var TaxonomyIntegration = {};

var LexiconIntegration = {};

var WebhookIntegration = {};

var SnowplowDataStructuresIntegration = {};

var Integration = {};

var _map$8 = {"Funnel":"Funnel","EventSegmentation":"EventSegmentation","Proportion":"Proportion","Retention":"Retention"};

function metricTypeToJs(param) {
  return param;
}

function metricTypeFromJs(param) {
  return _map$8[param];
}

var _map$9 = {"Draft":"Draft","ReadyForReview":"ReadyForReview","Approved":"Approved","ChangesRequested":"ChangesRequested"};

function branchStatusToJs(param) {
  return param;
}

function branchStatusFromJs(param) {
  return _map$9[param];
}

var _map$10 = {"user":"user","system":"system","importSideEffect":"importSideEffect"};

function issueTriggerToJs(param) {
  return param;
}

function issueTriggerFromJs(param) {
  return _map$10[param];
}

var _map$11 = {"cmdPalette":"cmdPalette","onboardingChecklist":"onboardingChecklist","sidebarSettingsCog":"sidebarSettingsCog","trackingPlanEventsEmptyState":"trackingPlanEventsEmptyState","inspectorIssuesHeader":"inspectorIssuesHeader","inspectorIssueDetails":"inspectorIssueDetails","inspectorEventsHeader":"inspectorEventsHeader","inspectorEventsTableRow":"inspectorEventsTableRow","inspectorEventsSidebar":"inspectorEventsSidebar","directLink":"directLink","unregistered":"unregistered"};

function importLocationToJs(param) {
  return param;
}

function importLocationFromJs(param) {
  return _map$11[param];
}

function collaborationItemId(collaborationItem) {
  return collaborationItem.VAL;
}

var Group = {};

function empty(metadata) {
  return {
          metadata: metadata,
          events: /* [] */0,
          properties: /* [] */0,
          propertyBundles: /* [] */0,
          goals: /* [] */0,
          groupTypes: [],
          rules: /* [] */0
        };
}

var GlobalRequirements = {
  empty: empty
};

var empty_types = {
  hd: {
    TAG: /* BuiltInType */0,
    _0: "String"
  },
  tl: {
    hd: {
      TAG: /* BuiltInType */0,
      _0: "Int"
    },
    tl: {
      hd: {
        TAG: /* BuiltInType */0,
        _0: "Float"
      },
      tl: {
        hd: {
          TAG: /* BuiltInType */0,
          _0: "Bool"
        },
        tl: /* [] */0
      }
    }
  }
};

var empty_migrations = {
  hd: "ExplicitIncludeSource",
  tl: {
    hd: "SourceDestinationMigration",
    tl: {
      hd: "PropertyOptionalAndExcludedSourcesToAbsence",
      tl: /* [] */0
    }
  }
};

var empty_groupTypes = [];

var empty_archive = {
  events: /* [] */0,
  properties: /* [] */0,
  propertyBundles: /* [] */0,
  sources: /* [] */0,
  destinations: /* [] */0,
  goals: /* [] */0,
  metrics: /* [] */0,
  groupTypes: [],
  eventVariants: []
};

var empty$1 = {
  types: empty_types,
  properties: /* [] */0,
  propertyBundles: /* [] */0,
  events: /* [] */0,
  migrations: empty_migrations,
  sources: /* [] */0,
  destinations: /* [] */0,
  groupTypes: empty_groupTypes,
  goals: /* [] */0,
  metrics: /* [] */0,
  archive: empty_archive,
  openBranches: /* [] */0,
  branchPointer: undefined,
  rules: /* [] */0,
  integrations: /* [] */0,
  globalRequirements: undefined
};

function emptyEvent(id, name) {
  return {
          id: id,
          name: name,
          uniqueName: undefined,
          description: "",
          properties: /* [] */0,
          propertyBundles: /* [] */0,
          variants: [],
          types: /* [] */0,
          tags: /* [] */0,
          excludeSourcesDeprecated: /* [] */0,
          includeSources: /* [] */0,
          includeDestinations: /* [] */0,
          hashes: /* [] */0,
          propertyWhitelist: /* [] */0,
          eventGroupTypeIdsWithArchive: [],
          userGroupTypeIdsWithArchive: [],
          triggers: [],
          globalRequirementsMetadata: undefined
        };
}

function emptyEventVariant(variantIdentifier, nameSuffix) {
  return {
          id: variantIdentifier.variantId,
          baseEventId: variantIdentifier.baseEventId,
          nameSuffix: nameSuffix,
          description: "",
          propertyOverrides: undefined,
          sourceOverrides: undefined,
          triggers: []
        };
}

function getName(e) {
  return e.name;
}

var EventNameDuplicator = NameDuplicator.Make({
      getName: getName
    });

function duplicateEvent($$event, toEventId, triggerIdTuples, events) {
  return {
          id: toEventId,
          name: Curry._2(EventNameDuplicator.duplicateNameFromList, $$event.name, events),
          uniqueName: undefined,
          description: $$event.description,
          properties: $$event.properties,
          propertyBundles: $$event.propertyBundles,
          variants: $$event.variants,
          types: $$event.types,
          tags: $$event.tags,
          excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
          includeSources: $$event.includeSources,
          includeDestinations: $$event.includeDestinations,
          hashes: $$event.hashes,
          propertyWhitelist: $$event.propertyWhitelist,
          eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
          userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
          triggers: Belt_Array.keepMap(triggerIdTuples, (function (param) {
                  var toTriggerId = param[1];
                  var fromTriggerId = param[0];
                  return Belt_Option.map(Belt_Array.getBy($$event.triggers, (function (trigger) {
                                    return trigger.id === fromTriggerId;
                                  })), (function (trigger) {
                                return {
                                        id: toTriggerId,
                                        sources: trigger.sources,
                                        description: trigger.description,
                                        content: trigger.content
                                      };
                              }));
                })),
          globalRequirementsMetadata: $$event.globalRequirementsMetadata
        };
}

function emptyPropertyRef(id) {
  return {
          id: id,
          description: "",
          pinnedValue: undefined
        };
}

function emptyProperty(id, name, sendAs, type_) {
  return {
          id: id,
          name: name,
          uniqueName: undefined,
          description: "",
          type_: type_,
          sendAs: sendAs,
          validations: /* [] */0,
          eventSpecificAllowedPropertyValues: [],
          auto: false,
          builtIn: "NotBuiltIn",
          analyticsTools: /* [] */0,
          devPlatforms: /* [] */0,
          list: false,
          operation: "Set",
          optionalDeprecated: false,
          excludedSourcesDeprecated: /* [] */0,
          optionalWhenInObject: false,
          absence: /* AlwaysSent */0,
          globalRequirementsMetadata: undefined
        };
}

function emptySource(nameOpt, id, filename, path) {
  var name = nameOpt !== undefined ? Caml_option.valFromOption(nameOpt) : undefined;
  return {
          id: id,
          name: name,
          language: undefined,
          platform: undefined,
          destinations: /* [] */0,
          filename: filename,
          path: path,
          segmentDestinationOptions: /* [] */0,
          config: /* [] */0
        };
}

function emptyValidSource(id, name) {
  return {
          id: id,
          name: name,
          language: "js_v2",
          platform: "Web",
          destinations: /* [] */0,
          filename: "Avo.js",
          path: "./",
          segmentDestinationOptions: /* [] */0,
          config: /* [] */0
        };
}

function emptyDestination(id) {
  return {
          id: id,
          name: undefined,
          type_: undefined,
          apiKeys: {
            dev: undefined,
            prod: undefined,
            other: /* [] */0
          },
          includeUserPropsWithEventProps: false,
          disabledByDefault: false
        };
}

function emptyMetric(id) {
  return {
          id: id,
          name: "",
          description: JSON.stringify(new QuillDelta()),
          type_: "Funnel",
          items: /* [] */0
        };
}

function emptyGoal(id) {
  return {
          id: id,
          name: "",
          description: JSON.stringify(new QuillDelta()),
          metrics: /* [] */0,
          events: /* [] */0
        };
}

function emptyPropertyBundle(id) {
  return {
          id: id,
          name: "",
          description: "",
          properties: /* [] */0,
          globalRequirementsMetadata: undefined
        };
}

function emptyIntegration(id) {
  return {
          id: id,
          name: undefined,
          config: undefined,
          filters: /* [] */0,
          autoPublish: true
        };
}

function emptyGroupType(id) {
  return {
          id: id,
          name: "",
          description: ""
        };
}

function emptyTrigger(descriptionOpt, sourcesOpt, contentOpt, id) {
  var description = descriptionOpt !== undefined ? descriptionOpt : JSON.stringify(new QuillDelta());
  var sources = sourcesOpt !== undefined ? sourcesOpt : /* SourceIndependent */0;
  var content = contentOpt !== undefined ? Caml_option.valFromOption(contentOpt) : undefined;
  return {
          id: id,
          sources: sources,
          description: description,
          content: content
        };
}

function compare(a, b) {
  return Caml.caml_string_compare(a.id, b.id);
}

var DedupeProperties = BeltListExtensions.Dedupe({
      compare: compare
    });

var propertyAbsenceAllEventsKey = "system";

var eventTypes = {
  hd: "Identify",
  tl: {
    hd: "Unidentify",
    tl: {
      hd: "Revenue",
      tl: {
        hd: "Page",
        tl: /* [] */0
      }
    }
  }
};

var emptyValidDestination = {
  id: "__empty-valid-destination__",
  name: "__empty-valid-destination__",
  type_: "Amplitude",
  apiKeys: {
    dev: "",
    prod: "",
    other: /* [] */0
  },
  includeUserPropsWithEventProps: false,
  disabledByDefault: false
};

export {
  binOpToJs ,
  binOpFromJs ,
  encodeBinOp ,
  Type ,
  PropertyRef ,
  EventSpecificPropertyValueActions ,
  decodeType ,
  DataType ,
  builtInTypesToJs ,
  builtInTypesFromJs ,
  operationToJs ,
  operationFromJs ,
  propertyAbsenceAllEventsKey ,
  GlobalRequirementsMetadata ,
  Property ,
  PropertyBundle ,
  PropertyBundleRef ,
  eventTypeToJs ,
  eventTypeFromJs ,
  eventTypeToCamelCase ,
  eventTypes ,
  Trigger ,
  EventVariant ,
  migrationToJs ,
  migrationFromJs ,
  constraint_ToJs ,
  constraint_FromJs ,
  Source ,
  Destination ,
  integrationTypeToJs ,
  integrationTypeFromJs ,
  integrationTypeHumanReadableName ,
  integrationTypes ,
  ProtocolsIntegration ,
  RudderstackTrackingPlansIntegration ,
  MParticleDataMasterIntegration ,
  TaxonomyIntegration ,
  LexiconIntegration ,
  WebhookIntegration ,
  SnowplowDataStructuresIntegration ,
  Integration ,
  metricTypeToJs ,
  metricTypeFromJs ,
  branchStatusToJs ,
  branchStatusFromJs ,
  issueTriggerToJs ,
  issueTriggerFromJs ,
  importLocationToJs ,
  importLocationFromJs ,
  collaborationItemId ,
  Group ,
  GlobalRequirements ,
  empty$1 as empty,
  emptyEvent ,
  emptyEventVariant ,
  EventNameDuplicator ,
  duplicateEvent ,
  emptyPropertyRef ,
  emptyProperty ,
  emptySource ,
  emptyValidSource ,
  emptyDestination ,
  emptyValidDestination ,
  emptyMetric ,
  emptyGoal ,
  emptyPropertyBundle ,
  emptyIntegration ,
  emptyGroupType ,
  emptyTrigger ,
  DedupeProperties ,
  
}
/* EventNameDuplicator Not a pure module */
