// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Caml from "rescript/lib/es6/caml.js";
import * as Case from "../../../bs-case/src/case.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Button from "../Button.mjs";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as TextButton from "../TextButton.mjs";
import * as AnalyticsRe from "../analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as SchemaBundleContext from "../SchemaBundleContext.mjs";
import * as AvoEngagement__Types from "../avoEngagement/AvoEngagement__Types.mjs";
import * as AvoEngagement__RichText from "../avoEngagement/AvoEngagement__RichText.mjs";
import * as AvoProductTour__StepProvider from "./AvoProductTour__StepProvider.mjs";
import * as AvoProductTour__TourProvider from "./AvoProductTour__TourProvider.mjs";

function extractField(block, fieldId) {
  return Belt_Option.mapWithDefault(Curry._2(AvoEngagement__Types.Fields.get, block.fields, fieldId), null, (function (param) {
                var value = param.value;
                if (value.NAME === "richText") {
                  return React.createElement(AvoEngagement__RichText.make, {
                              content: value.VAL
                            });
                } else {
                  return value.VAL;
                }
              }));
}

function getBodyFields(block) {
  return Belt_SortArray.stableSortBy(Curry._1(AvoEngagement__Types.Fields.toArray, Curry._2(AvoEngagement__Types.Fields.keep, block.fields, (function (field) {
                        return field.id.startsWith("body");
                      }))), (function (param, param$1) {
                return Caml.caml_string_compare(param.id, param$1.id);
              }));
}

function extractBodyFields(block) {
  return Belt_Array.map(getBodyFields(block), (function (field) {
                var match = field.value;
                if (match.NAME === "richText") {
                  return React.createElement(AvoEngagement__RichText.make, {
                              content: match.VAL
                            });
                } else {
                  return match.VAL;
                }
              }));
}

function AvoProductTour__GenericStep(Props) {
  var id = Props.id;
  var withExitOpt = Props.withExit;
  var withExit = withExitOpt !== undefined ? withExitOpt : true;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var match = AvoProductTour__StepProvider.use(undefined);
  var transition = match.transition;
  var block = match.block;
  var match$1 = AvoProductTour__TourProvider.use(undefined);
  var totalSteps = match$1.totalSteps;
  var intent = match$1.intent;
  var engagementId = match$1.engagementId;
  var version = match$1.engagement.version;
  var stepNumber = Belt_Option.getWithDefault(Curry._1(match$1.getStepNumber, block.id), 0);
  var tmp = {
    className: Curry._1(Css.style, {
          hd: Css.padding(Css.px(32)),
          tl: {
            hd: Css.paddingRight(Css.px(24)),
            tl: {
              hd: Css.paddingBottom(Css.px(24)),
              tl: {
                hd: Css.maxWidth(Css.px(320)),
                tl: /* [] */0
              }
            }
          }
        })
  };
  if (id !== undefined) {
    tmp.id = Caml_option.valFromOption(id);
  }
  return React.createElement("div", tmp, React.createElement($$Text.make, {
                  element: "Span",
                  size: "Large",
                  weight: "Semi",
                  children: extractField(block, "title")
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.marginRight(Css.px(4)),
                        tl: /* [] */0
                      })
                }), Belt_Array.mapWithIndex(extractBodyFields(block), (function (index, bodyStr) {
                    return React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                                    element: "Span",
                                    size: "Medium",
                                    children: bodyStr,
                                    key: String(index)
                                  }), React.createElement(Spacer.make, {
                                    height: 16
                                  }));
                  })), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.justifyContent("spaceBetween"),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: {
                                hd: Css.gap(Css.px(8)),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, match$1.offerReplayAtEnd && totalSteps === stepNumber ? React.createElement(TextButton.make, {
                            onClick: (function (param) {
                                return Curry._1(intent.start, undefined);
                              }),
                            children: React.createElement($$Text.make, {
                                  size: "Small",
                                  color: Styles.Color.light08,
                                  children: "Replay"
                                })
                          }) : (
                        match$1.showStepCount ? React.createElement($$Text.make, {
                                size: "Small",
                                color: Styles.Color.light08,
                                children: String(stepNumber) + "/" + String(totalSteps)
                              }) : null
                      ), withExit ? React.createElement(TextButton.make, {
                            onClick: (function (param) {
                                Curry._1(intent.exit, undefined);
                                return AnalyticsRe.productFlowExited(schemaBundle, AnalyticsRe.Bundle.productFlows(Case.title(engagementId), engagementId, String(version)), block.id, stepNumber, schemaBundle.schemaId);
                              }),
                            children: React.createElement($$Text.make, {
                                  size: "Small",
                                  weight: "Regular",
                                  children: "Exit Tour"
                                })
                          }) : null), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.gap(Css.px(4)),
                              tl: /* [] */0
                            }
                          })
                    }, Belt_Array.map(match.transitions, (function (param) {
                            var key = param.key;
                            var tmp = {
                              onClick: (function (param) {
                                  return Curry._1(transition, key);
                                }),
                              style: key.startsWith("prev") ? "ghost" : "primary",
                              key: key
                            };
                            if (param.icon !== undefined) {
                              tmp.icon = Caml_option.valFromOption(param.icon);
                            }
                            if (param.label !== undefined) {
                              tmp.label = param.label;
                            }
                            return React.createElement(Button.make, tmp);
                          })))));
}

var make = AvoProductTour__GenericStep;

export {
  extractField ,
  getBodyFields ,
  extractBodyFields ,
  make ,
  
}
/* Css Not a pure module */
