// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hashcode from "hashcode";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_int32 from "rescript/lib/es6/caml_int32.js";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import Tinycolor2 from "tinycolor2";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";

function toString(color) {
  if (typeof color !== "object") {
    if (color === "transparent") {
      return "transparent";
    } else {
      return "currentColor";
    }
  }
  var variant = color.NAME;
  if (variant === "rgba") {
    var match = color.VAL;
    return "rgba(" + String(match[0]) + ", " + String(match[1]) + ", " + String(match[2]) + ", " + String(match[3].VAL) + ")";
  }
  if (variant === "hex") {
    return "#" + color.VAL;
  }
  if (variant !== "rgb") {
    return Pervasives.failwith("Oops, fix hsl or hsla");
  }
  var match$1 = color.VAL;
  return "rgb(" + String(match$1[0]) + ", " + String(match$1[1]) + ", " + String(match$1[2]) + ")";
}

function setAlpha(color, alpha) {
  var tinyRgba = Tinycolor2(toString(color)).setAlpha(alpha).toRgb();
  return Css.rgba(tinyRgba.r, tinyRgba.g, tinyRgba.b, {
              NAME: "num",
              VAL: tinyRgba.a
            });
}

function lighten(color, steps) {
  var tinyRgba = Tinycolor2(toString(color)).lighten(steps).toRgb();
  return Css.rgba(tinyRgba.r, tinyRgba.g, tinyRgba.b, {
              NAME: "num",
              VAL: tinyRgba.a
            });
}

function darken(color, steps) {
  var tinyRgba = Tinycolor2(toString(color)).darken(steps).toRgb();
  return Css.rgba(tinyRgba.r, tinyRgba.g, tinyRgba.b, {
              NAME: "num",
              VAL: tinyRgba.a
            });
}

var white = Css.hex("FFFFFF");

var avoPink = Css.hex("FF0EB4");

var avoPinkShade1 = Css.hex("D10B93");

var avoPinkShade2 = Css.hex("A30873");

var avoPinkShade3 = Css.hex("740652");

var avoPinkShade4 = Css.hex("A30873");

var primaryPurple = Css.hex("580FF5");

var primaryPurpleShade1 = Css.hex("470CC7");

var primaryPurpleShade2 = Css.hex("370999");

var primaryPurpleShade3 = Css.hex("26076B");

var primaryPurpleShade4 = Css.hex("16043D");

var lightGreen = Css.hex("E6FFD3");

var paleGreen = Css.hex("D0FFAB");

var green = Css.hex("5EDE66");

var darkGreen = Css.hex("328552");

var deepGreen = Css.hex("123B2C");

var lightBlue = Css.hex("DCFCFF");

var paleBlue = Css.hex("B1F0F6");

var blue = Css.hex("84DAFF");

var darkBlue = Css.hex("2F70C6");

var deepBlue = Css.hex("272F61");

var lightPurple = Css.hex("F2EEFF");

var palePurple = Css.hex("D4C7FF");

var purple = Css.hex("B6A1FD");

var darkPurple = Css.hex("7B60D3");

var deepPurple = Css.hex("3C2242");

var lightPink = Css.hex("FFEDF7");

var palePink = Css.hex("FFCBE8");

var pink = Css.hex("FFA7D8");

var darkPink = Css.hex("C3468D");

var deepPink = Css.hex("531215");

var lightRed = Css.hex("FFECEB");

var paleRed = Css.hex("FFBAB7");

var red = Css.hex("FC7F78");

var darkRed = Css.hex("DE342B");

var deepRed = Css.hex("610500");

var lightOrange = Css.hex("FFF3C9");

var paleOrange = Css.hex("FEE486");

var orange = Css.hex("FEBC40");

var darkOrange = Css.hex("AF5F04");

var deepOrange = Css.hex("3D3304");

var light01 = Css.hex("FDFCFD");

var light02 = Css.hex("FAF9FB");

var light03 = Css.hex("F3F1F5");

var light04 = Css.hex("ECEAEF");

var light05 = Css.hex("E6E3E9");

var light06 = Css.hex("DFDCE3");

var light07 = Css.hex("D5D3DB");

var light08 = Css.hex("BCBAC7");

var light09 = Css.hex("8E8C99");

var light10 = Css.hex("817F8B");

var light11 = Css.hex("65636D");

var light12 = Css.hex("211F26");

var dark01 = Css.hex("191719");

var dark02 = Css.hex("1E1A1E");

var dark03 = Css.hex("2B272C");

var dark04 = Css.hex("332F35");

var dark05 = Css.hex("3A363C");

var dark06 = Css.hex("423E45");

var dark07 = Css.hex("4D4951");

var dark08 = Css.hex("625F69");

var dark09 = Css.hex("6F6D78");

var dark10 = Css.hex("82808B");

var dark11 = Css.hex("B1AFB8");

var dark12 = Css.hex("EEEEF0");

function getHueFromString(string) {
  var colors = [
    "pink",
    "blue",
    "purple",
    "green",
    "green",
    "orange",
    "red"
  ];
  return Belt_Array.getExn(colors, Caml_int32.mod_(Math.abs(Hashcode.hashCode().value(string)), colors.length));
}

var secondaryColors = [
  palePink,
  paleBlue,
  palePurple,
  paleGreen,
  paleGreen,
  paleOrange,
  paleRed
];

function getSecondaryColor(hue, shade) {
  if (hue === "orange") {
    if (shade === "deep") {
      return deepOrange;
    } else if (shade === "pale") {
      return paleOrange;
    } else if (shade === "light") {
      return lightOrange;
    } else if (shade === "normal") {
      return orange;
    } else {
      return darkOrange;
    }
  } else if (hue === "pink") {
    if (shade === "deep") {
      return deepPink;
    } else if (shade === "pale") {
      return palePink;
    } else if (shade === "light") {
      return lightPink;
    } else if (shade === "normal") {
      return pink;
    } else {
      return darkPink;
    }
  } else if (hue === "purple") {
    if (shade === "deep") {
      return deepPurple;
    } else if (shade === "pale") {
      return palePurple;
    } else if (shade === "light") {
      return lightPurple;
    } else if (shade === "normal") {
      return purple;
    } else {
      return darkPurple;
    }
  } else if (hue === "red") {
    if (shade === "deep") {
      return deepRed;
    } else if (shade === "pale") {
      return paleRed;
    } else if (shade === "light") {
      return lightRed;
    } else if (shade === "normal") {
      return red;
    } else {
      return darkRed;
    }
  } else if (hue === "green") {
    if (shade === "deep") {
      return deepGreen;
    } else if (shade === "pale") {
      return paleGreen;
    } else if (shade === "light") {
      return lightGreen;
    } else if (shade === "normal") {
      return green;
    } else {
      return darkGreen;
    }
  } else if (shade === "deep") {
    return deepBlue;
  } else if (shade === "pale") {
    return paleBlue;
  } else if (shade === "light") {
    return lightBlue;
  } else if (shade === "normal") {
    return blue;
  } else {
    return darkBlue;
  }
}

var Color = {
  toString: toString,
  setAlpha: setAlpha,
  lighten: lighten,
  darken: darken,
  white: white,
  avoPink: avoPink,
  avoPinkShade1: avoPinkShade1,
  avoPinkShade2: avoPinkShade2,
  avoPinkShade3: avoPinkShade3,
  avoPinkShade4: avoPinkShade4,
  primaryPurple: primaryPurple,
  primaryPurpleShade1: primaryPurpleShade1,
  primaryPurpleShade2: primaryPurpleShade2,
  primaryPurpleShade3: primaryPurpleShade3,
  primaryPurpleShade4: primaryPurpleShade4,
  lightGreen: lightGreen,
  paleGreen: paleGreen,
  green: green,
  darkGreen: darkGreen,
  deepGreen: deepGreen,
  lightBlue: lightBlue,
  paleBlue: paleBlue,
  blue: blue,
  darkBlue: darkBlue,
  deepBlue: deepBlue,
  lightPurple: lightPurple,
  palePurple: palePurple,
  purple: purple,
  darkPurple: darkPurple,
  deepPurple: deepPurple,
  lightPink: lightPink,
  palePink: palePink,
  pink: pink,
  darkPink: darkPink,
  deepPink: deepPink,
  lightRed: lightRed,
  paleRed: paleRed,
  red: red,
  darkRed: darkRed,
  deepRed: deepRed,
  lightOrange: lightOrange,
  paleOrange: paleOrange,
  orange: orange,
  darkOrange: darkOrange,
  deepOrange: deepOrange,
  light01: light01,
  light02: light02,
  light03: light03,
  light04: light04,
  light05: light05,
  light06: light06,
  light07: light07,
  light08: light08,
  light09: light09,
  light10: light10,
  light11: light11,
  light12: light12,
  dark01: dark01,
  dark02: dark02,
  dark03: dark03,
  dark04: dark04,
  dark05: dark05,
  dark06: dark06,
  dark07: dark07,
  dark08: dark08,
  dark09: dark09,
  dark10: dark10,
  dark11: dark11,
  dark12: dark12,
  getHueFromString: getHueFromString,
  secondaryColors: secondaryColors,
  getSecondaryColor: getSecondaryColor
};

var FontFamily_inter = {
  NAME: "custom",
  VAL: "Inter, Helvetica, Arial, sans-serif"
};

var FontFamily_acid = {
  NAME: "custom",
  VAL: "'Acid Grotesk', sans-serif"
};

var FontFamily_polar = {
  NAME: "custom",
  VAL: "'Polar SemiMono', monospace"
};

var FontFamily = {
  inter: FontFamily_inter,
  acid: FontFamily_acid,
  polar: FontFamily_polar
};

var semi = {
  NAME: "num",
  VAL: 600
};

var FontWeight_regular = {
  NAME: "num",
  VAL: 400
};

var FontWeight_medium = {
  NAME: "num",
  VAL: 500
};

var FontWeight_bold = {
  NAME: "num",
  VAL: 700
};

var FontWeight = {
  regular: FontWeight_regular,
  medium: FontWeight_medium,
  semi: semi,
  bold: FontWeight_bold
};

var tiny = Css.px(10);

var small = Css.px(12);

var regular = Css.px(14);

var medium = Css.px(16);

var large = Css.px(18);

var huge = Css.px(24);

var FontSize = {
  tiny: tiny,
  small: small,
  regular: regular,
  medium: medium,
  large: large,
  huge: huge
};

var regular$1 = Css.pct(140.0);

var LineHeight = {
  regular: regular$1
};

var radius = Css.px(5);

var $$default = Css.border(Css.px(1), "solid", light02);

var Border = {
  radius: radius,
  $$default: $$default
};

var box = Css_Legacy_Core.Shadow.box(Css.px(0), Css.px(2), Css.px(10), Css.px(0), undefined, Css.rgba(0, 0, 0, {
          NAME: "num",
          VAL: 0.1
        }));

var standard = Css_Legacy_Core.Shadow.box(Css.px(0), Css.px(4), Css.px(16), Css.px(0), undefined, Css.rgba(0, 0, 0, {
          NAME: "num",
          VAL: 0.1
        }));

var standardHover = Css_Legacy_Core.Shadow.box(Css.px(0), Css.px(8), Css.px(32), Css.px(0), undefined, Css.rgba(0, 0, 0, {
          NAME: "num",
          VAL: 0.16
        }));

var subtle = Css_Legacy_Core.Shadow.box(undefined, undefined, Css.px(12), undefined, undefined, Css.rgba(0, 0, 0, {
          NAME: "num",
          VAL: 0.1
        }));

function glow(color) {
  return Css_Legacy_Core.Shadow.box(Css.px(0), Css.px(0), Css.px(16), Css.px(8), undefined, color);
}

var Shadow = {
  box: box,
  standard: standard,
  standardHover: standardHover,
  subtle: subtle,
  glow: glow
};

var ZIndex = {
  aboveAll: 1000,
  eventDetailsFullScreen: 950,
  eventTriggerModal: 980,
  sidebar: 900,
  portalMenu: 1000
};

var Duration = {
  $$long: 500.0,
  $$default: 200.0,
  $$short: 100.0
};

var input_0 = Css.width(Css.pct(100));

var input_1 = {
  hd: Css.outlineWidth(Css.px(0)),
  tl: {
    hd: Css.borderWidth(Css.px(0)),
    tl: {
      hd: Css.margin(Css.px(0)),
      tl: {
        hd: Css.padding(Css.px(0)),
        tl: {
          hd: Css.fontSize(Css.px(14)),
          tl: /* [] */0
        }
      }
    }
  }
};

var input = {
  hd: input_0,
  tl: input_1
};

var button_0 = Css.backgroundColor(Css.transparent);

var button_1 = {
  hd: Css.borderWidth(Css.px(0)),
  tl: {
    hd: Css.cursor("pointer"),
    tl: {
      hd: Css.active({
            hd: Css.outlineColor(darkBlue),
            tl: /* [] */0
          }),
      tl: {
        hd: Css.outlineStyle("none"),
        tl: /* [] */0
      }
    }
  }
};

var button = {
  hd: button_0,
  tl: button_1
};

var buttonReset_0 = Css.unsafe("appearance", "none");

var buttonReset_1 = {
  hd: Css.borderWidth(Css.px(0)),
  tl: {
    hd: Css.borderColor("transparent"),
    tl: {
      hd: Css.borderStyle("solid"),
      tl: {
        hd: Css.padding(Css.px(0)),
        tl: {
          hd: Css.cursor("pointer"),
          tl: {
            hd: Css.backgroundColor("transparent"),
            tl: {
              hd: Css.margin(Css.px(0)),
              tl: /* [] */0
            }
          }
        }
      }
    }
  }
};

var buttonReset = {
  hd: buttonReset_0,
  tl: buttonReset_1
};

var linkReset_0 = Css.textDecoration("none");

var linkReset = {
  hd: linkReset_0,
  tl: /* [] */0
};

var select_0 = Css.display("block");

var select_1 = {
  hd: Css.width(Css.pct(100)),
  tl: {
    hd: Css.borderRadius(Css.px(0)),
    tl: {
      hd: Css.backgroundColor("transparent"),
      tl: {
        hd: Css.padding2(Css.px(10), Css.px(15)),
        tl: {
          hd: Css.borderRadius(radius),
          tl: {
            hd: Css.color(light09),
            tl: {
              hd: Css.borderColor(light09),
              tl: {
                hd: Css.fontWeight(semi),
                tl: {
                  hd: Css.fontSize(small),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    }
  }
};

var select = {
  hd: select_0,
  tl: select_1
};

var shimmer_0 = Css.display("block");

var shimmer_1 = {
  hd: Css.backgroundSize({
        NAME: "size",
        VAL: [
          Css.pct(200),
          Css.px(100)
        ]
      }),
  tl: {
    hd: Css.backgroundRepeat("noRepeat"),
    tl: {
      hd: Css.backgroundImage({
            NAME: "linearGradient",
            VAL: [
              {
                NAME: "deg",
                VAL: 90.0
              },
              [
                [
                  Css.pct(0.0),
                  light02
                ],
                [
                  Css.pct(20.0),
                  light04
                ],
                [
                  Css.pct(40.0),
                  light02
                ],
                [
                  Css.pct(100.0),
                  light02
                ]
              ]
            ]
          }),
      tl: {
        hd: Css.backgroundColor(light02),
        tl: {
          hd: Css.animation({
                NAME: "ms",
                VAL: 1500.0
              }, undefined, undefined, "linear", "forwards", undefined, "infinite", Curry._1(Css.keyframes, {
                    hd: [
                      0,
                      {
                        hd: Css.backgroundPosition({
                              NAME: "hv",
                              VAL: [
                                Css.px(-468),
                                Css.px(0)
                              ]
                            }),
                        tl: /* [] */0
                      }
                    ],
                    tl: {
                      hd: [
                        100,
                        {
                          hd: Css.backgroundPosition({
                                NAME: "hv",
                                VAL: [
                                  Css.px(468),
                                  Css.px(0)
                                ]
                              }),
                          tl: /* [] */0
                        }
                      ],
                      tl: /* [] */0
                    }
                  })),
          tl: /* [] */0
        }
      }
    }
  }
};

var shimmer = {
  hd: shimmer_0,
  tl: shimmer_1
};

function backgroundColorGuarantee(c) {
  var colorString = toString(c);
  return Css.unsafe("backgroundImage", "linear-gradient(" + colorString + ", " + colorString + "), linear-gradient(white, white)");
}

function backgroundColorWithOverlayGuarantee(color, overlay) {
  var colorString = toString(color);
  var overlayString = toString(overlay);
  return Css.unsafe("backgroundImage", "linear-gradient(" + overlayString + ", " + overlayString + "), linear-gradient(" + colorString + ", " + colorString + "), linear-gradient(white, white)");
}

function maxLines(lines) {
  return {
          hd: Css.unsafe("display", "-webkit-box"),
          tl: {
            hd: Css.unsafe("WebkitLineClamp", "calc(" + String(lines) + ")"),
            tl: {
              hd: Css.unsafe("WebkitBoxOrient", "vertical"),
              tl: {
                hd: Css.overflow("hidden"),
                tl: /* [] */0
              }
            }
          }
        };
}

var emptyStyle = Css.empty(/* [] */0);

export {
  Color ,
  FontFamily ,
  FontWeight ,
  FontSize ,
  LineHeight ,
  Border ,
  Shadow ,
  ZIndex ,
  Duration ,
  input ,
  button ,
  buttonReset ,
  linkReset ,
  select ,
  shimmer ,
  backgroundColorGuarantee ,
  backgroundColorWithOverlayGuarantee ,
  maxLines ,
  emptyStyle ,
  
}
/* white Not a pure module */
