// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as AvoModel from "./avoModel.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_format from "rescript/lib/es6/caml_format.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as ModelUtils_mapped from "./ModelUtils_mapped.mjs";
import * as TrackingPlanModel from "../../model/src/TrackingPlanModel.mjs";
import * as BeltListExtensions from "./BeltListExtensions.mjs";
import * as GlobalRequirementsUtils from "../../model/src/GlobalRequirementsUtils.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";
import * as GetRegexValidationUseCase from "./regex/GetRegexValidationUseCase.mjs";
import * as UpdatePropertyRegexValidationUseCase from "./regex/UpdatePropertyRegexValidationUseCase.mjs";
import * as GetEventIdsSendingPropertyForEventSpecificValuesUseCase from "../../model/src/eventSpecificPropertyValues/GetEventIdsSendingPropertyForEventSpecificValuesUseCase.mjs";
import * as ConvertPropertyValidationMatchesToEventSpecificAllowedValues from "../../model/src/eventSpecificPropertyValues/ConvertPropertyValidationMatchesToEventSpecificAllowedValues.mjs";

function createEverythingSometimesSentMatrix(modelType, propertyId, sentWhenDescription) {
  var isSystemProp;
  isSystemProp = modelType.TAG === /* Old */0 ? Belt_Option.getWithDefault(Belt_Option.map(ModelUtils.getModelPropertyById(propertyId, modelType._0), (function (property) {
                return property.sendAs === /* SystemProperty */0;
              })), false) : Belt_Option.getWithDefault(Belt_Option.map(Curry._2(TrackingPlanMappedModel.Properties.get, modelType._0.properties, propertyId), (function (property) {
                return property.sendAs === /* SystemProperty */0;
              })), false);
  if (isSystemProp) {
    var sourceAbsences = {
      TAG: /* AllSources */0,
      _0: /* SometimesSent */{
        _0: sentWhenDescription
      }
    };
    return Belt_MapString.fromArray([[
                  TrackingPlanModel.propertyAbsenceAllEventsKey,
                  sourceAbsences
                ]]);
  }
  if (modelType.TAG === /* Old */0) {
    return Belt_MapString.fromArray(Belt_List.toArray(Belt_List.mapU(ModelUtils.eventsSendingProperty(modelType._0, undefined, propertyId), (function ($$event) {
                          var sourceAbsences = {
                            TAG: /* AllSources */0,
                            _0: /* SometimesSent */{
                              _0: sentWhenDescription
                            }
                          };
                          return [
                                  $$event.id,
                                  sourceAbsences
                                ];
                        }))));
  }
  var model = modelType._0;
  return Curry._2(TrackingPlanMappedModel.Events.mapToMap, ModelUtils_mapped.eventsSendingProperty(model.events, model.propertyBundles, undefined, propertyId), (function (_event) {
                return {
                        TAG: /* AllSources */0,
                        _0: /* SometimesSent */{
                          _0: sentWhenDescription
                        }
                      };
              }));
}

function extractMixedSourceAbsenceFromAnySourceAbsence(modelType, eventId, sourceAbsence) {
  if (sourceAbsence.TAG !== /* AllSources */0) {
    return sourceAbsence._0;
  }
  var sourceAbsence$1 = sourceAbsence._0;
  if (modelType.TAG === /* Old */0) {
    var model = modelType._0;
    var sourceIds = eventId === TrackingPlanModel.propertyAbsenceAllEventsKey ? Belt_List.map(model.sources, (function (source) {
              return source.id;
            })) : ModelUtils.getEventSourceIdsByEventId(model, eventId);
    return Belt_MapString.fromArray(Belt_List.toArray(Belt_List.mapU(sourceIds, (function (sourceId) {
                          return [
                                  sourceId,
                                  sourceAbsence$1
                                ];
                        }))));
  }
  var model$1 = modelType._0;
  var sourceIds$1 = eventId === TrackingPlanModel.propertyAbsenceAllEventsKey ? Curry._1(TrackingPlanMappedModel.Sources.keys, model$1.sources) : ModelUtils_mapped.getEventSourceIdsByEventId(model$1, eventId);
  return Belt_MapString.fromArray(Belt_Array.mapU(sourceIds$1, (function (sourceId) {
                    return [
                            sourceId,
                            sourceAbsence$1
                          ];
                  })));
}

function computeCurrentAbsenceMatrix(modelType, property) {
  var match = property.absence;
  if (match !== undefined) {
    if (typeof match === "number") {
      return ;
    } else if (match.TAG === /* SometimesSent */0) {
      return createEverythingSometimesSentMatrix(modelType, property.id, match._0);
    } else {
      return match._0;
    }
  } else {
    return Pervasives.failwith("Workspace has not been migrated to use absence");
  }
}

function updatePropertyAbsence(modelType, property, propertyAbsenceUpdateType, maybeEventSourceAbsence) {
  if (property.absence === undefined) {
    Pervasives.failwith("This method should not be called before optional->absence migration.");
  }
  var currentMatrix = computeCurrentAbsenceMatrix(modelType, property);
  var absence;
  if (typeof propertyAbsenceUpdateType === "number") {
    absence = maybeEventSourceAbsence !== undefined ? (
        maybeEventSourceAbsence ? ({
              TAG: /* SometimesSent */0,
              _0: maybeEventSourceAbsence._0
            }) : Pervasives.failwith("Invalid state: Property should never be NeverSent from all events and sources.")
      ) : /* AlwaysSent */0;
  } else {
    switch (propertyAbsenceUpdateType.TAG | 0) {
      case /* Event */0 :
          var eventId = propertyAbsenceUpdateType._0;
          if (maybeEventSourceAbsence !== undefined) {
            var newMatrix = Belt_MapString.set(currentMatrix, eventId, {
                  TAG: /* AllSources */0,
                  _0: maybeEventSourceAbsence
                });
            absence = {
              TAG: /* Mixed */1,
              _0: newMatrix
            };
          } else {
            var newMatrix$1 = Belt_MapString.remove(currentMatrix, eventId);
            absence = Belt_MapString.isEmpty(newMatrix$1) ? /* AlwaysSent */0 : ({
                  TAG: /* Mixed */1,
                  _0: newMatrix$1
                });
          }
          break;
      case /* Source */1 :
          var sourceId = propertyAbsenceUpdateType._0;
          if (maybeEventSourceAbsence !== undefined) {
            var currentMatrixWithUpdatedAbsences = Belt_MapString.mapWithKeyU(currentMatrix, (function (eventId, sourceAbsences) {
                    var eventSourceIds;
                    eventSourceIds = modelType.TAG === /* Old */0 ? ModelUtils.getEventSourceIdsByEventId(modelType._0, eventId) : Belt_List.fromArray(ModelUtils_mapped.getEventSourceIdsByEventId(modelType._0, eventId));
                    if (!Belt_List.has(eventSourceIds, sourceId, (function (prim0, prim1) {
                              return prim0 === prim1;
                            }))) {
                      return sourceAbsences;
                    }
                    var currentSourceAbsences = extractMixedSourceAbsenceFromAnySourceAbsence(modelType, eventId, sourceAbsences);
                    return {
                            TAG: /* MixedSources */1,
                            _0: Belt_MapString.set(currentSourceAbsences, sourceId, maybeEventSourceAbsence)
                          };
                  }));
            var tmp;
            if (modelType.TAG === /* Old */0) {
              tmp = ModelUtils.eventsSendingProperty(modelType._0, undefined, property.id);
            } else {
              var model = modelType._0;
              tmp = Belt_List.fromArray(Curry._1(TrackingPlanMappedModel.Events.toArray, ModelUtils_mapped.eventsSendingProperty(model.events, model.propertyBundles, undefined, property.id)));
            }
            var newEventsToAddToTheMatrix = Belt_List.keepU(tmp, (function ($$event) {
                    return !Belt_Array.someU(Belt_MapString.keysToArray(currentMatrix), (function (keyEventId) {
                                  return keyEventId === $$event.id;
                                }));
                  }));
            var newMatrix$2 = Belt_List.reduceU(newEventsToAddToTheMatrix, currentMatrixWithUpdatedAbsences, (function (newMatrixAccumulator, newEvent) {
                    return Belt_MapString.set(newMatrixAccumulator, newEvent.id, {
                                TAG: /* MixedSources */1,
                                _0: Belt_MapString.fromArray([[
                                        sourceId,
                                        maybeEventSourceAbsence
                                      ]])
                              });
                  }));
            absence = {
              TAG: /* Mixed */1,
              _0: newMatrix$2
            };
          } else {
            var newMatrix$3 = Belt_MapString.mapU(Belt_MapString.keepU(Belt_MapString.mapWithKeyU(currentMatrix, (function (eventId, sourceAbsences) {
                            var currentSourceAbsences = extractMixedSourceAbsenceFromAnySourceAbsence(modelType, eventId, sourceAbsences);
                            return Belt_MapString.remove(currentSourceAbsences, sourceId);
                          })), (function (_eventId, absence) {
                        return !Belt_MapString.isEmpty(absence);
                      })), (function (item) {
                    return {
                            TAG: /* MixedSources */1,
                            _0: item
                          };
                  }));
            absence = {
              TAG: /* Mixed */1,
              _0: newMatrix$3
            };
          }
          break;
      case /* EventSource */2 :
          var sourceId$1 = propertyAbsenceUpdateType._1;
          var eventId$1 = propertyAbsenceUpdateType._0;
          if (maybeEventSourceAbsence !== undefined) {
            var newMatrix$4 = Belt_MapString.updateU(currentMatrix, eventId$1, (function (maybeSourcesAbsence) {
                    return {
                            TAG: /* MixedSources */1,
                            _0: Belt_MapString.set(Belt_Option.mapWithDefault(maybeSourcesAbsence, undefined, (function (sourcesAbsence) {
                                        return extractMixedSourceAbsenceFromAnySourceAbsence(modelType, eventId$1, sourcesAbsence);
                                      })), sourceId$1, maybeEventSourceAbsence)
                          };
                  }));
            absence = {
              TAG: /* Mixed */1,
              _0: newMatrix$4
            };
          } else {
            var newMatrix$5 = Belt_MapString.updateU(currentMatrix, eventId$1, (function (maybeSourcesAbsence) {
                    return Belt_Option.flatMap(maybeSourcesAbsence, (function (sourcesAbsence) {
                                  var sourcesAbsence$1 = extractMixedSourceAbsenceFromAnySourceAbsence(modelType, eventId$1, sourcesAbsence);
                                  var nextSourceAbsencesMap = Belt_MapString.remove(sourcesAbsence$1, sourceId$1);
                                  if (Belt_MapString.isEmpty(nextSourceAbsencesMap)) {
                                    return ;
                                  } else {
                                    return {
                                            TAG: /* MixedSources */1,
                                            _0: nextSourceAbsencesMap
                                          };
                                  }
                                }));
                  }));
            absence = {
              TAG: /* Mixed */1,
              _0: newMatrix$5
            };
          }
          break;
      
    }
  }
  return {
          id: property.id,
          name: property.name,
          uniqueName: property.uniqueName,
          description: property.description,
          type_: property.type_,
          sendAs: property.sendAs,
          validations: property.validations,
          eventSpecificAllowedPropertyValues: property.eventSpecificAllowedPropertyValues,
          auto: property.auto,
          builtIn: property.builtIn,
          analyticsTools: property.analyticsTools,
          devPlatforms: property.devPlatforms,
          list: property.list,
          operation: property.operation,
          optionalDeprecated: property.optionalDeprecated,
          excludedSourcesDeprecated: property.excludedSourcesDeprecated,
          optionalWhenInObject: property.optionalWhenInObject,
          absence: absence,
          globalRequirementsMetadata: property.globalRequirementsMetadata
        };
}

function reduceFunction(model, action) {
  if (typeof action === "object") {
    var variant = action.NAME;
    if (variant === "UpdateDestinationDisabledByDefault") {
      var match = action.VAL;
      var disabledByDefault = match[1];
      var destinationId = match[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateDestination(model._0, destinationId, (function (destination) {
                        return {
                                id: destination.id,
                                name: destination.name,
                                type_: destination.type_,
                                apiKeys: destination.apiKeys,
                                includeUserPropsWithEventProps: destination.includeUserPropsWithEventProps,
                                disabledByDefault: disabledByDefault
                              };
                      }))
              };
      }
      var model$1 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$1.archive,
                branchPointer: model$1.branchPointer,
                migrations: model$1.migrations,
                rules: model$1.rules,
                types: model$1.types,
                categories: model$1.categories,
                destinations: Curry._3(TrackingPlanMappedModel.Destinations.updateExisting, model$1.destinations, destinationId, (function (destination) {
                        return {
                                id: destination.id,
                                name: destination.name,
                                type_: destination.type_,
                                apiKeys: destination.apiKeys,
                                includeUserPropsWithEventProps: destination.includeUserPropsWithEventProps,
                                disabledByDefault: disabledByDefault
                              };
                      })),
                events: model$1.events,
                groupTypes: model$1.groupTypes,
                integrations: model$1.integrations,
                metrics: model$1.metrics,
                openBranches: model$1.openBranches,
                properties: model$1.properties,
                propertyBundles: model$1.propertyBundles,
                sources: model$1.sources,
                globalRequirements: model$1.globalRequirements
              }
            };
    }
    if (variant === "AddProperty") {
      var match$1 = action.VAL;
      var sendAs = match$1[3];
      var propertyId = match$1[1];
      var eventId = match$1[0];
      var newProperty_name = match$1[2];
      var newProperty_eventSpecificAllowedPropertyValues = [];
      var newProperty_absence = ModelUtils.hasMigrated_boxed(model, "PropertyOptionalAndExcludedSourcesToAbsence") ? /* AlwaysSent */0 : undefined;
      var newProperty = {
        id: propertyId,
        name: newProperty_name,
        uniqueName: undefined,
        description: "",
        type_: "string",
        sendAs: sendAs,
        validations: /* [] */0,
        eventSpecificAllowedPropertyValues: newProperty_eventSpecificAllowedPropertyValues,
        auto: false,
        builtIn: "NotBuiltIn",
        analyticsTools: /* [] */0,
        devPlatforms: /* [] */0,
        list: false,
        operation: "Set",
        optionalDeprecated: false,
        excludedSourcesDeprecated: /* [] */0,
        optionalWhenInObject: false,
        absence: newProperty_absence,
        globalRequirementsMetadata: undefined
      };
      if (model.TAG === /* Old */0) {
        var model$2 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$2.types,
                  properties: Belt_List.concat(model$2.properties, {
                        hd: {
                          TAG: /* Property */1,
                          _0: newProperty
                        },
                        tl: /* [] */0
                      }),
                  propertyBundles: model$2.propertyBundles,
                  events: sendAs === /* SystemProperty */0 ? model$2.events : ModelUtils.updateEvents(model$2.events, eventId, (function ($$event) {
                            return {
                                    id: $$event.id,
                                    name: $$event.name,
                                    uniqueName: $$event.uniqueName,
                                    description: $$event.description,
                                    properties: Belt_List.concat($$event.properties, {
                                          hd: {
                                            TAG: /* PropertyRef */0,
                                            _0: {
                                              id: propertyId,
                                              description: "",
                                              pinnedValue: undefined
                                            }
                                          },
                                          tl: /* [] */0
                                        }),
                                    propertyBundles: $$event.propertyBundles,
                                    variants: $$event.variants,
                                    types: typeof sendAs === "number" ? $$event.types : BeltListExtensions.dedupeSlow({
                                            hd: "UpdateGroups",
                                            tl: $$event.types
                                          }),
                                    tags: $$event.tags,
                                    excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                    includeSources: $$event.includeSources,
                                    includeDestinations: $$event.includeDestinations,
                                    hashes: $$event.hashes,
                                    propertyWhitelist: $$event.propertyWhitelist,
                                    eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                    userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                    triggers: $$event.triggers,
                                    globalRequirementsMetadata: $$event.globalRequirementsMetadata
                                  };
                          })),
                  migrations: model$2.migrations,
                  sources: model$2.sources,
                  destinations: model$2.destinations,
                  groupTypes: model$2.groupTypes,
                  goals: model$2.goals,
                  metrics: model$2.metrics,
                  archive: model$2.archive,
                  openBranches: model$2.openBranches,
                  branchPointer: model$2.branchPointer,
                  rules: model$2.rules,
                  integrations: model$2.integrations,
                  globalRequirements: model$2.globalRequirements
                }
              };
      }
      var model$3 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$3.archive,
                branchPointer: model$3.branchPointer,
                migrations: model$3.migrations,
                rules: model$3.rules,
                types: model$3.types,
                categories: model$3.categories,
                destinations: model$3.destinations,
                events: sendAs === /* SystemProperty */0 ? model$3.events : Curry._3(TrackingPlanMappedModel.Events.updateExisting, model$3.events, eventId, (function ($$event) {
                          return {
                                  id: $$event.id,
                                  name: $$event.name,
                                  uniqueName: $$event.uniqueName,
                                  description: $$event.description,
                                  properties: Belt_List.concat($$event.properties, {
                                        hd: {
                                          TAG: /* PropertyRef */0,
                                          _0: {
                                            id: propertyId,
                                            description: "",
                                            pinnedValue: undefined
                                          }
                                        },
                                        tl: /* [] */0
                                      }),
                                  propertyBundles: $$event.propertyBundles,
                                  variants: $$event.variants,
                                  types: typeof sendAs === "number" ? $$event.types : BeltListExtensions.dedupeSlow({
                                          hd: "UpdateGroups",
                                          tl: $$event.types
                                        }),
                                  tags: $$event.tags,
                                  excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                  includeSources: $$event.includeSources,
                                  includeDestinations: $$event.includeDestinations,
                                  hashes: $$event.hashes,
                                  propertyWhitelist: $$event.propertyWhitelist,
                                  eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                  userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                  triggers: $$event.triggers,
                                  globalRequirementsMetadata: $$event.globalRequirementsMetadata
                                };
                        })),
                groupTypes: model$3.groupTypes,
                integrations: model$3.integrations,
                metrics: model$3.metrics,
                openBranches: model$3.openBranches,
                properties: Curry._2(TrackingPlanMappedModel.Properties.append, model$3.properties, newProperty),
                propertyBundles: model$3.propertyBundles,
                sources: model$3.sources,
                globalRequirements: model$3.globalRequirements
              }
            };
    }
    if (variant === "MergeBranch") {
      var match$2 = action.VAL;
      var actions = match$2[2];
      var branchId = match$2[0];
      if (model.TAG === /* Old */0) {
        var nextModel = TrackingPlanMappedModel.unboxModel(Belt_List.reduceU(actions, model, reduceFunction));
        return {
                TAG: /* Old */0,
                _0: {
                  types: nextModel.types,
                  properties: nextModel.properties,
                  propertyBundles: nextModel.propertyBundles,
                  events: nextModel.events,
                  migrations: nextModel.migrations,
                  sources: nextModel.sources,
                  destinations: nextModel.destinations,
                  groupTypes: nextModel.groupTypes,
                  goals: nextModel.goals,
                  metrics: nextModel.metrics,
                  archive: nextModel.archive,
                  openBranches: Belt_List.keepU(model._0.openBranches, (function (param) {
                          return param[0] !== branchId;
                        })),
                  branchPointer: nextModel.branchPointer,
                  rules: nextModel.rules,
                  integrations: nextModel.integrations,
                  globalRequirements: nextModel.globalRequirements
                }
              };
      }
      var nextModel$1 = TrackingPlanMappedModel.unboxModel(Belt_List.reduceU(actions, model, reduceFunction));
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: nextModel$1.archive,
                branchPointer: nextModel$1.branchPointer,
                migrations: nextModel$1.migrations,
                rules: nextModel$1.rules,
                types: nextModel$1.types,
                categories: nextModel$1.categories,
                destinations: nextModel$1.destinations,
                events: nextModel$1.events,
                groupTypes: nextModel$1.groupTypes,
                integrations: nextModel$1.integrations,
                metrics: nextModel$1.metrics,
                openBranches: Curry._2(TrackingPlanMappedModel.OpenBranches.remove, model._0.openBranches, branchId),
                properties: nextModel$1.properties,
                propertyBundles: nextModel$1.propertyBundles,
                sources: nextModel$1.sources,
                globalRequirements: nextModel$1.globalRequirements
              }
            };
    }
    if (variant === "AddEventType") {
      var match$3 = action.VAL;
      var type_ = match$3[1];
      var eventId$1 = match$3[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEvent(model._0, eventId$1, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: {
                                  hd: type_,
                                  tl: $$event.types
                                },
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: type_ === "SkipLogEvent" ? [] : $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      }))
              };
      }
      var model$4 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$4.archive,
                branchPointer: model$4.branchPointer,
                migrations: model$4.migrations,
                rules: model$4.rules,
                types: model$4.types,
                categories: model$4.categories,
                destinations: model$4.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.updateExisting, model$4.events, eventId$1, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: {
                                  hd: type_,
                                  tl: $$event.types
                                },
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: type_ === "SkipLogEvent" ? [] : $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      })),
                groupTypes: model$4.groupTypes,
                integrations: model$4.integrations,
                metrics: model$4.metrics,
                openBranches: model$4.openBranches,
                properties: model$4.properties,
                propertyBundles: model$4.propertyBundles,
                sources: model$4.sources,
                globalRequirements: model$4.globalRequirements
              }
            };
    }
    if (variant === "UpdateTriggerContent") {
      var match$4 = action.VAL;
      var content = match$4[2];
      var triggerId = match$4[1];
      var eventId$2 = match$4[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEventTrigger(model._0, eventId$2, triggerId, (function (trigger) {
                        return {
                                id: trigger.id,
                                sources: trigger.sources,
                                description: trigger.description,
                                content: content
                              };
                      }))
              };
      }
      var model$5 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$5.archive,
                branchPointer: model$5.branchPointer,
                migrations: model$5.migrations,
                rules: model$5.rules,
                types: model$5.types,
                categories: model$5.categories,
                destinations: model$5.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.updateExisting, model$5.events, eventId$2, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: Belt_Array.mapU($$event.triggers, (function (trigger) {
                                        if (trigger.id === triggerId) {
                                          return {
                                                  id: trigger.id,
                                                  sources: trigger.sources,
                                                  description: trigger.description,
                                                  content: content
                                                };
                                        } else {
                                          return trigger;
                                        }
                                      })),
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      })),
                groupTypes: model$5.groupTypes,
                integrations: model$5.integrations,
                metrics: model$5.metrics,
                openBranches: model$5.openBranches,
                properties: model$5.properties,
                propertyBundles: model$5.propertyBundles,
                sources: model$5.sources,
                globalRequirements: model$5.globalRequirements
              }
            };
    }
    if (variant === "ConvertPropertyBundleToGlobalPropertyBundle") {
      var match$5 = action.VAL;
      var newGlobalPropertyBundle = match$5[4];
      var globalMetadata = match$5[3];
      var globalWorkspaceId = match$5[2];
      var toPropertyBundleId = match$5[1];
      var fromPropertyBundleId = match$5[0];
      var convertEvent = function ($$event) {
        var toExists = Belt_List.some($$event.propertyBundles, (function (ref) {
                return ref.id === toPropertyBundleId;
              }));
        var propertyBundles = Belt_List.keepMap($$event.propertyBundles, (function (propertyBundleRef) {
                var id = propertyBundleRef.id;
                if (id === fromPropertyBundleId) {
                  if (toExists) {
                    return ;
                  } else {
                    return {
                            id: toPropertyBundleId,
                            description: propertyBundleRef.description
                          };
                  }
                } else {
                  return propertyBundleRef;
                }
              }));
        return {
                id: $$event.id,
                name: $$event.name,
                uniqueName: $$event.uniqueName,
                description: $$event.description,
                properties: $$event.properties,
                propertyBundles: propertyBundles,
                variants: $$event.variants,
                types: $$event.types,
                tags: $$event.tags,
                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                includeSources: $$event.includeSources,
                includeDestinations: $$event.includeDestinations,
                hashes: $$event.hashes,
                propertyWhitelist: $$event.propertyWhitelist,
                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                triggers: $$event.triggers,
                globalRequirementsMetadata: $$event.globalRequirementsMetadata
              };
      };
      if (model.TAG === /* Old */0) {
        var model$6 = model._0;
        var globalRequirementsBundle = Belt_Option.map(Belt_List.getBy(model$6.propertyBundles, (function (bundle) {
                    return bundle.id === fromPropertyBundleId;
                  })), (function (bundle) {
                return GlobalRequirementsUtils.makeGlobalRequirementsBundle({
                            id: toPropertyBundleId,
                            name: bundle.name,
                            description: bundle.description,
                            properties: bundle.properties,
                            globalRequirementsMetadata: bundle.globalRequirementsMetadata
                          }, newGlobalPropertyBundle);
              }));
        var globalRequirements = model$6.globalRequirements;
        var globalRequirements$1 = globalRequirements !== undefined ? globalRequirements : TrackingPlanModel.GlobalRequirements.empty(globalMetadata);
        var globalRequirementsWithBundle = globalRequirementsBundle !== undefined ? ({
              metadata: globalRequirements$1.metadata,
              events: globalRequirements$1.events,
              properties: globalRequirements$1.properties,
              propertyBundles: Belt_List.concat(globalRequirements$1.propertyBundles, {
                    hd: globalRequirementsBundle,
                    tl: /* [] */0
                  }),
              goals: globalRequirements$1.goals,
              groupTypes: globalRequirements$1.groupTypes,
              rules: globalRequirements$1.rules
            }) : globalRequirements$1;
        var init = ModelUtils.updatePropertyGroup(model$6, fromPropertyBundleId, (function (propertyBundle) {
                return {
                        id: toPropertyBundleId,
                        name: propertyBundle.name,
                        description: propertyBundle.description,
                        properties: propertyBundle.properties,
                        globalRequirementsMetadata: {
                          fromLocalItemId: fromPropertyBundleId,
                          globalWorkspaceId: globalWorkspaceId
                        }
                      };
              }));
        return {
                TAG: /* Old */0,
                _0: {
                  types: init.types,
                  properties: init.properties,
                  propertyBundles: init.propertyBundles,
                  events: Belt_List.map(model$6.events, convertEvent),
                  migrations: init.migrations,
                  sources: init.sources,
                  destinations: init.destinations,
                  groupTypes: init.groupTypes,
                  goals: init.goals,
                  metrics: init.metrics,
                  archive: init.archive,
                  openBranches: init.openBranches,
                  branchPointer: init.branchPointer,
                  rules: init.rules,
                  integrations: init.integrations,
                  globalRequirements: globalRequirementsWithBundle
                }
              };
      }
      var model$7 = model._0;
      var globalRequirementsBundle$1 = Belt_Option.map(Curry._2(TrackingPlanMappedModel.PropertyBundles.get, model$7.propertyBundles, fromPropertyBundleId), (function (bundle) {
              return GlobalRequirementsUtils.makeGlobalRequirementsBundle({
                          id: toPropertyBundleId,
                          name: bundle.name,
                          description: bundle.description,
                          properties: bundle.properties,
                          globalRequirementsMetadata: bundle.globalRequirementsMetadata
                        }, newGlobalPropertyBundle);
            }));
      var globalRequirements$2 = model$7.globalRequirements;
      var globalRequirements$3 = globalRequirements$2 !== undefined ? globalRequirements$2 : TrackingPlanMappedModel.GlobalRequirements.fromOld(TrackingPlanModel.GlobalRequirements.empty(globalMetadata));
      var globalRequirementsWithBundle$1 = globalRequirementsBundle$1 !== undefined ? ({
            metadata: globalRequirements$3.metadata,
            events: globalRequirements$3.events,
            properties: globalRequirements$3.properties,
            propertyBundles: Curry._2(TrackingPlanMappedModel.PropertyBundles.append, globalRequirements$3.propertyBundles, globalRequirementsBundle$1),
            categories: globalRequirements$3.categories,
            groupTypes: globalRequirements$3.groupTypes,
            rules: globalRequirements$3.rules
          }) : globalRequirements$3;
      var convertPropertyBundle = function (bundles, fromPropertyBundleId, toPropertyBundleId) {
        return Belt_Option.getWithDefault(Belt_Option.map(Curry._2(TrackingPlanMappedModel.PropertyBundles.get, bundles, fromPropertyBundleId), (function (bundle) {
                          var updatedBundle_name = bundle.name;
                          var updatedBundle_description = bundle.description;
                          var updatedBundle_properties = bundle.properties;
                          var updatedBundle_globalRequirementsMetadata = {
                            fromLocalItemId: fromPropertyBundleId,
                            globalWorkspaceId: globalWorkspaceId
                          };
                          var updatedBundle = {
                            id: toPropertyBundleId,
                            name: updatedBundle_name,
                            description: updatedBundle_description,
                            properties: updatedBundle_properties,
                            globalRequirementsMetadata: updatedBundle_globalRequirementsMetadata
                          };
                          return Curry._2(TrackingPlanMappedModel.PropertyBundles.append, Curry._2(TrackingPlanMappedModel.PropertyBundles.remove, bundles, fromPropertyBundleId), updatedBundle);
                        })), bundles);
      };
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$7.archive,
                branchPointer: model$7.branchPointer,
                migrations: model$7.migrations,
                rules: model$7.rules,
                types: model$7.types,
                categories: model$7.categories,
                destinations: model$7.destinations,
                events: Curry._2(TrackingPlanMappedModel.Events.mapToT, model$7.events, convertEvent),
                groupTypes: model$7.groupTypes,
                integrations: model$7.integrations,
                metrics: model$7.metrics,
                openBranches: model$7.openBranches,
                properties: model$7.properties,
                propertyBundles: convertPropertyBundle(model$7.propertyBundles, fromPropertyBundleId, toPropertyBundleId),
                sources: model$7.sources,
                globalRequirements: globalRequirementsWithBundle$1
              }
            };
    }
    if (variant === "RemoveEventVariantTrigger") {
      var match$6 = action.VAL;
      var triggerId$1 = match$6[1];
      var variantIdentifier = match$6[0];
      var update = function (eventVariant) {
        return {
                id: eventVariant.id,
                baseEventId: eventVariant.baseEventId,
                nameSuffix: eventVariant.nameSuffix,
                description: eventVariant.description,
                propertyOverrides: eventVariant.propertyOverrides,
                sourceOverrides: eventVariant.sourceOverrides,
                triggers: Belt_Array.keep(eventVariant.triggers, (function (param) {
                        return param.id !== triggerId$1;
                      }))
              };
      };
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEventVariant(model._0, variantIdentifier, update)
              };
      }
      var model$8 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$8.archive,
                branchPointer: model$8.branchPointer,
                migrations: model$8.migrations,
                rules: model$8.rules,
                types: model$8.types,
                categories: model$8.categories,
                destinations: model$8.destinations,
                events: ModelUtils_mapped.updateExistingVariant(model$8.events, variantIdentifier, update),
                groupTypes: model$8.groupTypes,
                integrations: model$8.integrations,
                metrics: model$8.metrics,
                openBranches: model$8.openBranches,
                properties: model$8.properties,
                propertyBundles: model$8.propertyBundles,
                sources: model$8.sources,
                globalRequirements: model$8.globalRequirements
              }
            };
    }
    if (variant === "DeprecatedRemovePropertyValidationMatch") {
      var match$7 = action.VAL;
      var match_ = match$7[1];
      var propertyId$1 = match$7[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateProperty(model._0, propertyId$1, (function (property) {
                        var propertyMatches = ModelUtils.getMatchesFromPropertyLegacy(property);
                        var nextMatchList = Belt_List.keepU(propertyMatches, (function (matchItem) {
                                var match = matchItem[0].VAL;
                                if (typeof match === "object" && match.NAME === "StringLit") {
                                  return match.VAL !== match_;
                                } else {
                                  return true;
                                }
                              }));
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: Belt_List.length(nextMatchList) === 0 ? /* [] */0 : ({
                                      hd: {
                                        NAME: "Matches",
                                        VAL: nextMatchList
                                      },
                                      tl: /* [] */0
                                    }),
                                eventSpecificAllowedPropertyValues: property.eventSpecificAllowedPropertyValues,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence,
                                globalRequirementsMetadata: property.globalRequirementsMetadata
                              };
                      }))
              };
      }
      var model$9 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$9.archive,
                branchPointer: model$9.branchPointer,
                migrations: model$9.migrations,
                rules: model$9.rules,
                types: model$9.types,
                categories: model$9.categories,
                destinations: model$9.destinations,
                events: model$9.events,
                groupTypes: model$9.groupTypes,
                integrations: model$9.integrations,
                metrics: model$9.metrics,
                openBranches: model$9.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.updateExisting, model$9.properties, propertyId$1, (function (property) {
                        var propertyMatches = ModelUtils.getMatchesFromPropertyLegacy(property);
                        var nextMatchList = Belt_List.keepU(propertyMatches, (function (matchItem) {
                                var match = matchItem[0].VAL;
                                if (typeof match === "object" && match.NAME === "StringLit") {
                                  return match.VAL !== match_;
                                } else {
                                  return true;
                                }
                              }));
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: Belt_List.length(nextMatchList) === 0 ? /* [] */0 : ({
                                      hd: {
                                        NAME: "Matches",
                                        VAL: nextMatchList
                                      },
                                      tl: /* [] */0
                                    }),
                                eventSpecificAllowedPropertyValues: property.eventSpecificAllowedPropertyValues,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence,
                                globalRequirementsMetadata: property.globalRequirementsMetadata
                              };
                      })),
                propertyBundles: model$9.propertyBundles,
                sources: model$9.sources,
                globalRequirements: model$9.globalRequirements
              }
            };
    }
    if (variant === "UpdateSourceDestinationMode") {
      var match$8 = action.VAL;
      var destinationMode = match$8[2];
      var destinationId$1 = match$8[1];
      var sourceId = match$8[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateSource(model._0, sourceId, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: source.language,
                                platform: source.platform,
                                destinations: Belt_List.mapU(source.destinations, (function (destinationRef) {
                                        if (destinationRef.destinationId === destinationId$1) {
                                          return {
                                                  destinationId: destinationRef.destinationId,
                                                  destinationMode: destinationMode
                                                };
                                        } else {
                                          return destinationRef;
                                        }
                                      })),
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: source.config
                              };
                      }))
              };
      }
      var model$10 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$10.archive,
                branchPointer: model$10.branchPointer,
                migrations: model$10.migrations,
                rules: model$10.rules,
                types: model$10.types,
                categories: model$10.categories,
                destinations: model$10.destinations,
                events: model$10.events,
                groupTypes: model$10.groupTypes,
                integrations: model$10.integrations,
                metrics: model$10.metrics,
                openBranches: model$10.openBranches,
                properties: model$10.properties,
                propertyBundles: model$10.propertyBundles,
                sources: Curry._3(TrackingPlanMappedModel.Sources.updateExisting, model$10.sources, sourceId, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: source.language,
                                platform: source.platform,
                                destinations: Belt_List.mapU(source.destinations, (function (destinationRef) {
                                        if (destinationRef.destinationId === destinationId$1) {
                                          return {
                                                  destinationId: destinationRef.destinationId,
                                                  destinationMode: destinationMode
                                                };
                                        } else {
                                          return destinationRef;
                                        }
                                      })),
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: source.config
                              };
                      })),
                globalRequirements: model$10.globalRequirements
              }
            };
    }
    if (variant === "TogglePropertyValidationMatchSource") {
      var match$9 = action.VAL;
      var isActive = match$9[3];
      var sourceId$1 = match$9[2];
      var match_$1 = match$9[1];
      var propertyId$2 = match$9[0];
      if (model.TAG === /* Old */0) {
        var model$11 = model._0;
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateProperty(model$11, propertyId$2, (function (property) {
                        var newValidations = Belt_List.mapU(property.validations, (function (validation) {
                                if (typeof validation === "object" && validation.NAME === "Matches") {
                                  return {
                                          NAME: "Matches",
                                          VAL: Belt_List.mapU(validation.VAL, (function (param) {
                                                  var excludeSources = param[1];
                                                  var currentMatch = param[0];
                                                  var match = currentMatch.VAL;
                                                  if (typeof match === "object" && match.NAME === "StringLit" && match.VAL === match_$1) {
                                                    return [
                                                            currentMatch,
                                                            isActive ? Belt_List.keep(excludeSources, (function (id) {
                                                                      return id !== sourceId$1;
                                                                    })) : Belt_List.add(Belt_List.keep(excludeSources, (function (id) {
                                                                          return id !== sourceId$1;
                                                                        })), sourceId$1)
                                                          ];
                                                  } else {
                                                    return [
                                                            currentMatch,
                                                            excludeSources
                                                          ];
                                                  }
                                                }))
                                        };
                                } else {
                                  return validation;
                                }
                              }));
                        var newEventSpecificAllowedPropertyValues = ModelUtils.hasMigrated(model$11, "EventSpecificAllowedPropertyValues") ? ConvertPropertyValidationMatchesToEventSpecificAllowedValues.convertWithSlowModel(model$11, property, newValidations) : property.eventSpecificAllowedPropertyValues;
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: newValidations,
                                eventSpecificAllowedPropertyValues: newEventSpecificAllowedPropertyValues,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence,
                                globalRequirementsMetadata: property.globalRequirementsMetadata
                              };
                      }))
              };
      }
      var model$12 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$12.archive,
                branchPointer: model$12.branchPointer,
                migrations: model$12.migrations,
                rules: model$12.rules,
                types: model$12.types,
                categories: model$12.categories,
                destinations: model$12.destinations,
                events: model$12.events,
                groupTypes: model$12.groupTypes,
                integrations: model$12.integrations,
                metrics: model$12.metrics,
                openBranches: model$12.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.updateExisting, model$12.properties, propertyId$2, (function (property) {
                        var newValidations = Belt_List.mapU(property.validations, (function (validation) {
                                if (typeof validation === "object" && validation.NAME === "Matches") {
                                  return {
                                          NAME: "Matches",
                                          VAL: Belt_List.mapU(validation.VAL, (function (param) {
                                                  var excludeSources = param[1];
                                                  var currentMatch = param[0];
                                                  var match = currentMatch.VAL;
                                                  if (typeof match === "object" && match.NAME === "StringLit" && match.VAL === match_$1) {
                                                    return [
                                                            currentMatch,
                                                            isActive ? Belt_List.keep(excludeSources, (function (id) {
                                                                      return id !== sourceId$1;
                                                                    })) : Belt_List.add(Belt_List.keep(excludeSources, (function (id) {
                                                                          return id !== sourceId$1;
                                                                        })), sourceId$1)
                                                          ];
                                                  } else {
                                                    return [
                                                            currentMatch,
                                                            excludeSources
                                                          ];
                                                  }
                                                }))
                                        };
                                } else {
                                  return validation;
                                }
                              }));
                        var newEventSpecificAllowedPropertyValues = ModelUtils_mapped.hasMigrated(model$12, "EventSpecificAllowedPropertyValues") ? ConvertPropertyValidationMatchesToEventSpecificAllowedValues.convertWithMappedModel(model$12, property, newValidations) : property.eventSpecificAllowedPropertyValues;
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: newValidations,
                                eventSpecificAllowedPropertyValues: newEventSpecificAllowedPropertyValues,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence,
                                globalRequirementsMetadata: property.globalRequirementsMetadata
                              };
                      })),
                propertyBundles: model$12.propertyBundles,
                sources: model$12.sources,
                globalRequirements: model$12.globalRequirements
              }
            };
    }
    if (variant === "RemoveEventSpecificPropertyValueForAllEvents") {
      var match$10 = action.VAL;
      var literal = match$10[1];
      var propertyId$3 = match$10[0];
      var removePropertyValue = function (property) {
        var propertyDisallowedSourcesBefore = property.eventSpecificAllowedPropertyValues;
        var eventSpecificAllowedPropertyValues = Belt_Array.keep(propertyDisallowedSourcesBefore, (function (param) {
                return Caml_obj.caml_notequal(param[0], literal);
              }));
        return {
                id: property.id,
                name: property.name,
                uniqueName: property.uniqueName,
                description: property.description,
                type_: property.type_,
                sendAs: property.sendAs,
                validations: property.validations,
                eventSpecificAllowedPropertyValues: eventSpecificAllowedPropertyValues,
                auto: property.auto,
                builtIn: property.builtIn,
                analyticsTools: property.analyticsTools,
                devPlatforms: property.devPlatforms,
                list: property.list,
                operation: property.operation,
                optionalDeprecated: property.optionalDeprecated,
                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                optionalWhenInObject: property.optionalWhenInObject,
                absence: property.absence,
                globalRequirementsMetadata: property.globalRequirementsMetadata
              };
      };
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateProperty(model._0, propertyId$3, removePropertyValue)
              };
      }
      var model$13 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$13.archive,
                branchPointer: model$13.branchPointer,
                migrations: model$13.migrations,
                rules: model$13.rules,
                types: model$13.types,
                categories: model$13.categories,
                destinations: model$13.destinations,
                events: model$13.events,
                groupTypes: model$13.groupTypes,
                integrations: model$13.integrations,
                metrics: model$13.metrics,
                openBranches: model$13.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.updateExisting, model$13.properties, propertyId$3, removePropertyValue),
                propertyBundles: model$13.propertyBundles,
                sources: model$13.sources,
                globalRequirements: model$13.globalRequirements
              }
            };
    }
    if (variant === "UpdatePropertyUniqueName") {
      var match$11 = action.VAL;
      var name = match$11[1];
      var propertyId$4 = match$11[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateProperty(model._0, propertyId$4, (function (property) {
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: name === "" ? undefined : name,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: property.validations,
                                eventSpecificAllowedPropertyValues: property.eventSpecificAllowedPropertyValues,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence,
                                globalRequirementsMetadata: property.globalRequirementsMetadata
                              };
                      }))
              };
      }
      var model$14 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$14.archive,
                branchPointer: model$14.branchPointer,
                migrations: model$14.migrations,
                rules: model$14.rules,
                types: model$14.types,
                categories: model$14.categories,
                destinations: model$14.destinations,
                events: model$14.events,
                groupTypes: model$14.groupTypes,
                integrations: model$14.integrations,
                metrics: model$14.metrics,
                openBranches: model$14.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.updateExisting, model$14.properties, propertyId$4, (function (property) {
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: name === "" ? undefined : name,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: property.validations,
                                eventSpecificAllowedPropertyValues: property.eventSpecificAllowedPropertyValues,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence,
                                globalRequirementsMetadata: property.globalRequirementsMetadata
                              };
                      })),
                propertyBundles: model$14.propertyBundles,
                sources: model$14.sources,
                globalRequirements: model$14.globalRequirements
              }
            };
    }
    if (variant === "UpdateDestinationIncludeUserPropsWithEventProps") {
      var match$12 = action.VAL;
      var includeUserPropsWithEventProps = match$12[1];
      var destinationId$2 = match$12[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateDestination(model._0, destinationId$2, (function (destination) {
                        return {
                                id: destination.id,
                                name: destination.name,
                                type_: destination.type_,
                                apiKeys: destination.apiKeys,
                                includeUserPropsWithEventProps: includeUserPropsWithEventProps,
                                disabledByDefault: destination.disabledByDefault
                              };
                      }))
              };
      }
      var model$15 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$15.archive,
                branchPointer: model$15.branchPointer,
                migrations: model$15.migrations,
                rules: model$15.rules,
                types: model$15.types,
                categories: model$15.categories,
                destinations: Curry._3(TrackingPlanMappedModel.Destinations.updateExisting, model$15.destinations, destinationId$2, (function (destination) {
                        return {
                                id: destination.id,
                                name: destination.name,
                                type_: destination.type_,
                                apiKeys: destination.apiKeys,
                                includeUserPropsWithEventProps: includeUserPropsWithEventProps,
                                disabledByDefault: destination.disabledByDefault
                              };
                      })),
                events: model$15.events,
                groupTypes: model$15.groupTypes,
                integrations: model$15.integrations,
                metrics: model$15.metrics,
                openBranches: model$15.openBranches,
                properties: model$15.properties,
                propertyBundles: model$15.propertyBundles,
                sources: model$15.sources,
                globalRequirements: model$15.globalRequirements
              }
            };
    }
    if (variant === "AddEvent") {
      var match$13 = action.VAL;
      var name$1 = match$13[1];
      var eventId$3 = match$13[0];
      if (model.TAG === /* Old */0) {
        var model$16 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$16.types,
                  properties: model$16.properties,
                  propertyBundles: model$16.propertyBundles,
                  events: Belt_List.concat(model$16.events, {
                        hd: TrackingPlanModel.emptyEvent(eventId$3, name$1),
                        tl: /* [] */0
                      }),
                  migrations: model$16.migrations,
                  sources: model$16.sources,
                  destinations: model$16.destinations,
                  groupTypes: model$16.groupTypes,
                  goals: model$16.goals,
                  metrics: model$16.metrics,
                  archive: model$16.archive,
                  openBranches: model$16.openBranches,
                  branchPointer: model$16.branchPointer,
                  rules: model$16.rules,
                  integrations: model$16.integrations,
                  globalRequirements: model$16.globalRequirements
                }
              };
      }
      var model$17 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$17.archive,
                branchPointer: model$17.branchPointer,
                migrations: model$17.migrations,
                rules: model$17.rules,
                types: model$17.types,
                categories: model$17.categories,
                destinations: model$17.destinations,
                events: Curry._2(TrackingPlanMappedModel.Events.append, model$17.events, TrackingPlanModel.emptyEvent(eventId$3, name$1)),
                groupTypes: model$17.groupTypes,
                integrations: model$17.integrations,
                metrics: model$17.metrics,
                openBranches: model$17.openBranches,
                properties: model$17.properties,
                propertyBundles: model$17.propertyBundles,
                sources: model$17.sources,
                globalRequirements: model$17.globalRequirements
              }
            };
    }
    if (variant === "RemovePropertyPermanently") {
      var propertyId$5 = action.VAL;
      if (model.TAG === /* Old */0) {
        var model$18 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$18.types,
                  properties: Belt_List.keepU(model$18.properties, (function (property) {
                          if (property.TAG === /* PropertyRef */0) {
                            return true;
                          } else {
                            return property._0.id !== propertyId$5;
                          }
                        })),
                  propertyBundles: Belt_List.mapU(model$18.propertyBundles, (function (group) {
                          return {
                                  id: group.id,
                                  name: group.name,
                                  description: group.description,
                                  properties: Belt_List.keepU(group.properties, (function (id) {
                                          return id !== propertyId$5;
                                        })),
                                  globalRequirementsMetadata: group.globalRequirementsMetadata
                                };
                        })),
                  events: model$18.events,
                  migrations: model$18.migrations,
                  sources: model$18.sources,
                  destinations: model$18.destinations,
                  groupTypes: model$18.groupTypes,
                  goals: model$18.goals,
                  metrics: model$18.metrics,
                  archive: model$18.archive,
                  openBranches: model$18.openBranches,
                  branchPointer: model$18.branchPointer,
                  rules: model$18.rules,
                  integrations: model$18.integrations,
                  globalRequirements: model$18.globalRequirements
                }
              };
      }
      var model$19 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$19.archive,
                branchPointer: model$19.branchPointer,
                migrations: model$19.migrations,
                rules: model$19.rules,
                types: model$19.types,
                categories: model$19.categories,
                destinations: model$19.destinations,
                events: model$19.events,
                groupTypes: model$19.groupTypes,
                integrations: model$19.integrations,
                metrics: model$19.metrics,
                openBranches: model$19.openBranches,
                properties: Curry._2(TrackingPlanMappedModel.Properties.remove, model$19.properties, propertyId$5),
                propertyBundles: Curry._2(TrackingPlanMappedModel.PropertyBundles.mapToT, model$19.propertyBundles, (function (propertyBundle) {
                        return {
                                id: propertyBundle.id,
                                name: propertyBundle.name,
                                description: propertyBundle.description,
                                properties: Belt_List.keepU(propertyBundle.properties, (function (id) {
                                        return id !== propertyId$5;
                                      })),
                                globalRequirementsMetadata: propertyBundle.globalRequirementsMetadata
                              };
                      })),
                sources: model$19.sources,
                globalRequirements: model$19.globalRequirements
              }
            };
    }
    if (variant === "UpdateTrackingPlanAuditConfig" || variant === "RemoveMember" || variant === "UpdateIntegrationDestinationIds" || variant === "CompletedImport" || variant === "CollaboratorRemoved" || variant === "CreateServiceAccount" || variant === "AutoPublishIntegration" || variant === "BranchInit" || variant === "ArchiveSavedView" || variant === "CodeGenerated" || variant === "ResendInvite" || variant === "CreateInspectorIssue" || variant === "PropertyComment" || variant === "DeleteComment" || variant === "CreatePublicBranchImplementation" || variant === "SubscriptionStarted" || variant === "PublishIntegration" || variant === "SubscriptionCancelled" || variant === "SubscriptionUpdated" || variant === "GenerateWebhookSecret" || variant === "UpdateMemberRole" || variant === "GoalComment" || variant === "UpdateSavedView" || variant === "IntegrationComment" || variant === "InspectorIssueComment" || variant === "DeleteServiceAccount" || variant === "MetricComment" || variant === "DeletePublicBranchImplementation" || variant === "CollaboratorAdded" || variant === "PropertyGroupComment" || variant === "UpdateInspectorIssueStatus" || variant === "EventComment" || variant === "RemoveInvite" || variant === "BranchComment" || variant === "InviteMember" || variant === "PaymentCompleted" || variant === "SetBranchStatus" || variant === "CreateSavedView") {
      return model;
    }
    if (variant === "RemovePropertyFromWhitelist") {
      var match$14 = action.VAL;
      var analyticsTool = match$14[2];
      var propertyId$6 = match$14[1];
      var eventId$4 = match$14[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEvent(model._0, eventId$4, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: Belt_List.keepU($$event.propertyWhitelist, (function (param) {
                                        return !(param[0] === analyticsTool && param[1] === propertyId$6);
                                      })),
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      }))
              };
      }
      var model$20 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$20.archive,
                branchPointer: model$20.branchPointer,
                migrations: model$20.migrations,
                rules: model$20.rules,
                types: model$20.types,
                categories: model$20.categories,
                destinations: model$20.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.updateExisting, model$20.events, eventId$4, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: Belt_List.keepU($$event.propertyWhitelist, (function (param) {
                                        return !(param[0] === analyticsTool && param[1] === propertyId$6);
                                      })),
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      })),
                groupTypes: model$20.groupTypes,
                integrations: model$20.integrations,
                metrics: model$20.metrics,
                openBranches: model$20.openBranches,
                properties: model$20.properties,
                propertyBundles: model$20.propertyBundles,
                sources: model$20.sources,
                globalRequirements: model$20.globalRequirements
              }
            };
    }
    if (variant === "UpdateLibraryDestination") {
      var match$15 = action.VAL;
      var destination = match$15[1];
      var sourceId$2 = match$15[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateSource(model._0, sourceId$2, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: source.language,
                                platform: source.platform,
                                destinations: source.destinations,
                                filename: source.filename,
                                path: destination,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: source.config
                              };
                      }))
              };
      }
      var model$21 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$21.archive,
                branchPointer: model$21.branchPointer,
                migrations: model$21.migrations,
                rules: model$21.rules,
                types: model$21.types,
                categories: model$21.categories,
                destinations: model$21.destinations,
                events: model$21.events,
                groupTypes: model$21.groupTypes,
                integrations: model$21.integrations,
                metrics: model$21.metrics,
                openBranches: model$21.openBranches,
                properties: model$21.properties,
                propertyBundles: model$21.propertyBundles,
                sources: Curry._3(TrackingPlanMappedModel.Sources.updateExisting, model$21.sources, sourceId$2, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: source.language,
                                platform: source.platform,
                                destinations: source.destinations,
                                filename: source.filename,
                                path: destination,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: source.config
                              };
                      })),
                globalRequirements: model$21.globalRequirements
              }
            };
    }
    if (variant === "AddPropertyToEventVariant") {
      var match$16 = action.VAL;
      var propertyId$7 = match$16[1];
      var id = match$16[0];
      var update$1 = function (variant) {
        return {
                id: variant.id,
                baseEventId: variant.baseEventId,
                nameSuffix: variant.nameSuffix,
                description: variant.description,
                propertyOverrides: Belt_MapString.update(variant.propertyOverrides, propertyId$7, (function (override) {
                        if (override !== undefined && override) {
                          return override;
                        } else {
                          return /* Overridden */{
                                  _0: {
                                    pinnedValue: /* NoneOverride */0,
                                    absence: /* NoneOverride */0,
                                    allowedValues: /* NoneOverride */0,
                                    regexValidation: /* NoneOverride */0
                                  }
                                };
                        }
                      })),
                sourceOverrides: variant.sourceOverrides,
                triggers: variant.triggers
              };
      };
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEventVariant(model._0, id, update$1)
              };
      }
      var model$22 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$22.archive,
                branchPointer: model$22.branchPointer,
                migrations: model$22.migrations,
                rules: model$22.rules,
                types: model$22.types,
                categories: model$22.categories,
                destinations: model$22.destinations,
                events: ModelUtils_mapped.updateExistingVariant(model$22.events, id, update$1),
                groupTypes: model$22.groupTypes,
                integrations: model$22.integrations,
                metrics: model$22.metrics,
                openBranches: model$22.openBranches,
                properties: model$22.properties,
                propertyBundles: model$22.propertyBundles,
                sources: model$22.sources,
                globalRequirements: model$22.globalRequirements
              }
            };
    }
    if (variant === "UpdateEventVariantTriggerSources") {
      var match$17 = action.VAL;
      var sources = match$17[2];
      var triggerId$2 = match$17[1];
      var variantIdentifier$1 = match$17[0];
      var update$2 = function (trigger) {
        return {
                id: trigger.id,
                sources: sources,
                description: trigger.description,
                content: trigger.content
              };
      };
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEventVariantTrigger(model._0, variantIdentifier$1, triggerId$2, update$2)
              };
      } else {
        return {
                TAG: /* Mapped */1,
                _0: ModelUtils_mapped.updateEventVariantTrigger_mappedModel(model._0, variantIdentifier$1, triggerId$2, update$2)
              };
      }
    }
    if (variant === "ImportDeprecated") {
      var actions$1 = action.VAL;
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: TrackingPlanMappedModel.unboxModel(Belt_List.reduceU(actions$1, model, reduceFunction))
              };
      }
      var nextModel$2 = TrackingPlanMappedModel.unboxModel(Belt_List.reduceU(actions$1, model, reduceFunction));
      return {
              TAG: /* Mapped */1,
              _0: nextModel$2
            };
    }
    if (variant === "ClearPropertyPinnedValueFromEventVariant") {
      var match$18 = action.VAL;
      var propertyId$8 = match$18[1];
      var id$1 = match$18[0];
      var update$3 = function (variant) {
        return {
                id: variant.id,
                baseEventId: variant.baseEventId,
                nameSuffix: variant.nameSuffix,
                description: variant.description,
                propertyOverrides: Belt_MapString.update(variant.propertyOverrides, propertyId$8, (function (override) {
                        if (override === undefined) {
                          return /* Overridden */{
                                  _0: {
                                    pinnedValue: /* NoneOverride */0,
                                    absence: /* NoneOverride */0,
                                    allowedValues: /* NoneOverride */0,
                                    regexValidation: /* NoneOverride */0
                                  }
                                };
                        }
                        if (!override) {
                          return /* Removed */0;
                        }
                        var override$1 = override._0;
                        return /* Overridden */{
                                _0: {
                                  pinnedValue: /* NoneOverride */0,
                                  absence: override$1.absence,
                                  allowedValues: override$1.allowedValues,
                                  regexValidation: override$1.regexValidation
                                }
                              };
                      })),
                sourceOverrides: variant.sourceOverrides,
                triggers: variant.triggers
              };
      };
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEventVariant(model._0, id$1, update$3)
              };
      }
      var model$23 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$23.archive,
                branchPointer: model$23.branchPointer,
                migrations: model$23.migrations,
                rules: model$23.rules,
                types: model$23.types,
                categories: model$23.categories,
                destinations: model$23.destinations,
                events: ModelUtils_mapped.updateExistingVariant(model$23.events, id$1, update$3),
                groupTypes: model$23.groupTypes,
                integrations: model$23.integrations,
                metrics: model$23.metrics,
                openBranches: model$23.openBranches,
                properties: model$23.properties,
                propertyBundles: model$23.propertyBundles,
                sources: model$23.sources,
                globalRequirements: model$23.globalRequirements
              }
            };
    }
    if (variant === "AddGoal") {
      var goalId = action.VAL;
      if (model.TAG === /* Old */0) {
        var model$24 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$24.types,
                  properties: model$24.properties,
                  propertyBundles: model$24.propertyBundles,
                  events: model$24.events,
                  migrations: model$24.migrations,
                  sources: model$24.sources,
                  destinations: model$24.destinations,
                  groupTypes: model$24.groupTypes,
                  goals: Belt_List.concat({
                        hd: TrackingPlanModel.emptyGoal(goalId),
                        tl: /* [] */0
                      }, model$24.goals),
                  metrics: model$24.metrics,
                  archive: model$24.archive,
                  openBranches: model$24.openBranches,
                  branchPointer: model$24.branchPointer,
                  rules: model$24.rules,
                  integrations: model$24.integrations,
                  globalRequirements: model$24.globalRequirements
                }
              };
      }
      var model$25 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$25.archive,
                branchPointer: model$25.branchPointer,
                migrations: model$25.migrations,
                rules: model$25.rules,
                types: model$25.types,
                categories: Curry._2(TrackingPlanMappedModel.Categories.prepend, model$25.categories, TrackingPlanModel.emptyGoal(goalId)),
                destinations: model$25.destinations,
                events: model$25.events,
                groupTypes: model$25.groupTypes,
                integrations: model$25.integrations,
                metrics: model$25.metrics,
                openBranches: model$25.openBranches,
                properties: model$25.properties,
                propertyBundles: model$25.propertyBundles,
                sources: model$25.sources,
                globalRequirements: model$25.globalRequirements
              }
            };
    }
    if (variant === "UpdateIntegrationAutoPublish") {
      var match$19 = action.VAL;
      var autoPublish = match$19[1];
      var integrationId = match$19[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateIntegration(model._0, integrationId, (function (integration) {
                        return {
                                id: integration.id,
                                name: integration.name,
                                config: integration.config,
                                filters: integration.filters,
                                autoPublish: autoPublish
                              };
                      }))
              };
      }
      var model$26 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$26.archive,
                branchPointer: model$26.branchPointer,
                migrations: model$26.migrations,
                rules: model$26.rules,
                types: model$26.types,
                categories: model$26.categories,
                destinations: model$26.destinations,
                events: model$26.events,
                groupTypes: model$26.groupTypes,
                integrations: Curry._3(TrackingPlanMappedModel.Integrations.updateExisting, model$26.integrations, integrationId, (function (integration) {
                        return {
                                id: integration.id,
                                name: integration.name,
                                config: integration.config,
                                filters: integration.filters,
                                autoPublish: autoPublish
                              };
                      })),
                metrics: model$26.metrics,
                openBranches: model$26.openBranches,
                properties: model$26.properties,
                propertyBundles: model$26.propertyBundles,
                sources: model$26.sources,
                globalRequirements: model$26.globalRequirements
              }
            };
    }
    if (variant === "SetSourceOverrideOnVariant") {
      var match$20 = action.VAL;
      var sourceOverrideType = match$20[2];
      var sourceId$3 = match$20[1];
      var variantIdentifier$2 = match$20[0];
      var setSourceOverrideOnVariant = function (variant) {
        return {
                id: variant.id,
                baseEventId: variant.baseEventId,
                nameSuffix: variant.nameSuffix,
                description: variant.description,
                propertyOverrides: variant.propertyOverrides,
                sourceOverrides: sourceOverrideType ? Belt_MapString.set(variant.sourceOverrides, sourceId$3, /* Overridden */{
                        _0: {
                          includeInCodegen: /* NoneOverride */0
                        }
                      }) : Belt_MapString.set(variant.sourceOverrides, sourceId$3, /* Removed */0),
                triggers: variant.triggers
              };
      };
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEventVariant(model._0, variantIdentifier$2, setSourceOverrideOnVariant)
              };
      }
      var model$27 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$27.archive,
                branchPointer: model$27.branchPointer,
                migrations: model$27.migrations,
                rules: model$27.rules,
                types: model$27.types,
                categories: model$27.categories,
                destinations: model$27.destinations,
                events: ModelUtils_mapped.updateExistingVariant(model$27.events, variantIdentifier$2, setSourceOverrideOnVariant),
                groupTypes: model$27.groupTypes,
                integrations: model$27.integrations,
                metrics: model$27.metrics,
                openBranches: model$27.openBranches,
                properties: model$27.properties,
                propertyBundles: model$27.propertyBundles,
                sources: model$27.sources,
                globalRequirements: model$27.globalRequirements
              }
            };
    }
    if (variant === "CreateDemoBranch") {
      return Belt_List.reduceU(action.VAL, model, reduceFunction);
    }
    if (variant === "DeprecatedAddPropertyValidationMatch") {
      var match$21 = action.VAL;
      var match_$2 = match$21[1];
      var propertyId$9 = match$21[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateProperty(model._0, propertyId$9, (function (property) {
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: {
                                  hd: {
                                    NAME: "Matches",
                                    VAL: {
                                      hd: [
                                        {
                                          NAME: "Literal",
                                          VAL: {
                                            NAME: "StringLit",
                                            VAL: match_$2
                                          }
                                        },
                                        /* [] */0
                                      ],
                                      tl: ModelUtils.getMatchesFromPropertyLegacy(property)
                                    }
                                  },
                                  tl: /* [] */0
                                },
                                eventSpecificAllowedPropertyValues: property.eventSpecificAllowedPropertyValues,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence,
                                globalRequirementsMetadata: property.globalRequirementsMetadata
                              };
                      }))
              };
      }
      var model$28 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$28.archive,
                branchPointer: model$28.branchPointer,
                migrations: model$28.migrations,
                rules: model$28.rules,
                types: model$28.types,
                categories: model$28.categories,
                destinations: model$28.destinations,
                events: model$28.events,
                groupTypes: model$28.groupTypes,
                integrations: model$28.integrations,
                metrics: model$28.metrics,
                openBranches: model$28.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.updateExisting, model$28.properties, propertyId$9, (function (property) {
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: {
                                  hd: {
                                    NAME: "Matches",
                                    VAL: {
                                      hd: [
                                        {
                                          NAME: "Literal",
                                          VAL: {
                                            NAME: "StringLit",
                                            VAL: match_$2
                                          }
                                        },
                                        /* [] */0
                                      ],
                                      tl: ModelUtils.getMatchesFromPropertyLegacy(property)
                                    }
                                  },
                                  tl: /* [] */0
                                },
                                eventSpecificAllowedPropertyValues: property.eventSpecificAllowedPropertyValues,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence,
                                globalRequirementsMetadata: property.globalRequirementsMetadata
                              };
                      })),
                propertyBundles: model$28.propertyBundles,
                sources: model$28.sources,
                globalRequirements: model$28.globalRequirements
              }
            };
    }
    if (variant === "UpdateGoalDescription") {
      var match$22 = action.VAL;
      var description = match$22[1];
      var goalId$1 = match$22[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateGoal(model._0, goalId$1, (function (goal) {
                        return {
                                id: goal.id,
                                name: goal.name,
                                description: description,
                                metrics: goal.metrics,
                                events: goal.events
                              };
                      }))
              };
      }
      var model$29 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$29.archive,
                branchPointer: model$29.branchPointer,
                migrations: model$29.migrations,
                rules: model$29.rules,
                types: model$29.types,
                categories: Curry._3(TrackingPlanMappedModel.Categories.updateExisting, model$29.categories, goalId$1, (function (category) {
                        return {
                                id: category.id,
                                name: category.name,
                                description: description,
                                metrics: category.metrics,
                                events: category.events
                              };
                      })),
                destinations: model$29.destinations,
                events: model$29.events,
                groupTypes: model$29.groupTypes,
                integrations: model$29.integrations,
                metrics: model$29.metrics,
                openBranches: model$29.openBranches,
                properties: model$29.properties,
                propertyBundles: model$29.propertyBundles,
                sources: model$29.sources,
                globalRequirements: model$29.globalRequirements
              }
            };
    }
    if (variant === "MigrateSourcesAndDestinationsToModel") {
      var match$23 = action.VAL;
      var destinations = match$23[1];
      var sources$1 = match$23[0];
      if (model.TAG === /* Old */0) {
        var model$30 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$30.types,
                  properties: model$30.properties,
                  propertyBundles: model$30.propertyBundles,
                  events: model$30.events,
                  migrations: model$30.migrations,
                  sources: sources$1,
                  destinations: destinations,
                  groupTypes: model$30.groupTypes,
                  goals: model$30.goals,
                  metrics: model$30.metrics,
                  archive: model$30.archive,
                  openBranches: model$30.openBranches,
                  branchPointer: model$30.branchPointer,
                  rules: model$30.rules,
                  integrations: model$30.integrations,
                  globalRequirements: model$30.globalRequirements
                }
              };
      }
      var model$31 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$31.archive,
                branchPointer: model$31.branchPointer,
                migrations: model$31.migrations,
                rules: model$31.rules,
                types: model$31.types,
                categories: model$31.categories,
                destinations: Curry._1(TrackingPlanMappedModel.Destinations.fromList, destinations),
                events: model$31.events,
                groupTypes: model$31.groupTypes,
                integrations: model$31.integrations,
                metrics: model$31.metrics,
                openBranches: model$31.openBranches,
                properties: model$31.properties,
                propertyBundles: model$31.propertyBundles,
                sources: Curry._1(TrackingPlanMappedModel.Sources.fromList, sources$1),
                globalRequirements: model$31.globalRequirements
              }
            };
    }
    if (variant === "DeprecatedRemovePropertyValidationMatches") {
      var propertyId$10 = action.VAL;
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updatePropertyValidations(model._0, propertyId$10, (function (param, param$1) {
                        return /* [] */0;
                      }))
              };
      }
      var model$32 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$32.archive,
                branchPointer: model$32.branchPointer,
                migrations: model$32.migrations,
                rules: model$32.rules,
                types: model$32.types,
                categories: model$32.categories,
                destinations: model$32.destinations,
                events: model$32.events,
                groupTypes: model$32.groupTypes,
                integrations: model$32.integrations,
                metrics: model$32.metrics,
                openBranches: model$32.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.updateExisting, model$32.properties, propertyId$10, (function (property) {
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: /* [] */0,
                                eventSpecificAllowedPropertyValues: property.eventSpecificAllowedPropertyValues,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence,
                                globalRequirementsMetadata: property.globalRequirementsMetadata
                              };
                      })),
                propertyBundles: model$32.propertyBundles,
                sources: model$32.sources,
                globalRequirements: model$32.globalRequirements
              }
            };
    }
    if (variant === "UpdatePropertyList") {
      var match$24 = action.VAL;
      var list = match$24[1];
      var propertyId$11 = match$24[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateProperty(model._0, propertyId$11, (function (property) {
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: property.validations,
                                eventSpecificAllowedPropertyValues: property.eventSpecificAllowedPropertyValues,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence,
                                globalRequirementsMetadata: property.globalRequirementsMetadata
                              };
                      }))
              };
      }
      var model$33 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$33.archive,
                branchPointer: model$33.branchPointer,
                migrations: model$33.migrations,
                rules: model$33.rules,
                types: model$33.types,
                categories: model$33.categories,
                destinations: model$33.destinations,
                events: model$33.events,
                groupTypes: model$33.groupTypes,
                integrations: model$33.integrations,
                metrics: model$33.metrics,
                openBranches: model$33.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.updateExisting, model$33.properties, propertyId$11, (function (property) {
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: property.validations,
                                eventSpecificAllowedPropertyValues: property.eventSpecificAllowedPropertyValues,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence,
                                globalRequirementsMetadata: property.globalRequirementsMetadata
                              };
                      })),
                propertyBundles: model$33.propertyBundles,
                sources: model$33.sources,
                globalRequirements: model$33.globalRequirements
              }
            };
    }
    if (variant === "SetMetricWhereV2") {
      var match$25 = action.VAL;
      var literals = match$25[6];
      var binOp = match$25[5];
      var propertyId$12 = match$25[4];
      var id$2 = match$25[3];
      var metricItemId = match$25[1];
      var metricId = match$25[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateMetric(model._0, metricId, (function (metric) {
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metric.type_,
                                items: Belt_List.mapU(metric.items, (function (metricItem) {
                                        var $$event = metricItem.VAL;
                                        if (metricItemId !== $$event.id) {
                                          return metricItem;
                                        }
                                        var where = $$event.where;
                                        var match = Belt_List.keepU(where, (function (where) {
                                                return where.id === id$2;
                                              }));
                                        return {
                                                NAME: "Event",
                                                VAL: {
                                                  id: $$event.id,
                                                  eventId: $$event.eventId,
                                                  where: match ? Belt_List.mapU(where, (function (where) {
                                                            if (where.id === id$2) {
                                                              return {
                                                                      id: id$2,
                                                                      propertyId: propertyId$12,
                                                                      binOp: binOp,
                                                                      literals: literals
                                                                    };
                                                            } else {
                                                              return where;
                                                            }
                                                          })) : Belt_List.concat(where, {
                                                          hd: {
                                                            id: id$2,
                                                            propertyId: propertyId$12,
                                                            binOp: binOp,
                                                            literals: literals
                                                          },
                                                          tl: /* [] */0
                                                        }),
                                                  groupBy: $$event.groupBy
                                                }
                                              };
                                      }))
                              };
                      }))
              };
      }
      var model$34 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$34.archive,
                branchPointer: model$34.branchPointer,
                migrations: model$34.migrations,
                rules: model$34.rules,
                types: model$34.types,
                categories: model$34.categories,
                destinations: model$34.destinations,
                events: model$34.events,
                groupTypes: model$34.groupTypes,
                integrations: model$34.integrations,
                metrics: Curry._3(TrackingPlanMappedModel.Metrics.updateExisting, model$34.metrics, metricId, (function (metric) {
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metric.type_,
                                items: Belt_List.mapU(metric.items, (function (metricItem) {
                                        var $$event = metricItem.VAL;
                                        if (metricItemId !== $$event.id) {
                                          return metricItem;
                                        }
                                        var where = $$event.where;
                                        var match = Belt_List.keepU(where, (function (where) {
                                                return where.id === id$2;
                                              }));
                                        return {
                                                NAME: "Event",
                                                VAL: {
                                                  id: $$event.id,
                                                  eventId: $$event.eventId,
                                                  where: match ? Belt_List.mapU(where, (function (where) {
                                                            if (where.id === id$2) {
                                                              return {
                                                                      id: id$2,
                                                                      propertyId: propertyId$12,
                                                                      binOp: binOp,
                                                                      literals: literals
                                                                    };
                                                            } else {
                                                              return where;
                                                            }
                                                          })) : Belt_List.concat(where, {
                                                          hd: {
                                                            id: id$2,
                                                            propertyId: propertyId$12,
                                                            binOp: binOp,
                                                            literals: literals
                                                          },
                                                          tl: /* [] */0
                                                        }),
                                                  groupBy: $$event.groupBy
                                                }
                                              };
                                      }))
                              };
                      })),
                openBranches: model$34.openBranches,
                properties: model$34.properties,
                propertyBundles: model$34.propertyBundles,
                sources: model$34.sources,
                globalRequirements: model$34.globalRequirements
              }
            };
    }
    if (variant === "ConvertEventToGlobalEvent") {
      var match$26 = action.VAL;
      var newGlobalEvent = match$26[4];
      var globalMetadata$1 = match$26[3];
      var globalWorkspaceId$1 = match$26[2];
      var toEventId = match$26[1];
      var fromEventId = match$26[0];
      var convertRule = function (rule, globalRequirementEvents) {
        var item = rule.item;
        var variant = item.NAME;
        var ruleItem;
        if (variant === "PropertyRef") {
          var match = item.VAL;
          ruleItem = match[0] === fromEventId ? ({
                NAME: "PropertyRef",
                VAL: [
                  toEventId,
                  match[1]
                ]
              }) : item;
        } else if (variant === "Property") {
          ruleItem = item;
        } else if (variant === "Event") {
          ruleItem = item.VAL === fromEventId ? undefined : item;
        } else {
          var match$1 = item.VAL;
          if (match$1[0] === fromEventId) {
            var nestedPropertyId = match$1[2];
            var propertyId = match$1[1];
            var toEvent = Belt_List.getBy(globalRequirementEvents, (function ($$event) {
                    return $$event.id === toEventId;
                  }));
            var fromEvent = Belt_List.getBy(globalRequirementEvents, (function ($$event) {
                    return $$event.id === fromEventId;
                  }));
            var exit = 0;
            var $$event;
            if (toEvent !== undefined) {
              if (fromEvent !== undefined) {
                $$event = fromEvent;
                exit = 1;
              } else {
                $$event = toEvent;
                exit = 1;
              }
            } else if (fromEvent !== undefined) {
              $$event = fromEvent;
              exit = 1;
            } else {
              ruleItem = {
                NAME: "EventObjectField",
                VAL: [
                  toEventId,
                  propertyId,
                  nestedPropertyId
                ]
              };
            }
            if (exit === 1) {
              var propertyIsGlobal = Belt_List.some($$event.properties, (function (property) {
                      return ModelUtils.getPropertyId(property) === propertyId;
                    }));
              ruleItem = propertyIsGlobal ? undefined : ({
                    NAME: "EventObjectField",
                    VAL: [
                      toEventId,
                      propertyId,
                      nestedPropertyId
                    ]
                  });
            }
            
          } else {
            ruleItem = item;
          }
        }
        return Belt_Option.map(ruleItem, (function (item) {
                      return {
                              id: rule.id,
                              item: item,
                              definition: rule.definition
                            };
                    }));
      };
      var convertGoal = function (goal) {
        var events = Belt_List.map(goal.events, (function (eventId) {
                if (eventId === fromEventId) {
                  return toEventId;
                } else {
                  return eventId;
                }
              }));
        return {
                id: goal.id,
                name: goal.name,
                description: goal.description,
                metrics: goal.metrics,
                events: events
              };
      };
      var convertMetric = function (metric) {
        var items = Belt_List.map(metric.items, (function (metricItem) {
                var metricEvent = metricItem.VAL;
                if (metricEvent.eventId === fromEventId) {
                  return {
                          NAME: "Event",
                          VAL: {
                            id: metricEvent.id,
                            eventId: toEventId,
                            where: metricEvent.where,
                            groupBy: metricEvent.groupBy
                          }
                        };
                } else {
                  return {
                          NAME: "Event",
                          VAL: metricEvent
                        };
                }
              }));
        return {
                id: metric.id,
                name: metric.name,
                description: metric.description,
                type_: metric.type_,
                items: items
              };
      };
      var updateProperties = function (properties, fromEventId, toEventId) {
        return Belt_List.map(properties, (function (property) {
                      if (property.TAG === /* PropertyRef */0) {
                        return property;
                      }
                      var property$1 = property._0;
                      var sometimesSent = property$1.absence;
                      var tmp;
                      if (sometimesSent !== undefined && typeof sometimesSent !== "number") {
                        if (sometimesSent.TAG === /* SometimesSent */0) {
                          tmp = sometimesSent;
                        } else {
                          var eventIdsAbsences = sometimesSent._0;
                          var absence = Belt_MapString.get(eventIdsAbsences, fromEventId);
                          if (absence !== undefined) {
                            var eventIdsAbsences$1 = Belt_MapString.set(Belt_MapString.remove(eventIdsAbsences, fromEventId), toEventId, absence);
                            tmp = {
                              TAG: /* Mixed */1,
                              _0: eventIdsAbsences$1
                            };
                          } else {
                            tmp = {
                              TAG: /* Mixed */1,
                              _0: eventIdsAbsences
                            };
                          }
                        }
                      } else {
                        tmp = /* AlwaysSent */0;
                      }
                      return {
                              TAG: /* Property */1,
                              _0: {
                                id: property$1.id,
                                name: property$1.name,
                                uniqueName: property$1.uniqueName,
                                description: property$1.description,
                                type_: property$1.type_,
                                sendAs: property$1.sendAs,
                                validations: property$1.validations,
                                eventSpecificAllowedPropertyValues: property$1.eventSpecificAllowedPropertyValues,
                                auto: property$1.auto,
                                builtIn: property$1.builtIn,
                                analyticsTools: property$1.analyticsTools,
                                devPlatforms: property$1.devPlatforms,
                                list: property$1.list,
                                operation: property$1.operation,
                                optionalDeprecated: property$1.optionalDeprecated,
                                excludedSourcesDeprecated: property$1.excludedSourcesDeprecated,
                                optionalWhenInObject: property$1.optionalWhenInObject,
                                absence: tmp,
                                globalRequirementsMetadata: property$1.globalRequirementsMetadata
                              }
                            };
                    }));
      };
      if (model.TAG === /* Old */0) {
        var model$35 = model._0;
        var rules = Belt_List.keepMap(model$35.rules, (function (rule) {
                return convertRule(rule, Belt_Option.getWithDefault(Belt_Option.map(model$35.globalRequirements, (function (requirements) {
                                      return requirements.events;
                                    })), /* [] */0));
              }));
        var goals = Belt_List.map(model$35.goals, convertGoal);
        var metrics = Belt_List.map(model$35.metrics, convertMetric);
        var updatedModel = ModelUtils.updateEvent(model$35, fromEventId, (function ($$event) {
                return {
                        id: toEventId,
                        name: $$event.name,
                        uniqueName: $$event.uniqueName,
                        description: $$event.description,
                        properties: $$event.properties,
                        propertyBundles: $$event.propertyBundles,
                        variants: $$event.variants,
                        types: $$event.types,
                        tags: $$event.tags,
                        excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                        includeSources: $$event.includeSources,
                        includeDestinations: $$event.includeDestinations,
                        hashes: $$event.hashes,
                        propertyWhitelist: $$event.propertyWhitelist,
                        eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                        userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                        triggers: $$event.triggers,
                        globalRequirementsMetadata: {
                          fromLocalItemId: fromEventId,
                          globalWorkspaceId: globalWorkspaceId$1
                        }
                      };
              }));
        var globalRequirementsEvent = Belt_Option.map(Belt_List.getBy(model$35.events, (function ($$event) {
                    return $$event.id === fromEventId;
                  })), (function ($$event) {
                return GlobalRequirementsUtils.makeGlobalRequirementsEvent({
                            id: toEventId,
                            name: $$event.name,
                            uniqueName: $$event.uniqueName,
                            description: $$event.description,
                            properties: $$event.properties,
                            propertyBundles: $$event.propertyBundles,
                            variants: $$event.variants,
                            types: $$event.types,
                            tags: $$event.tags,
                            excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                            includeSources: $$event.includeSources,
                            includeDestinations: $$event.includeDestinations,
                            hashes: $$event.hashes,
                            propertyWhitelist: $$event.propertyWhitelist,
                            eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                            userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                            triggers: $$event.triggers,
                            globalRequirementsMetadata: $$event.globalRequirementsMetadata
                          }, newGlobalEvent);
              }));
        var globalRequirements$4 = model$35.globalRequirements;
        var globalRequirements$5 = globalRequirements$4 !== undefined ? globalRequirements$4 : TrackingPlanModel.GlobalRequirements.empty(globalMetadata$1);
        var globalRequirementsWithLocalEvent = globalRequirementsEvent !== undefined ? ({
              metadata: globalRequirements$5.metadata,
              events: Belt_List.concat(globalRequirements$5.events, {
                    hd: globalRequirementsEvent,
                    tl: /* [] */0
                  }),
              properties: globalRequirements$5.properties,
              propertyBundles: globalRequirements$5.propertyBundles,
              goals: globalRequirements$5.goals,
              groupTypes: globalRequirements$5.groupTypes,
              rules: globalRequirements$5.rules
            }) : globalRequirements$5;
        return {
                TAG: /* Old */0,
                _0: {
                  types: updatedModel.types,
                  properties: updateProperties(model$35.properties, fromEventId, toEventId),
                  propertyBundles: updatedModel.propertyBundles,
                  events: updatedModel.events,
                  migrations: updatedModel.migrations,
                  sources: updatedModel.sources,
                  destinations: updatedModel.destinations,
                  groupTypes: updatedModel.groupTypes,
                  goals: goals,
                  metrics: metrics,
                  archive: updatedModel.archive,
                  openBranches: updatedModel.openBranches,
                  branchPointer: updatedModel.branchPointer,
                  rules: rules,
                  integrations: updatedModel.integrations,
                  globalRequirements: globalRequirementsWithLocalEvent
                }
              };
      }
      var model$36 = model._0;
      var globalRequirementsEvent$1 = Belt_Option.map(Curry._2(TrackingPlanMappedModel.Events.get, model$36.events, fromEventId), (function ($$event) {
              return GlobalRequirementsUtils.makeGlobalRequirementsEvent({
                          id: toEventId,
                          name: $$event.name,
                          uniqueName: $$event.uniqueName,
                          description: $$event.description,
                          properties: $$event.properties,
                          propertyBundles: $$event.propertyBundles,
                          variants: $$event.variants,
                          types: $$event.types,
                          tags: $$event.tags,
                          excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                          includeSources: $$event.includeSources,
                          includeDestinations: $$event.includeDestinations,
                          hashes: $$event.hashes,
                          propertyWhitelist: $$event.propertyWhitelist,
                          eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                          userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                          triggers: $$event.triggers,
                          globalRequirementsMetadata: $$event.globalRequirementsMetadata
                        }, newGlobalEvent);
            }));
      var globalRequirements$6 = model$36.globalRequirements;
      var globalRequirements$7 = globalRequirements$6 !== undefined ? globalRequirements$6 : TrackingPlanMappedModel.GlobalRequirements.fromOld(TrackingPlanModel.GlobalRequirements.empty(globalMetadata$1));
      var globalRequirementsWithLocalEvent$1 = globalRequirementsEvent$1 !== undefined ? ({
            metadata: globalRequirements$7.metadata,
            events: Curry._2(TrackingPlanMappedModel.Events.append, globalRequirements$7.events, globalRequirementsEvent$1),
            properties: globalRequirements$7.properties,
            propertyBundles: globalRequirements$7.propertyBundles,
            categories: globalRequirements$7.categories,
            groupTypes: globalRequirements$7.groupTypes,
            rules: globalRequirements$7.rules
          }) : globalRequirements$7;
      var convertEvent$1 = function (events, fromEventId, toEventId) {
        return Belt_Option.getWithDefault(Belt_Option.map(Curry._2(TrackingPlanMappedModel.Events.get, events, fromEventId), (function ($$event) {
                          var variants = Belt_Array.map($$event.variants, (function (variant) {
                                  var baseEventId = variant.baseEventId;
                                  if (baseEventId === fromEventId) {
                                    return {
                                            id: variant.id,
                                            baseEventId: toEventId,
                                            nameSuffix: variant.nameSuffix,
                                            description: variant.description,
                                            propertyOverrides: variant.propertyOverrides,
                                            sourceOverrides: variant.sourceOverrides,
                                            triggers: variant.triggers
                                          };
                                  } else {
                                    return variant;
                                  }
                                }));
                          var updatedEvent_name = $$event.name;
                          var updatedEvent_uniqueName = $$event.uniqueName;
                          var updatedEvent_description = $$event.description;
                          var updatedEvent_properties = $$event.properties;
                          var updatedEvent_propertyBundles = $$event.propertyBundles;
                          var updatedEvent_types = $$event.types;
                          var updatedEvent_tags = $$event.tags;
                          var updatedEvent_excludeSourcesDeprecated = $$event.excludeSourcesDeprecated;
                          var updatedEvent_includeSources = $$event.includeSources;
                          var updatedEvent_includeDestinations = $$event.includeDestinations;
                          var updatedEvent_hashes = $$event.hashes;
                          var updatedEvent_propertyWhitelist = $$event.propertyWhitelist;
                          var updatedEvent_eventGroupTypeIdsWithArchive = $$event.eventGroupTypeIdsWithArchive;
                          var updatedEvent_userGroupTypeIdsWithArchive = $$event.userGroupTypeIdsWithArchive;
                          var updatedEvent_triggers = $$event.triggers;
                          var updatedEvent_globalRequirementsMetadata = {
                            fromLocalItemId: fromEventId,
                            globalWorkspaceId: globalWorkspaceId$1
                          };
                          var updatedEvent = {
                            id: toEventId,
                            name: updatedEvent_name,
                            uniqueName: updatedEvent_uniqueName,
                            description: updatedEvent_description,
                            properties: updatedEvent_properties,
                            propertyBundles: updatedEvent_propertyBundles,
                            variants: variants,
                            types: updatedEvent_types,
                            tags: updatedEvent_tags,
                            excludeSourcesDeprecated: updatedEvent_excludeSourcesDeprecated,
                            includeSources: updatedEvent_includeSources,
                            includeDestinations: updatedEvent_includeDestinations,
                            hashes: updatedEvent_hashes,
                            propertyWhitelist: updatedEvent_propertyWhitelist,
                            eventGroupTypeIdsWithArchive: updatedEvent_eventGroupTypeIdsWithArchive,
                            userGroupTypeIdsWithArchive: updatedEvent_userGroupTypeIdsWithArchive,
                            triggers: updatedEvent_triggers,
                            globalRequirementsMetadata: updatedEvent_globalRequirementsMetadata
                          };
                          return Curry._2(TrackingPlanMappedModel.Events.append, Curry._2(TrackingPlanMappedModel.Events.remove, events, fromEventId), updatedEvent);
                        })), events);
      };
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$36.archive,
                branchPointer: model$36.branchPointer,
                migrations: model$36.migrations,
                rules: Belt_Array.keepMap(model$36.rules, (function (rule) {
                        return convertRule(rule, Belt_Option.getWithDefault(Belt_Option.map(model$36.globalRequirements, (function (requirements) {
                                              return Curry._1(TrackingPlanMappedModel.Events.toList, requirements.events);
                                            })), /* [] */0));
                      })),
                types: model$36.types,
                categories: Curry._2(TrackingPlanMappedModel.Categories.mapToT, model$36.categories, convertGoal),
                destinations: model$36.destinations,
                events: convertEvent$1(model$36.events, fromEventId, toEventId),
                groupTypes: model$36.groupTypes,
                integrations: model$36.integrations,
                metrics: Curry._2(TrackingPlanMappedModel.Metrics.mapToT, model$36.metrics, convertMetric),
                openBranches: model$36.openBranches,
                properties: Curry._1(TrackingPlanMappedModel.Properties.Utils.fromOld, updateProperties(Belt_List.map(Curry._1(TrackingPlanMappedModel.Properties.toList, model$36.properties), (function (property) {
                                return {
                                        TAG: /* Property */1,
                                        _0: property
                                      };
                              })), fromEventId, toEventId)),
                propertyBundles: model$36.propertyBundles,
                sources: model$36.sources,
                globalRequirements: globalRequirementsWithLocalEvent$1
              }
            };
    }
    if (variant === "UpdatePropertyName") {
      var match$27 = action.VAL;
      var name$2 = match$27[1];
      var propertyId$13 = match$27[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateProperty(model._0, propertyId$13, (function (property) {
                        return {
                                id: property.id,
                                name: name$2,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: property.validations,
                                eventSpecificAllowedPropertyValues: property.eventSpecificAllowedPropertyValues,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence,
                                globalRequirementsMetadata: property.globalRequirementsMetadata
                              };
                      }))
              };
      }
      var model$37 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$37.archive,
                branchPointer: model$37.branchPointer,
                migrations: model$37.migrations,
                rules: model$37.rules,
                types: model$37.types,
                categories: model$37.categories,
                destinations: model$37.destinations,
                events: model$37.events,
                groupTypes: model$37.groupTypes,
                integrations: model$37.integrations,
                metrics: model$37.metrics,
                openBranches: model$37.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.updateExisting, model$37.properties, propertyId$13, (function (property) {
                        return {
                                id: property.id,
                                name: name$2,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: property.validations,
                                eventSpecificAllowedPropertyValues: property.eventSpecificAllowedPropertyValues,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence,
                                globalRequirementsMetadata: property.globalRequirementsMetadata
                              };
                      })),
                propertyBundles: model$37.propertyBundles,
                sources: model$37.sources,
                globalRequirements: model$37.globalRequirements
              }
            };
    }
    if (variant === "UpdatePropertyDescription") {
      var match$28 = action.VAL;
      var description$1 = match$28[1];
      var propertyId$14 = match$28[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateProperty(model._0, propertyId$14, (function (property) {
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: description$1,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: property.validations,
                                eventSpecificAllowedPropertyValues: property.eventSpecificAllowedPropertyValues,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence,
                                globalRequirementsMetadata: property.globalRequirementsMetadata
                              };
                      }))
              };
      }
      var model$38 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$38.archive,
                branchPointer: model$38.branchPointer,
                migrations: model$38.migrations,
                rules: model$38.rules,
                types: model$38.types,
                categories: model$38.categories,
                destinations: model$38.destinations,
                events: model$38.events,
                groupTypes: model$38.groupTypes,
                integrations: model$38.integrations,
                metrics: model$38.metrics,
                openBranches: model$38.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.updateExisting, model$38.properties, propertyId$14, (function (property) {
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: description$1,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: property.validations,
                                eventSpecificAllowedPropertyValues: property.eventSpecificAllowedPropertyValues,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence,
                                globalRequirementsMetadata: property.globalRequirementsMetadata
                              };
                      })),
                propertyBundles: model$38.propertyBundles,
                sources: model$38.sources,
                globalRequirements: model$38.globalRequirements
              }
            };
    }
    if (variant === "AddTag") {
      var match$29 = action.VAL;
      var tag = match$29[1];
      var eventId$5 = match$29[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEvent(model._0, eventId$5, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: Belt_List.hasU($$event.tags, tag, (function (a, b) {
                                        return a === b;
                                      })) ? $$event.tags : Belt_List.concat($$event.tags, {
                                        hd: tag,
                                        tl: /* [] */0
                                      }),
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      }))
              };
      }
      var model$39 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$39.archive,
                branchPointer: model$39.branchPointer,
                migrations: model$39.migrations,
                rules: model$39.rules,
                types: model$39.types,
                categories: model$39.categories,
                destinations: model$39.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.updateExisting, model$39.events, eventId$5, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: Belt_List.hasU($$event.tags, tag, (function (a, b) {
                                        return a === b;
                                      })) ? $$event.tags : Belt_List.concat($$event.tags, {
                                        hd: tag,
                                        tl: /* [] */0
                                      }),
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      })),
                groupTypes: model$39.groupTypes,
                integrations: model$39.integrations,
                metrics: model$39.metrics,
                openBranches: model$39.openBranches,
                properties: model$39.properties,
                propertyBundles: model$39.propertyBundles,
                sources: model$39.sources,
                globalRequirements: model$39.globalRequirements
              }
            };
    }
    if (variant === "ReorderEventsInGoalV2") {
      var match$30 = action.VAL;
      var maybePredecessorId = match$30[2];
      var eventId$6 = match$30[1];
      var goalId$2 = match$30[0];
      var doesPredecessorExist = function (listWithoutItem, predecessorId) {
        return Belt_List.someU(listWithoutItem, (function (id) {
                      return Caml_obj.caml_equal(id, predecessorId);
                    }));
      };
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateGoal(model._0, goalId$2, (function (goal) {
                        var movedItem = Belt_List.getByU(goal.events, (function (id) {
                                return id === eventId$6;
                              }));
                        var listWithoutItem = Belt_List.keepU(goal.events, (function (id) {
                                return id !== eventId$6;
                              }));
                        return {
                                id: goal.id,
                                name: goal.name,
                                description: goal.description,
                                metrics: goal.metrics,
                                events: movedItem !== undefined ? (
                                    maybePredecessorId !== undefined ? (
                                        doesPredecessorExist(listWithoutItem, maybePredecessorId) ? BeltListExtensions.flatMap(listWithoutItem, (function (id) {
                                                  if (id === maybePredecessorId) {
                                                    return {
                                                            hd: id,
                                                            tl: {
                                                              hd: eventId$6,
                                                              tl: /* [] */0
                                                            }
                                                          };
                                                  } else {
                                                    return {
                                                            hd: id,
                                                            tl: /* [] */0
                                                          };
                                                  }
                                                })) : goal.events
                                      ) : Belt_List.concat({
                                            hd: eventId$6,
                                            tl: /* [] */0
                                          }, listWithoutItem)
                                  ) : goal.events
                              };
                      }))
              };
      }
      var model$40 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$40.archive,
                branchPointer: model$40.branchPointer,
                migrations: model$40.migrations,
                rules: model$40.rules,
                types: model$40.types,
                categories: Curry._3(TrackingPlanMappedModel.Categories.updateExisting, model$40.categories, goalId$2, (function (category) {
                        var movedItem = Belt_List.getByU(category.events, (function (id) {
                                return id === eventId$6;
                              }));
                        var listWithoutItem = Belt_List.keepU(category.events, (function (id) {
                                return id !== eventId$6;
                              }));
                        return {
                                id: category.id,
                                name: category.name,
                                description: category.description,
                                metrics: category.metrics,
                                events: movedItem !== undefined ? (
                                    maybePredecessorId !== undefined ? (
                                        doesPredecessorExist(listWithoutItem, maybePredecessorId) ? BeltListExtensions.flatMap(listWithoutItem, (function (id) {
                                                  if (id === maybePredecessorId) {
                                                    return {
                                                            hd: id,
                                                            tl: {
                                                              hd: eventId$6,
                                                              tl: /* [] */0
                                                            }
                                                          };
                                                  } else {
                                                    return {
                                                            hd: id,
                                                            tl: /* [] */0
                                                          };
                                                  }
                                                })) : category.events
                                      ) : Belt_List.concat({
                                            hd: eventId$6,
                                            tl: /* [] */0
                                          }, listWithoutItem)
                                  ) : category.events
                              };
                      })),
                destinations: model$40.destinations,
                events: model$40.events,
                groupTypes: model$40.groupTypes,
                integrations: model$40.integrations,
                metrics: model$40.metrics,
                openBranches: model$40.openBranches,
                properties: model$40.properties,
                propertyBundles: model$40.propertyBundles,
                sources: model$40.sources,
                globalRequirements: model$40.globalRequirements
              }
            };
    }
    if (variant === "ReorderMetricsInGoal") {
      var match$31 = action.VAL;
      var maybePredecessorId$1 = match$31[2];
      var metricId$1 = match$31[1];
      var goalId$3 = match$31[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateGoal(model._0, goalId$3, (function (goal) {
                        var movedItem = Belt_List.getByU(goal.metrics, (function (id) {
                                return id === metricId$1;
                              }));
                        var listWithoutItem = Belt_List.keepU(goal.metrics, (function (id) {
                                return id !== metricId$1;
                              }));
                        return {
                                id: goal.id,
                                name: goal.name,
                                description: goal.description,
                                metrics: movedItem !== undefined ? (
                                    maybePredecessorId$1 !== undefined ? BeltListExtensions.flatMap(listWithoutItem, (function (id) {
                                              if (id === maybePredecessorId$1) {
                                                return {
                                                        hd: id,
                                                        tl: {
                                                          hd: metricId$1,
                                                          tl: /* [] */0
                                                        }
                                                      };
                                              } else {
                                                return {
                                                        hd: id,
                                                        tl: /* [] */0
                                                      };
                                              }
                                            })) : Belt_List.concat({
                                            hd: metricId$1,
                                            tl: /* [] */0
                                          }, listWithoutItem)
                                  ) : goal.metrics,
                                events: goal.events
                              };
                      }))
              };
      }
      var model$41 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$41.archive,
                branchPointer: model$41.branchPointer,
                migrations: model$41.migrations,
                rules: model$41.rules,
                types: model$41.types,
                categories: Curry._3(TrackingPlanMappedModel.Categories.updateExisting, model$41.categories, goalId$3, (function (category) {
                        var movedItem = Belt_List.getByU(category.metrics, (function (id) {
                                return id === metricId$1;
                              }));
                        var listWithoutItem = Belt_List.keepU(category.metrics, (function (id) {
                                return id !== metricId$1;
                              }));
                        return {
                                id: category.id,
                                name: category.name,
                                description: category.description,
                                metrics: movedItem !== undefined ? (
                                    maybePredecessorId$1 !== undefined ? BeltListExtensions.flatMap(listWithoutItem, (function (id) {
                                              if (id === maybePredecessorId$1) {
                                                return {
                                                        hd: id,
                                                        tl: {
                                                          hd: metricId$1,
                                                          tl: /* [] */0
                                                        }
                                                      };
                                              } else {
                                                return {
                                                        hd: id,
                                                        tl: /* [] */0
                                                      };
                                              }
                                            })) : Belt_List.concat({
                                            hd: metricId$1,
                                            tl: /* [] */0
                                          }, listWithoutItem)
                                  ) : category.metrics,
                                events: category.events
                              };
                      })),
                destinations: model$41.destinations,
                events: model$41.events,
                groupTypes: model$41.groupTypes,
                integrations: model$41.integrations,
                metrics: model$41.metrics,
                openBranches: model$41.openBranches,
                properties: model$41.properties,
                propertyBundles: model$41.propertyBundles,
                sources: model$41.sources,
                globalRequirements: model$41.globalRequirements
              }
            };
    }
    if (variant === "UpdatePropertySendAs") {
      var match$32 = action.VAL;
      var sendAs$1 = match$32[1];
      var propertyId$15 = match$32[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateProperty(model._0, propertyId$15, (function (property) {
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: sendAs$1,
                                validations: property.validations,
                                eventSpecificAllowedPropertyValues: property.eventSpecificAllowedPropertyValues,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence,
                                globalRequirementsMetadata: property.globalRequirementsMetadata
                              };
                      }))
              };
      }
      var model$42 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$42.archive,
                branchPointer: model$42.branchPointer,
                migrations: model$42.migrations,
                rules: model$42.rules,
                types: model$42.types,
                categories: model$42.categories,
                destinations: model$42.destinations,
                events: model$42.events,
                groupTypes: model$42.groupTypes,
                integrations: model$42.integrations,
                metrics: model$42.metrics,
                openBranches: model$42.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.updateExisting, model$42.properties, propertyId$15, (function (property) {
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: sendAs$1,
                                validations: property.validations,
                                eventSpecificAllowedPropertyValues: property.eventSpecificAllowedPropertyValues,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence,
                                globalRequirementsMetadata: property.globalRequirementsMetadata
                              };
                      })),
                propertyBundles: model$42.propertyBundles,
                sources: model$42.sources,
                globalRequirements: model$42.globalRequirements
              }
            };
    }
    if (variant === "RemoveEventVariantTriggerSource") {
      var match$33 = action.VAL;
      var sourceId$4 = match$33[2];
      var triggerId$3 = match$33[1];
      var variantIdentifier$3 = match$33[0];
      var update$4 = function (trigger) {
        var sourceIds = trigger.sources;
        var tmp;
        if (sourceIds) {
          var nextSourceIds = Belt_Array.keep(sourceIds._0, (function (id) {
                  return id !== sourceId$4;
                }));
          tmp = nextSourceIds.length === 0 ? /* SourceIndependent */0 : /* SourceIds */({
                _0: nextSourceIds
              });
        } else {
          tmp = /* SourceIndependent */0;
        }
        return {
                id: trigger.id,
                sources: tmp,
                description: trigger.description,
                content: trigger.content
              };
      };
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEventVariantTrigger(model._0, variantIdentifier$3, triggerId$3, update$4)
              };
      } else {
        return {
                TAG: /* Mapped */1,
                _0: ModelUtils_mapped.updateEventVariantTrigger_mappedModel(model._0, variantIdentifier$3, triggerId$3, update$4)
              };
      }
    }
    if (variant === "AddRule") {
      var match$34 = action.VAL;
      var definition = match$34[2];
      var item = match$34[1];
      var ruleId = match$34[0];
      if (model.TAG === /* Old */0) {
        var model$43 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$43.types,
                  properties: model$43.properties,
                  propertyBundles: model$43.propertyBundles,
                  events: model$43.events,
                  migrations: model$43.migrations,
                  sources: model$43.sources,
                  destinations: model$43.destinations,
                  groupTypes: model$43.groupTypes,
                  goals: model$43.goals,
                  metrics: model$43.metrics,
                  archive: model$43.archive,
                  openBranches: model$43.openBranches,
                  branchPointer: model$43.branchPointer,
                  rules: Belt_List.concat(Belt_List.keep(model$43.rules, (function (rule) {
                              var match = rule.item;
                              if (typeof match !== "object") {
                                return true;
                              }
                              if (match.NAME !== "EventObjectField") {
                                return true;
                              }
                              if (typeof item !== "object") {
                                return true;
                              }
                              if (item.NAME !== "EventObjectField") {
                                return true;
                              }
                              var match$1 = item.VAL;
                              var match$2 = match.VAL;
                              return !(match$2[0] === match$1[0] && match$2[1] === match$1[1] && match$2[2] === match$1[2]);
                            })), {
                        hd: {
                          id: ruleId,
                          item: item,
                          definition: definition
                        },
                        tl: /* [] */0
                      }),
                  integrations: model$43.integrations,
                  globalRequirements: model$43.globalRequirements
                }
              };
      }
      var model$44 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$44.archive,
                branchPointer: model$44.branchPointer,
                migrations: model$44.migrations,
                rules: Belt_Array.concat(Belt_Array.keep(model$44.rules, (function (rule) {
                            var match = rule.item;
                            if (typeof match !== "object") {
                              return true;
                            }
                            if (match.NAME !== "EventObjectField") {
                              return true;
                            }
                            if (typeof item !== "object") {
                              return true;
                            }
                            if (item.NAME !== "EventObjectField") {
                              return true;
                            }
                            var match$1 = item.VAL;
                            var match$2 = match.VAL;
                            return !(match$2[0] === match$1[0] && match$2[1] === match$1[1] && match$2[2] === match$1[2]);
                          })), [{
                        id: ruleId,
                        item: item,
                        definition: definition
                      }]),
                types: model$44.types,
                categories: model$44.categories,
                destinations: model$44.destinations,
                events: model$44.events,
                groupTypes: model$44.groupTypes,
                integrations: model$44.integrations,
                metrics: model$44.metrics,
                openBranches: model$44.openBranches,
                properties: model$44.properties,
                propertyBundles: model$44.propertyBundles,
                sources: model$44.sources,
                globalRequirements: model$44.globalRequirements
              }
            };
    }
    if (variant === "UpdateSourceName") {
      var match$35 = action.VAL;
      var name$3 = match$35[1];
      var sourceId$5 = match$35[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateSource(model._0, sourceId$5, (function (source) {
                        return {
                                id: source.id,
                                name: name$3,
                                language: source.language,
                                platform: source.platform,
                                destinations: source.destinations,
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: source.config
                              };
                      }))
              };
      }
      var model$45 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$45.archive,
                branchPointer: model$45.branchPointer,
                migrations: model$45.migrations,
                rules: model$45.rules,
                types: model$45.types,
                categories: model$45.categories,
                destinations: model$45.destinations,
                events: model$45.events,
                groupTypes: model$45.groupTypes,
                integrations: model$45.integrations,
                metrics: model$45.metrics,
                openBranches: model$45.openBranches,
                properties: model$45.properties,
                propertyBundles: model$45.propertyBundles,
                sources: Curry._3(TrackingPlanMappedModel.Sources.updateExisting, model$45.sources, sourceId$5, (function (source) {
                        return {
                                id: source.id,
                                name: name$3,
                                language: source.language,
                                platform: source.platform,
                                destinations: source.destinations,
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: source.config
                              };
                      })),
                globalRequirements: model$45.globalRequirements
              }
            };
    }
    if (variant === "UpdatePropertyType") {
      var match$36 = action.VAL;
      var type_$1 = match$36[1];
      var propertyId$16 = match$36[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateProperty(model._0, propertyId$16, (function (property) {
                        var tmp = type_$1 === "object" ? ({
                              hd: {
                                NAME: "NestedProperty",
                                VAL: /* [] */0
                              },
                              tl: /* [] */0
                            }) : Belt_List.keepU(property.validations, (function (validation) {
                                  switch (type_$1) {
                                    case "bool" :
                                        return false;
                                    case "float" :
                                        if (typeof validation !== "object") {
                                          return false;
                                        }
                                        var variant = validation.NAME;
                                        if (!(variant === "Min" || variant === "Max")) {
                                          return false;
                                        }
                                        var match = validation.VAL;
                                        if (typeof match === "object") {
                                          return match.NAME === "FloatLit";
                                        } else {
                                          return false;
                                        }
                                    case "int" :
                                        if (typeof validation !== "object") {
                                          return false;
                                        }
                                        var variant$1 = validation.NAME;
                                        if (!(variant$1 === "Min" || variant$1 === "Max")) {
                                          return false;
                                        }
                                        var match$1 = validation.VAL;
                                        if (typeof match$1 === "object") {
                                          return match$1.NAME === "IntLit";
                                        } else {
                                          return false;
                                        }
                                    case "string" :
                                        if (typeof validation === "object") {
                                          return validation.NAME === "Matches";
                                        } else {
                                          return false;
                                        }
                                    default:
                                      return false;
                                  }
                                }));
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: type_$1,
                                sendAs: property.sendAs,
                                validations: tmp,
                                eventSpecificAllowedPropertyValues: property.eventSpecificAllowedPropertyValues,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.type_ === "object" && type_$1 !== "object" ? "Set" : property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence,
                                globalRequirementsMetadata: property.globalRequirementsMetadata
                              };
                      }))
              };
      }
      var model$46 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$46.archive,
                branchPointer: model$46.branchPointer,
                migrations: model$46.migrations,
                rules: model$46.rules,
                types: model$46.types,
                categories: model$46.categories,
                destinations: model$46.destinations,
                events: model$46.events,
                groupTypes: model$46.groupTypes,
                integrations: model$46.integrations,
                metrics: model$46.metrics,
                openBranches: model$46.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.updateExisting, model$46.properties, propertyId$16, (function (property) {
                        var tmp = type_$1 === "object" ? ({
                              hd: {
                                NAME: "NestedProperty",
                                VAL: /* [] */0
                              },
                              tl: /* [] */0
                            }) : Belt_List.keepU(property.validations, (function (validation) {
                                  switch (type_$1) {
                                    case "bool" :
                                        return false;
                                    case "float" :
                                        if (typeof validation !== "object") {
                                          return false;
                                        }
                                        var variant = validation.NAME;
                                        if (!(variant === "Min" || variant === "Max")) {
                                          return false;
                                        }
                                        var match = validation.VAL;
                                        if (typeof match === "object") {
                                          return match.NAME === "FloatLit";
                                        } else {
                                          return false;
                                        }
                                    case "int" :
                                        if (typeof validation !== "object") {
                                          return false;
                                        }
                                        var variant$1 = validation.NAME;
                                        if (!(variant$1 === "Min" || variant$1 === "Max")) {
                                          return false;
                                        }
                                        var match$1 = validation.VAL;
                                        if (typeof match$1 === "object") {
                                          return match$1.NAME === "IntLit";
                                        } else {
                                          return false;
                                        }
                                    case "string" :
                                        if (typeof validation === "object") {
                                          return validation.NAME === "Matches";
                                        } else {
                                          return false;
                                        }
                                    default:
                                      return false;
                                  }
                                }));
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: type_$1,
                                sendAs: property.sendAs,
                                validations: tmp,
                                eventSpecificAllowedPropertyValues: property.eventSpecificAllowedPropertyValues,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.type_ === "object" && type_$1 !== "object" ? "Set" : property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence,
                                globalRequirementsMetadata: property.globalRequirementsMetadata
                              };
                      })),
                propertyBundles: model$46.propertyBundles,
                sources: model$46.sources,
                globalRequirements: model$46.globalRequirements
              }
            };
    }
    if (variant === "LegacyIncludeDestinationInSource") {
      var match$37 = action.VAL;
      var eventIds = match$37[2];
      var destinationId$3 = match$37[1];
      var sourceId$6 = match$37[0];
      if (model.TAG === /* Old */0) {
        var model$47 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$47.types,
                  properties: model$47.properties,
                  propertyBundles: model$47.propertyBundles,
                  events: Belt_List.mapU(model$47.events, (function ($$event) {
                          if (!Belt_List.someU(eventIds, (function (id) {
                                    return id === $$event.id;
                                  }))) {
                            return $$event;
                          }
                          var includedDestinations = Belt_List.flatten(Belt_List.keepMapU($$event.includeDestinations, (function (includeDestination) {
                                      var match = includeDestination.VAL;
                                      if (match[0] === sourceId$6) {
                                        return match[1];
                                      }
                                      
                                    })));
                          var otherIncludedDestinations = Belt_List.keepU($$event.includeDestinations, (function (includeDestination) {
                                  return includeDestination.VAL[0] !== sourceId$6;
                                }));
                          return {
                                  id: $$event.id,
                                  name: $$event.name,
                                  uniqueName: $$event.uniqueName,
                                  description: $$event.description,
                                  properties: $$event.properties,
                                  propertyBundles: $$event.propertyBundles,
                                  variants: $$event.variants,
                                  types: $$event.types,
                                  tags: $$event.tags,
                                  excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                  includeSources: $$event.includeSources,
                                  includeDestinations: Belt_List.concat(otherIncludedDestinations, {
                                        hd: {
                                          NAME: "IncludeSourceDestination",
                                          VAL: [
                                            sourceId$6,
                                            Belt_List.concat(includedDestinations, {
                                                  hd: destinationId$3,
                                                  tl: /* [] */0
                                                })
                                          ]
                                        },
                                        tl: /* [] */0
                                      }),
                                  hashes: $$event.hashes,
                                  propertyWhitelist: $$event.propertyWhitelist,
                                  eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                  userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                  triggers: $$event.triggers,
                                  globalRequirementsMetadata: $$event.globalRequirementsMetadata
                                };
                        })),
                  migrations: model$47.migrations,
                  sources: Belt_List.mapU(model$47.sources, (function (source) {
                          if (source.id === sourceId$6) {
                            return {
                                    id: source.id,
                                    name: source.name,
                                    language: source.language,
                                    platform: source.platform,
                                    destinations: Belt_List.concat(source.destinations, {
                                          hd: {
                                            destinationId: destinationId$3,
                                            destinationMode: /* Legacy */2
                                          },
                                          tl: /* [] */0
                                        }),
                                    filename: source.filename,
                                    path: source.path,
                                    segmentDestinationOptions: source.segmentDestinationOptions,
                                    config: source.config
                                  };
                          } else {
                            return source;
                          }
                        })),
                  destinations: model$47.destinations,
                  groupTypes: model$47.groupTypes,
                  goals: model$47.goals,
                  metrics: model$47.metrics,
                  archive: model$47.archive,
                  openBranches: model$47.openBranches,
                  branchPointer: model$47.branchPointer,
                  rules: model$47.rules,
                  integrations: model$47.integrations,
                  globalRequirements: model$47.globalRequirements
                }
              };
      }
      var model$48 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$48.archive,
                branchPointer: model$48.branchPointer,
                migrations: model$48.migrations,
                rules: model$48.rules,
                types: model$48.types,
                categories: model$48.categories,
                destinations: model$48.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.updateManyExisting, model$48.events, Belt_List.toArray(eventIds), (function ($$event) {
                        var includedDestinations = Belt_List.flatten(Belt_List.keepMapU($$event.includeDestinations, (function (includeDestination) {
                                    var match = includeDestination.VAL;
                                    if (match[0] === sourceId$6) {
                                      return match[1];
                                    }
                                    
                                  })));
                        var otherIncludedDestinations = Belt_List.keepU($$event.includeDestinations, (function (includeDestination) {
                                return includeDestination.VAL[0] !== sourceId$6;
                              }));
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: Belt_List.concat(otherIncludedDestinations, {
                                      hd: {
                                        NAME: "IncludeSourceDestination",
                                        VAL: [
                                          sourceId$6,
                                          Belt_List.concat(includedDestinations, {
                                                hd: destinationId$3,
                                                tl: /* [] */0
                                              })
                                        ]
                                      },
                                      tl: /* [] */0
                                    }),
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      })),
                groupTypes: model$48.groupTypes,
                integrations: model$48.integrations,
                metrics: model$48.metrics,
                openBranches: model$48.openBranches,
                properties: model$48.properties,
                propertyBundles: model$48.propertyBundles,
                sources: Curry._3(TrackingPlanMappedModel.Sources.updateExisting, model$48.sources, sourceId$6, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: source.language,
                                platform: source.platform,
                                destinations: Belt_List.concat(source.destinations, {
                                      hd: {
                                        destinationId: destinationId$3,
                                        destinationMode: /* Legacy */2
                                      },
                                      tl: /* [] */0
                                    }),
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: source.config
                              };
                      })),
                globalRequirements: model$48.globalRequirements
              }
            };
    }
    if (variant === "AddPropertyRef") {
      var match$38 = action.VAL;
      var propertyId$17 = match$38[1];
      var eventId$7 = match$38[0];
      if (model.TAG === /* Old */0) {
        var model$49 = model._0;
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEvent(model$49, eventId$7, (function ($$event) {
                        var match = ModelUtils.resolvePropertyById(propertyId$17, model$49);
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: Belt_List.concat($$event.properties, {
                                      hd: {
                                        TAG: /* PropertyRef */0,
                                        _0: {
                                          id: propertyId$17,
                                          description: "",
                                          pinnedValue: undefined
                                        }
                                      },
                                      tl: /* [] */0
                                    }),
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: match !== undefined && typeof match.sendAs !== "number" ? BeltListExtensions.dedupeSlow({
                                        hd: "UpdateGroups",
                                        tl: $$event.types
                                      }) : $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      }))
              };
      }
      var model$50 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$50.archive,
                branchPointer: model$50.branchPointer,
                migrations: model$50.migrations,
                rules: model$50.rules,
                types: model$50.types,
                categories: model$50.categories,
                destinations: model$50.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.updateExisting, model$50.events, eventId$7, (function ($$event) {
                        var match = Curry._2(TrackingPlanMappedModel.Properties.get, model$50.properties, propertyId$17);
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: Belt_List.concat($$event.properties, {
                                      hd: {
                                        TAG: /* PropertyRef */0,
                                        _0: {
                                          id: propertyId$17,
                                          description: "",
                                          pinnedValue: undefined
                                        }
                                      },
                                      tl: /* [] */0
                                    }),
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: match !== undefined && typeof match.sendAs !== "number" ? BeltListExtensions.dedupeSlow({
                                        hd: "UpdateGroups",
                                        tl: $$event.types
                                      }) : $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      })),
                groupTypes: model$50.groupTypes,
                integrations: model$50.integrations,
                metrics: model$50.metrics,
                openBranches: model$50.openBranches,
                properties: model$50.properties,
                propertyBundles: model$50.propertyBundles,
                sources: model$50.sources,
                globalRequirements: model$50.globalRequirements
              }
            };
    }
    if (variant === "OpenBranch") {
      var match$39 = action.VAL;
      var branchName = match$39[1];
      var branchId$1 = match$39[0];
      if (model.TAG === /* Old */0) {
        var model$51 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$51.types,
                  properties: model$51.properties,
                  propertyBundles: model$51.propertyBundles,
                  events: model$51.events,
                  migrations: model$51.migrations,
                  sources: model$51.sources,
                  destinations: model$51.destinations,
                  groupTypes: model$51.groupTypes,
                  goals: model$51.goals,
                  metrics: model$51.metrics,
                  archive: model$51.archive,
                  openBranches: Belt_List.concat(model$51.openBranches, {
                        hd: [
                          branchId$1,
                          branchName
                        ],
                        tl: /* [] */0
                      }),
                  branchPointer: model$51.branchPointer,
                  rules: model$51.rules,
                  integrations: model$51.integrations,
                  globalRequirements: model$51.globalRequirements
                }
              };
      }
      var model$52 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$52.archive,
                branchPointer: model$52.branchPointer,
                migrations: model$52.migrations,
                rules: model$52.rules,
                types: model$52.types,
                categories: model$52.categories,
                destinations: model$52.destinations,
                events: model$52.events,
                groupTypes: model$52.groupTypes,
                integrations: model$52.integrations,
                metrics: model$52.metrics,
                openBranches: Curry._2(TrackingPlanMappedModel.OpenBranches.append, model$52.openBranches, [
                      branchId$1,
                      branchName
                    ]),
                properties: model$52.properties,
                propertyBundles: model$52.propertyBundles,
                sources: model$52.sources,
                globalRequirements: model$52.globalRequirements
              }
            };
    }
    if (variant === "ConvertPropertyToGlobalProperty") {
      var match$40 = action.VAL;
      var globalMetadata$2 = match$40[4];
      var newGlobalProperty = match$40[3];
      var globalWorkspaceId$2 = match$40[2];
      var toPropertyId = match$40[1];
      var fromPropertyId = match$40[0];
      var convertRule$1 = function (rule, globalRequirementEvents) {
        var ruleEventIsGlobalWithProperty = function (eventId) {
          return Belt_List.some(globalRequirementEvents, (function ($$event) {
                        if ($$event.id === eventId) {
                          return Belt_List.some($$event.properties, (function (property) {
                                        return ModelUtils.getPropertyId(property) === toPropertyId;
                                      }));
                        } else {
                          return false;
                        }
                      }));
        };
        var item = rule.item;
        var variant = item.NAME;
        var ruleItem;
        if (variant === "PropertyRef") {
          var match = item.VAL;
          if (match[1] === fromPropertyId) {
            var eventId = match[0];
            ruleItem = ruleEventIsGlobalWithProperty(eventId) ? undefined : ({
                  NAME: "PropertyRef",
                  VAL: [
                    eventId,
                    toPropertyId
                  ]
                });
          } else {
            ruleItem = item;
          }
        } else if (variant === "Property") {
          var propertyId = item.VAL;
          ruleItem = propertyId === fromPropertyId ? undefined : ({
                NAME: "Property",
                VAL: propertyId
              });
        } else if (variant === "Event") {
          ruleItem = item;
        } else {
          var match$1 = item.VAL;
          if (match$1[1] === fromPropertyId) {
            var eventId$1 = match$1[0];
            ruleItem = ruleEventIsGlobalWithProperty(eventId$1) ? undefined : ({
                  NAME: "EventObjectField",
                  VAL: [
                    eventId$1,
                    toPropertyId,
                    match$1[2]
                  ]
                });
          } else {
            ruleItem = item;
          }
        }
        if (ruleItem !== undefined) {
          return {
                  id: rule.id,
                  item: ruleItem,
                  definition: rule.definition
                };
        }
        
      };
      var convertEvent$2 = function ($$event, globalRequirementEvents) {
        var toExists = Belt_List.some($$event.properties, (function (property) {
                return ModelUtils.getPropertyId(property) === toPropertyId;
              }));
        var properties = Belt_List.keepMap($$event.properties, (function (property) {
                if (property.TAG !== /* PropertyRef */0) {
                  return {
                          TAG: /* Property */1,
                          _0: property._0
                        };
                }
                var propRef = property._0;
                if (propRef.id === fromPropertyId) {
                  if (toExists) {
                    return ;
                  } else {
                    return {
                            TAG: /* PropertyRef */0,
                            _0: {
                              id: toPropertyId,
                              description: propRef.description,
                              pinnedValue: propRef.pinnedValue
                            }
                          };
                  }
                } else {
                  return {
                          TAG: /* PropertyRef */0,
                          _0: propRef
                        };
                }
              }));
        var variants = Belt_Array.map($$event.variants, (function (variant) {
                var overrides = Belt_MapString.get(variant.propertyOverrides, fromPropertyId);
                var propertyOverrides;
                if (overrides !== undefined) {
                  var mapWithRemovedFromId = Belt_MapString.remove(variant.propertyOverrides, fromPropertyId);
                  propertyOverrides = Belt_List.some(globalRequirementEvents, (function ($$event) {
                          return $$event.id === variant.baseEventId;
                        })) ? mapWithRemovedFromId : Belt_MapString.set(mapWithRemovedFromId, toPropertyId, overrides);
                } else {
                  propertyOverrides = variant.propertyOverrides;
                }
                return {
                        id: variant.id,
                        baseEventId: variant.baseEventId,
                        nameSuffix: variant.nameSuffix,
                        description: variant.description,
                        propertyOverrides: propertyOverrides,
                        sourceOverrides: variant.sourceOverrides,
                        triggers: variant.triggers
                      };
              }));
        var propertyWhitelist = Belt_List.map($$event.propertyWhitelist, (function (param) {
                var propertyId = param[1];
                var tool = param[0];
                if (propertyId === fromPropertyId) {
                  return [
                          tool,
                          toPropertyId
                        ];
                } else {
                  return [
                          tool,
                          propertyId
                        ];
                }
              }));
        return {
                id: $$event.id,
                name: $$event.name,
                uniqueName: $$event.uniqueName,
                description: $$event.description,
                properties: properties,
                propertyBundles: $$event.propertyBundles,
                variants: variants,
                types: $$event.types,
                tags: $$event.tags,
                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                includeSources: $$event.includeSources,
                includeDestinations: $$event.includeDestinations,
                hashes: $$event.hashes,
                propertyWhitelist: propertyWhitelist,
                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                triggers: $$event.triggers,
                globalRequirementsMetadata: $$event.globalRequirementsMetadata
              };
      };
      var convertMetric$1 = function (metric) {
        var items = Belt_List.map(metric.items, (function (metricItem) {
                var metricEvent = metricItem.VAL;
                var where = Belt_List.map(metricEvent.where, (function (metricWhere) {
                        var id = metricWhere.propertyId;
                        if (id === fromPropertyId) {
                          return {
                                  id: metricWhere.id,
                                  propertyId: toPropertyId,
                                  binOp: metricWhere.binOp,
                                  literals: metricWhere.literals
                                };
                        } else {
                          return metricWhere;
                        }
                      }));
                var groupBy = Belt_List.map(metricEvent.groupBy, (function (metricGroupBy) {
                        var id = metricGroupBy.propertyId;
                        if (id === fromPropertyId) {
                          return {
                                  id: metricGroupBy.id,
                                  propertyId: toPropertyId
                                };
                        } else {
                          return metricGroupBy;
                        }
                      }));
                return {
                        NAME: "Event",
                        VAL: {
                          id: metricEvent.id,
                          eventId: metricEvent.eventId,
                          where: where,
                          groupBy: groupBy
                        }
                      };
              }));
        return {
                id: metric.id,
                name: metric.name,
                description: metric.description,
                type_: metric.type_,
                items: items
              };
      };
      var convertPropertyBundle$1 = function (bundle) {
        var toExists = Belt_List.some(bundle.properties, (function (bundlePropertyId) {
                return bundlePropertyId === toPropertyId;
              }));
        var properties = Belt_List.keepMap(bundle.properties, (function (propertyId) {
                if (propertyId === fromPropertyId) {
                  if (toExists) {
                    return ;
                  } else {
                    return toPropertyId;
                  }
                } else {
                  return propertyId;
                }
              }));
        return {
                id: bundle.id,
                name: bundle.name,
                description: bundle.description,
                properties: properties,
                globalRequirementsMetadata: bundle.globalRequirementsMetadata
              };
      };
      if (model.TAG === /* Old */0) {
        var model$53 = model._0;
        var globalRequirements$8 = model$53.globalRequirements;
        var globalRequirements$9 = globalRequirements$8 !== undefined ? globalRequirements$8 : TrackingPlanModel.GlobalRequirements.empty(globalMetadata$2);
        var globalRequirements_metadata = globalRequirements$9.metadata;
        var globalRequirements_events = globalRequirements$9.events;
        var globalRequirements_properties = Belt_List.concat(globalRequirements$9.properties, {
              hd: {
                TAG: /* Property */1,
                _0: {
                  id: newGlobalProperty.id,
                  name: newGlobalProperty.name,
                  uniqueName: newGlobalProperty.uniqueName,
                  description: newGlobalProperty.description,
                  type_: newGlobalProperty.type_,
                  sendAs: newGlobalProperty.sendAs,
                  validations: newGlobalProperty.validations,
                  eventSpecificAllowedPropertyValues: newGlobalProperty.eventSpecificAllowedPropertyValues,
                  auto: newGlobalProperty.auto,
                  builtIn: newGlobalProperty.builtIn,
                  analyticsTools: newGlobalProperty.analyticsTools,
                  devPlatforms: newGlobalProperty.devPlatforms,
                  list: newGlobalProperty.list,
                  operation: newGlobalProperty.operation,
                  optionalDeprecated: newGlobalProperty.optionalDeprecated,
                  excludedSourcesDeprecated: newGlobalProperty.excludedSourcesDeprecated,
                  optionalWhenInObject: newGlobalProperty.optionalWhenInObject,
                  absence: /* AlwaysSent */0,
                  globalRequirementsMetadata: newGlobalProperty.globalRequirementsMetadata
                }
              },
              tl: /* [] */0
            });
        var globalRequirements_propertyBundles = globalRequirements$9.propertyBundles;
        var globalRequirements_goals = globalRequirements$9.goals;
        var globalRequirements_groupTypes = globalRequirements$9.groupTypes;
        var globalRequirements_rules = globalRequirements$9.rules;
        var globalRequirements$10 = {
          metadata: globalRequirements_metadata,
          events: globalRequirements_events,
          properties: globalRequirements_properties,
          propertyBundles: globalRequirements_propertyBundles,
          goals: globalRequirements_goals,
          groupTypes: globalRequirements_groupTypes,
          rules: globalRequirements_rules
        };
        var init$1 = ModelUtils.updateProperty(model$53, fromPropertyId, (function (property) {
                return {
                        id: toPropertyId,
                        name: property.name,
                        uniqueName: property.uniqueName,
                        description: property.description,
                        type_: property.type_,
                        sendAs: property.sendAs,
                        validations: property.validations,
                        eventSpecificAllowedPropertyValues: property.eventSpecificAllowedPropertyValues,
                        auto: property.auto,
                        builtIn: property.builtIn,
                        analyticsTools: property.analyticsTools,
                        devPlatforms: property.devPlatforms,
                        list: property.list,
                        operation: property.operation,
                        optionalDeprecated: property.optionalDeprecated,
                        excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                        optionalWhenInObject: property.optionalWhenInObject,
                        absence: property.absence,
                        globalRequirementsMetadata: {
                          fromLocalItemId: fromPropertyId,
                          globalWorkspaceId: globalWorkspaceId$2
                        }
                      };
              }));
        return {
                TAG: /* Old */0,
                _0: {
                  types: init$1.types,
                  properties: init$1.properties,
                  propertyBundles: Belt_List.map(model$53.propertyBundles, convertPropertyBundle$1),
                  events: Belt_List.map(model$53.events, (function ($$event) {
                          return convertEvent$2($$event, Belt_Option.getWithDefault(Belt_Option.map(model$53.globalRequirements, (function (globalRequirements) {
                                                return globalRequirements.events;
                                              })), /* [] */0));
                        })),
                  migrations: init$1.migrations,
                  sources: init$1.sources,
                  destinations: init$1.destinations,
                  groupTypes: init$1.groupTypes,
                  goals: init$1.goals,
                  metrics: Belt_List.map(model$53.metrics, convertMetric$1),
                  archive: init$1.archive,
                  openBranches: init$1.openBranches,
                  branchPointer: init$1.branchPointer,
                  rules: Belt_List.keepMap(model$53.rules, (function (rule) {
                          return convertRule$1(rule, Belt_Option.getWithDefault(Belt_Option.map(model$53.globalRequirements, (function (globalRequirements) {
                                                return globalRequirements.events;
                                              })), /* [] */0));
                        })),
                  integrations: init$1.integrations,
                  globalRequirements: globalRequirements$10
                }
              };
      }
      var model$54 = model._0;
      var globalRequirements$11 = model$54.globalRequirements;
      var globalRequirements$12 = globalRequirements$11 !== undefined ? globalRequirements$11 : TrackingPlanMappedModel.GlobalRequirements.fromOld(TrackingPlanModel.GlobalRequirements.empty(globalMetadata$2));
      var globalRequirements_metadata$1 = globalRequirements$12.metadata;
      var globalRequirements_events$1 = globalRequirements$12.events;
      var globalRequirements_properties$1 = Curry._2(TrackingPlanMappedModel.Properties.append, globalRequirements$12.properties, {
            id: newGlobalProperty.id,
            name: newGlobalProperty.name,
            uniqueName: newGlobalProperty.uniqueName,
            description: newGlobalProperty.description,
            type_: newGlobalProperty.type_,
            sendAs: newGlobalProperty.sendAs,
            validations: newGlobalProperty.validations,
            eventSpecificAllowedPropertyValues: newGlobalProperty.eventSpecificAllowedPropertyValues,
            auto: newGlobalProperty.auto,
            builtIn: newGlobalProperty.builtIn,
            analyticsTools: newGlobalProperty.analyticsTools,
            devPlatforms: newGlobalProperty.devPlatforms,
            list: newGlobalProperty.list,
            operation: newGlobalProperty.operation,
            optionalDeprecated: newGlobalProperty.optionalDeprecated,
            excludedSourcesDeprecated: newGlobalProperty.excludedSourcesDeprecated,
            optionalWhenInObject: newGlobalProperty.optionalWhenInObject,
            absence: /* AlwaysSent */0,
            globalRequirementsMetadata: newGlobalProperty.globalRequirementsMetadata
          });
      var globalRequirements_propertyBundles$1 = globalRequirements$12.propertyBundles;
      var globalRequirements_categories = globalRequirements$12.categories;
      var globalRequirements_groupTypes$1 = globalRequirements$12.groupTypes;
      var globalRequirements_rules$1 = globalRequirements$12.rules;
      var globalRequirements$13 = {
        metadata: globalRequirements_metadata$1,
        events: globalRequirements_events$1,
        properties: globalRequirements_properties$1,
        propertyBundles: globalRequirements_propertyBundles$1,
        categories: globalRequirements_categories,
        groupTypes: globalRequirements_groupTypes$1,
        rules: globalRequirements_rules$1
      };
      var convertProperty = function (properties, fromPropertyId, globalProperty) {
        return Belt_Option.getWithDefault(Belt_Option.map(Curry._2(TrackingPlanMappedModel.Properties.get, properties, fromPropertyId), (function (property) {
                          var updatedProperty_id = globalProperty.id;
                          var updatedProperty_name = globalProperty.name;
                          var updatedProperty_uniqueName = globalProperty.uniqueName;
                          var updatedProperty_description = globalProperty.description;
                          var updatedProperty_type_ = globalProperty.type_;
                          var updatedProperty_sendAs = globalProperty.sendAs;
                          var updatedProperty_validations = globalProperty.validations;
                          var updatedProperty_eventSpecificAllowedPropertyValues = globalProperty.eventSpecificAllowedPropertyValues;
                          var updatedProperty_auto = globalProperty.auto;
                          var updatedProperty_builtIn = globalProperty.builtIn;
                          var updatedProperty_analyticsTools = globalProperty.analyticsTools;
                          var updatedProperty_devPlatforms = globalProperty.devPlatforms;
                          var updatedProperty_list = globalProperty.list;
                          var updatedProperty_operation = globalProperty.operation;
                          var updatedProperty_optionalDeprecated = globalProperty.optionalDeprecated;
                          var updatedProperty_excludedSourcesDeprecated = globalProperty.excludedSourcesDeprecated;
                          var updatedProperty_optionalWhenInObject = globalProperty.optionalWhenInObject;
                          var updatedProperty_absence = property.absence;
                          var updatedProperty_globalRequirementsMetadata = {
                            fromLocalItemId: fromPropertyId,
                            globalWorkspaceId: globalWorkspaceId$2
                          };
                          var updatedProperty = {
                            id: updatedProperty_id,
                            name: updatedProperty_name,
                            uniqueName: updatedProperty_uniqueName,
                            description: updatedProperty_description,
                            type_: updatedProperty_type_,
                            sendAs: updatedProperty_sendAs,
                            validations: updatedProperty_validations,
                            eventSpecificAllowedPropertyValues: updatedProperty_eventSpecificAllowedPropertyValues,
                            auto: updatedProperty_auto,
                            builtIn: updatedProperty_builtIn,
                            analyticsTools: updatedProperty_analyticsTools,
                            devPlatforms: updatedProperty_devPlatforms,
                            list: updatedProperty_list,
                            operation: updatedProperty_operation,
                            optionalDeprecated: updatedProperty_optionalDeprecated,
                            excludedSourcesDeprecated: updatedProperty_excludedSourcesDeprecated,
                            optionalWhenInObject: updatedProperty_optionalWhenInObject,
                            absence: updatedProperty_absence,
                            globalRequirementsMetadata: updatedProperty_globalRequirementsMetadata
                          };
                          return Curry._2(TrackingPlanMappedModel.Properties.append, Curry._2(TrackingPlanMappedModel.Properties.remove, properties, fromPropertyId), updatedProperty);
                        })), properties);
      };
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$54.archive,
                branchPointer: model$54.branchPointer,
                migrations: model$54.migrations,
                rules: Belt_Array.keepMap(model$54.rules, (function (rule) {
                        return convertRule$1(rule, Belt_Option.getWithDefault(Belt_Option.map(model$54.globalRequirements, (function (globalRequirements) {
                                              return Curry._1(TrackingPlanMappedModel.Events.toList, globalRequirements.events);
                                            })), /* [] */0));
                      })),
                types: model$54.types,
                categories: model$54.categories,
                destinations: model$54.destinations,
                events: Curry._2(TrackingPlanMappedModel.Events.mapToT, model$54.events, (function ($$event) {
                        return convertEvent$2($$event, Belt_Option.getWithDefault(Belt_Option.map(model$54.globalRequirements, (function (globalRequirements) {
                                              return Curry._1(TrackingPlanMappedModel.Events.toList, globalRequirements.events);
                                            })), /* [] */0));
                      })),
                groupTypes: model$54.groupTypes,
                integrations: model$54.integrations,
                metrics: Curry._2(TrackingPlanMappedModel.Metrics.mapToT, model$54.metrics, convertMetric$1),
                openBranches: model$54.openBranches,
                properties: convertProperty(model$54.properties, fromPropertyId, newGlobalProperty),
                propertyBundles: Curry._2(TrackingPlanMappedModel.PropertyBundles.mapToT, model$54.propertyBundles, convertPropertyBundle$1),
                sources: model$54.sources,
                globalRequirements: globalRequirements$13
              }
            };
    }
    if (variant === "RemoveEvent") {
      var eventId$8 = action.VAL;
      if (model.TAG === /* Old */0) {
        var model$55 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$55.types,
                  properties: model$55.properties,
                  propertyBundles: model$55.propertyBundles,
                  events: Belt_List.keepU(model$55.events, (function ($$event) {
                          return $$event.id !== eventId$8;
                        })),
                  migrations: model$55.migrations,
                  sources: model$55.sources,
                  destinations: model$55.destinations,
                  groupTypes: model$55.groupTypes,
                  goals: model$55.goals,
                  metrics: model$55.metrics,
                  archive: model$55.archive,
                  openBranches: model$55.openBranches,
                  branchPointer: model$55.branchPointer,
                  rules: model$55.rules,
                  integrations: model$55.integrations,
                  globalRequirements: model$55.globalRequirements
                }
              };
      }
      var model$56 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$56.archive,
                branchPointer: model$56.branchPointer,
                migrations: model$56.migrations,
                rules: model$56.rules,
                types: model$56.types,
                categories: model$56.categories,
                destinations: model$56.destinations,
                events: Curry._2(TrackingPlanMappedModel.Events.remove, model$56.events, eventId$8),
                groupTypes: model$56.groupTypes,
                integrations: model$56.integrations,
                metrics: model$56.metrics,
                openBranches: model$56.openBranches,
                properties: model$56.properties,
                propertyBundles: model$56.propertyBundles,
                sources: model$56.sources,
                globalRequirements: model$56.globalRequirements
              }
            };
    }
    if (variant === "ReorderEventsInGoal") {
      var match$41 = action.VAL;
      var maybePredecessorId$2 = match$41[2];
      var eventId$9 = match$41[1];
      var goalId$4 = match$41[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateGoal(model._0, goalId$4, (function (goal) {
                        var movedItem = Belt_List.getByU(goal.events, (function (id) {
                                return id === eventId$9;
                              }));
                        var listWithoutItem = Belt_List.keepU(goal.events, (function (id) {
                                return id !== eventId$9;
                              }));
                        return {
                                id: goal.id,
                                name: goal.name,
                                description: goal.description,
                                metrics: goal.metrics,
                                events: movedItem !== undefined ? (
                                    maybePredecessorId$2 !== undefined ? BeltListExtensions.flatMap(listWithoutItem, (function (id) {
                                              if (id === maybePredecessorId$2) {
                                                return {
                                                        hd: id,
                                                        tl: {
                                                          hd: eventId$9,
                                                          tl: /* [] */0
                                                        }
                                                      };
                                              } else {
                                                return {
                                                        hd: id,
                                                        tl: /* [] */0
                                                      };
                                              }
                                            })) : Belt_List.concat({
                                            hd: eventId$9,
                                            tl: /* [] */0
                                          }, listWithoutItem)
                                  ) : goal.events
                              };
                      }))
              };
      }
      var model$57 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$57.archive,
                branchPointer: model$57.branchPointer,
                migrations: model$57.migrations,
                rules: model$57.rules,
                types: model$57.types,
                categories: Curry._3(TrackingPlanMappedModel.Categories.updateExisting, model$57.categories, goalId$4, (function (category) {
                        var movedItem = Belt_List.getByU(category.events, (function (id) {
                                return id === eventId$9;
                              }));
                        var listWithoutItem = Belt_List.keepU(category.events, (function (id) {
                                return id !== eventId$9;
                              }));
                        return {
                                id: category.id,
                                name: category.name,
                                description: category.description,
                                metrics: category.metrics,
                                events: movedItem !== undefined ? (
                                    maybePredecessorId$2 !== undefined ? BeltListExtensions.flatMap(listWithoutItem, (function (id) {
                                              if (id === maybePredecessorId$2) {
                                                return {
                                                        hd: id,
                                                        tl: {
                                                          hd: eventId$9,
                                                          tl: /* [] */0
                                                        }
                                                      };
                                              } else {
                                                return {
                                                        hd: id,
                                                        tl: /* [] */0
                                                      };
                                              }
                                            })) : Belt_List.concat({
                                            hd: eventId$9,
                                            tl: /* [] */0
                                          }, listWithoutItem)
                                  ) : category.events
                              };
                      })),
                destinations: model$57.destinations,
                events: model$57.events,
                groupTypes: model$57.groupTypes,
                integrations: model$57.integrations,
                metrics: model$57.metrics,
                openBranches: model$57.openBranches,
                properties: model$57.properties,
                propertyBundles: model$57.propertyBundles,
                sources: model$57.sources,
                globalRequirements: model$57.globalRequirements
              }
            };
    }
    if (variant === "CreateEventVariantTrigger") {
      var match$42 = action.VAL;
      var variantIdentifier$4 = match$42[0];
      var newTrigger_id = match$42[1];
      var newTrigger_sources = match$42[2];
      var newTrigger_description = match$42[3];
      var newTrigger_content = match$42[4];
      var newTrigger = {
        id: newTrigger_id,
        sources: newTrigger_sources,
        description: newTrigger_description,
        content: newTrigger_content
      };
      var update$5 = function (eventVariant) {
        return {
                id: eventVariant.id,
                baseEventId: eventVariant.baseEventId,
                nameSuffix: eventVariant.nameSuffix,
                description: eventVariant.description,
                propertyOverrides: eventVariant.propertyOverrides,
                sourceOverrides: eventVariant.sourceOverrides,
                triggers: Belt_Array.concat(eventVariant.triggers, [newTrigger])
              };
      };
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEventVariant(model._0, variantIdentifier$4, update$5)
              };
      }
      var model$58 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$58.archive,
                branchPointer: model$58.branchPointer,
                migrations: model$58.migrations,
                rules: model$58.rules,
                types: model$58.types,
                categories: model$58.categories,
                destinations: model$58.destinations,
                events: ModelUtils_mapped.updateExistingVariant(model$58.events, variantIdentifier$4, update$5),
                groupTypes: model$58.groupTypes,
                integrations: model$58.integrations,
                metrics: model$58.metrics,
                openBranches: model$58.openBranches,
                properties: model$58.properties,
                propertyBundles: model$58.propertyBundles,
                sources: model$58.sources,
                globalRequirements: model$58.globalRequirements
              }
            };
    }
    if (variant === "DeleteLocalWorkspace") {
      var localWorkspaceId = action.VAL;
      if (model.TAG === /* Old */0) {
        var model$59 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$59.types,
                  properties: model$59.properties,
                  propertyBundles: model$59.propertyBundles,
                  events: Belt_List.mapU(model$59.events, (function ($$event) {
                          return {
                                  id: $$event.id,
                                  name: $$event.name,
                                  uniqueName: $$event.uniqueName,
                                  description: $$event.description,
                                  properties: $$event.properties,
                                  propertyBundles: $$event.propertyBundles,
                                  variants: $$event.variants,
                                  types: $$event.types,
                                  tags: $$event.tags,
                                  excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                  includeSources: Belt_List.keepU($$event.includeSources, (function (includedSource) {
                                          return localWorkspaceId !== includedSource.id;
                                        })),
                                  includeDestinations: $$event.includeDestinations,
                                  hashes: $$event.hashes,
                                  propertyWhitelist: $$event.propertyWhitelist,
                                  eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                  userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                  triggers: $$event.triggers,
                                  globalRequirementsMetadata: $$event.globalRequirementsMetadata
                                };
                        })),
                  migrations: model$59.migrations,
                  sources: Belt_List.keepU(model$59.sources, (function (source) {
                          return source.id !== localWorkspaceId;
                        })),
                  destinations: model$59.destinations,
                  groupTypes: model$59.groupTypes,
                  goals: model$59.goals,
                  metrics: model$59.metrics,
                  archive: model$59.archive,
                  openBranches: model$59.openBranches,
                  branchPointer: model$59.branchPointer,
                  rules: model$59.rules,
                  integrations: model$59.integrations,
                  globalRequirements: model$59.globalRequirements
                }
              };
      }
      var model$60 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$60.archive,
                branchPointer: model$60.branchPointer,
                migrations: model$60.migrations,
                rules: model$60.rules,
                types: model$60.types,
                categories: model$60.categories,
                destinations: model$60.destinations,
                events: Curry._2(TrackingPlanMappedModel.Events.mapToT, model$60.events, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: Belt_List.keepU($$event.includeSources, (function (includedSource) {
                                        return localWorkspaceId !== includedSource.id;
                                      })),
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      })),
                groupTypes: model$60.groupTypes,
                integrations: model$60.integrations,
                metrics: model$60.metrics,
                openBranches: model$60.openBranches,
                properties: model$60.properties,
                propertyBundles: model$60.propertyBundles,
                sources: Curry._2(TrackingPlanMappedModel.Sources.remove, model$60.sources, localWorkspaceId),
                globalRequirements: model$60.globalRequirements
              }
            };
    }
    if (variant === "ExcludeEventFromSourceV2") {
      var match$43 = action.VAL;
      var sourceId$7 = match$43[1];
      var eventId$10 = match$43[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEvent(model._0, eventId$10, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: Belt_List.keepU($$event.includeSources, (function (includedSource) {
                                        return includedSource.id !== sourceId$7;
                                      })),
                                includeDestinations: Belt_List.keepU($$event.includeDestinations, (function (includeDestination) {
                                        return includeDestination.VAL[0] !== sourceId$7;
                                      })),
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      }))
              };
      }
      var model$61 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$61.archive,
                branchPointer: model$61.branchPointer,
                migrations: model$61.migrations,
                rules: model$61.rules,
                types: model$61.types,
                categories: model$61.categories,
                destinations: model$61.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.updateExisting, model$61.events, eventId$10, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: Belt_List.keepU($$event.includeSources, (function (includedSource) {
                                        return includedSource.id !== sourceId$7;
                                      })),
                                includeDestinations: Belt_List.keepU($$event.includeDestinations, (function (includeDestination) {
                                        return includeDestination.VAL[0] !== sourceId$7;
                                      })),
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      })),
                groupTypes: model$61.groupTypes,
                integrations: model$61.integrations,
                metrics: model$61.metrics,
                openBranches: model$61.openBranches,
                properties: model$61.properties,
                propertyBundles: model$61.propertyBundles,
                sources: model$61.sources,
                globalRequirements: model$61.globalRequirements
              }
            };
    }
    if (variant === "CreateGroupType") {
      var match$44 = action.VAL;
      var newGroupType_id = match$44[0];
      var newGroupType_name = match$44[1];
      var newGroupType_description = match$44[2];
      var newGroupType = {
        id: newGroupType_id,
        name: newGroupType_name,
        description: newGroupType_description
      };
      if (model.TAG === /* Old */0) {
        var model$62 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$62.types,
                  properties: model$62.properties,
                  propertyBundles: model$62.propertyBundles,
                  events: model$62.events,
                  migrations: model$62.migrations,
                  sources: model$62.sources,
                  destinations: model$62.destinations,
                  groupTypes: Belt_Array.concat(model$62.groupTypes, [newGroupType]),
                  goals: model$62.goals,
                  metrics: model$62.metrics,
                  archive: model$62.archive,
                  openBranches: model$62.openBranches,
                  branchPointer: model$62.branchPointer,
                  rules: model$62.rules,
                  integrations: model$62.integrations,
                  globalRequirements: model$62.globalRequirements
                }
              };
      }
      var model$63 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$63.archive,
                branchPointer: model$63.branchPointer,
                migrations: model$63.migrations,
                rules: model$63.rules,
                types: model$63.types,
                categories: model$63.categories,
                destinations: model$63.destinations,
                events: model$63.events,
                groupTypes: Curry._2(TrackingPlanMappedModel.GroupTypes.append, model$63.groupTypes, newGroupType),
                integrations: model$63.integrations,
                metrics: model$63.metrics,
                openBranches: model$63.openBranches,
                properties: model$63.properties,
                propertyBundles: model$63.propertyBundles,
                sources: model$63.sources,
                globalRequirements: model$63.globalRequirements
              }
            };
    }
    if (variant === "LegacyToggleDestination") {
      var match$45 = action.VAL;
      var isActive$1 = match$45[2];
      var destinationId$4 = match$45[1];
      var sourceId$8 = match$45[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateSource(model._0, sourceId$8, (function (source) {
                        var hasDestination = Belt_List.someU(source.destinations, (function (sourceDestination) {
                                return sourceDestination.destinationId === destinationId$4;
                              }));
                        return {
                                id: source.id,
                                name: source.name,
                                language: source.language,
                                platform: source.platform,
                                destinations: hasDestination ? (
                                    isActive$1 ? source.destinations : Belt_List.keepU(source.destinations, (function (sourceDestination) {
                                              return sourceDestination.destinationId !== destinationId$4;
                                            }))
                                  ) : (
                                    isActive$1 ? Belt_List.concat(source.destinations, {
                                            hd: {
                                              destinationId: destinationId$4,
                                              destinationMode: /* Legacy */2
                                            },
                                            tl: /* [] */0
                                          }) : source.destinations
                                  ),
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: source.config
                              };
                      }))
              };
      }
      var model$64 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$64.archive,
                branchPointer: model$64.branchPointer,
                migrations: model$64.migrations,
                rules: model$64.rules,
                types: model$64.types,
                categories: model$64.categories,
                destinations: model$64.destinations,
                events: model$64.events,
                groupTypes: model$64.groupTypes,
                integrations: model$64.integrations,
                metrics: model$64.metrics,
                openBranches: model$64.openBranches,
                properties: model$64.properties,
                propertyBundles: model$64.propertyBundles,
                sources: Curry._3(TrackingPlanMappedModel.Sources.updateExisting, model$64.sources, sourceId$8, (function (source) {
                        var hasDestination = Belt_List.someU(source.destinations, (function (sourceDestination) {
                                return sourceDestination.destinationId === destinationId$4;
                              }));
                        return {
                                id: source.id,
                                name: source.name,
                                language: source.language,
                                platform: source.platform,
                                destinations: hasDestination ? (
                                    isActive$1 ? source.destinations : Belt_List.keepU(source.destinations, (function (sourceDestination) {
                                              return sourceDestination.destinationId !== destinationId$4;
                                            }))
                                  ) : (
                                    isActive$1 ? Belt_List.concat(source.destinations, {
                                            hd: {
                                              destinationId: destinationId$4,
                                              destinationMode: /* Legacy */2
                                            },
                                            tl: /* [] */0
                                          }) : source.destinations
                                  ),
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: source.config
                              };
                      })),
                globalRequirements: model$64.globalRequirements
              }
            };
    }
    if (variant === "ClearSourceOverrideOnVariant") {
      var match$46 = action.VAL;
      var sourceId$9 = match$46[1];
      var variantIdentifier$5 = match$46[0];
      var clearSourceOverrideOnVariant = function (variant) {
        return {
                id: variant.id,
                baseEventId: variant.baseEventId,
                nameSuffix: variant.nameSuffix,
                description: variant.description,
                propertyOverrides: variant.propertyOverrides,
                sourceOverrides: Belt_MapString.remove(variant.sourceOverrides, sourceId$9),
                triggers: variant.triggers
              };
      };
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEventVariant(model._0, variantIdentifier$5, clearSourceOverrideOnVariant)
              };
      }
      var model$65 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$65.archive,
                branchPointer: model$65.branchPointer,
                migrations: model$65.migrations,
                rules: model$65.rules,
                types: model$65.types,
                categories: model$65.categories,
                destinations: model$65.destinations,
                events: ModelUtils_mapped.updateExistingVariant(model$65.events, variantIdentifier$5, clearSourceOverrideOnVariant),
                groupTypes: model$65.groupTypes,
                integrations: model$65.integrations,
                metrics: model$65.metrics,
                openBranches: model$65.openBranches,
                properties: model$65.properties,
                propertyBundles: model$65.propertyBundles,
                sources: model$65.sources,
                globalRequirements: model$65.globalRequirements
              }
            };
    }
    if (variant === "DeleteSource") {
      var sourceId$10 = action.VAL;
      var updateEvent = function ($$event) {
        return {
                id: $$event.id,
                name: $$event.name,
                uniqueName: $$event.uniqueName,
                description: $$event.description,
                properties: $$event.properties,
                propertyBundles: $$event.propertyBundles,
                variants: $$event.variants,
                types: $$event.types,
                tags: $$event.tags,
                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                includeSources: Belt_List.keepU($$event.includeSources, (function (includedSource) {
                        return sourceId$10 !== includedSource.id;
                      })),
                includeDestinations: $$event.includeDestinations,
                hashes: $$event.hashes,
                propertyWhitelist: $$event.propertyWhitelist,
                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                triggers: $$event.triggers,
                globalRequirementsMetadata: $$event.globalRequirementsMetadata
              };
      };
      if (model.TAG === /* Old */0) {
        var model$66 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$66.types,
                  properties: model$66.properties,
                  propertyBundles: model$66.propertyBundles,
                  events: Belt_List.mapU(model$66.events, updateEvent),
                  migrations: model$66.migrations,
                  sources: Belt_List.keepU(model$66.sources, (function (source) {
                          return source.id !== sourceId$10;
                        })),
                  destinations: model$66.destinations,
                  groupTypes: model$66.groupTypes,
                  goals: model$66.goals,
                  metrics: model$66.metrics,
                  archive: model$66.archive,
                  openBranches: model$66.openBranches,
                  branchPointer: model$66.branchPointer,
                  rules: model$66.rules,
                  integrations: model$66.integrations,
                  globalRequirements: model$66.globalRequirements
                }
              };
      }
      var model$67 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$67.archive,
                branchPointer: model$67.branchPointer,
                migrations: model$67.migrations,
                rules: model$67.rules,
                types: model$67.types,
                categories: model$67.categories,
                destinations: model$67.destinations,
                events: Curry._2(TrackingPlanMappedModel.Events.mapToT, model$67.events, updateEvent),
                groupTypes: model$67.groupTypes,
                integrations: model$67.integrations,
                metrics: model$67.metrics,
                openBranches: model$67.openBranches,
                properties: model$67.properties,
                propertyBundles: model$67.propertyBundles,
                sources: Curry._2(TrackingPlanMappedModel.Sources.remove, model$67.sources, sourceId$10),
                globalRequirements: model$67.globalRequirements
              }
            };
    }
    if (variant === "ReorderMetricsInGoalV2") {
      var match$47 = action.VAL;
      var maybePredecessorId$3 = match$47[2];
      var metricId$2 = match$47[1];
      var goalId$5 = match$47[0];
      var doesPredecessorExist$1 = function (listWithoutItem, predecessorId) {
        return Belt_List.someU(listWithoutItem, (function (id) {
                      return Caml_obj.caml_equal(id, predecessorId);
                    }));
      };
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateGoal(model._0, goalId$5, (function (goal) {
                        var movedItem = Belt_List.getByU(goal.metrics, (function (id) {
                                return id === metricId$2;
                              }));
                        var listWithoutItem = Belt_List.keepU(goal.metrics, (function (id) {
                                return id !== metricId$2;
                              }));
                        return {
                                id: goal.id,
                                name: goal.name,
                                description: goal.description,
                                metrics: movedItem !== undefined ? (
                                    maybePredecessorId$3 !== undefined ? (
                                        doesPredecessorExist$1(listWithoutItem, maybePredecessorId$3) ? BeltListExtensions.flatMap(listWithoutItem, (function (id) {
                                                  if (id === maybePredecessorId$3) {
                                                    return {
                                                            hd: id,
                                                            tl: {
                                                              hd: metricId$2,
                                                              tl: /* [] */0
                                                            }
                                                          };
                                                  } else {
                                                    return {
                                                            hd: id,
                                                            tl: /* [] */0
                                                          };
                                                  }
                                                })) : goal.metrics
                                      ) : Belt_List.concat({
                                            hd: metricId$2,
                                            tl: /* [] */0
                                          }, listWithoutItem)
                                  ) : goal.metrics,
                                events: goal.events
                              };
                      }))
              };
      }
      var model$68 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$68.archive,
                branchPointer: model$68.branchPointer,
                migrations: model$68.migrations,
                rules: model$68.rules,
                types: model$68.types,
                categories: Curry._3(TrackingPlanMappedModel.Categories.updateExisting, model$68.categories, goalId$5, (function (category) {
                        var movedItem = Belt_List.getByU(category.metrics, (function (id) {
                                return id === metricId$2;
                              }));
                        var listWithoutItem = Belt_List.keepU(category.metrics, (function (id) {
                                return id !== metricId$2;
                              }));
                        return {
                                id: category.id,
                                name: category.name,
                                description: category.description,
                                metrics: movedItem !== undefined ? (
                                    maybePredecessorId$3 !== undefined ? (
                                        doesPredecessorExist$1(listWithoutItem, maybePredecessorId$3) ? BeltListExtensions.flatMap(listWithoutItem, (function (id) {
                                                  if (id === maybePredecessorId$3) {
                                                    return {
                                                            hd: id,
                                                            tl: {
                                                              hd: metricId$2,
                                                              tl: /* [] */0
                                                            }
                                                          };
                                                  } else {
                                                    return {
                                                            hd: id,
                                                            tl: /* [] */0
                                                          };
                                                  }
                                                })) : category.metrics
                                      ) : Belt_List.concat({
                                            hd: metricId$2,
                                            tl: /* [] */0
                                          }, listWithoutItem)
                                  ) : category.metrics,
                                events: category.events
                              };
                      })),
                destinations: model$68.destinations,
                events: model$68.events,
                groupTypes: model$68.groupTypes,
                integrations: model$68.integrations,
                metrics: model$68.metrics,
                openBranches: model$68.openBranches,
                properties: model$68.properties,
                propertyBundles: model$68.propertyBundles,
                sources: model$68.sources,
                globalRequirements: model$68.globalRequirements
              }
            };
    }
    if (variant === "ReorderEventsInMetricV2") {
      var match$48 = action.VAL;
      var maybePredecessorId$4 = match$48[2];
      var metricItemId$1 = match$48[1];
      var metricId$3 = match$48[0];
      var doesPredecessorExist$2 = function (listOfMetricItems, predecessorId) {
        return Belt_List.someU(listOfMetricItems, (function (metricItem) {
                      return metricItem.VAL.id === predecessorId;
                    }));
      };
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateMetric(model._0, metricId$3, (function (metric) {
                        var movedItem = Belt_List.getByU(metric.items, (function (metricItem) {
                                return metricItem.VAL.id === metricItemId$1;
                              }));
                        var listWithoutItem = Belt_List.keepU(metric.items, (function (metricItem) {
                                return metricItem.VAL.id !== metricItemId$1;
                              }));
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metric.type_,
                                items: movedItem !== undefined ? (
                                    maybePredecessorId$4 !== undefined ? (
                                        doesPredecessorExist$2(listWithoutItem, maybePredecessorId$4) ? BeltListExtensions.flatMap(listWithoutItem, (function (x) {
                                                  if (x.VAL.id === maybePredecessorId$4) {
                                                    return {
                                                            hd: x,
                                                            tl: {
                                                              hd: movedItem,
                                                              tl: /* [] */0
                                                            }
                                                          };
                                                  } else {
                                                    return {
                                                            hd: x,
                                                            tl: /* [] */0
                                                          };
                                                  }
                                                })) : metric.items
                                      ) : Belt_List.concat({
                                            hd: movedItem,
                                            tl: /* [] */0
                                          }, listWithoutItem)
                                  ) : metric.items
                              };
                      }))
              };
      }
      var model$69 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$69.archive,
                branchPointer: model$69.branchPointer,
                migrations: model$69.migrations,
                rules: model$69.rules,
                types: model$69.types,
                categories: model$69.categories,
                destinations: model$69.destinations,
                events: model$69.events,
                groupTypes: model$69.groupTypes,
                integrations: model$69.integrations,
                metrics: Curry._3(TrackingPlanMappedModel.Metrics.updateExisting, model$69.metrics, metricId$3, (function (metric) {
                        var movedItem = Belt_List.getByU(metric.items, (function (metricItem) {
                                return metricItem.VAL.id === metricItemId$1;
                              }));
                        var listWithoutItem = Belt_List.keepU(metric.items, (function (metricItem) {
                                return metricItem.VAL.id !== metricItemId$1;
                              }));
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metric.type_,
                                items: movedItem !== undefined ? (
                                    maybePredecessorId$4 !== undefined ? (
                                        doesPredecessorExist$2(listWithoutItem, maybePredecessorId$4) ? BeltListExtensions.flatMap(listWithoutItem, (function (x) {
                                                  if (x.VAL.id === maybePredecessorId$4) {
                                                    return {
                                                            hd: x,
                                                            tl: {
                                                              hd: movedItem,
                                                              tl: /* [] */0
                                                            }
                                                          };
                                                  } else {
                                                    return {
                                                            hd: x,
                                                            tl: /* [] */0
                                                          };
                                                  }
                                                })) : metric.items
                                      ) : Belt_List.concat({
                                            hd: movedItem,
                                            tl: /* [] */0
                                          }, listWithoutItem)
                                  ) : metric.items
                              };
                      })),
                openBranches: model$69.openBranches,
                properties: model$69.properties,
                propertyBundles: model$69.propertyBundles,
                sources: model$69.sources,
                globalRequirements: model$69.globalRequirements
              }
            };
    }
    if (variant === "UpdatePropertyRegexOverrideOnEventVariant") {
      var match$49 = action.VAL;
      var maybeRegexValidationRule = match$49[2];
      var propertyId$18 = match$49[1];
      var id$3 = match$49[0];
      var update$6 = function (variant) {
        return {
                id: variant.id,
                baseEventId: variant.baseEventId,
                nameSuffix: variant.nameSuffix,
                description: variant.description,
                propertyOverrides: Belt_MapString.update(variant.propertyOverrides, propertyId$18, (function (override) {
                        if (override === undefined) {
                          return /* Overridden */{
                                  _0: {
                                    pinnedValue: /* NoneOverride */0,
                                    absence: /* NoneOverride */0,
                                    allowedValues: /* NoneOverride */0,
                                    regexValidation: /* SomeOverride */{
                                      _0: maybeRegexValidationRule
                                    }
                                  }
                                };
                        }
                        if (!override) {
                          return /* Removed */0;
                        }
                        var overridden = override._0;
                        return /* Overridden */{
                                _0: {
                                  pinnedValue: overridden.pinnedValue,
                                  absence: overridden.absence,
                                  allowedValues: overridden.allowedValues,
                                  regexValidation: /* SomeOverride */{
                                    _0: maybeRegexValidationRule
                                  }
                                }
                              };
                      })),
                sourceOverrides: variant.sourceOverrides,
                triggers: variant.triggers
              };
      };
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEventVariant(model._0, id$3, update$6)
              };
      }
      var model$70 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$70.archive,
                branchPointer: model$70.branchPointer,
                migrations: model$70.migrations,
                rules: model$70.rules,
                types: model$70.types,
                categories: model$70.categories,
                destinations: model$70.destinations,
                events: ModelUtils_mapped.updateExistingVariant(model$70.events, id$3, update$6),
                groupTypes: model$70.groupTypes,
                integrations: model$70.integrations,
                metrics: model$70.metrics,
                openBranches: model$70.openBranches,
                properties: model$70.properties,
                propertyBundles: model$70.propertyBundles,
                sources: model$70.sources,
                globalRequirements: model$70.globalRequirements
              }
            };
    }
    if (variant === "RemoveMetricGroupBy") {
      var match$50 = action.VAL;
      var id$4 = match$50[2];
      var eventId$11 = match$50[1];
      var metricId$4 = match$50[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateMetric(model._0, metricId$4, (function (metric) {
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metric.type_,
                                items: Belt_List.mapU(metric.items, (function (metricItem) {
                                        var $$event = metricItem.VAL;
                                        if (eventId$11 === $$event.eventId) {
                                          return {
                                                  NAME: "Event",
                                                  VAL: {
                                                    id: $$event.id,
                                                    eventId: $$event.eventId,
                                                    where: $$event.where,
                                                    groupBy: Belt_List.keepU($$event.groupBy, (function (groupBy) {
                                                            return groupBy.id !== id$4;
                                                          }))
                                                  }
                                                };
                                        } else {
                                          return metricItem;
                                        }
                                      }))
                              };
                      }))
              };
      }
      var model$71 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$71.archive,
                branchPointer: model$71.branchPointer,
                migrations: model$71.migrations,
                rules: model$71.rules,
                types: model$71.types,
                categories: model$71.categories,
                destinations: model$71.destinations,
                events: model$71.events,
                groupTypes: model$71.groupTypes,
                integrations: model$71.integrations,
                metrics: Curry._3(TrackingPlanMappedModel.Metrics.updateExisting, model$71.metrics, metricId$4, (function (metric) {
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metric.type_,
                                items: Belt_List.mapU(metric.items, (function (metricItem) {
                                        var $$event = metricItem.VAL;
                                        if (eventId$11 === $$event.eventId) {
                                          return {
                                                  NAME: "Event",
                                                  VAL: {
                                                    id: $$event.id,
                                                    eventId: $$event.eventId,
                                                    where: $$event.where,
                                                    groupBy: Belt_List.keepU($$event.groupBy, (function (groupBy) {
                                                            return groupBy.id !== id$4;
                                                          }))
                                                  }
                                                };
                                        } else {
                                          return metricItem;
                                        }
                                      }))
                              };
                      })),
                openBranches: model$71.openBranches,
                properties: model$71.properties,
                propertyBundles: model$71.propertyBundles,
                sources: model$71.sources,
                globalRequirements: model$71.globalRequirements
              }
            };
    }
    if (variant === "UpdateProgrammingLanguage") {
      var match$51 = action.VAL;
      var language = match$51[1];
      var sourceId$11 = match$51[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateSource(model._0, sourceId$11, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: language,
                                platform: source.platform,
                                destinations: source.destinations,
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: source.config
                              };
                      }))
              };
      }
      var model$72 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$72.archive,
                branchPointer: model$72.branchPointer,
                migrations: model$72.migrations,
                rules: model$72.rules,
                types: model$72.types,
                categories: model$72.categories,
                destinations: model$72.destinations,
                events: model$72.events,
                groupTypes: model$72.groupTypes,
                integrations: model$72.integrations,
                metrics: model$72.metrics,
                openBranches: model$72.openBranches,
                properties: model$72.properties,
                propertyBundles: model$72.propertyBundles,
                sources: Curry._3(TrackingPlanMappedModel.Sources.updateExisting, model$72.sources, sourceId$11, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: language,
                                platform: source.platform,
                                destinations: source.destinations,
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: source.config
                              };
                      })),
                globalRequirements: model$72.globalRequirements
              }
            };
    }
    if (variant === "ConvertEventToGlobalEventV2") {
      var match$52 = action.VAL;
      var newGlobalEvent$1 = match$52[4];
      var globalMetadata$3 = match$52[3];
      var globalWorkspaceId$3 = match$52[2];
      var toEventId$1 = match$52[1];
      var fromEventId$1 = match$52[0];
      var convertRule$2 = function (rule, globalRequirementEvents) {
        var item = rule.item;
        var variant = item.NAME;
        var ruleItem;
        if (variant === "PropertyRef") {
          var match = item.VAL;
          ruleItem = match[0] === fromEventId$1 ? ({
                NAME: "PropertyRef",
                VAL: [
                  toEventId$1,
                  match[1]
                ]
              }) : item;
        } else if (variant === "Property") {
          ruleItem = item;
        } else if (variant === "Event") {
          if (item.VAL === fromEventId$1) {
            var match$1 = rule.definition;
            if (match$1.NAME === "NameMapping") {
              var match$2 = match$1.VAL.destination;
              ruleItem = match$2 !== undefined && typeof match$2 === "object" ? ({
                    NAME: "Event",
                    VAL: toEventId$1
                  }) : undefined;
            } else {
              ruleItem = undefined;
            }
          } else {
            ruleItem = item;
          }
        } else {
          var match$3 = item.VAL;
          if (match$3[0] === fromEventId$1) {
            var nestedPropertyId = match$3[2];
            var propertyId = match$3[1];
            var toEvent = Belt_List.getBy(globalRequirementEvents, (function ($$event) {
                    return $$event.id === toEventId$1;
                  }));
            var fromEvent = Belt_List.getBy(globalRequirementEvents, (function ($$event) {
                    return $$event.id === fromEventId$1;
                  }));
            var exit = 0;
            var $$event;
            if (toEvent !== undefined) {
              if (fromEvent !== undefined) {
                $$event = fromEvent;
                exit = 1;
              } else {
                $$event = toEvent;
                exit = 1;
              }
            } else if (fromEvent !== undefined) {
              $$event = fromEvent;
              exit = 1;
            } else {
              ruleItem = {
                NAME: "EventObjectField",
                VAL: [
                  toEventId$1,
                  propertyId,
                  nestedPropertyId
                ]
              };
            }
            if (exit === 1) {
              var propertyIsGlobal = Belt_List.some($$event.properties, (function (property) {
                      return ModelUtils.getPropertyId(property) === propertyId;
                    }));
              ruleItem = propertyIsGlobal ? undefined : ({
                    NAME: "EventObjectField",
                    VAL: [
                      toEventId$1,
                      propertyId,
                      nestedPropertyId
                    ]
                  });
            }
            
          } else {
            ruleItem = item;
          }
        }
        return Belt_Option.map(ruleItem, (function (item) {
                      return {
                              id: rule.id,
                              item: item,
                              definition: rule.definition
                            };
                    }));
      };
      var convertGoal$1 = function (goal) {
        return {
                id: goal.id,
                name: goal.name,
                description: goal.description,
                metrics: goal.metrics,
                events: Belt_List.map(goal.events, (function (eventId) {
                        if (eventId === fromEventId$1) {
                          return toEventId$1;
                        } else {
                          return eventId;
                        }
                      }))
              };
      };
      var convertMetric$2 = function (metric) {
        var items = Belt_List.map(metric.items, (function (metricItem) {
                var metricEvent = metricItem.VAL;
                if (metricEvent.eventId === fromEventId$1) {
                  return {
                          NAME: "Event",
                          VAL: {
                            id: metricEvent.id,
                            eventId: toEventId$1,
                            where: metricEvent.where,
                            groupBy: metricEvent.groupBy
                          }
                        };
                } else {
                  return {
                          NAME: "Event",
                          VAL: metricEvent
                        };
                }
              }));
        return {
                id: metric.id,
                name: metric.name,
                description: metric.description,
                type_: metric.type_,
                items: items
              };
      };
      var updateProperty = function (property, fromEventId, toEventId) {
        var sometimesSent = property.absence;
        var tmp;
        if (sometimesSent !== undefined && typeof sometimesSent !== "number") {
          if (sometimesSent.TAG === /* SometimesSent */0) {
            tmp = sometimesSent;
          } else {
            var eventIdsAbsences = sometimesSent._0;
            var absence = Belt_MapString.get(eventIdsAbsences, fromEventId);
            if (absence !== undefined) {
              var eventIdsAbsences$1 = Belt_MapString.set(Belt_MapString.remove(eventIdsAbsences, fromEventId), toEventId, absence);
              tmp = {
                TAG: /* Mixed */1,
                _0: eventIdsAbsences$1
              };
            } else {
              tmp = {
                TAG: /* Mixed */1,
                _0: eventIdsAbsences
              };
            }
          }
        } else {
          tmp = /* AlwaysSent */0;
        }
        return {
                id: property.id,
                name: property.name,
                uniqueName: property.uniqueName,
                description: property.description,
                type_: property.type_,
                sendAs: property.sendAs,
                validations: property.validations,
                eventSpecificAllowedPropertyValues: property.eventSpecificAllowedPropertyValues,
                auto: property.auto,
                builtIn: property.builtIn,
                analyticsTools: property.analyticsTools,
                devPlatforms: property.devPlatforms,
                list: property.list,
                operation: property.operation,
                optionalDeprecated: property.optionalDeprecated,
                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                optionalWhenInObject: property.optionalWhenInObject,
                absence: tmp,
                globalRequirementsMetadata: property.globalRequirementsMetadata
              };
      };
      var convertEvent$3 = function ($$event, toEventId) {
        return {
                id: toEventId,
                name: $$event.name,
                uniqueName: $$event.uniqueName,
                description: $$event.description,
                properties: $$event.properties,
                propertyBundles: $$event.propertyBundles,
                variants: Belt_Array.map($$event.variants, (function (variant) {
                        return {
                                id: variant.id,
                                baseEventId: toEventId,
                                nameSuffix: variant.nameSuffix,
                                description: variant.description,
                                propertyOverrides: variant.propertyOverrides,
                                sourceOverrides: variant.sourceOverrides,
                                triggers: variant.triggers
                              };
                      })),
                types: $$event.types,
                tags: $$event.tags,
                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                includeSources: $$event.includeSources,
                includeDestinations: $$event.includeDestinations,
                hashes: $$event.hashes,
                propertyWhitelist: $$event.propertyWhitelist,
                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                triggers: $$event.triggers,
                globalRequirementsMetadata: {
                  fromLocalItemId: fromEventId$1,
                  globalWorkspaceId: globalWorkspaceId$3
                }
              };
      };
      if (model.TAG === /* Old */0) {
        var model$73 = model._0;
        var rules$1 = Belt_List.keepMap(model$73.rules, (function (rule) {
                return convertRule$2(rule, Belt_Option.getWithDefault(Belt_Option.map(model$73.globalRequirements, (function (requirements) {
                                      return requirements.events;
                                    })), /* [] */0));
              }));
        var goals$1 = Belt_List.map(model$73.goals, convertGoal$1);
        var metrics$1 = Belt_List.map(model$73.metrics, convertMetric$2);
        var updatedModel$1 = ModelUtils.updateEvent(model$73, fromEventId$1, (function (param) {
                return convertEvent$3(param, toEventId$1);
              }));
        var globalRequirementsEvent$2 = Belt_Option.map(Belt_List.getBy(model$73.events, (function ($$event) {
                    return $$event.id === fromEventId$1;
                  })), (function ($$event) {
                return GlobalRequirementsUtils.makeGlobalRequirementsEvent({
                            id: toEventId$1,
                            name: $$event.name,
                            uniqueName: $$event.uniqueName,
                            description: $$event.description,
                            properties: $$event.properties,
                            propertyBundles: $$event.propertyBundles,
                            variants: $$event.variants,
                            types: $$event.types,
                            tags: $$event.tags,
                            excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                            includeSources: $$event.includeSources,
                            includeDestinations: $$event.includeDestinations,
                            hashes: $$event.hashes,
                            propertyWhitelist: $$event.propertyWhitelist,
                            eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                            userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                            triggers: $$event.triggers,
                            globalRequirementsMetadata: $$event.globalRequirementsMetadata
                          }, newGlobalEvent$1);
              }));
        var globalRequirements$14 = model$73.globalRequirements;
        var globalRequirements$15 = globalRequirements$14 !== undefined ? globalRequirements$14 : TrackingPlanModel.GlobalRequirements.empty(globalMetadata$3);
        var globalRequirementsWithLocalEvent$2 = globalRequirementsEvent$2 !== undefined ? ({
              metadata: globalRequirements$15.metadata,
              events: Belt_List.concat(globalRequirements$15.events, {
                    hd: globalRequirementsEvent$2,
                    tl: /* [] */0
                  }),
              properties: globalRequirements$15.properties,
              propertyBundles: globalRequirements$15.propertyBundles,
              goals: globalRequirements$15.goals,
              groupTypes: globalRequirements$15.groupTypes,
              rules: globalRequirements$15.rules
            }) : globalRequirements$15;
        var updateProperties$1 = function (properties, fromEventId, toEventId) {
          return Belt_List.map(properties, (function (property) {
                        if (property.TAG === /* PropertyRef */0) {
                          return property;
                        } else {
                          return {
                                  TAG: /* Property */1,
                                  _0: updateProperty(property._0, fromEventId, toEventId)
                                };
                        }
                      }));
        };
        return {
                TAG: /* Old */0,
                _0: {
                  types: updatedModel$1.types,
                  properties: updateProperties$1(model$73.properties, fromEventId$1, toEventId$1),
                  propertyBundles: updatedModel$1.propertyBundles,
                  events: updatedModel$1.events,
                  migrations: updatedModel$1.migrations,
                  sources: updatedModel$1.sources,
                  destinations: updatedModel$1.destinations,
                  groupTypes: updatedModel$1.groupTypes,
                  goals: goals$1,
                  metrics: metrics$1,
                  archive: updatedModel$1.archive,
                  openBranches: updatedModel$1.openBranches,
                  branchPointer: updatedModel$1.branchPointer,
                  rules: rules$1,
                  integrations: updatedModel$1.integrations,
                  globalRequirements: globalRequirementsWithLocalEvent$2
                }
              };
      }
      var model$74 = model._0;
      var rules$2 = Belt_Array.keepMap(model$74.rules, (function (rule) {
              return convertRule$2(rule, Belt_Option.getWithDefault(Belt_Option.map(model$74.globalRequirements, (function (requirements) {
                                    return Curry._1(TrackingPlanMappedModel.Events.toList, requirements.events);
                                  })), /* [] */0));
            }));
      var globalRequirementsEvent$3 = Belt_Option.map(Curry._2(TrackingPlanMappedModel.Events.get, model$74.events, fromEventId$1), (function ($$event) {
              return GlobalRequirementsUtils.makeGlobalRequirementsEvent({
                          id: toEventId$1,
                          name: $$event.name,
                          uniqueName: $$event.uniqueName,
                          description: $$event.description,
                          properties: $$event.properties,
                          propertyBundles: $$event.propertyBundles,
                          variants: $$event.variants,
                          types: $$event.types,
                          tags: $$event.tags,
                          excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                          includeSources: $$event.includeSources,
                          includeDestinations: $$event.includeDestinations,
                          hashes: $$event.hashes,
                          propertyWhitelist: $$event.propertyWhitelist,
                          eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                          userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                          triggers: $$event.triggers,
                          globalRequirementsMetadata: $$event.globalRequirementsMetadata
                        }, newGlobalEvent$1);
            }));
      var globalRequirements$16 = model$74.globalRequirements;
      var globalRequirements$17 = globalRequirements$16 !== undefined ? globalRequirements$16 : TrackingPlanMappedModel.GlobalRequirements.fromOld(TrackingPlanModel.GlobalRequirements.empty(globalMetadata$3));
      var globalRequirementsWithLocalEvent$3 = globalRequirementsEvent$3 !== undefined ? ({
            metadata: globalRequirements$17.metadata,
            events: Curry._2(TrackingPlanMappedModel.Events.append, globalRequirements$17.events, globalRequirementsEvent$3),
            properties: globalRequirements$17.properties,
            propertyBundles: globalRequirements$17.propertyBundles,
            categories: globalRequirements$17.categories,
            groupTypes: globalRequirements$17.groupTypes,
            rules: globalRequirements$17.rules
          }) : globalRequirements$17;
      var updateEvents = function (events, fromEventId, toEventId) {
        var updatedEvent = Belt_Option.map(Curry._2(TrackingPlanMappedModel.Events.get, events, fromEventId), (function (param) {
                return convertEvent$3(param, toEventId);
              }));
        if (updatedEvent !== undefined) {
          return Curry._2(TrackingPlanMappedModel.Events.append, Curry._2(TrackingPlanMappedModel.Events.remove, events, fromEventId), updatedEvent);
        } else {
          return events;
        }
      };
      var updateProperties$2 = function (properties, fromEventId, toEventId) {
        return Curry._2(TrackingPlanMappedModel.Properties.mapToT, properties, (function (param) {
                      return updateProperty(param, fromEventId, toEventId);
                    }));
      };
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$74.archive,
                branchPointer: model$74.branchPointer,
                migrations: model$74.migrations,
                rules: rules$2,
                types: model$74.types,
                categories: Curry._2(TrackingPlanMappedModel.Categories.mapToT, model$74.categories, convertGoal$1),
                destinations: model$74.destinations,
                events: updateEvents(model$74.events, fromEventId$1, toEventId$1),
                groupTypes: model$74.groupTypes,
                integrations: model$74.integrations,
                metrics: Curry._2(TrackingPlanMappedModel.Metrics.mapToT, model$74.metrics, convertMetric$2),
                openBranches: model$74.openBranches,
                properties: updateProperties$2(model$74.properties, fromEventId$1, toEventId$1),
                propertyBundles: model$74.propertyBundles,
                sources: model$74.sources,
                globalRequirements: globalRequirementsWithLocalEvent$3
              }
            };
    }
    if (variant === "AddMetric") {
      var metricId$5 = action.VAL;
      if (model.TAG === /* Old */0) {
        var model$75 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$75.types,
                  properties: model$75.properties,
                  propertyBundles: model$75.propertyBundles,
                  events: model$75.events,
                  migrations: model$75.migrations,
                  sources: model$75.sources,
                  destinations: model$75.destinations,
                  groupTypes: model$75.groupTypes,
                  goals: model$75.goals,
                  metrics: Belt_List.concat(model$75.metrics, {
                        hd: TrackingPlanModel.emptyMetric(metricId$5),
                        tl: /* [] */0
                      }),
                  archive: model$75.archive,
                  openBranches: model$75.openBranches,
                  branchPointer: model$75.branchPointer,
                  rules: model$75.rules,
                  integrations: model$75.integrations,
                  globalRequirements: model$75.globalRequirements
                }
              };
      }
      var model$76 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$76.archive,
                branchPointer: model$76.branchPointer,
                migrations: model$76.migrations,
                rules: model$76.rules,
                types: model$76.types,
                categories: model$76.categories,
                destinations: model$76.destinations,
                events: model$76.events,
                groupTypes: model$76.groupTypes,
                integrations: model$76.integrations,
                metrics: Curry._2(TrackingPlanMappedModel.Metrics.append, model$76.metrics, TrackingPlanModel.emptyMetric(metricId$5)),
                openBranches: model$76.openBranches,
                properties: model$76.properties,
                propertyBundles: model$76.propertyBundles,
                sources: model$76.sources,
                globalRequirements: model$76.globalRequirements
              }
            };
    }
    if (variant === "UpdateTriggerSources") {
      var match$53 = action.VAL;
      var sources$2 = match$53[2];
      var triggerId$4 = match$53[1];
      var eventId$12 = match$53[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEventTrigger(model._0, eventId$12, triggerId$4, (function (trigger) {
                        return {
                                id: trigger.id,
                                sources: sources$2,
                                description: trigger.description,
                                content: trigger.content
                              };
                      }))
              };
      }
      var model$77 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$77.archive,
                branchPointer: model$77.branchPointer,
                migrations: model$77.migrations,
                rules: model$77.rules,
                types: model$77.types,
                categories: model$77.categories,
                destinations: model$77.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.updateExisting, model$77.events, eventId$12, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: Belt_Array.mapU($$event.triggers, (function (trigger) {
                                        if (trigger.id === triggerId$4) {
                                          return {
                                                  id: trigger.id,
                                                  sources: sources$2,
                                                  description: trigger.description,
                                                  content: trigger.content
                                                };
                                        } else {
                                          return trigger;
                                        }
                                      })),
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      })),
                groupTypes: model$77.groupTypes,
                integrations: model$77.integrations,
                metrics: model$77.metrics,
                openBranches: model$77.openBranches,
                properties: model$77.properties,
                propertyBundles: model$77.propertyBundles,
                sources: model$77.sources,
                globalRequirements: model$77.globalRequirements
              }
            };
    }
    if (variant === "UpdateSourcePlatformV2") {
      var match$54 = action.VAL;
      var platform = match$54[1];
      var sourceId$12 = match$54[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateSource(model._0, sourceId$12, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: undefined,
                                platform: platform,
                                destinations: source.destinations,
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: source.config
                              };
                      }))
              };
      }
      var model$78 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$78.archive,
                branchPointer: model$78.branchPointer,
                migrations: model$78.migrations,
                rules: model$78.rules,
                types: model$78.types,
                categories: model$78.categories,
                destinations: model$78.destinations,
                events: model$78.events,
                groupTypes: model$78.groupTypes,
                integrations: model$78.integrations,
                metrics: model$78.metrics,
                openBranches: model$78.openBranches,
                properties: model$78.properties,
                propertyBundles: model$78.propertyBundles,
                sources: Curry._3(TrackingPlanMappedModel.Sources.updateExisting, model$78.sources, sourceId$12, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: undefined,
                                platform: platform,
                                destinations: source.destinations,
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: source.config
                              };
                      })),
                globalRequirements: model$78.globalRequirements
              }
            };
    }
    if (variant === "UpdateSourcePlatformV3") {
      var match$55 = action.VAL;
      var maybePlatform = match$55[1];
      var sourceId$13 = match$55[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateSource(model._0, sourceId$13, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: undefined,
                                platform: maybePlatform,
                                destinations: source.destinations,
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: source.config
                              };
                      }))
              };
      }
      var model$79 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$79.archive,
                branchPointer: model$79.branchPointer,
                migrations: model$79.migrations,
                rules: model$79.rules,
                types: model$79.types,
                categories: model$79.categories,
                destinations: model$79.destinations,
                events: model$79.events,
                groupTypes: model$79.groupTypes,
                integrations: model$79.integrations,
                metrics: model$79.metrics,
                openBranches: model$79.openBranches,
                properties: model$79.properties,
                propertyBundles: model$79.propertyBundles,
                sources: Curry._3(TrackingPlanMappedModel.Sources.updateExisting, model$79.sources, sourceId$13, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: undefined,
                                platform: maybePlatform,
                                destinations: source.destinations,
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: source.config
                              };
                      })),
                globalRequirements: model$79.globalRequirements
              }
            };
    }
    if (variant === "UpdateEventUniqueNameV2") {
      var match$56 = action.VAL;
      var name$4 = match$56[1];
      var eventId$13 = match$56[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEvent(model._0, eventId$13, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: name$4 === "" ? undefined : name$4,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      }))
              };
      }
      var model$80 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$80.archive,
                branchPointer: model$80.branchPointer,
                migrations: model$80.migrations,
                rules: model$80.rules,
                types: model$80.types,
                categories: model$80.categories,
                destinations: model$80.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.updateExisting, model$80.events, eventId$13, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: name$4 === "" ? undefined : name$4,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      })),
                groupTypes: model$80.groupTypes,
                integrations: model$80.integrations,
                metrics: model$80.metrics,
                openBranches: model$80.openBranches,
                properties: model$80.properties,
                propertyBundles: model$80.propertyBundles,
                sources: model$80.sources,
                globalRequirements: model$80.globalRequirements
              }
            };
    }
    if (variant === "CreateEventTrigger") {
      var match$57 = action.VAL;
      var content$1 = match$57[4];
      var description$2 = match$57[3];
      var sources$3 = match$57[2];
      var id$5 = match$57[1];
      var eventId$14 = match$57[0];
      if (model.TAG === /* Old */0) {
        var newTrigger$1 = {
          id: id$5,
          sources: sources$3,
          description: description$2,
          content: content$1
        };
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEvent(model._0, eventId$14, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: Belt_Array.concat($$event.triggers, [newTrigger$1]),
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      }))
              };
      }
      var model$81 = model._0;
      var newTrigger$2 = {
        id: id$5,
        sources: sources$3,
        description: description$2,
        content: content$1
      };
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$81.archive,
                branchPointer: model$81.branchPointer,
                migrations: model$81.migrations,
                rules: model$81.rules,
                types: model$81.types,
                categories: model$81.categories,
                destinations: model$81.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.updateExisting, model$81.events, eventId$14, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: Belt_Array.concat($$event.triggers, [newTrigger$2]),
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      })),
                groupTypes: model$81.groupTypes,
                integrations: model$81.integrations,
                metrics: model$81.metrics,
                openBranches: model$81.openBranches,
                properties: model$81.properties,
                propertyBundles: model$81.propertyBundles,
                sources: model$81.sources,
                globalRequirements: model$81.globalRequirements
              }
            };
    }
    if (variant === "UpdatePropertyRegexValidation") {
      var match$58 = action.VAL;
      var maybeRegexRule = match$58[2];
      var maybeEventId = match$58[1];
      var propertyId$19 = match$58[0];
      var updatePropertyRegexValidation = function (__x) {
        return UpdatePropertyRegexValidationUseCase.update(__x, maybeEventId, maybeRegexRule);
      };
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateProperty(model._0, propertyId$19, updatePropertyRegexValidation)
              };
      }
      var model$82 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$82.archive,
                branchPointer: model$82.branchPointer,
                migrations: model$82.migrations,
                rules: model$82.rules,
                types: model$82.types,
                categories: model$82.categories,
                destinations: model$82.destinations,
                events: model$82.events,
                groupTypes: model$82.groupTypes,
                integrations: model$82.integrations,
                metrics: model$82.metrics,
                openBranches: model$82.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.updateExisting, model$82.properties, propertyId$19, updatePropertyRegexValidation),
                propertyBundles: model$82.propertyBundles,
                sources: model$82.sources,
                globalRequirements: model$82.globalRequirements
              }
            };
    }
    if (variant === "UpdateGroupTypeName") {
      var match$59 = action.VAL;
      var groupTypeName = match$59[1];
      var groupTypeId = match$59[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateGroupType(model._0, groupTypeId, (function (groupType) {
                        return {
                                id: groupType.id,
                                name: groupTypeName,
                                description: groupType.description
                              };
                      }))
              };
      }
      var model$83 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$83.archive,
                branchPointer: model$83.branchPointer,
                migrations: model$83.migrations,
                rules: model$83.rules,
                types: model$83.types,
                categories: model$83.categories,
                destinations: model$83.destinations,
                events: model$83.events,
                groupTypes: Curry._3(TrackingPlanMappedModel.GroupTypes.updateExisting, model$83.groupTypes, groupTypeId, (function (groupType) {
                        return {
                                id: groupType.id,
                                name: groupTypeName,
                                description: groupType.description
                              };
                      })),
                integrations: model$83.integrations,
                metrics: model$83.metrics,
                openBranches: model$83.openBranches,
                properties: model$83.properties,
                propertyBundles: model$83.propertyBundles,
                sources: model$83.sources,
                globalRequirements: model$83.globalRequirements
              }
            };
    }
    if (variant === "RemoveDestinationApiKey") {
      var match$60 = action.VAL;
      var envName = match$60[1];
      var destinationId$5 = match$60[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateDestination(model._0, destinationId$5, (function (destination) {
                        var init = destination.apiKeys;
                        return {
                                id: destination.id,
                                name: destination.name,
                                type_: destination.type_,
                                apiKeys: {
                                  dev: init.dev,
                                  prod: init.prod,
                                  other: Belt_List.keepU(destination.apiKeys.other, (function (param) {
                                          return param[0] !== envName;
                                        }))
                                },
                                includeUserPropsWithEventProps: destination.includeUserPropsWithEventProps,
                                disabledByDefault: destination.disabledByDefault
                              };
                      }))
              };
      }
      var model$84 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$84.archive,
                branchPointer: model$84.branchPointer,
                migrations: model$84.migrations,
                rules: model$84.rules,
                types: model$84.types,
                categories: model$84.categories,
                destinations: Curry._3(TrackingPlanMappedModel.Destinations.updateExisting, model$84.destinations, destinationId$5, (function (destination) {
                        var init = destination.apiKeys;
                        return {
                                id: destination.id,
                                name: destination.name,
                                type_: destination.type_,
                                apiKeys: {
                                  dev: init.dev,
                                  prod: init.prod,
                                  other: Belt_List.keepU(destination.apiKeys.other, (function (param) {
                                          return param[0] !== envName;
                                        }))
                                },
                                includeUserPropsWithEventProps: destination.includeUserPropsWithEventProps,
                                disabledByDefault: destination.disabledByDefault
                              };
                      })),
                events: model$84.events,
                groupTypes: model$84.groupTypes,
                integrations: model$84.integrations,
                metrics: model$84.metrics,
                openBranches: model$84.openBranches,
                properties: model$84.properties,
                propertyBundles: model$84.propertyBundles,
                sources: model$84.sources,
                globalRequirements: model$84.globalRequirements
              }
            };
    }
    if (variant === "RemoveEventSpecificPropertyValue") {
      var match$61 = action.VAL;
      var literal$1 = match$61[2];
      var propertyId$20 = match$61[1];
      var eventId$15 = match$61[0];
      var removeEventSpecificPropertyValue = function (property) {
        var eventId$16 = property.sendAs === /* SystemProperty */0 ? GetEventIdsSendingPropertyForEventSpecificValuesUseCase.propertyValuesAllEventsKey : eventId$15;
        var eventSpecificAllowedPropertyValues = property.eventSpecificAllowedPropertyValues;
        var eventSpecificAllowedPropertyValues$1 = Belt_Array.map(eventSpecificAllowedPropertyValues, (function (param) {
                var disallowedSourcesSet = param[1];
                var existingLiteral = param[0];
                if (Caml_obj.caml_equal(existingLiteral, literal$1)) {
                  return [
                          existingLiteral,
                          Belt_MapString.set(disallowedSourcesSet, eventId$16, /* Disallowed */0)
                        ];
                } else {
                  return [
                          existingLiteral,
                          disallowedSourcesSet
                        ];
                }
              }));
        return {
                id: property.id,
                name: property.name,
                uniqueName: property.uniqueName,
                description: property.description,
                type_: property.type_,
                sendAs: property.sendAs,
                validations: property.validations,
                eventSpecificAllowedPropertyValues: eventSpecificAllowedPropertyValues$1,
                auto: property.auto,
                builtIn: property.builtIn,
                analyticsTools: property.analyticsTools,
                devPlatforms: property.devPlatforms,
                list: property.list,
                operation: property.operation,
                optionalDeprecated: property.optionalDeprecated,
                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                optionalWhenInObject: property.optionalWhenInObject,
                absence: property.absence,
                globalRequirementsMetadata: property.globalRequirementsMetadata
              };
      };
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateProperty(model._0, propertyId$20, removeEventSpecificPropertyValue)
              };
      }
      var model$85 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$85.archive,
                branchPointer: model$85.branchPointer,
                migrations: model$85.migrations,
                rules: model$85.rules,
                types: model$85.types,
                categories: model$85.categories,
                destinations: model$85.destinations,
                events: model$85.events,
                groupTypes: model$85.groupTypes,
                integrations: model$85.integrations,
                metrics: model$85.metrics,
                openBranches: model$85.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.updateExisting, model$85.properties, propertyId$20, removeEventSpecificPropertyValue),
                propertyBundles: model$85.propertyBundles,
                sources: model$85.sources,
                globalRequirements: model$85.globalRequirements
              }
            };
    }
    if (variant === "Archive") {
      var item$1 = action.VAL;
      var variant$1 = item$1.NAME;
      if (variant$1 === "Destination") {
        var destinationId$6 = item$1.VAL;
        if (model.TAG === /* Old */0) {
          var model$86 = model._0;
          return {
                  TAG: /* Old */0,
                  _0: {
                    types: model$86.types,
                    properties: model$86.properties,
                    propertyBundles: model$86.propertyBundles,
                    events: model$86.events,
                    migrations: model$86.migrations,
                    sources: model$86.sources,
                    destinations: Belt_List.keepU(model$86.destinations, (function (destination) {
                            return destination.id !== destinationId$6;
                          })),
                    groupTypes: model$86.groupTypes,
                    goals: model$86.goals,
                    metrics: model$86.metrics,
                    archive: Belt_Option.mapWithDefault(Belt_List.getByU(model$86.destinations, (function (destination) {
                                return destination.id === destinationId$6;
                              })), model$86.archive, (function (destination) {
                            var init = model$86.archive;
                            return {
                                    events: init.events,
                                    properties: init.properties,
                                    propertyBundles: init.propertyBundles,
                                    sources: init.sources,
                                    destinations: Belt_List.concat(model$86.archive.destinations, {
                                          hd: destination,
                                          tl: /* [] */0
                                        }),
                                    goals: init.goals,
                                    metrics: init.metrics,
                                    groupTypes: init.groupTypes,
                                    eventVariants: init.eventVariants
                                  };
                          })),
                    openBranches: model$86.openBranches,
                    branchPointer: model$86.branchPointer,
                    rules: model$86.rules,
                    integrations: model$86.integrations,
                    globalRequirements: model$86.globalRequirements
                  }
                };
        }
        var model$87 = model._0;
        return {
                TAG: /* Mapped */1,
                _0: {
                  archive: Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.Destinations.get, model$87.destinations, destinationId$6), model$87.archive, (function (destination) {
                          var init = model$87.archive;
                          return {
                                  categories: init.categories,
                                  destinations: Curry._2(TrackingPlanMappedModel.Destinations.append, model$87.archive.destinations, destination),
                                  events: init.events,
                                  groupTypes: init.groupTypes,
                                  metrics: init.metrics,
                                  properties: init.properties,
                                  propertyBundles: init.propertyBundles,
                                  sources: init.sources,
                                  eventVariants: init.eventVariants
                                };
                        })),
                  branchPointer: model$87.branchPointer,
                  migrations: model$87.migrations,
                  rules: model$87.rules,
                  types: model$87.types,
                  categories: model$87.categories,
                  destinations: Curry._2(TrackingPlanMappedModel.Destinations.remove, model$87.destinations, destinationId$6),
                  events: model$87.events,
                  groupTypes: model$87.groupTypes,
                  integrations: model$87.integrations,
                  metrics: model$87.metrics,
                  openBranches: model$87.openBranches,
                  properties: model$87.properties,
                  propertyBundles: model$87.propertyBundles,
                  sources: model$87.sources,
                  globalRequirements: model$87.globalRequirements
                }
              };
      }
      if (variant$1 === "Integration") {
        return model;
      }
      if (variant$1 === "GroupType") {
        var groupTypeId$1 = item$1.VAL;
        if (model.TAG === /* Old */0) {
          var model$88 = model._0;
          var groupTypeProperties = Belt_List.keepU(model$88.properties, (function (modelProperty) {
                  var match = Belt_Option.mapU(ModelUtils.resolveProperty(model$88, modelProperty), (function (prop) {
                          return prop.sendAs;
                        }));
                  if (match !== undefined && typeof match !== "number") {
                    return match._0 === groupTypeId$1;
                  } else {
                    return false;
                  }
                }));
          var model$89 = TrackingPlanMappedModel.unboxModel(Belt_List.reduceU(groupTypeProperties, model, (function (nextModel, property) {
                      return reduceFunction(nextModel, {
                                  NAME: "Archive",
                                  VAL: {
                                    NAME: "Property",
                                    VAL: ModelUtils.getPropertyId(property)
                                  }
                                });
                    })));
          var init$2 = model$89.archive;
          return {
                  TAG: /* Old */0,
                  _0: {
                    types: model$89.types,
                    properties: model$89.properties,
                    propertyBundles: model$89.propertyBundles,
                    events: model$89.events,
                    migrations: model$89.migrations,
                    sources: model$89.sources,
                    destinations: model$89.destinations,
                    groupTypes: Belt_Array.keepU(model$89.groupTypes, (function (groupType) {
                            return groupType.id !== groupTypeId$1;
                          })),
                    goals: model$89.goals,
                    metrics: model$89.metrics,
                    archive: {
                      events: init$2.events,
                      properties: init$2.properties,
                      propertyBundles: init$2.propertyBundles,
                      sources: init$2.sources,
                      destinations: init$2.destinations,
                      goals: init$2.goals,
                      metrics: init$2.metrics,
                      groupTypes: Belt_Option.mapWithDefault(Belt_Array.getByU(model$89.groupTypes, (function (groupType) {
                                  return groupType.id === groupTypeId$1;
                                })), model$89.archive.groupTypes, (function (groupType) {
                              return Belt_Array.concat(model$89.archive.groupTypes, [groupType]);
                            })),
                      eventVariants: init$2.eventVariants
                    },
                    openBranches: model$89.openBranches,
                    branchPointer: model$89.branchPointer,
                    rules: model$89.rules,
                    integrations: model$89.integrations,
                    globalRequirements: model$89.globalRequirements
                  }
                };
        }
        var groupTypeProperties$1 = Curry._2(TrackingPlanMappedModel.Properties.keepMap, model._0.properties, (function (property) {
                var inGroupTypeId = property.sendAs;
                if (typeof inGroupTypeId === "number" || inGroupTypeId._0 !== groupTypeId$1) {
                  return ;
                } else {
                  return property;
                }
              }));
        var model$90 = TrackingPlanMappedModel.unboxModel(Belt_Array.reduceU(groupTypeProperties$1, model, (function (nextModel, property) {
                    return reduceFunction(nextModel, {
                                NAME: "Archive",
                                VAL: {
                                  NAME: "Property",
                                  VAL: property.id
                                }
                              });
                  })));
        return {
                TAG: /* Mapped */1,
                _0: {
                  archive: Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.GroupTypes.get, model$90.groupTypes, groupTypeId$1), model$90.archive, (function (groupType) {
                          var init = model$90.archive;
                          return {
                                  categories: init.categories,
                                  destinations: init.destinations,
                                  events: init.events,
                                  groupTypes: Curry._2(TrackingPlanMappedModel.GroupTypes.append, model$90.archive.groupTypes, groupType),
                                  metrics: init.metrics,
                                  properties: init.properties,
                                  propertyBundles: init.propertyBundles,
                                  sources: init.sources,
                                  eventVariants: init.eventVariants
                                };
                        })),
                  branchPointer: model$90.branchPointer,
                  migrations: model$90.migrations,
                  rules: model$90.rules,
                  types: model$90.types,
                  categories: model$90.categories,
                  destinations: model$90.destinations,
                  events: model$90.events,
                  groupTypes: Curry._2(TrackingPlanMappedModel.GroupTypes.remove, model$90.groupTypes, groupTypeId$1),
                  integrations: model$90.integrations,
                  metrics: model$90.metrics,
                  openBranches: model$90.openBranches,
                  properties: model$90.properties,
                  propertyBundles: model$90.propertyBundles,
                  sources: model$90.sources,
                  globalRequirements: model$90.globalRequirements
                }
              };
      }
      if (variant$1 === "Property") {
        var propertyId$21 = item$1.VAL;
        if (model.TAG === /* Old */0) {
          var model$91 = model._0;
          return {
                  TAG: /* Old */0,
                  _0: {
                    types: model$91.types,
                    properties: Belt_List.keepMapU(model$91.properties, (function (property) {
                            if (property.TAG === /* PropertyRef */0) {
                              return property;
                            }
                            var p = property._0;
                            if (p.id === propertyId$21) {
                              return ;
                            }
                            var newP_id = p.id;
                            var newP_name = p.name;
                            var newP_uniqueName = p.uniqueName;
                            var newP_description = p.description;
                            var newP_type_ = p.type_;
                            var newP_sendAs = p.sendAs;
                            var newP_validations = Belt_List.map(p.validations, (function (x) {
                                    if (typeof x === "object" && x.NAME === "NestedProperty") {
                                      return {
                                              NAME: "NestedProperty",
                                              VAL: Belt_List.keep(x.VAL, (function (propertyRef) {
                                                      return propertyRef.id !== propertyId$21;
                                                    }))
                                            };
                                    } else {
                                      return x;
                                    }
                                  }));
                            var newP_eventSpecificAllowedPropertyValues = p.eventSpecificAllowedPropertyValues;
                            var newP_auto = p.auto;
                            var newP_builtIn = p.builtIn;
                            var newP_analyticsTools = p.analyticsTools;
                            var newP_devPlatforms = p.devPlatforms;
                            var newP_list = p.list;
                            var newP_operation = p.operation;
                            var newP_optionalDeprecated = p.optionalDeprecated;
                            var newP_excludedSourcesDeprecated = p.excludedSourcesDeprecated;
                            var newP_optionalWhenInObject = p.optionalWhenInObject;
                            var newP_absence = p.absence;
                            var newP_globalRequirementsMetadata = p.globalRequirementsMetadata;
                            var newP = {
                              id: newP_id,
                              name: newP_name,
                              uniqueName: newP_uniqueName,
                              description: newP_description,
                              type_: newP_type_,
                              sendAs: newP_sendAs,
                              validations: newP_validations,
                              eventSpecificAllowedPropertyValues: newP_eventSpecificAllowedPropertyValues,
                              auto: newP_auto,
                              builtIn: newP_builtIn,
                              analyticsTools: newP_analyticsTools,
                              devPlatforms: newP_devPlatforms,
                              list: newP_list,
                              operation: newP_operation,
                              optionalDeprecated: newP_optionalDeprecated,
                              excludedSourcesDeprecated: newP_excludedSourcesDeprecated,
                              optionalWhenInObject: newP_optionalWhenInObject,
                              absence: newP_absence,
                              globalRequirementsMetadata: newP_globalRequirementsMetadata
                            };
                            return {
                                    TAG: /* Property */1,
                                    _0: newP
                                  };
                          })),
                    propertyBundles: Belt_List.mapU(model$91.propertyBundles, (function (group) {
                            return {
                                    id: group.id,
                                    name: group.name,
                                    description: group.description,
                                    properties: Belt_List.keepU(group.properties, (function (id) {
                                            return id !== propertyId$21;
                                          })),
                                    globalRequirementsMetadata: group.globalRequirementsMetadata
                                  };
                          })),
                    events: Belt_List.mapU(model$91.events, (function ($$event) {
                            return {
                                    id: $$event.id,
                                    name: $$event.name,
                                    uniqueName: $$event.uniqueName,
                                    description: $$event.description,
                                    properties: Belt_List.keepU($$event.properties, (function (property) {
                                            if (property.TAG === /* PropertyRef */0) {
                                              return property._0.id !== propertyId$21;
                                            } else {
                                              return true;
                                            }
                                          })),
                                    propertyBundles: $$event.propertyBundles,
                                    variants: $$event.variants,
                                    types: $$event.types,
                                    tags: $$event.tags,
                                    excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                    includeSources: $$event.includeSources,
                                    includeDestinations: $$event.includeDestinations,
                                    hashes: $$event.hashes,
                                    propertyWhitelist: Belt_List.keepU($$event.propertyWhitelist, (function (param) {
                                            return param[1] !== propertyId$21;
                                          })),
                                    eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                    userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                    triggers: $$event.triggers,
                                    globalRequirementsMetadata: $$event.globalRequirementsMetadata
                                  };
                          })),
                    migrations: model$91.migrations,
                    sources: model$91.sources,
                    destinations: model$91.destinations,
                    groupTypes: model$91.groupTypes,
                    goals: model$91.goals,
                    metrics: model$91.metrics,
                    archive: Belt_Option.mapWithDefault(Belt_List.getByU(model$91.properties, (function (property) {
                                if (property.TAG === /* PropertyRef */0) {
                                  return false;
                                } else {
                                  return property._0.id === propertyId$21;
                                }
                              })), model$91.archive, (function (property) {
                            var init = model$91.archive;
                            return {
                                    events: init.events,
                                    properties: Belt_List.concat(model$91.archive.properties, {
                                          hd: property,
                                          tl: /* [] */0
                                        }),
                                    propertyBundles: init.propertyBundles,
                                    sources: init.sources,
                                    destinations: init.destinations,
                                    goals: init.goals,
                                    metrics: init.metrics,
                                    groupTypes: init.groupTypes,
                                    eventVariants: init.eventVariants
                                  };
                          })),
                    openBranches: model$91.openBranches,
                    branchPointer: model$91.branchPointer,
                    rules: model$91.rules,
                    integrations: model$91.integrations,
                    globalRequirements: model$91.globalRequirements
                  }
                };
        }
        var model$92 = model._0;
        return {
                TAG: /* Mapped */1,
                _0: {
                  archive: Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.Properties.get, model$92.properties, propertyId$21), model$92.archive, (function (property) {
                          var init = model$92.archive;
                          return {
                                  categories: init.categories,
                                  destinations: init.destinations,
                                  events: init.events,
                                  groupTypes: init.groupTypes,
                                  metrics: init.metrics,
                                  properties: Curry._2(TrackingPlanMappedModel.Properties.append, model$92.archive.properties, property),
                                  propertyBundles: init.propertyBundles,
                                  sources: init.sources,
                                  eventVariants: init.eventVariants
                                };
                        })),
                  branchPointer: model$92.branchPointer,
                  migrations: model$92.migrations,
                  rules: model$92.rules,
                  types: model$92.types,
                  categories: model$92.categories,
                  destinations: model$92.destinations,
                  events: Curry._2(TrackingPlanMappedModel.Events.mapToT, model$92.events, (function ($$event) {
                          return {
                                  id: $$event.id,
                                  name: $$event.name,
                                  uniqueName: $$event.uniqueName,
                                  description: $$event.description,
                                  properties: Belt_List.keepU($$event.properties, (function (property) {
                                          if (property.TAG === /* PropertyRef */0) {
                                            return property._0.id !== propertyId$21;
                                          } else {
                                            return true;
                                          }
                                        })),
                                  propertyBundles: $$event.propertyBundles,
                                  variants: $$event.variants,
                                  types: $$event.types,
                                  tags: $$event.tags,
                                  excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                  includeSources: $$event.includeSources,
                                  includeDestinations: $$event.includeDestinations,
                                  hashes: $$event.hashes,
                                  propertyWhitelist: Belt_List.keepU($$event.propertyWhitelist, (function (param) {
                                          return param[1] !== propertyId$21;
                                        })),
                                  eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                  userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                  triggers: $$event.triggers,
                                  globalRequirementsMetadata: $$event.globalRequirementsMetadata
                                };
                        })),
                  groupTypes: model$92.groupTypes,
                  integrations: model$92.integrations,
                  metrics: model$92.metrics,
                  openBranches: model$92.openBranches,
                  properties: Curry._2(TrackingPlanMappedModel.Properties.mapToT, Curry._2(TrackingPlanMappedModel.Properties.remove, model$92.properties, propertyId$21), (function (property) {
                          return {
                                  id: property.id,
                                  name: property.name,
                                  uniqueName: property.uniqueName,
                                  description: property.description,
                                  type_: property.type_,
                                  sendAs: property.sendAs,
                                  validations: Belt_List.map(property.validations, (function (x) {
                                          if (typeof x === "object" && x.NAME === "NestedProperty") {
                                            return {
                                                    NAME: "NestedProperty",
                                                    VAL: Belt_List.keep(x.VAL, (function (propertyRef) {
                                                            return propertyRef.id !== propertyId$21;
                                                          }))
                                                  };
                                          } else {
                                            return x;
                                          }
                                        })),
                                  eventSpecificAllowedPropertyValues: property.eventSpecificAllowedPropertyValues,
                                  auto: property.auto,
                                  builtIn: property.builtIn,
                                  analyticsTools: property.analyticsTools,
                                  devPlatforms: property.devPlatforms,
                                  list: property.list,
                                  operation: property.operation,
                                  optionalDeprecated: property.optionalDeprecated,
                                  excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                  optionalWhenInObject: property.optionalWhenInObject,
                                  absence: property.absence,
                                  globalRequirementsMetadata: property.globalRequirementsMetadata
                                };
                        })),
                  propertyBundles: Curry._2(TrackingPlanMappedModel.PropertyBundles.mapToT, model$92.propertyBundles, (function (propertyBundle) {
                          return {
                                  id: propertyBundle.id,
                                  name: propertyBundle.name,
                                  description: propertyBundle.description,
                                  properties: Belt_List.keepU(propertyBundle.properties, (function (id) {
                                          return id !== propertyId$21;
                                        })),
                                  globalRequirementsMetadata: propertyBundle.globalRequirementsMetadata
                                };
                        })),
                  sources: model$92.sources,
                  globalRequirements: model$92.globalRequirements
                }
              };
      }
      if (variant$1 === "Event") {
        var eventId$16 = item$1.VAL;
        if (model.TAG === /* Old */0) {
          var model$93 = model._0;
          return {
                  TAG: /* Old */0,
                  _0: {
                    types: model$93.types,
                    properties: model$93.properties,
                    propertyBundles: model$93.propertyBundles,
                    events: Belt_List.keepU(model$93.events, (function ($$event) {
                            return $$event.id !== eventId$16;
                          })),
                    migrations: model$93.migrations,
                    sources: model$93.sources,
                    destinations: model$93.destinations,
                    groupTypes: model$93.groupTypes,
                    goals: model$93.goals,
                    metrics: model$93.metrics,
                    archive: Belt_Option.mapWithDefault(Belt_List.getByU(model$93.events, (function ($$event) {
                                return $$event.id === eventId$16;
                              })), model$93.archive, (function ($$event) {
                            var init = model$93.archive;
                            return {
                                    events: Belt_List.concat(model$93.archive.events, {
                                          hd: $$event,
                                          tl: /* [] */0
                                        }),
                                    properties: init.properties,
                                    propertyBundles: init.propertyBundles,
                                    sources: init.sources,
                                    destinations: init.destinations,
                                    goals: init.goals,
                                    metrics: init.metrics,
                                    groupTypes: init.groupTypes,
                                    eventVariants: init.eventVariants
                                  };
                          })),
                    openBranches: model$93.openBranches,
                    branchPointer: model$93.branchPointer,
                    rules: model$93.rules,
                    integrations: model$93.integrations,
                    globalRequirements: model$93.globalRequirements
                  }
                };
        }
        var model$94 = model._0;
        return {
                TAG: /* Mapped */1,
                _0: {
                  archive: Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.Events.get, model$94.events, eventId$16), model$94.archive, (function ($$event) {
                          var init = model$94.archive;
                          return {
                                  categories: init.categories,
                                  destinations: init.destinations,
                                  events: Curry._2(TrackingPlanMappedModel.Events.append, model$94.archive.events, $$event),
                                  groupTypes: init.groupTypes,
                                  metrics: init.metrics,
                                  properties: init.properties,
                                  propertyBundles: init.propertyBundles,
                                  sources: init.sources,
                                  eventVariants: init.eventVariants
                                };
                        })),
                  branchPointer: model$94.branchPointer,
                  migrations: model$94.migrations,
                  rules: model$94.rules,
                  types: model$94.types,
                  categories: model$94.categories,
                  destinations: model$94.destinations,
                  events: Curry._2(TrackingPlanMappedModel.Events.remove, model$94.events, eventId$16),
                  groupTypes: model$94.groupTypes,
                  integrations: model$94.integrations,
                  metrics: model$94.metrics,
                  openBranches: model$94.openBranches,
                  properties: model$94.properties,
                  propertyBundles: model$94.propertyBundles,
                  sources: model$94.sources,
                  globalRequirements: model$94.globalRequirements
                }
              };
      }
      if (variant$1 === "PropertyGroup") {
        var propertyBundleId = item$1.VAL;
        if (model.TAG === /* Old */0) {
          var model$95 = model._0;
          return {
                  TAG: /* Old */0,
                  _0: {
                    types: model$95.types,
                    properties: model$95.properties,
                    propertyBundles: Belt_List.keepU(model$95.propertyBundles, (function (group) {
                            return group.id !== propertyBundleId;
                          })),
                    events: Belt_List.mapU(model$95.events, (function ($$event) {
                            return {
                                    id: $$event.id,
                                    name: $$event.name,
                                    uniqueName: $$event.uniqueName,
                                    description: $$event.description,
                                    properties: $$event.properties,
                                    propertyBundles: Belt_List.keepU($$event.propertyBundles, (function (group) {
                                            return group.id !== propertyBundleId;
                                          })),
                                    variants: $$event.variants,
                                    types: $$event.types,
                                    tags: $$event.tags,
                                    excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                    includeSources: $$event.includeSources,
                                    includeDestinations: $$event.includeDestinations,
                                    hashes: $$event.hashes,
                                    propertyWhitelist: $$event.propertyWhitelist,
                                    eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                    userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                    triggers: $$event.triggers,
                                    globalRequirementsMetadata: $$event.globalRequirementsMetadata
                                  };
                          })),
                    migrations: model$95.migrations,
                    sources: model$95.sources,
                    destinations: model$95.destinations,
                    groupTypes: model$95.groupTypes,
                    goals: model$95.goals,
                    metrics: model$95.metrics,
                    archive: Belt_Option.mapWithDefault(Belt_List.getByU(model$95.propertyBundles, (function (group) {
                                return group.id === propertyBundleId;
                              })), model$95.archive, (function (group) {
                            var init = model$95.archive;
                            return {
                                    events: init.events,
                                    properties: init.properties,
                                    propertyBundles: Belt_List.concat(model$95.archive.propertyBundles, {
                                          hd: group,
                                          tl: /* [] */0
                                        }),
                                    sources: init.sources,
                                    destinations: init.destinations,
                                    goals: init.goals,
                                    metrics: init.metrics,
                                    groupTypes: init.groupTypes,
                                    eventVariants: init.eventVariants
                                  };
                          })),
                    openBranches: model$95.openBranches,
                    branchPointer: model$95.branchPointer,
                    rules: model$95.rules,
                    integrations: model$95.integrations,
                    globalRequirements: model$95.globalRequirements
                  }
                };
        }
        var model$96 = model._0;
        return {
                TAG: /* Mapped */1,
                _0: {
                  archive: Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.PropertyBundles.get, model$96.propertyBundles, propertyBundleId), model$96.archive, (function (propertyBundle) {
                          var init = model$96.archive;
                          return {
                                  categories: init.categories,
                                  destinations: init.destinations,
                                  events: init.events,
                                  groupTypes: init.groupTypes,
                                  metrics: init.metrics,
                                  properties: init.properties,
                                  propertyBundles: Curry._2(TrackingPlanMappedModel.PropertyBundles.append, model$96.archive.propertyBundles, propertyBundle),
                                  sources: init.sources,
                                  eventVariants: init.eventVariants
                                };
                        })),
                  branchPointer: model$96.branchPointer,
                  migrations: model$96.migrations,
                  rules: model$96.rules,
                  types: model$96.types,
                  categories: model$96.categories,
                  destinations: model$96.destinations,
                  events: Curry._2(TrackingPlanMappedModel.Events.mapToT, model$96.events, (function ($$event) {
                          return {
                                  id: $$event.id,
                                  name: $$event.name,
                                  uniqueName: $$event.uniqueName,
                                  description: $$event.description,
                                  properties: $$event.properties,
                                  propertyBundles: Belt_List.keepU($$event.propertyBundles, (function (propertyBundle) {
                                          return propertyBundle.id !== propertyBundleId;
                                        })),
                                  variants: $$event.variants,
                                  types: $$event.types,
                                  tags: $$event.tags,
                                  excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                  includeSources: $$event.includeSources,
                                  includeDestinations: $$event.includeDestinations,
                                  hashes: $$event.hashes,
                                  propertyWhitelist: $$event.propertyWhitelist,
                                  eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                  userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                  triggers: $$event.triggers,
                                  globalRequirementsMetadata: $$event.globalRequirementsMetadata
                                };
                        })),
                  groupTypes: model$96.groupTypes,
                  integrations: model$96.integrations,
                  metrics: model$96.metrics,
                  openBranches: model$96.openBranches,
                  properties: model$96.properties,
                  propertyBundles: Curry._2(TrackingPlanMappedModel.PropertyBundles.remove, model$96.propertyBundles, propertyBundleId),
                  sources: model$96.sources,
                  globalRequirements: model$96.globalRequirements
                }
              };
      }
      if (variant$1 === "Goal") {
        var categoryId = item$1.VAL;
        if (model.TAG === /* Old */0) {
          var model$97 = model._0;
          return {
                  TAG: /* Old */0,
                  _0: {
                    types: model$97.types,
                    properties: model$97.properties,
                    propertyBundles: model$97.propertyBundles,
                    events: model$97.events,
                    migrations: model$97.migrations,
                    sources: model$97.sources,
                    destinations: model$97.destinations,
                    groupTypes: model$97.groupTypes,
                    goals: Belt_List.keepU(model$97.goals, (function (goal) {
                            return goal.id !== categoryId;
                          })),
                    metrics: model$97.metrics,
                    archive: Belt_Option.mapWithDefault(Belt_List.getByU(model$97.goals, (function (goal) {
                                return goal.id === categoryId;
                              })), model$97.archive, (function (goal) {
                            var init = model$97.archive;
                            return {
                                    events: init.events,
                                    properties: init.properties,
                                    propertyBundles: init.propertyBundles,
                                    sources: init.sources,
                                    destinations: init.destinations,
                                    goals: Belt_List.concat(model$97.archive.goals, {
                                          hd: goal,
                                          tl: /* [] */0
                                        }),
                                    metrics: init.metrics,
                                    groupTypes: init.groupTypes,
                                    eventVariants: init.eventVariants
                                  };
                          })),
                    openBranches: model$97.openBranches,
                    branchPointer: model$97.branchPointer,
                    rules: model$97.rules,
                    integrations: model$97.integrations,
                    globalRequirements: model$97.globalRequirements
                  }
                };
        }
        var model$98 = model._0;
        return {
                TAG: /* Mapped */1,
                _0: {
                  archive: Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.Categories.get, model$98.categories, categoryId), model$98.archive, (function (category) {
                          var init = model$98.archive;
                          return {
                                  categories: Curry._2(TrackingPlanMappedModel.Categories.append, model$98.archive.categories, category),
                                  destinations: init.destinations,
                                  events: init.events,
                                  groupTypes: init.groupTypes,
                                  metrics: init.metrics,
                                  properties: init.properties,
                                  propertyBundles: init.propertyBundles,
                                  sources: init.sources,
                                  eventVariants: init.eventVariants
                                };
                        })),
                  branchPointer: model$98.branchPointer,
                  migrations: model$98.migrations,
                  rules: model$98.rules,
                  types: model$98.types,
                  categories: Curry._2(TrackingPlanMappedModel.Categories.remove, model$98.categories, categoryId),
                  destinations: model$98.destinations,
                  events: model$98.events,
                  groupTypes: model$98.groupTypes,
                  integrations: model$98.integrations,
                  metrics: model$98.metrics,
                  openBranches: model$98.openBranches,
                  properties: model$98.properties,
                  propertyBundles: model$98.propertyBundles,
                  sources: model$98.sources,
                  globalRequirements: model$98.globalRequirements
                }
              };
      }
      if (variant$1 === "EventVariant") {
        var variantIdentifier$6 = item$1.VAL;
        if (model.TAG === /* Old */0) {
          var model$99 = model._0;
          var init$3 = ModelUtils.updateEvent(model$99, variantIdentifier$6.baseEventId, (function ($$event) {
                  return {
                          id: $$event.id,
                          name: $$event.name,
                          uniqueName: $$event.uniqueName,
                          description: $$event.description,
                          properties: $$event.properties,
                          propertyBundles: $$event.propertyBundles,
                          variants: Belt_Array.keep($$event.variants, (function (param) {
                                  return param.id !== variantIdentifier$6.variantId;
                                })),
                          types: $$event.types,
                          tags: $$event.tags,
                          excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                          includeSources: $$event.includeSources,
                          includeDestinations: $$event.includeDestinations,
                          hashes: $$event.hashes,
                          propertyWhitelist: $$event.propertyWhitelist,
                          eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                          userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                          triggers: $$event.triggers,
                          globalRequirementsMetadata: $$event.globalRequirementsMetadata
                        };
                }));
          var init$4 = model$99.archive;
          return {
                  TAG: /* Old */0,
                  _0: {
                    types: init$3.types,
                    properties: init$3.properties,
                    propertyBundles: init$3.propertyBundles,
                    events: init$3.events,
                    migrations: init$3.migrations,
                    sources: init$3.sources,
                    destinations: init$3.destinations,
                    groupTypes: init$3.groupTypes,
                    goals: init$3.goals,
                    metrics: init$3.metrics,
                    archive: {
                      events: init$4.events,
                      properties: init$4.properties,
                      propertyBundles: init$4.propertyBundles,
                      sources: init$4.sources,
                      destinations: init$4.destinations,
                      goals: init$4.goals,
                      metrics: init$4.metrics,
                      groupTypes: init$4.groupTypes,
                      eventVariants: Belt_Option.mapWithDefault(Belt_Option.flatMap(ModelUtils.getEventById(variantIdentifier$6.baseEventId, model$99), (function ($$event) {
                                  return Belt_Array.getBy($$event.variants, (function (param) {
                                                return param.id === variantIdentifier$6.variantId;
                                              }));
                                })), model$99.archive.eventVariants, (function (variant) {
                              return Belt_Array.concat(model$99.archive.eventVariants, [variant]);
                            }))
                    },
                    openBranches: init$3.openBranches,
                    branchPointer: init$3.branchPointer,
                    rules: init$3.rules,
                    integrations: init$3.integrations,
                    globalRequirements: init$3.globalRequirements
                  }
                };
        }
        var model$100 = model._0;
        var init$5 = model$100.archive;
        return {
                TAG: /* Mapped */1,
                _0: {
                  archive: {
                    categories: init$5.categories,
                    destinations: init$5.destinations,
                    events: init$5.events,
                    groupTypes: init$5.groupTypes,
                    metrics: init$5.metrics,
                    properties: init$5.properties,
                    propertyBundles: init$5.propertyBundles,
                    sources: init$5.sources,
                    eventVariants: Belt_Option.mapWithDefault(Belt_Option.flatMap(Curry._2(TrackingPlanMappedModel.Events.get, model$100.events, variantIdentifier$6.baseEventId), (function ($$event) {
                                return Belt_Array.getBy($$event.variants, (function (param) {
                                              return param.id === variantIdentifier$6.variantId;
                                            }));
                              })), model$100.archive.eventVariants, (function (variant) {
                            return Curry._2(TrackingPlanMappedModel.EventVariants.append, model$100.archive.eventVariants, variant);
                          }))
                  },
                  branchPointer: model$100.branchPointer,
                  migrations: model$100.migrations,
                  rules: model$100.rules,
                  types: model$100.types,
                  categories: model$100.categories,
                  destinations: model$100.destinations,
                  events: Curry._3(TrackingPlanMappedModel.Events.update, model$100.events, variantIdentifier$6.baseEventId, (function ($$event) {
                          if ($$event !== undefined) {
                            return {
                                    id: $$event.id,
                                    name: $$event.name,
                                    uniqueName: $$event.uniqueName,
                                    description: $$event.description,
                                    properties: $$event.properties,
                                    propertyBundles: $$event.propertyBundles,
                                    variants: Belt_Array.keepU($$event.variants, (function (param) {
                                            return param.id !== variantIdentifier$6.variantId;
                                          })),
                                    types: $$event.types,
                                    tags: $$event.tags,
                                    excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                    includeSources: $$event.includeSources,
                                    includeDestinations: $$event.includeDestinations,
                                    hashes: $$event.hashes,
                                    propertyWhitelist: $$event.propertyWhitelist,
                                    eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                    userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                    triggers: $$event.triggers,
                                    globalRequirementsMetadata: $$event.globalRequirementsMetadata
                                  };
                          }
                          
                        })),
                  groupTypes: model$100.groupTypes,
                  integrations: model$100.integrations,
                  metrics: model$100.metrics,
                  openBranches: model$100.openBranches,
                  properties: model$100.properties,
                  propertyBundles: model$100.propertyBundles,
                  sources: model$100.sources,
                  globalRequirements: model$100.globalRequirements
                }
              };
      }
      if (variant$1 === "Metric") {
        var metricId$6 = item$1.VAL;
        if (model.TAG === /* Old */0) {
          var model$101 = model._0;
          return {
                  TAG: /* Old */0,
                  _0: {
                    types: model$101.types,
                    properties: model$101.properties,
                    propertyBundles: model$101.propertyBundles,
                    events: model$101.events,
                    migrations: model$101.migrations,
                    sources: model$101.sources,
                    destinations: model$101.destinations,
                    groupTypes: model$101.groupTypes,
                    goals: Belt_List.mapU(model$101.goals, (function (goal) {
                            return {
                                    id: goal.id,
                                    name: goal.name,
                                    description: goal.description,
                                    metrics: Belt_List.keepU(goal.metrics, (function (id) {
                                            return id !== metricId$6;
                                          })),
                                    events: goal.events
                                  };
                          })),
                    metrics: Belt_List.keepU(model$101.metrics, (function (metric) {
                            return metric.id !== metricId$6;
                          })),
                    archive: Belt_Option.mapWithDefault(Belt_List.getByU(model$101.metrics, (function (metric) {
                                return metric.id === metricId$6;
                              })), model$101.archive, (function (metric) {
                            var init = model$101.archive;
                            return {
                                    events: init.events,
                                    properties: init.properties,
                                    propertyBundles: init.propertyBundles,
                                    sources: init.sources,
                                    destinations: init.destinations,
                                    goals: init.goals,
                                    metrics: Belt_List.concat(model$101.archive.metrics, {
                                          hd: metric,
                                          tl: /* [] */0
                                        }),
                                    groupTypes: init.groupTypes,
                                    eventVariants: init.eventVariants
                                  };
                          })),
                    openBranches: model$101.openBranches,
                    branchPointer: model$101.branchPointer,
                    rules: model$101.rules,
                    integrations: model$101.integrations,
                    globalRequirements: model$101.globalRequirements
                  }
                };
        }
        var model$102 = model._0;
        return {
                TAG: /* Mapped */1,
                _0: {
                  archive: Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.Metrics.get, model$102.metrics, metricId$6), model$102.archive, (function (metric) {
                          var init = model$102.archive;
                          return {
                                  categories: init.categories,
                                  destinations: init.destinations,
                                  events: init.events,
                                  groupTypes: init.groupTypes,
                                  metrics: Curry._2(TrackingPlanMappedModel.Metrics.append, model$102.archive.metrics, metric),
                                  properties: init.properties,
                                  propertyBundles: init.propertyBundles,
                                  sources: init.sources,
                                  eventVariants: init.eventVariants
                                };
                        })),
                  branchPointer: model$102.branchPointer,
                  migrations: model$102.migrations,
                  rules: model$102.rules,
                  types: model$102.types,
                  categories: Curry._2(TrackingPlanMappedModel.Categories.mapToT, model$102.categories, (function (category) {
                          return {
                                  id: category.id,
                                  name: category.name,
                                  description: category.description,
                                  metrics: Belt_List.keepU(category.metrics, (function (id) {
                                          return id !== metricId$6;
                                        })),
                                  events: category.events
                                };
                        })),
                  destinations: model$102.destinations,
                  events: model$102.events,
                  groupTypes: model$102.groupTypes,
                  integrations: model$102.integrations,
                  metrics: Curry._2(TrackingPlanMappedModel.Metrics.remove, model$102.metrics, metricId$6),
                  openBranches: model$102.openBranches,
                  properties: model$102.properties,
                  propertyBundles: model$102.propertyBundles,
                  sources: model$102.sources,
                  globalRequirements: model$102.globalRequirements
                }
              };
      }
      var sourceId$14 = item$1.VAL;
      if (model.TAG === /* Old */0) {
        var model$103 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$103.types,
                  properties: model$103.properties,
                  propertyBundles: model$103.propertyBundles,
                  events: model$103.events,
                  migrations: model$103.migrations,
                  sources: Belt_List.keepU(model$103.sources, (function (source) {
                          return source.id !== sourceId$14;
                        })),
                  destinations: model$103.destinations,
                  groupTypes: model$103.groupTypes,
                  goals: model$103.goals,
                  metrics: model$103.metrics,
                  archive: Belt_Option.mapWithDefault(Belt_List.getByU(model$103.sources, (function (source) {
                              return source.id === sourceId$14;
                            })), model$103.archive, (function (source) {
                          var init = model$103.archive;
                          return {
                                  events: init.events,
                                  properties: init.properties,
                                  propertyBundles: init.propertyBundles,
                                  sources: Belt_List.concat(model$103.archive.sources, {
                                        hd: source,
                                        tl: /* [] */0
                                      }),
                                  destinations: init.destinations,
                                  goals: init.goals,
                                  metrics: init.metrics,
                                  groupTypes: init.groupTypes,
                                  eventVariants: init.eventVariants
                                };
                        })),
                  openBranches: model$103.openBranches,
                  branchPointer: model$103.branchPointer,
                  rules: model$103.rules,
                  integrations: model$103.integrations,
                  globalRequirements: model$103.globalRequirements
                }
              };
      }
      var model$104 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.Sources.get, model$104.sources, sourceId$14), model$104.archive, (function (source) {
                        var init = model$104.archive;
                        return {
                                categories: init.categories,
                                destinations: init.destinations,
                                events: init.events,
                                groupTypes: init.groupTypes,
                                metrics: init.metrics,
                                properties: init.properties,
                                propertyBundles: init.propertyBundles,
                                sources: Curry._2(TrackingPlanMappedModel.Sources.append, model$104.archive.sources, source),
                                eventVariants: init.eventVariants
                              };
                      })),
                branchPointer: model$104.branchPointer,
                migrations: model$104.migrations,
                rules: model$104.rules,
                types: model$104.types,
                categories: model$104.categories,
                destinations: model$104.destinations,
                events: model$104.events,
                groupTypes: model$104.groupTypes,
                integrations: model$104.integrations,
                metrics: model$104.metrics,
                openBranches: model$104.openBranches,
                properties: model$104.properties,
                propertyBundles: model$104.propertyBundles,
                sources: Curry._2(TrackingPlanMappedModel.Sources.remove, model$104.sources, sourceId$14),
                globalRequirements: model$104.globalRequirements
              }
            };
    }
    if (variant === "RemovePropertyRefV2Deprecated") {
      var match$62 = action.VAL;
      var propertyId$22 = match$62[1];
      var eventId$17 = match$62[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateProperty(ModelUtils.updateEvent(model._0, eventId$17, (function ($$event) {
                            return {
                                    id: $$event.id,
                                    name: $$event.name,
                                    uniqueName: $$event.uniqueName,
                                    description: $$event.description,
                                    properties: Belt_List.keepU($$event.properties, (function (item) {
                                            return item._0.id !== propertyId$22;
                                          })),
                                    propertyBundles: $$event.propertyBundles,
                                    variants: $$event.variants,
                                    types: $$event.types,
                                    tags: $$event.tags,
                                    excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                    includeSources: $$event.includeSources,
                                    includeDestinations: $$event.includeDestinations,
                                    hashes: $$event.hashes,
                                    propertyWhitelist: Belt_List.keepU($$event.propertyWhitelist, (function (param) {
                                            return param[1] !== propertyId$22;
                                          })),
                                    eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                    userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                    triggers: $$event.triggers,
                                    globalRequirementsMetadata: $$event.globalRequirementsMetadata
                                  };
                          })), propertyId$22, (function (property) {
                        return updatePropertyAbsence(model, property, {
                                    TAG: /* Event */0,
                                    _0: eventId$17
                                  }, undefined);
                      }))
              };
      }
      var model$105 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$105.archive,
                branchPointer: model$105.branchPointer,
                migrations: model$105.migrations,
                rules: model$105.rules,
                types: model$105.types,
                categories: model$105.categories,
                destinations: model$105.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.updateExisting, model$105.events, eventId$17, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: Belt_List.keepU($$event.properties, (function (item) {
                                        return item._0.id !== propertyId$22;
                                      })),
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: Belt_List.keepU($$event.propertyWhitelist, (function (param) {
                                        return param[1] !== propertyId$22;
                                      })),
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      })),
                groupTypes: model$105.groupTypes,
                integrations: model$105.integrations,
                metrics: model$105.metrics,
                openBranches: model$105.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.updateExisting, model$105.properties, propertyId$22, (function (property) {
                        return updatePropertyAbsence(model, property, {
                                    TAG: /* Event */0,
                                    _0: eventId$17
                                  }, undefined);
                      })),
                propertyBundles: model$105.propertyBundles,
                sources: model$105.sources,
                globalRequirements: model$105.globalRequirements
              }
            };
    }
    if (variant === "UpdateIntegrationConfig") {
      var match$63 = action.VAL;
      var config = match$63[1];
      var integrationId$1 = match$63[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateIntegration(model._0, integrationId$1, (function (integration) {
                        return {
                                id: integration.id,
                                name: integration.name,
                                config: config,
                                filters: integration.filters,
                                autoPublish: integration.autoPublish
                              };
                      }))
              };
      }
      var model$106 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$106.archive,
                branchPointer: model$106.branchPointer,
                migrations: model$106.migrations,
                rules: model$106.rules,
                types: model$106.types,
                categories: model$106.categories,
                destinations: model$106.destinations,
                events: model$106.events,
                groupTypes: model$106.groupTypes,
                integrations: Curry._3(TrackingPlanMappedModel.Integrations.updateExisting, model$106.integrations, integrationId$1, (function (integration) {
                        return {
                                id: integration.id,
                                name: integration.name,
                                config: config,
                                filters: integration.filters,
                                autoPublish: integration.autoPublish
                              };
                      })),
                metrics: model$106.metrics,
                openBranches: model$106.openBranches,
                properties: model$106.properties,
                propertyBundles: model$106.propertyBundles,
                sources: model$106.sources,
                globalRequirements: model$106.globalRequirements
              }
            };
    }
    if (variant === "ClearEventVariantPropertyOverride") {
      var match$64 = action.VAL;
      var propertyId$23 = match$64[1];
      var id$6 = match$64[0];
      var update$7 = function (variant) {
        return {
                id: variant.id,
                baseEventId: variant.baseEventId,
                nameSuffix: variant.nameSuffix,
                description: variant.description,
                propertyOverrides: Belt_MapString.remove(variant.propertyOverrides, propertyId$23),
                sourceOverrides: variant.sourceOverrides,
                triggers: variant.triggers
              };
      };
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEventVariant(model._0, id$6, update$7)
              };
      }
      var model$107 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$107.archive,
                branchPointer: model$107.branchPointer,
                migrations: model$107.migrations,
                rules: model$107.rules,
                types: model$107.types,
                categories: model$107.categories,
                destinations: model$107.destinations,
                events: ModelUtils_mapped.updateExistingVariant(model$107.events, id$6, update$7),
                groupTypes: model$107.groupTypes,
                integrations: model$107.integrations,
                metrics: model$107.metrics,
                openBranches: model$107.openBranches,
                properties: model$107.properties,
                propertyBundles: model$107.propertyBundles,
                sources: model$107.sources,
                globalRequirements: model$107.globalRequirements
              }
            };
    }
    if (variant === "UpdatePropertyOperation") {
      var match$65 = action.VAL;
      var operation = match$65[1];
      var propertyId$24 = match$65[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateProperty(model._0, propertyId$24, (function (property) {
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: property.validations,
                                eventSpecificAllowedPropertyValues: property.eventSpecificAllowedPropertyValues,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence,
                                globalRequirementsMetadata: property.globalRequirementsMetadata
                              };
                      }))
              };
      }
      var model$108 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$108.archive,
                branchPointer: model$108.branchPointer,
                migrations: model$108.migrations,
                rules: model$108.rules,
                types: model$108.types,
                categories: model$108.categories,
                destinations: model$108.destinations,
                events: model$108.events,
                groupTypes: model$108.groupTypes,
                integrations: model$108.integrations,
                metrics: model$108.metrics,
                openBranches: model$108.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.updateExisting, model$108.properties, propertyId$24, (function (property) {
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: property.validations,
                                eventSpecificAllowedPropertyValues: property.eventSpecificAllowedPropertyValues,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence,
                                globalRequirementsMetadata: property.globalRequirementsMetadata
                              };
                      })),
                propertyBundles: model$108.propertyBundles,
                sources: model$108.sources,
                globalRequirements: model$108.globalRequirements
              }
            };
    }
    if (variant === "TogglePropertyExcludedSource") {
      var match$66 = action.VAL;
      var isActive$2 = match$66[2];
      var sourceId$15 = match$66[1];
      var propertyId$25 = match$66[0];
      if (model.TAG === /* Old */0) {
        var model$109 = model._0;
        return {
                TAG: /* Old */0,
                _0: ModelUtils.hasMigrated(model$109, "PropertyOptionalAndExcludedSourcesToAbsence") ? Belt_List.reduceU(ModelUtils.eventsSendingProperty(model$109, undefined, propertyId$25), model$109, (function (innerModel, $$event) {
                          if (Belt_List.someU($$event.includeSources, (function (includedSource) {
                                    return includedSource.id === sourceId$15;
                                  }))) {
                            return ModelUtils.updateProperty(model$109, propertyId$25, (function (property) {
                                          return updatePropertyAbsence({
                                                      TAG: /* Old */0,
                                                      _0: innerModel
                                                    }, property, {
                                                      TAG: /* EventSource */2,
                                                      _0: $$event.id,
                                                      _1: sourceId$15
                                                    }, isActive$2 ? /* NeverSent */0 : (
                                                        property.optionalDeprecated ? /* SometimesSent */({
                                                              _0: ""
                                                            }) : undefined
                                                      ));
                                        }));
                          } else {
                            return innerModel;
                          }
                        })) : ModelUtils.updateProperty(model$109, propertyId$25, (function (property) {
                          return {
                                  id: property.id,
                                  name: property.name,
                                  uniqueName: property.uniqueName,
                                  description: property.description,
                                  type_: property.type_,
                                  sendAs: property.sendAs,
                                  validations: property.validations,
                                  eventSpecificAllowedPropertyValues: property.eventSpecificAllowedPropertyValues,
                                  auto: property.auto,
                                  builtIn: property.builtIn,
                                  analyticsTools: property.analyticsTools,
                                  devPlatforms: property.devPlatforms,
                                  list: property.list,
                                  operation: property.operation,
                                  optionalDeprecated: property.optionalDeprecated,
                                  excludedSourcesDeprecated: isActive$2 ? Belt_List.keepU(property.excludedSourcesDeprecated, (function (excludedSourceId) {
                                            return excludedSourceId !== sourceId$15;
                                          })) : Belt_List.concat(Belt_List.keepU(property.excludedSourcesDeprecated, (function (excludedSourceId) {
                                                return excludedSourceId !== sourceId$15;
                                              })), {
                                          hd: sourceId$15,
                                          tl: /* [] */0
                                        }),
                                  optionalWhenInObject: property.optionalWhenInObject,
                                  absence: property.absence,
                                  globalRequirementsMetadata: property.globalRequirementsMetadata
                                };
                        }))
              };
      }
      var model$110 = model._0;
      if (ModelUtils_mapped.hasMigrated(model$110, "PropertyOptionalAndExcludedSourcesToAbsence")) {
        return {
                TAG: /* Mapped */1,
                _0: Curry._3(TrackingPlanMappedModel.Events.reduce, ModelUtils_mapped.eventsSendingProperty(model$110.events, model$110.propertyBundles, undefined, propertyId$25), model$110, (function (innerModel, $$event) {
                        if (Belt_List.someU($$event.includeSources, (function (includedSource) {
                                  return includedSource.id === sourceId$15;
                                }))) {
                          return {
                                  archive: model$110.archive,
                                  branchPointer: model$110.branchPointer,
                                  migrations: model$110.migrations,
                                  rules: model$110.rules,
                                  types: model$110.types,
                                  categories: model$110.categories,
                                  destinations: model$110.destinations,
                                  events: model$110.events,
                                  groupTypes: model$110.groupTypes,
                                  integrations: model$110.integrations,
                                  metrics: model$110.metrics,
                                  openBranches: model$110.openBranches,
                                  properties: Curry._3(TrackingPlanMappedModel.Properties.updateExisting, innerModel.properties, propertyId$25, (function (property) {
                                          return updatePropertyAbsence({
                                                      TAG: /* Mapped */1,
                                                      _0: innerModel
                                                    }, property, {
                                                      TAG: /* EventSource */2,
                                                      _0: $$event.id,
                                                      _1: sourceId$15
                                                    }, isActive$2 ? /* NeverSent */0 : (
                                                        property.optionalDeprecated ? /* SometimesSent */({
                                                              _0: ""
                                                            }) : undefined
                                                      ));
                                        })),
                                  propertyBundles: model$110.propertyBundles,
                                  sources: model$110.sources,
                                  globalRequirements: model$110.globalRequirements
                                };
                        } else {
                          return innerModel;
                        }
                      }))
              };
      } else {
        return {
                TAG: /* Mapped */1,
                _0: {
                  archive: model$110.archive,
                  branchPointer: model$110.branchPointer,
                  migrations: model$110.migrations,
                  rules: model$110.rules,
                  types: model$110.types,
                  categories: model$110.categories,
                  destinations: model$110.destinations,
                  events: model$110.events,
                  groupTypes: model$110.groupTypes,
                  integrations: model$110.integrations,
                  metrics: model$110.metrics,
                  openBranches: model$110.openBranches,
                  properties: Curry._3(TrackingPlanMappedModel.Properties.updateExisting, model$110.properties, propertyId$25, (function (property) {
                          return {
                                  id: property.id,
                                  name: property.name,
                                  uniqueName: property.uniqueName,
                                  description: property.description,
                                  type_: property.type_,
                                  sendAs: property.sendAs,
                                  validations: property.validations,
                                  eventSpecificAllowedPropertyValues: property.eventSpecificAllowedPropertyValues,
                                  auto: property.auto,
                                  builtIn: property.builtIn,
                                  analyticsTools: property.analyticsTools,
                                  devPlatforms: property.devPlatforms,
                                  list: property.list,
                                  operation: property.operation,
                                  optionalDeprecated: property.optionalDeprecated,
                                  excludedSourcesDeprecated: isActive$2 ? Belt_List.keepU(property.excludedSourcesDeprecated, (function (excludedSourceId) {
                                            return excludedSourceId !== sourceId$15;
                                          })) : Belt_List.concat(Belt_List.keepU(property.excludedSourcesDeprecated, (function (excludedSourceId) {
                                                return excludedSourceId !== sourceId$15;
                                              })), {
                                          hd: sourceId$15,
                                          tl: /* [] */0
                                        }),
                                  optionalWhenInObject: property.optionalWhenInObject,
                                  absence: property.absence,
                                  globalRequirementsMetadata: property.globalRequirementsMetadata
                                };
                        })),
                  propertyBundles: model$110.propertyBundles,
                  sources: model$110.sources,
                  globalRequirements: model$110.globalRequirements
                }
              };
      }
    }
    if (variant === "RemoveTag") {
      var match$67 = action.VAL;
      var tag$1 = match$67[1];
      var eventId$18 = match$67[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEvent(model._0, eventId$18, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: Belt_List.keepU($$event.tags, (function (eventTag) {
                                        return eventTag !== tag$1;
                                      })),
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      }))
              };
      }
      var model$111 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$111.archive,
                branchPointer: model$111.branchPointer,
                migrations: model$111.migrations,
                rules: model$111.rules,
                types: model$111.types,
                categories: model$111.categories,
                destinations: model$111.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.updateExisting, model$111.events, eventId$18, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: Belt_List.keepU($$event.tags, (function (eventTag) {
                                        return eventTag !== tag$1;
                                      })),
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      })),
                groupTypes: model$111.groupTypes,
                integrations: model$111.integrations,
                metrics: model$111.metrics,
                openBranches: model$111.openBranches,
                properties: model$111.properties,
                propertyBundles: model$111.propertyBundles,
                sources: model$111.sources,
                globalRequirements: model$111.globalRequirements
              }
            };
    }
    if (variant === "AddUserToGroup") {
      var match$68 = action.VAL;
      var groupTypeId$2 = match$68[1];
      var eventId$19 = match$68[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEvent(model._0, eventId$19, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: Belt_Array.concat($$event.userGroupTypeIdsWithArchive, [groupTypeId$2]),
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      }))
              };
      }
      var model$112 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$112.archive,
                branchPointer: model$112.branchPointer,
                migrations: model$112.migrations,
                rules: model$112.rules,
                types: model$112.types,
                categories: model$112.categories,
                destinations: model$112.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.updateExisting, model$112.events, eventId$19, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: Belt_Array.concat($$event.userGroupTypeIdsWithArchive, [groupTypeId$2]),
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      })),
                groupTypes: model$112.groupTypes,
                integrations: model$112.integrations,
                metrics: model$112.metrics,
                openBranches: model$112.openBranches,
                properties: model$112.properties,
                propertyBundles: model$112.propertyBundles,
                sources: model$112.sources,
                globalRequirements: model$112.globalRequirements
              }
            };
    }
    if (variant === "SetEventSpecificPropertyValueSources") {
      var match$69 = action.VAL;
      var removedAllowedSourcesSet = match$69[4];
      var addedAllowedSourcesSet = match$69[3];
      var literal$2 = match$69[2];
      var propertyId$26 = match$69[1];
      var eventId$20 = match$69[0];
      var setOrUpdateEventSpecificAllowedPropertyValue = function (values, literal, updater) {
        var alreadyHasLiteral = Belt_Array.some(values, (function (param) {
                return Caml_obj.caml_equal(param[0], literal);
              }));
        if (alreadyHasLiteral) {
          return Belt_Array.keepMap(values, (function (param) {
                        var sources = param[1];
                        var thisLiteral = param[0];
                        if (Caml_obj.caml_equal(thisLiteral, literal)) {
                          return Curry._1(updater, [
                                      thisLiteral,
                                      sources
                                    ]);
                        } else {
                          return [
                                  thisLiteral,
                                  sources
                                ];
                        }
                      }));
        }
        var newEntry = Curry._1(updater, undefined);
        if (newEntry !== undefined) {
          return Belt_Array.concat(values, [newEntry]);
        } else {
          return values;
        }
      };
      var setAllowedValueSources = function (property, allTargetSourceIds, eventId) {
        var getNextDisallowedState = function (disallowedSourcesSet) {
          var disallowedSourcesSet$1 = Belt_SetString.intersect(disallowedSourcesSet, allTargetSourceIds);
          if (Belt_SetString.isEmpty(disallowedSourcesSet$1)) {
            return ;
          } else if (Belt_SetString.eq(disallowedSourcesSet$1, allTargetSourceIds)) {
            return /* Disallowed */0;
          } else {
            return /* DisallowedSources */{
                    _0: disallowedSourcesSet$1
                  };
          }
        };
        return {
                id: property.id,
                name: property.name,
                uniqueName: property.uniqueName,
                description: property.description,
                type_: property.type_,
                sendAs: property.sendAs,
                validations: property.validations,
                eventSpecificAllowedPropertyValues: setOrUpdateEventSpecificAllowedPropertyValue(property.eventSpecificAllowedPropertyValues, literal$2, (function (maybeLiteral) {
                        if (maybeLiteral !== undefined) {
                          return [
                                  maybeLiteral[0],
                                  Belt_MapString.update(maybeLiteral[1], eventId, (function (maybeDisallowedSourcesBefore) {
                                          if (maybeDisallowedSourcesBefore !== undefined) {
                                            if (maybeDisallowedSourcesBefore) {
                                              return getNextDisallowedState(Belt_SetString.diff(Belt_SetString.union(maybeDisallowedSourcesBefore._0, removedAllowedSourcesSet), addedAllowedSourcesSet));
                                            } else {
                                              return getNextDisallowedState(Belt_SetString.diff(Belt_SetString.union(allTargetSourceIds, removedAllowedSourcesSet), addedAllowedSourcesSet));
                                            }
                                          } else {
                                            return getNextDisallowedState(Belt_SetString.diff(removedAllowedSourcesSet, addedAllowedSourcesSet));
                                          }
                                        }))
                                ];
                        }
                        
                      })),
                auto: property.auto,
                builtIn: property.builtIn,
                analyticsTools: property.analyticsTools,
                devPlatforms: property.devPlatforms,
                list: property.list,
                operation: property.operation,
                optionalDeprecated: property.optionalDeprecated,
                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                optionalWhenInObject: property.optionalWhenInObject,
                absence: property.absence,
                globalRequirementsMetadata: property.globalRequirementsMetadata
              };
      };
      var setEventSpecificPropertyValueSources = function (property, $$event) {
        var eventIncludeSourcesSet = Belt_SetString.fromArray(Belt_Array.mapU(Belt_List.toArray($$event.includeSources), (function (source) {
                    return source.id;
                  })));
        return setAllowedValueSources(property, eventIncludeSourcesSet, eventId$20);
      };
      if (model.TAG === /* Old */0) {
        var model$113 = model._0;
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateProperty(model$113, propertyId$26, (function (property) {
                        if (property.sendAs === /* SystemProperty */0) {
                          var allSourcesSet = Belt_SetString.fromArray(Belt_Array.mapU(Belt_List.toArray(model$113.sources), (function (source) {
                                      return source.id;
                                    })));
                          return setAllowedValueSources(property, allSourcesSet, GetEventIdsSendingPropertyForEventSpecificValuesUseCase.propertyValuesAllEventsKey);
                        }
                        var $$event = ModelUtils.getEventById(eventId$20, model$113);
                        if ($$event !== undefined) {
                          return setEventSpecificPropertyValueSources(property, $$event);
                        } else {
                          return property;
                        }
                      }))
              };
      }
      var model$114 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$114.archive,
                branchPointer: model$114.branchPointer,
                migrations: model$114.migrations,
                rules: model$114.rules,
                types: model$114.types,
                categories: model$114.categories,
                destinations: model$114.destinations,
                events: model$114.events,
                groupTypes: model$114.groupTypes,
                integrations: model$114.integrations,
                metrics: model$114.metrics,
                openBranches: model$114.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.updateExisting, model$114.properties, propertyId$26, (function (property) {
                        if (property.sendAs === /* SystemProperty */0) {
                          var allSourcesSet = Belt_SetString.fromArray(Belt_Array.mapU(Curry._1(TrackingPlanMappedModel.Sources.toArray, model$114.sources), (function (source) {
                                      return source.id;
                                    })));
                          return setAllowedValueSources(property, allSourcesSet, GetEventIdsSendingPropertyForEventSpecificValuesUseCase.propertyValuesAllEventsKey);
                        }
                        var $$event = Curry._2(TrackingPlanMappedModel.Events.get, model$114.events, eventId$20);
                        if ($$event !== undefined) {
                          return setEventSpecificPropertyValueSources(property, $$event);
                        } else {
                          return property;
                        }
                      })),
                propertyBundles: model$114.propertyBundles,
                sources: model$114.sources,
                globalRequirements: model$114.globalRequirements
              }
            };
    }
    if (variant === "MigrateExcludeEventsToIncludeEvents") {
      var sourceIds = action.VAL;
      if (model.TAG === /* Old */0) {
        var model$115 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$115.types,
                  properties: model$115.properties,
                  propertyBundles: model$115.propertyBundles,
                  events: Belt_List.mapU(model$115.events, (function ($$event) {
                          return {
                                  id: $$event.id,
                                  name: $$event.name,
                                  uniqueName: $$event.uniqueName,
                                  description: $$event.description,
                                  properties: $$event.properties,
                                  propertyBundles: $$event.propertyBundles,
                                  variants: $$event.variants,
                                  types: $$event.types,
                                  tags: $$event.tags,
                                  excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                  includeSources: Belt_List.map(Belt_List.keepU(sourceIds, (function (sourceId) {
                                              return Belt_Option.mapWithDefault(Belt_List.getByU($$event.excludeSourcesDeprecated, (function (excludedSourceId) {
                                                                return excludedSourceId === sourceId;
                                                              })), true, (function (param) {
                                                            return false;
                                                          }));
                                            })), (function (sourceId) {
                                          return {
                                                  id: sourceId,
                                                  includeInCodegen: true,
                                                  inspectorValidation: undefined
                                                };
                                        })),
                                  includeDestinations: $$event.includeDestinations,
                                  hashes: $$event.hashes,
                                  propertyWhitelist: $$event.propertyWhitelist,
                                  eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                  userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                  triggers: $$event.triggers,
                                  globalRequirementsMetadata: $$event.globalRequirementsMetadata
                                };
                        })),
                  migrations: {
                    hd: "ExplicitIncludeSource",
                    tl: model$115.migrations
                  },
                  sources: model$115.sources,
                  destinations: model$115.destinations,
                  groupTypes: model$115.groupTypes,
                  goals: model$115.goals,
                  metrics: model$115.metrics,
                  archive: model$115.archive,
                  openBranches: model$115.openBranches,
                  branchPointer: model$115.branchPointer,
                  rules: model$115.rules,
                  integrations: model$115.integrations,
                  globalRequirements: model$115.globalRequirements
                }
              };
      }
      var model$116 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$116.archive,
                branchPointer: model$116.branchPointer,
                migrations: Belt_Array.concat(["ExplicitIncludeSource"], model$116.migrations),
                rules: model$116.rules,
                types: model$116.types,
                categories: model$116.categories,
                destinations: model$116.destinations,
                events: Curry._2(TrackingPlanMappedModel.Events.mapToT, model$116.events, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: Belt_List.map(Belt_List.keepU(sourceIds, (function (sourceId) {
                                            return Belt_Option.mapWithDefault(Belt_List.getByU($$event.excludeSourcesDeprecated, (function (excludedSourceId) {
                                                              return excludedSourceId === sourceId;
                                                            })), true, (function (param) {
                                                          return false;
                                                        }));
                                          })), (function (sourceId) {
                                        return {
                                                id: sourceId,
                                                includeInCodegen: true,
                                                inspectorValidation: undefined
                                              };
                                      })),
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      })),
                groupTypes: model$116.groupTypes,
                integrations: model$116.integrations,
                metrics: model$116.metrics,
                openBranches: model$116.openBranches,
                properties: model$116.properties,
                propertyBundles: model$116.propertyBundles,
                sources: model$116.sources,
                globalRequirements: model$116.globalRequirements
              }
            };
    }
    if (variant === "UpdateEventVariantDescription") {
      var match$70 = action.VAL;
      var description$3 = match$70[1];
      var id$7 = match$70[0];
      var update$8 = function (variant) {
        return {
                id: variant.id,
                baseEventId: variant.baseEventId,
                nameSuffix: variant.nameSuffix,
                description: description$3,
                propertyOverrides: variant.propertyOverrides,
                sourceOverrides: variant.sourceOverrides,
                triggers: variant.triggers
              };
      };
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEventVariant(model._0, id$7, update$8)
              };
      }
      var model$117 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$117.archive,
                branchPointer: model$117.branchPointer,
                migrations: model$117.migrations,
                rules: model$117.rules,
                types: model$117.types,
                categories: model$117.categories,
                destinations: model$117.destinations,
                events: ModelUtils_mapped.updateExistingVariant(model$117.events, id$7, update$8),
                groupTypes: model$117.groupTypes,
                integrations: model$117.integrations,
                metrics: model$117.metrics,
                openBranches: model$117.openBranches,
                properties: model$117.properties,
                propertyBundles: model$117.propertyBundles,
                sources: model$117.sources,
                globalRequirements: model$117.globalRequirements
              }
            };
    }
    if (variant === "DeprecatedUpdatePropertyValidationMinOrMax") {
      var match$71 = action.VAL;
      var validationIndex = match$71[3];
      var validationType = match$71[2];
      var newValue = match$71[1];
      var propertyId$27 = match$71[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updatePropertyValidations(model._0, propertyId$27, (function (validations, param) {
                        var value = Belt_List.get(validations, validationIndex);
                        var updatedValidation;
                        if (value !== undefined) {
                          if (typeof value === "object") {
                            var variant = value.NAME;
                            if (variant === "Min" || variant === "Max") {
                              var match = value.VAL;
                              if (typeof match === "object") {
                                var variant$1 = match.NAME;
                                if (variant$1 === "IntLit") {
                                  var validationValue_1 = Caml_format.caml_int_of_string(newValue);
                                  var validationValue = {
                                    NAME: "IntLit",
                                    VAL: validationValue_1
                                  };
                                  updatedValidation = validationType === "Max" ? ({
                                        NAME: "Max",
                                        VAL: validationValue
                                      }) : (
                                      validationType === "Min" ? ({
                                            NAME: "Min",
                                            VAL: validationValue
                                          }) : Pervasives.failwith("Cannot convert to anything else than Min and Max")
                                    );
                                } else if (variant$1 === "FloatLit") {
                                  var validationValue_1$1 = Caml_format.caml_float_of_string(newValue);
                                  var validationValue$1 = {
                                    NAME: "FloatLit",
                                    VAL: validationValue_1$1
                                  };
                                  updatedValidation = validationType === "Max" ? ({
                                        NAME: "Max",
                                        VAL: validationValue$1
                                      }) : (
                                      validationType === "Min" ? ({
                                            NAME: "Min",
                                            VAL: validationValue$1
                                          }) : Pervasives.failwith("Cannot convert to anything else than Min and Max")
                                    );
                                } else {
                                  updatedValidation = value;
                                }
                              } else {
                                updatedValidation = value;
                              }
                            } else {
                              updatedValidation = value;
                            }
                          } else {
                            updatedValidation = value;
                          }
                        } else {
                          updatedValidation = Pervasives.failwith("Invalid index");
                        }
                        return BeltListExtensions.setValueAtIndex(validationIndex, updatedValidation, validations);
                      }))
              };
      }
      var model$118 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$118.archive,
                branchPointer: model$118.branchPointer,
                migrations: model$118.migrations,
                rules: model$118.rules,
                types: model$118.types,
                categories: model$118.categories,
                destinations: model$118.destinations,
                events: model$118.events,
                groupTypes: model$118.groupTypes,
                integrations: model$118.integrations,
                metrics: model$118.metrics,
                openBranches: model$118.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.updateExisting, model$118.properties, propertyId$27, (function (property) {
                        var value = Belt_List.get(property.validations, validationIndex);
                        var updatedValidation;
                        if (value !== undefined) {
                          if (typeof value === "object") {
                            var variant = value.NAME;
                            if (variant === "Min" || variant === "Max") {
                              var match = value.VAL;
                              if (typeof match === "object") {
                                var variant$1 = match.NAME;
                                if (variant$1 === "IntLit") {
                                  var validationValue_1 = Caml_format.caml_int_of_string(newValue);
                                  var validationValue = {
                                    NAME: "IntLit",
                                    VAL: validationValue_1
                                  };
                                  updatedValidation = validationType === "Max" ? ({
                                        NAME: "Max",
                                        VAL: validationValue
                                      }) : (
                                      validationType === "Min" ? ({
                                            NAME: "Min",
                                            VAL: validationValue
                                          }) : Pervasives.failwith("Cannot convert to anything else than Min and Max")
                                    );
                                } else if (variant$1 === "FloatLit") {
                                  var validationValue_1$1 = Caml_format.caml_float_of_string(newValue);
                                  var validationValue$1 = {
                                    NAME: "FloatLit",
                                    VAL: validationValue_1$1
                                  };
                                  updatedValidation = validationType === "Max" ? ({
                                        NAME: "Max",
                                        VAL: validationValue$1
                                      }) : (
                                      validationType === "Min" ? ({
                                            NAME: "Min",
                                            VAL: validationValue$1
                                          }) : Pervasives.failwith("Cannot convert to anything else than Min and Max")
                                    );
                                } else {
                                  updatedValidation = value;
                                }
                              } else {
                                updatedValidation = value;
                              }
                            } else {
                              updatedValidation = value;
                            }
                          } else {
                            updatedValidation = value;
                          }
                        } else {
                          updatedValidation = Pervasives.failwith("Invalid index");
                        }
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: BeltListExtensions.setValueAtIndex(validationIndex, updatedValidation, property.validations),
                                eventSpecificAllowedPropertyValues: property.eventSpecificAllowedPropertyValues,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence,
                                globalRequirementsMetadata: property.globalRequirementsMetadata
                              };
                      })),
                propertyBundles: model$118.propertyBundles,
                sources: model$118.sources,
                globalRequirements: model$118.globalRequirements
              }
            };
    }
    if (variant === "RemovePropertyRefV3") {
      var match$72 = action.VAL;
      var propertyId$28 = match$72[1];
      var eventId$21 = match$72[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEvent(model._0, eventId$21, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: Belt_List.keepU($$event.properties, (function (item) {
                                        return item._0.id !== propertyId$28;
                                      })),
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: Belt_List.keepU($$event.propertyWhitelist, (function (param) {
                                        return param[1] !== propertyId$28;
                                      })),
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      }))
              };
      }
      var model$119 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$119.archive,
                branchPointer: model$119.branchPointer,
                migrations: model$119.migrations,
                rules: model$119.rules,
                types: model$119.types,
                categories: model$119.categories,
                destinations: model$119.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.updateExisting, model$119.events, eventId$21, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: Belt_List.keepU($$event.properties, (function (item) {
                                        return item._0.id !== propertyId$28;
                                      })),
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: Belt_List.keepU($$event.propertyWhitelist, (function (param) {
                                        return param[1] !== propertyId$28;
                                      })),
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      })),
                groupTypes: model$119.groupTypes,
                integrations: model$119.integrations,
                metrics: model$119.metrics,
                openBranches: model$119.openBranches,
                properties: model$119.properties,
                propertyBundles: model$119.propertyBundles,
                sources: model$119.sources,
                globalRequirements: model$119.globalRequirements
              }
            };
    }
    if (variant === "RemoveIntegration") {
      var integrationId$2 = action.VAL;
      if (model.TAG === /* Old */0) {
        var model$120 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$120.types,
                  properties: model$120.properties,
                  propertyBundles: model$120.propertyBundles,
                  events: model$120.events,
                  migrations: model$120.migrations,
                  sources: model$120.sources,
                  destinations: model$120.destinations,
                  groupTypes: model$120.groupTypes,
                  goals: model$120.goals,
                  metrics: model$120.metrics,
                  archive: model$120.archive,
                  openBranches: model$120.openBranches,
                  branchPointer: model$120.branchPointer,
                  rules: model$120.rules,
                  integrations: Belt_List.keepU(model$120.integrations, (function (integration) {
                          return integration.id !== integrationId$2;
                        })),
                  globalRequirements: model$120.globalRequirements
                }
              };
      }
      var model$121 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$121.archive,
                branchPointer: model$121.branchPointer,
                migrations: model$121.migrations,
                rules: model$121.rules,
                types: model$121.types,
                categories: model$121.categories,
                destinations: model$121.destinations,
                events: model$121.events,
                groupTypes: model$121.groupTypes,
                integrations: Curry._2(TrackingPlanMappedModel.Integrations.remove, model$121.integrations, integrationId$2),
                metrics: model$121.metrics,
                openBranches: model$121.openBranches,
                properties: model$121.properties,
                propertyBundles: model$121.propertyBundles,
                sources: model$121.sources,
                globalRequirements: model$121.globalRequirements
              }
            };
    }
    if (variant === "SetPropertyAbsenceOnVariant") {
      var match$73 = action.VAL;
      var absenceOverride = match$73[2];
      var propertyId$29 = match$73[1];
      var variantIdentifier$7 = match$73[0];
      var setPropertyAbsenceOverride = function (variant) {
        return {
                id: variant.id,
                baseEventId: variant.baseEventId,
                nameSuffix: variant.nameSuffix,
                description: variant.description,
                propertyOverrides: Belt_MapString.update(variant.propertyOverrides, propertyId$29, (function (override) {
                        if (override === undefined) {
                          return /* Overridden */{
                                  _0: {
                                    pinnedValue: /* NoneOverride */0,
                                    absence: absenceOverride,
                                    allowedValues: /* NoneOverride */0,
                                    regexValidation: /* NoneOverride */0
                                  }
                                };
                        }
                        if (!override) {
                          return override;
                        }
                        var overridden = override._0;
                        return /* Overridden */{
                                _0: {
                                  pinnedValue: overridden.pinnedValue,
                                  absence: absenceOverride,
                                  allowedValues: overridden.allowedValues,
                                  regexValidation: overridden.regexValidation
                                }
                              };
                      })),
                sourceOverrides: variant.sourceOverrides,
                triggers: variant.triggers
              };
      };
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEventVariant(model._0, variantIdentifier$7, setPropertyAbsenceOverride)
              };
      }
      var model$122 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$122.archive,
                branchPointer: model$122.branchPointer,
                migrations: model$122.migrations,
                rules: model$122.rules,
                types: model$122.types,
                categories: model$122.categories,
                destinations: model$122.destinations,
                events: ModelUtils_mapped.updateExistingVariant(model$122.events, variantIdentifier$7, setPropertyAbsenceOverride),
                groupTypes: model$122.groupTypes,
                integrations: model$122.integrations,
                metrics: model$122.metrics,
                openBranches: model$122.openBranches,
                properties: model$122.properties,
                propertyBundles: model$122.propertyBundles,
                sources: model$122.sources,
                globalRequirements: model$122.globalRequirements
              }
            };
    }
    if (variant === "UpdateDestinationApiKey") {
      var match$74 = action.VAL;
      var apiKey = match$74[2];
      var envName$1 = match$74[1];
      var destinationId$7 = match$74[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateDestination(model._0, destinationId$7, (function (destination) {
                        var init = destination.apiKeys;
                        var maybeKeyPair = Belt_List.getByU(destination.apiKeys.other, (function (param) {
                                return param[0] === envName$1;
                              }));
                        return {
                                id: destination.id,
                                name: destination.name,
                                type_: destination.type_,
                                apiKeys: {
                                  dev: init.dev,
                                  prod: init.prod,
                                  other: maybeKeyPair !== undefined ? Belt_List.mapU(destination.apiKeys.other, (function (keyPair) {
                                            if (keyPair[0] === envName$1) {
                                              return [
                                                      envName$1,
                                                      apiKey
                                                    ];
                                            } else {
                                              return keyPair;
                                            }
                                          })) : Belt_List.concat(destination.apiKeys.other, {
                                          hd: [
                                            envName$1,
                                            apiKey
                                          ],
                                          tl: /* [] */0
                                        })
                                },
                                includeUserPropsWithEventProps: destination.includeUserPropsWithEventProps,
                                disabledByDefault: destination.disabledByDefault
                              };
                      }))
              };
      }
      var model$123 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$123.archive,
                branchPointer: model$123.branchPointer,
                migrations: model$123.migrations,
                rules: model$123.rules,
                types: model$123.types,
                categories: model$123.categories,
                destinations: Curry._3(TrackingPlanMappedModel.Destinations.updateExisting, model$123.destinations, destinationId$7, (function (destination) {
                        var init = destination.apiKeys;
                        var maybeKeyPair = Belt_List.getByU(destination.apiKeys.other, (function (param) {
                                return param[0] === envName$1;
                              }));
                        return {
                                id: destination.id,
                                name: destination.name,
                                type_: destination.type_,
                                apiKeys: {
                                  dev: init.dev,
                                  prod: init.prod,
                                  other: maybeKeyPair !== undefined ? Belt_List.mapU(destination.apiKeys.other, (function (keyPair) {
                                            if (keyPair[0] === envName$1) {
                                              return [
                                                      envName$1,
                                                      apiKey
                                                    ];
                                            } else {
                                              return keyPair;
                                            }
                                          })) : Belt_List.concat(destination.apiKeys.other, {
                                          hd: [
                                            envName$1,
                                            apiKey
                                          ],
                                          tl: /* [] */0
                                        })
                                },
                                includeUserPropsWithEventProps: destination.includeUserPropsWithEventProps,
                                disabledByDefault: destination.disabledByDefault
                              };
                      })),
                events: model$123.events,
                groupTypes: model$123.groupTypes,
                integrations: model$123.integrations,
                metrics: model$123.metrics,
                openBranches: model$123.openBranches,
                properties: model$123.properties,
                propertyBundles: model$123.propertyBundles,
                sources: model$123.sources,
                globalRequirements: model$123.globalRequirements
              }
            };
    }
    if (variant === "UpdateDestinationName") {
      var match$75 = action.VAL;
      var name$5 = match$75[1];
      var destinationId$8 = match$75[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateDestination(model._0, destinationId$8, (function (destination) {
                        return {
                                id: destination.id,
                                name: name$5,
                                type_: destination.type_,
                                apiKeys: destination.apiKeys,
                                includeUserPropsWithEventProps: destination.includeUserPropsWithEventProps,
                                disabledByDefault: destination.disabledByDefault
                              };
                      }))
              };
      }
      var model$124 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$124.archive,
                branchPointer: model$124.branchPointer,
                migrations: model$124.migrations,
                rules: model$124.rules,
                types: model$124.types,
                categories: model$124.categories,
                destinations: Curry._3(TrackingPlanMappedModel.Destinations.updateExisting, model$124.destinations, destinationId$8, (function (destination) {
                        return {
                                id: destination.id,
                                name: name$5,
                                type_: destination.type_,
                                apiKeys: destination.apiKeys,
                                includeUserPropsWithEventProps: destination.includeUserPropsWithEventProps,
                                disabledByDefault: destination.disabledByDefault
                              };
                      })),
                events: model$124.events,
                groupTypes: model$124.groupTypes,
                integrations: model$124.integrations,
                metrics: model$124.metrics,
                openBranches: model$124.openBranches,
                properties: model$124.properties,
                propertyBundles: model$124.propertyBundles,
                sources: model$124.sources,
                globalRequirements: model$124.globalRequirements
              }
            };
    }
    if (variant === "ToggleIncludeEventInCodegenForSource") {
      var match$76 = action.VAL;
      var includeInCodegen = match$76[2];
      var sourceId$16 = match$76[1];
      var eventId$22 = match$76[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEvent(model._0, eventId$22, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: Belt_List.mapU($$event.includeSources, (function (includeInSource) {
                                        if (includeInSource.id === sourceId$16) {
                                          return {
                                                  id: includeInSource.id,
                                                  includeInCodegen: includeInCodegen,
                                                  inspectorValidation: includeInSource.inspectorValidation
                                                };
                                        } else {
                                          return includeInSource;
                                        }
                                      })),
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      }))
              };
      }
      var model$125 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$125.archive,
                branchPointer: model$125.branchPointer,
                migrations: model$125.migrations,
                rules: model$125.rules,
                types: model$125.types,
                categories: model$125.categories,
                destinations: model$125.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.updateExisting, model$125.events, eventId$22, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: Belt_List.mapU($$event.includeSources, (function (includeInSource) {
                                        if (includeInSource.id === sourceId$16) {
                                          return {
                                                  id: includeInSource.id,
                                                  includeInCodegen: includeInCodegen,
                                                  inspectorValidation: includeInSource.inspectorValidation
                                                };
                                        } else {
                                          return includeInSource;
                                        }
                                      })),
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      })),
                groupTypes: model$125.groupTypes,
                integrations: model$125.integrations,
                metrics: model$125.metrics,
                openBranches: model$125.openBranches,
                properties: model$125.properties,
                propertyBundles: model$125.propertyBundles,
                sources: model$125.sources,
                globalRequirements: model$125.globalRequirements
              }
            };
    }
    if (variant === "UpdateLocalWorkspaceName") {
      var match$77 = action.VAL;
      var name$6 = match$77[1];
      var localWorkspaceId$1 = match$77[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateSource(model._0, localWorkspaceId$1, (function (source) {
                        return {
                                id: source.id,
                                name: name$6,
                                language: source.language,
                                platform: source.platform,
                                destinations: source.destinations,
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: source.config
                              };
                      }))
              };
      }
      var model$126 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$126.archive,
                branchPointer: model$126.branchPointer,
                migrations: model$126.migrations,
                rules: model$126.rules,
                types: model$126.types,
                categories: model$126.categories,
                destinations: model$126.destinations,
                events: model$126.events,
                groupTypes: model$126.groupTypes,
                integrations: model$126.integrations,
                metrics: model$126.metrics,
                openBranches: model$126.openBranches,
                properties: model$126.properties,
                propertyBundles: model$126.propertyBundles,
                sources: Curry._3(TrackingPlanMappedModel.Sources.updateExisting, model$126.sources, localWorkspaceId$1, (function (source) {
                        return {
                                id: source.id,
                                name: name$6,
                                language: source.language,
                                platform: source.platform,
                                destinations: source.destinations,
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: source.config
                              };
                      })),
                globalRequirements: model$126.globalRequirements
              }
            };
    }
    if (variant === "UpdatePropertyOptional") {
      var match$78 = action.VAL;
      var optional = match$78[1];
      var propertyId$30 = match$78[0];
      if (model.TAG === /* Old */0) {
        var model$127 = model._0;
        return {
                TAG: /* Old */0,
                _0: ModelUtils.hasMigrated(model$127, "PropertyOptionalAndExcludedSourcesToAbsence") ? ModelUtils.updateProperty(model$127, propertyId$30, (function (property) {
                          var excludedSources = property.excludedSourcesDeprecated;
                          if (!excludedSources) {
                            return updatePropertyAbsence(model, property, /* All */0, optional ? /* SometimesSent */({
                                            _0: ""
                                          }) : undefined);
                          }
                          var relevantEventSourcePairs = Belt_List.flatten(Belt_List.mapU(ModelUtils.eventsSendingProperty(model$127, undefined, propertyId$30), (function ($$event) {
                                      return Belt_List.keepMapU($$event.includeSources, (function (includedSource) {
                                                    if (!Belt_List.has(excludedSources, includedSource.id, (function (prim0, prim1) {
                                                              return prim0 === prim1;
                                                            }))) {
                                                      return [
                                                              $$event.id,
                                                              includedSource.id
                                                            ];
                                                    }
                                                    
                                                  }));
                                    })));
                          return Belt_List.reduceU(relevantEventSourcePairs, property, (function (property, param) {
                                        return updatePropertyAbsence(model, property, {
                                                    TAG: /* EventSource */2,
                                                    _0: param[0],
                                                    _1: param[1]
                                                  }, optional ? /* SometimesSent */({
                                                        _0: ""
                                                      }) : undefined);
                                      }));
                        })) : ModelUtils.updateProperty(model$127, propertyId$30, (function (property) {
                          return {
                                  id: property.id,
                                  name: property.name,
                                  uniqueName: property.uniqueName,
                                  description: property.description,
                                  type_: property.type_,
                                  sendAs: property.sendAs,
                                  validations: property.validations,
                                  eventSpecificAllowedPropertyValues: property.eventSpecificAllowedPropertyValues,
                                  auto: property.auto,
                                  builtIn: property.builtIn,
                                  analyticsTools: property.analyticsTools,
                                  devPlatforms: property.devPlatforms,
                                  list: property.list,
                                  operation: property.operation,
                                  optionalDeprecated: optional,
                                  excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                  optionalWhenInObject: property.optionalWhenInObject,
                                  absence: property.absence,
                                  globalRequirementsMetadata: property.globalRequirementsMetadata
                                };
                        }))
              };
      }
      var model$128 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$128.archive,
                branchPointer: model$128.branchPointer,
                migrations: model$128.migrations,
                rules: model$128.rules,
                types: model$128.types,
                categories: model$128.categories,
                destinations: model$128.destinations,
                events: model$128.events,
                groupTypes: model$128.groupTypes,
                integrations: model$128.integrations,
                metrics: model$128.metrics,
                openBranches: model$128.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.updateExisting, model$128.properties, propertyId$30, (function (property) {
                        if (!ModelUtils.hasMigrated_boxed(model, "PropertyOptionalAndExcludedSourcesToAbsence")) {
                          return {
                                  id: property.id,
                                  name: property.name,
                                  uniqueName: property.uniqueName,
                                  description: property.description,
                                  type_: property.type_,
                                  sendAs: property.sendAs,
                                  validations: property.validations,
                                  eventSpecificAllowedPropertyValues: property.eventSpecificAllowedPropertyValues,
                                  auto: property.auto,
                                  builtIn: property.builtIn,
                                  analyticsTools: property.analyticsTools,
                                  devPlatforms: property.devPlatforms,
                                  list: property.list,
                                  operation: property.operation,
                                  optionalDeprecated: optional,
                                  excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                  optionalWhenInObject: property.optionalWhenInObject,
                                  absence: property.absence,
                                  globalRequirementsMetadata: property.globalRequirementsMetadata
                                };
                        }
                        var excludedSources = property.excludedSourcesDeprecated;
                        if (!excludedSources) {
                          return updatePropertyAbsence(model, property, /* All */0, optional ? /* SometimesSent */({
                                          _0: ""
                                        }) : undefined);
                        }
                        var relevantEventSourcePairs = Belt_List.flatten(Belt_List.fromArray(Curry._2(TrackingPlanMappedModel.Events.mapToArray, ModelUtils_mapped.eventsSendingProperty(model$128.events, model$128.propertyBundles, undefined, propertyId$30), (function ($$event) {
                                        return Belt_List.keepMapU($$event.includeSources, (function (includedSource) {
                                                      if (!Belt_List.has(excludedSources, includedSource.id, (function (prim0, prim1) {
                                                                return prim0 === prim1;
                                                              }))) {
                                                        return [
                                                                $$event.id,
                                                                includedSource.id
                                                              ];
                                                      }
                                                      
                                                    }));
                                      }))));
                        return Belt_List.reduceU(relevantEventSourcePairs, property, (function (property, param) {
                                      return updatePropertyAbsence(model, property, {
                                                  TAG: /* EventSource */2,
                                                  _0: param[0],
                                                  _1: param[1]
                                                }, optional ? /* SometimesSent */({
                                                      _0: ""
                                                    }) : undefined);
                                    }));
                      })),
                propertyBundles: model$128.propertyBundles,
                sources: model$128.sources,
                globalRequirements: model$128.globalRequirements
              }
            };
    }
    if (variant === "OverrideEventVariantSpecificPropertyValueToBeAllowedOnAllSources" || variant === "OverrideEventVariantSpecificPropertyValueToBeDisallowed") {
      var match$79 = action.VAL;
      var literal$3 = match$79[2];
      var propertyId$31 = match$79[1];
      var variantIdentifier$8 = match$79[0];
      var newDisallowedState;
      if (typeof action === "object") {
        var variant$2 = action.NAME;
        newDisallowedState = variant$2 === "OverrideEventVariantSpecificPropertyValueToBeDisallowed" ? /* Disallowed */0 : (
            variant$2 === "OverrideEventVariantSpecificPropertyValueToBeAllowedOnAllSources" ? /* DisallowedSources */({
                  _0: undefined
                }) : Pervasives.failwith("Unsupported action")
          );
      } else {
        newDisallowedState = Pervasives.failwith("Unsupported action");
      }
      var baseEventId = variantIdentifier$8.baseEventId;
      var allowedValueString = typeof literal$3 === "object" && literal$3.NAME === "StringLit" ? literal$3.VAL : Pervasives.failwith("Only string literals are supported");
      var overrideEventVariantSpecificPropertyValue = function ($$event, propertyId, variantIdentifier) {
        var maybeEventVariantBefore = Belt_Array.getByU($$event.variants, (function (variant) {
                return Caml_obj.caml_equal(TrackingPlanModel.EventVariant.makeVariantIdentifier(variant), variantIdentifier);
              }));
        var variantWithOverride = maybeEventVariantBefore !== undefined ? ({
              id: maybeEventVariantBefore.id,
              baseEventId: maybeEventVariantBefore.baseEventId,
              nameSuffix: maybeEventVariantBefore.nameSuffix,
              description: maybeEventVariantBefore.description,
              propertyOverrides: Belt_MapString.update(maybeEventVariantBefore.propertyOverrides, propertyId, (function (override) {
                      if (override === undefined) {
                        return /* Overridden */{
                                _0: {
                                  pinnedValue: /* NoneOverride */0,
                                  absence: /* NoneOverride */0,
                                  allowedValues: /* SomeOverride */{
                                    _0: Belt_MapString.fromArray([[
                                            allowedValueString,
                                            newDisallowedState
                                          ]])
                                  },
                                  regexValidation: /* NoneOverride */0
                                }
                              };
                      }
                      if (!override) {
                        return /* Removed */0;
                      }
                      var currentOverride = override._0;
                      var existingOverride = currentOverride.allowedValues;
                      return /* Overridden */{
                              _0: {
                                pinnedValue: currentOverride.pinnedValue,
                                absence: currentOverride.absence,
                                allowedValues: existingOverride ? /* SomeOverride */({
                                      _0: Belt_MapString.set(existingOverride._0, allowedValueString, newDisallowedState)
                                    }) : /* SomeOverride */({
                                      _0: Belt_MapString.fromArray([[
                                              allowedValueString,
                                              newDisallowedState
                                            ]])
                                    }),
                                regexValidation: currentOverride.regexValidation
                              }
                            };
                    })),
              sourceOverrides: maybeEventVariantBefore.sourceOverrides,
              triggers: maybeEventVariantBefore.triggers
            }) : Pervasives.failwith("Variant not found");
        return {
                id: $$event.id,
                name: $$event.name,
                uniqueName: $$event.uniqueName,
                description: $$event.description,
                properties: $$event.properties,
                propertyBundles: $$event.propertyBundles,
                variants: Belt_Array.mapU($$event.variants, (function (variant) {
                        if (variant.id === variantIdentifier.variantId) {
                          return variantWithOverride;
                        } else {
                          return variant;
                        }
                      })),
                types: $$event.types,
                tags: $$event.tags,
                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                includeSources: $$event.includeSources,
                includeDestinations: $$event.includeDestinations,
                hashes: $$event.hashes,
                propertyWhitelist: $$event.propertyWhitelist,
                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                triggers: $$event.triggers,
                globalRequirementsMetadata: $$event.globalRequirementsMetadata
              };
      };
      var property;
      property = model.TAG === /* Old */0 ? ModelUtils.resolvePropertyById(propertyId$31, model._0) : Curry._2(TrackingPlanMappedModel.Properties.get, model._0.properties, propertyId$31);
      var alreadyHasLiteral = property !== undefined ? Belt_Array.some(property.eventSpecificAllowedPropertyValues, (function (param) {
                return Caml_obj.caml_equal(param[0], literal$3);
              })) : false;
      var model$129;
      if (property !== undefined && !alreadyHasLiteral) {
        var eventIdsSendingProperty;
        eventIdsSendingProperty = model.TAG === /* Old */0 ? GetEventIdsSendingPropertyForEventSpecificValuesUseCase.getFromSlowModel(model._0, property) : GetEventIdsSendingPropertyForEventSpecificValuesUseCase.getFromMappedModel(model._0, property);
        var tmp;
        switch (eventIdsSendingProperty.TAG | 0) {
          case /* SystemProperty */0 :
              tmp = [eventIdsSendingProperty._0];
              break;
          case /* SystemAndNotSystemProperty */1 :
          case /* NotSystemProperty */2 :
              tmp = eventIdsSendingProperty._0;
              break;
          
        }
        var allDisallowed = Belt_MapString.fromArray(Belt_Array.map(tmp, (function (eventId) {
                    return [
                            eventId,
                            /* Disallowed */0
                          ];
                  })));
        model$129 = reduceFunction(model, {
              NAME: "AddEventSpecificPropertyValueForSomeEvents",
              VAL: [
                propertyId$31,
                literal$3,
                allDisallowed
              ]
            });
      } else {
        model$129 = model;
      }
      if (model$129.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEvent(model$129._0, baseEventId, (function ($$event) {
                        return overrideEventVariantSpecificPropertyValue($$event, propertyId$31, variantIdentifier$8);
                      }))
              };
      }
      var model$130 = model$129._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$130.archive,
                branchPointer: model$130.branchPointer,
                migrations: model$130.migrations,
                rules: model$130.rules,
                types: model$130.types,
                categories: model$130.categories,
                destinations: model$130.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.updateExisting, model$130.events, baseEventId, (function ($$event) {
                        return overrideEventVariantSpecificPropertyValue($$event, propertyId$31, variantIdentifier$8);
                      })),
                groupTypes: model$130.groupTypes,
                integrations: model$130.integrations,
                metrics: model$130.metrics,
                openBranches: model$130.openBranches,
                properties: model$130.properties,
                propertyBundles: model$130.propertyBundles,
                sources: model$130.sources,
                globalRequirements: model$130.globalRequirements
              }
            };
    }
    if (variant === "IncludeEventInSourceV2") {
      var match$80 = action.VAL;
      var includeInCodegen$1 = match$80[3];
      var destinationIds = match$80[2];
      var sourceId$17 = match$80[1];
      var eventId$23 = match$80[0];
      var updateEvent$1 = function ($$event) {
        return {
                id: $$event.id,
                name: $$event.name,
                uniqueName: $$event.uniqueName,
                description: $$event.description,
                properties: $$event.properties,
                propertyBundles: $$event.propertyBundles,
                variants: $$event.variants,
                types: $$event.types,
                tags: $$event.tags,
                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                includeSources: {
                  hd: {
                    id: sourceId$17,
                    includeInCodegen: includeInCodegen$1,
                    inspectorValidation: undefined
                  },
                  tl: $$event.includeSources
                },
                includeDestinations: Belt_List.concat(Belt_List.keepU($$event.includeDestinations, (function (includeDestination) {
                            return includeDestination.VAL[0] !== sourceId$17;
                          })), {
                      hd: {
                        NAME: "IncludeSourceDestination",
                        VAL: [
                          sourceId$17,
                          destinationIds
                        ]
                      },
                      tl: /* [] */0
                    }),
                hashes: $$event.hashes,
                propertyWhitelist: $$event.propertyWhitelist,
                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                triggers: $$event.triggers,
                globalRequirementsMetadata: $$event.globalRequirementsMetadata
              };
      };
      var updateProperty$1 = function (property, eventIncludeSourcesSet) {
        return {
                id: property.id,
                name: property.name,
                uniqueName: property.uniqueName,
                description: property.description,
                type_: property.type_,
                sendAs: property.sendAs,
                validations: property.validations,
                eventSpecificAllowedPropertyValues: Belt_Array.map(property.eventSpecificAllowedPropertyValues, (function (param) {
                        var nextDisallowedEvents = Belt_MapString.update(param[1], eventId$23, (function (maybeDisallowedState) {
                                if (maybeDisallowedState === undefined) {
                                  return ;
                                }
                                if (!maybeDisallowedState) {
                                  return /* Disallowed */0;
                                }
                                var disallowedSources = maybeDisallowedState._0;
                                if (Belt_SetString.isEmpty(Belt_SetString.intersect(disallowedSources, eventIncludeSourcesSet)) || !Belt_SetString.eq(Belt_SetString.intersect(disallowedSources, eventIncludeSourcesSet), eventIncludeSourcesSet)) {
                                  return maybeDisallowedState;
                                } else {
                                  return /* DisallowedSources */{
                                          _0: Belt_SetString.add(disallowedSources, sourceId$17)
                                        };
                                }
                              }));
                        return [
                                param[0],
                                nextDisallowedEvents
                              ];
                      })),
                auto: property.auto,
                builtIn: property.builtIn,
                analyticsTools: property.analyticsTools,
                devPlatforms: property.devPlatforms,
                list: property.list,
                operation: property.operation,
                optionalDeprecated: property.optionalDeprecated,
                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                optionalWhenInObject: property.optionalWhenInObject,
                absence: property.absence,
                globalRequirementsMetadata: property.globalRequirementsMetadata
              };
      };
      var getEventIncludeSourcesSet = function (maybeEvent) {
        return Belt_Option.mapWithDefault(maybeEvent, undefined, (function ($$event) {
                      return Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map($$event.includeSources, (function (source) {
                                            return source.id;
                                          }))));
                    }));
      };
      if (model.TAG === /* Old */0) {
        var model$131 = model._0;
        var maybeEvent = ModelUtils.getEventById(eventId$23, model$131);
        var eventIncludeSourcesSet = getEventIncludeSourcesSet(maybeEvent);
        var eventProperties = Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map(Belt_Option.mapWithDefault(maybeEvent, /* [] */0, (function ($$event) {
                            return AvoModel.getResolvedPropertiesForEvent(model$131, $$event);
                          })), (function (property) {
                        return property.id;
                      }))));
        var init$6 = ModelUtils.updateEvent(model$131, eventId$23, updateEvent$1);
        return {
                TAG: /* Old */0,
                _0: {
                  types: init$6.types,
                  properties: ModelUtils.hasMigrated(model$131, "EventSpecificAllowedPropertyValues") ? Belt_List.map(model$131.properties, (function (property) {
                            if (property.TAG === /* PropertyRef */0) {
                              return property;
                            }
                            var property$1 = property._0;
                            if (Belt_SetString.has(eventProperties, property$1.id)) {
                              return {
                                      TAG: /* Property */1,
                                      _0: updateProperty$1(property$1, eventIncludeSourcesSet)
                                    };
                            } else {
                              return property;
                            }
                          })) : model$131.properties,
                  propertyBundles: init$6.propertyBundles,
                  events: init$6.events,
                  migrations: init$6.migrations,
                  sources: init$6.sources,
                  destinations: init$6.destinations,
                  groupTypes: init$6.groupTypes,
                  goals: init$6.goals,
                  metrics: init$6.metrics,
                  archive: init$6.archive,
                  openBranches: init$6.openBranches,
                  branchPointer: init$6.branchPointer,
                  rules: init$6.rules,
                  integrations: init$6.integrations,
                  globalRequirements: init$6.globalRequirements
                }
              };
      }
      var model$132 = model._0;
      var maybeEvent$1 = Curry._2(TrackingPlanMappedModel.Events.get, model$132.events, eventId$23);
      var eventIncludeSourcesSet$1 = getEventIncludeSourcesSet(maybeEvent$1);
      var eventProperties$1 = Belt_SetString.fromArray(Belt_Array.map(Belt_Option.mapWithDefault(maybeEvent$1, [], (function ($$event) {
                      return AvoModel.getResolvedPropertiesForEvent_mappedModel(model$132.propertyBundles, model$132.properties, $$event);
                    })), (function (property) {
                  return property.id;
                })));
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$132.archive,
                branchPointer: model$132.branchPointer,
                migrations: model$132.migrations,
                rules: model$132.rules,
                types: model$132.types,
                categories: model$132.categories,
                destinations: model$132.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.updateExisting, model$132.events, eventId$23, updateEvent$1),
                groupTypes: model$132.groupTypes,
                integrations: model$132.integrations,
                metrics: model$132.metrics,
                openBranches: model$132.openBranches,
                properties: ModelUtils_mapped.hasMigrated(model$132, "EventSpecificAllowedPropertyValues") ? Belt_SetString.reduceU(eventProperties$1, model$132.properties, (function (properties, propertyId) {
                          return Curry._3(TrackingPlanMappedModel.Properties.update, properties, propertyId, (function (maybeProperty) {
                                        return Belt_Option.map(maybeProperty, (function (property) {
                                                      return updateProperty$1(property, eventIncludeSourcesSet$1);
                                                    }));
                                      }));
                        })) : model$132.properties,
                propertyBundles: model$132.propertyBundles,
                sources: model$132.sources,
                globalRequirements: model$132.globalRequirements
              }
            };
    }
    if (variant === "ConvertPropertyToGlobalPropertyV2") {
      var match$81 = action.VAL;
      var globalMetadata$4 = match$81[4];
      var newGlobalProperty$1 = match$81[3];
      var globalWorkspaceId$4 = match$81[2];
      var toPropertyId$1 = match$81[1];
      var fromPropertyId$1 = match$81[0];
      var convertRule$3 = function (rule, globalRequirementEvents) {
        var eventIsGlobalAndContainsProperty = function (eventId) {
          return Belt_List.some(globalRequirementEvents, (function ($$event) {
                        if ($$event.id === eventId) {
                          return Belt_List.some($$event.properties, (function (property) {
                                        return ModelUtils.getPropertyId(property) === toPropertyId$1;
                                      }));
                        } else {
                          return false;
                        }
                      }));
        };
        var isNameMappingSpecificToDestination = function (definition) {
          if (definition.NAME !== "NameMapping") {
            return false;
          }
          var match = definition.VAL.destination;
          if (match !== undefined && typeof match === "object") {
            return true;
          } else {
            return false;
          }
        };
        var item = rule.item;
        var variant = item.NAME;
        var ruleItem;
        if (variant === "PropertyRef") {
          var match = item.VAL;
          if (match[1] === fromPropertyId$1) {
            var eventId = match[0];
            var match$1 = eventIsGlobalAndContainsProperty(eventId);
            var match$2 = isNameMappingSpecificToDestination(rule.definition);
            ruleItem = match$1 && !match$2 ? undefined : ({
                  NAME: "PropertyRef",
                  VAL: [
                    eventId,
                    toPropertyId$1
                  ]
                });
          } else {
            ruleItem = item;
          }
        } else if (variant === "Property") {
          var propertyId = item.VAL;
          ruleItem = propertyId === fromPropertyId$1 ? (
              isNameMappingSpecificToDestination(rule.definition) ? ({
                    NAME: "Property",
                    VAL: toPropertyId$1
                  }) : undefined
            ) : ({
                NAME: "Property",
                VAL: propertyId
              });
        } else if (variant === "Event") {
          ruleItem = item;
        } else {
          var match$3 = item.VAL;
          if (match$3[1] === fromPropertyId$1) {
            var eventId$1 = match$3[0];
            ruleItem = eventIsGlobalAndContainsProperty(eventId$1) ? undefined : ({
                  NAME: "EventObjectField",
                  VAL: [
                    eventId$1,
                    toPropertyId$1,
                    match$3[2]
                  ]
                });
          } else {
            ruleItem = item;
          }
        }
        if (ruleItem !== undefined) {
          return {
                  id: rule.id,
                  item: ruleItem,
                  definition: rule.definition
                };
        }
        
      };
      var convertEvent$4 = function ($$event, globalRequirementEvents) {
        var toExists = Belt_List.some($$event.properties, (function (property) {
                return ModelUtils.getPropertyId(property) === toPropertyId$1;
              }));
        var properties = Belt_List.keepMap($$event.properties, (function (property) {
                if (property.TAG !== /* PropertyRef */0) {
                  return {
                          TAG: /* Property */1,
                          _0: property._0
                        };
                }
                var propRef = property._0;
                if (propRef.id === fromPropertyId$1) {
                  if (toExists) {
                    return ;
                  } else {
                    return {
                            TAG: /* PropertyRef */0,
                            _0: {
                              id: toPropertyId$1,
                              description: propRef.description,
                              pinnedValue: propRef.pinnedValue
                            }
                          };
                  }
                } else {
                  return {
                          TAG: /* PropertyRef */0,
                          _0: propRef
                        };
                }
              }));
        var variants = Belt_Array.map($$event.variants, (function (variant) {
                var overrides = Belt_MapString.get(variant.propertyOverrides, fromPropertyId$1);
                var propertyOverrides;
                if (overrides !== undefined) {
                  var mapWithRemovedFromId = Belt_MapString.remove(variant.propertyOverrides, fromPropertyId$1);
                  propertyOverrides = Belt_List.some(globalRequirementEvents, (function ($$event) {
                          return $$event.id === variant.baseEventId;
                        })) ? mapWithRemovedFromId : Belt_MapString.set(mapWithRemovedFromId, toPropertyId$1, overrides);
                } else {
                  propertyOverrides = variant.propertyOverrides;
                }
                return {
                        id: variant.id,
                        baseEventId: variant.baseEventId,
                        nameSuffix: variant.nameSuffix,
                        description: variant.description,
                        propertyOverrides: propertyOverrides,
                        sourceOverrides: variant.sourceOverrides,
                        triggers: variant.triggers
                      };
              }));
        var propertyWhitelist = Belt_List.map($$event.propertyWhitelist, (function (param) {
                var propertyId = param[1];
                var tool = param[0];
                if (propertyId === fromPropertyId$1) {
                  return [
                          tool,
                          toPropertyId$1
                        ];
                } else {
                  return [
                          tool,
                          propertyId
                        ];
                }
              }));
        return {
                id: $$event.id,
                name: $$event.name,
                uniqueName: $$event.uniqueName,
                description: $$event.description,
                properties: properties,
                propertyBundles: $$event.propertyBundles,
                variants: variants,
                types: $$event.types,
                tags: $$event.tags,
                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                includeSources: $$event.includeSources,
                includeDestinations: $$event.includeDestinations,
                hashes: $$event.hashes,
                propertyWhitelist: propertyWhitelist,
                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                triggers: $$event.triggers,
                globalRequirementsMetadata: $$event.globalRequirementsMetadata
              };
      };
      var convertMetric$3 = function (metric) {
        var items = Belt_List.map(metric.items, (function (metricItem) {
                var metricEvent = metricItem.VAL;
                var where = Belt_List.map(metricEvent.where, (function (metricWhere) {
                        var id = metricWhere.propertyId;
                        if (id === fromPropertyId$1) {
                          return {
                                  id: metricWhere.id,
                                  propertyId: toPropertyId$1,
                                  binOp: metricWhere.binOp,
                                  literals: metricWhere.literals
                                };
                        } else {
                          return metricWhere;
                        }
                      }));
                var groupBy = Belt_List.map(metricEvent.groupBy, (function (metricGroupBy) {
                        var id = metricGroupBy.propertyId;
                        if (id === fromPropertyId$1) {
                          return {
                                  id: metricGroupBy.id,
                                  propertyId: toPropertyId$1
                                };
                        } else {
                          return metricGroupBy;
                        }
                      }));
                return {
                        NAME: "Event",
                        VAL: {
                          id: metricEvent.id,
                          eventId: metricEvent.eventId,
                          where: where,
                          groupBy: groupBy
                        }
                      };
              }));
        return {
                id: metric.id,
                name: metric.name,
                description: metric.description,
                type_: metric.type_,
                items: items
              };
      };
      var convertPropertyBundle$2 = function (bundle) {
        var toExists = Belt_List.some(bundle.properties, (function (bundlePropertyId) {
                return bundlePropertyId === toPropertyId$1;
              }));
        var properties = Belt_List.keepMap(bundle.properties, (function (propertyId) {
                if (propertyId === fromPropertyId$1) {
                  if (toExists) {
                    return ;
                  } else {
                    return toPropertyId$1;
                  }
                } else {
                  return propertyId;
                }
              }));
        return {
                id: bundle.id,
                name: bundle.name,
                description: bundle.description,
                properties: properties,
                globalRequirementsMetadata: bundle.globalRequirementsMetadata
              };
      };
      if (model.TAG === /* Old */0) {
        var model$133 = model._0;
        var globalRequirements$18 = model$133.globalRequirements;
        var globalRequirements$19 = globalRequirements$18 !== undefined ? globalRequirements$18 : TrackingPlanModel.GlobalRequirements.empty(globalMetadata$4);
        var globalRequirements_metadata$2 = globalRequirements$19.metadata;
        var globalRequirements_events$2 = globalRequirements$19.events;
        var globalRequirements_properties$2 = Belt_List.concat(globalRequirements$19.properties, {
              hd: {
                TAG: /* Property */1,
                _0: {
                  id: newGlobalProperty$1.id,
                  name: newGlobalProperty$1.name,
                  uniqueName: newGlobalProperty$1.uniqueName,
                  description: newGlobalProperty$1.description,
                  type_: newGlobalProperty$1.type_,
                  sendAs: newGlobalProperty$1.sendAs,
                  validations: newGlobalProperty$1.validations,
                  eventSpecificAllowedPropertyValues: newGlobalProperty$1.eventSpecificAllowedPropertyValues,
                  auto: newGlobalProperty$1.auto,
                  builtIn: newGlobalProperty$1.builtIn,
                  analyticsTools: newGlobalProperty$1.analyticsTools,
                  devPlatforms: newGlobalProperty$1.devPlatforms,
                  list: newGlobalProperty$1.list,
                  operation: newGlobalProperty$1.operation,
                  optionalDeprecated: newGlobalProperty$1.optionalDeprecated,
                  excludedSourcesDeprecated: newGlobalProperty$1.excludedSourcesDeprecated,
                  optionalWhenInObject: newGlobalProperty$1.optionalWhenInObject,
                  absence: /* AlwaysSent */0,
                  globalRequirementsMetadata: newGlobalProperty$1.globalRequirementsMetadata
                }
              },
              tl: /* [] */0
            });
        var globalRequirements_propertyBundles$2 = globalRequirements$19.propertyBundles;
        var globalRequirements_goals$1 = globalRequirements$19.goals;
        var globalRequirements_groupTypes$2 = globalRequirements$19.groupTypes;
        var globalRequirements_rules$2 = globalRequirements$19.rules;
        var globalRequirements$20 = {
          metadata: globalRequirements_metadata$2,
          events: globalRequirements_events$2,
          properties: globalRequirements_properties$2,
          propertyBundles: globalRequirements_propertyBundles$2,
          goals: globalRequirements_goals$1,
          groupTypes: globalRequirements_groupTypes$2,
          rules: globalRequirements_rules$2
        };
        var init$7 = ModelUtils.updateProperty(model$133, fromPropertyId$1, (function (property) {
                return {
                        id: toPropertyId$1,
                        name: property.name,
                        uniqueName: property.uniqueName,
                        description: property.description,
                        type_: property.type_,
                        sendAs: property.sendAs,
                        validations: property.validations,
                        eventSpecificAllowedPropertyValues: property.eventSpecificAllowedPropertyValues,
                        auto: property.auto,
                        builtIn: property.builtIn,
                        analyticsTools: property.analyticsTools,
                        devPlatforms: property.devPlatforms,
                        list: property.list,
                        operation: property.operation,
                        optionalDeprecated: property.optionalDeprecated,
                        excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                        optionalWhenInObject: property.optionalWhenInObject,
                        absence: property.absence,
                        globalRequirementsMetadata: {
                          fromLocalItemId: fromPropertyId$1,
                          globalWorkspaceId: globalWorkspaceId$4
                        }
                      };
              }));
        return {
                TAG: /* Old */0,
                _0: {
                  types: init$7.types,
                  properties: init$7.properties,
                  propertyBundles: Belt_List.map(model$133.propertyBundles, convertPropertyBundle$2),
                  events: Belt_List.map(model$133.events, (function ($$event) {
                          return convertEvent$4($$event, Belt_Option.getWithDefault(Belt_Option.map(model$133.globalRequirements, (function (globalRequirements) {
                                                return globalRequirements.events;
                                              })), /* [] */0));
                        })),
                  migrations: init$7.migrations,
                  sources: init$7.sources,
                  destinations: init$7.destinations,
                  groupTypes: init$7.groupTypes,
                  goals: init$7.goals,
                  metrics: Belt_List.map(model$133.metrics, convertMetric$3),
                  archive: init$7.archive,
                  openBranches: init$7.openBranches,
                  branchPointer: init$7.branchPointer,
                  rules: Belt_List.keepMap(model$133.rules, (function (rule) {
                          return convertRule$3(rule, Belt_Option.getWithDefault(Belt_Option.map(model$133.globalRequirements, (function (globalRequirements) {
                                                return globalRequirements.events;
                                              })), /* [] */0));
                        })),
                  integrations: init$7.integrations,
                  globalRequirements: globalRequirements$20
                }
              };
      }
      var model$134 = model._0;
      var globalRequirements$21 = model$134.globalRequirements;
      var globalRequirements$22 = globalRequirements$21 !== undefined ? globalRequirements$21 : TrackingPlanMappedModel.GlobalRequirements.fromOld(TrackingPlanModel.GlobalRequirements.empty(globalMetadata$4));
      var globalRequirements_metadata$3 = globalRequirements$22.metadata;
      var globalRequirements_events$3 = globalRequirements$22.events;
      var globalRequirements_properties$3 = Curry._2(TrackingPlanMappedModel.Properties.append, globalRequirements$22.properties, {
            id: newGlobalProperty$1.id,
            name: newGlobalProperty$1.name,
            uniqueName: newGlobalProperty$1.uniqueName,
            description: newGlobalProperty$1.description,
            type_: newGlobalProperty$1.type_,
            sendAs: newGlobalProperty$1.sendAs,
            validations: newGlobalProperty$1.validations,
            eventSpecificAllowedPropertyValues: newGlobalProperty$1.eventSpecificAllowedPropertyValues,
            auto: newGlobalProperty$1.auto,
            builtIn: newGlobalProperty$1.builtIn,
            analyticsTools: newGlobalProperty$1.analyticsTools,
            devPlatforms: newGlobalProperty$1.devPlatforms,
            list: newGlobalProperty$1.list,
            operation: newGlobalProperty$1.operation,
            optionalDeprecated: newGlobalProperty$1.optionalDeprecated,
            excludedSourcesDeprecated: newGlobalProperty$1.excludedSourcesDeprecated,
            optionalWhenInObject: newGlobalProperty$1.optionalWhenInObject,
            absence: /* AlwaysSent */0,
            globalRequirementsMetadata: newGlobalProperty$1.globalRequirementsMetadata
          });
      var globalRequirements_propertyBundles$3 = globalRequirements$22.propertyBundles;
      var globalRequirements_categories$1 = globalRequirements$22.categories;
      var globalRequirements_groupTypes$3 = globalRequirements$22.groupTypes;
      var globalRequirements_rules$3 = globalRequirements$22.rules;
      var globalRequirements$23 = {
        metadata: globalRequirements_metadata$3,
        events: globalRequirements_events$3,
        properties: globalRequirements_properties$3,
        propertyBundles: globalRequirements_propertyBundles$3,
        categories: globalRequirements_categories$1,
        groupTypes: globalRequirements_groupTypes$3,
        rules: globalRequirements_rules$3
      };
      var convertProperty$1 = function (properties, fromPropertyId, globalProperty) {
        return Belt_Option.getWithDefault(Belt_Option.map(Curry._2(TrackingPlanMappedModel.Properties.get, properties, fromPropertyId), (function (property) {
                          var updatedProperty_id = globalProperty.id;
                          var updatedProperty_name = globalProperty.name;
                          var updatedProperty_uniqueName = globalProperty.uniqueName;
                          var updatedProperty_description = globalProperty.description;
                          var updatedProperty_type_ = globalProperty.type_;
                          var updatedProperty_sendAs = globalProperty.sendAs;
                          var updatedProperty_validations = globalProperty.validations;
                          var updatedProperty_eventSpecificAllowedPropertyValues = globalProperty.eventSpecificAllowedPropertyValues;
                          var updatedProperty_auto = globalProperty.auto;
                          var updatedProperty_builtIn = globalProperty.builtIn;
                          var updatedProperty_analyticsTools = globalProperty.analyticsTools;
                          var updatedProperty_devPlatforms = globalProperty.devPlatforms;
                          var updatedProperty_list = globalProperty.list;
                          var updatedProperty_operation = globalProperty.operation;
                          var updatedProperty_optionalDeprecated = globalProperty.optionalDeprecated;
                          var updatedProperty_excludedSourcesDeprecated = globalProperty.excludedSourcesDeprecated;
                          var updatedProperty_optionalWhenInObject = globalProperty.optionalWhenInObject;
                          var updatedProperty_absence = property.absence;
                          var updatedProperty_globalRequirementsMetadata = {
                            fromLocalItemId: fromPropertyId,
                            globalWorkspaceId: globalWorkspaceId$4
                          };
                          var updatedProperty = {
                            id: updatedProperty_id,
                            name: updatedProperty_name,
                            uniqueName: updatedProperty_uniqueName,
                            description: updatedProperty_description,
                            type_: updatedProperty_type_,
                            sendAs: updatedProperty_sendAs,
                            validations: updatedProperty_validations,
                            eventSpecificAllowedPropertyValues: updatedProperty_eventSpecificAllowedPropertyValues,
                            auto: updatedProperty_auto,
                            builtIn: updatedProperty_builtIn,
                            analyticsTools: updatedProperty_analyticsTools,
                            devPlatforms: updatedProperty_devPlatforms,
                            list: updatedProperty_list,
                            operation: updatedProperty_operation,
                            optionalDeprecated: updatedProperty_optionalDeprecated,
                            excludedSourcesDeprecated: updatedProperty_excludedSourcesDeprecated,
                            optionalWhenInObject: updatedProperty_optionalWhenInObject,
                            absence: updatedProperty_absence,
                            globalRequirementsMetadata: updatedProperty_globalRequirementsMetadata
                          };
                          return Curry._2(TrackingPlanMappedModel.Properties.append, Curry._2(TrackingPlanMappedModel.Properties.remove, properties, fromPropertyId), updatedProperty);
                        })), properties);
      };
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$134.archive,
                branchPointer: model$134.branchPointer,
                migrations: model$134.migrations,
                rules: Belt_Array.keepMap(model$134.rules, (function (rule) {
                        return convertRule$3(rule, Belt_Option.getWithDefault(Belt_Option.map(model$134.globalRequirements, (function (globalRequirements) {
                                              return Curry._1(TrackingPlanMappedModel.Events.toList, globalRequirements.events);
                                            })), /* [] */0));
                      })),
                types: model$134.types,
                categories: model$134.categories,
                destinations: model$134.destinations,
                events: Curry._2(TrackingPlanMappedModel.Events.mapToT, model$134.events, (function ($$event) {
                        return convertEvent$4($$event, Belt_Option.getWithDefault(Belt_Option.map(model$134.globalRequirements, (function (globalRequirements) {
                                              return Curry._1(TrackingPlanMappedModel.Events.toList, globalRequirements.events);
                                            })), /* [] */0));
                      })),
                groupTypes: model$134.groupTypes,
                integrations: model$134.integrations,
                metrics: Curry._2(TrackingPlanMappedModel.Metrics.mapToT, model$134.metrics, convertMetric$3),
                openBranches: model$134.openBranches,
                properties: convertProperty$1(model$134.properties, fromPropertyId$1, newGlobalProperty$1),
                propertyBundles: Curry._2(TrackingPlanMappedModel.PropertyBundles.mapToT, model$134.propertyBundles, convertPropertyBundle$2),
                sources: model$134.sources,
                globalRequirements: globalRequirements$23
              }
            };
    }
    if (variant === "AddSystemProperty") {
      var match$82 = action.VAL;
      var newSystemProperty_id = match$82[0];
      var newSystemProperty_name = match$82[1];
      var newSystemProperty_eventSpecificAllowedPropertyValues = [];
      var newSystemProperty_absence = ModelUtils.hasMigrated_boxed(model, "PropertyOptionalAndExcludedSourcesToAbsence") ? /* AlwaysSent */0 : undefined;
      var newSystemProperty = {
        id: newSystemProperty_id,
        name: newSystemProperty_name,
        uniqueName: undefined,
        description: "",
        type_: "string",
        sendAs: /* SystemProperty */0,
        validations: /* [] */0,
        eventSpecificAllowedPropertyValues: newSystemProperty_eventSpecificAllowedPropertyValues,
        auto: false,
        builtIn: "NotBuiltIn",
        analyticsTools: /* [] */0,
        devPlatforms: /* [] */0,
        list: false,
        operation: "Set",
        optionalDeprecated: false,
        excludedSourcesDeprecated: /* [] */0,
        optionalWhenInObject: false,
        absence: newSystemProperty_absence,
        globalRequirementsMetadata: undefined
      };
      if (model.TAG === /* Old */0) {
        var model$135 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$135.types,
                  properties: Belt_List.concat(model$135.properties, {
                        hd: {
                          TAG: /* Property */1,
                          _0: newSystemProperty
                        },
                        tl: /* [] */0
                      }),
                  propertyBundles: model$135.propertyBundles,
                  events: model$135.events,
                  migrations: model$135.migrations,
                  sources: model$135.sources,
                  destinations: model$135.destinations,
                  groupTypes: model$135.groupTypes,
                  goals: model$135.goals,
                  metrics: model$135.metrics,
                  archive: model$135.archive,
                  openBranches: model$135.openBranches,
                  branchPointer: model$135.branchPointer,
                  rules: model$135.rules,
                  integrations: model$135.integrations,
                  globalRequirements: model$135.globalRequirements
                }
              };
      }
      var model$136 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$136.archive,
                branchPointer: model$136.branchPointer,
                migrations: model$136.migrations,
                rules: model$136.rules,
                types: model$136.types,
                categories: model$136.categories,
                destinations: model$136.destinations,
                events: model$136.events,
                groupTypes: model$136.groupTypes,
                integrations: model$136.integrations,
                metrics: model$136.metrics,
                openBranches: model$136.openBranches,
                properties: Curry._2(TrackingPlanMappedModel.Properties.append, model$136.properties, newSystemProperty),
                propertyBundles: model$136.propertyBundles,
                sources: model$136.sources,
                globalRequirements: model$136.globalRequirements
              }
            };
    }
    if (variant === "UpdateRule") {
      var match$83 = action.VAL;
      var definition$1 = match$83[2];
      var ruleId$1 = match$83[0];
      if (model.TAG === /* Old */0) {
        var model$137 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$137.types,
                  properties: model$137.properties,
                  propertyBundles: model$137.propertyBundles,
                  events: model$137.events,
                  migrations: model$137.migrations,
                  sources: model$137.sources,
                  destinations: model$137.destinations,
                  groupTypes: model$137.groupTypes,
                  goals: model$137.goals,
                  metrics: model$137.metrics,
                  archive: model$137.archive,
                  openBranches: model$137.openBranches,
                  branchPointer: model$137.branchPointer,
                  rules: Belt_List.mapU(model$137.rules, (function (rule) {
                          if (rule.id === ruleId$1) {
                            return {
                                    id: rule.id,
                                    item: rule.item,
                                    definition: definition$1
                                  };
                          } else {
                            return rule;
                          }
                        })),
                  integrations: model$137.integrations,
                  globalRequirements: model$137.globalRequirements
                }
              };
      }
      var model$138 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$138.archive,
                branchPointer: model$138.branchPointer,
                migrations: model$138.migrations,
                rules: Belt_Array.mapU(model$138.rules, (function (rule) {
                        if (rule.id === ruleId$1) {
                          return {
                                  id: rule.id,
                                  item: rule.item,
                                  definition: definition$1
                                };
                        } else {
                          return rule;
                        }
                      })),
                types: model$138.types,
                categories: model$138.categories,
                destinations: model$138.destinations,
                events: model$138.events,
                groupTypes: model$138.groupTypes,
                integrations: model$138.integrations,
                metrics: model$138.metrics,
                openBranches: model$138.openBranches,
                properties: model$138.properties,
                propertyBundles: model$138.propertyBundles,
                sources: model$138.sources,
                globalRequirements: model$138.globalRequirements
              }
            };
    }
    if (variant === "SetMetricGroupBy") {
      var match$84 = action.VAL;
      var propertyId$32 = match$84[3];
      var id$8 = match$84[2];
      var eventId$24 = match$84[1];
      var metricId$7 = match$84[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateMetric(model._0, metricId$7, (function (metric) {
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metric.type_,
                                items: Belt_List.mapU(metric.items, (function (metricItem) {
                                        var $$event = metricItem.VAL;
                                        if (eventId$24 !== $$event.eventId) {
                                          return metricItem;
                                        }
                                        var groupBy = $$event.groupBy;
                                        var match = Belt_List.keepU(groupBy, (function (groupBy) {
                                                return groupBy.id === id$8;
                                              }));
                                        return {
                                                NAME: "Event",
                                                VAL: {
                                                  id: $$event.id,
                                                  eventId: $$event.eventId,
                                                  where: $$event.where,
                                                  groupBy: match ? Belt_List.mapU(groupBy, (function (groupBy) {
                                                            if (groupBy.id === id$8) {
                                                              return {
                                                                      id: id$8,
                                                                      propertyId: propertyId$32
                                                                    };
                                                            } else {
                                                              return groupBy;
                                                            }
                                                          })) : Belt_List.concat(groupBy, {
                                                          hd: {
                                                            id: id$8,
                                                            propertyId: propertyId$32
                                                          },
                                                          tl: /* [] */0
                                                        })
                                                }
                                              };
                                      }))
                              };
                      }))
              };
      }
      var model$139 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$139.archive,
                branchPointer: model$139.branchPointer,
                migrations: model$139.migrations,
                rules: model$139.rules,
                types: model$139.types,
                categories: model$139.categories,
                destinations: model$139.destinations,
                events: model$139.events,
                groupTypes: model$139.groupTypes,
                integrations: model$139.integrations,
                metrics: Curry._3(TrackingPlanMappedModel.Metrics.updateExisting, model$139.metrics, metricId$7, (function (metric) {
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metric.type_,
                                items: Belt_List.mapU(metric.items, (function (metricItem) {
                                        var $$event = metricItem.VAL;
                                        if (eventId$24 !== $$event.eventId) {
                                          return metricItem;
                                        }
                                        var groupBy = $$event.groupBy;
                                        var match = Belt_List.keepU(groupBy, (function (groupBy) {
                                                return groupBy.id === id$8;
                                              }));
                                        return {
                                                NAME: "Event",
                                                VAL: {
                                                  id: $$event.id,
                                                  eventId: $$event.eventId,
                                                  where: $$event.where,
                                                  groupBy: match ? Belt_List.mapU(groupBy, (function (groupBy) {
                                                            if (groupBy.id === id$8) {
                                                              return {
                                                                      id: id$8,
                                                                      propertyId: propertyId$32
                                                                    };
                                                            } else {
                                                              return groupBy;
                                                            }
                                                          })) : Belt_List.concat(groupBy, {
                                                          hd: {
                                                            id: id$8,
                                                            propertyId: propertyId$32
                                                          },
                                                          tl: /* [] */0
                                                        })
                                                }
                                              };
                                      }))
                              };
                      })),
                openBranches: model$139.openBranches,
                properties: model$139.properties,
                propertyBundles: model$139.propertyBundles,
                sources: model$139.sources,
                globalRequirements: model$139.globalRequirements
              }
            };
    }
    if (variant === "AddEventSpecificPropertyValue") {
      var match$85 = action.VAL;
      var addedAllowedSourcesSet$1 = match$85[3];
      var literal$4 = match$85[2];
      var propertyId$33 = match$85[1];
      var eventId$25 = match$85[0];
      var addAllowedValue = function (eventId, allTargetSourceIds, eventIdsSendingProperty, property) {
        var disallowedSourcesSet = Belt_SetString.intersect(Belt_SetString.diff(allTargetSourceIds, addedAllowedSourcesSet$1), allTargetSourceIds);
        var eventSpecificAllowedPropertyValuesBefore = property.eventSpecificAllowedPropertyValues;
        var alreadyHasLiteral = Belt_Array.some(eventSpecificAllowedPropertyValuesBefore, (function (param) {
                return Caml_obj.caml_equal(param[0], literal$4);
              }));
        var nextDisallowedState = Belt_SetString.eq(disallowedSourcesSet, allTargetSourceIds) ? /* Disallowed */0 : (
            Belt_SetString.isEmpty(disallowedSourcesSet) ? undefined : /* DisallowedSources */({
                  _0: disallowedSourcesSet
                })
          );
        var eventSpecificAllowedPropertyValues;
        if (alreadyHasLiteral) {
          eventSpecificAllowedPropertyValues = Belt_Array.map(eventSpecificAllowedPropertyValuesBefore, (function (param) {
                  var propertyValueDisallowedEventSources = param[1];
                  var existingLiteral = param[0];
                  if (Caml_obj.caml_equal(existingLiteral, literal$4)) {
                    return [
                            literal$4,
                            nextDisallowedState !== undefined ? Belt_MapString.set(propertyValueDisallowedEventSources, eventId, nextDisallowedState) : Belt_MapString.remove(propertyValueDisallowedEventSources, eventId)
                          ];
                  } else {
                    return [
                            existingLiteral,
                            propertyValueDisallowedEventSources
                          ];
                  }
                }));
        } else {
          var nextDisallowedEvents = Belt_MapString.fromArray(Belt_Array.map(eventIdsSendingProperty, (function (eventId) {
                      return [
                              eventId,
                              /* Disallowed */0
                            ];
                    })));
          var nextDisallowedEvents$1 = nextDisallowedState !== undefined ? Belt_MapString.set(nextDisallowedEvents, eventId, nextDisallowedState) : Belt_MapString.remove(nextDisallowedEvents, eventId);
          eventSpecificAllowedPropertyValues = Belt_Array.concat(eventSpecificAllowedPropertyValuesBefore, [[
                  literal$4,
                  nextDisallowedEvents$1
                ]]);
        }
        return {
                id: property.id,
                name: property.name,
                uniqueName: property.uniqueName,
                description: property.description,
                type_: property.type_,
                sendAs: property.sendAs,
                validations: property.validations,
                eventSpecificAllowedPropertyValues: eventSpecificAllowedPropertyValues,
                auto: property.auto,
                builtIn: property.builtIn,
                analyticsTools: property.analyticsTools,
                devPlatforms: property.devPlatforms,
                list: property.list,
                operation: property.operation,
                optionalDeprecated: property.optionalDeprecated,
                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                optionalWhenInObject: property.optionalWhenInObject,
                absence: property.absence,
                globalRequirementsMetadata: property.globalRequirementsMetadata
              };
      };
      var addSystemPropertyValue = function (allSourceIds, property) {
        return addAllowedValue(GetEventIdsSendingPropertyForEventSpecificValuesUseCase.propertyValuesAllEventsKey, allSourceIds, [GetEventIdsSendingPropertyForEventSpecificValuesUseCase.propertyValuesAllEventsKey], property);
      };
      var addEventSpecificPropertyValue = function (property, $$event, eventIdsSendingProperty) {
        var eventIncludeSourcesSet = Belt_SetString.fromArray(Belt_Array.mapU(Belt_List.toArray($$event.includeSources), (function (source) {
                    return source.id;
                  })));
        return addAllowedValue(eventId$25, eventIncludeSourcesSet, eventIdsSendingProperty, property);
      };
      if (model.TAG === /* Old */0) {
        var model$140 = model._0;
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateProperty(model$140, propertyId$33, (function (property) {
                        if (property.sendAs === /* SystemProperty */0) {
                          return addSystemPropertyValue(Belt_SetString.fromArray(Belt_Array.mapU(Belt_List.toArray(model$140.sources), (function (source) {
                                                return source.id;
                                              }))), property);
                        }
                        var $$event = ModelUtils.getEventById(eventId$25, model$140);
                        if ($$event === undefined) {
                          return property;
                        }
                        var eventIds = GetEventIdsSendingPropertyForEventSpecificValuesUseCase.getFromSlowModel(model$140, property);
                        var eventIdsSendingProperty;
                        switch (eventIds.TAG | 0) {
                          case /* SystemProperty */0 :
                              eventIdsSendingProperty = Pervasives.failwith("System property should be handled in a different code path");
                              break;
                          case /* SystemAndNotSystemProperty */1 :
                          case /* NotSystemProperty */2 :
                              eventIdsSendingProperty = eventIds._0;
                              break;
                          
                        }
                        return addEventSpecificPropertyValue(property, $$event, eventIdsSendingProperty);
                      }))
              };
      }
      var model$141 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$141.archive,
                branchPointer: model$141.branchPointer,
                migrations: model$141.migrations,
                rules: model$141.rules,
                types: model$141.types,
                categories: model$141.categories,
                destinations: model$141.destinations,
                events: model$141.events,
                groupTypes: model$141.groupTypes,
                integrations: model$141.integrations,
                metrics: model$141.metrics,
                openBranches: model$141.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.updateExisting, model$141.properties, propertyId$33, (function (property) {
                        if (property.sendAs === /* SystemProperty */0) {
                          return addSystemPropertyValue(Belt_SetString.fromArray(Belt_Array.mapU(Curry._1(TrackingPlanMappedModel.Sources.toArray, model$141.sources), (function (source) {
                                                return source.id;
                                              }))), property);
                        }
                        var $$event = Curry._2(TrackingPlanMappedModel.Events.get, model$141.events, eventId$25);
                        if ($$event === undefined) {
                          return property;
                        }
                        var eventIds = GetEventIdsSendingPropertyForEventSpecificValuesUseCase.getFromMappedModel(model$141, property);
                        var eventIdsSendingProperty;
                        switch (eventIds.TAG | 0) {
                          case /* SystemProperty */0 :
                              eventIdsSendingProperty = Pervasives.failwith("System property shall be handled in a different code path");
                              break;
                          case /* SystemAndNotSystemProperty */1 :
                          case /* NotSystemProperty */2 :
                              eventIdsSendingProperty = eventIds._0;
                              break;
                          
                        }
                        return addEventSpecificPropertyValue(property, $$event, eventIdsSendingProperty);
                      })),
                propertyBundles: model$141.propertyBundles,
                sources: model$141.sources,
                globalRequirements: model$141.globalRequirements
              }
            };
    }
    if (variant === "RemoveSourceSegmentIntegration") {
      var match$86 = action.VAL;
      var segmentIntegration = match$86[1];
      var sourceId$18 = match$86[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateSource(model._0, sourceId$18, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: source.language,
                                platform: source.platform,
                                destinations: source.destinations,
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: Belt_List.keepU(source.segmentDestinationOptions, (function (param) {
                                        return param[0] !== segmentIntegration;
                                      })),
                                config: source.config
                              };
                      }))
              };
      }
      var model$142 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$142.archive,
                branchPointer: model$142.branchPointer,
                migrations: model$142.migrations,
                rules: model$142.rules,
                types: model$142.types,
                categories: model$142.categories,
                destinations: model$142.destinations,
                events: model$142.events,
                groupTypes: model$142.groupTypes,
                integrations: model$142.integrations,
                metrics: model$142.metrics,
                openBranches: model$142.openBranches,
                properties: model$142.properties,
                propertyBundles: model$142.propertyBundles,
                sources: Curry._3(TrackingPlanMappedModel.Sources.updateExisting, model$142.sources, sourceId$18, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: source.language,
                                platform: source.platform,
                                destinations: source.destinations,
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: Belt_List.keepU(source.segmentDestinationOptions, (function (param) {
                                        return param[0] !== segmentIntegration;
                                      })),
                                config: source.config
                              };
                      })),
                globalRequirements: model$142.globalRequirements
              }
            };
    }
    if (variant === "IncludeEventInSource") {
      var match$87 = action.VAL;
      var sourceId$19 = match$87[1];
      var eventId$26 = match$87[0];
      if (model.TAG === /* Old */0) {
        var model$143 = model._0;
        return {
                TAG: /* Old */0,
                _0: ModelUtils.hasMigrated(model$143, "ExplicitIncludeSource") ? ModelUtils.updateEvent(model$143, eventId$26, (function ($$event) {
                          return {
                                  id: $$event.id,
                                  name: $$event.name,
                                  uniqueName: $$event.uniqueName,
                                  description: $$event.description,
                                  properties: $$event.properties,
                                  propertyBundles: $$event.propertyBundles,
                                  variants: $$event.variants,
                                  types: $$event.types,
                                  tags: $$event.tags,
                                  excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                  includeSources: {
                                    hd: {
                                      id: sourceId$19,
                                      includeInCodegen: true,
                                      inspectorValidation: undefined
                                    },
                                    tl: $$event.includeSources
                                  },
                                  includeDestinations: $$event.includeDestinations,
                                  hashes: $$event.hashes,
                                  propertyWhitelist: $$event.propertyWhitelist,
                                  eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                  userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                  triggers: $$event.triggers,
                                  globalRequirementsMetadata: $$event.globalRequirementsMetadata
                                };
                        })) : ModelUtils.updateEvent(model$143, eventId$26, (function ($$event) {
                          return {
                                  id: $$event.id,
                                  name: $$event.name,
                                  uniqueName: $$event.uniqueName,
                                  description: $$event.description,
                                  properties: $$event.properties,
                                  propertyBundles: $$event.propertyBundles,
                                  variants: $$event.variants,
                                  types: $$event.types,
                                  tags: $$event.tags,
                                  excludeSourcesDeprecated: Belt_List.keepU($$event.excludeSourcesDeprecated, (function (excludeSourceId) {
                                          return excludeSourceId !== sourceId$19;
                                        })),
                                  includeSources: $$event.includeSources,
                                  includeDestinations: $$event.includeDestinations,
                                  hashes: $$event.hashes,
                                  propertyWhitelist: $$event.propertyWhitelist,
                                  eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                  userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                  triggers: $$event.triggers,
                                  globalRequirementsMetadata: $$event.globalRequirementsMetadata
                                };
                        }))
              };
      }
      var model$144 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$144.archive,
                branchPointer: model$144.branchPointer,
                migrations: model$144.migrations,
                rules: model$144.rules,
                types: model$144.types,
                categories: model$144.categories,
                destinations: model$144.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.updateExisting, model$144.events, eventId$26, (function ($$event) {
                        if (ModelUtils.hasMigrated_boxed(model, "ExplicitIncludeSource")) {
                          return {
                                  id: $$event.id,
                                  name: $$event.name,
                                  uniqueName: $$event.uniqueName,
                                  description: $$event.description,
                                  properties: $$event.properties,
                                  propertyBundles: $$event.propertyBundles,
                                  variants: $$event.variants,
                                  types: $$event.types,
                                  tags: $$event.tags,
                                  excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                  includeSources: {
                                    hd: {
                                      id: sourceId$19,
                                      includeInCodegen: true,
                                      inspectorValidation: undefined
                                    },
                                    tl: $$event.includeSources
                                  },
                                  includeDestinations: $$event.includeDestinations,
                                  hashes: $$event.hashes,
                                  propertyWhitelist: $$event.propertyWhitelist,
                                  eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                  userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                  triggers: $$event.triggers,
                                  globalRequirementsMetadata: $$event.globalRequirementsMetadata
                                };
                        } else {
                          return {
                                  id: $$event.id,
                                  name: $$event.name,
                                  uniqueName: $$event.uniqueName,
                                  description: $$event.description,
                                  properties: $$event.properties,
                                  propertyBundles: $$event.propertyBundles,
                                  variants: $$event.variants,
                                  types: $$event.types,
                                  tags: $$event.tags,
                                  excludeSourcesDeprecated: Belt_List.keepU($$event.excludeSourcesDeprecated, (function (excludeSourceId) {
                                          return excludeSourceId !== sourceId$19;
                                        })),
                                  includeSources: $$event.includeSources,
                                  includeDestinations: $$event.includeDestinations,
                                  hashes: $$event.hashes,
                                  propertyWhitelist: $$event.propertyWhitelist,
                                  eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                  userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                  triggers: $$event.triggers,
                                  globalRequirementsMetadata: $$event.globalRequirementsMetadata
                                };
                        }
                      })),
                groupTypes: model$144.groupTypes,
                integrations: model$144.integrations,
                metrics: model$144.metrics,
                openBranches: model$144.openBranches,
                properties: model$144.properties,
                propertyBundles: model$144.propertyBundles,
                sources: model$144.sources,
                globalRequirements: model$144.globalRequirements
              }
            };
    }
    if (variant === "UpdateEventVariantTriggerDescription") {
      var match$88 = action.VAL;
      var description$4 = match$88[2];
      var triggerId$5 = match$88[1];
      var variantIdentifier$9 = match$88[0];
      var update$9 = function (trigger) {
        return {
                id: trigger.id,
                sources: trigger.sources,
                description: description$4,
                content: trigger.content
              };
      };
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEventVariantTrigger(model._0, variantIdentifier$9, triggerId$5, update$9)
              };
      } else {
        return {
                TAG: /* Mapped */1,
                _0: ModelUtils_mapped.updateEventVariantTrigger_mappedModel(model._0, variantIdentifier$9, triggerId$5, update$9)
              };
      }
    }
    if (variant === "UpdateEventDescription") {
      var match$89 = action.VAL;
      var description$5 = match$89[1];
      var eventId$27 = match$89[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEvent(model._0, eventId$27, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: description$5,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      }))
              };
      }
      var model$145 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$145.archive,
                branchPointer: model$145.branchPointer,
                migrations: model$145.migrations,
                rules: model$145.rules,
                types: model$145.types,
                categories: model$145.categories,
                destinations: model$145.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.updateExisting, model$145.events, eventId$27, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: description$5,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      })),
                groupTypes: model$145.groupTypes,
                integrations: model$145.integrations,
                metrics: model$145.metrics,
                openBranches: model$145.openBranches,
                properties: model$145.properties,
                propertyBundles: model$145.propertyBundles,
                sources: model$145.sources,
                globalRequirements: model$145.globalRequirements
              }
            };
    }
    if (variant === "AddEventToMetric") {
      var match$90 = action.VAL;
      var eventId$28 = match$90[2];
      var metricItemId$2 = match$90[1];
      var metricId$8 = match$90[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateMetric(model._0, metricId$8, (function (metric) {
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metric.type_,
                                items: Belt_List.concat(metric.items, {
                                      hd: {
                                        NAME: "Event",
                                        VAL: {
                                          id: metricItemId$2,
                                          eventId: eventId$28,
                                          where: /* [] */0,
                                          groupBy: /* [] */0
                                        }
                                      },
                                      tl: /* [] */0
                                    })
                              };
                      }))
              };
      }
      var model$146 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$146.archive,
                branchPointer: model$146.branchPointer,
                migrations: model$146.migrations,
                rules: model$146.rules,
                types: model$146.types,
                categories: model$146.categories,
                destinations: model$146.destinations,
                events: model$146.events,
                groupTypes: model$146.groupTypes,
                integrations: model$146.integrations,
                metrics: Curry._3(TrackingPlanMappedModel.Metrics.updateExisting, model$146.metrics, metricId$8, (function (metric) {
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metric.type_,
                                items: Belt_List.concat(metric.items, {
                                      hd: {
                                        NAME: "Event",
                                        VAL: {
                                          id: metricItemId$2,
                                          eventId: eventId$28,
                                          where: /* [] */0,
                                          groupBy: /* [] */0
                                        }
                                      },
                                      tl: /* [] */0
                                    })
                              };
                      })),
                openBranches: model$146.openBranches,
                properties: model$146.properties,
                propertyBundles: model$146.propertyBundles,
                sources: model$146.sources,
                globalRequirements: model$146.globalRequirements
              }
            };
    }
    if (variant === "AddEventVariantTriggerSource") {
      var match$91 = action.VAL;
      var sourceId$20 = match$91[2];
      var triggerId$6 = match$91[1];
      var variantIdentifier$10 = match$91[0];
      var update$10 = function (trigger) {
        var sourceIds = trigger.sources;
        var tmp;
        if (sourceIds) {
          var sourceIds$1 = sourceIds._0;
          tmp = sourceIds$1.includes(sourceId$20) ? /* SourceIds */({
                _0: sourceIds$1
              }) : /* SourceIds */({
                _0: Belt_Array.concat(sourceIds$1, [sourceId$20])
              });
        } else {
          tmp = /* SourceIds */{
            _0: [sourceId$20]
          };
        }
        return {
                id: trigger.id,
                sources: tmp,
                description: trigger.description,
                content: trigger.content
              };
      };
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEventVariantTrigger(model._0, variantIdentifier$10, triggerId$6, update$10)
              };
      } else {
        return {
                TAG: /* Mapped */1,
                _0: ModelUtils_mapped.updateEventVariantTrigger_mappedModel(model._0, variantIdentifier$10, triggerId$6, update$10)
              };
      }
    }
    if (variant === "ReorderEventsInMetric") {
      var match$92 = action.VAL;
      var maybePredecessorId$5 = match$92[2];
      var metricItemId$3 = match$92[1];
      var metricId$9 = match$92[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateMetric(model._0, metricId$9, (function (metric) {
                        var movedItem = Belt_List.getByU(metric.items, (function (metricItem) {
                                return metricItem.VAL.id === metricItemId$3;
                              }));
                        var listWithoutItem = Belt_List.keepU(metric.items, (function (metricItem) {
                                return metricItem.VAL.id !== metricItemId$3;
                              }));
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metric.type_,
                                items: movedItem !== undefined ? (
                                    maybePredecessorId$5 !== undefined ? BeltListExtensions.flatMap(listWithoutItem, (function (x) {
                                              if (x.VAL.id === maybePredecessorId$5) {
                                                return {
                                                        hd: x,
                                                        tl: {
                                                          hd: movedItem,
                                                          tl: /* [] */0
                                                        }
                                                      };
                                              } else {
                                                return {
                                                        hd: x,
                                                        tl: /* [] */0
                                                      };
                                              }
                                            })) : Belt_List.concat({
                                            hd: movedItem,
                                            tl: /* [] */0
                                          }, listWithoutItem)
                                  ) : metric.items
                              };
                      }))
              };
      }
      var model$147 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$147.archive,
                branchPointer: model$147.branchPointer,
                migrations: model$147.migrations,
                rules: model$147.rules,
                types: model$147.types,
                categories: model$147.categories,
                destinations: model$147.destinations,
                events: model$147.events,
                groupTypes: model$147.groupTypes,
                integrations: model$147.integrations,
                metrics: Curry._3(TrackingPlanMappedModel.Metrics.updateExisting, model$147.metrics, metricId$9, (function (metric) {
                        var movedItem = Belt_List.getByU(metric.items, (function (metricItem) {
                                return metricItem.VAL.id === metricItemId$3;
                              }));
                        var listWithoutItem = Belt_List.keepU(metric.items, (function (metricItem) {
                                return metricItem.VAL.id !== metricItemId$3;
                              }));
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metric.type_,
                                items: movedItem !== undefined ? (
                                    maybePredecessorId$5 !== undefined ? BeltListExtensions.flatMap(listWithoutItem, (function (x) {
                                              if (x.VAL.id === maybePredecessorId$5) {
                                                return {
                                                        hd: x,
                                                        tl: {
                                                          hd: movedItem,
                                                          tl: /* [] */0
                                                        }
                                                      };
                                              } else {
                                                return {
                                                        hd: x,
                                                        tl: /* [] */0
                                                      };
                                              }
                                            })) : Belt_List.concat({
                                            hd: movedItem,
                                            tl: /* [] */0
                                          }, listWithoutItem)
                                  ) : metric.items
                              };
                      })),
                openBranches: model$147.openBranches,
                properties: model$147.properties,
                propertyBundles: model$147.propertyBundles,
                sources: model$147.sources,
                globalRequirements: model$147.globalRequirements
              }
            };
    }
    if (variant === "CreateDestination") {
      var destinationId$9 = action.VAL;
      if (model.TAG === /* Old */0) {
        var model$148 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$148.types,
                  properties: model$148.properties,
                  propertyBundles: model$148.propertyBundles,
                  events: model$148.events,
                  migrations: model$148.migrations,
                  sources: model$148.sources,
                  destinations: Belt_List.concat(model$148.destinations, {
                        hd: TrackingPlanModel.emptyDestination(destinationId$9),
                        tl: /* [] */0
                      }),
                  groupTypes: model$148.groupTypes,
                  goals: model$148.goals,
                  metrics: model$148.metrics,
                  archive: model$148.archive,
                  openBranches: model$148.openBranches,
                  branchPointer: model$148.branchPointer,
                  rules: model$148.rules,
                  integrations: model$148.integrations,
                  globalRequirements: model$148.globalRequirements
                }
              };
      }
      var model$149 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$149.archive,
                branchPointer: model$149.branchPointer,
                migrations: model$149.migrations,
                rules: model$149.rules,
                types: model$149.types,
                categories: model$149.categories,
                destinations: Curry._2(TrackingPlanMappedModel.Destinations.append, model$149.destinations, TrackingPlanModel.emptyDestination(destinationId$9)),
                events: model$149.events,
                groupTypes: model$149.groupTypes,
                integrations: model$149.integrations,
                metrics: model$149.metrics,
                openBranches: model$149.openBranches,
                properties: model$149.properties,
                propertyBundles: model$149.propertyBundles,
                sources: model$149.sources,
                globalRequirements: model$149.globalRequirements
              }
            };
    }
    if (variant === "UpdateNestedPropertyPinnedValue") {
      var match$93 = action.VAL;
      var pinnedValue = match$93[2];
      var nestedPropertyId = match$93[1];
      var propertyId$34 = match$93[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateProperty(model._0, propertyId$34, (function (property) {
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: Belt_List.map(property.validations, (function (x) {
                                        if (typeof x === "object" && x.NAME === "NestedProperty") {
                                          return {
                                                  NAME: "NestedProperty",
                                                  VAL: Belt_List.map(x.VAL, (function (propertyRef) {
                                                          if (propertyRef.id === nestedPropertyId) {
                                                            return {
                                                                    id: propertyRef.id,
                                                                    description: propertyRef.description,
                                                                    pinnedValue: pinnedValue
                                                                  };
                                                          } else {
                                                            return propertyRef;
                                                          }
                                                        }))
                                                };
                                        } else {
                                          return x;
                                        }
                                      })),
                                eventSpecificAllowedPropertyValues: property.eventSpecificAllowedPropertyValues,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence,
                                globalRequirementsMetadata: property.globalRequirementsMetadata
                              };
                      }))
              };
      }
      var model$150 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$150.archive,
                branchPointer: model$150.branchPointer,
                migrations: model$150.migrations,
                rules: model$150.rules,
                types: model$150.types,
                categories: model$150.categories,
                destinations: model$150.destinations,
                events: model$150.events,
                groupTypes: model$150.groupTypes,
                integrations: model$150.integrations,
                metrics: model$150.metrics,
                openBranches: model$150.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.updateExisting, model$150.properties, propertyId$34, (function (property) {
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: Belt_List.map(property.validations, (function (x) {
                                        if (typeof x === "object" && x.NAME === "NestedProperty") {
                                          return {
                                                  NAME: "NestedProperty",
                                                  VAL: Belt_List.map(x.VAL, (function (propertyRef) {
                                                          if (propertyRef.id === nestedPropertyId) {
                                                            return {
                                                                    id: propertyRef.id,
                                                                    description: propertyRef.description,
                                                                    pinnedValue: pinnedValue
                                                                  };
                                                          } else {
                                                            return propertyRef;
                                                          }
                                                        }))
                                                };
                                        } else {
                                          return x;
                                        }
                                      })),
                                eventSpecificAllowedPropertyValues: property.eventSpecificAllowedPropertyValues,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence,
                                globalRequirementsMetadata: property.globalRequirementsMetadata
                              };
                      })),
                propertyBundles: model$150.propertyBundles,
                sources: model$150.sources,
                globalRequirements: model$150.globalRequirements
              }
            };
    }
    if (variant === "AddMetricToGoal") {
      var match$94 = action.VAL;
      var metricId$10 = match$94[1];
      var goalId$6 = match$94[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateGoal(model._0, goalId$6, (function (goal) {
                        return {
                                id: goal.id,
                                name: goal.name,
                                description: goal.description,
                                metrics: Belt_List.someU(goal.metrics, (function (id) {
                                        return id === metricId$10;
                                      })) ? goal.metrics : Belt_List.concat(goal.metrics, {
                                        hd: metricId$10,
                                        tl: /* [] */0
                                      }),
                                events: goal.events
                              };
                      }))
              };
      }
      var model$151 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$151.archive,
                branchPointer: model$151.branchPointer,
                migrations: model$151.migrations,
                rules: model$151.rules,
                types: model$151.types,
                categories: Curry._3(TrackingPlanMappedModel.Categories.updateExisting, model$151.categories, goalId$6, (function (category) {
                        return {
                                id: category.id,
                                name: category.name,
                                description: category.description,
                                metrics: Belt_List.someU(category.metrics, (function (id) {
                                        return id === metricId$10;
                                      })) ? category.metrics : Belt_List.concat(category.metrics, {
                                        hd: metricId$10,
                                        tl: /* [] */0
                                      }),
                                events: category.events
                              };
                      })),
                destinations: model$151.destinations,
                events: model$151.events,
                groupTypes: model$151.groupTypes,
                integrations: model$151.integrations,
                metrics: model$151.metrics,
                openBranches: model$151.openBranches,
                properties: model$151.properties,
                propertyBundles: model$151.propertyBundles,
                sources: model$151.sources,
                globalRequirements: model$151.globalRequirements
              }
            };
    }
    if (variant === "AddEventSpecificPropertyValueForAllEvents") {
      var match$95 = action.VAL;
      var literal$5 = match$95[1];
      var propertyId$35 = match$95[0];
      var addPropertyValue = function (property) {
        var propertyDisallowedSourcesBefore = property.eventSpecificAllowedPropertyValues;
        var alreadyHasLiteral = Belt_Array.some(propertyDisallowedSourcesBefore, (function (param) {
                return Caml_obj.caml_equal(param[0], literal$5);
              }));
        var eventSpecificAllowedPropertyValues = alreadyHasLiteral ? Belt_Array.map(propertyDisallowedSourcesBefore, (function (param) {
                  var existingLiteral = param[0];
                  if (Caml_obj.caml_equal(existingLiteral, literal$5)) {
                    return [
                            literal$5,
                            undefined
                          ];
                  } else {
                    return [
                            existingLiteral,
                            param[1]
                          ];
                  }
                })) : Belt_Array.concat(propertyDisallowedSourcesBefore, [[
                  literal$5,
                  undefined
                ]]);
        return {
                id: property.id,
                name: property.name,
                uniqueName: property.uniqueName,
                description: property.description,
                type_: property.type_,
                sendAs: property.sendAs,
                validations: property.validations,
                eventSpecificAllowedPropertyValues: eventSpecificAllowedPropertyValues,
                auto: property.auto,
                builtIn: property.builtIn,
                analyticsTools: property.analyticsTools,
                devPlatforms: property.devPlatforms,
                list: property.list,
                operation: property.operation,
                optionalDeprecated: property.optionalDeprecated,
                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                optionalWhenInObject: property.optionalWhenInObject,
                absence: property.absence,
                globalRequirementsMetadata: property.globalRequirementsMetadata
              };
      };
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateProperty(model._0, propertyId$35, addPropertyValue)
              };
      }
      var model$152 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$152.archive,
                branchPointer: model$152.branchPointer,
                migrations: model$152.migrations,
                rules: model$152.rules,
                types: model$152.types,
                categories: model$152.categories,
                destinations: model$152.destinations,
                events: model$152.events,
                groupTypes: model$152.groupTypes,
                integrations: model$152.integrations,
                metrics: model$152.metrics,
                openBranches: model$152.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.updateExisting, model$152.properties, propertyId$35, addPropertyValue),
                propertyBundles: model$152.propertyBundles,
                sources: model$152.sources,
                globalRequirements: model$152.globalRequirements
              }
            };
    }
    if (variant === "ExcludeDestinationFromEventSource") {
      var match$96 = action.VAL;
      var destinationId$10 = match$96[2];
      var sourceId$21 = match$96[1];
      var eventId$29 = match$96[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEvent(model._0, eventId$29, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: Belt_List.mapU($$event.includeDestinations, (function (includeDestination) {
                                        var match = includeDestination.VAL;
                                        var id = match[0];
                                        if (id === sourceId$21) {
                                          return {
                                                  NAME: "IncludeSourceDestination",
                                                  VAL: [
                                                    id,
                                                    Belt_List.keepU(match[1], (function (id) {
                                                            return id !== destinationId$10;
                                                          }))
                                                  ]
                                                };
                                        } else {
                                          return includeDestination;
                                        }
                                      })),
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      }))
              };
      }
      var model$153 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$153.archive,
                branchPointer: model$153.branchPointer,
                migrations: model$153.migrations,
                rules: model$153.rules,
                types: model$153.types,
                categories: model$153.categories,
                destinations: model$153.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.updateExisting, model$153.events, eventId$29, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: Belt_List.mapU($$event.includeDestinations, (function (includeDestination) {
                                        var match = includeDestination.VAL;
                                        var id = match[0];
                                        if (id === sourceId$21) {
                                          return {
                                                  NAME: "IncludeSourceDestination",
                                                  VAL: [
                                                    id,
                                                    Belt_List.keepU(match[1], (function (id) {
                                                            return id !== destinationId$10;
                                                          }))
                                                  ]
                                                };
                                        } else {
                                          return includeDestination;
                                        }
                                      })),
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      })),
                groupTypes: model$153.groupTypes,
                integrations: model$153.integrations,
                metrics: model$153.metrics,
                openBranches: model$153.openBranches,
                properties: model$153.properties,
                propertyBundles: model$153.propertyBundles,
                sources: model$153.sources,
                globalRequirements: model$153.globalRequirements
              }
            };
    }
    if (variant === "DeleteDestination") {
      var destinationId$11 = action.VAL;
      if (model.TAG === /* Old */0) {
        var model$154 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$154.types,
                  properties: model$154.properties,
                  propertyBundles: model$154.propertyBundles,
                  events: model$154.events,
                  migrations: model$154.migrations,
                  sources: Belt_List.mapU(model$154.sources, (function (source) {
                          return {
                                  id: source.id,
                                  name: source.name,
                                  language: source.language,
                                  platform: source.platform,
                                  destinations: Belt_List.keepU(source.destinations, (function (sourceDestination) {
                                          return sourceDestination.destinationId !== destinationId$11;
                                        })),
                                  filename: source.filename,
                                  path: source.path,
                                  segmentDestinationOptions: source.segmentDestinationOptions,
                                  config: source.config
                                };
                        })),
                  destinations: Belt_List.keepU(model$154.destinations, (function (destination) {
                          return destination.id !== destinationId$11;
                        })),
                  groupTypes: model$154.groupTypes,
                  goals: model$154.goals,
                  metrics: model$154.metrics,
                  archive: model$154.archive,
                  openBranches: model$154.openBranches,
                  branchPointer: model$154.branchPointer,
                  rules: model$154.rules,
                  integrations: model$154.integrations,
                  globalRequirements: model$154.globalRequirements
                }
              };
      }
      var model$155 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$155.archive,
                branchPointer: model$155.branchPointer,
                migrations: model$155.migrations,
                rules: model$155.rules,
                types: model$155.types,
                categories: model$155.categories,
                destinations: Curry._2(TrackingPlanMappedModel.Destinations.remove, model$155.destinations, destinationId$11),
                events: model$155.events,
                groupTypes: model$155.groupTypes,
                integrations: model$155.integrations,
                metrics: model$155.metrics,
                openBranches: model$155.openBranches,
                properties: model$155.properties,
                propertyBundles: model$155.propertyBundles,
                sources: Curry._2(TrackingPlanMappedModel.Sources.mapToT, model$155.sources, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: source.language,
                                platform: source.platform,
                                destinations: Belt_List.keepU(source.destinations, (function (sourceDestination) {
                                        return sourceDestination.destinationId !== destinationId$11;
                                      })),
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: source.config
                              };
                      })),
                globalRequirements: model$155.globalRequirements
              }
            };
    }
    if (variant === "UpdatePropertyGroupDescription") {
      var match$97 = action.VAL;
      var description$6 = match$97[1];
      var groupId = match$97[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updatePropertyGroup(model._0, groupId, (function (group) {
                        return {
                                id: group.id,
                                name: group.name,
                                description: description$6,
                                properties: group.properties,
                                globalRequirementsMetadata: group.globalRequirementsMetadata
                              };
                      }))
              };
      }
      var model$156 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$156.archive,
                branchPointer: model$156.branchPointer,
                migrations: model$156.migrations,
                rules: model$156.rules,
                types: model$156.types,
                categories: model$156.categories,
                destinations: model$156.destinations,
                events: model$156.events,
                groupTypes: model$156.groupTypes,
                integrations: model$156.integrations,
                metrics: model$156.metrics,
                openBranches: model$156.openBranches,
                properties: model$156.properties,
                propertyBundles: Curry._3(TrackingPlanMappedModel.PropertyBundles.updateExisting, model$156.propertyBundles, groupId, (function (propertyBundle) {
                        return {
                                id: propertyBundle.id,
                                name: propertyBundle.name,
                                description: description$6,
                                properties: propertyBundle.properties,
                                globalRequirementsMetadata: propertyBundle.globalRequirementsMetadata
                              };
                      })),
                sources: model$156.sources,
                globalRequirements: model$156.globalRequirements
              }
            };
    }
    if (variant === "ExcludeEventFromSource") {
      var match$98 = action.VAL;
      var sourceId$22 = match$98[1];
      var eventId$30 = match$98[0];
      if (model.TAG === /* Old */0) {
        var model$157 = model._0;
        return {
                TAG: /* Old */0,
                _0: ModelUtils.hasMigrated(model$157, "ExplicitIncludeSource") ? ModelUtils.updateEvent(model$157, eventId$30, (function ($$event) {
                          return {
                                  id: $$event.id,
                                  name: $$event.name,
                                  uniqueName: $$event.uniqueName,
                                  description: $$event.description,
                                  properties: $$event.properties,
                                  propertyBundles: $$event.propertyBundles,
                                  variants: $$event.variants,
                                  types: $$event.types,
                                  tags: $$event.tags,
                                  excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                  includeSources: Belt_List.keepU($$event.includeSources, (function (includedSource) {
                                          return includedSource.id !== sourceId$22;
                                        })),
                                  includeDestinations: $$event.includeDestinations,
                                  hashes: $$event.hashes,
                                  propertyWhitelist: $$event.propertyWhitelist,
                                  eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                  userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                  triggers: $$event.triggers,
                                  globalRequirementsMetadata: $$event.globalRequirementsMetadata
                                };
                        })) : ModelUtils.updateEvent(model$157, eventId$30, (function ($$event) {
                          return {
                                  id: $$event.id,
                                  name: $$event.name,
                                  uniqueName: $$event.uniqueName,
                                  description: $$event.description,
                                  properties: $$event.properties,
                                  propertyBundles: $$event.propertyBundles,
                                  variants: $$event.variants,
                                  types: $$event.types,
                                  tags: $$event.tags,
                                  excludeSourcesDeprecated: {
                                    hd: sourceId$22,
                                    tl: $$event.excludeSourcesDeprecated
                                  },
                                  includeSources: $$event.includeSources,
                                  includeDestinations: $$event.includeDestinations,
                                  hashes: $$event.hashes,
                                  propertyWhitelist: $$event.propertyWhitelist,
                                  eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                  userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                  triggers: $$event.triggers,
                                  globalRequirementsMetadata: $$event.globalRequirementsMetadata
                                };
                        }))
              };
      }
      var model$158 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$158.archive,
                branchPointer: model$158.branchPointer,
                migrations: model$158.migrations,
                rules: model$158.rules,
                types: model$158.types,
                categories: model$158.categories,
                destinations: model$158.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.updateExisting, model$158.events, eventId$30, (function ($$event) {
                        if (ModelUtils.hasMigrated_boxed(model, "ExplicitIncludeSource")) {
                          return {
                                  id: $$event.id,
                                  name: $$event.name,
                                  uniqueName: $$event.uniqueName,
                                  description: $$event.description,
                                  properties: $$event.properties,
                                  propertyBundles: $$event.propertyBundles,
                                  variants: $$event.variants,
                                  types: $$event.types,
                                  tags: $$event.tags,
                                  excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                  includeSources: Belt_List.keepU($$event.includeSources, (function (includedSource) {
                                          return includedSource.id !== sourceId$22;
                                        })),
                                  includeDestinations: $$event.includeDestinations,
                                  hashes: $$event.hashes,
                                  propertyWhitelist: $$event.propertyWhitelist,
                                  eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                  userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                  triggers: $$event.triggers,
                                  globalRequirementsMetadata: $$event.globalRequirementsMetadata
                                };
                        } else {
                          return {
                                  id: $$event.id,
                                  name: $$event.name,
                                  uniqueName: $$event.uniqueName,
                                  description: $$event.description,
                                  properties: $$event.properties,
                                  propertyBundles: $$event.propertyBundles,
                                  variants: $$event.variants,
                                  types: $$event.types,
                                  tags: $$event.tags,
                                  excludeSourcesDeprecated: {
                                    hd: sourceId$22,
                                    tl: $$event.excludeSourcesDeprecated
                                  },
                                  includeSources: $$event.includeSources,
                                  includeDestinations: $$event.includeDestinations,
                                  hashes: $$event.hashes,
                                  propertyWhitelist: $$event.propertyWhitelist,
                                  eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                  userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                  triggers: $$event.triggers,
                                  globalRequirementsMetadata: $$event.globalRequirementsMetadata
                                };
                        }
                      })),
                groupTypes: model$158.groupTypes,
                integrations: model$158.integrations,
                metrics: model$158.metrics,
                openBranches: model$158.openBranches,
                properties: model$158.properties,
                propertyBundles: model$158.propertyBundles,
                sources: model$158.sources,
                globalRequirements: model$158.globalRequirements
              }
            };
    }
    if (variant === "RemoveMetricGroupByV2") {
      var match$99 = action.VAL;
      var id$9 = match$99[3];
      var metricItemId$4 = match$99[1];
      var metricId$11 = match$99[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateMetric(model._0, metricId$11, (function (metric) {
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metric.type_,
                                items: Belt_List.mapU(metric.items, (function (metricItem) {
                                        var $$event = metricItem.VAL;
                                        if (metricItemId$4 === $$event.id) {
                                          return {
                                                  NAME: "Event",
                                                  VAL: {
                                                    id: $$event.id,
                                                    eventId: $$event.eventId,
                                                    where: $$event.where,
                                                    groupBy: Belt_List.keepU($$event.groupBy, (function (groupBy) {
                                                            return groupBy.id !== id$9;
                                                          }))
                                                  }
                                                };
                                        } else {
                                          return metricItem;
                                        }
                                      }))
                              };
                      }))
              };
      }
      var model$159 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$159.archive,
                branchPointer: model$159.branchPointer,
                migrations: model$159.migrations,
                rules: model$159.rules,
                types: model$159.types,
                categories: model$159.categories,
                destinations: model$159.destinations,
                events: model$159.events,
                groupTypes: model$159.groupTypes,
                integrations: model$159.integrations,
                metrics: Curry._3(TrackingPlanMappedModel.Metrics.updateExisting, model$159.metrics, metricId$11, (function (metric) {
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metric.type_,
                                items: Belt_List.mapU(metric.items, (function (metricItem) {
                                        var $$event = metricItem.VAL;
                                        if (metricItemId$4 === $$event.id) {
                                          return {
                                                  NAME: "Event",
                                                  VAL: {
                                                    id: $$event.id,
                                                    eventId: $$event.eventId,
                                                    where: $$event.where,
                                                    groupBy: Belt_List.keepU($$event.groupBy, (function (groupBy) {
                                                            return groupBy.id !== id$9;
                                                          }))
                                                  }
                                                };
                                        } else {
                                          return metricItem;
                                        }
                                      }))
                              };
                      })),
                openBranches: model$159.openBranches,
                properties: model$159.properties,
                propertyBundles: model$159.propertyBundles,
                sources: model$159.sources,
                globalRequirements: model$159.globalRequirements
              }
            };
    }
    if (variant === "UpdateTriggerDescription") {
      var match$100 = action.VAL;
      var description$7 = match$100[2];
      var triggerId$7 = match$100[1];
      var eventId$31 = match$100[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEventTrigger(model._0, eventId$31, triggerId$7, (function (trigger) {
                        return {
                                id: trigger.id,
                                sources: trigger.sources,
                                description: description$7,
                                content: trigger.content
                              };
                      }))
              };
      }
      var model$160 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$160.archive,
                branchPointer: model$160.branchPointer,
                migrations: model$160.migrations,
                rules: model$160.rules,
                types: model$160.types,
                categories: model$160.categories,
                destinations: model$160.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.updateExisting, model$160.events, eventId$31, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: Belt_Array.mapU($$event.triggers, (function (trigger) {
                                        if (trigger.id === triggerId$7) {
                                          return {
                                                  id: trigger.id,
                                                  sources: trigger.sources,
                                                  description: description$7,
                                                  content: trigger.content
                                                };
                                        } else {
                                          return trigger;
                                        }
                                      })),
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      })),
                groupTypes: model$160.groupTypes,
                integrations: model$160.integrations,
                metrics: model$160.metrics,
                openBranches: model$160.openBranches,
                properties: model$160.properties,
                propertyBundles: model$160.propertyBundles,
                sources: model$160.sources,
                globalRequirements: model$160.globalRequirements
              }
            };
    }
    if (variant === "AddPropertyToWhitelist") {
      var match$101 = action.VAL;
      var analyticsTool$1 = match$101[2];
      var propertyId$36 = match$101[1];
      var eventId$32 = match$101[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEvent(model._0, eventId$32, (function ($$event) {
                        var alreadyAdded = Belt_List.someU($$event.propertyWhitelist, (function (param) {
                                if (param[0] === analyticsTool$1) {
                                  return param[1] === propertyId$36;
                                } else {
                                  return false;
                                }
                              }));
                        var exceedsIntercomLimit = Belt_List.length(Belt_List.keepU($$event.propertyWhitelist, (function (param) {
                                    return param[0] === "Intercom";
                                  }))) >= AvoConfig.intercomPropertyLimit;
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: alreadyAdded || exceedsIntercomLimit ? $$event.propertyWhitelist : Belt_List.concat($$event.propertyWhitelist, {
                                        hd: [
                                          analyticsTool$1,
                                          propertyId$36
                                        ],
                                        tl: /* [] */0
                                      }),
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      }))
              };
      }
      var model$161 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$161.archive,
                branchPointer: model$161.branchPointer,
                migrations: model$161.migrations,
                rules: model$161.rules,
                types: model$161.types,
                categories: model$161.categories,
                destinations: model$161.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.updateExisting, model$161.events, eventId$32, (function ($$event) {
                        var alreadyAdded = Belt_List.someU($$event.propertyWhitelist, (function (param) {
                                if (param[0] === analyticsTool$1) {
                                  return param[1] === propertyId$36;
                                } else {
                                  return false;
                                }
                              }));
                        var exceedsIntercomLimit = Belt_List.length(Belt_List.keepU($$event.propertyWhitelist, (function (param) {
                                    return param[0] === "Intercom";
                                  }))) >= AvoConfig.intercomPropertyLimit;
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: alreadyAdded || exceedsIntercomLimit ? $$event.propertyWhitelist : Belt_List.concat($$event.propertyWhitelist, {
                                        hd: [
                                          analyticsTool$1,
                                          propertyId$36
                                        ],
                                        tl: /* [] */0
                                      }),
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      })),
                groupTypes: model$161.groupTypes,
                integrations: model$161.integrations,
                metrics: model$161.metrics,
                openBranches: model$161.openBranches,
                properties: model$161.properties,
                propertyBundles: model$161.propertyBundles,
                sources: model$161.sources,
                globalRequirements: model$161.globalRequirements
              }
            };
    }
    if (variant === "AllowAllEventSpecificPropertyValuesOnAllEventsAndSources") {
      var propertyId$37 = action.VAL;
      var allowAllValues = function (property) {
        return {
                id: property.id,
                name: property.name,
                uniqueName: property.uniqueName,
                description: property.description,
                type_: property.type_,
                sendAs: property.sendAs,
                validations: property.validations,
                eventSpecificAllowedPropertyValues: Belt_Array.mapU(property.eventSpecificAllowedPropertyValues, (function (param) {
                        return [
                                param[0],
                                undefined
                              ];
                      })),
                auto: property.auto,
                builtIn: property.builtIn,
                analyticsTools: property.analyticsTools,
                devPlatforms: property.devPlatforms,
                list: property.list,
                operation: property.operation,
                optionalDeprecated: property.optionalDeprecated,
                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                optionalWhenInObject: property.optionalWhenInObject,
                absence: property.absence,
                globalRequirementsMetadata: property.globalRequirementsMetadata
              };
      };
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateProperty(model._0, propertyId$37, allowAllValues)
              };
      }
      var model$162 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$162.archive,
                branchPointer: model$162.branchPointer,
                migrations: model$162.migrations,
                rules: model$162.rules,
                types: model$162.types,
                categories: model$162.categories,
                destinations: model$162.destinations,
                events: model$162.events,
                groupTypes: model$162.groupTypes,
                integrations: model$162.integrations,
                metrics: model$162.metrics,
                openBranches: model$162.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.updateExisting, model$162.properties, propertyId$37, allowAllValues),
                propertyBundles: model$162.propertyBundles,
                sources: model$162.sources,
                globalRequirements: model$162.globalRequirements
              }
            };
    }
    if (variant === "IncludeDestinationInSourceV2") {
      var match$102 = action.VAL;
      var eventIds$1 = match$102[2];
      var destinationId$12 = match$102[1];
      var sourceId$23 = match$102[0];
      if (model.TAG === /* Old */0) {
        var model$163 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$163.types,
                  properties: model$163.properties,
                  propertyBundles: model$163.propertyBundles,
                  events: Belt_List.mapU(model$163.events, (function ($$event) {
                          if (!Belt_List.someU(eventIds$1, (function (id) {
                                    return id === $$event.id;
                                  }))) {
                            return $$event;
                          }
                          var includedDestinations = Belt_List.flatten(Belt_List.keepMapU($$event.includeDestinations, (function (includeDestination) {
                                      var match = includeDestination.VAL;
                                      if (match[0] === sourceId$23) {
                                        return match[1];
                                      }
                                      
                                    })));
                          var otherIncludedDestinations = Belt_List.keepU($$event.includeDestinations, (function (includeDestination) {
                                  return includeDestination.VAL[0] !== sourceId$23;
                                }));
                          return {
                                  id: $$event.id,
                                  name: $$event.name,
                                  uniqueName: $$event.uniqueName,
                                  description: $$event.description,
                                  properties: $$event.properties,
                                  propertyBundles: $$event.propertyBundles,
                                  variants: $$event.variants,
                                  types: $$event.types,
                                  tags: $$event.tags,
                                  excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                  includeSources: $$event.includeSources,
                                  includeDestinations: Belt_List.concat(otherIncludedDestinations, {
                                        hd: {
                                          NAME: "IncludeSourceDestination",
                                          VAL: [
                                            sourceId$23,
                                            Belt_List.concat(includedDestinations, {
                                                  hd: destinationId$12,
                                                  tl: /* [] */0
                                                })
                                          ]
                                        },
                                        tl: /* [] */0
                                      }),
                                  hashes: $$event.hashes,
                                  propertyWhitelist: $$event.propertyWhitelist,
                                  eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                  userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                  triggers: $$event.triggers,
                                  globalRequirementsMetadata: $$event.globalRequirementsMetadata
                                };
                        })),
                  migrations: model$163.migrations,
                  sources: Belt_List.mapU(model$163.sources, (function (source) {
                          if (source.id === sourceId$23) {
                            return {
                                    id: source.id,
                                    name: source.name,
                                    language: source.language,
                                    platform: source.platform,
                                    destinations: Belt_List.concat(source.destinations, {
                                          hd: {
                                            destinationId: destinationId$12,
                                            destinationMode: /* DestinationInterface */1
                                          },
                                          tl: /* [] */0
                                        }),
                                    filename: source.filename,
                                    path: source.path,
                                    segmentDestinationOptions: source.segmentDestinationOptions,
                                    config: source.config
                                  };
                          } else {
                            return source;
                          }
                        })),
                  destinations: model$163.destinations,
                  groupTypes: model$163.groupTypes,
                  goals: model$163.goals,
                  metrics: model$163.metrics,
                  archive: model$163.archive,
                  openBranches: model$163.openBranches,
                  branchPointer: model$163.branchPointer,
                  rules: model$163.rules,
                  integrations: model$163.integrations,
                  globalRequirements: model$163.globalRequirements
                }
              };
      }
      var model$164 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$164.archive,
                branchPointer: model$164.branchPointer,
                migrations: model$164.migrations,
                rules: model$164.rules,
                types: model$164.types,
                categories: model$164.categories,
                destinations: model$164.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.updateManyExisting, model$164.events, Belt_List.toArray(eventIds$1), (function ($$event) {
                        var includedDestinations = Belt_List.flatten(Belt_List.keepMapU($$event.includeDestinations, (function (includeDestination) {
                                    var match = includeDestination.VAL;
                                    if (match[0] === sourceId$23) {
                                      return match[1];
                                    }
                                    
                                  })));
                        var otherIncludedDestinations = Belt_List.keepU($$event.includeDestinations, (function (includeDestination) {
                                return includeDestination.VAL[0] !== sourceId$23;
                              }));
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: Belt_List.concat(otherIncludedDestinations, {
                                      hd: {
                                        NAME: "IncludeSourceDestination",
                                        VAL: [
                                          sourceId$23,
                                          Belt_List.concat(includedDestinations, {
                                                hd: destinationId$12,
                                                tl: /* [] */0
                                              })
                                        ]
                                      },
                                      tl: /* [] */0
                                    }),
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      })),
                groupTypes: model$164.groupTypes,
                integrations: model$164.integrations,
                metrics: model$164.metrics,
                openBranches: model$164.openBranches,
                properties: model$164.properties,
                propertyBundles: model$164.propertyBundles,
                sources: Curry._3(TrackingPlanMappedModel.Sources.updateExisting, model$164.sources, sourceId$23, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: source.language,
                                platform: source.platform,
                                destinations: Belt_List.concat(source.destinations, {
                                      hd: {
                                        destinationId: destinationId$12,
                                        destinationMode: /* DestinationInterface */1
                                      },
                                      tl: /* [] */0
                                    }),
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: source.config
                              };
                      })),
                globalRequirements: model$164.globalRequirements
              }
            };
    }
    if (variant === "CreateIntegration") {
      var integrationId$3 = action.VAL;
      if (model.TAG === /* Old */0) {
        var model$165 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$165.types,
                  properties: model$165.properties,
                  propertyBundles: model$165.propertyBundles,
                  events: model$165.events,
                  migrations: model$165.migrations,
                  sources: model$165.sources,
                  destinations: model$165.destinations,
                  groupTypes: model$165.groupTypes,
                  goals: model$165.goals,
                  metrics: model$165.metrics,
                  archive: model$165.archive,
                  openBranches: model$165.openBranches,
                  branchPointer: model$165.branchPointer,
                  rules: model$165.rules,
                  integrations: Belt_List.concat(model$165.integrations, {
                        hd: TrackingPlanModel.emptyIntegration(integrationId$3),
                        tl: /* [] */0
                      }),
                  globalRequirements: model$165.globalRequirements
                }
              };
      }
      var model$166 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$166.archive,
                branchPointer: model$166.branchPointer,
                migrations: model$166.migrations,
                rules: model$166.rules,
                types: model$166.types,
                categories: model$166.categories,
                destinations: model$166.destinations,
                events: model$166.events,
                groupTypes: model$166.groupTypes,
                integrations: Curry._2(TrackingPlanMappedModel.Integrations.append, model$166.integrations, TrackingPlanModel.emptyIntegration(integrationId$3)),
                metrics: model$166.metrics,
                openBranches: model$166.openBranches,
                properties: model$166.properties,
                propertyBundles: model$166.propertyBundles,
                sources: model$166.sources,
                globalRequirements: model$166.globalRequirements
              }
            };
    }
    if (variant === "UpdateDestinationAnalyticsTool") {
      var match$103 = action.VAL;
      var analyticsTool$2 = match$103[1];
      var destinationId$13 = match$103[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateDestination(model._0, destinationId$13, (function (destination) {
                        return {
                                id: destination.id,
                                name: destination.name,
                                type_: analyticsTool$2,
                                apiKeys: destination.apiKeys,
                                includeUserPropsWithEventProps: destination.includeUserPropsWithEventProps,
                                disabledByDefault: destination.disabledByDefault
                              };
                      }))
              };
      }
      var model$167 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$167.archive,
                branchPointer: model$167.branchPointer,
                migrations: model$167.migrations,
                rules: model$167.rules,
                types: model$167.types,
                categories: model$167.categories,
                destinations: Curry._3(TrackingPlanMappedModel.Destinations.updateExisting, model$167.destinations, destinationId$13, (function (destination) {
                        return {
                                id: destination.id,
                                name: destination.name,
                                type_: analyticsTool$2,
                                apiKeys: destination.apiKeys,
                                includeUserPropsWithEventProps: destination.includeUserPropsWithEventProps,
                                disabledByDefault: destination.disabledByDefault
                              };
                      })),
                events: model$167.events,
                groupTypes: model$167.groupTypes,
                integrations: model$167.integrations,
                metrics: model$167.metrics,
                openBranches: model$167.openBranches,
                properties: model$167.properties,
                propertyBundles: model$167.propertyBundles,
                sources: model$167.sources,
                globalRequirements: model$167.globalRequirements
              }
            };
    }
    if (variant === "SetMetricGroupByV2") {
      var match$104 = action.VAL;
      var propertyId$38 = match$104[4];
      var id$10 = match$104[3];
      var metricItemId$5 = match$104[1];
      var metricId$12 = match$104[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateMetric(model._0, metricId$12, (function (metric) {
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metric.type_,
                                items: Belt_List.mapU(metric.items, (function (metricItem) {
                                        var $$event = metricItem.VAL;
                                        if (metricItemId$5 !== $$event.id) {
                                          return metricItem;
                                        }
                                        var groupBy = $$event.groupBy;
                                        var match = Belt_List.keepU(groupBy, (function (groupBy) {
                                                return groupBy.id === id$10;
                                              }));
                                        return {
                                                NAME: "Event",
                                                VAL: {
                                                  id: $$event.id,
                                                  eventId: $$event.eventId,
                                                  where: $$event.where,
                                                  groupBy: match ? Belt_List.mapU(groupBy, (function (groupBy) {
                                                            if (groupBy.id === id$10) {
                                                              return {
                                                                      id: id$10,
                                                                      propertyId: propertyId$38
                                                                    };
                                                            } else {
                                                              return groupBy;
                                                            }
                                                          })) : Belt_List.concat(groupBy, {
                                                          hd: {
                                                            id: id$10,
                                                            propertyId: propertyId$38
                                                          },
                                                          tl: /* [] */0
                                                        })
                                                }
                                              };
                                      }))
                              };
                      }))
              };
      }
      var model$168 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$168.archive,
                branchPointer: model$168.branchPointer,
                migrations: model$168.migrations,
                rules: model$168.rules,
                types: model$168.types,
                categories: model$168.categories,
                destinations: model$168.destinations,
                events: model$168.events,
                groupTypes: model$168.groupTypes,
                integrations: model$168.integrations,
                metrics: Curry._3(TrackingPlanMappedModel.Metrics.updateExisting, model$168.metrics, metricId$12, (function (metric) {
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metric.type_,
                                items: Belt_List.mapU(metric.items, (function (metricItem) {
                                        var $$event = metricItem.VAL;
                                        if (metricItemId$5 !== $$event.id) {
                                          return metricItem;
                                        }
                                        var groupBy = $$event.groupBy;
                                        var match = Belt_List.keepU(groupBy, (function (groupBy) {
                                                return groupBy.id === id$10;
                                              }));
                                        return {
                                                NAME: "Event",
                                                VAL: {
                                                  id: $$event.id,
                                                  eventId: $$event.eventId,
                                                  where: $$event.where,
                                                  groupBy: match ? Belt_List.mapU(groupBy, (function (groupBy) {
                                                            if (groupBy.id === id$10) {
                                                              return {
                                                                      id: id$10,
                                                                      propertyId: propertyId$38
                                                                    };
                                                            } else {
                                                              return groupBy;
                                                            }
                                                          })) : Belt_List.concat(groupBy, {
                                                          hd: {
                                                            id: id$10,
                                                            propertyId: propertyId$38
                                                          },
                                                          tl: /* [] */0
                                                        })
                                                }
                                              };
                                      }))
                              };
                      })),
                openBranches: model$168.openBranches,
                properties: model$168.properties,
                propertyBundles: model$168.propertyBundles,
                sources: model$168.sources,
                globalRequirements: model$168.globalRequirements
              }
            };
    }
    if (variant === "RemovePropertyFromGroup") {
      var match$105 = action.VAL;
      var propertyId$39 = match$105[1];
      var groupId$1 = match$105[0];
      if (model.TAG === /* Old */0) {
        var model$169 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$169.types,
                  properties: model$169.properties,
                  propertyBundles: Belt_List.mapU(model$169.propertyBundles, (function (group) {
                          if (group.id === groupId$1) {
                            return {
                                    id: group.id,
                                    name: group.name,
                                    description: group.description,
                                    properties: Belt_List.keepU(group.properties, (function (id) {
                                            return id !== propertyId$39;
                                          })),
                                    globalRequirementsMetadata: group.globalRequirementsMetadata
                                  };
                          } else {
                            return group;
                          }
                        })),
                  events: Belt_List.mapU(model$169.events, (function ($$event) {
                          var hasGroup = Belt_List.someU($$event.propertyBundles, (function (groupRef) {
                                  return groupRef.id === groupId$1;
                                }));
                          var hasProperty = Belt_List.someU($$event.properties, (function (property) {
                                  return property._0.id === propertyId$39;
                                }));
                          return {
                                  id: $$event.id,
                                  name: $$event.name,
                                  uniqueName: $$event.uniqueName,
                                  description: $$event.description,
                                  properties: $$event.properties,
                                  propertyBundles: $$event.propertyBundles,
                                  variants: $$event.variants,
                                  types: $$event.types,
                                  tags: $$event.tags,
                                  excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                  includeSources: $$event.includeSources,
                                  includeDestinations: $$event.includeDestinations,
                                  hashes: $$event.hashes,
                                  propertyWhitelist: hasGroup && !hasProperty ? Belt_List.keepU($$event.propertyWhitelist, (function (param) {
                                            return param[1] !== propertyId$39;
                                          })) : $$event.propertyWhitelist,
                                  eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                  userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                  triggers: $$event.triggers,
                                  globalRequirementsMetadata: $$event.globalRequirementsMetadata
                                };
                        })),
                  migrations: model$169.migrations,
                  sources: model$169.sources,
                  destinations: model$169.destinations,
                  groupTypes: model$169.groupTypes,
                  goals: model$169.goals,
                  metrics: model$169.metrics,
                  archive: model$169.archive,
                  openBranches: model$169.openBranches,
                  branchPointer: model$169.branchPointer,
                  rules: model$169.rules,
                  integrations: model$169.integrations,
                  globalRequirements: model$169.globalRequirements
                }
              };
      }
      var model$170 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$170.archive,
                branchPointer: model$170.branchPointer,
                migrations: model$170.migrations,
                rules: model$170.rules,
                types: model$170.types,
                categories: model$170.categories,
                destinations: model$170.destinations,
                events: Curry._2(TrackingPlanMappedModel.Events.mapToT, model$170.events, (function ($$event) {
                        var hasGroup = Belt_List.someU($$event.propertyBundles, (function (groupRef) {
                                return groupRef.id === groupId$1;
                              }));
                        var hasProperty = Belt_List.someU($$event.properties, (function (property) {
                                return property._0.id === propertyId$39;
                              }));
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: hasGroup && !hasProperty ? Belt_List.keepU($$event.propertyWhitelist, (function (param) {
                                          return param[1] !== propertyId$39;
                                        })) : $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      })),
                groupTypes: model$170.groupTypes,
                integrations: model$170.integrations,
                metrics: model$170.metrics,
                openBranches: model$170.openBranches,
                properties: model$170.properties,
                propertyBundles: Curry._3(TrackingPlanMappedModel.PropertyBundles.updateExisting, model$170.propertyBundles, groupId$1, (function (propertyBundle) {
                        return {
                                id: propertyBundle.id,
                                name: propertyBundle.name,
                                description: propertyBundle.description,
                                properties: Belt_List.keepU(propertyBundle.properties, (function (id) {
                                        return id !== propertyId$39;
                                      })),
                                globalRequirementsMetadata: propertyBundle.globalRequirementsMetadata
                              };
                      })),
                sources: model$170.sources,
                globalRequirements: model$170.globalRequirements
              }
            };
    }
    if (variant === "AddEventToGoal") {
      var match$106 = action.VAL;
      var eventId$33 = match$106[1];
      var goalId$7 = match$106[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateGoal(model._0, goalId$7, (function (goal) {
                        return {
                                id: goal.id,
                                name: goal.name,
                                description: goal.description,
                                metrics: goal.metrics,
                                events: Belt_List.someU(goal.events, (function (id) {
                                        return id === eventId$33;
                                      })) ? goal.events : Belt_List.concat(goal.events, {
                                        hd: eventId$33,
                                        tl: /* [] */0
                                      })
                              };
                      }))
              };
      }
      var model$171 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$171.archive,
                branchPointer: model$171.branchPointer,
                migrations: model$171.migrations,
                rules: model$171.rules,
                types: model$171.types,
                categories: Curry._3(TrackingPlanMappedModel.Categories.updateExisting, model$171.categories, goalId$7, (function (category) {
                        return {
                                id: category.id,
                                name: category.name,
                                description: category.description,
                                metrics: category.metrics,
                                events: Belt_List.someU(category.events, (function (id) {
                                        return id === eventId$33;
                                      })) ? category.events : Belt_List.concat(category.events, {
                                        hd: eventId$33,
                                        tl: /* [] */0
                                      })
                              };
                      })),
                destinations: model$171.destinations,
                events: model$171.events,
                groupTypes: model$171.groupTypes,
                integrations: model$171.integrations,
                metrics: model$171.metrics,
                openBranches: model$171.openBranches,
                properties: model$171.properties,
                propertyBundles: model$171.propertyBundles,
                sources: model$171.sources,
                globalRequirements: model$171.globalRequirements
              }
            };
    }
    if (variant === "AddTriggerSource") {
      var match$107 = action.VAL;
      var sourceId$24 = match$107[2];
      var triggerId$8 = match$107[1];
      var eventId$34 = match$107[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEventTrigger(model._0, eventId$34, triggerId$8, (function (trigger) {
                        var sourceIds = trigger.sources;
                        var tmp;
                        if (sourceIds) {
                          var sourceIds$1 = sourceIds._0;
                          tmp = sourceIds$1.includes(sourceId$24) ? /* SourceIds */({
                                _0: sourceIds$1
                              }) : /* SourceIds */({
                                _0: Belt_Array.concat(sourceIds$1, [sourceId$24])
                              });
                        } else {
                          tmp = /* SourceIds */{
                            _0: [sourceId$24]
                          };
                        }
                        return {
                                id: trigger.id,
                                sources: tmp,
                                description: trigger.description,
                                content: trigger.content
                              };
                      }))
              };
      }
      var model$172 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$172.archive,
                branchPointer: model$172.branchPointer,
                migrations: model$172.migrations,
                rules: model$172.rules,
                types: model$172.types,
                categories: model$172.categories,
                destinations: model$172.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.updateExisting, model$172.events, eventId$34, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: Belt_Array.mapU($$event.triggers, (function (trigger) {
                                        if (trigger.id !== triggerId$8) {
                                          return trigger;
                                        }
                                        var sourceIds = trigger.sources;
                                        var tmp;
                                        if (sourceIds) {
                                          var sourceIds$1 = sourceIds._0;
                                          tmp = sourceIds$1.includes(sourceId$24) ? /* SourceIds */({
                                                _0: sourceIds$1
                                              }) : /* SourceIds */({
                                                _0: Belt_Array.concat(sourceIds$1, [sourceId$24])
                                              });
                                        } else {
                                          tmp = /* SourceIds */{
                                            _0: [sourceId$24]
                                          };
                                        }
                                        return {
                                                id: trigger.id,
                                                sources: tmp,
                                                description: trigger.description,
                                                content: trigger.content
                                              };
                                      })),
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      })),
                groupTypes: model$172.groupTypes,
                integrations: model$172.integrations,
                metrics: model$172.metrics,
                openBranches: model$172.openBranches,
                properties: model$172.properties,
                propertyBundles: model$172.propertyBundles,
                sources: model$172.sources,
                globalRequirements: model$172.globalRequirements
              }
            };
    }
    if (variant === "IncludeDestinationInEventSource") {
      var match$108 = action.VAL;
      var destinationId$14 = match$108[2];
      var sourceId$25 = match$108[1];
      var eventId$35 = match$108[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEvent(model._0, eventId$35, (function ($$event) {
                        var includedDestinations = Belt_List.flatten(Belt_List.keepMapU($$event.includeDestinations, (function (includeDestination) {
                                    var match = includeDestination.VAL;
                                    if (match[0] === sourceId$25) {
                                      return match[1];
                                    }
                                    
                                  })));
                        var otherIncludedDestinations = Belt_List.keepU($$event.includeDestinations, (function (includeDestination) {
                                return includeDestination.VAL[0] !== sourceId$25;
                              }));
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: Belt_List.concat(otherIncludedDestinations, {
                                      hd: {
                                        NAME: "IncludeSourceDestination",
                                        VAL: [
                                          sourceId$25,
                                          Belt_List.has(includedDestinations, destinationId$14, (function (prim0, prim1) {
                                                  return prim0 === prim1;
                                                })) ? includedDestinations : Belt_List.concat(includedDestinations, {
                                                  hd: destinationId$14,
                                                  tl: /* [] */0
                                                })
                                        ]
                                      },
                                      tl: /* [] */0
                                    }),
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      }))
              };
      }
      var model$173 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$173.archive,
                branchPointer: model$173.branchPointer,
                migrations: model$173.migrations,
                rules: model$173.rules,
                types: model$173.types,
                categories: model$173.categories,
                destinations: model$173.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.updateExisting, model$173.events, eventId$35, (function ($$event) {
                        var includedDestinations = Belt_List.flatten(Belt_List.keepMapU($$event.includeDestinations, (function (includeDestination) {
                                    var match = includeDestination.VAL;
                                    if (match[0] === sourceId$25) {
                                      return match[1];
                                    }
                                    
                                  })));
                        var otherIncludedDestinations = Belt_List.keepU($$event.includeDestinations, (function (includeDestination) {
                                return includeDestination.VAL[0] !== sourceId$25;
                              }));
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: Belt_List.concat(otherIncludedDestinations, {
                                      hd: {
                                        NAME: "IncludeSourceDestination",
                                        VAL: [
                                          sourceId$25,
                                          Belt_List.has(includedDestinations, destinationId$14, (function (prim0, prim1) {
                                                  return prim0 === prim1;
                                                })) ? includedDestinations : Belt_List.concat(includedDestinations, {
                                                  hd: destinationId$14,
                                                  tl: /* [] */0
                                                })
                                        ]
                                      },
                                      tl: /* [] */0
                                    }),
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      })),
                groupTypes: model$173.groupTypes,
                integrations: model$173.integrations,
                metrics: model$173.metrics,
                openBranches: model$173.openBranches,
                properties: model$173.properties,
                propertyBundles: model$173.propertyBundles,
                sources: model$173.sources,
                globalRequirements: model$173.globalRequirements
              }
            };
    }
    if (variant === "PushGlobalRequirementsToTrackingPlan") {
      return Belt_List.reduceU(action.VAL[2], model, reduceFunction);
    }
    if (variant === "AddGroupTypeToLogEvent") {
      var match$109 = action.VAL;
      var groupTypeId$3 = match$109[1];
      var eventId$36 = match$109[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEvent(model._0, eventId$36, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: Belt_Array.concat($$event.eventGroupTypeIdsWithArchive, [groupTypeId$3]),
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      }))
              };
      }
      var model$174 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$174.archive,
                branchPointer: model$174.branchPointer,
                migrations: model$174.migrations,
                rules: model$174.rules,
                types: model$174.types,
                categories: model$174.categories,
                destinations: model$174.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.updateExisting, model$174.events, eventId$36, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: Belt_Array.concat($$event.eventGroupTypeIdsWithArchive, [groupTypeId$3]),
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      })),
                groupTypes: model$174.groupTypes,
                integrations: model$174.integrations,
                metrics: model$174.metrics,
                openBranches: model$174.openBranches,
                properties: model$174.properties,
                propertyBundles: model$174.propertyBundles,
                sources: model$174.sources,
                globalRequirements: model$174.globalRequirements
              }
            };
    }
    if (variant === "UpdatePropertyPinnedValue") {
      var match$110 = action.VAL;
      var pinnedValue$1 = match$110[2];
      var propertyId$40 = match$110[1];
      var eventId$37 = match$110[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEvent(model._0, eventId$37, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: Belt_List.map($$event.properties, (function (property) {
                                        if (property.TAG !== /* PropertyRef */0) {
                                          return property;
                                        }
                                        var propRef = property._0;
                                        if (propRef.id === propertyId$40) {
                                          return {
                                                  TAG: /* PropertyRef */0,
                                                  _0: {
                                                    id: propRef.id,
                                                    description: propRef.description,
                                                    pinnedValue: pinnedValue$1
                                                  }
                                                };
                                        } else {
                                          return property;
                                        }
                                      })),
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      }))
              };
      }
      var model$175 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$175.archive,
                branchPointer: model$175.branchPointer,
                migrations: model$175.migrations,
                rules: model$175.rules,
                types: model$175.types,
                categories: model$175.categories,
                destinations: model$175.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.updateExisting, model$175.events, eventId$37, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: Belt_List.map($$event.properties, (function (property) {
                                        if (property.TAG !== /* PropertyRef */0) {
                                          return property;
                                        }
                                        var propRef = property._0;
                                        if (propRef.id === propertyId$40) {
                                          return {
                                                  TAG: /* PropertyRef */0,
                                                  _0: {
                                                    id: propRef.id,
                                                    description: propRef.description,
                                                    pinnedValue: pinnedValue$1
                                                  }
                                                };
                                        } else {
                                          return property;
                                        }
                                      })),
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      })),
                groupTypes: model$175.groupTypes,
                integrations: model$175.integrations,
                metrics: model$175.metrics,
                openBranches: model$175.openBranches,
                properties: model$175.properties,
                propertyBundles: model$175.propertyBundles,
                sources: model$175.sources,
                globalRequirements: model$175.globalRequirements
              }
            };
    }
    if (variant === "RemoveUserFromGroup") {
      var match$111 = action.VAL;
      var groupTypeIdToRemove = match$111[1];
      var eventId$38 = match$111[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEvent(model._0, eventId$38, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: Belt_Array.keep($$event.userGroupTypeIdsWithArchive, (function (groupTypeId) {
                                        return groupTypeId !== groupTypeIdToRemove;
                                      })),
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      }))
              };
      }
      var model$176 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$176.archive,
                branchPointer: model$176.branchPointer,
                migrations: model$176.migrations,
                rules: model$176.rules,
                types: model$176.types,
                categories: model$176.categories,
                destinations: model$176.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.updateExisting, model$176.events, eventId$38, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: Belt_Array.keep($$event.userGroupTypeIdsWithArchive, (function (groupTypeId) {
                                        return groupTypeId !== groupTypeIdToRemove;
                                      })),
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      })),
                groupTypes: model$176.groupTypes,
                integrations: model$176.integrations,
                metrics: model$176.metrics,
                openBranches: model$176.openBranches,
                properties: model$176.properties,
                propertyBundles: model$176.propertyBundles,
                sources: model$176.sources,
                globalRequirements: model$176.globalRequirements
              }
            };
    }
    if (variant === "RemovePropertyValidation") {
      var match$112 = action.VAL;
      var validation = match$112[1];
      var propertyId$41 = match$112[0];
      if (model.TAG === /* Old */0) {
        var model$177 = model._0;
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateProperty(model$177, propertyId$41, (function (property) {
                        var variant = validation.NAME;
                        var newValidations;
                        if (variant === "EndsWith" || variant === "Contains" || variant === "StartsWith") {
                          newValidations = Pervasives.failwith("Unsupported validation type.");
                        } else if (variant === "Regex") {
                          newValidations = Belt_List.keepU(property.validations, (function (item) {
                                  if (typeof item === "object") {
                                    return item.NAME !== "Regex";
                                  } else {
                                    return true;
                                  }
                                }));
                        } else if (variant === "Max") {
                          newValidations = Belt_List.keepU(property.validations, (function (item) {
                                  if (typeof item === "object") {
                                    return item.NAME !== "Max";
                                  } else {
                                    return true;
                                  }
                                }));
                        } else if (variant === "Min") {
                          newValidations = Belt_List.keepU(property.validations, (function (item) {
                                  if (typeof item === "object") {
                                    return item.NAME !== "Min";
                                  } else {
                                    return true;
                                  }
                                }));
                        } else if (variant === "NestedProperty") {
                          var removePropertyRefs = validation.VAL;
                          newValidations = Belt_List.keepMapU(property.validations, (function (validation) {
                                  if (typeof validation !== "object") {
                                    return validation;
                                  }
                                  if (validation.NAME !== "NestedProperty") {
                                    return validation;
                                  }
                                  var nextPropertyRefs = Belt_List.keepU(validation.VAL, (function (propertyRef) {
                                          return Belt_List.someU(removePropertyRefs, (function (removePropertyRef) {
                                                        return removePropertyRef.id !== propertyRef.id;
                                                      }));
                                        }));
                                  return {
                                          NAME: "NestedProperty",
                                          VAL: nextPropertyRefs
                                        };
                                }));
                        } else if (variant === "Shape") {
                          var removeItems = validation.VAL;
                          newValidations = Belt_List.keepMapU(property.validations, (function (validation) {
                                  if (typeof validation !== "object") {
                                    return validation;
                                  }
                                  if (validation.NAME !== "Shape") {
                                    return validation;
                                  }
                                  var nextItems = Belt_List.keepU(validation.VAL, (function (item) {
                                          return Belt_List.someU(removeItems, (function (removeItem) {
                                                        return removeItem.key !== item.key;
                                                      }));
                                        }));
                                  if (Belt_List.length(nextItems) === 0) {
                                    return ;
                                  } else {
                                    return {
                                            NAME: "Shape",
                                            VAL: nextItems
                                          };
                                  }
                                }));
                        } else {
                          var removeMatches = validation.VAL;
                          newValidations = removeMatches ? Belt_List.keepMapU(property.validations, (function (validation) {
                                    if (typeof validation !== "object") {
                                      return validation;
                                    }
                                    if (validation.NAME !== "Matches") {
                                      return validation;
                                    }
                                    var nextMatches = Belt_List.keepU(validation.VAL, (function (match_) {
                                            return Belt_List.has(removeMatches, match_, Caml_obj.caml_notequal);
                                          }));
                                    if (Belt_List.length(nextMatches) === 0) {
                                      return ;
                                    } else {
                                      return {
                                              NAME: "Matches",
                                              VAL: nextMatches
                                            };
                                    }
                                  })) : Belt_List.keepU(property.validations, (function (item) {
                                    if (typeof item === "object") {
                                      return item.NAME !== "Matches";
                                    } else {
                                      return true;
                                    }
                                  }));
                        }
                        var newEventSpecificAllowedPropertyValues = ModelUtils.hasMigrated(model$177, "EventSpecificAllowedPropertyValues") ? ConvertPropertyValidationMatchesToEventSpecificAllowedValues.convertWithSlowModel(model$177, property, newValidations) : property.eventSpecificAllowedPropertyValues;
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: newValidations,
                                eventSpecificAllowedPropertyValues: newEventSpecificAllowedPropertyValues,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence,
                                globalRequirementsMetadata: property.globalRequirementsMetadata
                              };
                      }))
              };
      }
      var model$178 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$178.archive,
                branchPointer: model$178.branchPointer,
                migrations: model$178.migrations,
                rules: model$178.rules,
                types: model$178.types,
                categories: model$178.categories,
                destinations: model$178.destinations,
                events: model$178.events,
                groupTypes: model$178.groupTypes,
                integrations: model$178.integrations,
                metrics: model$178.metrics,
                openBranches: model$178.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.updateExisting, model$178.properties, propertyId$41, (function (property) {
                        var variant = validation.NAME;
                        var newValidations;
                        if (variant === "EndsWith" || variant === "Contains" || variant === "StartsWith") {
                          newValidations = Pervasives.failwith("Unsupported validation type.");
                        } else if (variant === "Regex") {
                          newValidations = Belt_List.keepU(property.validations, (function (item) {
                                  if (typeof item === "object") {
                                    return item.NAME !== "Regex";
                                  } else {
                                    return true;
                                  }
                                }));
                        } else if (variant === "Max") {
                          newValidations = Belt_List.keepU(property.validations, (function (item) {
                                  if (typeof item === "object") {
                                    return item.NAME !== "Max";
                                  } else {
                                    return true;
                                  }
                                }));
                        } else if (variant === "Min") {
                          newValidations = Belt_List.keepU(property.validations, (function (item) {
                                  if (typeof item === "object") {
                                    return item.NAME !== "Min";
                                  } else {
                                    return true;
                                  }
                                }));
                        } else if (variant === "NestedProperty") {
                          var removePropertyRefs = validation.VAL;
                          newValidations = Belt_List.keepMapU(property.validations, (function (validation) {
                                  if (typeof validation !== "object") {
                                    return validation;
                                  }
                                  if (validation.NAME !== "NestedProperty") {
                                    return validation;
                                  }
                                  var nextPropertyRefs = Belt_List.keepU(validation.VAL, (function (propertyRef) {
                                          return Belt_List.someU(removePropertyRefs, (function (removePropertyRef) {
                                                        return removePropertyRef.id !== propertyRef.id;
                                                      }));
                                        }));
                                  return {
                                          NAME: "NestedProperty",
                                          VAL: nextPropertyRefs
                                        };
                                }));
                        } else if (variant === "Shape") {
                          var removeItems = validation.VAL;
                          newValidations = Belt_List.keepMapU(property.validations, (function (validation) {
                                  if (typeof validation !== "object") {
                                    return validation;
                                  }
                                  if (validation.NAME !== "Shape") {
                                    return validation;
                                  }
                                  var nextItems = Belt_List.keepU(validation.VAL, (function (item) {
                                          return Belt_List.someU(removeItems, (function (removeItem) {
                                                        return removeItem.key !== item.key;
                                                      }));
                                        }));
                                  if (Belt_List.length(nextItems) === 0) {
                                    return ;
                                  } else {
                                    return {
                                            NAME: "Shape",
                                            VAL: nextItems
                                          };
                                  }
                                }));
                        } else {
                          var removeMatches = validation.VAL;
                          newValidations = removeMatches ? Belt_List.keepMapU(property.validations, (function (validation) {
                                    if (typeof validation !== "object") {
                                      return validation;
                                    }
                                    if (validation.NAME !== "Matches") {
                                      return validation;
                                    }
                                    var nextMatches = Belt_List.keepU(validation.VAL, (function (match_) {
                                            return Belt_List.has(removeMatches, match_, Caml_obj.caml_notequal);
                                          }));
                                    if (Belt_List.length(nextMatches) === 0) {
                                      return ;
                                    } else {
                                      return {
                                              NAME: "Matches",
                                              VAL: nextMatches
                                            };
                                    }
                                  })) : Belt_List.keepU(property.validations, (function (item) {
                                    if (typeof item === "object") {
                                      return item.NAME !== "Matches";
                                    } else {
                                      return true;
                                    }
                                  }));
                        }
                        var newEventSpecificAllowedPropertyValues = ModelUtils_mapped.hasMigrated(model$178, "EventSpecificAllowedPropertyValues") ? ConvertPropertyValidationMatchesToEventSpecificAllowedValues.convertWithMappedModel(model$178, property, newValidations) : property.eventSpecificAllowedPropertyValues;
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: newValidations,
                                eventSpecificAllowedPropertyValues: newEventSpecificAllowedPropertyValues,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence,
                                globalRequirementsMetadata: property.globalRequirementsMetadata
                              };
                      })),
                propertyBundles: model$178.propertyBundles,
                sources: model$178.sources,
                globalRequirements: model$178.globalRequirements
              }
            };
    }
    if (variant === "UpdateGoalName") {
      var match$113 = action.VAL;
      var name$7 = match$113[1];
      var goalId$8 = match$113[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateGoal(model._0, goalId$8, (function (goal) {
                        return {
                                id: goal.id,
                                name: name$7,
                                description: goal.description,
                                metrics: goal.metrics,
                                events: goal.events
                              };
                      }))
              };
      }
      var model$179 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$179.archive,
                branchPointer: model$179.branchPointer,
                migrations: model$179.migrations,
                rules: model$179.rules,
                types: model$179.types,
                categories: Curry._3(TrackingPlanMappedModel.Categories.updateExisting, model$179.categories, goalId$8, (function (category) {
                        return {
                                id: category.id,
                                name: name$7,
                                description: category.description,
                                metrics: category.metrics,
                                events: category.events
                              };
                      })),
                destinations: model$179.destinations,
                events: model$179.events,
                groupTypes: model$179.groupTypes,
                integrations: model$179.integrations,
                metrics: model$179.metrics,
                openBranches: model$179.openBranches,
                properties: model$179.properties,
                propertyBundles: model$179.propertyBundles,
                sources: model$179.sources,
                globalRequirements: model$179.globalRequirements
              }
            };
    }
    if (variant === "DuplicateEvent") {
      var match$114 = action.VAL;
      var ruleIdTuples = match$114[2];
      var triggerIdTuples = match$114[1];
      var match$115 = match$114[0];
      var toEventId$2 = match$115[1];
      var fromEventId$2 = match$115[0];
      if (model.TAG === /* Old */0) {
        var model$180 = model._0;
        var eventToDuplicate = ModelUtils.getEventByIdWithArchive(fromEventId$2, model$180);
        var tmp$1;
        if (eventToDuplicate !== undefined) {
          var duplicatedEvent = TrackingPlanModel.duplicateEvent(eventToDuplicate, toEventId$2, triggerIdTuples, model$180.events);
          var rulesForDuplicatedEvent = Belt_List.fromArray(Belt_Array.keepMap(ruleIdTuples, (function (param) {
                      var toRuleId = param[1];
                      var fromRuleId = param[0];
                      return Belt_Option.flatMap(Belt_List.getBy(model$180.rules, (function (param) {
                                        return param.id === fromRuleId;
                                      })), (function (rule) {
                                    var item = rule.item;
                                    var variant = item.NAME;
                                    if (variant === "PropertyRef") {
                                      var match = item.VAL;
                                      if (match[0] === fromEventId$2) {
                                        return {
                                                id: toRuleId,
                                                item: {
                                                  NAME: "PropertyRef",
                                                  VAL: [
                                                    duplicatedEvent.id,
                                                    match[1]
                                                  ]
                                                },
                                                definition: rule.definition
                                              };
                                      } else {
                                        return ;
                                      }
                                    }
                                    if (variant === "Property") {
                                      return ;
                                    }
                                    if (variant === "Event") {
                                      if (item.VAL === fromEventId$2) {
                                        return {
                                                id: toRuleId,
                                                item: {
                                                  NAME: "Event",
                                                  VAL: duplicatedEvent.id
                                                },
                                                definition: rule.definition
                                              };
                                      } else {
                                        return ;
                                      }
                                    }
                                    var match$1 = item.VAL;
                                    if (match$1[0] === fromEventId$2) {
                                      return {
                                              id: toRuleId,
                                              item: {
                                                NAME: "EventObjectField",
                                                VAL: [
                                                  duplicatedEvent.id,
                                                  match$1[1],
                                                  match$1[2]
                                                ]
                                              },
                                              definition: rule.definition
                                            };
                                    }
                                    
                                  }));
                    })));
          tmp$1 = {
            types: model$180.types,
            properties: model$180.properties,
            propertyBundles: model$180.propertyBundles,
            events: Belt_List.concat(model$180.events, {
                  hd: duplicatedEvent,
                  tl: /* [] */0
                }),
            migrations: model$180.migrations,
            sources: model$180.sources,
            destinations: model$180.destinations,
            groupTypes: model$180.groupTypes,
            goals: Belt_List.map(model$180.goals, (function (goal) {
                    if (Belt_List.has(goal.events, fromEventId$2, (function (prim0, prim1) {
                              return prim0 === prim1;
                            }))) {
                      return {
                              id: goal.id,
                              name: goal.name,
                              description: goal.description,
                              metrics: goal.metrics,
                              events: Belt_List.concat(goal.events, {
                                    hd: duplicatedEvent.id,
                                    tl: /* [] */0
                                  })
                            };
                    } else {
                      return goal;
                    }
                  })),
            metrics: model$180.metrics,
            archive: model$180.archive,
            openBranches: model$180.openBranches,
            branchPointer: model$180.branchPointer,
            rules: Belt_List.concat(model$180.rules, rulesForDuplicatedEvent),
            integrations: model$180.integrations,
            globalRequirements: model$180.globalRequirements
          };
        } else {
          tmp$1 = model$180;
        }
        return {
                TAG: /* Old */0,
                _0: tmp$1
              };
      }
      var model$181 = model._0;
      var eventToDuplicate$1 = Curry._2(TrackingPlanMappedModel.Events.get, model$181.events, fromEventId$2);
      var tmp$2;
      if (eventToDuplicate$1 !== undefined) {
        var duplicatedEvent$1 = TrackingPlanModel.duplicateEvent(eventToDuplicate$1, toEventId$2, triggerIdTuples, Belt_List.fromArray(Curry._1(TrackingPlanMappedModel.Events.toArray, model$181.events)));
        var rulesForDuplicatedEvent$1 = Belt_Array.keepMap(ruleIdTuples, (function (param) {
                var toRuleId = param[1];
                var fromRuleId = param[0];
                return Belt_Option.flatMap(Belt_Array.getBy(model$181.rules, (function (param) {
                                  return param.id === fromRuleId;
                                })), (function (rule) {
                              var item = rule.item;
                              var variant = item.NAME;
                              if (variant === "PropertyRef") {
                                var match = item.VAL;
                                if (match[0] === fromEventId$2) {
                                  return {
                                          id: toRuleId,
                                          item: {
                                            NAME: "PropertyRef",
                                            VAL: [
                                              duplicatedEvent$1.id,
                                              match[1]
                                            ]
                                          },
                                          definition: rule.definition
                                        };
                                } else {
                                  return ;
                                }
                              }
                              if (variant === "Property") {
                                return ;
                              }
                              if (variant === "Event") {
                                if (item.VAL === fromEventId$2) {
                                  return {
                                          id: toRuleId,
                                          item: {
                                            NAME: "Event",
                                            VAL: duplicatedEvent$1.id
                                          },
                                          definition: rule.definition
                                        };
                                } else {
                                  return ;
                                }
                              }
                              var match$1 = item.VAL;
                              if (match$1[0] === fromEventId$2) {
                                return {
                                        id: toRuleId,
                                        item: {
                                          NAME: "EventObjectField",
                                          VAL: [
                                            duplicatedEvent$1.id,
                                            match$1[1],
                                            match$1[2]
                                          ]
                                        },
                                        definition: rule.definition
                                      };
                              }
                              
                            }));
              }));
        tmp$2 = {
          archive: model$181.archive,
          branchPointer: model$181.branchPointer,
          migrations: model$181.migrations,
          rules: Belt_Array.concat(model$181.rules, rulesForDuplicatedEvent$1),
          types: model$181.types,
          categories: Curry._2(TrackingPlanMappedModel.Categories.mapToT, model$181.categories, (function (category) {
                  if (Belt_List.has(category.events, fromEventId$2, (function (prim0, prim1) {
                            return prim0 === prim1;
                          }))) {
                    return {
                            id: category.id,
                            name: category.name,
                            description: category.description,
                            metrics: category.metrics,
                            events: Belt_List.concat(category.events, {
                                  hd: duplicatedEvent$1.id,
                                  tl: /* [] */0
                                })
                          };
                  } else {
                    return category;
                  }
                })),
          destinations: model$181.destinations,
          events: Curry._2(TrackingPlanMappedModel.Events.append, model$181.events, duplicatedEvent$1),
          groupTypes: model$181.groupTypes,
          integrations: model$181.integrations,
          metrics: model$181.metrics,
          openBranches: model$181.openBranches,
          properties: model$181.properties,
          propertyBundles: model$181.propertyBundles,
          sources: model$181.sources,
          globalRequirements: model$181.globalRequirements
        };
      } else {
        tmp$2 = model$181;
      }
      return {
              TAG: /* Mapped */1,
              _0: tmp$2
            };
    }
    if (variant === "CreateEventVariant") {
      var match$116 = action.VAL;
      var variantIdentifier$11 = match$116[0];
      var newEventVariant = TrackingPlanModel.emptyEventVariant(variantIdentifier$11, match$116[1]);
      var update$11 = function ($$event) {
        return {
                id: $$event.id,
                name: $$event.name,
                uniqueName: $$event.uniqueName,
                description: $$event.description,
                properties: $$event.properties,
                propertyBundles: $$event.propertyBundles,
                variants: Belt_Array.concat($$event.variants, [newEventVariant]),
                types: $$event.types,
                tags: $$event.tags,
                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                includeSources: $$event.includeSources,
                includeDestinations: $$event.includeDestinations,
                hashes: $$event.hashes,
                propertyWhitelist: $$event.propertyWhitelist,
                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                triggers: $$event.triggers,
                globalRequirementsMetadata: $$event.globalRequirementsMetadata
              };
      };
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEvent(model._0, variantIdentifier$11.baseEventId, update$11)
              };
      }
      var model$182 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$182.archive,
                branchPointer: model$182.branchPointer,
                migrations: model$182.migrations,
                rules: model$182.rules,
                types: model$182.types,
                categories: model$182.categories,
                destinations: model$182.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.updateExisting, model$182.events, variantIdentifier$11.baseEventId, update$11),
                groupTypes: model$182.groupTypes,
                integrations: model$182.integrations,
                metrics: model$182.metrics,
                openBranches: model$182.openBranches,
                properties: model$182.properties,
                propertyBundles: model$182.propertyBundles,
                sources: model$182.sources,
                globalRequirements: model$182.globalRequirements
              }
            };
    }
    if (variant === "AddPropertyValidation") {
      var match$117 = action.VAL;
      var validation$1 = match$117[1];
      var propertyId$42 = match$117[0];
      if (model.TAG === /* Old */0) {
        var model$183 = model._0;
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateProperty(model$183, propertyId$42, (function (property) {
                        var variant = validation$1.NAME;
                        var validations;
                        if (variant === "EndsWith" || variant === "Contains" || variant === "StartsWith") {
                          validations = Pervasives.failwith("Unsupported validation type.");
                        } else if (variant === "Regex") {
                          var hasExistingRegex = Belt_Option.isSome(GetRegexValidationUseCase.get(property));
                          validations = hasExistingRegex ? Belt_List.mapU(property.validations, (function (item) {
                                    if (typeof item === "object" && item.NAME === "Regex") {
                                      return validation$1;
                                    } else {
                                      return item;
                                    }
                                  })) : Belt_List.concat(property.validations, {
                                  hd: validation$1,
                                  tl: /* [] */0
                                });
                        } else if (variant === "Max") {
                          var maybeMax = Belt_List.getByU(property.validations, (function (item) {
                                  if (typeof item === "object") {
                                    return item.NAME === "Max";
                                  } else {
                                    return false;
                                  }
                                }));
                          validations = maybeMax !== undefined ? Belt_List.mapU(property.validations, (function (item) {
                                    if (typeof item === "object" && item.NAME === "Max") {
                                      return validation$1;
                                    } else {
                                      return item;
                                    }
                                  })) : Belt_List.concat(property.validations, {
                                  hd: validation$1,
                                  tl: /* [] */0
                                });
                        } else if (variant === "Min") {
                          var maybeMin = Belt_List.getByU(property.validations, (function (item) {
                                  if (typeof item === "object") {
                                    return item.NAME === "Min";
                                  } else {
                                    return false;
                                  }
                                }));
                          validations = maybeMin !== undefined ? Belt_List.mapU(property.validations, (function (item) {
                                    if (typeof item === "object" && item.NAME === "Min") {
                                      return validation$1;
                                    } else {
                                      return item;
                                    }
                                  })) : Belt_List.concat(property.validations, {
                                  hd: validation$1,
                                  tl: /* [] */0
                                });
                        } else if (variant === "NestedProperty") {
                          var newPropertyRefs = validation$1.VAL;
                          var maybeShape = Belt_List.getByU(property.validations, (function (item) {
                                  if (typeof item === "object") {
                                    return item.NAME === "NestedProperty";
                                  } else {
                                    return false;
                                  }
                                }));
                          validations = maybeShape !== undefined ? Belt_List.mapU(property.validations, (function (item) {
                                    if (typeof item !== "object") {
                                      return item;
                                    }
                                    if (item.NAME !== "NestedProperty") {
                                      return item;
                                    }
                                    var currentPropertyRefs = item.VAL;
                                    var addingPropertyRefs = Belt_List.keepU(newPropertyRefs, (function (newPropertyRef) {
                                            return !Belt_List.someU(currentPropertyRefs, (function (currentPropertyRef) {
                                                          return currentPropertyRef.id === newPropertyRef.id;
                                                        }));
                                          }));
                                    var currentPropertyRefs$1 = Belt_List.mapU(currentPropertyRefs, (function (currentPropertyRef) {
                                            var updatedPropertyRef = Belt_List.getByU(newPropertyRefs, (function (newPropertyRef) {
                                                    return currentPropertyRef.id === newPropertyRef.id;
                                                  }));
                                            return Belt_Option.getWithDefault(updatedPropertyRef, currentPropertyRef);
                                          }));
                                    var nextPropertyRefs = Belt_List.concat(currentPropertyRefs$1, addingPropertyRefs);
                                    return {
                                            NAME: "NestedProperty",
                                            VAL: nextPropertyRefs
                                          };
                                  })) : Belt_List.concat(property.validations, {
                                  hd: validation$1,
                                  tl: /* [] */0
                                });
                        } else if (variant === "Shape") {
                          var newItemTypes = validation$1.VAL;
                          var maybeShape$1 = Belt_List.getByU(property.validations, (function (item) {
                                  if (typeof item === "object") {
                                    return item.NAME === "Shape";
                                  } else {
                                    return false;
                                  }
                                }));
                          validations = maybeShape$1 !== undefined ? Belt_List.mapU(property.validations, (function (item) {
                                    if (typeof item !== "object") {
                                      return item;
                                    }
                                    if (item.NAME !== "Shape") {
                                      return item;
                                    }
                                    var currentItemTypes = item.VAL;
                                    var addingItemTypes = Belt_List.keepU(newItemTypes, (function (newItemType) {
                                            return !Belt_List.someU(currentItemTypes, (function (currentItemType) {
                                                          return currentItemType.key === newItemType.key;
                                                        }));
                                          }));
                                    var currentItemTypes$1 = Belt_List.mapU(currentItemTypes, (function (currentItemType) {
                                            var updatedItemType = Belt_List.getByU(newItemTypes, (function (newItemType) {
                                                    return currentItemType.key === newItemType.key;
                                                  }));
                                            return Belt_Option.getWithDefault(updatedItemType, currentItemType);
                                          }));
                                    var nextItemTypes = Belt_List.concat(currentItemTypes$1, addingItemTypes);
                                    return {
                                            NAME: "Shape",
                                            VAL: nextItemTypes
                                          };
                                  })) : Belt_List.concat(property.validations, {
                                  hd: validation$1,
                                  tl: /* [] */0
                                });
                        } else {
                          var newMatches = validation$1.VAL;
                          var maybeMatches = Belt_List.getByU(property.validations, (function (item) {
                                  if (typeof item === "object") {
                                    return item.NAME === "Matches";
                                  } else {
                                    return false;
                                  }
                                }));
                          validations = maybeMatches !== undefined ? Belt_List.mapU(property.validations, (function (item) {
                                    if (typeof item !== "object") {
                                      return item;
                                    }
                                    if (item.NAME !== "Matches") {
                                      return item;
                                    }
                                    var currentMatches = item.VAL;
                                    var newMatches$1 = Belt_List.keepU(newMatches, (function (match_) {
                                            return !Belt_List.has(currentMatches, match_, Caml_obj.caml_equal);
                                          }));
                                    var nextMatches = Belt_List.concat(currentMatches, newMatches$1);
                                    return {
                                            NAME: "Matches",
                                            VAL: nextMatches
                                          };
                                  })) : Belt_List.concat(property.validations, {
                                  hd: validation$1,
                                  tl: /* [] */0
                                });
                        }
                        var match = ModelUtils.hasMigrated(model$183, "EventSpecificAllowedPropertyValues");
                        var newEventSpecificAllowedPropertyValues = typeof validation$1 === "object" && validation$1.NAME === "Matches" && match ? ConvertPropertyValidationMatchesToEventSpecificAllowedValues.convertWithSlowModel(model$183, property, validations) : property.eventSpecificAllowedPropertyValues;
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: validations,
                                eventSpecificAllowedPropertyValues: newEventSpecificAllowedPropertyValues,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence,
                                globalRequirementsMetadata: property.globalRequirementsMetadata
                              };
                      }))
              };
      }
      var model$184 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$184.archive,
                branchPointer: model$184.branchPointer,
                migrations: model$184.migrations,
                rules: model$184.rules,
                types: model$184.types,
                categories: model$184.categories,
                destinations: model$184.destinations,
                events: model$184.events,
                groupTypes: model$184.groupTypes,
                integrations: model$184.integrations,
                metrics: model$184.metrics,
                openBranches: model$184.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.updateExisting, model$184.properties, propertyId$42, (function (property) {
                        var variant = validation$1.NAME;
                        var validations;
                        if (variant === "EndsWith" || variant === "Contains" || variant === "StartsWith") {
                          validations = Pervasives.failwith("Unsupported validation type.");
                        } else if (variant === "Regex") {
                          var hasExistingRegex = Belt_Option.isSome(GetRegexValidationUseCase.get(property));
                          validations = hasExistingRegex ? Belt_List.mapU(property.validations, (function (item) {
                                    if (typeof item === "object" && item.NAME === "Regex") {
                                      return validation$1;
                                    } else {
                                      return item;
                                    }
                                  })) : Belt_List.concat(property.validations, {
                                  hd: validation$1,
                                  tl: /* [] */0
                                });
                        } else if (variant === "Max") {
                          var maybeMax = Belt_List.getByU(property.validations, (function (item) {
                                  if (typeof item === "object") {
                                    return item.NAME === "Max";
                                  } else {
                                    return false;
                                  }
                                }));
                          validations = maybeMax !== undefined ? Belt_List.mapU(property.validations, (function (item) {
                                    if (typeof item === "object" && item.NAME === "Max") {
                                      return validation$1;
                                    } else {
                                      return item;
                                    }
                                  })) : Belt_List.concat(property.validations, {
                                  hd: validation$1,
                                  tl: /* [] */0
                                });
                        } else if (variant === "Min") {
                          var maybeMin = Belt_List.getByU(property.validations, (function (item) {
                                  if (typeof item === "object") {
                                    return item.NAME === "Min";
                                  } else {
                                    return false;
                                  }
                                }));
                          validations = maybeMin !== undefined ? Belt_List.mapU(property.validations, (function (item) {
                                    if (typeof item === "object" && item.NAME === "Min") {
                                      return validation$1;
                                    } else {
                                      return item;
                                    }
                                  })) : Belt_List.concat(property.validations, {
                                  hd: validation$1,
                                  tl: /* [] */0
                                });
                        } else if (variant === "NestedProperty") {
                          var newPropertyRefs = validation$1.VAL;
                          var maybeShape = Belt_List.getByU(property.validations, (function (item) {
                                  if (typeof item === "object") {
                                    return item.NAME === "NestedProperty";
                                  } else {
                                    return false;
                                  }
                                }));
                          validations = maybeShape !== undefined ? Belt_List.mapU(property.validations, (function (item) {
                                    if (typeof item !== "object") {
                                      return item;
                                    }
                                    if (item.NAME !== "NestedProperty") {
                                      return item;
                                    }
                                    var currentPropertyRefs = item.VAL;
                                    var addingPropertyRefs = Belt_List.keepU(newPropertyRefs, (function (newPropertyRef) {
                                            return !Belt_List.someU(currentPropertyRefs, (function (currentPropertyRef) {
                                                          return currentPropertyRef.id === newPropertyRef.id;
                                                        }));
                                          }));
                                    var currentPropertyRefs$1 = Belt_List.mapU(currentPropertyRefs, (function (currentPropertyRef) {
                                            var updatedPropertyRef = Belt_List.getByU(newPropertyRefs, (function (newPropertyRef) {
                                                    return currentPropertyRef.id === newPropertyRef.id;
                                                  }));
                                            return Belt_Option.getWithDefault(updatedPropertyRef, currentPropertyRef);
                                          }));
                                    var nextPropertyRefs = Belt_List.concat(currentPropertyRefs$1, addingPropertyRefs);
                                    return {
                                            NAME: "NestedProperty",
                                            VAL: nextPropertyRefs
                                          };
                                  })) : Belt_List.concat(property.validations, {
                                  hd: validation$1,
                                  tl: /* [] */0
                                });
                        } else if (variant === "Shape") {
                          var newItemTypes = validation$1.VAL;
                          var maybeShape$1 = Belt_List.getByU(property.validations, (function (item) {
                                  if (typeof item === "object") {
                                    return item.NAME === "Shape";
                                  } else {
                                    return false;
                                  }
                                }));
                          validations = maybeShape$1 !== undefined ? Belt_List.mapU(property.validations, (function (item) {
                                    if (typeof item !== "object") {
                                      return item;
                                    }
                                    if (item.NAME !== "Shape") {
                                      return item;
                                    }
                                    var currentItemTypes = item.VAL;
                                    var addingItemTypes = Belt_List.keepU(newItemTypes, (function (newItemType) {
                                            return !Belt_List.someU(currentItemTypes, (function (currentItemType) {
                                                          return currentItemType.key === newItemType.key;
                                                        }));
                                          }));
                                    var currentItemTypes$1 = Belt_List.mapU(currentItemTypes, (function (currentItemType) {
                                            var updatedItemType = Belt_List.getByU(newItemTypes, (function (newItemType) {
                                                    return currentItemType.key === newItemType.key;
                                                  }));
                                            return Belt_Option.getWithDefault(updatedItemType, currentItemType);
                                          }));
                                    var nextItemTypes = Belt_List.concat(currentItemTypes$1, addingItemTypes);
                                    return {
                                            NAME: "Shape",
                                            VAL: nextItemTypes
                                          };
                                  })) : Belt_List.concat(property.validations, {
                                  hd: validation$1,
                                  tl: /* [] */0
                                });
                        } else {
                          var newMatches = validation$1.VAL;
                          var maybeMatches = Belt_List.getByU(property.validations, (function (item) {
                                  if (typeof item === "object") {
                                    return item.NAME === "Matches";
                                  } else {
                                    return false;
                                  }
                                }));
                          validations = maybeMatches !== undefined ? Belt_List.mapU(property.validations, (function (item) {
                                    if (typeof item !== "object") {
                                      return item;
                                    }
                                    if (item.NAME !== "Matches") {
                                      return item;
                                    }
                                    var currentMatches = item.VAL;
                                    var newMatches$1 = Belt_List.keepU(newMatches, (function (match_) {
                                            return !Belt_List.has(currentMatches, match_, Caml_obj.caml_equal);
                                          }));
                                    var nextMatches = Belt_List.concat(currentMatches, newMatches$1);
                                    return {
                                            NAME: "Matches",
                                            VAL: nextMatches
                                          };
                                  })) : Belt_List.concat(property.validations, {
                                  hd: validation$1,
                                  tl: /* [] */0
                                });
                        }
                        var match = ModelUtils_mapped.hasMigrated(model$184, "EventSpecificAllowedPropertyValues");
                        var newEventSpecificAllowedPropertyValues = typeof validation$1 === "object" && validation$1.NAME === "Matches" && match ? ConvertPropertyValidationMatchesToEventSpecificAllowedValues.convertWithMappedModel(model$184, property, validations) : property.eventSpecificAllowedPropertyValues;
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: validations,
                                eventSpecificAllowedPropertyValues: newEventSpecificAllowedPropertyValues,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence,
                                globalRequirementsMetadata: property.globalRequirementsMetadata
                              };
                      })),
                propertyBundles: model$184.propertyBundles,
                sources: model$184.sources,
                globalRequirements: model$184.globalRequirements
              }
            };
    }
    if (variant === "PullMaster") {
      var match$118 = action.VAL;
      var actions$2 = match$118[3];
      var masterActionTimestamp = match$118[2];
      var masterActionId = match$118[1];
      if (model.TAG === /* Old */0) {
        var nextModel$3 = TrackingPlanMappedModel.unboxModel(Belt_List.reduceU(actions$2, model, reduceFunction));
        return {
                TAG: /* Old */0,
                _0: {
                  types: nextModel$3.types,
                  properties: nextModel$3.properties,
                  propertyBundles: nextModel$3.propertyBundles,
                  events: nextModel$3.events,
                  migrations: nextModel$3.migrations,
                  sources: nextModel$3.sources,
                  destinations: nextModel$3.destinations,
                  groupTypes: nextModel$3.groupTypes,
                  goals: nextModel$3.goals,
                  metrics: nextModel$3.metrics,
                  archive: nextModel$3.archive,
                  openBranches: nextModel$3.openBranches,
                  branchPointer: [
                    masterActionId,
                    masterActionTimestamp
                  ],
                  rules: nextModel$3.rules,
                  integrations: nextModel$3.integrations,
                  globalRequirements: nextModel$3.globalRequirements
                }
              };
      }
      var nextModel$4 = TrackingPlanMappedModel.unboxModel(Belt_List.reduceU(actions$2, model, reduceFunction));
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: nextModel$4.archive,
                branchPointer: [
                  masterActionId,
                  masterActionTimestamp
                ],
                migrations: nextModel$4.migrations,
                rules: nextModel$4.rules,
                types: nextModel$4.types,
                categories: nextModel$4.categories,
                destinations: nextModel$4.destinations,
                events: nextModel$4.events,
                groupTypes: nextModel$4.groupTypes,
                integrations: nextModel$4.integrations,
                metrics: nextModel$4.metrics,
                openBranches: nextModel$4.openBranches,
                properties: nextModel$4.properties,
                propertyBundles: nextModel$4.propertyBundles,
                sources: nextModel$4.sources,
                globalRequirements: nextModel$4.globalRequirements
              }
            };
    }
    if (variant === "AddEventSpecificPropertyValueForSomeEvents") {
      var match$119 = action.VAL;
      var disallowedEvents = match$119[2];
      var literal$6 = match$119[1];
      var propertyId$43 = match$119[0];
      var addPropertyValue$1 = function (property) {
        var propertyDisallowedSourcesBefore = property.eventSpecificAllowedPropertyValues;
        var alreadyHasLiteral = Belt_Array.some(propertyDisallowedSourcesBefore, (function (param) {
                return Caml_obj.caml_equal(param[0], literal$6);
              }));
        var eventSpecificAllowedPropertyValues = alreadyHasLiteral ? Belt_Array.map(propertyDisallowedSourcesBefore, (function (param) {
                  var existingLiteral = param[0];
                  if (Caml_obj.caml_equal(existingLiteral, literal$6)) {
                    return [
                            literal$6,
                            disallowedEvents
                          ];
                  } else {
                    return [
                            existingLiteral,
                            param[1]
                          ];
                  }
                })) : Belt_Array.concat(propertyDisallowedSourcesBefore, [[
                  literal$6,
                  disallowedEvents
                ]]);
        return {
                id: property.id,
                name: property.name,
                uniqueName: property.uniqueName,
                description: property.description,
                type_: property.type_,
                sendAs: property.sendAs,
                validations: property.validations,
                eventSpecificAllowedPropertyValues: eventSpecificAllowedPropertyValues,
                auto: property.auto,
                builtIn: property.builtIn,
                analyticsTools: property.analyticsTools,
                devPlatforms: property.devPlatforms,
                list: property.list,
                operation: property.operation,
                optionalDeprecated: property.optionalDeprecated,
                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                optionalWhenInObject: property.optionalWhenInObject,
                absence: property.absence,
                globalRequirementsMetadata: property.globalRequirementsMetadata
              };
      };
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateProperty(model._0, propertyId$43, addPropertyValue$1)
              };
      }
      var model$185 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$185.archive,
                branchPointer: model$185.branchPointer,
                migrations: model$185.migrations,
                rules: model$185.rules,
                types: model$185.types,
                categories: model$185.categories,
                destinations: model$185.destinations,
                events: model$185.events,
                groupTypes: model$185.groupTypes,
                integrations: model$185.integrations,
                metrics: model$185.metrics,
                openBranches: model$185.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.updateExisting, model$185.properties, propertyId$43, addPropertyValue$1),
                propertyBundles: model$185.propertyBundles,
                sources: model$185.sources,
                globalRequirements: model$185.globalRequirements
              }
            };
    }
    if (variant === "UpdateMetricName") {
      var match$120 = action.VAL;
      var name$8 = match$120[1];
      var metricId$13 = match$120[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateMetric(model._0, metricId$13, (function (metric) {
                        return {
                                id: metric.id,
                                name: name$8,
                                description: metric.description,
                                type_: metric.type_,
                                items: metric.items
                              };
                      }))
              };
      }
      var model$186 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$186.archive,
                branchPointer: model$186.branchPointer,
                migrations: model$186.migrations,
                rules: model$186.rules,
                types: model$186.types,
                categories: model$186.categories,
                destinations: model$186.destinations,
                events: model$186.events,
                groupTypes: model$186.groupTypes,
                integrations: model$186.integrations,
                metrics: Curry._3(TrackingPlanMappedModel.Metrics.updateExisting, model$186.metrics, metricId$13, (function (metric) {
                        return {
                                id: metric.id,
                                name: name$8,
                                description: metric.description,
                                type_: metric.type_,
                                items: metric.items
                              };
                      })),
                openBranches: model$186.openBranches,
                properties: model$186.properties,
                propertyBundles: model$186.propertyBundles,
                sources: model$186.sources,
                globalRequirements: model$186.globalRequirements
              }
            };
    }
    if (variant === "RemoveNestedPropertyPinnedValue") {
      var match$121 = action.VAL;
      var nestedPropertyId$1 = match$121[1];
      var propertyId$44 = match$121[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateProperty(model._0, propertyId$44, (function (property) {
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: Belt_List.map(property.validations, (function (x) {
                                        if (typeof x === "object" && x.NAME === "NestedProperty") {
                                          return {
                                                  NAME: "NestedProperty",
                                                  VAL: Belt_List.map(x.VAL, (function (propertyRef) {
                                                          if (propertyRef.id === nestedPropertyId$1) {
                                                            return {
                                                                    id: propertyRef.id,
                                                                    description: propertyRef.description,
                                                                    pinnedValue: undefined
                                                                  };
                                                          } else {
                                                            return propertyRef;
                                                          }
                                                        }))
                                                };
                                        } else {
                                          return x;
                                        }
                                      })),
                                eventSpecificAllowedPropertyValues: property.eventSpecificAllowedPropertyValues,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence,
                                globalRequirementsMetadata: property.globalRequirementsMetadata
                              };
                      }))
              };
      }
      var model$187 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$187.archive,
                branchPointer: model$187.branchPointer,
                migrations: model$187.migrations,
                rules: model$187.rules,
                types: model$187.types,
                categories: model$187.categories,
                destinations: model$187.destinations,
                events: model$187.events,
                groupTypes: model$187.groupTypes,
                integrations: model$187.integrations,
                metrics: model$187.metrics,
                openBranches: model$187.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.updateExisting, model$187.properties, propertyId$44, (function (property) {
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: Belt_List.map(property.validations, (function (x) {
                                        if (typeof x === "object" && x.NAME === "NestedProperty") {
                                          return {
                                                  NAME: "NestedProperty",
                                                  VAL: Belt_List.map(x.VAL, (function (propertyRef) {
                                                          if (propertyRef.id === nestedPropertyId$1) {
                                                            return {
                                                                    id: propertyRef.id,
                                                                    description: propertyRef.description,
                                                                    pinnedValue: undefined
                                                                  };
                                                          } else {
                                                            return propertyRef;
                                                          }
                                                        }))
                                                };
                                        } else {
                                          return x;
                                        }
                                      })),
                                eventSpecificAllowedPropertyValues: property.eventSpecificAllowedPropertyValues,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence,
                                globalRequirementsMetadata: property.globalRequirementsMetadata
                              };
                      })),
                propertyBundles: model$187.propertyBundles,
                sources: model$187.sources,
                globalRequirements: model$187.globalRequirements
              }
            };
    }
    if (variant === "SetMetricWhere") {
      var match$122 = action.VAL;
      var literals$1 = match$122[5];
      var binOp$1 = match$122[4];
      var propertyId$45 = match$122[3];
      var id$11 = match$122[2];
      var eventId$39 = match$122[1];
      var metricId$14 = match$122[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateMetric(model._0, metricId$14, (function (metric) {
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metric.type_,
                                items: Belt_List.mapU(metric.items, (function (metricItem) {
                                        var $$event = metricItem.VAL;
                                        if (eventId$39 !== $$event.eventId) {
                                          return metricItem;
                                        }
                                        var where = $$event.where;
                                        var match = Belt_List.keepU(where, (function (where) {
                                                return where.id === id$11;
                                              }));
                                        return {
                                                NAME: "Event",
                                                VAL: {
                                                  id: $$event.id,
                                                  eventId: $$event.eventId,
                                                  where: match ? Belt_List.mapU(where, (function (where) {
                                                            if (where.id === id$11) {
                                                              return {
                                                                      id: id$11,
                                                                      propertyId: propertyId$45,
                                                                      binOp: binOp$1,
                                                                      literals: literals$1
                                                                    };
                                                            } else {
                                                              return where;
                                                            }
                                                          })) : Belt_List.concat(where, {
                                                          hd: {
                                                            id: id$11,
                                                            propertyId: propertyId$45,
                                                            binOp: binOp$1,
                                                            literals: literals$1
                                                          },
                                                          tl: /* [] */0
                                                        }),
                                                  groupBy: $$event.groupBy
                                                }
                                              };
                                      }))
                              };
                      }))
              };
      }
      var model$188 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$188.archive,
                branchPointer: model$188.branchPointer,
                migrations: model$188.migrations,
                rules: model$188.rules,
                types: model$188.types,
                categories: model$188.categories,
                destinations: model$188.destinations,
                events: model$188.events,
                groupTypes: model$188.groupTypes,
                integrations: model$188.integrations,
                metrics: Curry._3(TrackingPlanMappedModel.Metrics.updateExisting, model$188.metrics, metricId$14, (function (metric) {
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metric.type_,
                                items: Belt_List.mapU(metric.items, (function (metricItem) {
                                        var $$event = metricItem.VAL;
                                        if (eventId$39 !== $$event.eventId) {
                                          return metricItem;
                                        }
                                        var where = $$event.where;
                                        var match = Belt_List.keepU(where, (function (where) {
                                                return where.id === id$11;
                                              }));
                                        return {
                                                NAME: "Event",
                                                VAL: {
                                                  id: $$event.id,
                                                  eventId: $$event.eventId,
                                                  where: match ? Belt_List.mapU(where, (function (where) {
                                                            if (where.id === id$11) {
                                                              return {
                                                                      id: id$11,
                                                                      propertyId: propertyId$45,
                                                                      binOp: binOp$1,
                                                                      literals: literals$1
                                                                    };
                                                            } else {
                                                              return where;
                                                            }
                                                          })) : Belt_List.concat(where, {
                                                          hd: {
                                                            id: id$11,
                                                            propertyId: propertyId$45,
                                                            binOp: binOp$1,
                                                            literals: literals$1
                                                          },
                                                          tl: /* [] */0
                                                        }),
                                                  groupBy: $$event.groupBy
                                                }
                                              };
                                      }))
                              };
                      })),
                openBranches: model$188.openBranches,
                properties: model$188.properties,
                propertyBundles: model$188.propertyBundles,
                sources: model$188.sources,
                globalRequirements: model$188.globalRequirements
              }
            };
    }
    if (variant === "CreateSource") {
      var sourceId$26 = action.VAL;
      if (model.TAG === /* Old */0) {
        var model$189 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$189.types,
                  properties: model$189.properties,
                  propertyBundles: model$189.propertyBundles,
                  events: model$189.events,
                  migrations: model$189.migrations,
                  sources: Belt_List.concat(model$189.sources, {
                        hd: TrackingPlanModel.emptySource(undefined, sourceId$26, AvoConfig.defaultFilename, AvoConfig.defaultPath),
                        tl: /* [] */0
                      }),
                  destinations: model$189.destinations,
                  groupTypes: model$189.groupTypes,
                  goals: model$189.goals,
                  metrics: model$189.metrics,
                  archive: model$189.archive,
                  openBranches: model$189.openBranches,
                  branchPointer: model$189.branchPointer,
                  rules: model$189.rules,
                  integrations: model$189.integrations,
                  globalRequirements: model$189.globalRequirements
                }
              };
      }
      var model$190 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$190.archive,
                branchPointer: model$190.branchPointer,
                migrations: model$190.migrations,
                rules: model$190.rules,
                types: model$190.types,
                categories: model$190.categories,
                destinations: model$190.destinations,
                events: model$190.events,
                groupTypes: model$190.groupTypes,
                integrations: model$190.integrations,
                metrics: model$190.metrics,
                openBranches: model$190.openBranches,
                properties: model$190.properties,
                propertyBundles: model$190.propertyBundles,
                sources: Curry._2(TrackingPlanMappedModel.Sources.append, model$190.sources, TrackingPlanModel.emptySource(undefined, sourceId$26, AvoConfig.defaultFilename, AvoConfig.defaultPath)),
                globalRequirements: model$190.globalRequirements
              }
            };
    }
    if (variant === "UpdateSourceSegmentIntegration") {
      var match$123 = action.VAL;
      var isActive$3 = match$123[2];
      var segmentIntegration$1 = match$123[1];
      var sourceId$27 = match$123[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateSource(model._0, sourceId$27, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: source.language,
                                platform: source.platform,
                                destinations: source.destinations,
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: Belt_List.mapU(source.segmentDestinationOptions, (function (param) {
                                        var integration = param[0];
                                        if (integration === segmentIntegration$1) {
                                          return [
                                                  integration,
                                                  isActive$3
                                                ];
                                        } else {
                                          return [
                                                  integration,
                                                  param[1]
                                                ];
                                        }
                                      })),
                                config: source.config
                              };
                      }))
              };
      }
      var model$191 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$191.archive,
                branchPointer: model$191.branchPointer,
                migrations: model$191.migrations,
                rules: model$191.rules,
                types: model$191.types,
                categories: model$191.categories,
                destinations: model$191.destinations,
                events: model$191.events,
                groupTypes: model$191.groupTypes,
                integrations: model$191.integrations,
                metrics: model$191.metrics,
                openBranches: model$191.openBranches,
                properties: model$191.properties,
                propertyBundles: model$191.propertyBundles,
                sources: Curry._3(TrackingPlanMappedModel.Sources.updateExisting, model$191.sources, sourceId$27, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: source.language,
                                platform: source.platform,
                                destinations: source.destinations,
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: Belt_List.mapU(source.segmentDestinationOptions, (function (param) {
                                        var integration = param[0];
                                        if (integration === segmentIntegration$1) {
                                          return [
                                                  integration,
                                                  isActive$3
                                                ];
                                        } else {
                                          return [
                                                  integration,
                                                  param[1]
                                                ];
                                        }
                                      })),
                                config: source.config
                              };
                      })),
                globalRequirements: model$191.globalRequirements
              }
            };
    }
    if (variant === "RemoveEventType") {
      var match$124 = action.VAL;
      var type_$2 = match$124[1];
      var eventId$40 = match$124[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEvent(model._0, eventId$40, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: Belt_List.keepU($$event.types, (function (t) {
                                        return t !== type_$2;
                                      })),
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: type_$2 === "UpdateGroups" ? [] : $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      }))
              };
      }
      var model$192 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$192.archive,
                branchPointer: model$192.branchPointer,
                migrations: model$192.migrations,
                rules: model$192.rules,
                types: model$192.types,
                categories: model$192.categories,
                destinations: model$192.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.updateExisting, model$192.events, eventId$40, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: Belt_List.keepU($$event.types, (function (t) {
                                        return t !== type_$2;
                                      })),
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: type_$2 === "UpdateGroups" ? [] : $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      })),
                groupTypes: model$192.groupTypes,
                integrations: model$192.integrations,
                metrics: model$192.metrics,
                openBranches: model$192.openBranches,
                properties: model$192.properties,
                propertyBundles: model$192.propertyBundles,
                sources: model$192.sources,
                globalRequirements: model$192.globalRequirements
              }
            };
    }
    if (variant === "RemoveEventFromGoal") {
      var match$125 = action.VAL;
      var eventId$41 = match$125[1];
      var goalId$9 = match$125[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateGoal(model._0, goalId$9, (function (goal) {
                        return {
                                id: goal.id,
                                name: goal.name,
                                description: goal.description,
                                metrics: goal.metrics,
                                events: Belt_List.keepU(goal.events, (function (id) {
                                        return id !== eventId$41;
                                      }))
                              };
                      }))
              };
      }
      var model$193 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$193.archive,
                branchPointer: model$193.branchPointer,
                migrations: model$193.migrations,
                rules: model$193.rules,
                types: model$193.types,
                categories: Curry._3(TrackingPlanMappedModel.Categories.updateExisting, model$193.categories, goalId$9, (function (category) {
                        return {
                                id: category.id,
                                name: category.name,
                                description: category.description,
                                metrics: category.metrics,
                                events: Belt_List.keepU(category.events, (function (id) {
                                        return id !== eventId$41;
                                      }))
                              };
                      })),
                destinations: model$193.destinations,
                events: model$193.events,
                groupTypes: model$193.groupTypes,
                integrations: model$193.integrations,
                metrics: model$193.metrics,
                openBranches: model$193.openBranches,
                properties: model$193.properties,
                propertyBundles: model$193.propertyBundles,
                sources: model$193.sources,
                globalRequirements: model$193.globalRequirements
              }
            };
    }
    if (variant === "ExcludeDestinationFromSource") {
      var match$126 = action.VAL;
      var destinationId$15 = match$126[1];
      var sourceId$28 = match$126[0];
      if (model.TAG === /* Old */0) {
        var model$194 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$194.types,
                  properties: model$194.properties,
                  propertyBundles: model$194.propertyBundles,
                  events: Belt_List.mapU(model$194.events, (function ($$event) {
                          return {
                                  id: $$event.id,
                                  name: $$event.name,
                                  uniqueName: $$event.uniqueName,
                                  description: $$event.description,
                                  properties: $$event.properties,
                                  propertyBundles: $$event.propertyBundles,
                                  variants: $$event.variants,
                                  types: $$event.types,
                                  tags: $$event.tags,
                                  excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                  includeSources: $$event.includeSources,
                                  includeDestinations: Belt_List.mapU($$event.includeDestinations, (function (includeDestination) {
                                          var match = includeDestination.VAL;
                                          var id = match[0];
                                          if (id === sourceId$28) {
                                            return {
                                                    NAME: "IncludeSourceDestination",
                                                    VAL: [
                                                      id,
                                                      Belt_List.keepU(match[1], (function (id) {
                                                              return id !== destinationId$15;
                                                            }))
                                                    ]
                                                  };
                                          } else {
                                            return includeDestination;
                                          }
                                        })),
                                  hashes: $$event.hashes,
                                  propertyWhitelist: $$event.propertyWhitelist,
                                  eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                  userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                  triggers: $$event.triggers,
                                  globalRequirementsMetadata: $$event.globalRequirementsMetadata
                                };
                        })),
                  migrations: model$194.migrations,
                  sources: Belt_List.mapU(model$194.sources, (function (source) {
                          if (source.id === sourceId$28) {
                            return {
                                    id: source.id,
                                    name: source.name,
                                    language: source.language,
                                    platform: source.platform,
                                    destinations: Belt_List.keepU(source.destinations, (function (sourceDestination) {
                                            return sourceDestination.destinationId !== destinationId$15;
                                          })),
                                    filename: source.filename,
                                    path: source.path,
                                    segmentDestinationOptions: source.segmentDestinationOptions,
                                    config: source.config
                                  };
                          } else {
                            return source;
                          }
                        })),
                  destinations: model$194.destinations,
                  groupTypes: model$194.groupTypes,
                  goals: model$194.goals,
                  metrics: model$194.metrics,
                  archive: model$194.archive,
                  openBranches: model$194.openBranches,
                  branchPointer: model$194.branchPointer,
                  rules: model$194.rules,
                  integrations: model$194.integrations,
                  globalRequirements: model$194.globalRequirements
                }
              };
      }
      var model$195 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$195.archive,
                branchPointer: model$195.branchPointer,
                migrations: model$195.migrations,
                rules: model$195.rules,
                types: model$195.types,
                categories: model$195.categories,
                destinations: model$195.destinations,
                events: Curry._2(TrackingPlanMappedModel.Events.mapToT, model$195.events, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: Belt_List.mapU($$event.includeDestinations, (function (includeDestination) {
                                        var match = includeDestination.VAL;
                                        var id = match[0];
                                        if (id === sourceId$28) {
                                          return {
                                                  NAME: "IncludeSourceDestination",
                                                  VAL: [
                                                    id,
                                                    Belt_List.keepU(match[1], (function (id) {
                                                            return id !== destinationId$15;
                                                          }))
                                                  ]
                                                };
                                        } else {
                                          return includeDestination;
                                        }
                                      })),
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      })),
                groupTypes: model$195.groupTypes,
                integrations: model$195.integrations,
                metrics: model$195.metrics,
                openBranches: model$195.openBranches,
                properties: model$195.properties,
                propertyBundles: model$195.propertyBundles,
                sources: Curry._3(TrackingPlanMappedModel.Sources.updateExisting, model$195.sources, sourceId$28, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: source.language,
                                platform: source.platform,
                                destinations: Belt_List.keepU(source.destinations, (function (sourceDestination) {
                                        return sourceDestination.destinationId !== destinationId$15;
                                      })),
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: source.config
                              };
                      })),
                globalRequirements: model$195.globalRequirements
              }
            };
    }
    if (variant === "ReorderGoalsV2") {
      var match$127 = action.VAL;
      var maybePredecessorId$6 = match$127[1];
      var goalId$10 = match$127[0];
      var doesPredecessorExist$3 = function (listWithoutItem, predecessorId) {
        return Belt_List.someU(listWithoutItem, (function (goal) {
                      return goal.id === predecessorId;
                    }));
      };
      if (model.TAG === /* Old */0) {
        var model$196 = model._0;
        var movedItem = Belt_List.getByU(model$196.goals, (function (goal) {
                return goal.id === goalId$10;
              }));
        var listWithoutItem = Belt_List.keepU(model$196.goals, (function (goal) {
                return goal.id !== goalId$10;
              }));
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$196.types,
                  properties: model$196.properties,
                  propertyBundles: model$196.propertyBundles,
                  events: model$196.events,
                  migrations: model$196.migrations,
                  sources: model$196.sources,
                  destinations: model$196.destinations,
                  groupTypes: model$196.groupTypes,
                  goals: movedItem !== undefined ? (
                      maybePredecessorId$6 !== undefined ? (
                          doesPredecessorExist$3(listWithoutItem, maybePredecessorId$6) ? BeltListExtensions.flatMap(listWithoutItem, (function (goal) {
                                    if (goal.id === maybePredecessorId$6) {
                                      return {
                                              hd: goal,
                                              tl: {
                                                hd: movedItem,
                                                tl: /* [] */0
                                              }
                                            };
                                    } else {
                                      return {
                                              hd: goal,
                                              tl: /* [] */0
                                            };
                                    }
                                  })) : model$196.goals
                        ) : Belt_List.concat({
                              hd: movedItem,
                              tl: /* [] */0
                            }, listWithoutItem)
                    ) : model$196.goals,
                  metrics: model$196.metrics,
                  archive: model$196.archive,
                  openBranches: model$196.openBranches,
                  branchPointer: model$196.branchPointer,
                  rules: model$196.rules,
                  integrations: model$196.integrations,
                  globalRequirements: model$196.globalRequirements
                }
              };
      }
      var model$197 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$197.archive,
                branchPointer: model$197.branchPointer,
                migrations: model$197.migrations,
                rules: model$197.rules,
                types: model$197.types,
                categories: Curry._3(TrackingPlanMappedModel.Categories.reorderV2, model$197.categories, goalId$10, maybePredecessorId$6),
                destinations: model$197.destinations,
                events: model$197.events,
                groupTypes: model$197.groupTypes,
                integrations: model$197.integrations,
                metrics: model$197.metrics,
                openBranches: model$197.openBranches,
                properties: model$197.properties,
                propertyBundles: model$197.propertyBundles,
                sources: model$197.sources,
                globalRequirements: model$197.globalRequirements
              }
            };
    }
    if (variant === "UpdateMetricType") {
      var match$128 = action.VAL;
      var metricType = match$128[1];
      var metricId$15 = match$128[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateMetric(model._0, metricId$15, (function (metric) {
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metricType,
                                items: metric.items
                              };
                      }))
              };
      }
      var model$198 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$198.archive,
                branchPointer: model$198.branchPointer,
                migrations: model$198.migrations,
                rules: model$198.rules,
                types: model$198.types,
                categories: model$198.categories,
                destinations: model$198.destinations,
                events: model$198.events,
                groupTypes: model$198.groupTypes,
                integrations: model$198.integrations,
                metrics: Curry._3(TrackingPlanMappedModel.Metrics.updateExisting, model$198.metrics, metricId$15, (function (metric) {
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metricType,
                                items: metric.items
                              };
                      })),
                openBranches: model$198.openBranches,
                properties: model$198.properties,
                propertyBundles: model$198.propertyBundles,
                sources: model$198.sources,
                globalRequirements: model$198.globalRequirements
              }
            };
    }
    if (variant === "ClearPropertyExcludedSources") {
      var propertyId$46 = action.VAL;
      if (model.TAG === /* Old */0) {
        var model$199 = model._0;
        var tmp$3;
        if (ModelUtils.hasMigrated(model$199, "PropertyOptionalAndExcludedSourcesToAbsence")) {
          var propertyOptional = Belt_Option.map(ModelUtils.resolvePropertyById(propertyId$46, model$199), (function (prop) {
                  return prop.optionalDeprecated;
                }));
          if (propertyOptional !== undefined) {
            var relevantEventSourceIds = Belt_List.flatten(Belt_List.mapU(ModelUtils.eventsSendingProperty(model$199, undefined, propertyId$46), (function ($$event) {
                        return Belt_List.mapU($$event.includeSources, (function (includedSource) {
                                      return [
                                              $$event.id,
                                              includedSource.id
                                            ];
                                    }));
                      })));
            tmp$3 = ModelUtils.updateProperty(model$199, propertyId$46, (function (property) {
                    return Belt_List.reduceU(relevantEventSourceIds, property, (function (property, param) {
                                  return updatePropertyAbsence({
                                              TAG: /* Old */0,
                                              _0: model$199
                                            }, property, {
                                              TAG: /* EventSource */2,
                                              _0: param[0],
                                              _1: param[1]
                                            }, propertyOptional ? /* SometimesSent */({
                                                  _0: ""
                                                }) : undefined);
                                }));
                  }));
          } else {
            tmp$3 = model$199;
          }
        } else {
          tmp$3 = ModelUtils.updateProperty(model$199, propertyId$46, (function (property) {
                  return {
                          id: property.id,
                          name: property.name,
                          uniqueName: property.uniqueName,
                          description: property.description,
                          type_: property.type_,
                          sendAs: property.sendAs,
                          validations: property.validations,
                          eventSpecificAllowedPropertyValues: property.eventSpecificAllowedPropertyValues,
                          auto: property.auto,
                          builtIn: property.builtIn,
                          analyticsTools: property.analyticsTools,
                          devPlatforms: property.devPlatforms,
                          list: property.list,
                          operation: property.operation,
                          optionalDeprecated: property.optionalDeprecated,
                          excludedSourcesDeprecated: /* [] */0,
                          optionalWhenInObject: property.optionalWhenInObject,
                          absence: property.absence,
                          globalRequirementsMetadata: property.globalRequirementsMetadata
                        };
                }));
        }
        return {
                TAG: /* Old */0,
                _0: tmp$3
              };
      }
      var model$200 = model._0;
      if (!ModelUtils_mapped.hasMigrated(model$200, "PropertyOptionalAndExcludedSourcesToAbsence")) {
        return {
                TAG: /* Mapped */1,
                _0: {
                  archive: model$200.archive,
                  branchPointer: model$200.branchPointer,
                  migrations: model$200.migrations,
                  rules: model$200.rules,
                  types: model$200.types,
                  categories: model$200.categories,
                  destinations: model$200.destinations,
                  events: model$200.events,
                  groupTypes: model$200.groupTypes,
                  integrations: model$200.integrations,
                  metrics: model$200.metrics,
                  openBranches: model$200.openBranches,
                  properties: Curry._3(TrackingPlanMappedModel.Properties.updateExisting, model$200.properties, propertyId$46, (function (property) {
                          return {
                                  id: property.id,
                                  name: property.name,
                                  uniqueName: property.uniqueName,
                                  description: property.description,
                                  type_: property.type_,
                                  sendAs: property.sendAs,
                                  validations: property.validations,
                                  eventSpecificAllowedPropertyValues: property.eventSpecificAllowedPropertyValues,
                                  auto: property.auto,
                                  builtIn: property.builtIn,
                                  analyticsTools: property.analyticsTools,
                                  devPlatforms: property.devPlatforms,
                                  list: property.list,
                                  operation: property.operation,
                                  optionalDeprecated: property.optionalDeprecated,
                                  excludedSourcesDeprecated: /* [] */0,
                                  optionalWhenInObject: property.optionalWhenInObject,
                                  absence: property.absence,
                                  globalRequirementsMetadata: property.globalRequirementsMetadata
                                };
                        })),
                  propertyBundles: model$200.propertyBundles,
                  sources: model$200.sources,
                  globalRequirements: model$200.globalRequirements
                }
              };
      }
      var propertyOptional$1 = Belt_Option.map(Curry._2(TrackingPlanMappedModel.Properties.get, model$200.properties, propertyId$46), (function (prop) {
              return prop.optionalDeprecated;
            }));
      if (propertyOptional$1 === undefined) {
        return model;
      }
      var relevantEventSourceIds$1 = Belt_Array.concatMany(Curry._2(TrackingPlanMappedModel.Events.mapToArray, ModelUtils_mapped.eventsSendingProperty(model$200.events, model$200.propertyBundles, undefined, propertyId$46), (function ($$event) {
                  return Belt_List.toArray(Belt_List.mapU($$event.includeSources, (function (includedSource) {
                                    return [
                                            $$event.id,
                                            includedSource.id
                                          ];
                                  })));
                })));
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$200.archive,
                branchPointer: model$200.branchPointer,
                migrations: model$200.migrations,
                rules: model$200.rules,
                types: model$200.types,
                categories: model$200.categories,
                destinations: model$200.destinations,
                events: model$200.events,
                groupTypes: model$200.groupTypes,
                integrations: model$200.integrations,
                metrics: model$200.metrics,
                openBranches: model$200.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.updateExisting, model$200.properties, propertyId$46, (function (property) {
                        return Belt_Array.reduceU(relevantEventSourceIds$1, property, (function (property, param) {
                                      return updatePropertyAbsence({
                                                  TAG: /* Mapped */1,
                                                  _0: model$200
                                                }, property, {
                                                  TAG: /* EventSource */2,
                                                  _0: param[0],
                                                  _1: param[1]
                                                }, propertyOptional$1 ? /* SometimesSent */({
                                                      _0: ""
                                                    }) : undefined);
                                    }));
                      })),
                propertyBundles: model$200.propertyBundles,
                sources: model$200.sources,
                globalRequirements: model$200.globalRequirements
              }
            };
    }
    if (variant === "UpdateIntegrationName") {
      var match$129 = action.VAL;
      var name$9 = match$129[1];
      var integrationId$4 = match$129[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateIntegration(model._0, integrationId$4, (function (integration) {
                        return {
                                id: integration.id,
                                name: name$9,
                                config: integration.config,
                                filters: integration.filters,
                                autoPublish: integration.autoPublish
                              };
                      }))
              };
      }
      var model$201 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$201.archive,
                branchPointer: model$201.branchPointer,
                migrations: model$201.migrations,
                rules: model$201.rules,
                types: model$201.types,
                categories: model$201.categories,
                destinations: model$201.destinations,
                events: model$201.events,
                groupTypes: model$201.groupTypes,
                integrations: Curry._3(TrackingPlanMappedModel.Integrations.updateExisting, model$201.integrations, integrationId$4, (function (integration) {
                        return {
                                id: integration.id,
                                name: name$9,
                                config: integration.config,
                                filters: integration.filters,
                                autoPublish: integration.autoPublish
                              };
                      })),
                metrics: model$201.metrics,
                openBranches: model$201.openBranches,
                properties: model$201.properties,
                propertyBundles: model$201.propertyBundles,
                sources: model$201.sources,
                globalRequirements: model$201.globalRequirements
              }
            };
    }
    if (variant === "CreatePropertyGroup") {
      var match$130 = action.VAL;
      var name$10 = match$130[1];
      var groupId$2 = match$130[0];
      if (model.TAG === /* Old */0) {
        var model$202 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$202.types,
                  properties: model$202.properties,
                  propertyBundles: Belt_List.concat(model$202.propertyBundles, {
                        hd: {
                          id: groupId$2,
                          name: name$10,
                          description: "",
                          properties: /* [] */0,
                          globalRequirementsMetadata: undefined
                        },
                        tl: /* [] */0
                      }),
                  events: model$202.events,
                  migrations: model$202.migrations,
                  sources: model$202.sources,
                  destinations: model$202.destinations,
                  groupTypes: model$202.groupTypes,
                  goals: model$202.goals,
                  metrics: model$202.metrics,
                  archive: model$202.archive,
                  openBranches: model$202.openBranches,
                  branchPointer: model$202.branchPointer,
                  rules: model$202.rules,
                  integrations: model$202.integrations,
                  globalRequirements: model$202.globalRequirements
                }
              };
      }
      var model$203 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$203.archive,
                branchPointer: model$203.branchPointer,
                migrations: model$203.migrations,
                rules: model$203.rules,
                types: model$203.types,
                categories: model$203.categories,
                destinations: model$203.destinations,
                events: model$203.events,
                groupTypes: model$203.groupTypes,
                integrations: model$203.integrations,
                metrics: model$203.metrics,
                openBranches: model$203.openBranches,
                properties: model$203.properties,
                propertyBundles: Curry._2(TrackingPlanMappedModel.PropertyBundles.append, model$203.propertyBundles, {
                      id: groupId$2,
                      name: name$10,
                      description: "",
                      properties: /* [] */0,
                      globalRequirementsMetadata: undefined
                    }),
                sources: model$203.sources,
                globalRequirements: model$203.globalRequirements
              }
            };
    }
    if (variant === "UpdatePropertyGroupName") {
      var match$131 = action.VAL;
      var name$11 = match$131[1];
      var groupId$3 = match$131[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updatePropertyGroup(model._0, groupId$3, (function (group) {
                        return {
                                id: group.id,
                                name: name$11,
                                description: group.description,
                                properties: group.properties,
                                globalRequirementsMetadata: group.globalRequirementsMetadata
                              };
                      }))
              };
      }
      var model$204 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$204.archive,
                branchPointer: model$204.branchPointer,
                migrations: model$204.migrations,
                rules: model$204.rules,
                types: model$204.types,
                categories: model$204.categories,
                destinations: model$204.destinations,
                events: model$204.events,
                groupTypes: model$204.groupTypes,
                integrations: model$204.integrations,
                metrics: model$204.metrics,
                openBranches: model$204.openBranches,
                properties: model$204.properties,
                propertyBundles: Curry._3(TrackingPlanMappedModel.PropertyBundles.updateExisting, model$204.propertyBundles, groupId$3, (function (propertyBundle) {
                        return {
                                id: propertyBundle.id,
                                name: name$11,
                                description: propertyBundle.description,
                                properties: propertyBundle.properties,
                                globalRequirementsMetadata: propertyBundle.globalRequirementsMetadata
                              };
                      })),
                sources: model$204.sources,
                globalRequirements: model$204.globalRequirements
              }
            };
    }
    if (variant === "AddSourceConfig") {
      var match$132 = action.VAL;
      var sourceConfig = match$132[1];
      var sourceId$29 = match$132[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateSource(model._0, sourceId$29, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: source.language,
                                platform: source.platform,
                                destinations: source.destinations,
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: Belt_List.some(source.config, (function (config) {
                                        return config === sourceConfig;
                                      })) ? source.config : ({
                                      hd: sourceConfig,
                                      tl: source.config
                                    })
                              };
                      }))
              };
      }
      var model$205 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$205.archive,
                branchPointer: model$205.branchPointer,
                migrations: model$205.migrations,
                rules: model$205.rules,
                types: model$205.types,
                categories: model$205.categories,
                destinations: model$205.destinations,
                events: model$205.events,
                groupTypes: model$205.groupTypes,
                integrations: model$205.integrations,
                metrics: model$205.metrics,
                openBranches: model$205.openBranches,
                properties: model$205.properties,
                propertyBundles: model$205.propertyBundles,
                sources: Curry._3(TrackingPlanMappedModel.Sources.updateExisting, model$205.sources, sourceId$29, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: source.language,
                                platform: source.platform,
                                destinations: source.destinations,
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: Belt_List.some(source.config, (function (config) {
                                        return config === sourceConfig;
                                      })) ? source.config : ({
                                      hd: sourceConfig,
                                      tl: source.config
                                    })
                              };
                      })),
                globalRequirements: model$205.globalRequirements
              }
            };
    }
    if (variant === "UpdateEventUniqueName") {
      var match$133 = action.VAL;
      var name$12 = match$133[1];
      var eventId$42 = match$133[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEvent(model._0, eventId$42, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: name$12,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      }))
              };
      }
      var model$206 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$206.archive,
                branchPointer: model$206.branchPointer,
                migrations: model$206.migrations,
                rules: model$206.rules,
                types: model$206.types,
                categories: model$206.categories,
                destinations: model$206.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.updateExisting, model$206.events, eventId$42, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: name$12,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      })),
                groupTypes: model$206.groupTypes,
                integrations: model$206.integrations,
                metrics: model$206.metrics,
                openBranches: model$206.openBranches,
                properties: model$206.properties,
                propertyBundles: model$206.propertyBundles,
                sources: model$206.sources,
                globalRequirements: model$206.globalRequirements
              }
            };
    }
    if (variant === "DeprecatedRemovePropertyValidationMinOrMax") {
      var match$134 = action.VAL;
      var validationIndex$1 = match$134[1];
      var propertyId$47 = match$134[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updatePropertyValidations(model._0, propertyId$47, (function (validations, param) {
                        var match = Belt_List.splitAt(validations, validationIndex$1);
                        if (match !== undefined) {
                          return Belt_List.concat(match[0], Belt_Option.getWithDefault(Belt_List.tail(match[1]), /* [] */0));
                        } else {
                          return validations;
                        }
                      }))
              };
      }
      var model$207 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$207.archive,
                branchPointer: model$207.branchPointer,
                migrations: model$207.migrations,
                rules: model$207.rules,
                types: model$207.types,
                categories: model$207.categories,
                destinations: model$207.destinations,
                events: model$207.events,
                groupTypes: model$207.groupTypes,
                integrations: model$207.integrations,
                metrics: model$207.metrics,
                openBranches: model$207.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.updateExisting, model$207.properties, propertyId$47, (function (property) {
                        var match = Belt_List.splitAt(property.validations, validationIndex$1);
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: match !== undefined ? Belt_List.concat(match[0], Belt_Option.getWithDefault(Belt_List.tail(match[1]), /* [] */0)) : property.validations,
                                eventSpecificAllowedPropertyValues: property.eventSpecificAllowedPropertyValues,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence,
                                globalRequirementsMetadata: property.globalRequirementsMetadata
                              };
                      })),
                propertyBundles: model$207.propertyBundles,
                sources: model$207.sources,
                globalRequirements: model$207.globalRequirements
              }
            };
    }
    if (variant === "RemovePropertyFromEventVariant") {
      var match$135 = action.VAL;
      var propertyId$48 = match$135[1];
      var id$12 = match$135[0];
      var update$12 = function (variant) {
        return {
                id: variant.id,
                baseEventId: variant.baseEventId,
                nameSuffix: variant.nameSuffix,
                description: variant.description,
                propertyOverrides: Belt_MapString.set(variant.propertyOverrides, propertyId$48, /* Removed */0),
                sourceOverrides: variant.sourceOverrides,
                triggers: variant.triggers
              };
      };
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEventVariant(model._0, id$12, update$12)
              };
      }
      var model$208 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$208.archive,
                branchPointer: model$208.branchPointer,
                migrations: model$208.migrations,
                rules: model$208.rules,
                types: model$208.types,
                categories: model$208.categories,
                destinations: model$208.destinations,
                events: ModelUtils_mapped.updateExistingVariant(model$208.events, id$12, update$12),
                groupTypes: model$208.groupTypes,
                integrations: model$208.integrations,
                metrics: model$208.metrics,
                openBranches: model$208.openBranches,
                properties: model$208.properties,
                propertyBundles: model$208.propertyBundles,
                sources: model$208.sources,
                globalRequirements: model$208.globalRequirements
              }
            };
    }
    if (variant === "UpdateIntegrationType") {
      var match$136 = action.VAL;
      var integrationType = match$136[1];
      var integrationId$5 = match$136[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateIntegration(model._0, integrationId$5, (function (integration) {
                        return {
                                id: integration.id,
                                name: integration.name,
                                config: integrationType === "Protocols" ? ({
                                      TAG: /* Protocols */0,
                                      _0: {
                                        accessKey: undefined,
                                        trackingPlanId: undefined,
                                        workspace: undefined,
                                        usePublicApi: false,
                                        euDataResidency: false
                                      }
                                    }) : (
                                    integrationType === "SnowplowDataStructures" ? ({
                                          TAG: /* SnowplowDataStructures */4,
                                          _0: {
                                            accessToken: undefined,
                                            organizationId: undefined,
                                            vendor: undefined
                                          }
                                        }) : (
                                        integrationType === "Lexicon" ? ({
                                              TAG: /* Lexicon */2,
                                              _0: {
                                                userName: undefined,
                                                secretKey: undefined,
                                                projectId: undefined,
                                                euDataResidency: false,
                                                categoriesAsTags: false
                                              }
                                            }) : (
                                            integrationType === "MParticleDataMaster" ? ({
                                                  TAG: /* MParticleDataMaster */5,
                                                  _0: {
                                                    clientId: undefined,
                                                    clientSecret: undefined,
                                                    workspaceId: undefined,
                                                    planId: undefined
                                                  }
                                                }) : (
                                                integrationType === "Taxonomy" ? ({
                                                      TAG: /* Taxonomy */1,
                                                      _0: {
                                                        apiKey: undefined,
                                                        secretKey: undefined,
                                                        euDataResidency: false
                                                      }
                                                    }) : (
                                                    integrationType === "Webhook" ? ({
                                                          TAG: /* Webhook */3,
                                                          _0: {
                                                            webhookUrl: undefined,
                                                            payloadFormat: /* JsonSchema */0
                                                          }
                                                        }) : ({
                                                          TAG: /* RudderstackTrackingPlans */6,
                                                          _0: {
                                                            accessToken: undefined,
                                                            trackingPlanDisplayName: undefined,
                                                            emailAddress: undefined
                                                          }
                                                        })
                                                  )
                                              )
                                          )
                                      )
                                  ),
                                filters: integration.filters,
                                autoPublish: integration.autoPublish
                              };
                      }))
              };
      }
      var model$209 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$209.archive,
                branchPointer: model$209.branchPointer,
                migrations: model$209.migrations,
                rules: model$209.rules,
                types: model$209.types,
                categories: model$209.categories,
                destinations: model$209.destinations,
                events: model$209.events,
                groupTypes: model$209.groupTypes,
                integrations: Curry._3(TrackingPlanMappedModel.Integrations.updateExisting, model$209.integrations, integrationId$5, (function (integration) {
                        return {
                                id: integration.id,
                                name: integration.name,
                                config: integrationType === "Protocols" ? ({
                                      TAG: /* Protocols */0,
                                      _0: {
                                        accessKey: undefined,
                                        trackingPlanId: undefined,
                                        workspace: undefined,
                                        usePublicApi: false,
                                        euDataResidency: false
                                      }
                                    }) : (
                                    integrationType === "SnowplowDataStructures" ? ({
                                          TAG: /* SnowplowDataStructures */4,
                                          _0: {
                                            accessToken: undefined,
                                            organizationId: undefined,
                                            vendor: undefined
                                          }
                                        }) : (
                                        integrationType === "Lexicon" ? ({
                                              TAG: /* Lexicon */2,
                                              _0: {
                                                userName: undefined,
                                                secretKey: undefined,
                                                projectId: undefined,
                                                euDataResidency: false,
                                                categoriesAsTags: false
                                              }
                                            }) : (
                                            integrationType === "MParticleDataMaster" ? ({
                                                  TAG: /* MParticleDataMaster */5,
                                                  _0: {
                                                    clientId: undefined,
                                                    clientSecret: undefined,
                                                    workspaceId: undefined,
                                                    planId: undefined
                                                  }
                                                }) : (
                                                integrationType === "Taxonomy" ? ({
                                                      TAG: /* Taxonomy */1,
                                                      _0: {
                                                        apiKey: undefined,
                                                        secretKey: undefined,
                                                        euDataResidency: false
                                                      }
                                                    }) : (
                                                    integrationType === "Webhook" ? ({
                                                          TAG: /* Webhook */3,
                                                          _0: {
                                                            webhookUrl: undefined,
                                                            payloadFormat: /* JsonSchema */0
                                                          }
                                                        }) : ({
                                                          TAG: /* RudderstackTrackingPlans */6,
                                                          _0: {
                                                            accessToken: undefined,
                                                            trackingPlanDisplayName: undefined,
                                                            emailAddress: undefined
                                                          }
                                                        })
                                                  )
                                              )
                                          )
                                      )
                                  ),
                                filters: integration.filters,
                                autoPublish: integration.autoPublish
                              };
                      })),
                metrics: model$209.metrics,
                openBranches: model$209.openBranches,
                properties: model$209.properties,
                propertyBundles: model$209.propertyBundles,
                sources: model$209.sources,
                globalRequirements: model$209.globalRequirements
              }
            };
    }
    if (variant === "RemoveMetricWhere") {
      var match$137 = action.VAL;
      var id$13 = match$137[2];
      var eventId$43 = match$137[1];
      var metricId$16 = match$137[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateMetric(model._0, metricId$16, (function (metric) {
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metric.type_,
                                items: Belt_List.mapU(metric.items, (function (metricItem) {
                                        var $$event = metricItem.VAL;
                                        if (eventId$43 === $$event.eventId) {
                                          return {
                                                  NAME: "Event",
                                                  VAL: {
                                                    id: $$event.id,
                                                    eventId: $$event.eventId,
                                                    where: Belt_List.keepU($$event.where, (function (where) {
                                                            return where.id !== id$13;
                                                          })),
                                                    groupBy: $$event.groupBy
                                                  }
                                                };
                                        } else {
                                          return metricItem;
                                        }
                                      }))
                              };
                      }))
              };
      }
      var model$210 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$210.archive,
                branchPointer: model$210.branchPointer,
                migrations: model$210.migrations,
                rules: model$210.rules,
                types: model$210.types,
                categories: model$210.categories,
                destinations: model$210.destinations,
                events: model$210.events,
                groupTypes: model$210.groupTypes,
                integrations: model$210.integrations,
                metrics: Curry._3(TrackingPlanMappedModel.Metrics.updateExisting, model$210.metrics, metricId$16, (function (metric) {
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metric.type_,
                                items: Belt_List.mapU(metric.items, (function (metricItem) {
                                        var $$event = metricItem.VAL;
                                        if (eventId$43 === $$event.eventId) {
                                          return {
                                                  NAME: "Event",
                                                  VAL: {
                                                    id: $$event.id,
                                                    eventId: $$event.eventId,
                                                    where: Belt_List.keepU($$event.where, (function (where) {
                                                            return where.id !== id$13;
                                                          })),
                                                    groupBy: $$event.groupBy
                                                  }
                                                };
                                        } else {
                                          return metricItem;
                                        }
                                      }))
                              };
                      })),
                openBranches: model$210.openBranches,
                properties: model$210.properties,
                propertyBundles: model$210.propertyBundles,
                sources: model$210.sources,
                globalRequirements: model$210.globalRequirements
              }
            };
    }
    if (variant === "UpdateEventName") {
      var match$138 = action.VAL;
      var name$13 = match$138[1];
      var eventId$44 = match$138[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEvent(model._0, eventId$44, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: name$13,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      }))
              };
      }
      var model$211 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$211.archive,
                branchPointer: model$211.branchPointer,
                migrations: model$211.migrations,
                rules: model$211.rules,
                types: model$211.types,
                categories: model$211.categories,
                destinations: model$211.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.updateExisting, model$211.events, eventId$44, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: name$13,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      })),
                groupTypes: model$211.groupTypes,
                integrations: model$211.integrations,
                metrics: model$211.metrics,
                openBranches: model$211.openBranches,
                properties: model$211.properties,
                propertyBundles: model$211.propertyBundles,
                sources: model$211.sources,
                globalRequirements: model$211.globalRequirements
              }
            };
    }
    if (variant === "UpdateIntegrationFilters") {
      var match$139 = action.VAL;
      var filters = match$139[1];
      var integrationId$6 = match$139[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateIntegration(model._0, integrationId$6, (function (integration) {
                        return {
                                id: integration.id,
                                name: integration.name,
                                config: integration.config,
                                filters: filters,
                                autoPublish: integration.autoPublish
                              };
                      }))
              };
      }
      var model$212 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$212.archive,
                branchPointer: model$212.branchPointer,
                migrations: model$212.migrations,
                rules: model$212.rules,
                types: model$212.types,
                categories: model$212.categories,
                destinations: model$212.destinations,
                events: model$212.events,
                groupTypes: model$212.groupTypes,
                integrations: Curry._3(TrackingPlanMappedModel.Integrations.updateExisting, model$212.integrations, integrationId$6, (function (integration) {
                        return {
                                id: integration.id,
                                name: integration.name,
                                config: integration.config,
                                filters: filters,
                                autoPublish: integration.autoPublish
                              };
                      })),
                metrics: model$212.metrics,
                openBranches: model$212.openBranches,
                properties: model$212.properties,
                propertyBundles: model$212.propertyBundles,
                sources: model$212.sources,
                globalRequirements: model$212.globalRequirements
              }
            };
    }
    if (variant === "CreateProperty") {
      var match$140 = action.VAL;
      var createdProperty_id = match$140[0];
      var createdProperty_name = match$140[1];
      var createdProperty_sendAs = match$140[2];
      var createdProperty_eventSpecificAllowedPropertyValues = [];
      var createdProperty_absence = ModelUtils.hasMigrated_boxed(model, "PropertyOptionalAndExcludedSourcesToAbsence") ? /* AlwaysSent */0 : undefined;
      var createdProperty = {
        id: createdProperty_id,
        name: createdProperty_name,
        uniqueName: undefined,
        description: "",
        type_: "string",
        sendAs: createdProperty_sendAs,
        validations: /* [] */0,
        eventSpecificAllowedPropertyValues: createdProperty_eventSpecificAllowedPropertyValues,
        auto: false,
        builtIn: "NotBuiltIn",
        analyticsTools: /* [] */0,
        devPlatforms: /* [] */0,
        list: false,
        operation: "Set",
        optionalDeprecated: false,
        excludedSourcesDeprecated: /* [] */0,
        optionalWhenInObject: false,
        absence: createdProperty_absence,
        globalRequirementsMetadata: undefined
      };
      if (model.TAG === /* Old */0) {
        var model$213 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$213.types,
                  properties: Belt_List.concat(model$213.properties, {
                        hd: {
                          TAG: /* Property */1,
                          _0: createdProperty
                        },
                        tl: /* [] */0
                      }),
                  propertyBundles: model$213.propertyBundles,
                  events: model$213.events,
                  migrations: model$213.migrations,
                  sources: model$213.sources,
                  destinations: model$213.destinations,
                  groupTypes: model$213.groupTypes,
                  goals: model$213.goals,
                  metrics: model$213.metrics,
                  archive: model$213.archive,
                  openBranches: model$213.openBranches,
                  branchPointer: model$213.branchPointer,
                  rules: model$213.rules,
                  integrations: model$213.integrations,
                  globalRequirements: model$213.globalRequirements
                }
              };
      }
      var model$214 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$214.archive,
                branchPointer: model$214.branchPointer,
                migrations: model$214.migrations,
                rules: model$214.rules,
                types: model$214.types,
                categories: model$214.categories,
                destinations: model$214.destinations,
                events: model$214.events,
                groupTypes: model$214.groupTypes,
                integrations: model$214.integrations,
                metrics: model$214.metrics,
                openBranches: model$214.openBranches,
                properties: Curry._2(TrackingPlanMappedModel.Properties.append, model$214.properties, createdProperty),
                propertyBundles: model$214.propertyBundles,
                sources: model$214.sources,
                globalRequirements: model$214.globalRequirements
              }
            };
    }
    if (variant === "UpdatePropertyOptionalWhenInObject") {
      var match$141 = action.VAL;
      var optionalWhenInObject = match$141[1];
      var propertyId$49 = match$141[0];
      if (model.TAG === /* Old */0) {
        var model$215 = model._0;
        return {
                TAG: /* Old */0,
                _0: ModelUtils.hasMigrated(model$215, "PropertyOptionalAndExcludedSourcesToAbsence") ? ModelUtils.updateProperty(model$215, propertyId$49, (function (property) {
                          return {
                                  id: property.id,
                                  name: property.name,
                                  uniqueName: property.uniqueName,
                                  description: property.description,
                                  type_: property.type_,
                                  sendAs: property.sendAs,
                                  validations: property.validations,
                                  eventSpecificAllowedPropertyValues: property.eventSpecificAllowedPropertyValues,
                                  auto: property.auto,
                                  builtIn: property.builtIn,
                                  analyticsTools: property.analyticsTools,
                                  devPlatforms: property.devPlatforms,
                                  list: property.list,
                                  operation: property.operation,
                                  optionalDeprecated: property.optionalDeprecated,
                                  excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                  optionalWhenInObject: optionalWhenInObject,
                                  absence: property.absence,
                                  globalRequirementsMetadata: property.globalRequirementsMetadata
                                };
                        })) : model$215
              };
      }
      var model$216 = model._0;
      if (ModelUtils.hasMigrated_boxed(model, "PropertyOptionalAndExcludedSourcesToAbsence")) {
        return {
                TAG: /* Mapped */1,
                _0: {
                  archive: model$216.archive,
                  branchPointer: model$216.branchPointer,
                  migrations: model$216.migrations,
                  rules: model$216.rules,
                  types: model$216.types,
                  categories: model$216.categories,
                  destinations: model$216.destinations,
                  events: model$216.events,
                  groupTypes: model$216.groupTypes,
                  integrations: model$216.integrations,
                  metrics: model$216.metrics,
                  openBranches: model$216.openBranches,
                  properties: Curry._3(TrackingPlanMappedModel.Properties.updateExisting, model$216.properties, propertyId$49, (function (property) {
                          return {
                                  id: property.id,
                                  name: property.name,
                                  uniqueName: property.uniqueName,
                                  description: property.description,
                                  type_: property.type_,
                                  sendAs: property.sendAs,
                                  validations: property.validations,
                                  eventSpecificAllowedPropertyValues: property.eventSpecificAllowedPropertyValues,
                                  auto: property.auto,
                                  builtIn: property.builtIn,
                                  analyticsTools: property.analyticsTools,
                                  devPlatforms: property.devPlatforms,
                                  list: property.list,
                                  operation: property.operation,
                                  optionalDeprecated: property.optionalDeprecated,
                                  excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                  optionalWhenInObject: optionalWhenInObject,
                                  absence: property.absence,
                                  globalRequirementsMetadata: property.globalRequirementsMetadata
                                };
                        })),
                  propertyBundles: model$216.propertyBundles,
                  sources: model$216.sources,
                  globalRequirements: model$216.globalRequirements
                }
              };
      } else {
        return model;
      }
    }
    if (variant === "RemoveGroupTypeFromLogEvent") {
      var match$142 = action.VAL;
      var groupTypeIdToRemove$1 = match$142[1];
      var eventId$45 = match$142[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEvent(model._0, eventId$45, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: Belt_Array.keep($$event.eventGroupTypeIdsWithArchive, (function (groupTypeId) {
                                        return groupTypeId !== groupTypeIdToRemove$1;
                                      })),
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      }))
              };
      }
      var model$217 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$217.archive,
                branchPointer: model$217.branchPointer,
                migrations: model$217.migrations,
                rules: model$217.rules,
                types: model$217.types,
                categories: model$217.categories,
                destinations: model$217.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.updateExisting, model$217.events, eventId$45, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: Belt_Array.keep($$event.eventGroupTypeIdsWithArchive, (function (groupTypeId) {
                                        return groupTypeId !== groupTypeIdToRemove$1;
                                      })),
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      })),
                groupTypes: model$217.groupTypes,
                integrations: model$217.integrations,
                metrics: model$217.metrics,
                openBranches: model$217.openBranches,
                properties: model$217.properties,
                propertyBundles: model$217.propertyBundles,
                sources: model$217.sources,
                globalRequirements: model$217.globalRequirements
              }
            };
    }
    if (variant === "ClearPropertyRegexOverrideFromEventVariant") {
      var match$143 = action.VAL;
      var propertyId$50 = match$143[1];
      var id$14 = match$143[0];
      var update$13 = function (variant) {
        return {
                id: variant.id,
                baseEventId: variant.baseEventId,
                nameSuffix: variant.nameSuffix,
                description: variant.description,
                propertyOverrides: Belt_MapString.update(variant.propertyOverrides, propertyId$50, (function (override) {
                        if (override === undefined) {
                          return /* Overridden */{
                                  _0: {
                                    pinnedValue: /* NoneOverride */0,
                                    absence: /* NoneOverride */0,
                                    allowedValues: /* NoneOverride */0,
                                    regexValidation: /* NoneOverride */0
                                  }
                                };
                        }
                        if (!override) {
                          return /* Removed */0;
                        }
                        var overridden = override._0;
                        return /* Overridden */{
                                _0: {
                                  pinnedValue: overridden.pinnedValue,
                                  absence: overridden.absence,
                                  allowedValues: overridden.allowedValues,
                                  regexValidation: /* NoneOverride */0
                                }
                              };
                      })),
                sourceOverrides: variant.sourceOverrides,
                triggers: variant.triggers
              };
      };
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEventVariant(model._0, id$14, update$13)
              };
      }
      var model$218 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$218.archive,
                branchPointer: model$218.branchPointer,
                migrations: model$218.migrations,
                rules: model$218.rules,
                types: model$218.types,
                categories: model$218.categories,
                destinations: model$218.destinations,
                events: ModelUtils_mapped.updateExistingVariant(model$218.events, id$14, update$13),
                groupTypes: model$218.groupTypes,
                integrations: model$218.integrations,
                metrics: model$218.metrics,
                openBranches: model$218.openBranches,
                properties: model$218.properties,
                propertyBundles: model$218.propertyBundles,
                sources: model$218.sources,
                globalRequirements: model$218.globalRequirements
              }
            };
    }
    if (variant === "RemovePropertyGroupFromEvent") {
      var match$144 = action.VAL;
      var groupId$4 = match$144[1];
      var eventId$46 = match$144[0];
      if (model.TAG === /* Old */0) {
        var model$219 = model._0;
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEvent(model$219, eventId$46, (function ($$event) {
                        var propertyGroup = ModelUtils.getPropertyGroupById(groupId$4, model$219);
                        var tmp;
                        if (propertyGroup !== undefined) {
                          var groupProperties = propertyGroup.properties;
                          var propertiesBeingRemovedFromEvent = Belt_List.keepU(groupProperties, (function (propertyId) {
                                  return !Belt_List.some($$event.properties, (function (x) {
                                                return x._0.id === propertyId;
                                              }));
                                }));
                          tmp = Belt_List.keepU($$event.propertyWhitelist, (function (param) {
                                  var propId = param[1];
                                  return !Belt_List.someU(propertiesBeingRemovedFromEvent, (function (removedId) {
                                                return removedId === propId;
                                              }));
                                }));
                        } else {
                          tmp = $$event.propertyWhitelist;
                        }
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: Belt_List.keepU($$event.propertyBundles, (function (group) {
                                        return group.id !== groupId$4;
                                      })),
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: tmp,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      }))
              };
      }
      var model$220 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$220.archive,
                branchPointer: model$220.branchPointer,
                migrations: model$220.migrations,
                rules: model$220.rules,
                types: model$220.types,
                categories: model$220.categories,
                destinations: model$220.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.updateExisting, model$220.events, eventId$46, (function ($$event) {
                        var propertyGroup = Curry._2(TrackingPlanMappedModel.PropertyBundles.get, model$220.propertyBundles, groupId$4);
                        var tmp;
                        if (propertyGroup !== undefined) {
                          var groupProperties = propertyGroup.properties;
                          var propertiesBeingRemovedFromEvent = Belt_List.keepU(groupProperties, (function (propertyId) {
                                  return !Belt_List.some($$event.properties, (function (x) {
                                                return x._0.id === propertyId;
                                              }));
                                }));
                          tmp = Belt_List.keepU($$event.propertyWhitelist, (function (param) {
                                  var propId = param[1];
                                  return !Belt_List.someU(propertiesBeingRemovedFromEvent, (function (removedId) {
                                                return removedId === propId;
                                              }));
                                }));
                        } else {
                          tmp = $$event.propertyWhitelist;
                        }
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: Belt_List.keepU($$event.propertyBundles, (function (group) {
                                        return group.id !== groupId$4;
                                      })),
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: tmp,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      })),
                groupTypes: model$220.groupTypes,
                integrations: model$220.integrations,
                metrics: model$220.metrics,
                openBranches: model$220.openBranches,
                properties: model$220.properties,
                propertyBundles: model$220.propertyBundles,
                sources: model$220.sources,
                globalRequirements: model$220.globalRequirements
              }
            };
    }
    if (variant === "UpdateMetricDescription") {
      var match$145 = action.VAL;
      var description$8 = match$145[1];
      var metricId$17 = match$145[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateMetric(model._0, metricId$17, (function (metric) {
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: description$8,
                                type_: metric.type_,
                                items: metric.items
                              };
                      }))
              };
      }
      var model$221 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$221.archive,
                branchPointer: model$221.branchPointer,
                migrations: model$221.migrations,
                rules: model$221.rules,
                types: model$221.types,
                categories: model$221.categories,
                destinations: model$221.destinations,
                events: model$221.events,
                groupTypes: model$221.groupTypes,
                integrations: model$221.integrations,
                metrics: Curry._3(TrackingPlanMappedModel.Metrics.updateExisting, model$221.metrics, metricId$17, (function (metric) {
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: description$8,
                                type_: metric.type_,
                                items: metric.items
                              };
                      })),
                openBranches: model$221.openBranches,
                properties: model$221.properties,
                propertyBundles: model$221.propertyBundles,
                sources: model$221.sources,
                globalRequirements: model$221.globalRequirements
              }
            };
    }
    if (variant === "CreateLocalWorkspace") {
      var localWorkspaceId$2 = action.VAL;
      if (model.TAG === /* Old */0) {
        var model$222 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$222.types,
                  properties: model$222.properties,
                  propertyBundles: model$222.propertyBundles,
                  events: model$222.events,
                  migrations: model$222.migrations,
                  sources: Belt_List.concat(model$222.sources, {
                        hd: TrackingPlanModel.emptySource(undefined, localWorkspaceId$2, AvoConfig.defaultFilename, AvoConfig.defaultPath),
                        tl: /* [] */0
                      }),
                  destinations: model$222.destinations,
                  groupTypes: model$222.groupTypes,
                  goals: model$222.goals,
                  metrics: model$222.metrics,
                  archive: model$222.archive,
                  openBranches: model$222.openBranches,
                  branchPointer: model$222.branchPointer,
                  rules: model$222.rules,
                  integrations: model$222.integrations,
                  globalRequirements: model$222.globalRequirements
                }
              };
      }
      var model$223 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$223.archive,
                branchPointer: model$223.branchPointer,
                migrations: model$223.migrations,
                rules: model$223.rules,
                types: model$223.types,
                categories: model$223.categories,
                destinations: model$223.destinations,
                events: model$223.events,
                groupTypes: model$223.groupTypes,
                integrations: model$223.integrations,
                metrics: model$223.metrics,
                openBranches: model$223.openBranches,
                properties: model$223.properties,
                propertyBundles: model$223.propertyBundles,
                sources: Curry._2(TrackingPlanMappedModel.Sources.append, model$223.sources, TrackingPlanModel.emptySource(undefined, localWorkspaceId$2, AvoConfig.defaultFilename, AvoConfig.defaultPath)),
                globalRequirements: model$223.globalRequirements
              }
            };
    }
    if (variant === "UpdateGlobalRequirements") {
      var globalRequirements$24 = action.VAL;
      if (model.TAG === /* Old */0) {
        var model$224 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$224.types,
                  properties: model$224.properties,
                  propertyBundles: model$224.propertyBundles,
                  events: model$224.events,
                  migrations: model$224.migrations,
                  sources: model$224.sources,
                  destinations: model$224.destinations,
                  groupTypes: model$224.groupTypes,
                  goals: model$224.goals,
                  metrics: model$224.metrics,
                  archive: model$224.archive,
                  openBranches: model$224.openBranches,
                  branchPointer: model$224.branchPointer,
                  rules: model$224.rules,
                  integrations: model$224.integrations,
                  globalRequirements: globalRequirements$24
                }
              };
      }
      var model$225 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$225.archive,
                branchPointer: model$225.branchPointer,
                migrations: model$225.migrations,
                rules: model$225.rules,
                types: model$225.types,
                categories: model$225.categories,
                destinations: model$225.destinations,
                events: model$225.events,
                groupTypes: model$225.groupTypes,
                integrations: model$225.integrations,
                metrics: model$225.metrics,
                openBranches: model$225.openBranches,
                properties: model$225.properties,
                propertyBundles: model$225.propertyBundles,
                sources: model$225.sources,
                globalRequirements: TrackingPlanMappedModel.GlobalRequirements.fromOld(globalRequirements$24)
              }
            };
    }
    if (variant === "RemoveMetricFromGoal") {
      var match$146 = action.VAL;
      var metricId$18 = match$146[1];
      var goalId$11 = match$146[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateGoal(model._0, goalId$11, (function (goal) {
                        return {
                                id: goal.id,
                                name: goal.name,
                                description: goal.description,
                                metrics: Belt_List.keepU(goal.metrics, (function (id) {
                                        return id !== metricId$18;
                                      })),
                                events: goal.events
                              };
                      }))
              };
      }
      var model$226 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$226.archive,
                branchPointer: model$226.branchPointer,
                migrations: model$226.migrations,
                rules: model$226.rules,
                types: model$226.types,
                categories: Curry._3(TrackingPlanMappedModel.Categories.updateExisting, model$226.categories, goalId$11, (function (category) {
                        return {
                                id: category.id,
                                name: category.name,
                                description: category.description,
                                metrics: Belt_List.keepU(category.metrics, (function (id) {
                                        return id !== metricId$18;
                                      })),
                                events: category.events
                              };
                      })),
                destinations: model$226.destinations,
                events: model$226.events,
                groupTypes: model$226.groupTypes,
                integrations: model$226.integrations,
                metrics: model$226.metrics,
                openBranches: model$226.openBranches,
                properties: model$226.properties,
                propertyBundles: model$226.propertyBundles,
                sources: model$226.sources,
                globalRequirements: model$226.globalRequirements
              }
            };
    }
    if (variant === "UpdateEventVariantTriggerContent") {
      var match$147 = action.VAL;
      var content$2 = match$147[2];
      var triggerId$9 = match$147[1];
      var variantIdentifier$12 = match$147[0];
      var update$14 = function (trigger) {
        return {
                id: trigger.id,
                sources: trigger.sources,
                description: trigger.description,
                content: content$2
              };
      };
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEventVariantTrigger(model._0, variantIdentifier$12, triggerId$9, update$14)
              };
      } else {
        return {
                TAG: /* Mapped */1,
                _0: ModelUtils_mapped.updateEventVariantTrigger_mappedModel(model._0, variantIdentifier$12, triggerId$9, update$14)
              };
      }
    }
    if (variant === "ConfigureInspectorValidation") {
      var match$148 = action.VAL;
      var sourceValidationMap = match$148[1];
      var eventIds$2 = match$148[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: Belt_List.reduceU(eventIds$2, model._0, (function (nextModel, eventId) {
                        return ModelUtils.updateEvent(nextModel, eventId, (function ($$event) {
                                      return {
                                              id: $$event.id,
                                              name: $$event.name,
                                              uniqueName: $$event.uniqueName,
                                              description: $$event.description,
                                              properties: $$event.properties,
                                              propertyBundles: $$event.propertyBundles,
                                              variants: $$event.variants,
                                              types: $$event.types,
                                              tags: $$event.tags,
                                              excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                              includeSources: Belt_List.map($$event.includeSources, (function (source) {
                                                      var validation = Belt_MapString.get(sourceValidationMap, source.id);
                                                      if (validation !== undefined) {
                                                        return {
                                                                id: source.id,
                                                                includeInCodegen: source.includeInCodegen,
                                                                inspectorValidation: Caml_option.valFromOption(validation)
                                                              };
                                                      } else {
                                                        return source;
                                                      }
                                                    })),
                                              includeDestinations: $$event.includeDestinations,
                                              hashes: $$event.hashes,
                                              propertyWhitelist: $$event.propertyWhitelist,
                                              eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                              userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                              triggers: $$event.triggers,
                                              globalRequirementsMetadata: $$event.globalRequirementsMetadata
                                            };
                                    }));
                      }))
              };
      } else {
        return {
                TAG: /* Mapped */1,
                _0: Belt_List.reduceU(eventIds$2, model._0, (function (nextModel, eventId) {
                        return {
                                archive: nextModel.archive,
                                branchPointer: nextModel.branchPointer,
                                migrations: nextModel.migrations,
                                rules: nextModel.rules,
                                types: nextModel.types,
                                categories: nextModel.categories,
                                destinations: nextModel.destinations,
                                events: Curry._3(TrackingPlanMappedModel.Events.updateExisting, nextModel.events, eventId, (function ($$event) {
                                        return {
                                                id: $$event.id,
                                                name: $$event.name,
                                                uniqueName: $$event.uniqueName,
                                                description: $$event.description,
                                                properties: $$event.properties,
                                                propertyBundles: $$event.propertyBundles,
                                                variants: $$event.variants,
                                                types: $$event.types,
                                                tags: $$event.tags,
                                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                                includeSources: Belt_List.map($$event.includeSources, (function (source) {
                                                        var validation = Belt_MapString.get(sourceValidationMap, source.id);
                                                        if (validation !== undefined) {
                                                          return {
                                                                  id: source.id,
                                                                  includeInCodegen: source.includeInCodegen,
                                                                  inspectorValidation: Caml_option.valFromOption(validation)
                                                                };
                                                        } else {
                                                          return source;
                                                        }
                                                      })),
                                                includeDestinations: $$event.includeDestinations,
                                                hashes: $$event.hashes,
                                                propertyWhitelist: $$event.propertyWhitelist,
                                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                                triggers: $$event.triggers,
                                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                                              };
                                      })),
                                groupTypes: nextModel.groupTypes,
                                integrations: nextModel.integrations,
                                metrics: nextModel.metrics,
                                openBranches: nextModel.openBranches,
                                properties: nextModel.properties,
                                propertyBundles: nextModel.propertyBundles,
                                sources: nextModel.sources,
                                globalRequirements: nextModel.globalRequirements
                              };
                      }))
              };
      }
    }
    if (variant === "AddPropertyToGroup") {
      var match$149 = action.VAL;
      var propertyId$51 = match$149[1];
      var groupId$5 = match$149[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updatePropertyGroup(model._0, groupId$5, (function (group) {
                        return {
                                id: group.id,
                                name: group.name,
                                description: group.description,
                                properties: Belt_List.someU(group.properties, (function (id) {
                                        return id === propertyId$51;
                                      })) ? group.properties : Belt_List.concat(group.properties, {
                                        hd: propertyId$51,
                                        tl: /* [] */0
                                      }),
                                globalRequirementsMetadata: group.globalRequirementsMetadata
                              };
                      }))
              };
      }
      var model$227 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$227.archive,
                branchPointer: model$227.branchPointer,
                migrations: model$227.migrations,
                rules: model$227.rules,
                types: model$227.types,
                categories: model$227.categories,
                destinations: model$227.destinations,
                events: model$227.events,
                groupTypes: model$227.groupTypes,
                integrations: model$227.integrations,
                metrics: model$227.metrics,
                openBranches: model$227.openBranches,
                properties: model$227.properties,
                propertyBundles: Curry._3(TrackingPlanMappedModel.PropertyBundles.updateExisting, model$227.propertyBundles, groupId$5, (function (group) {
                        return {
                                id: group.id,
                                name: group.name,
                                description: group.description,
                                properties: Belt_List.someU(group.properties, (function (id) {
                                        return id === propertyId$51;
                                      })) ? group.properties : Belt_List.concat(group.properties, {
                                        hd: propertyId$51,
                                        tl: /* [] */0
                                      }),
                                globalRequirementsMetadata: group.globalRequirementsMetadata
                              };
                      })),
                sources: model$227.sources,
                globalRequirements: model$227.globalRequirements
              }
            };
    }
    if (variant === "UpdateSourcePlatform") {
      var match$150 = action.VAL;
      var platform$1 = match$150[1];
      var sourceId$30 = match$150[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateSource(model._0, sourceId$30, (function (source) {
                        var language = source.language;
                        return {
                                id: source.id,
                                name: source.name,
                                language: language !== undefined && Belt_Array.someU(AvoConfig.getSupportedLanguages(platform$1), (function (supportedLanguage) {
                                        return supportedLanguage === language;
                                      })) ? language : Belt_Array.get(AvoConfig.getSupportedLanguages(platform$1), 0),
                                platform: platform$1,
                                destinations: source.destinations,
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: source.config
                              };
                      }))
              };
      }
      var model$228 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$228.archive,
                branchPointer: model$228.branchPointer,
                migrations: model$228.migrations,
                rules: model$228.rules,
                types: model$228.types,
                categories: model$228.categories,
                destinations: model$228.destinations,
                events: model$228.events,
                groupTypes: model$228.groupTypes,
                integrations: model$228.integrations,
                metrics: model$228.metrics,
                openBranches: model$228.openBranches,
                properties: model$228.properties,
                propertyBundles: model$228.propertyBundles,
                sources: Curry._3(TrackingPlanMappedModel.Sources.updateExisting, model$228.sources, sourceId$30, (function (source) {
                        var language = source.language;
                        return {
                                id: source.id,
                                name: source.name,
                                language: language !== undefined && Belt_Array.someU(AvoConfig.getSupportedLanguages(platform$1), (function (supportedLanguage) {
                                        return supportedLanguage === language;
                                      })) ? language : Belt_Array.get(AvoConfig.getSupportedLanguages(platform$1), 0),
                                platform: platform$1,
                                destinations: source.destinations,
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: source.config
                              };
                      })),
                globalRequirements: model$228.globalRequirements
              }
            };
    }
    if (variant === "UpdateDestinationDevApiKey") {
      var match$151 = action.VAL;
      var devApiKey = match$151[1];
      var destinationId$16 = match$151[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateDestination(model._0, destinationId$16, (function (destination) {
                        var init = destination.apiKeys;
                        return {
                                id: destination.id,
                                name: destination.name,
                                type_: destination.type_,
                                apiKeys: {
                                  dev: devApiKey,
                                  prod: init.prod,
                                  other: init.other
                                },
                                includeUserPropsWithEventProps: destination.includeUserPropsWithEventProps,
                                disabledByDefault: destination.disabledByDefault
                              };
                      }))
              };
      }
      var model$229 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$229.archive,
                branchPointer: model$229.branchPointer,
                migrations: model$229.migrations,
                rules: model$229.rules,
                types: model$229.types,
                categories: model$229.categories,
                destinations: Curry._3(TrackingPlanMappedModel.Destinations.updateExisting, model$229.destinations, destinationId$16, (function (destination) {
                        var init = destination.apiKeys;
                        return {
                                id: destination.id,
                                name: destination.name,
                                type_: destination.type_,
                                apiKeys: {
                                  dev: devApiKey,
                                  prod: init.prod,
                                  other: init.other
                                },
                                includeUserPropsWithEventProps: destination.includeUserPropsWithEventProps,
                                disabledByDefault: destination.disabledByDefault
                              };
                      })),
                events: model$229.events,
                groupTypes: model$229.groupTypes,
                integrations: model$229.integrations,
                metrics: model$229.metrics,
                openBranches: model$229.openBranches,
                properties: model$229.properties,
                propertyBundles: model$229.propertyBundles,
                sources: model$229.sources,
                globalRequirements: model$229.globalRequirements
              }
            };
    }
    if (variant === "UpdateLibraryName") {
      var match$152 = action.VAL;
      var name$14 = match$152[1];
      var sourceId$31 = match$152[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateSource(model._0, sourceId$31, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: source.language,
                                platform: source.platform,
                                destinations: source.destinations,
                                filename: name$14,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: source.config
                              };
                      }))
              };
      }
      var model$230 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$230.archive,
                branchPointer: model$230.branchPointer,
                migrations: model$230.migrations,
                rules: model$230.rules,
                types: model$230.types,
                categories: model$230.categories,
                destinations: model$230.destinations,
                events: model$230.events,
                groupTypes: model$230.groupTypes,
                integrations: model$230.integrations,
                metrics: model$230.metrics,
                openBranches: model$230.openBranches,
                properties: model$230.properties,
                propertyBundles: model$230.propertyBundles,
                sources: Curry._3(TrackingPlanMappedModel.Sources.updateExisting, model$230.sources, sourceId$31, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: source.language,
                                platform: source.platform,
                                destinations: source.destinations,
                                filename: name$14,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: source.config
                              };
                      })),
                globalRequirements: model$230.globalRequirements
              }
            };
    }
    if (variant === "CloseBranch") {
      var branchId$2 = action.VAL[0];
      if (model.TAG === /* Old */0) {
        var model$231 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$231.types,
                  properties: model$231.properties,
                  propertyBundles: model$231.propertyBundles,
                  events: model$231.events,
                  migrations: model$231.migrations,
                  sources: model$231.sources,
                  destinations: model$231.destinations,
                  groupTypes: model$231.groupTypes,
                  goals: model$231.goals,
                  metrics: model$231.metrics,
                  archive: model$231.archive,
                  openBranches: Belt_List.keepU(model$231.openBranches, (function (param) {
                          return param[0] !== branchId$2;
                        })),
                  branchPointer: model$231.branchPointer,
                  rules: model$231.rules,
                  integrations: model$231.integrations,
                  globalRequirements: model$231.globalRequirements
                }
              };
      }
      var model$232 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$232.archive,
                branchPointer: model$232.branchPointer,
                migrations: model$232.migrations,
                rules: model$232.rules,
                types: model$232.types,
                categories: model$232.categories,
                destinations: model$232.destinations,
                events: model$232.events,
                groupTypes: model$232.groupTypes,
                integrations: model$232.integrations,
                metrics: model$232.metrics,
                openBranches: Curry._2(TrackingPlanMappedModel.OpenBranches.remove, model$232.openBranches, branchId$2),
                properties: model$232.properties,
                propertyBundles: model$232.propertyBundles,
                sources: model$232.sources,
                globalRequirements: model$232.globalRequirements
              }
            };
    }
    if (variant === "RemoveEventFromMetric") {
      var match$153 = action.VAL;
      var metricItemId$6 = match$153[1];
      var metricId$19 = match$153[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateMetric(model._0, metricId$19, (function (metric) {
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metric.type_,
                                items: Belt_List.keepU(metric.items, (function (item) {
                                        return item.VAL.id !== metricItemId$6;
                                      }))
                              };
                      }))
              };
      }
      var model$233 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$233.archive,
                branchPointer: model$233.branchPointer,
                migrations: model$233.migrations,
                rules: model$233.rules,
                types: model$233.types,
                categories: model$233.categories,
                destinations: model$233.destinations,
                events: model$233.events,
                groupTypes: model$233.groupTypes,
                integrations: model$233.integrations,
                metrics: Curry._3(TrackingPlanMappedModel.Metrics.updateExisting, model$233.metrics, metricId$19, (function (metric) {
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metric.type_,
                                items: Belt_List.keepU(metric.items, (function (item) {
                                        return item.VAL.id !== metricItemId$6;
                                      }))
                              };
                      })),
                openBranches: model$233.openBranches,
                properties: model$233.properties,
                propertyBundles: model$233.propertyBundles,
                sources: model$233.sources,
                globalRequirements: model$233.globalRequirements
              }
            };
    }
    if (variant === "RemovePropertyPinnedValue") {
      var match$154 = action.VAL;
      var propertyId$52 = match$154[1];
      var eventId$47 = match$154[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEvent(model._0, eventId$47, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: Belt_List.map($$event.properties, (function (property) {
                                        if (property.TAG !== /* PropertyRef */0) {
                                          return property;
                                        }
                                        var propRef = property._0;
                                        if (propRef.id === propertyId$52) {
                                          return {
                                                  TAG: /* PropertyRef */0,
                                                  _0: {
                                                    id: propRef.id,
                                                    description: propRef.description,
                                                    pinnedValue: undefined
                                                  }
                                                };
                                        } else {
                                          return property;
                                        }
                                      })),
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      }))
              };
      }
      var model$234 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$234.archive,
                branchPointer: model$234.branchPointer,
                migrations: model$234.migrations,
                rules: model$234.rules,
                types: model$234.types,
                categories: model$234.categories,
                destinations: model$234.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.updateExisting, model$234.events, eventId$47, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: Belt_List.map($$event.properties, (function (property) {
                                        if (property.TAG !== /* PropertyRef */0) {
                                          return property;
                                        }
                                        var propRef = property._0;
                                        if (propRef.id === propertyId$52) {
                                          return {
                                                  TAG: /* PropertyRef */0,
                                                  _0: {
                                                    id: propRef.id,
                                                    description: propRef.description,
                                                    pinnedValue: undefined
                                                  }
                                                };
                                        } else {
                                          return property;
                                        }
                                      })),
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      })),
                groupTypes: model$234.groupTypes,
                integrations: model$234.integrations,
                metrics: model$234.metrics,
                openBranches: model$234.openBranches,
                properties: model$234.properties,
                propertyBundles: model$234.propertyBundles,
                sources: model$234.sources,
                globalRequirements: model$234.globalRequirements
              }
            };
    }
    if (variant === "UpdateDestinationProdApiKey") {
      var match$155 = action.VAL;
      var prodApiKey = match$155[1];
      var destinationId$17 = match$155[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateDestination(model._0, destinationId$17, (function (destination) {
                        var init = destination.apiKeys;
                        return {
                                id: destination.id,
                                name: destination.name,
                                type_: destination.type_,
                                apiKeys: {
                                  dev: init.dev,
                                  prod: prodApiKey,
                                  other: init.other
                                },
                                includeUserPropsWithEventProps: destination.includeUserPropsWithEventProps,
                                disabledByDefault: destination.disabledByDefault
                              };
                      }))
              };
      }
      var model$235 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$235.archive,
                branchPointer: model$235.branchPointer,
                migrations: model$235.migrations,
                rules: model$235.rules,
                types: model$235.types,
                categories: model$235.categories,
                destinations: Curry._3(TrackingPlanMappedModel.Destinations.updateExisting, model$235.destinations, destinationId$17, (function (destination) {
                        var init = destination.apiKeys;
                        return {
                                id: destination.id,
                                name: destination.name,
                                type_: destination.type_,
                                apiKeys: {
                                  dev: init.dev,
                                  prod: prodApiKey,
                                  other: init.other
                                },
                                includeUserPropsWithEventProps: destination.includeUserPropsWithEventProps,
                                disabledByDefault: destination.disabledByDefault
                              };
                      })),
                events: model$235.events,
                groupTypes: model$235.groupTypes,
                integrations: model$235.integrations,
                metrics: model$235.metrics,
                openBranches: model$235.openBranches,
                properties: model$235.properties,
                propertyBundles: model$235.propertyBundles,
                sources: model$235.sources,
                globalRequirements: model$235.globalRequirements
              }
            };
    }
    if (variant === "RemoveEventTrigger") {
      var match$156 = action.VAL;
      var triggerId$10 = match$156[1];
      var eventId$48 = match$156[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEvent(model._0, eventId$48, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: Belt_Array.keep($$event.triggers, (function (param) {
                                        return param.id !== triggerId$10;
                                      })),
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      }))
              };
      }
      var model$236 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$236.archive,
                branchPointer: model$236.branchPointer,
                migrations: model$236.migrations,
                rules: model$236.rules,
                types: model$236.types,
                categories: model$236.categories,
                destinations: model$236.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.updateExisting, model$236.events, eventId$48, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: Belt_Array.keep($$event.triggers, (function (param) {
                                        return param.id !== triggerId$10;
                                      })),
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      })),
                groupTypes: model$236.groupTypes,
                integrations: model$236.integrations,
                metrics: model$236.metrics,
                openBranches: model$236.openBranches,
                properties: model$236.properties,
                propertyBundles: model$236.propertyBundles,
                sources: model$236.sources,
                globalRequirements: model$236.globalRequirements
              }
            };
    }
    if (variant === "ReorderGoals") {
      var match$157 = action.VAL;
      var maybePredecessorId$7 = match$157[1];
      var goalId$12 = match$157[0];
      if (model.TAG === /* Old */0) {
        var model$237 = model._0;
        var movedItem$1 = Belt_List.getByU(model$237.goals, (function (goal) {
                return goal.id === goalId$12;
              }));
        var listWithoutItem$1 = Belt_List.keepU(model$237.goals, (function (goal) {
                return goal.id !== goalId$12;
              }));
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$237.types,
                  properties: model$237.properties,
                  propertyBundles: model$237.propertyBundles,
                  events: model$237.events,
                  migrations: model$237.migrations,
                  sources: model$237.sources,
                  destinations: model$237.destinations,
                  groupTypes: model$237.groupTypes,
                  goals: movedItem$1 !== undefined ? (
                      maybePredecessorId$7 !== undefined ? BeltListExtensions.flatMap(listWithoutItem$1, (function (goal) {
                                if (goal.id === maybePredecessorId$7) {
                                  return {
                                          hd: goal,
                                          tl: {
                                            hd: movedItem$1,
                                            tl: /* [] */0
                                          }
                                        };
                                } else {
                                  return {
                                          hd: goal,
                                          tl: /* [] */0
                                        };
                                }
                              })) : Belt_List.concat({
                              hd: movedItem$1,
                              tl: /* [] */0
                            }, listWithoutItem$1)
                    ) : model$237.goals,
                  metrics: model$237.metrics,
                  archive: model$237.archive,
                  openBranches: model$237.openBranches,
                  branchPointer: model$237.branchPointer,
                  rules: model$237.rules,
                  integrations: model$237.integrations,
                  globalRequirements: model$237.globalRequirements
                }
              };
      }
      var model$238 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$238.archive,
                branchPointer: model$238.branchPointer,
                migrations: model$238.migrations,
                rules: model$238.rules,
                types: model$238.types,
                categories: Curry._3(TrackingPlanMappedModel.Categories.reorderBuggy, model$238.categories, goalId$12, maybePredecessorId$7),
                destinations: model$238.destinations,
                events: model$238.events,
                groupTypes: model$238.groupTypes,
                integrations: model$238.integrations,
                metrics: model$238.metrics,
                openBranches: model$238.openBranches,
                properties: model$238.properties,
                propertyBundles: model$238.propertyBundles,
                sources: model$238.sources,
                globalRequirements: model$238.globalRequirements
              }
            };
    }
    if (variant === "DeprecatedAddPropertyValidationMinOrMax") {
      var match$158 = action.VAL;
      var validationType$1 = match$158[1];
      var propertyId$53 = match$158[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updatePropertyValidations(model._0, propertyId$53, (function (validations, property) {
                        var match = TrackingPlanModel.decodeType(property.type_);
                        var validationValue = match === "Int" ? ({
                              NAME: "IntLit",
                              VAL: 0
                            }) : (
                            match === "Float" ? ({
                                  NAME: "FloatLit",
                                  VAL: 0.0
                                }) : Pervasives.failwith("Unsupported type for Min constraint")
                          );
                        return Belt_List.reverse({
                                    hd: validationType$1 === "Max" ? ({
                                          NAME: "Max",
                                          VAL: validationValue
                                        }) : (
                                        validationType$1 === "Min" ? ({
                                              NAME: "Min",
                                              VAL: validationValue
                                            }) : Pervasives.failwith("Cannot convert to anything else than Min and Max")
                                      ),
                                    tl: Belt_List.reverse(validations)
                                  });
                      }))
              };
      }
      var model$239 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$239.archive,
                branchPointer: model$239.branchPointer,
                migrations: model$239.migrations,
                rules: model$239.rules,
                types: model$239.types,
                categories: model$239.categories,
                destinations: model$239.destinations,
                events: model$239.events,
                groupTypes: model$239.groupTypes,
                integrations: model$239.integrations,
                metrics: model$239.metrics,
                openBranches: model$239.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.updateExisting, model$239.properties, propertyId$53, (function (property) {
                        var match = TrackingPlanModel.decodeType(property.type_);
                        var validationValue = match === "Int" ? ({
                              NAME: "IntLit",
                              VAL: 0
                            }) : (
                            match === "Float" ? ({
                                  NAME: "FloatLit",
                                  VAL: 0.0
                                }) : Pervasives.failwith("Unsupported type for Min constraint")
                          );
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: Belt_List.reverse({
                                      hd: validationType$1 === "Max" ? ({
                                            NAME: "Max",
                                            VAL: validationValue
                                          }) : (
                                          validationType$1 === "Min" ? ({
                                                NAME: "Min",
                                                VAL: validationValue
                                              }) : Pervasives.failwith("Cannot convert to anything else than Min and Max")
                                        ),
                                      tl: Belt_List.reverse(property.validations)
                                    }),
                                eventSpecificAllowedPropertyValues: property.eventSpecificAllowedPropertyValues,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence,
                                globalRequirementsMetadata: property.globalRequirementsMetadata
                              };
                      })),
                propertyBundles: model$239.propertyBundles,
                sources: model$239.sources,
                globalRequirements: model$239.globalRequirements
              }
            };
    }
    if (variant === "RemoveGoal") {
      var goalId$13 = action.VAL;
      if (model.TAG === /* Old */0) {
        var model$240 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$240.types,
                  properties: model$240.properties,
                  propertyBundles: model$240.propertyBundles,
                  events: model$240.events,
                  migrations: model$240.migrations,
                  sources: model$240.sources,
                  destinations: model$240.destinations,
                  groupTypes: model$240.groupTypes,
                  goals: Belt_List.keepU(model$240.goals, (function (goal) {
                          return goal.id !== goalId$13;
                        })),
                  metrics: model$240.metrics,
                  archive: model$240.archive,
                  openBranches: model$240.openBranches,
                  branchPointer: model$240.branchPointer,
                  rules: model$240.rules,
                  integrations: model$240.integrations,
                  globalRequirements: model$240.globalRequirements
                }
              };
      }
      var model$241 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$241.archive,
                branchPointer: model$241.branchPointer,
                migrations: model$241.migrations,
                rules: model$241.rules,
                types: model$241.types,
                categories: Curry._2(TrackingPlanMappedModel.Categories.remove, model$241.categories, goalId$13),
                destinations: model$241.destinations,
                events: model$241.events,
                groupTypes: model$241.groupTypes,
                integrations: model$241.integrations,
                metrics: model$241.metrics,
                openBranches: model$241.openBranches,
                properties: model$241.properties,
                propertyBundles: model$241.propertyBundles,
                sources: model$241.sources,
                globalRequirements: model$241.globalRequirements
              }
            };
    }
    if (variant === "RemoveMetricWhereV2") {
      var match$159 = action.VAL;
      var id$15 = match$159[3];
      var metricItemId$7 = match$159[1];
      var metricId$20 = match$159[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateMetric(model._0, metricId$20, (function (metric) {
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metric.type_,
                                items: Belt_List.mapU(metric.items, (function (metricItem) {
                                        var $$event = metricItem.VAL;
                                        if (metricItemId$7 === $$event.id) {
                                          return {
                                                  NAME: "Event",
                                                  VAL: {
                                                    id: $$event.id,
                                                    eventId: $$event.eventId,
                                                    where: Belt_List.keepU($$event.where, (function (where) {
                                                            return where.id !== id$15;
                                                          })),
                                                    groupBy: $$event.groupBy
                                                  }
                                                };
                                        } else {
                                          return metricItem;
                                        }
                                      }))
                              };
                      }))
              };
      }
      var model$242 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$242.archive,
                branchPointer: model$242.branchPointer,
                migrations: model$242.migrations,
                rules: model$242.rules,
                types: model$242.types,
                categories: model$242.categories,
                destinations: model$242.destinations,
                events: model$242.events,
                groupTypes: model$242.groupTypes,
                integrations: model$242.integrations,
                metrics: Curry._3(TrackingPlanMappedModel.Metrics.updateExisting, model$242.metrics, metricId$20, (function (metric) {
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metric.type_,
                                items: Belt_List.mapU(metric.items, (function (metricItem) {
                                        var $$event = metricItem.VAL;
                                        if (metricItemId$7 === $$event.id) {
                                          return {
                                                  NAME: "Event",
                                                  VAL: {
                                                    id: $$event.id,
                                                    eventId: $$event.eventId,
                                                    where: Belt_List.keepU($$event.where, (function (where) {
                                                            return where.id !== id$15;
                                                          })),
                                                    groupBy: $$event.groupBy
                                                  }
                                                };
                                        } else {
                                          return metricItem;
                                        }
                                      }))
                              };
                      })),
                openBranches: model$242.openBranches,
                properties: model$242.properties,
                propertyBundles: model$242.propertyBundles,
                sources: model$242.sources,
                globalRequirements: model$242.globalRequirements
              }
            };
    }
    if (variant === "RemoveTriggerSource") {
      var match$160 = action.VAL;
      var sourceId$32 = match$160[2];
      var triggerId$11 = match$160[1];
      var eventId$49 = match$160[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEventTrigger(model._0, eventId$49, triggerId$11, (function (trigger) {
                        var sourceIds = trigger.sources;
                        var tmp;
                        if (sourceIds) {
                          var nextSourceIds = Belt_Array.keep(sourceIds._0, (function (id) {
                                  return id !== sourceId$32;
                                }));
                          tmp = nextSourceIds.length === 0 ? /* SourceIndependent */0 : /* SourceIds */({
                                _0: nextSourceIds
                              });
                        } else {
                          tmp = /* SourceIndependent */0;
                        }
                        return {
                                id: trigger.id,
                                sources: tmp,
                                description: trigger.description,
                                content: trigger.content
                              };
                      }))
              };
      }
      var model$243 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$243.archive,
                branchPointer: model$243.branchPointer,
                migrations: model$243.migrations,
                rules: model$243.rules,
                types: model$243.types,
                categories: model$243.categories,
                destinations: model$243.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.updateExisting, model$243.events, eventId$49, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: Belt_Array.mapU($$event.triggers, (function (trigger) {
                                        if (trigger.id !== triggerId$11) {
                                          return trigger;
                                        }
                                        var sourceIds = trigger.sources;
                                        var tmp;
                                        if (sourceIds) {
                                          var nextSourceIds = Belt_Array.keep(sourceIds._0, (function (id) {
                                                  return id !== sourceId$32;
                                                }));
                                          tmp = nextSourceIds.length === 0 ? /* SourceIndependent */0 : /* SourceIds */({
                                                _0: nextSourceIds
                                              });
                                        } else {
                                          tmp = /* SourceIndependent */0;
                                        }
                                        return {
                                                id: trigger.id,
                                                sources: tmp,
                                                description: trigger.description,
                                                content: trigger.content
                                              };
                                      })),
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      })),
                groupTypes: model$243.groupTypes,
                integrations: model$243.integrations,
                metrics: model$243.metrics,
                openBranches: model$243.openBranches,
                properties: model$243.properties,
                propertyBundles: model$243.propertyBundles,
                sources: model$243.sources,
                globalRequirements: model$243.globalRequirements
              }
            };
    }
    if (variant === "UpdatePropertyAbsence") {
      var match$161 = action.VAL;
      var maybeEventSourceAbsence = match$161[2];
      var propertyAbsenceUpdateType = match$161[1];
      var propertyId$54 = match$161[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateProperty(model._0, propertyId$54, (function (property) {
                        return updatePropertyAbsence(model, property, propertyAbsenceUpdateType, maybeEventSourceAbsence);
                      }))
              };
      }
      var model$244 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$244.archive,
                branchPointer: model$244.branchPointer,
                migrations: model$244.migrations,
                rules: model$244.rules,
                types: model$244.types,
                categories: model$244.categories,
                destinations: model$244.destinations,
                events: model$244.events,
                groupTypes: model$244.groupTypes,
                integrations: model$244.integrations,
                metrics: model$244.metrics,
                openBranches: model$244.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.updateExisting, model$244.properties, propertyId$54, (function (property) {
                        return updatePropertyAbsence(model, property, propertyAbsenceUpdateType, maybeEventSourceAbsence);
                      })),
                propertyBundles: model$244.propertyBundles,
                sources: model$244.sources,
                globalRequirements: model$244.globalRequirements
              }
            };
    }
    if (variant === "Unarchive") {
      var item$2 = action.VAL;
      var variant$3 = item$2.NAME;
      if (variant$3 === "Destination") {
        var destinationId$18 = item$2.VAL;
        if (model.TAG === /* Old */0) {
          var model$245 = model._0;
          var init$8 = model$245.archive;
          return {
                  TAG: /* Old */0,
                  _0: {
                    types: model$245.types,
                    properties: model$245.properties,
                    propertyBundles: model$245.propertyBundles,
                    events: model$245.events,
                    migrations: model$245.migrations,
                    sources: model$245.sources,
                    destinations: Belt_Option.mapWithDefault(Belt_List.getByU(model$245.archive.destinations, (function (destination) {
                                return destination.id === destinationId$18;
                              })), model$245.destinations, (function (destination) {
                            return Belt_List.concat(model$245.destinations, {
                                        hd: destination,
                                        tl: /* [] */0
                                      });
                          })),
                    groupTypes: model$245.groupTypes,
                    goals: model$245.goals,
                    metrics: model$245.metrics,
                    archive: {
                      events: init$8.events,
                      properties: init$8.properties,
                      propertyBundles: init$8.propertyBundles,
                      sources: init$8.sources,
                      destinations: Belt_List.keepU(model$245.archive.destinations, (function (destination) {
                              return destination.id !== destinationId$18;
                            })),
                      goals: init$8.goals,
                      metrics: init$8.metrics,
                      groupTypes: init$8.groupTypes,
                      eventVariants: init$8.eventVariants
                    },
                    openBranches: model$245.openBranches,
                    branchPointer: model$245.branchPointer,
                    rules: model$245.rules,
                    integrations: model$245.integrations,
                    globalRequirements: model$245.globalRequirements
                  }
                };
        }
        var model$246 = model._0;
        var init$9 = model$246.archive;
        return {
                TAG: /* Mapped */1,
                _0: {
                  archive: {
                    categories: init$9.categories,
                    destinations: Curry._2(TrackingPlanMappedModel.Destinations.remove, model$246.archive.destinations, destinationId$18),
                    events: init$9.events,
                    groupTypes: init$9.groupTypes,
                    metrics: init$9.metrics,
                    properties: init$9.properties,
                    propertyBundles: init$9.propertyBundles,
                    sources: init$9.sources,
                    eventVariants: init$9.eventVariants
                  },
                  branchPointer: model$246.branchPointer,
                  migrations: model$246.migrations,
                  rules: model$246.rules,
                  types: model$246.types,
                  categories: model$246.categories,
                  destinations: Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.Destinations.get, model$246.archive.destinations, destinationId$18), model$246.destinations, Curry._1(TrackingPlanMappedModel.Destinations.append, model$246.destinations)),
                  events: model$246.events,
                  groupTypes: model$246.groupTypes,
                  integrations: model$246.integrations,
                  metrics: model$246.metrics,
                  openBranches: model$246.openBranches,
                  properties: model$246.properties,
                  propertyBundles: model$246.propertyBundles,
                  sources: model$246.sources,
                  globalRequirements: model$246.globalRequirements
                }
              };
      }
      if (variant$3 === "Integration") {
        return model;
      }
      if (variant$3 === "GroupType") {
        var groupTypeId$4 = item$2.VAL;
        if (model.TAG === /* Old */0) {
          var model$247 = model._0;
          var init$10 = model$247.archive;
          return {
                  TAG: /* Old */0,
                  _0: {
                    types: model$247.types,
                    properties: model$247.properties,
                    propertyBundles: model$247.propertyBundles,
                    events: model$247.events,
                    migrations: model$247.migrations,
                    sources: model$247.sources,
                    destinations: model$247.destinations,
                    groupTypes: Belt_Option.mapWithDefault(Belt_Array.getByU(model$247.archive.groupTypes, (function (groupType) {
                                return groupType.id === groupTypeId$4;
                              })), model$247.groupTypes, (function (groupType) {
                            return Belt_Array.concat(model$247.groupTypes, [groupType]);
                          })),
                    goals: model$247.goals,
                    metrics: model$247.metrics,
                    archive: {
                      events: init$10.events,
                      properties: init$10.properties,
                      propertyBundles: init$10.propertyBundles,
                      sources: init$10.sources,
                      destinations: init$10.destinations,
                      goals: init$10.goals,
                      metrics: init$10.metrics,
                      groupTypes: Belt_Array.keepU(model$247.archive.groupTypes, (function (groupType) {
                              return groupType.id !== groupTypeId$4;
                            })),
                      eventVariants: init$10.eventVariants
                    },
                    openBranches: model$247.openBranches,
                    branchPointer: model$247.branchPointer,
                    rules: model$247.rules,
                    integrations: model$247.integrations,
                    globalRequirements: model$247.globalRequirements
                  }
                };
        }
        var model$248 = model._0;
        var init$11 = model$248.archive;
        return {
                TAG: /* Mapped */1,
                _0: {
                  archive: {
                    categories: init$11.categories,
                    destinations: init$11.destinations,
                    events: init$11.events,
                    groupTypes: Curry._2(TrackingPlanMappedModel.GroupTypes.remove, model$248.archive.groupTypes, groupTypeId$4),
                    metrics: init$11.metrics,
                    properties: init$11.properties,
                    propertyBundles: init$11.propertyBundles,
                    sources: init$11.sources,
                    eventVariants: init$11.eventVariants
                  },
                  branchPointer: model$248.branchPointer,
                  migrations: model$248.migrations,
                  rules: model$248.rules,
                  types: model$248.types,
                  categories: model$248.categories,
                  destinations: model$248.destinations,
                  events: model$248.events,
                  groupTypes: Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.GroupTypes.get, model$248.archive.groupTypes, groupTypeId$4), model$248.groupTypes, Curry._1(TrackingPlanMappedModel.GroupTypes.append, model$248.groupTypes)),
                  integrations: model$248.integrations,
                  metrics: model$248.metrics,
                  openBranches: model$248.openBranches,
                  properties: model$248.properties,
                  propertyBundles: model$248.propertyBundles,
                  sources: model$248.sources,
                  globalRequirements: model$248.globalRequirements
                }
              };
      }
      if (variant$3 === "Property") {
        var propertyId$55 = item$2.VAL;
        if (model.TAG === /* Old */0) {
          var model$249 = model._0;
          var init$12 = model$249.archive;
          return {
                  TAG: /* Old */0,
                  _0: {
                    types: model$249.types,
                    properties: Belt_Option.mapWithDefault(Belt_List.getByU(model$249.archive.properties, (function (property) {
                                if (property.TAG === /* PropertyRef */0) {
                                  return false;
                                } else {
                                  return property._0.id === propertyId$55;
                                }
                              })), model$249.properties, (function (property) {
                            return Belt_List.concat(model$249.properties, {
                                        hd: property,
                                        tl: /* [] */0
                                      });
                          })),
                    propertyBundles: model$249.propertyBundles,
                    events: model$249.events,
                    migrations: model$249.migrations,
                    sources: model$249.sources,
                    destinations: model$249.destinations,
                    groupTypes: model$249.groupTypes,
                    goals: model$249.goals,
                    metrics: model$249.metrics,
                    archive: {
                      events: init$12.events,
                      properties: Belt_List.keepU(model$249.archive.properties, (function (property) {
                              if (property.TAG === /* PropertyRef */0) {
                                return true;
                              } else {
                                return property._0.id !== propertyId$55;
                              }
                            })),
                      propertyBundles: init$12.propertyBundles,
                      sources: init$12.sources,
                      destinations: init$12.destinations,
                      goals: init$12.goals,
                      metrics: init$12.metrics,
                      groupTypes: init$12.groupTypes,
                      eventVariants: init$12.eventVariants
                    },
                    openBranches: model$249.openBranches,
                    branchPointer: model$249.branchPointer,
                    rules: model$249.rules,
                    integrations: model$249.integrations,
                    globalRequirements: model$249.globalRequirements
                  }
                };
        }
        var model$250 = model._0;
        var init$13 = model$250.archive;
        return {
                TAG: /* Mapped */1,
                _0: {
                  archive: {
                    categories: init$13.categories,
                    destinations: init$13.destinations,
                    events: init$13.events,
                    groupTypes: init$13.groupTypes,
                    metrics: init$13.metrics,
                    properties: Curry._2(TrackingPlanMappedModel.Properties.remove, model$250.archive.properties, propertyId$55),
                    propertyBundles: init$13.propertyBundles,
                    sources: init$13.sources,
                    eventVariants: init$13.eventVariants
                  },
                  branchPointer: model$250.branchPointer,
                  migrations: model$250.migrations,
                  rules: model$250.rules,
                  types: model$250.types,
                  categories: model$250.categories,
                  destinations: model$250.destinations,
                  events: model$250.events,
                  groupTypes: model$250.groupTypes,
                  integrations: model$250.integrations,
                  metrics: model$250.metrics,
                  openBranches: model$250.openBranches,
                  properties: Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.Properties.get, model$250.archive.properties, propertyId$55), model$250.properties, (function (property) {
                          return Curry._2(TrackingPlanMappedModel.Properties.append, model$250.properties, property);
                        })),
                  propertyBundles: model$250.propertyBundles,
                  sources: model$250.sources,
                  globalRequirements: model$250.globalRequirements
                }
              };
      }
      if (variant$3 === "Event") {
        var eventId$50 = item$2.VAL;
        if (model.TAG === /* Old */0) {
          var model$251 = model._0;
          var init$14 = model$251.archive;
          return {
                  TAG: /* Old */0,
                  _0: {
                    types: model$251.types,
                    properties: model$251.properties,
                    propertyBundles: model$251.propertyBundles,
                    events: Belt_Option.mapWithDefault(Belt_List.getByU(model$251.archive.events, (function ($$event) {
                                return $$event.id === eventId$50;
                              })), model$251.events, (function ($$event) {
                            return Belt_List.concat(model$251.events, {
                                        hd: $$event,
                                        tl: /* [] */0
                                      });
                          })),
                    migrations: model$251.migrations,
                    sources: model$251.sources,
                    destinations: model$251.destinations,
                    groupTypes: model$251.groupTypes,
                    goals: model$251.goals,
                    metrics: model$251.metrics,
                    archive: {
                      events: Belt_List.keepU(model$251.archive.events, (function ($$event) {
                              return $$event.id !== eventId$50;
                            })),
                      properties: init$14.properties,
                      propertyBundles: init$14.propertyBundles,
                      sources: init$14.sources,
                      destinations: init$14.destinations,
                      goals: init$14.goals,
                      metrics: init$14.metrics,
                      groupTypes: init$14.groupTypes,
                      eventVariants: init$14.eventVariants
                    },
                    openBranches: model$251.openBranches,
                    branchPointer: model$251.branchPointer,
                    rules: model$251.rules,
                    integrations: model$251.integrations,
                    globalRequirements: model$251.globalRequirements
                  }
                };
        }
        var model$252 = model._0;
        var init$15 = model$252.archive;
        return {
                TAG: /* Mapped */1,
                _0: {
                  archive: {
                    categories: init$15.categories,
                    destinations: init$15.destinations,
                    events: Curry._2(TrackingPlanMappedModel.Events.remove, model$252.archive.events, eventId$50),
                    groupTypes: init$15.groupTypes,
                    metrics: init$15.metrics,
                    properties: init$15.properties,
                    propertyBundles: init$15.propertyBundles,
                    sources: init$15.sources,
                    eventVariants: init$15.eventVariants
                  },
                  branchPointer: model$252.branchPointer,
                  migrations: model$252.migrations,
                  rules: model$252.rules,
                  types: model$252.types,
                  categories: model$252.categories,
                  destinations: model$252.destinations,
                  events: Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.Events.get, model$252.archive.events, eventId$50), model$252.events, Curry._1(TrackingPlanMappedModel.Events.append, model$252.events)),
                  groupTypes: model$252.groupTypes,
                  integrations: model$252.integrations,
                  metrics: model$252.metrics,
                  openBranches: model$252.openBranches,
                  properties: model$252.properties,
                  propertyBundles: model$252.propertyBundles,
                  sources: model$252.sources,
                  globalRequirements: model$252.globalRequirements
                }
              };
      }
      if (variant$3 === "PropertyGroup") {
        var propertyBundleId$1 = item$2.VAL;
        if (model.TAG === /* Old */0) {
          var model$253 = model._0;
          var init$16 = model$253.archive;
          return {
                  TAG: /* Old */0,
                  _0: {
                    types: model$253.types,
                    properties: model$253.properties,
                    propertyBundles: Belt_Option.mapWithDefault(Belt_List.getByU(model$253.archive.propertyBundles, (function (group) {
                                return group.id === propertyBundleId$1;
                              })), model$253.propertyBundles, (function (group) {
                            return Belt_List.concat(model$253.propertyBundles, {
                                        hd: group,
                                        tl: /* [] */0
                                      });
                          })),
                    events: model$253.events,
                    migrations: model$253.migrations,
                    sources: model$253.sources,
                    destinations: model$253.destinations,
                    groupTypes: model$253.groupTypes,
                    goals: model$253.goals,
                    metrics: model$253.metrics,
                    archive: {
                      events: init$16.events,
                      properties: init$16.properties,
                      propertyBundles: Belt_List.keepU(model$253.archive.propertyBundles, (function (group) {
                              return group.id !== propertyBundleId$1;
                            })),
                      sources: init$16.sources,
                      destinations: init$16.destinations,
                      goals: init$16.goals,
                      metrics: init$16.metrics,
                      groupTypes: init$16.groupTypes,
                      eventVariants: init$16.eventVariants
                    },
                    openBranches: model$253.openBranches,
                    branchPointer: model$253.branchPointer,
                    rules: model$253.rules,
                    integrations: model$253.integrations,
                    globalRequirements: model$253.globalRequirements
                  }
                };
        }
        var model$254 = model._0;
        var init$17 = model$254.archive;
        return {
                TAG: /* Mapped */1,
                _0: {
                  archive: {
                    categories: init$17.categories,
                    destinations: init$17.destinations,
                    events: init$17.events,
                    groupTypes: init$17.groupTypes,
                    metrics: init$17.metrics,
                    properties: init$17.properties,
                    propertyBundles: Curry._2(TrackingPlanMappedModel.PropertyBundles.remove, model$254.archive.propertyBundles, propertyBundleId$1),
                    sources: init$17.sources,
                    eventVariants: init$17.eventVariants
                  },
                  branchPointer: model$254.branchPointer,
                  migrations: model$254.migrations,
                  rules: model$254.rules,
                  types: model$254.types,
                  categories: model$254.categories,
                  destinations: model$254.destinations,
                  events: model$254.events,
                  groupTypes: model$254.groupTypes,
                  integrations: model$254.integrations,
                  metrics: model$254.metrics,
                  openBranches: model$254.openBranches,
                  properties: model$254.properties,
                  propertyBundles: Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.PropertyBundles.get, model$254.archive.propertyBundles, propertyBundleId$1), model$254.propertyBundles, Curry._1(TrackingPlanMappedModel.PropertyBundles.append, model$254.propertyBundles)),
                  sources: model$254.sources,
                  globalRequirements: model$254.globalRequirements
                }
              };
      }
      if (variant$3 === "Goal") {
        var categoryId$1 = item$2.VAL;
        if (model.TAG === /* Old */0) {
          var model$255 = model._0;
          var init$18 = model$255.archive;
          return {
                  TAG: /* Old */0,
                  _0: {
                    types: model$255.types,
                    properties: model$255.properties,
                    propertyBundles: model$255.propertyBundles,
                    events: model$255.events,
                    migrations: model$255.migrations,
                    sources: model$255.sources,
                    destinations: model$255.destinations,
                    groupTypes: model$255.groupTypes,
                    goals: Belt_Option.mapWithDefault(Belt_List.getByU(model$255.archive.goals, (function (goal) {
                                return goal.id === categoryId$1;
                              })), model$255.goals, (function (goal) {
                            return Belt_List.concat(model$255.goals, {
                                        hd: goal,
                                        tl: /* [] */0
                                      });
                          })),
                    metrics: model$255.metrics,
                    archive: {
                      events: init$18.events,
                      properties: init$18.properties,
                      propertyBundles: init$18.propertyBundles,
                      sources: init$18.sources,
                      destinations: init$18.destinations,
                      goals: Belt_List.keepU(model$255.archive.goals, (function (goal) {
                              return goal.id !== categoryId$1;
                            })),
                      metrics: init$18.metrics,
                      groupTypes: init$18.groupTypes,
                      eventVariants: init$18.eventVariants
                    },
                    openBranches: model$255.openBranches,
                    branchPointer: model$255.branchPointer,
                    rules: model$255.rules,
                    integrations: model$255.integrations,
                    globalRequirements: model$255.globalRequirements
                  }
                };
        }
        var model$256 = model._0;
        var init$19 = model$256.archive;
        return {
                TAG: /* Mapped */1,
                _0: {
                  archive: {
                    categories: Curry._2(TrackingPlanMappedModel.Categories.remove, model$256.archive.categories, categoryId$1),
                    destinations: init$19.destinations,
                    events: init$19.events,
                    groupTypes: init$19.groupTypes,
                    metrics: init$19.metrics,
                    properties: init$19.properties,
                    propertyBundles: init$19.propertyBundles,
                    sources: init$19.sources,
                    eventVariants: init$19.eventVariants
                  },
                  branchPointer: model$256.branchPointer,
                  migrations: model$256.migrations,
                  rules: model$256.rules,
                  types: model$256.types,
                  categories: Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.Categories.get, model$256.archive.categories, categoryId$1), model$256.categories, Curry._1(TrackingPlanMappedModel.Categories.append, model$256.categories)),
                  destinations: model$256.destinations,
                  events: model$256.events,
                  groupTypes: model$256.groupTypes,
                  integrations: model$256.integrations,
                  metrics: model$256.metrics,
                  openBranches: model$256.openBranches,
                  properties: model$256.properties,
                  propertyBundles: model$256.propertyBundles,
                  sources: model$256.sources,
                  globalRequirements: model$256.globalRequirements
                }
              };
      }
      if (variant$3 === "EventVariant") {
        var variantIdentifier$13 = item$2.VAL;
        if (model.TAG === /* Old */0) {
          var model$257 = model._0;
          var archivedVariant = Belt_Array.getByU(model$257.archive.eventVariants, (function (param) {
                  return param.id === variantIdentifier$13.variantId;
                }));
          if (archivedVariant === undefined) {
            return {
                    TAG: /* Old */0,
                    _0: model$257
                  };
          }
          if (!Belt_List.someU(model$257.events, (function (param) {
                    return param.id === archivedVariant.baseEventId;
                  }))) {
            return {
                    TAG: /* Old */0,
                    _0: model$257
                  };
          }
          var init$20 = ModelUtils.updateEvent(model$257, archivedVariant.baseEventId, (function ($$event) {
                  return {
                          id: $$event.id,
                          name: $$event.name,
                          uniqueName: $$event.uniqueName,
                          description: $$event.description,
                          properties: $$event.properties,
                          propertyBundles: $$event.propertyBundles,
                          variants: Belt_Array.concat($$event.variants, [archivedVariant]),
                          types: $$event.types,
                          tags: $$event.tags,
                          excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                          includeSources: $$event.includeSources,
                          includeDestinations: $$event.includeDestinations,
                          hashes: $$event.hashes,
                          propertyWhitelist: $$event.propertyWhitelist,
                          eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                          userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                          triggers: $$event.triggers,
                          globalRequirementsMetadata: $$event.globalRequirementsMetadata
                        };
                }));
          var init$21 = model$257.archive;
          return {
                  TAG: /* Old */0,
                  _0: {
                    types: init$20.types,
                    properties: init$20.properties,
                    propertyBundles: init$20.propertyBundles,
                    events: init$20.events,
                    migrations: init$20.migrations,
                    sources: init$20.sources,
                    destinations: init$20.destinations,
                    groupTypes: init$20.groupTypes,
                    goals: init$20.goals,
                    metrics: init$20.metrics,
                    archive: {
                      events: init$21.events,
                      properties: init$21.properties,
                      propertyBundles: init$21.propertyBundles,
                      sources: init$21.sources,
                      destinations: init$21.destinations,
                      goals: init$21.goals,
                      metrics: init$21.metrics,
                      groupTypes: init$21.groupTypes,
                      eventVariants: Belt_Array.keepU(model$257.archive.eventVariants, (function (param) {
                              return param.id !== variantIdentifier$13.variantId;
                            }))
                    },
                    openBranches: init$20.openBranches,
                    branchPointer: init$20.branchPointer,
                    rules: init$20.rules,
                    integrations: init$20.integrations,
                    globalRequirements: init$20.globalRequirements
                  }
                };
        }
        var model$258 = model._0;
        var archivedVariant$1 = Curry._2(TrackingPlanMappedModel.EventVariants.get, model$258.archive.eventVariants, variantIdentifier$13.variantId);
        if (archivedVariant$1 === undefined) {
          return {
                  TAG: /* Mapped */1,
                  _0: model$258
                };
        }
        if (!Curry._2(TrackingPlanMappedModel.Events.has, model$258.events, archivedVariant$1.baseEventId)) {
          return {
                  TAG: /* Mapped */1,
                  _0: model$258
                };
        }
        var init$22 = model$258.archive;
        var updatedModel_archive = {
          categories: init$22.categories,
          destinations: init$22.destinations,
          events: init$22.events,
          groupTypes: init$22.groupTypes,
          metrics: init$22.metrics,
          properties: init$22.properties,
          propertyBundles: init$22.propertyBundles,
          sources: init$22.sources,
          eventVariants: Curry._2(TrackingPlanMappedModel.EventVariants.remove, model$258.archive.eventVariants, variantIdentifier$13.variantId)
        };
        var updatedModel_branchPointer = model$258.branchPointer;
        var updatedModel_migrations = model$258.migrations;
        var updatedModel_rules = model$258.rules;
        var updatedModel_types = model$258.types;
        var updatedModel_categories = model$258.categories;
        var updatedModel_destinations = model$258.destinations;
        var updatedModel_events = Curry._3(TrackingPlanMappedModel.Events.update, model$258.events, archivedVariant$1.baseEventId, (function ($$event) {
                return Belt_Option.map($$event, (function ($$event) {
                              return {
                                      id: $$event.id,
                                      name: $$event.name,
                                      uniqueName: $$event.uniqueName,
                                      description: $$event.description,
                                      properties: $$event.properties,
                                      propertyBundles: $$event.propertyBundles,
                                      variants: Belt_Array.concat($$event.variants, [archivedVariant$1]),
                                      types: $$event.types,
                                      tags: $$event.tags,
                                      excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                      includeSources: $$event.includeSources,
                                      includeDestinations: $$event.includeDestinations,
                                      hashes: $$event.hashes,
                                      propertyWhitelist: $$event.propertyWhitelist,
                                      eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                      userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                      triggers: $$event.triggers,
                                      globalRequirementsMetadata: $$event.globalRequirementsMetadata
                                    };
                            }));
              }));
        var updatedModel_groupTypes = model$258.groupTypes;
        var updatedModel_integrations = model$258.integrations;
        var updatedModel_metrics = model$258.metrics;
        var updatedModel_openBranches = model$258.openBranches;
        var updatedModel_properties = model$258.properties;
        var updatedModel_propertyBundles = model$258.propertyBundles;
        var updatedModel_sources = model$258.sources;
        var updatedModel_globalRequirements = model$258.globalRequirements;
        var updatedModel$2 = {
          archive: updatedModel_archive,
          branchPointer: updatedModel_branchPointer,
          migrations: updatedModel_migrations,
          rules: updatedModel_rules,
          types: updatedModel_types,
          categories: updatedModel_categories,
          destinations: updatedModel_destinations,
          events: updatedModel_events,
          groupTypes: updatedModel_groupTypes,
          integrations: updatedModel_integrations,
          metrics: updatedModel_metrics,
          openBranches: updatedModel_openBranches,
          properties: updatedModel_properties,
          propertyBundles: updatedModel_propertyBundles,
          sources: updatedModel_sources,
          globalRequirements: updatedModel_globalRequirements
        };
        return {
                TAG: /* Mapped */1,
                _0: updatedModel$2
              };
      }
      if (variant$3 === "Metric") {
        var metricId$21 = item$2.VAL;
        if (model.TAG === /* Old */0) {
          var model$259 = model._0;
          var init$23 = model$259.archive;
          return {
                  TAG: /* Old */0,
                  _0: {
                    types: model$259.types,
                    properties: model$259.properties,
                    propertyBundles: model$259.propertyBundles,
                    events: model$259.events,
                    migrations: model$259.migrations,
                    sources: model$259.sources,
                    destinations: model$259.destinations,
                    groupTypes: model$259.groupTypes,
                    goals: model$259.goals,
                    metrics: Belt_Option.mapWithDefault(Belt_List.getByU(model$259.archive.metrics, (function (metric) {
                                return metric.id === metricId$21;
                              })), model$259.metrics, (function (metric) {
                            return Belt_List.concat(model$259.metrics, {
                                        hd: metric,
                                        tl: /* [] */0
                                      });
                          })),
                    archive: {
                      events: init$23.events,
                      properties: init$23.properties,
                      propertyBundles: init$23.propertyBundles,
                      sources: init$23.sources,
                      destinations: init$23.destinations,
                      goals: init$23.goals,
                      metrics: Belt_List.keepU(model$259.archive.metrics, (function (metric) {
                              return metric.id !== metricId$21;
                            })),
                      groupTypes: init$23.groupTypes,
                      eventVariants: init$23.eventVariants
                    },
                    openBranches: model$259.openBranches,
                    branchPointer: model$259.branchPointer,
                    rules: model$259.rules,
                    integrations: model$259.integrations,
                    globalRequirements: model$259.globalRequirements
                  }
                };
        }
        var model$260 = model._0;
        var init$24 = model$260.archive;
        return {
                TAG: /* Mapped */1,
                _0: {
                  archive: {
                    categories: init$24.categories,
                    destinations: init$24.destinations,
                    events: init$24.events,
                    groupTypes: init$24.groupTypes,
                    metrics: Curry._2(TrackingPlanMappedModel.Metrics.remove, model$260.archive.metrics, metricId$21),
                    properties: init$24.properties,
                    propertyBundles: init$24.propertyBundles,
                    sources: init$24.sources,
                    eventVariants: init$24.eventVariants
                  },
                  branchPointer: model$260.branchPointer,
                  migrations: model$260.migrations,
                  rules: model$260.rules,
                  types: model$260.types,
                  categories: model$260.categories,
                  destinations: model$260.destinations,
                  events: model$260.events,
                  groupTypes: model$260.groupTypes,
                  integrations: model$260.integrations,
                  metrics: Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.Metrics.get, model$260.archive.metrics, metricId$21), model$260.metrics, Curry._1(TrackingPlanMappedModel.Metrics.append, model$260.metrics)),
                  openBranches: model$260.openBranches,
                  properties: model$260.properties,
                  propertyBundles: model$260.propertyBundles,
                  sources: model$260.sources,
                  globalRequirements: model$260.globalRequirements
                }
              };
      }
      var sourceId$33 = item$2.VAL;
      if (model.TAG === /* Old */0) {
        var model$261 = model._0;
        var init$25 = model$261.archive;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$261.types,
                  properties: model$261.properties,
                  propertyBundles: model$261.propertyBundles,
                  events: model$261.events,
                  migrations: model$261.migrations,
                  sources: Belt_Option.mapWithDefault(Belt_List.getByU(model$261.archive.sources, (function (source) {
                              return source.id === sourceId$33;
                            })), model$261.sources, (function (source) {
                          return Belt_List.concat(model$261.sources, {
                                      hd: source,
                                      tl: /* [] */0
                                    });
                        })),
                  destinations: model$261.destinations,
                  groupTypes: model$261.groupTypes,
                  goals: model$261.goals,
                  metrics: model$261.metrics,
                  archive: {
                    events: init$25.events,
                    properties: init$25.properties,
                    propertyBundles: init$25.propertyBundles,
                    sources: Belt_List.keepU(model$261.archive.sources, (function (source) {
                            return source.id !== sourceId$33;
                          })),
                    destinations: init$25.destinations,
                    goals: init$25.goals,
                    metrics: init$25.metrics,
                    groupTypes: init$25.groupTypes,
                    eventVariants: init$25.eventVariants
                  },
                  openBranches: model$261.openBranches,
                  branchPointer: model$261.branchPointer,
                  rules: model$261.rules,
                  integrations: model$261.integrations,
                  globalRequirements: model$261.globalRequirements
                }
              };
      }
      var model$262 = model._0;
      var init$26 = model$262.archive;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: {
                  categories: init$26.categories,
                  destinations: init$26.destinations,
                  events: init$26.events,
                  groupTypes: init$26.groupTypes,
                  metrics: init$26.metrics,
                  properties: init$26.properties,
                  propertyBundles: init$26.propertyBundles,
                  sources: Curry._2(TrackingPlanMappedModel.Sources.remove, model$262.archive.sources, sourceId$33),
                  eventVariants: init$26.eventVariants
                },
                branchPointer: model$262.branchPointer,
                migrations: model$262.migrations,
                rules: model$262.rules,
                types: model$262.types,
                categories: model$262.categories,
                destinations: model$262.destinations,
                events: model$262.events,
                groupTypes: model$262.groupTypes,
                integrations: model$262.integrations,
                metrics: model$262.metrics,
                openBranches: model$262.openBranches,
                properties: model$262.properties,
                propertyBundles: model$262.propertyBundles,
                sources: Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.Sources.get, model$262.archive.sources, sourceId$33), model$262.sources, Curry._1(TrackingPlanMappedModel.Sources.append, model$262.sources)),
                globalRequirements: model$262.globalRequirements
              }
            };
    }
    if (variant === "ClearEventVariantSpecificPropertyValueOverride") {
      var match$162 = action.VAL;
      var literal$7 = match$162[2];
      var propertyId$56 = match$162[1];
      var variantIdentifier$14 = match$162[0];
      var literalString = typeof literal$7 === "object" && literal$7.NAME === "StringLit" ? literal$7.VAL : Pervasives.failwith("Only string literals are supported");
      var update$15 = function (variant) {
        return {
                id: variant.id,
                baseEventId: variant.baseEventId,
                nameSuffix: variant.nameSuffix,
                description: variant.description,
                propertyOverrides: Belt_MapString.update(variant.propertyOverrides, propertyId$56, (function (override) {
                        if (override === undefined) {
                          return /* Overridden */{
                                  _0: {
                                    pinnedValue: /* NoneOverride */0,
                                    absence: /* NoneOverride */0,
                                    allowedValues: /* NoneOverride */0,
                                    regexValidation: /* NoneOverride */0
                                  }
                                };
                        }
                        if (!override) {
                          return /* Removed */0;
                        }
                        var override$1 = override._0;
                        var allowedValues = override$1.allowedValues;
                        return /* Overridden */{
                                _0: {
                                  pinnedValue: override$1.pinnedValue,
                                  absence: override$1.absence,
                                  allowedValues: allowedValues ? /* SomeOverride */({
                                        _0: Belt_MapString.remove(allowedValues._0, literalString)
                                      }) : /* NoneOverride */0,
                                  regexValidation: override$1.regexValidation
                                }
                              };
                      })),
                sourceOverrides: variant.sourceOverrides,
                triggers: variant.triggers
              };
      };
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEventVariant(model._0, variantIdentifier$14, update$15)
              };
      }
      var model$263 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$263.archive,
                branchPointer: model$263.branchPointer,
                migrations: model$263.migrations,
                rules: model$263.rules,
                types: model$263.types,
                categories: model$263.categories,
                destinations: model$263.destinations,
                events: ModelUtils_mapped.updateExistingVariant(model$263.events, variantIdentifier$14, update$15),
                groupTypes: model$263.groupTypes,
                integrations: model$263.integrations,
                metrics: model$263.metrics,
                openBranches: model$263.openBranches,
                properties: model$263.properties,
                propertyBundles: model$263.propertyBundles,
                sources: model$263.sources,
                globalRequirements: model$263.globalRequirements
              }
            };
    }
    if (variant === "UpdatePropertyPinnedValueOnEventVariant") {
      var match$163 = action.VAL;
      var literal$8 = match$163[2];
      var propertyId$57 = match$163[1];
      var id$16 = match$163[0];
      var update$16 = function (variant) {
        return {
                id: variant.id,
                baseEventId: variant.baseEventId,
                nameSuffix: variant.nameSuffix,
                description: variant.description,
                propertyOverrides: Belt_MapString.update(variant.propertyOverrides, propertyId$57, (function (override) {
                        if (override === undefined) {
                          return /* Overridden */{
                                  _0: {
                                    pinnedValue: /* SomeOverride */{
                                      _0: literal$8
                                    },
                                    absence: /* NoneOverride */0,
                                    allowedValues: /* NoneOverride */0,
                                    regexValidation: /* NoneOverride */0
                                  }
                                };
                        }
                        if (!override) {
                          return /* Removed */0;
                        }
                        var override$1 = override._0;
                        return /* Overridden */{
                                _0: {
                                  pinnedValue: /* SomeOverride */{
                                    _0: literal$8
                                  },
                                  absence: override$1.absence,
                                  allowedValues: override$1.allowedValues,
                                  regexValidation: override$1.regexValidation
                                }
                              };
                      })),
                sourceOverrides: variant.sourceOverrides,
                triggers: variant.triggers
              };
      };
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEventVariant(model._0, id$16, update$16)
              };
      }
      var model$264 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$264.archive,
                branchPointer: model$264.branchPointer,
                migrations: model$264.migrations,
                rules: model$264.rules,
                types: model$264.types,
                categories: model$264.categories,
                destinations: model$264.destinations,
                events: ModelUtils_mapped.updateExistingVariant(model$264.events, id$16, update$16),
                groupTypes: model$264.groupTypes,
                integrations: model$264.integrations,
                metrics: model$264.metrics,
                openBranches: model$264.openBranches,
                properties: model$264.properties,
                propertyBundles: model$264.propertyBundles,
                sources: model$264.sources,
                globalRequirements: model$264.globalRequirements
              }
            };
    }
    if (variant === "RemoveSourceConfig") {
      var match$164 = action.VAL;
      var sourceConfig$1 = match$164[1];
      var sourceId$34 = match$164[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateSource(model._0, sourceId$34, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: source.language,
                                platform: source.platform,
                                destinations: source.destinations,
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: Belt_List.keep(source.config, (function (config) {
                                        return config !== sourceConfig$1;
                                      }))
                              };
                      }))
              };
      }
      var model$265 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$265.archive,
                branchPointer: model$265.branchPointer,
                migrations: model$265.migrations,
                rules: model$265.rules,
                types: model$265.types,
                categories: model$265.categories,
                destinations: model$265.destinations,
                events: model$265.events,
                groupTypes: model$265.groupTypes,
                integrations: model$265.integrations,
                metrics: model$265.metrics,
                openBranches: model$265.openBranches,
                properties: model$265.properties,
                propertyBundles: model$265.propertyBundles,
                sources: Curry._3(TrackingPlanMappedModel.Sources.updateExisting, model$265.sources, sourceId$34, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: source.language,
                                platform: source.platform,
                                destinations: source.destinations,
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: Belt_List.keep(source.config, (function (config) {
                                        return config !== sourceConfig$1;
                                      }))
                              };
                      })),
                globalRequirements: model$265.globalRequirements
              }
            };
    }
    if (variant === "AddSourceSegmentIntegration") {
      var match$165 = action.VAL;
      var isActive$4 = match$165[2];
      var segmentIntegration$2 = match$165[1];
      var sourceId$35 = match$165[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateSource(model._0, sourceId$35, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: source.language,
                                platform: source.platform,
                                destinations: source.destinations,
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: Belt_List.some(source.segmentDestinationOptions, (function (param) {
                                        return param[0] === segmentIntegration$2;
                                      })) ? source.segmentDestinationOptions : Belt_List.concat(source.segmentDestinationOptions, {
                                        hd: [
                                          segmentIntegration$2,
                                          isActive$4
                                        ],
                                        tl: /* [] */0
                                      }),
                                config: source.config
                              };
                      }))
              };
      }
      var model$266 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$266.archive,
                branchPointer: model$266.branchPointer,
                migrations: model$266.migrations,
                rules: model$266.rules,
                types: model$266.types,
                categories: model$266.categories,
                destinations: model$266.destinations,
                events: model$266.events,
                groupTypes: model$266.groupTypes,
                integrations: model$266.integrations,
                metrics: model$266.metrics,
                openBranches: model$266.openBranches,
                properties: model$266.properties,
                propertyBundles: model$266.propertyBundles,
                sources: Curry._3(TrackingPlanMappedModel.Sources.updateExisting, model$266.sources, sourceId$35, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: source.language,
                                platform: source.platform,
                                destinations: source.destinations,
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: Belt_List.some(source.segmentDestinationOptions, (function (param) {
                                        return param[0] === segmentIntegration$2;
                                      })) ? source.segmentDestinationOptions : Belt_List.concat(source.segmentDestinationOptions, {
                                        hd: [
                                          segmentIntegration$2,
                                          isActive$4
                                        ],
                                        tl: /* [] */0
                                      }),
                                config: source.config
                              };
                      })),
                globalRequirements: model$266.globalRequirements
              }
            };
    }
    if (variant === "RemoveRule") {
      var ruleId$2 = action.VAL[0];
      if (model.TAG === /* Old */0) {
        var model$267 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$267.types,
                  properties: model$267.properties,
                  propertyBundles: model$267.propertyBundles,
                  events: model$267.events,
                  migrations: model$267.migrations,
                  sources: model$267.sources,
                  destinations: model$267.destinations,
                  groupTypes: model$267.groupTypes,
                  goals: model$267.goals,
                  metrics: model$267.metrics,
                  archive: model$267.archive,
                  openBranches: model$267.openBranches,
                  branchPointer: model$267.branchPointer,
                  rules: Belt_List.keepU(model$267.rules, (function (rule) {
                          return rule.id !== ruleId$2;
                        })),
                  integrations: model$267.integrations,
                  globalRequirements: model$267.globalRequirements
                }
              };
      }
      var model$268 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$268.archive,
                branchPointer: model$268.branchPointer,
                migrations: model$268.migrations,
                rules: Belt_Array.keepU(model$268.rules, (function (rule) {
                        return rule.id !== ruleId$2;
                      })),
                types: model$268.types,
                categories: model$268.categories,
                destinations: model$268.destinations,
                events: model$268.events,
                groupTypes: model$268.groupTypes,
                integrations: model$268.integrations,
                metrics: model$268.metrics,
                openBranches: model$268.openBranches,
                properties: model$268.properties,
                propertyBundles: model$268.propertyBundles,
                sources: model$268.sources,
                globalRequirements: model$268.globalRequirements
              }
            };
    }
    if (variant === "AddPropertyGroupToEvent") {
      var match$166 = action.VAL;
      var groupId$6 = match$166[1];
      var eventId$51 = match$166[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: ModelUtils.updateEvent(model._0, eventId$51, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: Belt_List.someU($$event.propertyBundles, (function (group) {
                                        return group.id === groupId$6;
                                      })) ? $$event.propertyBundles : Belt_List.concat($$event.propertyBundles, {
                                        hd: {
                                          id: groupId$6,
                                          description: ""
                                        },
                                        tl: /* [] */0
                                      }),
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      }))
              };
      }
      var model$269 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$269.archive,
                branchPointer: model$269.branchPointer,
                migrations: model$269.migrations,
                rules: model$269.rules,
                types: model$269.types,
                categories: model$269.categories,
                destinations: model$269.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.updateExisting, model$269.events, eventId$51, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: Belt_List.someU($$event.propertyBundles, (function (group) {
                                        return group.id === groupId$6;
                                      })) ? $$event.propertyBundles : Belt_List.concat($$event.propertyBundles, {
                                        hd: {
                                          id: groupId$6,
                                          description: ""
                                        },
                                        tl: /* [] */0
                                      }),
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      })),
                groupTypes: model$269.groupTypes,
                integrations: model$269.integrations,
                metrics: model$269.metrics,
                openBranches: model$269.openBranches,
                properties: model$269.properties,
                propertyBundles: model$269.propertyBundles,
                sources: model$269.sources,
                globalRequirements: model$269.globalRequirements
              }
            };
    }
    var metricId$22 = action.VAL;
    if (model.TAG === /* Old */0) {
      var model$270 = model._0;
      return {
              TAG: /* Old */0,
              _0: {
                types: model$270.types,
                properties: model$270.properties,
                propertyBundles: model$270.propertyBundles,
                events: model$270.events,
                migrations: model$270.migrations,
                sources: model$270.sources,
                destinations: model$270.destinations,
                groupTypes: model$270.groupTypes,
                goals: Belt_List.mapU(model$270.goals, (function (goal) {
                        return {
                                id: goal.id,
                                name: goal.name,
                                description: goal.description,
                                metrics: Belt_List.keepU(goal.metrics, (function (id) {
                                        return id !== metricId$22;
                                      })),
                                events: goal.events
                              };
                      })),
                metrics: Belt_List.keepU(model$270.metrics, (function (metric) {
                        return metric.id !== metricId$22;
                      })),
                archive: model$270.archive,
                openBranches: model$270.openBranches,
                branchPointer: model$270.branchPointer,
                rules: model$270.rules,
                integrations: model$270.integrations,
                globalRequirements: model$270.globalRequirements
              }
            };
    }
    var model$271 = model._0;
    return {
            TAG: /* Mapped */1,
            _0: {
              archive: model$271.archive,
              branchPointer: model$271.branchPointer,
              migrations: model$271.migrations,
              rules: model$271.rules,
              types: model$271.types,
              categories: Curry._2(TrackingPlanMappedModel.Categories.mapToT, model$271.categories, (function (category) {
                      return {
                              id: category.id,
                              name: category.name,
                              description: category.description,
                              metrics: Belt_List.keepU(category.metrics, (function (id) {
                                      return id !== metricId$22;
                                    })),
                              events: category.events
                            };
                    })),
              destinations: model$271.destinations,
              events: model$271.events,
              groupTypes: model$271.groupTypes,
              integrations: model$271.integrations,
              metrics: Curry._2(TrackingPlanMappedModel.Metrics.keep, model$271.metrics, (function (metric) {
                      return metric.id !== metricId$22;
                    })),
              openBranches: model$271.openBranches,
              properties: model$271.properties,
              propertyBundles: model$271.propertyBundles,
              sources: model$271.sources,
              globalRequirements: model$271.globalRequirements
            }
          };
  }
  if (action === "SchemaAccessed" || action === "StartedImport") {
    return model;
  }
  if (action === "MigratePropertyOptionalAndExcludedSourcesToAbsence") {
    if (model.TAG === /* Old */0) {
      var model$272 = model._0;
      return {
              TAG: /* Old */0,
              _0: ModelUtils.hasMigrated(model$272, "PropertyOptionalAndExcludedSourcesToAbsence") ? model$272 : ({
                    types: model$272.types,
                    properties: Belt_List.mapU(model$272.properties, (function (property) {
                            if (property.TAG === /* PropertyRef */0) {
                              return property;
                            }
                            var property$1 = property._0;
                            return {
                                    TAG: /* Property */1,
                                    _0: {
                                      id: property$1.id,
                                      name: property$1.name,
                                      uniqueName: property$1.uniqueName,
                                      description: property$1.description,
                                      type_: property$1.type_,
                                      sendAs: property$1.sendAs,
                                      validations: property$1.validations,
                                      eventSpecificAllowedPropertyValues: property$1.eventSpecificAllowedPropertyValues,
                                      auto: property$1.auto,
                                      builtIn: property$1.builtIn,
                                      analyticsTools: property$1.analyticsTools,
                                      devPlatforms: property$1.devPlatforms,
                                      list: property$1.list,
                                      operation: property$1.operation,
                                      optionalDeprecated: property$1.optionalDeprecated,
                                      excludedSourcesDeprecated: property$1.excludedSourcesDeprecated,
                                      optionalWhenInObject: property$1.optionalDeprecated,
                                      absence: ModelUtils.makePropertyAbsenceFromOptionalAndExcludedSources(property$1, model$272),
                                      globalRequirementsMetadata: property$1.globalRequirementsMetadata
                                    }
                                  };
                          })),
                    propertyBundles: model$272.propertyBundles,
                    events: model$272.events,
                    migrations: Belt_List.add(model$272.migrations, "PropertyOptionalAndExcludedSourcesToAbsence"),
                    sources: model$272.sources,
                    destinations: model$272.destinations,
                    groupTypes: model$272.groupTypes,
                    goals: model$272.goals,
                    metrics: model$272.metrics,
                    archive: model$272.archive,
                    openBranches: model$272.openBranches,
                    branchPointer: model$272.branchPointer,
                    rules: model$272.rules,
                    integrations: model$272.integrations,
                    globalRequirements: model$272.globalRequirements
                  })
            };
    }
    var model$273 = model._0;
    return {
            TAG: /* Mapped */1,
            _0: ModelUtils.hasMigrated_boxed(model, "PropertyOptionalAndExcludedSourcesToAbsence") ? model$273 : ({
                  archive: model$273.archive,
                  branchPointer: model$273.branchPointer,
                  migrations: Belt_Array.concat(["PropertyOptionalAndExcludedSourcesToAbsence"], model$273.migrations),
                  rules: model$273.rules,
                  types: model$273.types,
                  categories: model$273.categories,
                  destinations: model$273.destinations,
                  events: model$273.events,
                  groupTypes: model$273.groupTypes,
                  integrations: model$273.integrations,
                  metrics: model$273.metrics,
                  openBranches: model$273.openBranches,
                  properties: Curry._2(TrackingPlanMappedModel.Properties.mapToT, model$273.properties, (function (property) {
                          return {
                                  id: property.id,
                                  name: property.name,
                                  uniqueName: property.uniqueName,
                                  description: property.description,
                                  type_: property.type_,
                                  sendAs: property.sendAs,
                                  validations: property.validations,
                                  eventSpecificAllowedPropertyValues: property.eventSpecificAllowedPropertyValues,
                                  auto: property.auto,
                                  builtIn: property.builtIn,
                                  analyticsTools: property.analyticsTools,
                                  devPlatforms: property.devPlatforms,
                                  list: property.list,
                                  operation: property.operation,
                                  optionalDeprecated: property.optionalDeprecated,
                                  excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                  optionalWhenInObject: property.optionalDeprecated,
                                  absence: ModelUtils_mapped.makePropertyAbsenceFromOptionalAndExcludedSources(property, model$273),
                                  globalRequirementsMetadata: property.globalRequirementsMetadata
                                };
                        })),
                  propertyBundles: model$273.propertyBundles,
                  sources: model$273.sources,
                  globalRequirements: model$273.globalRequirements
                })
          };
  }
  if (action === "MigrateToEventSpecificAllowedPropertyValues") {
    if (model.TAG === /* Old */0) {
      var model$274 = model._0;
      var migrateProperties = function (properties) {
        return Belt_List.mapU(properties, (function (property) {
                      if (property.TAG === /* PropertyRef */0) {
                        return property;
                      }
                      var property$1 = property._0;
                      return {
                              TAG: /* Property */1,
                              _0: {
                                id: property$1.id,
                                name: property$1.name,
                                uniqueName: property$1.uniqueName,
                                description: property$1.description,
                                type_: property$1.type_,
                                sendAs: property$1.sendAs,
                                validations: property$1.validations,
                                eventSpecificAllowedPropertyValues: ConvertPropertyValidationMatchesToEventSpecificAllowedValues.convertWithSlowModel(model$274, property$1, property$1.validations),
                                auto: property$1.auto,
                                builtIn: property$1.builtIn,
                                analyticsTools: property$1.analyticsTools,
                                devPlatforms: property$1.devPlatforms,
                                list: property$1.list,
                                operation: property$1.operation,
                                optionalDeprecated: property$1.optionalDeprecated,
                                excludedSourcesDeprecated: property$1.excludedSourcesDeprecated,
                                optionalWhenInObject: property$1.optionalWhenInObject,
                                absence: property$1.absence,
                                globalRequirementsMetadata: property$1.globalRequirementsMetadata
                              }
                            };
                    }));
      };
      var init$27 = model$274.archive;
      return {
              TAG: /* Old */0,
              _0: {
                types: model$274.types,
                properties: migrateProperties(model$274.properties),
                propertyBundles: model$274.propertyBundles,
                events: model$274.events,
                migrations: {
                  hd: "EventSpecificAllowedPropertyValues",
                  tl: model$274.migrations
                },
                sources: model$274.sources,
                destinations: model$274.destinations,
                groupTypes: model$274.groupTypes,
                goals: model$274.goals,
                metrics: model$274.metrics,
                archive: {
                  events: init$27.events,
                  properties: migrateProperties(model$274.archive.properties),
                  propertyBundles: init$27.propertyBundles,
                  sources: init$27.sources,
                  destinations: init$27.destinations,
                  goals: init$27.goals,
                  metrics: init$27.metrics,
                  groupTypes: init$27.groupTypes,
                  eventVariants: init$27.eventVariants
                },
                openBranches: model$274.openBranches,
                branchPointer: model$274.branchPointer,
                rules: model$274.rules,
                integrations: model$274.integrations,
                globalRequirements: Belt_Option.map(model$274.globalRequirements, (function (globalRequirements) {
                        return {
                                metadata: globalRequirements.metadata,
                                events: globalRequirements.events,
                                properties: migrateProperties(globalRequirements.properties),
                                propertyBundles: globalRequirements.propertyBundles,
                                goals: globalRequirements.goals,
                                groupTypes: globalRequirements.groupTypes,
                                rules: globalRequirements.rules
                              };
                      }))
              }
            };
    }
    var model$275 = model._0;
    var migrateMappedProperties = function (properties) {
      return Curry._2(TrackingPlanMappedModel.Properties.mapToT, properties, (function (property) {
                    return {
                            id: property.id,
                            name: property.name,
                            uniqueName: property.uniqueName,
                            description: property.description,
                            type_: property.type_,
                            sendAs: property.sendAs,
                            validations: property.validations,
                            eventSpecificAllowedPropertyValues: ConvertPropertyValidationMatchesToEventSpecificAllowedValues.convertWithMappedModel(model$275, property, property.validations),
                            auto: property.auto,
                            builtIn: property.builtIn,
                            analyticsTools: property.analyticsTools,
                            devPlatforms: property.devPlatforms,
                            list: property.list,
                            operation: property.operation,
                            optionalDeprecated: property.optionalDeprecated,
                            excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                            optionalWhenInObject: property.optionalWhenInObject,
                            absence: property.absence,
                            globalRequirementsMetadata: property.globalRequirementsMetadata
                          };
                  }));
    };
    var init$28 = model$275.archive;
    return {
            TAG: /* Mapped */1,
            _0: {
              archive: {
                categories: init$28.categories,
                destinations: init$28.destinations,
                events: init$28.events,
                groupTypes: init$28.groupTypes,
                metrics: init$28.metrics,
                properties: migrateMappedProperties(model$275.archive.properties),
                propertyBundles: init$28.propertyBundles,
                sources: init$28.sources,
                eventVariants: init$28.eventVariants
              },
              branchPointer: model$275.branchPointer,
              migrations: Belt_Array.concat(["EventSpecificAllowedPropertyValues"], model$275.migrations),
              rules: model$275.rules,
              types: model$275.types,
              categories: model$275.categories,
              destinations: model$275.destinations,
              events: model$275.events,
              groupTypes: model$275.groupTypes,
              integrations: model$275.integrations,
              metrics: model$275.metrics,
              openBranches: model$275.openBranches,
              properties: migrateMappedProperties(model$275.properties),
              propertyBundles: model$275.propertyBundles,
              sources: model$275.sources,
              globalRequirements: model$275.globalRequirements
            }
          };
  }
  if (action === "MigrateSkipWebFnTagToIncludeInCodegen") {
    if (model.TAG === /* Old */0) {
      var model$276 = model._0;
      var webSourcesInWorkspace = Belt_List.keepMapU(model$276.sources, (function (source) {
              if (Caml_obj.caml_equal(source.platform, "Web")) {
                return source.id;
              }
              
            }));
      return {
              TAG: /* Old */0,
              _0: {
                types: model$276.types,
                properties: model$276.properties,
                propertyBundles: model$276.propertyBundles,
                events: Belt_List.mapU(model$276.events, (function ($$event) {
                        var skipWebFnTag = "skip-web-fn";
                        var hasSkipWebFnTag = Belt_List.someU($$event.tags, (function (tag) {
                                return tag === skipWebFnTag;
                              }));
                        if (hasSkipWebFnTag) {
                          return {
                                  id: $$event.id,
                                  name: $$event.name,
                                  uniqueName: $$event.uniqueName,
                                  description: $$event.description,
                                  properties: $$event.properties,
                                  propertyBundles: $$event.propertyBundles,
                                  variants: $$event.variants,
                                  types: $$event.types,
                                  tags: Belt_List.keepU($$event.tags, (function (tag) {
                                          return tag !== skipWebFnTag;
                                        })),
                                  excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                  includeSources: Belt_List.mapU($$event.includeSources, (function (includedSource) {
                                          if (Belt_List.has(webSourcesInWorkspace, includedSource.id, (function (prim0, prim1) {
                                                    return prim0 === prim1;
                                                  }))) {
                                            return {
                                                    id: includedSource.id,
                                                    includeInCodegen: false,
                                                    inspectorValidation: includedSource.inspectorValidation
                                                  };
                                          } else {
                                            return includedSource;
                                          }
                                        })),
                                  includeDestinations: $$event.includeDestinations,
                                  hashes: $$event.hashes,
                                  propertyWhitelist: $$event.propertyWhitelist,
                                  eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                  userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                  triggers: $$event.triggers,
                                  globalRequirementsMetadata: $$event.globalRequirementsMetadata
                                };
                        } else {
                          return $$event;
                        }
                      })),
                migrations: {
                  hd: "SkipWebFnTagToIncludeInCodegen",
                  tl: model$276.migrations
                },
                sources: model$276.sources,
                destinations: model$276.destinations,
                groupTypes: model$276.groupTypes,
                goals: model$276.goals,
                metrics: model$276.metrics,
                archive: model$276.archive,
                openBranches: model$276.openBranches,
                branchPointer: model$276.branchPointer,
                rules: model$276.rules,
                integrations: model$276.integrations,
                globalRequirements: model$276.globalRequirements
              }
            };
    }
    var model$277 = model._0;
    var webSourcesInWorkspace$1 = Curry._2(TrackingPlanMappedModel.Sources.keep, model$277.sources, (function (source) {
            return Caml_obj.caml_equal(source.platform, "Web");
          }));
    return {
            TAG: /* Mapped */1,
            _0: {
              archive: model$277.archive,
              branchPointer: model$277.branchPointer,
              migrations: Belt_Array.concat(["SkipWebFnTagToIncludeInCodegen"], model$277.migrations),
              rules: model$277.rules,
              types: model$277.types,
              categories: model$277.categories,
              destinations: model$277.destinations,
              events: Curry._2(TrackingPlanMappedModel.Events.mapToT, model$277.events, (function ($$event) {
                      var skipWebFnTag = "skip-web-fn";
                      var hasSkipWebFnTag = Belt_List.someU($$event.tags, (function (tag) {
                              return tag === skipWebFnTag;
                            }));
                      if (hasSkipWebFnTag) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: Belt_List.keepU($$event.tags, (function (tag) {
                                        return tag !== skipWebFnTag;
                                      })),
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: Belt_List.mapU($$event.includeSources, (function (includedSource) {
                                        if (Curry._2(TrackingPlanMappedModel.Sources.has, webSourcesInWorkspace$1, includedSource.id)) {
                                          return {
                                                  id: includedSource.id,
                                                  includeInCodegen: false,
                                                  inspectorValidation: includedSource.inspectorValidation
                                                };
                                        } else {
                                          return includedSource;
                                        }
                                      })),
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      } else {
                        return $$event;
                      }
                    })),
              groupTypes: model$277.groupTypes,
              integrations: model$277.integrations,
              metrics: model$277.metrics,
              openBranches: model$277.openBranches,
              properties: model$277.properties,
              propertyBundles: model$277.propertyBundles,
              sources: model$277.sources,
              globalRequirements: model$277.globalRequirements
            }
          };
  }
  if (action === "MigrateSourceDestinationMapping") {
    if (model.TAG === /* Old */0) {
      var model$278 = model._0;
      return {
              TAG: /* Old */0,
              _0: {
                types: model$278.types,
                properties: model$278.properties,
                propertyBundles: model$278.propertyBundles,
                events: Belt_List.mapU(model$278.events, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: Belt_List.keepMapU($$event.includeSources, (function (includedSource) {
                                        return Belt_Option.map(ModelUtils.getSourceById(includedSource.id, model$278), (function (source) {
                                                      return {
                                                              NAME: "IncludeSourceDestination",
                                                              VAL: [
                                                                includedSource.id,
                                                                ModelUtils.getSourceDestinationIds(source.destinations)
                                                              ]
                                                            };
                                                    }));
                                      })),
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers,
                                globalRequirementsMetadata: $$event.globalRequirementsMetadata
                              };
                      })),
                migrations: {
                  hd: "SourceDestinationMigration",
                  tl: model$278.migrations
                },
                sources: model$278.sources,
                destinations: model$278.destinations,
                groupTypes: model$278.groupTypes,
                goals: model$278.goals,
                metrics: model$278.metrics,
                archive: model$278.archive,
                openBranches: model$278.openBranches,
                branchPointer: model$278.branchPointer,
                rules: model$278.rules,
                integrations: model$278.integrations,
                globalRequirements: model$278.globalRequirements
              }
            };
    }
    var model$279 = model._0;
    return {
            TAG: /* Mapped */1,
            _0: {
              archive: model$279.archive,
              branchPointer: model$279.branchPointer,
              migrations: Belt_Array.concat(["SourceDestinationMigration"], model$279.migrations),
              rules: model$279.rules,
              types: model$279.types,
              categories: model$279.categories,
              destinations: model$279.destinations,
              events: Curry._2(TrackingPlanMappedModel.Events.mapToT, model$279.events, (function ($$event) {
                      return {
                              id: $$event.id,
                              name: $$event.name,
                              uniqueName: $$event.uniqueName,
                              description: $$event.description,
                              properties: $$event.properties,
                              propertyBundles: $$event.propertyBundles,
                              variants: $$event.variants,
                              types: $$event.types,
                              tags: $$event.tags,
                              excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                              includeSources: $$event.includeSources,
                              includeDestinations: Belt_List.keepMapU($$event.includeSources, (function (includedSource) {
                                      return Belt_Option.map(Curry._2(TrackingPlanMappedModel.Sources.get, model$279.sources, includedSource.id), (function (source) {
                                                    return {
                                                            NAME: "IncludeSourceDestination",
                                                            VAL: [
                                                              includedSource.id,
                                                              ModelUtils.getSourceDestinationIds(source.destinations)
                                                            ]
                                                          };
                                                  }));
                                    })),
                              hashes: $$event.hashes,
                              propertyWhitelist: $$event.propertyWhitelist,
                              eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                              userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                              triggers: $$event.triggers,
                              globalRequirementsMetadata: $$event.globalRequirementsMetadata
                            };
                    })),
              groupTypes: model$279.groupTypes,
              integrations: model$279.integrations,
              metrics: model$279.metrics,
              openBranches: model$279.openBranches,
              properties: model$279.properties,
              propertyBundles: model$279.propertyBundles,
              sources: model$279.sources,
              globalRequirements: model$279.globalRequirements
            }
          };
  }
  if (action === "ResetTrackingPlan") {
    if (model.TAG === /* Old */0) {
      var model$280 = model._0;
      return {
              TAG: /* Old */0,
              _0: {
                types: TrackingPlanModel.empty.types,
                properties: Belt_Option.mapWithDefault(model$280.globalRequirements, /* [] */0, (function (param) {
                        return param.properties;
                      })),
                propertyBundles: Belt_Option.mapWithDefault(model$280.globalRequirements, /* [] */0, (function (param) {
                        return param.propertyBundles;
                      })),
                events: Belt_Option.mapWithDefault(model$280.globalRequirements, /* [] */0, (function (param) {
                        return param.events;
                      })),
                migrations: model$280.migrations,
                sources: model$280.sources,
                destinations: model$280.destinations,
                groupTypes: Belt_Option.mapWithDefault(model$280.globalRequirements, [], (function (param) {
                        return param.groupTypes;
                      })),
                goals: Belt_Option.mapWithDefault(model$280.globalRequirements, /* [] */0, (function (param) {
                        return param.goals;
                      })),
                metrics: TrackingPlanModel.empty.metrics,
                archive: TrackingPlanModel.empty.archive,
                openBranches: TrackingPlanModel.empty.openBranches,
                branchPointer: TrackingPlanModel.empty.branchPointer,
                rules: TrackingPlanModel.empty.rules,
                integrations: TrackingPlanModel.empty.integrations,
                globalRequirements: model$280.globalRequirements
              }
            };
    }
    var model$281 = model._0;
    return {
            TAG: /* Mapped */1,
            _0: {
              archive: TrackingPlanMappedModel.empty.archive,
              branchPointer: TrackingPlanMappedModel.empty.branchPointer,
              migrations: model$281.migrations,
              rules: TrackingPlanMappedModel.empty.rules,
              types: TrackingPlanMappedModel.empty.types,
              categories: Belt_Option.mapWithDefault(model$281.globalRequirements, TrackingPlanMappedModel.Categories.empty, (function (param) {
                      return param.categories;
                    })),
              destinations: model$281.destinations,
              events: Belt_Option.mapWithDefault(model$281.globalRequirements, TrackingPlanMappedModel.Events.empty, (function (param) {
                      return param.events;
                    })),
              groupTypes: Belt_Option.mapWithDefault(model$281.globalRequirements, TrackingPlanMappedModel.GroupTypes.empty, (function (param) {
                      return param.groupTypes;
                    })),
              integrations: TrackingPlanMappedModel.empty.integrations,
              metrics: TrackingPlanMappedModel.empty.metrics,
              openBranches: TrackingPlanMappedModel.empty.openBranches,
              properties: Belt_Option.mapWithDefault(model$281.globalRequirements, TrackingPlanMappedModel.Properties.empty, (function (param) {
                      return param.properties;
                    })),
              propertyBundles: Belt_Option.mapWithDefault(model$281.globalRequirements, TrackingPlanMappedModel.PropertyBundles.empty, (function (param) {
                      return param.propertyBundles;
                    })),
              sources: model$281.sources,
              globalRequirements: model$281.globalRequirements
            }
          };
  }
  if (model.TAG === /* Old */0) {
    var model$282 = model._0;
    return {
            TAG: /* Old */0,
            _0: {
              types: model$282.types,
              properties: model$282.properties,
              propertyBundles: model$282.propertyBundles,
              events: model$282.events,
              migrations: {
                hd: "RemoveUnarchivedItemsFromArchive",
                tl: model$282.migrations
              },
              sources: model$282.sources,
              destinations: model$282.destinations,
              groupTypes: model$282.groupTypes,
              goals: model$282.goals,
              metrics: model$282.metrics,
              archive: {
                events: Belt_List.keepU(model$282.archive.events, (function (archivedEvent) {
                        return !Belt_List.someU(model$282.events, (function ($$event) {
                                      return $$event.id === archivedEvent.id;
                                    }));
                      })),
                properties: Belt_List.keepU(model$282.archive.properties, (function (archivedProperty) {
                        return !Belt_List.someU(model$282.properties, (function (property) {
                                      if (property.TAG === /* PropertyRef */0 || archivedProperty.TAG === /* PropertyRef */0) {
                                        return false;
                                      } else {
                                        return property._0.id === archivedProperty._0.id;
                                      }
                                    }));
                      })),
                propertyBundles: Belt_List.keepU(model$282.archive.propertyBundles, (function (archivedGroup) {
                        return !Belt_List.someU(model$282.propertyBundles, (function (group) {
                                      return group.id === archivedGroup.id;
                                    }));
                      })),
                sources: Belt_List.keepU(model$282.archive.sources, (function (archivedSource) {
                        return !Belt_List.someU(model$282.sources, (function (source) {
                                      return source.id === archivedSource.id;
                                    }));
                      })),
                destinations: Belt_List.keepU(model$282.archive.destinations, (function (archivedDestination) {
                        return !Belt_List.someU(model$282.destinations, (function (destination) {
                                      return destination.id === archivedDestination.id;
                                    }));
                      })),
                goals: Belt_List.keepU(model$282.archive.goals, (function (archivedGoal) {
                        return !Belt_List.someU(model$282.goals, (function (goal) {
                                      return goal.id === archivedGoal.id;
                                    }));
                      })),
                metrics: Belt_List.keepU(model$282.archive.metrics, (function (archivedMetric) {
                        return !Belt_List.someU(model$282.metrics, (function (metric) {
                                      return metric.id === archivedMetric.id;
                                    }));
                      })),
                groupTypes: Belt_Array.keepU(model$282.archive.groupTypes, (function (archivedGroupType) {
                        return !Belt_Array.someU(model$282.groupTypes, (function (groupType) {
                                      return groupType.id === archivedGroupType.id;
                                    }));
                      })),
                eventVariants: model$282.archive.eventVariants
              },
              openBranches: model$282.openBranches,
              branchPointer: model$282.branchPointer,
              rules: model$282.rules,
              integrations: model$282.integrations,
              globalRequirements: model$282.globalRequirements
            }
          };
  }
  var model$283 = model._0;
  return {
          TAG: /* Mapped */1,
          _0: {
            archive: {
              categories: Curry._2(TrackingPlanMappedModel.Categories.keep, model$283.archive.categories, (function (param) {
                      return !Curry._2(TrackingPlanMappedModel.Categories.has, model$283.categories, param.id);
                    })),
              destinations: Curry._2(TrackingPlanMappedModel.Destinations.keep, model$283.archive.destinations, (function (param) {
                      return !Curry._2(TrackingPlanMappedModel.Destinations.has, model$283.destinations, param.id);
                    })),
              events: Curry._2(TrackingPlanMappedModel.Events.keep, model$283.archive.events, (function (param) {
                      return !Curry._2(TrackingPlanMappedModel.Events.has, model$283.events, param.id);
                    })),
              groupTypes: Curry._2(TrackingPlanMappedModel.GroupTypes.keep, model$283.archive.groupTypes, (function (param) {
                      return !Curry._2(TrackingPlanMappedModel.GroupTypes.has, model$283.groupTypes, param.id);
                    })),
              metrics: Curry._2(TrackingPlanMappedModel.Metrics.keep, model$283.archive.metrics, (function (param) {
                      return !Curry._2(TrackingPlanMappedModel.Metrics.has, model$283.metrics, param.id);
                    })),
              properties: Curry._2(TrackingPlanMappedModel.Properties.keep, model$283.archive.properties, (function (property) {
                      return !Curry._2(TrackingPlanMappedModel.Properties.has, model$283.properties, property.id);
                    })),
              propertyBundles: Curry._2(TrackingPlanMappedModel.PropertyBundles.keep, model$283.archive.propertyBundles, (function (param) {
                      return !Curry._2(TrackingPlanMappedModel.PropertyBundles.has, model$283.propertyBundles, param.id);
                    })),
              sources: Curry._2(TrackingPlanMappedModel.Sources.keep, model$283.archive.sources, (function (param) {
                      return !Curry._2(TrackingPlanMappedModel.Sources.has, model$283.sources, param.id);
                    })),
              eventVariants: model$283.archive.eventVariants
            },
            branchPointer: model$283.branchPointer,
            migrations: Belt_Array.concat(["RemoveUnarchivedItemsFromArchive"], model$283.migrations),
            rules: model$283.rules,
            types: model$283.types,
            categories: model$283.categories,
            destinations: model$283.destinations,
            events: model$283.events,
            groupTypes: model$283.groupTypes,
            integrations: model$283.integrations,
            metrics: model$283.metrics,
            openBranches: model$283.openBranches,
            properties: model$283.properties,
            propertyBundles: model$283.propertyBundles,
            sources: model$283.sources,
            globalRequirements: model$283.globalRequirements
          }
        };
}

function reduce(model, action) {
  return TrackingPlanMappedModel.unboxModel(reduceFunction({
                  TAG: /* Old */0,
                  _0: model
                }, action));
}

function reduceMapped(model, action) {
  return TrackingPlanMappedModel.unboxModel(reduceFunction({
                  TAG: /* Mapped */1,
                  _0: model
                }, action));
}

var Model;

export {
  Model ,
  createEverythingSometimesSentMatrix ,
  extractMixedSourceAbsenceFromAnySourceAbsence ,
  computeCurrentAbsenceMatrix ,
  updatePropertyAbsence ,
  reduceFunction ,
  reduce ,
  reduceMapped ,
  
}
/* AvoModel Not a pure module */
