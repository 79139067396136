// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as GetRegexValidationUseCase from "./GetRegexValidationUseCase.mjs";

function update(property, maybeEventId, maybeRegexRule) {
  var isEmpty = function (param) {
    if (param.propertyRule === undefined) {
      return param.eventOverrides === undefined;
    } else {
      return false;
    }
  };
  var existingRegexValidation = GetRegexValidationUseCase.get(property);
  var nextRegexValidation = existingRegexValidation !== undefined ? (
      maybeEventId !== undefined ? ({
            propertyRule: existingRegexValidation.propertyRule,
            eventOverrides: Belt_MapString.update(existingRegexValidation.eventOverrides, maybeEventId, (function (param) {
                    return maybeRegexRule;
                  }))
          }) : ({
            propertyRule: maybeRegexRule,
            eventOverrides: existingRegexValidation.eventOverrides
          })
    ) : (
      maybeEventId !== undefined ? (
          maybeRegexRule !== undefined ? ({
                propertyRule: undefined,
                eventOverrides: Belt_MapString.fromArray([[
                        maybeEventId,
                        maybeRegexRule
                      ]])
              }) : undefined
        ) : (
          maybeRegexRule !== undefined ? ({
                propertyRule: maybeRegexRule,
                eventOverrides: undefined
              }) : undefined
        )
    );
  var nextRegexValidation$1 = Belt_Option.flatMap(nextRegexValidation, (function (validation) {
          if (isEmpty(validation)) {
            return ;
          } else {
            return validation;
          }
        }));
  if (existingRegexValidation !== undefined) {
    if (nextRegexValidation$1 !== undefined) {
      return {
              id: property.id,
              name: property.name,
              uniqueName: property.uniqueName,
              description: property.description,
              type_: property.type_,
              sendAs: property.sendAs,
              validations: Belt_List.mapU(property.validations, (function (validation) {
                      if (typeof validation === "object" && validation.NAME === "Regex") {
                        return {
                                NAME: "Regex",
                                VAL: nextRegexValidation$1
                              };
                      } else {
                        return validation;
                      }
                    })),
              eventSpecificAllowedPropertyValues: property.eventSpecificAllowedPropertyValues,
              auto: property.auto,
              builtIn: property.builtIn,
              analyticsTools: property.analyticsTools,
              devPlatforms: property.devPlatforms,
              list: property.list,
              operation: property.operation,
              optionalDeprecated: property.optionalDeprecated,
              excludedSourcesDeprecated: property.excludedSourcesDeprecated,
              optionalWhenInObject: property.optionalWhenInObject,
              absence: property.absence,
              globalRequirementsMetadata: property.globalRequirementsMetadata
            };
    } else {
      return {
              id: property.id,
              name: property.name,
              uniqueName: property.uniqueName,
              description: property.description,
              type_: property.type_,
              sendAs: property.sendAs,
              validations: Belt_List.keepU(property.validations, (function (validation) {
                      if (typeof validation === "object") {
                        return validation.NAME !== "Regex";
                      } else {
                        return true;
                      }
                    })),
              eventSpecificAllowedPropertyValues: property.eventSpecificAllowedPropertyValues,
              auto: property.auto,
              builtIn: property.builtIn,
              analyticsTools: property.analyticsTools,
              devPlatforms: property.devPlatforms,
              list: property.list,
              operation: property.operation,
              optionalDeprecated: property.optionalDeprecated,
              excludedSourcesDeprecated: property.excludedSourcesDeprecated,
              optionalWhenInObject: property.optionalWhenInObject,
              absence: property.absence,
              globalRequirementsMetadata: property.globalRequirementsMetadata
            };
    }
  } else if (nextRegexValidation$1 !== undefined) {
    return {
            id: property.id,
            name: property.name,
            uniqueName: property.uniqueName,
            description: property.description,
            type_: property.type_,
            sendAs: property.sendAs,
            validations: Belt_List.concat(property.validations, {
                  hd: {
                    NAME: "Regex",
                    VAL: nextRegexValidation$1
                  },
                  tl: /* [] */0
                }),
            eventSpecificAllowedPropertyValues: property.eventSpecificAllowedPropertyValues,
            auto: property.auto,
            builtIn: property.builtIn,
            analyticsTools: property.analyticsTools,
            devPlatforms: property.devPlatforms,
            list: property.list,
            operation: property.operation,
            optionalDeprecated: property.optionalDeprecated,
            excludedSourcesDeprecated: property.excludedSourcesDeprecated,
            optionalWhenInObject: property.optionalWhenInObject,
            absence: property.absence,
            globalRequirementsMetadata: property.globalRequirementsMetadata
          };
  } else {
    return property;
  }
}

export {
  update ,
  
}
/* No side effect */
