// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function Input(Props) {
  var label = Props.label;
  var value = Props.value;
  var onChange = Props.onChange;
  var id = Props.id;
  var name = Props.name;
  var autoFocus = Props.autoFocus;
  var password = Props.password;
  var number = Props.number;
  var onEnter = Props.onEnter;
  var onBackspace = Props.onBackspace;
  var placeholder = Props.placeholder;
  var onBlur = Props.onBlur;
  var onFocus = Props.onFocus;
  var data = Props.data;
  var classNameOpt = Props.className;
  var required = Props.required;
  var disabled = Props.disabled;
  var onSubmit = Props.onSubmit;
  var containerClassNameOpt = Props.containerClassName;
  var min = Props.min;
  var max = Props.max;
  var step = Props.step;
  var inputRef = Props.inputRef;
  var icon = Props.icon;
  var iconPositionOpt = Props.iconPosition;
  var className = classNameOpt !== undefined ? classNameOpt : /* [] */0;
  var containerClassName = containerClassNameOpt !== undefined ? containerClassNameOpt : /* [] */0;
  var iconPosition = iconPositionOpt !== undefined ? iconPositionOpt : ({
        NAME: "right",
        VAL: 40
      });
  var tmp;
  var exit = 0;
  if (password !== undefined && password) {
    tmp = "password";
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = number !== undefined && number ? "number" : "default";
  }
  var tmp$1 = {
    className: Curry._1(Css.merge, {
          hd: Curry._1(Css.style, {
                hd: Css.width(Css.pct(100)),
                tl: {
                  hd: Css.padding2(Css.px(7), Css.px(10)),
                  tl: {
                    hd: Css.fontSize(Styles.FontSize.medium),
                    tl: {
                      hd: Css.borderRadius(Styles.Border.radius),
                      tl: {
                        hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                        tl: {
                          hd: Css.outlineColor(Styles.Color.darkBlue),
                          tl: {
                            hd: Css.position("relative"),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }),
          tl: {
            hd: Curry._1(Css.style, disabled !== undefined && disabled ? ({
                      hd: Css.backgroundColor(Styles.Color.light04),
                      tl: /* [] */0
                    }) : /* [] */0),
            tl: {
              hd: Curry._1(Css.style, className),
              tl: {
                hd: Belt_Option.mapWithDefault(icon, Curry._1(Css.style, /* [] */0), (function (param) {
                        return Curry._1(Css.style, {
                                    hd: iconPosition.NAME === "right" ? Css.paddingRight(Css.px(iconPosition.VAL)) : Css.paddingLeft(Css.px(iconPosition.VAL)),
                                    tl: /* [] */0
                                  });
                      })),
                tl: /* [] */0
              }
            }
          }
        }),
    required: Belt_Option.getWithDefault(required, false),
    type: tmp,
    value: value,
    onKeyDown: (function ($$event) {
        var match = $$event.key;
        if (onBackspace !== undefined && match === "Backspace") {
          return Curry._1(onBackspace, $$event);
        }
        
      }),
    onKeyPress: (function ($$event) {
        var match = $$event.key;
        if (onEnter !== undefined && match === "Enter") {
          return Curry._1(onEnter, $$event);
        }
        
      }),
    onFocus: (function ($$event) {
        if (onFocus !== undefined) {
          return Curry._1(onFocus, $$event.target.value);
        }
        
      }),
    onBlur: (function ($$event) {
        if (onBlur !== undefined) {
          return Curry._1(onBlur, $$event.target.value);
        }
        
      }),
    onChange: (function ($$event) {
        return Curry._1(onChange, $$event.target.value);
      })
  };
  if (inputRef !== undefined) {
    tmp$1.ref = Caml_option.valFromOption(inputRef);
  }
  if (id !== undefined) {
    tmp$1.id = Caml_option.valFromOption(id);
  }
  if (autoFocus !== undefined) {
    tmp$1.autoFocus = Caml_option.valFromOption(autoFocus);
  }
  if (disabled !== undefined) {
    tmp$1.disabled = Caml_option.valFromOption(disabled);
  }
  var tmp$2 = Belt_Option.map(max, (function (prim) {
          return prim.toString();
        }));
  if (tmp$2 !== undefined) {
    tmp$1.max = Caml_option.valFromOption(tmp$2);
  }
  var tmp$3 = Belt_Option.map(min, (function (prim) {
          return prim.toString();
        }));
  if (tmp$3 !== undefined) {
    tmp$1.min = Caml_option.valFromOption(tmp$3);
  }
  if (name !== undefined) {
    tmp$1.name = Caml_option.valFromOption(name);
  }
  if (placeholder !== undefined) {
    tmp$1.placeholder = Caml_option.valFromOption(placeholder);
  }
  if (step !== undefined) {
    tmp$1.step = Caml_option.valFromOption(step);
  }
  if (onSubmit !== undefined) {
    tmp$1.onSubmit = Caml_option.valFromOption(onSubmit);
  }
  var input = React.createElement(React.Fragment, undefined, React.createElement("input", tmp$1), icon !== undefined ? React.createElement("span", {
              className: Curry._1(Css.style, {
                    hd: Css.position("absolute"),
                    tl: {
                      hd: typeof iconPosition === "object" && iconPosition.NAME === "right" ? Css.right(Css.px(16)) : Css.left(Css.px(16)),
                      tl: {
                        hd: Css.top(Css.px(8)),
                        tl: /* [] */0
                      }
                    }
                  })
            }, Caml_option.valFromOption(icon)) : null);
  var tmp$4;
  if (label !== undefined) {
    var tmp$5 = {
      className: Curry._1(Css.style, {
            hd: Css.display("block"),
            tl: {
              hd: Css.marginBottom(Css.px(8)),
              tl: {
                hd: Css.marginLeft(Css.px(11)),
                tl: /* [] */0
              }
            }
          })
    };
    if (id !== undefined) {
      tmp$5.htmlFor = Caml_option.valFromOption(id);
    }
    tmp$4 = React.createElement("label", tmp$5, React.createElement($$Text.make, {
              element: "Span",
              size: "Small",
              weight: "Semi",
              color: Styles.Color.light11,
              children: label
            }));
  } else {
    tmp$4 = null;
  }
  return React.createElement("div", {
              className: Curry._1(Css.merge, {
                    hd: Curry._1(Css.style, {
                          hd: Css.position("relative"),
                          tl: {
                            hd: Css.width(Css.pct(100)),
                            tl: {
                              hd: Css.margin2(Css.px(5), Css.px(0)),
                              tl: {
                                hd: Css.color(Styles.Color.light10),
                                tl: /* [] */0
                              }
                            }
                          }
                        }),
                    tl: {
                      hd: Curry._1(Css.style, containerClassName),
                      tl: /* [] */0
                    }
                  })
            }, tmp$4, Belt_Option.mapWithDefault(data, input, (function (data) {
                    return React.cloneElement(input, data);
                  })));
}

var make = Input;

export {
  make ,
  
}
/* Css Not a pure module */
