// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Buffer from "rescript/lib/es6/buffer.js";
import * as Format from "rescript/lib/es6/format.js";

function pp_make_buffer(param) {
  return $$Buffer.create(512);
}

function str_formatter(param) {
  var buf = $$Buffer.create(512);
  return [
          buf,
          Format.formatter_of_buffer(buf)
        ];
}

function flush_buffer_formatter(buf, ppf) {
  Format.pp_print_flush(ppf, undefined);
  var s = $$Buffer.contents(buf);
  $$Buffer.reset(buf);
  return s;
}

var flush_str_formatter = flush_buffer_formatter;

var pp_buffer_size = 512;

export {
  pp_buffer_size ,
  pp_make_buffer ,
  str_formatter ,
  flush_buffer_formatter ,
  flush_str_formatter ,
  
}
/* Format Not a pure module */
