// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function cmp(a, b) {
  return a === b;
}

function widowless(str) {
  return str.replace(/(\s|^)(\S+)$/, String.fromCharCode(160) + "$2");
}

function normalizeNewlines(string) {
  return string.replace(/(\r\n|\n|\r)/g, "\n");
}

function joinToSentence(stringsToJoin) {
  if (stringsToJoin.length <= 1) {
    return Belt_Option.getWithDefault(Belt_Array.get(stringsToJoin, 0), "");
  }
  var allButLast = Belt_Array.slice(stringsToJoin, 0, stringsToJoin.length - 1 | 0).join(", ");
  var last = Belt_Array.getExn(Belt_Array.slice(stringsToJoin, -1, 1), 0);
  return allButLast + " and " + last;
}

export {
  cmp ,
  widowless ,
  normalizeNewlines ,
  joinToSentence ,
  
}
/* No side effect */
