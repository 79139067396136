// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as ModelUtils_mapped from "./ModelUtils_mapped.mjs";
import * as TrackingPlanModel from "../../model/src/TrackingPlanModel.mjs";
import * as BeltListExtensions from "./BeltListExtensions.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";

var modes = [
  /* Always */0,
  /* Sometimes */1,
  /* BySource */2
];

function getModeString(mode) {
  switch (mode) {
    case /* Always */0 :
        return "Always sent";
    case /* Sometimes */1 :
        return "Sometimes sent";
    case /* BySource */2 :
        return "Depending on source";
    
  }
}

var ParseMode = /* @__PURE__ */Caml_exceptions.create("PropertyAbsenceViewHelpers.ParseMode");

function parseModeString(mode) {
  switch (mode) {
    case "Always sent" :
        return /* Always */0;
    case "Depending on source" :
        return /* BySource */2;
    case "Sometimes sent" :
        return /* Sometimes */1;
    default:
      throw {
            RE_EXN_ID: ParseMode,
            Error: new Error()
          };
  }
}

function getMoreComplex(a, b) {
  if (Belt_Option.mapWithDefault(Belt_Array.getBy(modes, (function (mode) {
                return mode === a ? true : mode === b;
              })), true, (function (mode) {
            return mode === a;
          }))) {
    return b;
  } else {
    return a;
  }
}

function getEventAndSourceStateString(state) {
  switch (state) {
    case /* Unavailable */0 :
        return "Unavailable";
    case /* Never */1 :
        return "Never";
    case /* Sometimes */2 :
        return "Sometimes";
    case /* Always */3 :
        return "Always";
    
  }
}

function parseEventAndSourceStateString(state) {
  switch (state) {
    case "Always" :
        return /* Always */3;
    case "Never" :
        return /* Never */1;
    case "Sometimes" :
        return /* Sometimes */2;
    case "Unavailable" :
        return /* Unavailable */0;
    default:
      return /* Unavailable */0;
  }
}

function getAbsenceByEventAndSource(eventAbsences, eventId, sourceId) {
  return Belt_Option.flatMap(Belt_MapString.get(eventAbsences, eventId), (function (sourceAbsences) {
                if (sourceAbsences.TAG === /* AllSources */0) {
                  if (sourceAbsences._0) {
                    return /* Sometimes */2;
                  } else {
                    return /* Never */1;
                  }
                } else {
                  return Belt_Option.map(Belt_MapString.get(sourceAbsences._0, sourceId), (function (sourceAbsence) {
                                if (sourceAbsence) {
                                  return /* Sometimes */2;
                                } else {
                                  return /* Never */1;
                                }
                              }));
                }
              }));
}

function getAllEventSources(modelSources, propertyEvents) {
  var sources = Belt_List.flatten(Belt_List.map(propertyEvents, (function ($$event) {
              var match = AvoConfig.determineSendingToSources(modelSources, $$event);
              if (typeof match === "object") {
                return match.VAL;
              } else if (match === "None") {
                return /* [] */0;
              } else {
                return modelSources;
              }
            })));
  return Belt_List.keepMap(BeltListExtensions.dedupeString(Belt_List.map(sources, (function (source) {
                        return source.id;
                      }))), (function (sourceId) {
                return Belt_List.getBy(sources, (function (source) {
                              return source.id === sourceId;
                            }));
              }));
}

function getFullStateMatrix(propertyEvents, modelSources, absence) {
  if (absence !== undefined && typeof absence !== "number") {
    if (absence.TAG === /* SometimesSent */0) {
      var allEventSources = getAllEventSources(modelSources, propertyEvents);
      return Belt_MapString.fromArray(Belt_List.toArray(Belt_List.map(propertyEvents, (function ($$event) {
                            var eventSources = AvoConfig.determineSendingToSources(modelSources, $$event);
                            return [
                                    $$event.id,
                                    Belt_MapString.fromArray(Belt_List.toArray(Belt_List.map(allEventSources, (function (source) {
                                                    var eventSendsSource = typeof eventSources === "object" ? Belt_Option.isSome(Belt_List.getBy(eventSources.VAL, (function (eventSource) {
                                                                  return eventSource.id === source.id;
                                                                }))) : eventSources !== "None";
                                                    return [
                                                            source.id,
                                                            eventSendsSource ? /* Sometimes */2 : /* Unavailable */0
                                                          ];
                                                  }))))
                                  ];
                          }))));
    }
    var eventAbsences = absence._0;
    var allEventSources$1 = getAllEventSources(modelSources, propertyEvents);
    return Belt_MapString.fromArray(Belt_List.toArray(Belt_List.map(propertyEvents, (function ($$event) {
                          var eventSources = AvoConfig.determineSendingToSources(modelSources, $$event);
                          return [
                                  $$event.id,
                                  Belt_MapString.fromArray(Belt_List.toArray(Belt_List.map(allEventSources$1, (function (source) {
                                                  var eventSendsSource = typeof eventSources === "object" ? Belt_Option.isSome(Belt_List.getBy(eventSources.VAL, (function (eventSource) {
                                                                return eventSource.id === source.id;
                                                              }))) : eventSources !== "None";
                                                  return [
                                                          source.id,
                                                          eventSendsSource ? Belt_Option.getWithDefault(getAbsenceByEventAndSource(eventAbsences, $$event.id, source.id), /* Always */3) : /* Unavailable */0
                                                        ];
                                                }))))
                                ];
                        }))));
  }
  var allEventSources$2 = getAllEventSources(modelSources, propertyEvents);
  return Belt_MapString.fromArray(Belt_List.toArray(Belt_List.map(propertyEvents, (function ($$event) {
                        var eventSources = AvoConfig.determineSendingToSources(modelSources, $$event);
                        return [
                                $$event.id,
                                Belt_MapString.fromArray(Belt_List.toArray(Belt_List.map(allEventSources$2, (function (source) {
                                                var eventSendsSource = typeof eventSources === "object" ? Belt_Option.isSome(Belt_List.getBy(eventSources.VAL, (function (eventSource) {
                                                              return eventSource.id === source.id;
                                                            }))) : eventSources !== "None";
                                                return [
                                                        source.id,
                                                        eventSendsSource ? /* Always */3 : /* Unavailable */0
                                                      ];
                                              }))))
                              ];
                      }))));
}

function emptyOrEverythingSometimes(stateMatrix) {
  return Belt_Array.every(Belt_MapString.valuesToArray(stateMatrix), (function (sources) {
                return Belt_Array.every(Belt_MapString.valuesToArray(sources), (function (state) {
                              if (state === /* Sometimes */2) {
                                return true;
                              } else {
                                return state === /* Unavailable */0;
                              }
                            }));
              }));
}

function getSimplestModeForStateMatrix(stateMatrix) {
  var withUnavailableFiltered = Belt_Array.map(Belt_MapString.valuesToArray(stateMatrix), (function (sources) {
          return Belt_Array.keep(Belt_MapString.valuesToArray(sources), (function (state) {
                        return state !== /* Unavailable */0;
                      }));
        }));
  var emptyOrEverythingAlways = Belt_Array.every(withUnavailableFiltered, (function ($$event) {
          return Belt_Array.every($$event, (function (state) {
                        return state === /* Always */3;
                      }));
        }));
  var emptyOrEveryEventConsistent = Belt_Array.every(withUnavailableFiltered, (function ($$event) {
          return Belt_SetString.size(Belt_SetString.fromArray(Belt_Array.map($$event, getEventAndSourceStateString))) <= 1;
        }));
  if (emptyOrEverythingAlways) {
    return /* Always */0;
  } else if (emptyOrEveryEventConsistent) {
    return /* Sometimes */1;
  } else {
    return /* BySource */2;
  }
}

function getDefaultModeForAbsence(propertyEvents, modelSources, absence) {
  if (absence !== undefined && typeof absence !== "number") {
    if (absence.TAG === /* SometimesSent */0) {
      return /* Sometimes */1;
    } else {
      return getSimplestModeForStateMatrix(getFullStateMatrix(propertyEvents, modelSources, absence));
    }
  } else {
    return /* Always */0;
  }
}

function sourcesEmptyOrEverythingSometimes(systemPropsAbsenceMap) {
  return Belt_Array.every(Belt_MapString.valuesToArray(systemPropsAbsenceMap), (function (state) {
                if (state === /* Sometimes */2) {
                  return true;
                } else {
                  return state === /* Unavailable */0;
                }
              }));
}

function getSimplestModeForAbsenceMap(absenceMap) {
  var withUnavailableFiltered = Belt_Array.keep(Belt_MapString.valuesToArray(absenceMap), (function (state) {
          return state !== /* Unavailable */0;
        }));
  var emptyOrEverythingAlways = Belt_Array.every(withUnavailableFiltered, (function (state) {
          return state === /* Always */3;
        }));
  var emptyOrEveryEventConsistent = Belt_Array.every(withUnavailableFiltered, (function (state) {
          return state === /* Sometimes */2;
        }));
  if (emptyOrEverythingAlways) {
    return /* Always */0;
  } else if (emptyOrEveryEventConsistent) {
    return /* Sometimes */1;
  } else {
    return /* BySource */2;
  }
}

function getFullSysAbsenceMap(modelSources, absence) {
  if (absence !== undefined && typeof absence !== "number") {
    if (absence.TAG === /* SometimesSent */0) {
      return Belt_MapString.fromArray(Belt_List.toArray(Belt_List.map(modelSources, (function (source) {
                            return [
                                    source.id,
                                    /* Sometimes */2
                                  ];
                          }))));
    }
    var alleventsAbsences = absence._0;
    return Belt_MapString.fromArray(Belt_List.toArray(Belt_List.map(modelSources, (function (source) {
                          return [
                                  source.id,
                                  Belt_Option.getWithDefault(getAbsenceByEventAndSource(alleventsAbsences, TrackingPlanModel.propertyAbsenceAllEventsKey, source.id), /* Always */3)
                                ];
                        }))));
  }
  return Belt_MapString.fromArray(Belt_List.toArray(Belt_List.map(modelSources, (function (source) {
                        return [
                                source.id,
                                /* Always */3
                              ];
                      }))));
}

function getDefaultModeForAbsenceSystemProp(modelSources, absence) {
  if (absence !== undefined && typeof absence !== "number") {
    if (absence.TAG === /* SometimesSent */0) {
      return /* Sometimes */1;
    } else {
      return getSimplestModeForAbsenceMap(getFullSysAbsenceMap(modelSources, absence));
    }
  } else {
    return /* Always */0;
  }
}

function getPropertyAbsenceString(model, sometimesSentPhraseOpt, property, param) {
  var sometimesSentPhrase = sometimesSentPhraseOpt !== undefined ? sometimesSentPhraseOpt : "Sometimes sent";
  if (property.sendAs === /* SystemProperty */0) {
    var match = property.absence;
    if (match !== undefined) {
      if (typeof match === "number") {
        return ;
      } else if (match.TAG === /* SometimesSent */0) {
        return sometimesSentPhrase;
      } else {
        return getModeString(getSimplestModeForAbsenceMap(getFullSysAbsenceMap(model.sources, property.absence)));
      }
    } else if (property.optionalDeprecated) {
      return sometimesSentPhrase;
    } else {
      return ;
    }
  }
  var propertyEvents = ModelUtils.eventsSendingProperty(model, undefined, property.id);
  var stateMatrix = getFullStateMatrix(propertyEvents, model.sources, property.absence);
  var match$1 = getSimplestModeForStateMatrix(stateMatrix);
  switch (match$1) {
    case /* Always */0 :
        return ;
    case /* Sometimes */1 :
        if (emptyOrEverythingSometimes(stateMatrix)) {
          return sometimesSentPhrase;
        } else {
          return sometimesSentPhrase + " by event";
        }
    case /* BySource */2 :
        return sometimesSentPhrase + " by event and source";
    
  }
}

function getPropertyAbsenceString_mapped(events, propertyBundles, sources, sometimesSentPhraseOpt, property) {
  var sometimesSentPhrase = sometimesSentPhraseOpt !== undefined ? sometimesSentPhraseOpt : "Sometimes sent";
  if (property.sendAs === /* SystemProperty */0) {
    var match = property.absence;
    if (match !== undefined) {
      if (typeof match === "number") {
        return ;
      } else if (match.TAG === /* SometimesSent */0) {
        return sometimesSentPhrase;
      } else {
        return getModeString(getSimplestModeForAbsenceMap(getFullSysAbsenceMap(sources, property.absence)));
      }
    } else if (property.optionalDeprecated) {
      return sometimesSentPhrase;
    } else {
      return ;
    }
  }
  var propertyEvents = Curry._1(TrackingPlanMappedModel.Events.toList, ModelUtils_mapped.eventsSendingProperty(events, propertyBundles, undefined, property.id));
  var stateMatrix = getFullStateMatrix(propertyEvents, sources, property.absence);
  var match$1 = getSimplestModeForStateMatrix(stateMatrix);
  switch (match$1) {
    case /* Always */0 :
        return ;
    case /* Sometimes */1 :
        if (emptyOrEverythingSometimes(stateMatrix)) {
          return sometimesSentPhrase;
        } else {
          return sometimesSentPhrase + " by event";
        }
    case /* BySource */2 :
        return sometimesSentPhrase + " by event and source";
    
  }
}

export {
  modes ,
  getModeString ,
  ParseMode ,
  parseModeString ,
  getMoreComplex ,
  getEventAndSourceStateString ,
  parseEventAndSourceStateString ,
  getAbsenceByEventAndSource ,
  getAllEventSources ,
  getFullStateMatrix ,
  emptyOrEverythingSometimes ,
  getSimplestModeForStateMatrix ,
  getDefaultModeForAbsence ,
  sourcesEmptyOrEverythingSometimes ,
  getSimplestModeForAbsenceMap ,
  getFullSysAbsenceMap ,
  getDefaultModeForAbsenceSystemProp ,
  getPropertyAbsenceString ,
  getPropertyAbsenceString_mapped ,
  
}
/* AvoConfig Not a pure module */
