// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Case from "../../bs-case/src/case.mjs";
import * as Link from "./Link.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as AvoUtils from "../../shared/utils/AvoUtils.mjs";
import * as DidMount from "./DidMount.mjs";
import * as AvoLimits from "./AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as IconError from "./IconError.mjs";
import * as StringExt from "./StringExt.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as PortalMenu from "./PortalMenu.mjs";
import * as TextButton from "./TextButton.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IconArrowUp from "./IconArrowUp.mjs";
import * as IconArrowDown from "./IconArrowDown.mjs";
import * as IconCheckmark from "./IconCheckmark.mjs";
import * as AppFeatureFlag from "./AppFeatureFlag.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as CollapsedContent from "./CollapsedContent.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as BeltListExtensions from "./BeltListExtensions.mjs";
import * as DiscrepancyContext from "./DiscrepancyContext.mjs";
import * as ValidationConfigV2 from "./guardrails/ValidationConfigV2.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";

function Discrepancies$Item(Props) {
  var label = Props.label;
  var subLabel = Props.subLabel;
  var path = Props.path;
  var onNavigate = Props.onNavigate;
  return React.createElement("li", undefined, React.createElement(Link.make, {
                  path: path,
                  className: Curry._1(Css.style, {
                        hd: Css.color(Styles.Color.darkBlue),
                        tl: {
                          hd: Css.hover({
                                hd: Css.color(Styles.Color.deepBlue),
                                tl: /* [] */0
                              }),
                          tl: /* [] */0
                        }
                      }),
                  onClick: (function ($$event) {
                      if ($$event.metaKey || $$event.shiftKey) {
                        return ;
                      } else {
                        return Curry._1(onNavigate, undefined);
                      }
                    }),
                  children: null
                }, React.createElement($$Text.make, {
                      size: "Small",
                      weight: "Semi",
                      children: label
                    }), subLabel !== undefined ? React.createElement($$Text.make, {
                        size: "Small",
                        color: Styles.Color.light11,
                        children: subLabel
                      }) : null));
}

var Item = {
  make: Discrepancies$Item
};

function Discrepancies$WarningGroup(Props) {
  var discrepancy = Props.discrepancy;
  var schemaBundle = Props.schemaBundle;
  var numSchemaIssues = Props.numSchemaIssues;
  var label = Props.label;
  var children = Props.children;
  return React.createElement(CollapsedContent.make, {
              button: (function (onClick, collapsed) {
                  return React.createElement("button", {
                              className: Curry._1(Css.merge, {
                                    hd: Curry._1(Css.style, Styles.button),
                                    tl: {
                                      hd: Curry._1(Css.style, {
                                            hd: Css.display("flex"),
                                            tl: {
                                              hd: Css.alignItems("center"),
                                              tl: {
                                                hd: Css.justifyContent("spaceBetween"),
                                                tl: {
                                                  hd: Css.color(collapsed ? Styles.Color.light11 : Styles.Color.light12),
                                                  tl: {
                                                    hd: Css.padding2(Css.px(8), Css.px(12)),
                                                    tl: {
                                                      hd: Css.width(Css.pct(100)),
                                                      tl: {
                                                        hd: Css.margin(Css.px(0)),
                                                        tl: {
                                                          hd: Css.textAlign("left"),
                                                          tl: {
                                                            hd: Css.transition({
                                                                  NAME: "ms",
                                                                  VAL: Styles.Duration.$$short
                                                                }, undefined, undefined, "all"),
                                                            tl: {
                                                              hd: Css.hover({
                                                                    hd: Css.color(Styles.Color.light12),
                                                                    tl: {
                                                                      hd: Css.backgroundColor(collapsed ? Styles.Color.setAlpha(Styles.Color.orange, 0.1) : "transparent"),
                                                                      tl: /* [] */0
                                                                    }
                                                                  }),
                                                              tl: /* [] */0
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }),
                                      tl: /* [] */0
                                    }
                                  }),
                              onClick: (function (param) {
                                  return Curry._1(onClick, undefined);
                                })
                            }, React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.display("flex"),
                                        tl: {
                                          hd: Css.alignItems("center"),
                                          tl: {
                                            hd: Css.justifyContent("flexStart"),
                                            tl: /* [] */0
                                          }
                                        }
                                      })
                                }, React.createElement("div", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.marginRight(Css.px(10)),
                                            tl: /* [] */0
                                          })
                                    }, React.createElement(IconError.make, {
                                          size: 14,
                                          color: Styles.Color.orange
                                        })), React.createElement($$Text.make, {
                                      size: "Medium",
                                      weight: "Medium",
                                      children: label
                                    })), collapsed ? React.createElement(IconArrowDown.make, {
                                    size: 12
                                  }) : React.createElement(IconArrowUp.make, {
                                    size: 12
                                  }));
                }),
              children: (function (param) {
                  return React.createElement(React.Fragment, undefined, React.createElement(DidMount.make, {
                                  didMount: (function (param) {
                                      var variant = discrepancy.NAME;
                                      AnalyticsRe.issueExpanded(schemaBundle, numSchemaIssues, variant === "SameEventName" ? "SameNameEvents" : (
                                              variant === "SamePropertyName" ? "SameNameProperty" : (
                                                  variant === "PropertiesWithAnyType" ? "PropertyTypeNotDefined" : (
                                                      variant === "EventsWithoutDescription" ? "EventMissingDescription" : (
                                                          variant === "EventsWithoutExpectedValues" ? "EventAdvancedNamingConventionDiscrepancy" : (
                                                              variant === "EventsWithUnexpectedCase" ? "EventCommonCasingDiscrepancy" : (
                                                                  variant === "PropertyConflictOnEvent" ? "PropertyConflicts" : (
                                                                      variant === "PropertiesWithoutDescription" ? "PropertyMissingDescription" : "PropertyCommonCasingDiscrepancy"
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            ), "Sidebar", undefined, false, schemaBundle.branchId, schemaBundle.schemaId);
                                      
                                    })
                                }), children);
                })
            });
}

var WarningGroup = {
  make: Discrepancies$WarningGroup
};

function Discrepancies$ValidGroup(Props) {
  var label = Props.label;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.paddingTop(Css.px(8)),
                        tl: {
                          hd: Css.paddingRight(Css.px(12)),
                          tl: {
                            hd: Css.paddingBottom(Css.px(8)),
                            tl: {
                              hd: Css.paddingLeft(Css.px(12)),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement(IconCheckmark.make, {
                  size: 14,
                  color: Styles.Color.green
                }), React.createElement(Spacer.make, {
                  width: 10
                }), React.createElement($$Text.make, {
                  size: "Medium",
                  weight: "Medium",
                  color: Styles.Color.light10,
                  children: label
                }));
}

var ValidGroup = {
  make: Discrepancies$ValidGroup
};

function Discrepancies$DisabledGroup(Props) {
  var label = Props.label;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.paddingTop(Css.px(8)),
                        tl: {
                          hd: Css.paddingRight(Css.px(12)),
                          tl: {
                            hd: Css.paddingBottom(Css.px(8)),
                            tl: {
                              hd: Css.paddingLeft(Css.px(12)),
                              tl: {
                                hd: Css.textDecoration("lineThrough"),
                                tl: {
                                  hd: Css.marginLeft(Css.px(24)),
                                  tl: {
                                    hd: Css.color(Styles.Color.light08),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement($$Text.make, {
                  size: "Medium",
                  weight: "Medium",
                  children: label
                }));
}

var DisabledGroup = {
  make: Discrepancies$DisabledGroup
};

function Discrepancies$Popup(Props) {
  var discrepancies = Props.discrepancies;
  var hasConsistentCase = Props.hasConsistentCase;
  var schemaBundle = Props.schemaBundle;
  var warningCount = Props.warningCount;
  var headerExtra = Props.headerExtra;
  var onNavigate = Props.onNavigate;
  var expectedEventCase = Props.expectedEventCase;
  var expectedPropertyCase = Props.expectedPropertyCase;
  var workspace = WorkspaceContext.use(undefined);
  var hasGuardrails = AppFeatureFlag.useFeatureFlag("GuardrailsForAll") || Curry._1(AvoLimits.Guardrails.AdvancedNamingConvention_deprecated.isAvailable, workspace);
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.width(Css.px(320)),
                    tl: {
                      hd: Css.backgroundColor(Styles.Color.white),
                      tl: {
                        hd: Css.borderRadius(Styles.Border.radius),
                        tl: /* [] */0
                      }
                    }
                  })
            }, React.createElement(DidMount.make, {
                  didMount: (function (param) {
                      return AnalyticsRe.issueReportOpened(schemaBundle, warningCount, schemaBundle.branchId, schemaBundle.schemaId);
                    })
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("baseline"),
                          tl: {
                            hd: Css.paddingLeft(Css.px(36)),
                            tl: {
                              hd: Css.paddingRight(Css.px(20)),
                              tl: {
                                hd: Css.paddingTop(Css.px(16)),
                                tl: {
                                  hd: Css.paddingBottom(Css.px(13)),
                                  tl: {
                                    hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light02),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      })
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Medium",
                      color: Styles.Color.light12,
                      children: "Tracking Plan Audit"
                    }), React.createElement(Spacer.make, {
                      width: 8,
                      grow: 1.0
                    }), Belt_Option.getWithDefault(headerExtra, null)), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.maxHeight(Css.vh(50)),
                        tl: {
                          hd: Css.overflow("auto"),
                          tl: {
                            hd: Css.paddingTop(Css.px(8)),
                            tl: {
                              hd: Css.paddingBottom(Css.px(8)),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, Belt_Array.mapU(discrepancies, (function (discrepancy) {
                        var variant = discrepancy.NAME;
                        if (variant === "SameEventName") {
                          var match = discrepancy.VAL;
                          if (!match[0]) {
                            return React.createElement(Discrepancies$DisabledGroup, {
                                        label: "All events have a unique name",
                                        key: "SameEventNameDisabled"
                                      });
                          }
                          var events = match[2];
                          if (events) {
                            return React.createElement(Discrepancies$WarningGroup, {
                                        discrepancy: discrepancy,
                                        schemaBundle: schemaBundle,
                                        numSchemaIssues: warningCount,
                                        label: String(Belt_List.length(events)) + " events have same or similar names",
                                        children: React.createElement("div", {
                                              className: Curry._1(Css.style, {
                                                    hd: Css.padding4(Css.px(0), Css.px(15), Css.px(2), Css.px(38)),
                                                    tl: /* [] */0
                                                  })
                                            }, Belt_List.toArray(Belt_List.mapU(BeltListExtensions.group((function (a, b) {
                                                            var a$1 = Case.camel(a.name);
                                                            var b$1 = Case.camel(b.name);
                                                            if (a$1 === b$1) {
                                                              return 0;
                                                            } else if (a$1 > b$1) {
                                                              return -1;
                                                            } else {
                                                              return 1;
                                                            }
                                                          }), events), (function (events) {
                                                        return React.createElement("ul", {
                                                                    className: Curry._1(Css.style, {
                                                                          hd: Css.marginTop(Css.px(6)),
                                                                          tl: {
                                                                            hd: Css.marginBottom(Css.px(12)),
                                                                            tl: /* [] */0
                                                                          }
                                                                        })
                                                                  }, Belt_List.toArray(Belt_List.mapU(events, (function ($$event) {
                                                                              return React.createElement(Discrepancies$Item, {
                                                                                          label: $$event.name,
                                                                                          path: Router.Link.addDrawerItem(undefined, {
                                                                                                NAME: "event",
                                                                                                VAL: [
                                                                                                  $$event.id,
                                                                                                  undefined,
                                                                                                  undefined,
                                                                                                  false
                                                                                                ]
                                                                                              }),
                                                                                          onNavigate: (function (param) {
                                                                                              return Curry._2(onNavigate, {
                                                                                                          id: $$event.id,
                                                                                                          name: $$event.name,
                                                                                                          type_: "Event"
                                                                                                        }, discrepancy);
                                                                                            }),
                                                                                          key: $$event.id
                                                                                        });
                                                                            }))));
                                                      })))),
                                        key: "SameEventNameInvalid"
                                      });
                          } else {
                            return React.createElement(Discrepancies$ValidGroup, {
                                        label: "All events have a unique name",
                                        key: "SameEventNameValid"
                                      });
                          }
                        }
                        if (variant === "SamePropertyName") {
                          var match$1 = discrepancy.VAL;
                          if (!match$1[0]) {
                            return React.createElement(Discrepancies$DisabledGroup, {
                                        label: "All properties have a unique name",
                                        key: "SamePropertyNameDisabled"
                                      });
                          }
                          var properties = match$1[2];
                          if (properties) {
                            return React.createElement(Discrepancies$WarningGroup, {
                                        discrepancy: discrepancy,
                                        schemaBundle: schemaBundle,
                                        numSchemaIssues: warningCount,
                                        label: String(Belt_List.length(properties)) + " properties have same or similar names",
                                        children: React.createElement("div", {
                                              className: Curry._1(Css.style, {
                                                    hd: Css.padding4(Css.px(0), Css.px(15), Css.px(2), Css.px(38)),
                                                    tl: /* [] */0
                                                  })
                                            }, Belt_List.toArray(Belt_List.mapU(BeltListExtensions.group((function (a, b) {
                                                            var a$1 = Case.camel(a.name);
                                                            var b$1 = Case.camel(b.name);
                                                            if (a$1 === b$1) {
                                                              return 0;
                                                            } else if (a$1 > b$1) {
                                                              return -1;
                                                            } else {
                                                              return 1;
                                                            }
                                                          }), properties), (function (properties) {
                                                        return React.createElement("ul", {
                                                                    className: Curry._1(Css.style, {
                                                                          hd: Css.marginTop(Css.px(6)),
                                                                          tl: {
                                                                            hd: Css.marginBottom(Css.px(12)),
                                                                            tl: /* [] */0
                                                                          }
                                                                        })
                                                                  }, Belt_List.toArray(Belt_List.mapU(properties, (function (property) {
                                                                              return React.createElement(Discrepancies$Item, {
                                                                                          label: property.name,
                                                                                          path: Router.Link.addDrawerItem(undefined, {
                                                                                                NAME: "property",
                                                                                                VAL: [
                                                                                                  property.id,
                                                                                                  undefined
                                                                                                ]
                                                                                              }),
                                                                                          onNavigate: (function (param) {
                                                                                              return Curry._2(onNavigate, {
                                                                                                          id: property.id,
                                                                                                          name: property.name,
                                                                                                          type_: "Property"
                                                                                                        }, discrepancy);
                                                                                            }),
                                                                                          key: property.id
                                                                                        });
                                                                            }))));
                                                      })))),
                                        key: "SamePropertyNameInvalid"
                                      });
                          } else {
                            return React.createElement(Discrepancies$ValidGroup, {
                                        label: "All properties have a unique name",
                                        key: "SamePropertyNameValid"
                                      });
                          }
                        }
                        if (variant === "PropertiesWithAnyType") {
                          var match$2 = discrepancy.VAL;
                          if (!match$2[0]) {
                            return React.createElement(Discrepancies$DisabledGroup, {
                                        label: "All properties have a defined type",
                                        key: "PropertiesWithAnyTypeDisabled"
                                      });
                          }
                          var properties$1 = match$2[2];
                          if (!properties$1) {
                            return React.createElement(Discrepancies$ValidGroup, {
                                        label: "All properties have a defined type",
                                        key: "PropertiesWithAnyTypeValid"
                                      });
                          }
                          var numProps = Belt_List.length(properties$1);
                          return React.createElement(Discrepancies$WarningGroup, {
                                      discrepancy: discrepancy,
                                      schemaBundle: schemaBundle,
                                      numSchemaIssues: warningCount,
                                      label: numProps !== 1 ? String(numProps) + " properties don't have a defined type" : "1 property does not have a defined type",
                                      children: React.createElement("ul", {
                                            className: Curry._1(Css.style, {
                                                  hd: Css.padding4(Css.px(0), Css.px(15), Css.px(2), Css.px(38)),
                                                  tl: {
                                                    hd: Css.margin2(Css.px(6), Css.px(0)),
                                                    tl: /* [] */0
                                                  }
                                                })
                                          }, Belt_List.toArray(Belt_List.mapU(properties$1, (function (property) {
                                                      return React.createElement(Discrepancies$Item, {
                                                                  label: property.name,
                                                                  path: Router.Link.addDrawerItem(undefined, {
                                                                        NAME: "property",
                                                                        VAL: [
                                                                          property.id,
                                                                          undefined
                                                                        ]
                                                                      }),
                                                                  onNavigate: (function (param) {
                                                                      return Curry._2(onNavigate, {
                                                                                  id: property.id,
                                                                                  name: property.name,
                                                                                  type_: "Property"
                                                                                }, discrepancy);
                                                                    }),
                                                                  key: property.id
                                                                });
                                                    })))),
                                      key: "PropertiesWithAnyTypeInvalid"
                                    });
                        }
                        if (variant === "EventsWithoutDescription") {
                          var match$3 = discrepancy.VAL;
                          if (!match$3[0]) {
                            return React.createElement(Discrepancies$DisabledGroup, {
                                        label: "All events have a description",
                                        key: "EventsWithoutDescriptionDisabled"
                                      });
                          }
                          var events$1 = match$3[2];
                          if (!events$1) {
                            return React.createElement(Discrepancies$ValidGroup, {
                                        label: "All events have a description",
                                        key: "EventsWithoutDescriptionValid"
                                      });
                          }
                          var numEvents = Belt_List.length(events$1);
                          return React.createElement(Discrepancies$WarningGroup, {
                                      discrepancy: discrepancy,
                                      schemaBundle: schemaBundle,
                                      numSchemaIssues: warningCount,
                                      label: numEvents !== 1 ? String(numEvents) + " events don't have a description" : "1 event does not have a description",
                                      children: React.createElement("ul", {
                                            className: Curry._1(Css.style, {
                                                  hd: Css.margin2(Css.px(6), Css.px(0)),
                                                  tl: /* [] */0
                                                })
                                          }, Belt_List.toArray(Belt_List.mapU(events$1, (function ($$event) {
                                                      return React.createElement(Discrepancies$Item, {
                                                                  label: $$event.name,
                                                                  path: Router.Link.addDrawerItem(undefined, {
                                                                        NAME: "event",
                                                                        VAL: [
                                                                          $$event.id,
                                                                          undefined,
                                                                          undefined,
                                                                          false
                                                                        ]
                                                                      }),
                                                                  onNavigate: (function (param) {
                                                                      return Curry._2(onNavigate, {
                                                                                  id: $$event.id,
                                                                                  name: $$event.name,
                                                                                  type_: "Event"
                                                                                }, discrepancy);
                                                                    }),
                                                                  key: $$event.id
                                                                });
                                                    })))),
                                      key: "EventsWithoutDescriptionInvalid"
                                    });
                        }
                        if (variant === "EventsWithoutExpectedValues") {
                          var match$4 = discrepancy.VAL;
                          if (!hasGuardrails) {
                            return null;
                          }
                          if (!match$4[0]) {
                            return React.createElement(Discrepancies$DisabledGroup, {
                                        label: "All event names include valid values",
                                        key: "EventsWithoutExpectedValues"
                                      });
                          }
                          var events$2 = match$4[2];
                          if (!events$2) {
                            return React.createElement(Discrepancies$ValidGroup, {
                                        label: "All event names include valid values",
                                        key: "EventsWithoutExpectedValues"
                                      });
                          }
                          var numEvents$1 = Belt_List.length(events$2);
                          return React.createElement(Discrepancies$WarningGroup, {
                                      discrepancy: discrepancy,
                                      schemaBundle: schemaBundle,
                                      numSchemaIssues: warningCount,
                                      label: numEvents$1 !== 1 ? String(numEvents$1) + " event names have missing or invalid values" : "1 event name has missing or invalid values",
                                      children: React.createElement("ul", {
                                            className: Curry._1(Css.style, {
                                                  hd: Css.margin2(Css.px(6), Css.px(0)),
                                                  tl: /* [] */0
                                                })
                                          }, Belt_List.toArray(Belt_List.mapU(events$2, (function (param) {
                                                      var match = param[1];
                                                      var predefined = match.predefined;
                                                      var blocked = match.blocked;
                                                      var $$event = param[0];
                                                      var tmp;
                                                      var exit = 0;
                                                      var exit$1 = 0;
                                                      if (blocked.length !== 0 || predefined.length === 0) {
                                                        exit$1 = 2;
                                                      } else {
                                                        tmp = "Predefined values are missing for " + StringExt.joinToSentence(Belt_Array.map(predefined, (function (c) {
                                                                    return "\"" + c + "\"";
                                                                  })));
                                                      }
                                                      if (exit$1 === 2) {
                                                        if (predefined.length !== 0) {
                                                          exit = 1;
                                                        } else if (blocked.length !== 0) {
                                                          tmp = StringExt.joinToSentence(Belt_Array.map(blocked, (function (c) {
                                                                      return "\"" + c + "\"";
                                                                    }))) + " " + AvoUtils.plural("contain", false, blocked.length, "contains") + " blocked values";
                                                        } else if (blocked.length !== 0) {
                                                          exit = 1;
                                                        } else {
                                                          tmp = undefined;
                                                        }
                                                      }
                                                      if (exit === 1) {
                                                        tmp = "Predefined values are missing for " + StringExt.joinToSentence(Belt_Array.map(predefined, (function (c) {
                                                                    return "\"" + c + "\"";
                                                                  }))) + " and " + StringExt.joinToSentence(Belt_Array.map(blocked, (function (c) {
                                                                    return "\"" + c + "\"";
                                                                  }))) + " " + AvoUtils.plural("contain", false, blocked.length, "contains") + " blocked values";
                                                      }
                                                      var tmp$1 = {
                                                        label: $$event.name,
                                                        path: Router.Link.addDrawerItem(undefined, {
                                                              NAME: "event",
                                                              VAL: [
                                                                $$event.id,
                                                                undefined,
                                                                undefined,
                                                                false
                                                              ]
                                                            }),
                                                        onNavigate: (function (param) {
                                                            return Curry._2(onNavigate, {
                                                                        id: $$event.id,
                                                                        name: $$event.name,
                                                                        type_: "Event"
                                                                      }, discrepancy);
                                                          }),
                                                        key: $$event.id
                                                      };
                                                      if (tmp !== undefined) {
                                                        tmp$1.subLabel = Caml_option.valFromOption(tmp);
                                                      }
                                                      return React.createElement(Discrepancies$Item, tmp$1);
                                                    })))),
                                      key: "EventsWithoutExpectedValues"
                                    });
                        }
                        if (variant === "EventsWithUnexpectedCase") {
                          var match$5 = discrepancy.VAL;
                          if (!match$5[0]) {
                            if (hasConsistentCase) {
                              return React.createElement(Discrepancies$DisabledGroup, {
                                          label: "All event names are in " + Case.tToJs(expectedEventCase) + " case",
                                          key: "EventsWithUnexpectedCaseDisabled"
                                        });
                            } else {
                              return null;
                            }
                          }
                          var events$3 = match$5[2];
                          if (!events$3) {
                            if (hasConsistentCase) {
                              return React.createElement(Discrepancies$ValidGroup, {
                                          label: "All event names are in " + Case.tToJs(expectedEventCase) + " case",
                                          key: "EventsWithUnexpectedCaseValid"
                                        });
                            } else {
                              return null;
                            }
                          }
                          if (!hasConsistentCase) {
                            return null;
                          }
                          var numEvents$2 = Belt_List.length(events$3);
                          return React.createElement(Discrepancies$WarningGroup, {
                                      discrepancy: discrepancy,
                                      schemaBundle: schemaBundle,
                                      numSchemaIssues: warningCount,
                                      label: numEvents$2 !== 1 ? String(numEvents$2) + " event names are not in " + Case.tToJs(expectedEventCase) + " case" : "1 event name is not in " + Case.tToJs(expectedEventCase) + " case",
                                      children: React.createElement("div", undefined, Belt_List.toArray(Belt_List.mapU(BeltListExtensions.group((function (a, b) {
                                                          var a$1 = Case.getCasingWithWorkspaceCase(a.name, expectedEventCase);
                                                          var b$1 = Case.getCasingWithWorkspaceCase(b.name, expectedEventCase);
                                                          if (a$1 === b$1) {
                                                            return 0;
                                                          } else if (a$1 < b$1) {
                                                            return -1;
                                                          } else {
                                                            return 1;
                                                          }
                                                        }), events$3), (function (events) {
                                                      var $$event = Belt_List.headExn(events);
                                                      return React.createElement("div", {
                                                                  key: Case.tToJs(Case.getCasingWithWorkspaceCase($$event.name, expectedEventCase)),
                                                                  className: Curry._1(Css.style, {
                                                                        hd: Css.padding4(Css.px(0), Css.px(15), Css.px(2), Css.px(38)),
                                                                        tl: /* [] */0
                                                                      })
                                                                }, React.createElement("div", {
                                                                      className: Curry._1(Css.style, {
                                                                            hd: Css.fontWeight(Styles.FontWeight.semi),
                                                                            tl: {
                                                                              hd: Css.fontSize(Styles.FontSize.small),
                                                                              tl: /* [] */0
                                                                            }
                                                                          })
                                                                    }, String(Belt_List.length(events)) + " " + Case.tToJs(Case.getCasingWithWorkspaceCase($$event.name, expectedEventCase)) + " case:"), React.createElement("ul", {
                                                                      className: Curry._1(Css.style, {
                                                                            hd: Css.margin2(Css.px(6), Css.px(0)),
                                                                            tl: /* [] */0
                                                                          })
                                                                    }, Belt_List.toArray(Belt_List.mapU(events, (function ($$event) {
                                                                                return React.createElement(Discrepancies$Item, {
                                                                                            label: $$event.name,
                                                                                            path: Router.Link.addDrawerItem(undefined, {
                                                                                                  NAME: "event",
                                                                                                  VAL: [
                                                                                                    $$event.id,
                                                                                                    undefined,
                                                                                                    undefined,
                                                                                                    false
                                                                                                  ]
                                                                                                }),
                                                                                            onNavigate: (function (param) {
                                                                                                return Curry._2(onNavigate, {
                                                                                                            id: $$event.id,
                                                                                                            name: $$event.name,
                                                                                                            type_: "Event"
                                                                                                          }, discrepancy);
                                                                                              }),
                                                                                            key: $$event.id
                                                                                          });
                                                                              })))));
                                                    })))),
                                      key: "EventsWithUnexpectedCaseInvalid"
                                    });
                        }
                        if (variant === "PropertyConflictOnEvent") {
                          var match$6 = discrepancy.VAL;
                          if (!match$6[0]) {
                            return React.createElement(Discrepancies$DisabledGroup, {
                                        label: "No conflicting properties",
                                        key: "PropertyConflictOnEventDisabled"
                                      });
                          }
                          var events$4 = match$6[2];
                          if (!events$4) {
                            return React.createElement(Discrepancies$ValidGroup, {
                                        label: "No conflicting properties",
                                        key: "PropertyConflictOnEventValid"
                                      });
                          }
                          var numEvents$3 = Belt_List.length(events$4);
                          return React.createElement(Discrepancies$WarningGroup, {
                                      discrepancy: discrepancy,
                                      schemaBundle: schemaBundle,
                                      numSchemaIssues: warningCount,
                                      label: numEvents$3 !== 1 ? String(numEvents$3) + " events have conflicting properties" : "1 event has conflicting properties",
                                      children: React.createElement("div", {
                                            className: Curry._1(Css.style, {
                                                  hd: Css.padding4(Css.px(0), Css.px(15), Css.px(2), Css.px(38)),
                                                  tl: /* [] */0
                                                })
                                          }, React.createElement("ul", {
                                                className: Curry._1(Css.style, {
                                                      hd: Css.margin2(Css.px(6), Css.px(0)),
                                                      tl: /* [] */0
                                                    })
                                              }, Belt_List.toArray(Belt_List.mapU(events$4, (function ($$event) {
                                                          return React.createElement(Discrepancies$Item, {
                                                                      label: $$event.name,
                                                                      path: Router.Link.addDrawerItem(undefined, {
                                                                            NAME: "event",
                                                                            VAL: [
                                                                              $$event.id,
                                                                              undefined,
                                                                              undefined,
                                                                              false
                                                                            ]
                                                                          }),
                                                                      onNavigate: (function (param) {
                                                                          return Curry._2(onNavigate, {
                                                                                      id: $$event.id,
                                                                                      name: $$event.name,
                                                                                      type_: "Event"
                                                                                    }, discrepancy);
                                                                        }),
                                                                      key: $$event.id
                                                                    });
                                                        }))))),
                                      key: "PropertyConflictOnEventWarnings"
                                    });
                        }
                        if (variant === "PropertiesWithoutDescription") {
                          var match$7 = discrepancy.VAL;
                          if (!match$7[0]) {
                            return React.createElement(Discrepancies$DisabledGroup, {
                                        label: "All properties have a description",
                                        key: "PropertiesWithoutDescriptionDisabled"
                                      });
                          }
                          var properties$2 = match$7[2];
                          if (!properties$2) {
                            return React.createElement(Discrepancies$ValidGroup, {
                                        label: "All properties have a description",
                                        key: "PropertiesWithoutDescriptionValid"
                                      });
                          }
                          var numProperties = Belt_List.length(properties$2);
                          return React.createElement(Discrepancies$WarningGroup, {
                                      discrepancy: discrepancy,
                                      schemaBundle: schemaBundle,
                                      numSchemaIssues: warningCount,
                                      label: numProperties !== 1 ? String(numProperties) + " properties don't have a description" : "1 property does not have a description",
                                      children: React.createElement("ul", {
                                            className: Curry._1(Css.style, {
                                                  hd: Css.padding4(Css.px(0), Css.px(15), Css.px(2), Css.px(38)),
                                                  tl: {
                                                    hd: Css.margin2(Css.px(6), Css.px(0)),
                                                    tl: /* [] */0
                                                  }
                                                })
                                          }, Belt_List.toArray(Belt_List.mapU(properties$2, (function (property) {
                                                      return React.createElement(Discrepancies$Item, {
                                                                  label: property.name,
                                                                  path: Router.Link.addDrawerItem(undefined, {
                                                                        NAME: "property",
                                                                        VAL: [
                                                                          property.id,
                                                                          undefined
                                                                        ]
                                                                      }),
                                                                  onNavigate: (function (param) {
                                                                      return Curry._2(onNavigate, {
                                                                                  id: property.id,
                                                                                  name: property.name,
                                                                                  type_: "Property"
                                                                                }, discrepancy);
                                                                    }),
                                                                  key: property.id
                                                                });
                                                    })))),
                                      key: "PropertiesWithoutDescriptionInvalid"
                                    });
                        }
                        var match$8 = discrepancy.VAL;
                        if (!match$8[0]) {
                          return React.createElement(Discrepancies$DisabledGroup, {
                                      label: "All property names are in " + Case.tToJs(expectedPropertyCase) + " case",
                                      key: "PropertiesWithUnexpectedCaseDisabled"
                                    });
                        }
                        var properties$3 = match$8[2];
                        if (!properties$3) {
                          return React.createElement(Discrepancies$ValidGroup, {
                                      label: "All property names are in " + Case.tToJs(expectedPropertyCase) + " case",
                                      key: "PropertiesWithUnexpectedCaseValid"
                                    });
                        }
                        var numProps$1 = Belt_List.length(properties$3);
                        return React.createElement(Discrepancies$WarningGroup, {
                                    discrepancy: discrepancy,
                                    schemaBundle: schemaBundle,
                                    numSchemaIssues: warningCount,
                                    label: numProps$1 !== 1 ? String(numProps$1) + " property names are not in " + Case.tToJs(expectedPropertyCase) + " case" : "1 property name is not in " + Case.tToJs(expectedPropertyCase) + " case",
                                    children: React.createElement("div", undefined, Belt_List.toArray(Belt_List.mapU(BeltListExtensions.group((function (a, b) {
                                                        var a$1 = Case.getCasingWithWorkspaceCase(a.name, expectedPropertyCase);
                                                        var b$1 = Case.getCasingWithWorkspaceCase(b.name, expectedPropertyCase);
                                                        if (a$1 === b$1) {
                                                          return 0;
                                                        } else if (a$1 < b$1) {
                                                          return -1;
                                                        } else {
                                                          return 1;
                                                        }
                                                      }), properties$3), (function (properties) {
                                                    var property = Belt_List.headExn(properties);
                                                    return React.createElement("div", {
                                                                key: Case.tToJs(Case.getCasingWithWorkspaceCase(property.name, expectedPropertyCase)),
                                                                className: Curry._1(Css.style, {
                                                                      hd: Css.padding4(Css.px(0), Css.px(15), Css.px(2), Css.px(38)),
                                                                      tl: /* [] */0
                                                                    })
                                                              }, React.createElement("div", {
                                                                    className: Curry._1(Css.style, {
                                                                          hd: Css.fontWeight(Styles.FontWeight.semi),
                                                                          tl: {
                                                                            hd: Css.fontSize(Styles.FontSize.small),
                                                                            tl: /* [] */0
                                                                          }
                                                                        })
                                                                  }, String(Belt_List.length(properties)) + " " + Case.tToJs(Case.getCasingWithWorkspaceCase(property.name, expectedPropertyCase)) + " case:"), React.createElement("ul", {
                                                                    className: Curry._1(Css.style, {
                                                                          hd: Css.margin2(Css.px(6), Css.px(0)),
                                                                          tl: /* [] */0
                                                                        })
                                                                  }, Belt_List.toArray(Belt_List.mapU(properties, (function (property) {
                                                                              return React.createElement(Discrepancies$Item, {
                                                                                          label: property.name,
                                                                                          path: Router.Link.addDrawerItem(undefined, {
                                                                                                NAME: "property",
                                                                                                VAL: [
                                                                                                  property.id,
                                                                                                  undefined
                                                                                                ]
                                                                                              }),
                                                                                          onNavigate: (function (param) {
                                                                                              return Curry._2(onNavigate, {
                                                                                                          id: property.id,
                                                                                                          name: property.name,
                                                                                                          type_: "Property"
                                                                                                        }, discrepancy);
                                                                                            }),
                                                                                          key: property.id
                                                                                        });
                                                                            })))));
                                                  })))),
                                    key: "PropertiesWithUnexpectedCaseInvalid"
                                  });
                      }))));
}

var Popup = {
  make: Discrepancies$Popup
};

function Discrepancies(Props) {
  var globalSend = GlobalSendContext.use(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var discrepancies = React.useContext(DiscrepancyContext.context);
  var match = React.useState(function () {
        return false;
      });
  var setIsOpen = match[1];
  var buttonRef = React.useRef(null);
  var previousWarningCount = Hooks.usePrevious1(discrepancies.warningCount);
  var hasConsistentCase = React.useMemo((function () {
          var match = ValidationConfigV2.EventsV2.getCase(discrepancies.config.events);
          if (typeof match === "object") {
            return true;
          } else {
            return false;
          }
        }), [discrepancies.config.events.nameComponents]);
  React.useEffect((function () {
          if (previousWarningCount === 0 && discrepancies.warningCount > 0) {
            AnalyticsRe.issuePreviewDisplayed(schemaBundle, discrepancies.warningCount, "Sidebar", undefined, schemaBundle.branchId, schemaBundle.schemaId);
          }
          
        }), [discrepancies.warningCount]);
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.color(Styles.Color.light12),
                    tl: /* [] */0
                  })
            }, React.createElement("button", {
                  ref: buttonRef,
                  className: Curry._1(Css.merge, {
                        hd: Curry._1(Css.style, Styles.button),
                        tl: {
                          hd: Curry._1(Css.style, {
                                hd: Css.padding2(Css.px(discrepancies.warningCount === 0 ? 4 : 2), Css.px(6)),
                                tl: {
                                  hd: Css.margin(Css.px(0)),
                                  tl: {
                                    hd: Css.color(Styles.Color.light12),
                                    tl: {
                                      hd: Css.backgroundColor(discrepancies.warningCount === 0 ? Styles.Color.green : Styles.Color.orange),
                                      tl: {
                                        hd: Css.borderRadius(Css.px(16)),
                                        tl: {
                                          hd: Css.active({
                                                hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, undefined, undefined, "transparent")),
                                                tl: /* [] */0
                                              }),
                                          tl: {
                                            hd: Css.focus({
                                                  hd: Css.outlineStyle("none"),
                                                  tl: {
                                                    hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.darkBlue)),
                                                    tl: /* [] */0
                                                  }
                                                }),
                                            tl: {
                                              hd: Css.transition({
                                                    NAME: "ms",
                                                    VAL: Styles.Duration.$$short
                                                  }, undefined, undefined, "all"),
                                              tl: {
                                                hd: Css.hover({
                                                      hd: Css.backgroundColor(Styles.Color.lighten(discrepancies.warningCount === 0 ? Styles.Color.green : Styles.Color.orange, 8)),
                                                      tl: /* [] */0
                                                    }),
                                                tl: {
                                                  hd: Css.display("flex"),
                                                  tl: {
                                                    hd: Css.alignItems("center"),
                                                    tl: {
                                                      hd: Css.fontSize(Styles.FontSize.small),
                                                      tl: {
                                                        hd: Css.fontWeight(Styles.FontWeight.semi),
                                                        tl: /* [] */0
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }),
                          tl: /* [] */0
                        }
                      }),
                  onClick: (function (param) {
                      return Curry._1(setIsOpen, (function (isOpen) {
                                    return !isOpen;
                                  }));
                    })
                }, discrepancies.warningCount === 0 ? React.createElement(IconCheckmark.make, {
                        size: 10,
                        color: Styles.Color.light12
                      }) : React.createElement(IconError.make, {
                        size: 12,
                        color: Styles.Color.light12
                      }), discrepancies.warningCount === 0 ? null : React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.marginLeft(Css.px(4)),
                              tl: /* [] */0
                            })
                      }, String(discrepancies.warningCount))), React.createElement(PortalMenu.make, {
                  position: "Right",
                  placement: "Start",
                  distance: 2,
                  visible: match[0],
                  onClose: (function (param) {
                      return Curry._1(setIsOpen, (function (param) {
                                    return false;
                                  }));
                    }),
                  referenceItemRef: buttonRef,
                  children: React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.position("absolute"),
                              tl: {
                                hd: Css.borderRadius(Styles.Border.radius),
                                tl: {
                                  hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, Css.px(4), Css.px(24), undefined, undefined, Css.rgba(0, 0, 0, {
                                                NAME: "percent",
                                                VAL: 10.0
                                              }))),
                                  tl: {
                                    hd: Css.top(Css.px(-15)),
                                    tl: {
                                      hd: Css.left({
                                            NAME: "add",
                                            VAL: [
                                              Css.pct(100),
                                              Css.px(6)
                                            ]
                                          }),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            })
                      }, React.createElement(Discrepancies$Popup, {
                            discrepancies: discrepancies.discrepancies,
                            hasConsistentCase: hasConsistentCase,
                            schemaBundle: schemaBundle,
                            warningCount: discrepancies.warningCount,
                            headerExtra: React.createElement(TextButton.make, {
                                  onClick: (function (param) {
                                      Curry._1(globalSend, {
                                            TAG: /* OpenSlideOver */6,
                                            _0: {
                                              NAME: "DiscrepanciesConfig",
                                              VAL: undefined
                                            }
                                          });
                                      return Curry._1(setIsOpen, (function (param) {
                                                    return false;
                                                  }));
                                    }),
                                  id: "audit-tour-config-button",
                                  style: "Blue",
                                  size: "Small",
                                  children: "CONFIGURE"
                                }),
                            onNavigate: (function (item, discrepancy) {
                                var variant = discrepancy.NAME;
                                return AnalyticsRe.auditIssueInteracted(schemaBundle, item.id, item.name, item.type_, "Sidebar", "OpenIssueItem", variant === "SameEventName" ? "SameNameEvents" : (
                                              variant === "SamePropertyName" ? "SameNameProperty" : (
                                                  variant === "PropertiesWithAnyType" ? "PropertyTypeNotDefined" : (
                                                      variant === "EventsWithoutDescription" ? "EventMissingDescription" : (
                                                          variant === "EventsWithoutExpectedValues" ? "EventAdvancedNamingConventionDiscrepancy" : (
                                                              variant === "EventsWithUnexpectedCase" ? "EventCommonCasingDiscrepancy" : (
                                                                  variant === "PropertyConflictOnEvent" ? "PropertyConflicts" : (
                                                                      variant === "PropertiesWithoutDescription" ? "PropertyMissingDescription" : "PropertyCommonCasingDiscrepancy"
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            ), schemaBundle.schemaId, schemaBundle.branchId);
                              }),
                            expectedEventCase: discrepancies.expectedEventCase,
                            expectedPropertyCase: discrepancies.expectedPropertyCase
                          }))
                }));
}

var make = Discrepancies;

export {
  Item ,
  WarningGroup ,
  ValidGroup ,
  DisabledGroup ,
  Popup ,
  make ,
  
}
/* Css Not a pure module */
