// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as GetRegexValidationUseCase from "./GetRegexValidationUseCase.mjs";

function clear(model) {
  return {
          types: model.types,
          properties: Belt_List.mapU(model.properties, (function (property) {
                  if (property.TAG === /* PropertyRef */0) {
                    return {
                            TAG: /* PropertyRef */0,
                            _0: property._0
                          };
                  }
                  var property$1 = property._0;
                  var hasRegex = GetRegexValidationUseCase.get(property$1) !== undefined;
                  return {
                          TAG: /* Property */1,
                          _0: {
                            id: property$1.id,
                            name: property$1.name,
                            uniqueName: property$1.uniqueName,
                            description: property$1.description,
                            type_: property$1.type_,
                            sendAs: property$1.sendAs,
                            validations: Belt_List.keepU(property$1.validations, (function (validation) {
                                    if (typeof validation === "object" && validation.NAME === "Matches") {
                                      return !hasRegex;
                                    } else {
                                      return true;
                                    }
                                  })),
                            eventSpecificAllowedPropertyValues: hasRegex ? [] : property$1.eventSpecificAllowedPropertyValues,
                            auto: property$1.auto,
                            builtIn: property$1.builtIn,
                            analyticsTools: property$1.analyticsTools,
                            devPlatforms: property$1.devPlatforms,
                            list: property$1.list,
                            operation: property$1.operation,
                            optionalDeprecated: property$1.optionalDeprecated,
                            excludedSourcesDeprecated: property$1.excludedSourcesDeprecated,
                            optionalWhenInObject: property$1.optionalWhenInObject,
                            absence: property$1.absence,
                            globalRequirementsMetadata: property$1.globalRequirementsMetadata
                          }
                        };
                })),
          propertyBundles: model.propertyBundles,
          events: model.events,
          migrations: model.migrations,
          sources: model.sources,
          destinations: model.destinations,
          groupTypes: model.groupTypes,
          goals: model.goals,
          metrics: model.metrics,
          archive: model.archive,
          openBranches: model.openBranches,
          branchPointer: model.branchPointer,
          rules: model.rules,
          integrations: model.integrations,
          globalRequirements: model.globalRequirements
        };
}

export {
  clear ,
  
}
/* No side effect */
