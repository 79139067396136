// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Icons from "../Icons.mjs";
import * as React from "react";
import * as Router from "../Router.mjs";
import * as Styles from "../styles.mjs";
import * as Actions from "../actions.mjs";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as Sidebar from "../Sidebar.mjs";
import * as Firebase from "../../../bs-firestore/src/Firebase.mjs";
import * as Redirect from "../Redirect.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as PromiseExt from "../externals/PromiseExt.mjs";
import * as TextButton from "../TextButton.mjs";
import * as AnalyticsRe from "../analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as NamedBranch from "../NamedBranch.mjs";
import * as RouterStore from "../RouterStore.mjs";
import * as ImportAction from "./ImportAction.mjs";
import * as ImportReview from "./ImportReview.mjs";
import * as ImportLanding from "./ImportLanding.mjs";
import * as SandboxBanner from "../SandboxBanner.mjs";
import * as FramerMotion from "framer-motion";
import * as AnalyticsUtils from "../analyticsUtils.mjs";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as NewBranchModal from "../NewBranchModal.mjs";
import * as WorkspaceContext from "../WorkspaceContext.mjs";
import * as SendActionsContext from "../SendActionsContext.mjs";
import * as SchemaBundleContext from "../SchemaBundleContext.mjs";
import * as FirebaseFetcherHooks from "../FirebaseFetcherHooks.mjs";
import * as ImportReview__V2__Utils from "./ImportReview__V2__Utils.mjs";

var rootStyles = Curry._1(Css.style, {
      hd: Css.marginLeft(Css.px(-Sidebar.sidebarWidth | 0)),
      tl: /* [] */0
    });

function logoStyles(withSandboxBanner) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, Styles.buttonReset),
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.position("fixed"),
                      tl: {
                        hd: Css.left(Css.px(22)),
                        tl: {
                          hd: Css.top(Css.px(26 + SandboxBanner.getHeight(withSandboxBanner) | 0)),
                          tl: {
                            hd: Css.zIndex(2),
                            tl: /* [] */0
                          }
                        }
                      }
                    }),
                tl: /* [] */0
              }
            });
}

var closeStyles = Curry._1(Css.style, {
      hd: Css.position("fixed"),
      tl: {
        hd: Css.right(Css.px(22)),
        tl: {
          hd: Css.top(Css.px(20)),
          tl: {
            hd: Css.zIndex(2),
            tl: /* [] */0
          }
        }
      }
    });

var contentStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.alignItems("center"),
          tl: /* [] */0
        }
      }
    });

function lastActionOnBranchQuery(schemaId, branchId) {
  return Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("actions").where("branchId", "==", branchId).orderBy("createdAt", "desc").limit(1);
}

function Import(Props) {
  var currentBranch = Props.currentBranch;
  var openBranches = Props.openBranches;
  var model = Props.model;
  var viewerRole = Props.viewerRole;
  var workspace = WorkspaceContext.use(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var schemaRoute = RouterStore.Schema.useSchemaRoute(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var importStartedLocationQueryParam = ImportReview__V2__Utils.useImportStartedLocationQueryParam(undefined);
  var match = React.useState(function () {
        
      });
  var setParsedTrackingPlan = match[1];
  var parsedTrackingPlan = match[0];
  var match$1 = React.useState(function () {
        return "AddOnly";
      });
  var setImportMethod = match$1[1];
  var importMethod = match$1[0];
  React.useEffect((function () {
          AnalyticsRe.importStarted(schemaBundle, Belt_List.length(model.events), Belt_List.length(model.properties), importStartedLocationQueryParam.value, schemaBundle.branchId, schemaBundle.schemaId);
          
        }), []);
  var onUploadSuccess = React.useCallback((function (parsedTrackingPlan) {
          Curry._1(setParsedTrackingPlan, (function (param) {
                  return parsedTrackingPlan;
                }));
          return Router.Schema.pushImportRoute(undefined, importStartedLocationQueryParam.value, {
                      NAME: "import",
                      VAL: "review"
                    });
        }), []);
  var onReviewAccepted = function (selectedBranch, parsedTrackingPlan) {
    var newAndUpdatedProperties = Belt_Array.concat(parsedTrackingPlan.newProperties, parsedTrackingPlan.updatedProperties);
    var createBranchAsPromise = function (branchName, sendActions, schemaBundle) {
      return new Promise((function (resolve, reject) {
                    return NewBranchModal.createBranch(branchName, "Import", sendActions, schemaBundle, (function (error) {
                                  return reject(error);
                                }), (function (branchId) {
                                  return resolve(branchId);
                                }), undefined);
                  }));
    };
    $$Promise.$$catch((
                  typeof selectedBranch === "object" ? (
                      selectedBranch.NAME === "Create" ? createBranchAsPromise(selectedBranch.VAL, sendActions, schemaBundle).then(function (branchId) {
                              return Promise.resolve({
                                          NAME: "Branch",
                                          VAL: branchId
                                        });
                            }) : Promise.resolve({
                              NAME: "Branch",
                              VAL: selectedBranch.VAL.id
                            })
                    ) : Promise.resolve("Master")
                ).then(function (writeToBranch) {
                    return new Promise((function (resolve, reject) {
                                  return Curry.app(sendActions, [
                                              writeToBranch,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              (function (branch) {
                                                  return resolve(branch);
                                                }),
                                              (function (error) {
                                                  return reject(error);
                                                }),
                                              [[
                                                  "StartedImport",
                                                  {}
                                                ]]
                                            ]);
                                }));
                  }).then(function (branch) {
                  return lastActionOnBranchQuery(workspace.id, NamedBranch.getId(branch)).get().then(function (snapshot) {
                              var lastAction = Belt_Option.mapU(Belt_Array.get(snapshot.docs, 0), (function (doc) {
                                      return FirebaseFetcherHooks.transformAction(doc.data());
                                    }));
                              return [
                                      branch,
                                      lastAction
                                    ];
                            });
                }).then(function (param) {
                var branch = param[0];
                var importActions = Belt_List.toArray(ImportAction.buildActions(parsedTrackingPlan, model, importMethod));
                var modelWithImport = Belt_Array.reduceU(importActions, model, Actions.reduce);
                return Actions.snapshot(workspace.id, NamedBranch.getId(branch), modelWithImport, Belt_Option.getExn(param[1])).then(function (param) {
                            var eventIds = Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.map(Belt_Array.concat(parsedTrackingPlan.newEvents, parsedTrackingPlan.updatedEvents), (function ($$event) {
                                            return $$event.id;
                                          }))));
                            var propertyIds = Belt_Array.map(newAndUpdatedProperties, (function (property) {
                                    return property.id;
                                  }));
                            var sourceIds = parsedTrackingPlan.sources;
                            var propertyGroupIds = Belt_Array.map(parsedTrackingPlan.propertyBundles, (function (propertyGroup) {
                                    return propertyGroup.id;
                                  }));
                            return new Promise((function (resolve, reject) {
                                          var match = importStartedLocationQueryParam.value;
                                          return Curry.app(sendActions, [
                                                      NamedBranch.toUnnamed(branch),
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      (function (_branch) {
                                                          return resolve(branch);
                                                        }),
                                                      (function (error) {
                                                          return reject(error);
                                                        }),
                                                      [[
                                                          {
                                                            NAME: "CompletedImport",
                                                            VAL: [
                                                              eventIds.length,
                                                              propertyIds.length,
                                                              Belt_Array.keepU(importActions, (function (importAction) {
                                                                      if (typeof importAction === "object") {
                                                                        return importAction.NAME === "CreateSource";
                                                                      } else {
                                                                        return false;
                                                                      }
                                                                    })).length,
                                                              match === "OnboardingChecklist" ? "onboardingChecklist" : (
                                                                  match === "TrackingPlanEventsEmptyState" ? "trackingPlanEventsEmptyState" : (
                                                                      match === "InspectorIssuesHeader" ? "inspectorIssuesHeader" : (
                                                                          match === "DirectLink" ? "directLink" : (
                                                                              match === "InspectorEventsHeader" ? "inspectorEventsHeader" : (
                                                                                  match === "InspectorIssueDetails" ? "inspectorIssueDetails" : (
                                                                                      match === "InspectorEventsSidebar" ? "inspectorEventsSidebar" : (
                                                                                          match === "InspectorEventsTableRow" ? "inspectorEventsTableRow" : (
                                                                                              match === "CmdPalette" ? "cmdPalette" : "sidebarSettingsCog"
                                                                                            )
                                                                                        )
                                                                                    )
                                                                                )
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            ]
                                                          },
                                                          {
                                                            eventIds: eventIds,
                                                            eventsQuery: eventIds,
                                                            propertyIds: propertyIds,
                                                            propertiesQuery: propertyIds,
                                                            sourceIds: sourceIds,
                                                            sourcesQuery: sourceIds,
                                                            propertyGroupIds: propertyGroupIds,
                                                            propertyGroupsQuery: propertyGroupIds
                                                          }
                                                        ]]
                                                    ]);
                                        }));
                          });
              }).then(function (branch) {
              AnalyticsRe.importCompleted(AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), Belt_List.length(model.events) + Belt_Array.concat(parsedTrackingPlan.newEvents, parsedTrackingPlan.updatedEvents).length | 0, Belt_List.length(model.properties) + newAndUpdatedProperties.length | 0, newAndUpdatedProperties.length, Belt_Array.concat(parsedTrackingPlan.newEvents, parsedTrackingPlan.updatedEvents).length, parsedTrackingPlan.newEvents.length, parsedTrackingPlan.updatedEvents.length, importStartedLocationQueryParam.value, NamedBranch.getId(branch), schemaBundle.schemaId);
              Router.Schema.pushImportRoute(branch ? ({
                        NAME: "branch",
                        VAL: branch._0
                      }) : "master", importStartedLocationQueryParam.value, {
                    NAME: "import",
                    VAL: "success"
                  });
              return Promise.resolve(undefined);
            }), (function (error) {
            console.error(error);
            return Promise.resolve((window.alert("Something went wrong when importing the tracking plan.\n" + PromiseExt.getErrorMessage(error)), undefined));
          }));
    
  };
  var handleClose = function (param) {
    if (schemaRoute !== ({
          NAME: "import",
          VAL: "review"
        }) || window.confirm("You have an unfinished import draft. Are you sure you want to discard it?")) {
      return Router.Schema.pushSchemaRoute(undefined, undefined, "dashboard");
    }
    
  };
  var hasEventsOnModel = React.useMemo((function () {
          return model.events !== /* [] */0;
        }), []);
  var tmp;
  var exit = 0;
  if (typeof schemaRoute === "object" && schemaRoute.NAME === "import") {
    var importSubRoute = schemaRoute.VAL;
    if (importSubRoute === "success") {
      tmp = React.createElement(Redirect.make, {
            path: Router.Link.getSchemaRouteLink(undefined, "events")
          });
    } else if (importSubRoute === "index") {
      tmp = React.createElement(ImportLanding.make, {
            model: model,
            onUploadSuccess: onUploadSuccess,
            branchId: Actions.branchToId(currentBranch),
            importMethod: importMethod,
            onImportMethodChange: (function (importMethod) {
                return Curry._1(setImportMethod, (function (param) {
                              return Belt_Option.getWithDefault(importMethod, "AddOnly");
                            }));
              })
          });
    } else if (parsedTrackingPlan !== undefined) {
      tmp = React.createElement(ImportReview.make, {
            parsedTrackingPlan: parsedTrackingPlan,
            onContinue: onReviewAccepted,
            hasEventsOnModel: hasEventsOnModel,
            currentBranch: currentBranch,
            openBranches: openBranches,
            model: model,
            viewerRole: viewerRole,
            importMethod: importMethod
          });
    } else {
      Router.Schema.pushImportRoute(undefined, importStartedLocationQueryParam.value, {
            NAME: "import",
            VAL: "index"
          });
      tmp = null;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = React.createElement(Redirect.make, {
          path: Router.Link.getSchemaRouteLink(undefined, "dashboard")
        });
  }
  return React.createElement("div", {
              className: rootStyles
            }, React.createElement("button", {
                  className: logoStyles(workspace.isSandbox),
                  onClick: handleClose
                }, React.createElement(Icons.AvoLogo.make, {
                      width: 60,
                      fill: Styles.Color.light12
                    })), React.createElement("div", {
                  className: closeStyles
                }, React.createElement(TextButton.make, {
                      onClick: handleClose,
                      size: "Small",
                      children: "Close"
                    })), React.createElement(FramerMotion.AnimatePresence, {
                  exitBeforeEnter: true,
                  children: React.createElement(FramerMotion.motion.div, {
                        animate: {
                          opacity: 1,
                          x: 0
                        },
                        transition: {
                          duration: 0.3
                        },
                        initial: {
                          opacity: 0,
                          x: 100
                        },
                        exit: {
                          opacity: 0,
                          x: -100
                        },
                        className: contentStyles,
                        key: schemaRoute === ({
                            NAME: "import",
                            VAL: "index"
                          }) ? "index" : "review",
                        children: tmp
                      })
                }));
}

var make = Import;

export {
  rootStyles ,
  logoStyles ,
  closeStyles ,
  contentStyles ,
  lastActionOnBranchQuery ,
  make ,
  
}
/* rootStyles Not a pure module */
