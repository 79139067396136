// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Case from "../../../bs-case/src/case.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "../Hooks.mjs";
import * as React from "react";
import * as Styles from "../styles.mjs";
import * as Confetti from "../Confetti.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as AnalyticsRe from "../analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as AvoEngagement from "../avoEngagement/AvoEngagement.mjs";
import * as SchemaBundleContext from "../SchemaBundleContext.mjs";
import * as AvoProductTour__TourProvider from "./AvoProductTour__TourProvider.mjs";

function useOnStart(engagement) {
  var schemaBundle = SchemaBundleContext.use(undefined);
  var eventSent = React.useRef(false);
  React.useEffect((function () {
          if (engagement.state.active && !eventSent.current) {
            eventSent.current = true;
            AnalyticsRe.productFlowStarted(AnalyticsRe.Bundle.productFlows(Case.title(engagement.id), engagement.id, String(engagement.version)), schemaBundle, schemaBundle.schemaId);
          }
          
        }), [engagement.state.active]);
  
}

function useOnComplete(onComplete, setShowConfetti, engagement) {
  var schemaBundle = SchemaBundleContext.use(undefined);
  var eventSent = React.useRef(false);
  var previousCompleted = Hooks.usePrevious1(engagement.state.completed);
  React.useEffect((function () {
          if (!previousCompleted && engagement.state.completed && !eventSent.current) {
            eventSent.current = true;
            AnalyticsRe.productFlowCompleted(AnalyticsRe.Bundle.productFlows(Case.title(engagement.id), engagement.id, String(engagement.version)), schemaBundle, schemaBundle.schemaId);
            Curry._1(setShowConfetti, (function (param) {
                    return true;
                  }));
            setTimeout((function (param) {
                    return Belt_Option.forEach(onComplete, (function (fn) {
                                  return Curry._1(fn, undefined);
                                }));
                  }), 0);
          }
          
        }), [engagement.state.completed]);
  
}

function AvoProductTour__Root(Props) {
  var confettiOnCompleteOpt = Props.confettiOnComplete;
  var engagementId = Props.engagementId;
  var onComplete = Props.onComplete;
  var offerReplayAtEnd = Props.offerReplayAtEnd;
  var showStepCount = Props.showStepCount;
  var stepStartOffsetOpt = Props.stepStartOffset;
  var totalStepsOverride = Props.totalStepsOverride;
  var children = Props.children;
  var confettiOnComplete = confettiOnCompleteOpt !== undefined ? confettiOnCompleteOpt : false;
  var stepStartOffset = stepStartOffsetOpt !== undefined ? stepStartOffsetOpt : 0;
  var match = AvoEngagement.Engagement.use(engagementId);
  var engagement = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setShowConfetti = match$1[1];
  var showConfetti = match$1[0];
  React.useEffect((function () {
          var timeout = {
            contents: undefined
          };
          if (showConfetti) {
            timeout.contents = Caml_option.some(setTimeout((function (param) {
                        return Curry._1(setShowConfetti, (function (param) {
                                      return false;
                                    }));
                      }), 10000));
          }
          return (function (param) {
                    return Belt_Option.forEach(timeout.contents, (function (prim) {
                                  clearTimeout(prim);
                                  
                                }));
                  });
        }), [showConfetti]);
  useOnStart(engagement);
  useOnComplete(onComplete, setShowConfetti, engagement);
  var tmp;
  if (engagement.state.active) {
    var tmp$1 = {
      engagement: engagement,
      getStepNumber: (function (stepId) {
          return Belt_Option.map(Curry._1(AvoEngagement.Engagement.SortedBlocks.getStepNumber, stepId), (function (stepNumber) {
                        return stepStartOffset + stepNumber | 0;
                      }));
        }),
      intent: match[1],
      children: children,
      key: engagement.id
    };
    if (offerReplayAtEnd !== undefined) {
      tmp$1.offerReplayAtEnd = Caml_option.valFromOption(offerReplayAtEnd);
    }
    if (showStepCount !== undefined) {
      tmp$1.showStepCount = Caml_option.valFromOption(showStepCount);
    }
    if (totalStepsOverride !== undefined) {
      tmp$1.totalStepsOverride = Caml_option.valFromOption(totalStepsOverride);
    }
    tmp = React.createElement(AvoProductTour__TourProvider.make, tmp$1);
  } else {
    tmp = null;
  }
  return React.createElement(React.Fragment, undefined, showConfetti && confettiOnComplete ? React.createElement(Confetti.make, {
                    numberOfPieces: 600,
                    width: window.innerWidth,
                    height: window.innerHeight,
                    colors: Belt_Array.map(Styles.Color.secondaryColors, Styles.Color.toString),
                    run: showConfetti,
                    recycle: false
                  }) : null, tmp);
}

var make = AvoProductTour__Root;

export {
  useOnStart ,
  useOnComplete ,
  make ,
  
}
/* Case Not a pure module */
