// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Models from "./Models.mjs";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as NamedBranch from "./NamedBranch.mjs";
import * as App from "firebase/app";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";

function addCollaborator(schemaBundle, userId, currentBranch, collaborationType, collaborationId, collaboratorId, collaboratorEmail, adderAction, sendActions) {
  var batch = Firebase.app(undefined).firestore().batch();
  var collaborationRef = Firebase.app(undefined).firestore().collection("schemas").doc(schemaBundle.schemaId).collection("collaboration").doc(collaborationId);
  batch.set(collaborationRef, {
        itemType: "branch",
        id: collaborationId
      });
  var collaboratorRef = Firebase.app(undefined).firestore().collection("schemas").doc(schemaBundle.schemaId).collection("collaboration").doc(collaborationId).collection("collaborators").doc(collaboratorId);
  batch.set(collaboratorRef, {
        itemType: "branch",
        itemId: collaborationId,
        id: collaboratorId,
        createdAt: App.default.firestore.FieldValue.serverTimestamp(),
        createdBy: userId,
        removedAt: null,
        collaborationTrigger: Models.CollaborationTrigger.tToJs("AddCollaborator")
      });
  var action_1 = [
    {
      NAME: "CollaborationItemBranch",
      VAL: collaborationId
    },
    userId,
    collaboratorId
  ];
  var action = {
    NAME: "CollaboratorAdded",
    VAL: action_1
  };
  var context = {
    branchId: collaborationId,
    branchQuery: collaborationId
  };
  return Curry.app(sendActions, [
              currentBranch,
              undefined,
              Caml_option.some(batch),
              undefined,
              undefined,
              undefined,
              undefined,
              (function (branch) {
                  return AnalyticsRe.collaboratorAdded(AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), collaborationId, NamedBranch.getId(branch), "Branch", collaborationType, collaboratorEmail, adderAction, userId === collaboratorId ? "Self" : "OtherMember", NamedBranch.getId(branch), schemaBundle.schemaId);
                }),
              undefined,
              [[
                  action,
                  context
                ]]
            ]);
}

function removeCollaborator(schemaBundle, userId, currentBranch, collaborationType, collaborationId, collaboratorId, sendActions) {
  var batch = Firebase.app(undefined).firestore().batch();
  var collaboratorRef = Firebase.app(undefined).firestore().collection("schemas").doc(schemaBundle.schemaId).collection("collaboration").doc(collaborationId).collection("collaborators").doc(collaboratorId);
  batch.update(collaboratorRef, {
        removedAt: App.default.firestore.FieldValue.serverTimestamp(),
        removedBy: userId
      });
  var action_1 = [
    {
      NAME: "CollaborationItemBranch",
      VAL: collaborationId
    },
    userId,
    collaboratorId
  ];
  var action = {
    NAME: "CollaboratorRemoved",
    VAL: action_1
  };
  var context = {
    branchId: collaborationId,
    branchQuery: collaborationId
  };
  return Curry.app(sendActions, [
              currentBranch,
              undefined,
              Caml_option.some(batch),
              undefined,
              undefined,
              undefined,
              undefined,
              (function (branch) {
                  return AnalyticsRe.collaboratorRemoved(AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), collaborationId, NamedBranch.getId(branch), "Branch", collaborationType, "@", NamedBranch.getId(branch), schemaBundle.schemaId);
                }),
              undefined,
              [[
                  action,
                  context
                ]]
            ]);
}

function useCollaborators(collaborationId) {
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var collaborators = FirebaseFetcherHooks.useStreamCollection(Firebase.app(undefined).firestore().collection("schemas").doc(workspace.id).collection("collaboration").doc(collaborationId).collection("collaborators"), (function (query) {
          return query.where("removedAt", "==", null).orderBy("createdAt", "asc");
        }), undefined, undefined);
  var collaboratorIds = Belt_List.map(collaborators, (function (c) {
          return c.id;
        }));
  var workspaceUserIds = Belt_List.map(workspace.members, (function (member) {
          return member.id;
        }));
  return Belt_List.keep(collaboratorIds, (function (id) {
                return Belt_List.has(workspaceUserIds, id, (function (prim0, prim1) {
                              return prim0 === prim1;
                            }));
              }));
}

export {
  addCollaborator ,
  removeCollaborator ,
  useCollaborators ,
  
}
/* react Not a pure module */
