// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";

function getHeight(withSandboxBanner) {
  if (withSandboxBanner) {
    return 40;
  } else {
    return 0;
  }
}

var containerStyles = Curry._1(Css.style, {
      hd: Css.position("fixed"),
      tl: {
        hd: Css.top(Css.px(0)),
        tl: {
          hd: Css.left(Css.px(0)),
          tl: {
            hd: Css.right(Css.px(0)),
            tl: {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.justifyContent("spaceBetween"),
                  tl: {
                    hd: Css.height(Css.px(40)),
                    tl: {
                      hd: Css.backgroundColor(Styles.Color.avoPink),
                      tl: {
                        hd: Css.paddingLeft(Css.px(20)),
                        tl: {
                          hd: Css.paddingRight(Css.px(24)),
                          tl: {
                            hd: Css.zIndex(Styles.ZIndex.aboveAll),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var contentContainerStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: /* [] */0
      }
    });

var linkStyles = Curry._1(Css.style, {
      hd: Css.color(Styles.Color.white),
      tl: {
        hd: Css.textDecoration("underline"),
        tl: /* [] */0
      }
    });

function isAnonymousUser(param) {
  return Belt_Option.mapWithDefault(Caml_option.nullable_to_opt(Firebase.getAuth(undefined).currentUser), true, (function (user) {
                return user.isAnonymous;
              }));
}

function SandboxBanner(Props) {
  var isAnonymousUser$1 = isAnonymousUser(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  return React.createElement("div", {
              className: containerStyles
            }, React.createElement($$Text.make, {
                  size: "Medium",
                  weight: "Semi",
                  color: Styles.Color.white,
                  children: "Avo Sandbox"
                }), React.createElement("div", {
                  className: contentContainerStyles
                }, React.createElement($$Text.make, {
                      size: "Small",
                      color: Styles.Color.white,
                      children: isAnonymousUser$1 ? "Like what you see? Sign up for free to start fixing your data" : "Are you done exploring and ready to kick off your journey to good data?"
                    }), React.createElement(Spacer.make, {
                      width: 12
                    }), React.createElement("a", {
                      className: linkStyles,
                      href: isAnonymousUser$1 ? "/onboarding" : "/schemas/default",
                      rel: "noopener",
                      onClick: (function (param) {
                          if (isAnonymousUser$1) {
                            return AnalyticsRe.sandboxInteraction(schemaBundle, "SignUp", "Banner", isAnonymousUser$1 ? "Anonymous" : "Authenticated", schemaBundle.schemaId);
                          } else {
                            return AnalyticsRe.sandboxInteraction(schemaBundle, "GoToMyWorkspace", "Banner", isAnonymousUser$1 ? "Anonymous" : "Authenticated", schemaBundle.schemaId);
                          }
                        })
                    }, React.createElement($$Text.make, {
                          size: "Small",
                          weight: "Semi",
                          color: Styles.Color.white,
                          children: isAnonymousUser$1 ? "Sign up" : "Take me to my workspace"
                        }))));
}

var bannerHeight = 40;

var make = SandboxBanner;

export {
  bannerHeight ,
  getHeight ,
  containerStyles ,
  contentContainerStyles ,
  linkStyles ,
  isAnonymousUser ,
  make ,
  
}
/* containerStyles Not a pure module */
