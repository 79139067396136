// Generated by ReScript, PLEASE EDIT WITH CARE

import * as AvoModel from "../../app/src/avoModel.mjs";
import * as AvoFormat from "../../app/src/AvoFormat.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "../../app/src/ModelUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as PropertyUtils from "../../app/src/PropertyUtils.mjs";
import * as CodegenCommons from "./CodegenCommons.mjs";
import * as UnionVariantsUseCase from "../../model/src/variants/unionVariantsUseCase.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";
import * as GetRegexValidationUseCase from "../../app/src/regex/GetRegexValidationUseCase.mjs";
import * as GetMostSpecificRegexUseCase from "../../app/src/regex/GetMostSpecificRegexUseCase.mjs";
import * as ComputeUniqueAllowedValuesSetsUseCase from "../../model/src/eventSpecificPropertyValues/ComputeUniqueAllowedValuesSetsUseCase.mjs";

function stringToQuotedEscapedString(str) {
  if (str === "") {
    return "";
  } else {
    return "\"" + str.replace(/"/g, "\"\"") + "\"";
  }
}

function arrayToCsvString(arr) {
  if (arr.length !== 0) {
    return JSON.stringify(arr.join(", "));
  } else {
    return "";
  }
}

function allPropertiesWithEvent(model, $$event, systemProperties) {
  var inputProperties = AvoModel.resolveProperties(model, $$event.properties);
  var match = CodegenCommons.classifyProperties(inputProperties);
  var match$1 = Belt_List.partition(match[0], (function (property) {
          return property.builtIn === "NotBuiltIn";
        }));
  return Belt_List.concatMany([
              match$1[0],
              match[1],
              match[2],
              match[3],
              systemProperties
            ]);
}

function printValueType(property) {
  var match = property.type_;
  switch (match) {
    case "any" :
        return "any";
    case "bool" :
        return "boolean";
    case "float" :
        return "float";
    case "int" :
        return "int";
    case "long" :
        return "long";
    case "object" :
        return "object";
    case "string" :
        return "string";
    default:
      return "any";
  }
}

function printPropertyType(property, model) {
  var groupTypeId = property.sendAs;
  if (typeof groupTypeId === "number") {
    switch (groupTypeId) {
      case /* SystemProperty */0 :
          return "System Property";
      case /* EventProperty */1 :
          return "Event Property";
      case /* UserProperty */2 :
          return "User Property";
      
    }
  } else {
    var groupName = Belt_Option.map(ModelUtils.getGroupType(groupTypeId._0, model), (function (groupType) {
            return groupType.name;
          }));
    if (groupName !== undefined) {
      return "" + groupName + " Group Property";
    } else {
      return "Group Property";
    }
  }
}

function generateCsv(ppf, model, systemProperties, includedSources, schemaId, branchId, modelPropertiesArray) {
  var mappedModel = TrackingPlanMappedModel.fromOld(model);
  AvoFormat.fprintF(ppf, "KPIs,Event Category,Event Name,Event Description,Event Triggers,Event Tags,Property Name,Property Description,Property Value Type,Property Type,Is Property Required?,Is Property Array?,Property Enumeration Options,Sources,Pinned Value,Regex", undefined, []);
  return Belt_List.forEachU(model.events, (function ($$event) {
                var includedSources$1 = Belt_List.keepU(includedSources, (function (includedSource) {
                        return Belt_List.someU($$event.includeSources, (function (eventIncludedSource) {
                                      return eventIncludedSource.id === includedSource.id;
                                    }));
                      }));
                var includedSourceIds = Belt_List.map(includedSources$1, (function (source) {
                        return source.id;
                      }));
                var kpis = arrayToCsvString(Belt_Array.map(ModelUtils.getMetricsWithEvent($$event, model), (function (metric) {
                            return metric.name;
                          })));
                var categories = arrayToCsvString(Belt_Array.map(ModelUtils.getGoalsWithEvent($$event, model), (function (goal) {
                            return goal.name;
                          })));
                var sources = arrayToCsvString(Belt_List.toArray(Belt_List.map(includedSources$1, (function (source) {
                                return source.name;
                              }))));
                var allProperties = allPropertiesWithEvent(model, $$event, systemProperties);
                var triggers = CodegenCommons.eventTriggersForSources(model, $$event.id, Belt_List.mapU(includedSources$1, (function (validSource) {
                            return validSource.id;
                          })), true, schemaId, branchId);
                AvoFormat.fprintF(ppf, "@,%s,%s,%s,%s,%s,%s,,,,,,,,%s,,", undefined, [
                      kpis,
                      categories,
                      $$event.name,
                      stringToQuotedEscapedString($$event.description),
                      stringToQuotedEscapedString(triggers.join(". ")),
                      arrayToCsvString(Belt_List.toArray($$event.tags)),
                      sources
                    ]);
                return Belt_List.forEachU(allProperties, (function (property) {
                              var optional = ModelUtils.isPropertyOptionalOnEventsAndSources({
                                    hd: $$event.id,
                                    tl: /* [] */0
                                  }, includedSourceIds, model, property);
                              var eventId = $$event.id;
                              var sourceIds = Belt_List.toArray(includedSourceIds);
                              var uniqueAllowedValuesSets = ComputeUniqueAllowedValuesSetsUseCase.compute(mappedModel, modelPropertiesArray, sourceIds, Caml_option.some(eventId), property);
                              var matches = CodegenCommons.getEventSpecificPropertyValuesCsvString(property, mappedModel, sourceIds, eventId, uniqueAllowedValuesSets);
                              var pinnedValues = stringToQuotedEscapedString(Belt_List.toArray(PropertyUtils.getPinnedValues(undefined, property.id, eventId, model)).join("; "));
                              return AvoFormat.fprintF(ppf, "@,,,,,,,%s,%s,%s,%s,%s,%s,%s,,%s,%s", [], [
                                          property.name,
                                          stringToQuotedEscapedString(property.description),
                                          printValueType(property),
                                          printPropertyType(property, model),
                                          optional ? "false" : "true",
                                          property.list ? "true" : "false",
                                          matches,
                                          pinnedValues,
                                          Belt_Option.getWithDefault(GetMostSpecificRegexUseCase.getMostSpecificRegex(GetRegexValidationUseCase.get(property), eventId), "")
                                        ]);
                            }));
              }));
}

function generateCsvSchema(sources, schemaId, branchId, model) {
  var model$1 = UnionVariantsUseCase.unionAllEventVariants(model);
  var model$2 = AvoModel.enrichModel(AvoModel.filterUnusedPropertyBundles(model$1), sources);
  var sourceIds = Belt_List.map(sources, (function (source) {
          return source.id;
        }));
  var model$3 = AvoModel.enrichPropertyBundlesForCodegen(model$2, undefined, sourceIds);
  var allSystemProperties = AvoModel.getSystemProperties(model$3.properties);
  var match = Belt_List.partition(allSystemProperties, (function (property) {
          return property.auto;
        }));
  var modelPropertiesArray = Belt_List.toArray(AvoModel.resolveProperties(model$3, model$3.properties));
  var ppf = AvoFormat.newTextBuilder(undefined);
  generateCsv(ppf, model$3, match[1], sources, schemaId, branchId, modelPropertiesArray);
  return AvoFormat.buildText(ppf);
}

export {
  generateCsvSchema ,
  
}
/* AvoModel Not a pure module */
