// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Case from "../../../bs-case/src/case.mjs";
import * as Dopt from "../externals/Dopt.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Button from "../Button.mjs";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as TextButton from "../TextButton.mjs";
import * as AnalyticsRe from "../analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ProductTour__Utils from "./ProductTour__Utils.mjs";
import * as SchemaBundleContext from "../SchemaBundleContext.mjs";
import * as ProductTour__StepProvider from "./ProductTour__StepProvider.mjs";
import * as ProductTour__TourProvider from "./ProductTour__TourProvider.mjs";

function ProductTour__GenericStep(Props) {
  var id = Props.id;
  var withExitOpt = Props.withExit;
  var withExit = withExitOpt !== undefined ? withExitOpt : true;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var match = ProductTour__StepProvider.use(undefined);
  var transition = match.transition;
  var intent = match.intent;
  var block = match.block;
  var match$1 = ProductTour__TourProvider.use(undefined);
  var resetOnExit = match$1.resetOnExit;
  var flow = match$1.flow;
  var tmp = {
    className: Curry._1(Css.style, {
          hd: Css.padding(Css.px(32)),
          tl: {
            hd: Css.paddingRight(Css.px(24)),
            tl: {
              hd: Css.paddingBottom(Css.px(24)),
              tl: {
                hd: Css.maxWidth(Css.px(320)),
                tl: /* [] */0
              }
            }
          }
        })
  };
  if (id !== undefined) {
    tmp.id = Caml_option.valFromOption(id);
  }
  return React.createElement("div", tmp, React.createElement($$Text.make, {
                  element: "Span",
                  size: "Large",
                  weight: "Semi",
                  children: ProductTour__Utils.extractField(block, "title")
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.marginRight(Css.px(4)),
                        tl: /* [] */0
                      })
                }), React.createElement($$Text.make, {
                  element: "Span",
                  size: "Medium",
                  children: ProductTour__Utils.extractField(block, "body")
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.justifyContent("spaceBetween"),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: {
                                hd: Css.gap(Css.px(8)),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, match$1.showStepCount ? React.createElement($$Text.make, {
                            size: "Small",
                            color: Styles.Color.light08,
                            children: String(match.stepNumber) + "/" + String(match$1.totalSteps)
                          }) : null, withExit ? React.createElement(TextButton.make, {
                            onClick: (function (param) {
                                Belt_Option.forEach(intent, (function (intent) {
                                        if (resetOnExit) {
                                          return Dopt.Flow.reset(undefined, intent);
                                        } else {
                                          return Dopt.Flow.stop(intent);
                                        }
                                      }));
                                return AnalyticsRe.productFlowExited(schemaBundle, AnalyticsRe.Bundle.productFlows(Case.title(flow.uid), flow.uid, String(flow.version)), block.sid.replace(flow.uid + ".", ""), Belt_Option.getWithDefault(Dopt.Flow.getStepNumber(block.sid.replace(flow.uid + ".", "")), 0), schemaBundle.schemaId);
                              }),
                            children: React.createElement($$Text.make, {
                                  size: "Small",
                                  weight: "Regular",
                                  children: "Exit Tour"
                                })
                          }) : null), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.gap(Css.px(4)),
                              tl: /* [] */0
                            }
                          })
                    }, Belt_Array.map(match.transitions, (function (param) {
                            var key = param.key;
                            var tmp = {
                              onClick: (function (param) {
                                  return Curry._1(transition, key);
                                }),
                              style: key.startsWith("prev") ? "ghost" : "primary",
                              key: key
                            };
                            if (param.icon !== undefined) {
                              tmp.icon = Caml_option.valFromOption(param.icon);
                            }
                            if (param.label !== undefined) {
                              tmp.label = param.label;
                            }
                            return React.createElement(Button.make, tmp);
                          })))));
}

var Utils;

var StepProvider;

var TourProvider;

var make = ProductTour__GenericStep;

export {
  Utils ,
  StepProvider ,
  TourProvider ,
  make ,
  
}
/* Css Not a pure module */
