// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_List from "rescript/lib/es6/belt_List.js";

function getIncludedSources(filters, sources, modelSources) {
  var includedSources = Belt_List.keepMap(filters, (function (filter) {
          if (typeof filter === "object" && filter.NAME === "Source") {
            return filter.VAL;
          }
          
        }));
  var includedSources$1 = includedSources !== /* [] */0 ? includedSources : Belt_List.map(sources, (function (source) {
            return source.id;
          }));
  if (includedSources$1 !== /* [] */0) {
    return includedSources$1;
  } else {
    return Belt_List.map(modelSources, (function (source) {
                  return source.id;
                }));
  }
}

function trimEventAndPropertyNames(model) {
  return {
          types: model.types,
          properties: Belt_List.map(model.properties, (function (property) {
                  if (property.TAG === /* PropertyRef */0) {
                    return property;
                  }
                  var property$1 = property._0;
                  return {
                          TAG: /* Property */1,
                          _0: {
                            id: property$1.id,
                            name: property$1.name.trim(),
                            uniqueName: property$1.uniqueName,
                            description: property$1.description,
                            type_: property$1.type_,
                            sendAs: property$1.sendAs,
                            validations: property$1.validations,
                            eventSpecificAllowedPropertyValues: property$1.eventSpecificAllowedPropertyValues,
                            auto: property$1.auto,
                            builtIn: property$1.builtIn,
                            analyticsTools: property$1.analyticsTools,
                            devPlatforms: property$1.devPlatforms,
                            list: property$1.list,
                            operation: property$1.operation,
                            optionalDeprecated: property$1.optionalDeprecated,
                            excludedSourcesDeprecated: property$1.excludedSourcesDeprecated,
                            optionalWhenInObject: property$1.optionalWhenInObject,
                            absence: property$1.absence,
                            globalRequirementsMetadata: property$1.globalRequirementsMetadata
                          }
                        };
                })),
          propertyBundles: model.propertyBundles,
          events: Belt_List.map(model.events, (function ($$event) {
                  return {
                          id: $$event.id,
                          name: $$event.name.trim(),
                          uniqueName: $$event.uniqueName,
                          description: $$event.description,
                          properties: $$event.properties,
                          propertyBundles: $$event.propertyBundles,
                          variants: $$event.variants,
                          types: $$event.types,
                          tags: $$event.tags,
                          excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                          includeSources: $$event.includeSources,
                          includeDestinations: $$event.includeDestinations,
                          hashes: $$event.hashes,
                          propertyWhitelist: $$event.propertyWhitelist,
                          eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                          userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                          triggers: $$event.triggers,
                          globalRequirementsMetadata: $$event.globalRequirementsMetadata
                        };
                })),
          migrations: model.migrations,
          sources: model.sources,
          destinations: model.destinations,
          groupTypes: model.groupTypes,
          goals: model.goals,
          metrics: model.metrics,
          archive: model.archive,
          openBranches: model.openBranches,
          branchPointer: model.branchPointer,
          rules: model.rules,
          integrations: model.integrations,
          globalRequirements: model.globalRequirements
        };
}

export {
  getIncludedSources ,
  trimEventAndPropertyNames ,
  
}
/* No side effect */
