// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Number from "../../app/src/Number.mjs";
import * as DateFns from "../../app/src/DateFns.mjs";
import * as AvoModel from "../../app/src/avoModel.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AvoConfig from "./AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "../../app/src/ModelUtils.mjs";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as BeltListExtensions from "../../app/src/BeltListExtensions.mjs";

function renderLanguageFileExtension(language) {
  if (language !== undefined) {
    if (language === "java") {
      return "java";
    } else if (language === "json") {
      return "json";
    } else if (language === "objc") {
      return "m";
    } else if (language === "js_v2") {
      return "js";
    } else if (language === "re_v2") {
      return "re";
    } else if (language === "cs") {
      return "cs";
    } else if (language === "go") {
      return "go";
    } else if (language === "re" || language === "js") {
      return "js";
    } else if (language === "kt") {
      return "kt";
    } else if (language === "py3" || language === "py") {
      return "py";
    } else if (language === "rb") {
      return "rb";
    } else if (language === "ts") {
      return "ts";
    } else if (language === "php") {
      return "php";
    } else if (language === "swift") {
      return "swift";
    } else {
      return "dart";
    }
  } else {
    return "js";
  }
}

function renderFilename(name, language) {
  return name + "." + renderLanguageFileExtension(language);
}

function renderLibraryDestination(path, name, language) {
  return path + "/" + renderFilename(name, language);
}

function getDestinationsSupport(platform, language, destinations) {
  return Belt_List.map(Belt_List.keepMap(destinations, (function (destination) {
                    var type_ = destination.type_;
                    if (type_ !== undefined) {
                      return [
                              type_,
                              destination
                            ];
                    }
                    
                  })), (function (param) {
                var type_ = param[0];
                return [
                        type_,
                        param[1],
                        Belt_Array.some(AvoConfig.getSupportedDestinations(platform, language), (function (destination) {
                                return destination === type_;
                              }))
                      ];
              }));
}

function isDestinationSupportedForSource(sourcePlatform, sourceLanguage, destinationType) {
  return Belt_Array.some(AvoConfig.getSupportedDestinations(sourcePlatform, sourceLanguage), (function (destination) {
                return destination === destinationType;
              }));
}

function filterInvalidSources(destination, sources) {
  return Belt_List.keep(sources, (function (source) {
                return Belt_Array.some(AvoConfig.getSupportedDestinations(source.platform, source.language), (function (type_) {
                              return destination === type_;
                            }));
              }));
}

function filterInvalidDestinations(sourcePlatform, sourceLanguage, sourceDestinations, allDestinations) {
  return Belt_List.keep(Belt_List.keepMap(sourceDestinations, (function (id) {
                    return Belt_List.head(Belt_List.keep(allDestinations, (function (destination) {
                                      return destination.id === id;
                                    })));
                  })), (function (destination) {
                var destinationType = destination.type_;
                if (destinationType !== undefined) {
                  return isDestinationSupportedForSource(sourcePlatform, sourceLanguage, destinationType);
                } else {
                  return false;
                }
              }));
}

function singularActionByObject(action) {
  var exit = 0;
  var eventId;
  var propertyId;
  var sourceId;
  var destinationId;
  var goalId;
  var metricId;
  var metricId$1;
  var eventId$1;
  var id;
  var variantIdentifier;
  var id$1;
  var id$2;
  var id$3;
  var id$4;
  var id$5;
  var id$6;
  var id$7;
  var id$8;
  if (typeof action !== "object") {
    if (action === "StartedImport") {
      return Pervasives.failwith("We should never check which object an Import belongs to");
    } else {
      return /* [] */0;
    }
  }
  var variant = action.NAME;
  if (variant === "UpdateDestinationProdApiKey" || variant === "UpdateDestinationDevApiKey" || variant === "UpdateDestinationAnalyticsTool" || variant === "UpdateDestinationName" || variant === "UpdateDestinationApiKey" || variant === "RemoveDestinationApiKey" || variant === "UpdateDestinationIncludeUserPropsWithEventProps" || variant === "UpdateDestinationDisabledByDefault") {
    destinationId = action.VAL[0];
    exit = 4;
  } else if (variant === "AddEventSpecificPropertyValue" || variant === "ConvertPropertyToGlobalPropertyV2" || variant === "SetEventSpecificPropertyValueSources" || variant === "RemoveEventSpecificPropertyValue" || variant === "ConvertPropertyToGlobalProperty" || variant === "AddProperty") {
    propertyId = action.VAL[1];
    exit = 2;
  } else {
    if (variant === "UpdateTrackingPlanAuditConfig" || variant === "RemoveMember" || variant === "UpdateIntegrationDestinationIds" || variant === "CollaboratorRemoved" || variant === "CreateServiceAccount" || variant === "ReorderGoals" || variant === "AutoPublishIntegration" || variant === "BranchInit" || variant === "CloseBranch" || variant === "ArchiveSavedView" || variant === "CodeGenerated" || variant === "UpdateGlobalRequirements" || variant === "ResendInvite" || variant === "CreateInspectorIssue" || variant === "PropertyComment" || variant === "DeleteComment" || variant === "CreatePublicBranchImplementation" || variant === "SubscriptionStarted" || variant === "ReorderGoalsV2" || variant === "PullMaster" || variant === "PublishIntegration" || variant === "SubscriptionCancelled" || variant === "SubscriptionUpdated" || variant === "UpdateMemberRole" || variant === "GoalComment" || variant === "UpdateSavedView" || variant === "IntegrationComment" || variant === "InspectorIssueComment" || variant === "MigrateExcludeEventsToIncludeEvents" || variant === "DeleteServiceAccount" || variant === "MetricComment" || variant === "DeletePublicBranchImplementation" || variant === "CollaboratorAdded" || variant === "PropertyGroupComment" || variant === "UpdateInspectorIssueStatus" || variant === "EventComment" || variant === "OpenBranch" || variant === "RemoveInvite" || variant === "BranchComment" || variant === "InviteMember" || variant === "MigrateSourcesAndDestinationsToModel" || variant === "PaymentCompleted" || variant === "SetBranchStatus" || variant === "CreateSavedView" || variant === "MergeBranch") {
      return /* [] */0;
    }
    if (variant === "AddPropertyGroupToEvent" || variant === "RemoveTriggerSource" || variant === "RemoveEventTrigger" || variant === "RemovePropertyPinnedValue" || variant === "RemovePropertyGroupFromEvent" || variant === "RemoveGroupTypeFromLogEvent" || variant === "UpdateEventName" || variant === "UpdateEventUniqueName" || variant === "RemoveEventType" || variant === "RemoveUserFromGroup" || variant === "UpdatePropertyPinnedValue" || variant === "AddGroupTypeToLogEvent" || variant === "IncludeDestinationInEventSource" || variant === "AddTriggerSource" || variant === "AddPropertyToWhitelist" || variant === "UpdateTriggerDescription" || variant === "ExcludeEventFromSource" || variant === "ExcludeDestinationFromEventSource" || variant === "UpdateEventDescription" || variant === "IncludeEventInSource" || variant === "IncludeEventInSourceV2" || variant === "ToggleIncludeEventInCodegenForSource" || variant === "RemovePropertyRefV3" || variant === "AddUserToGroup" || variant === "RemoveTag" || variant === "RemovePropertyRefV2Deprecated" || variant === "CreateEventTrigger" || variant === "UpdateEventUniqueNameV2" || variant === "UpdateTriggerSources" || variant === "ExcludeEventFromSourceV2" || variant === "AddPropertyRef" || variant === "AddTag" || variant === "RemovePropertyFromWhitelist" || variant === "AddEvent" || variant === "UpdateTriggerContent" || variant === "AddEventType") {
      eventId = action.VAL[0];
      exit = 1;
    } else if (variant === "ConvertPropertyBundleToGlobalPropertyBundle") {
      id$6 = action.VAL[1];
      exit = 15;
    } else {
      if (variant === "UpdatePropertyPinnedValueOnEventVariant" || variant === "ClearEventVariantSpecificPropertyValueOverride" || variant === "UpdateEventVariantTriggerContent" || variant === "ClearPropertyRegexOverrideFromEventVariant" || variant === "RemovePropertyFromEventVariant" || variant === "CreateEventVariant" || variant === "AddEventVariantTriggerSource" || variant === "UpdateEventVariantTriggerDescription" || variant === "OverrideEventVariantSpecificPropertyValueToBeAllowedOnAllSources" || variant === "OverrideEventVariantSpecificPropertyValueToBeDisallowed" || variant === "SetPropertyAbsenceOnVariant" || variant === "UpdateEventVariantDescription" || variant === "ClearEventVariantPropertyOverride" || variant === "UpdatePropertyRegexOverrideOnEventVariant" || variant === "ClearSourceOverrideOnVariant" || variant === "CreateEventVariantTrigger" || variant === "RemoveEventVariantTriggerSource" || variant === "SetSourceOverrideOnVariant" || variant === "ClearPropertyPinnedValueFromEventVariant" || variant === "UpdateEventVariantTriggerSources" || variant === "AddPropertyToEventVariant" || variant === "RemoveEventVariantTrigger") {
        var variantIdentifier$1 = action.VAL[0];
        return {
                hd: [
                  {
                    NAME: "Event",
                    VAL: variantIdentifier$1.baseEventId
                  },
                  action
                ],
                tl: {
                  hd: [
                    {
                      NAME: "EventVariant",
                      VAL: variantIdentifier$1
                    },
                    action
                  ],
                  tl: /* [] */0
                }
              };
      }
      if (variant === "UpdatePropertyAbsence" || variant === "DeprecatedAddPropertyValidationMinOrMax" || variant === "UpdatePropertyOptionalWhenInObject" || variant === "CreateProperty" || variant === "DeprecatedRemovePropertyValidationMinOrMax" || variant === "RemoveNestedPropertyPinnedValue" || variant === "AddEventSpecificPropertyValueForSomeEvents" || variant === "AddPropertyValidation" || variant === "RemovePropertyValidation" || variant === "AddEventSpecificPropertyValueForAllEvents" || variant === "UpdateNestedPropertyPinnedValue" || variant === "AddSystemProperty" || variant === "UpdatePropertyOptional" || variant === "DeprecatedUpdatePropertyValidationMinOrMax" || variant === "TogglePropertyExcludedSource" || variant === "UpdatePropertyOperation" || variant === "UpdatePropertyRegexValidation" || variant === "UpdatePropertyType" || variant === "UpdatePropertySendAs" || variant === "UpdatePropertyDescription" || variant === "UpdatePropertyName" || variant === "UpdatePropertyList" || variant === "DeprecatedAddPropertyValidationMatch" || variant === "UpdatePropertyUniqueName" || variant === "RemoveEventSpecificPropertyValueForAllEvents" || variant === "TogglePropertyValidationMatchSource" || variant === "DeprecatedRemovePropertyValidationMatch") {
        propertyId = action.VAL[0];
        exit = 2;
      } else {
        if (variant === "ExcludeDestinationFromSource" || variant === "IncludeDestinationInSourceV2" || variant === "LegacyToggleDestination" || variant === "LegacyIncludeDestinationInSource" || variant === "UpdateSourceDestinationMode") {
          var match = action.VAL;
          return {
                  hd: [
                    {
                      NAME: "Source",
                      VAL: match[0]
                    },
                    action
                  ],
                  tl: {
                    hd: [
                      {
                        NAME: "Destination",
                        VAL: match[1]
                      },
                      action
                    ],
                    tl: /* [] */0
                  }
                };
        }
        if (variant === "ClearPropertyExcludedSources" || variant === "AllowAllEventSpecificPropertyValuesOnAllEventsAndSources" || variant === "DeprecatedRemovePropertyValidationMatches" || variant === "RemovePropertyPermanently") {
          propertyId = action.VAL;
          exit = 2;
        } else if (variant === "AddSourceSegmentIntegration" || variant === "RemoveSourceConfig" || variant === "UpdateLibraryName" || variant === "UpdateSourcePlatform" || variant === "AddSourceConfig" || variant === "UpdateSourceSegmentIntegration" || variant === "RemoveSourceSegmentIntegration" || variant === "UpdateLocalWorkspaceName" || variant === "UpdateSourcePlatformV3" || variant === "UpdateSourcePlatformV2" || variant === "UpdateProgrammingLanguage" || variant === "UpdateSourceName" || variant === "UpdateLibraryDestination") {
          sourceId = action.VAL[0];
          exit = 3;
        } else {
          if (variant === "CompletedImport" || variant === "ImportDeprecated") {
            return Pervasives.failwith("We should never check which object an Import belongs to");
          }
          if (variant === "RemoveGoal" || variant === "AddGoal") {
            goalId = action.VAL;
            exit = 5;
          } else if (variant === "UpdateIntegrationFilters" || variant === "UpdateIntegrationType" || variant === "UpdateIntegrationName" || variant === "UpdateIntegrationConfig" || variant === "UpdateIntegrationAutoPublish") {
            id$7 = action.VAL[0];
            exit = 16;
          } else {
            if (variant === "CreateDemoBranch") {
              return BeltListExtensions.flatMap(action.VAL, singularActionByObject);
            }
            if (variant === "RemoveMetricFromGoal" || variant === "RemoveEventFromGoal" || variant === "UpdateGoalName" || variant === "AddEventToGoal" || variant === "AddMetricToGoal" || variant === "ReorderMetricsInGoalV2" || variant === "ReorderEventsInGoal" || variant === "ReorderMetricsInGoal" || variant === "ReorderEventsInGoalV2" || variant === "UpdateGoalDescription") {
              goalId = action.VAL[0];
              exit = 5;
            } else if (variant === "RemoveMetricWhereV2" || variant === "RemoveEventFromMetric" || variant === "SetMetricGroupByV2" || variant === "RemoveMetricGroupByV2" || variant === "AddEventToMetric" || variant === "SetMetricWhereV2") {
              var match$1 = action.VAL;
              metricId$1 = match$1[0];
              eventId$1 = match$1[2];
              exit = 7;
            } else if (variant === "ConvertEventToGlobalEventV2" || variant === "ConvertEventToGlobalEvent") {
              eventId = action.VAL[1];
              exit = 1;
            } else if (variant === "AddRule") {
              var match$2 = action.VAL[1];
              var variant$1 = match$2.NAME;
              if (variant$1 === "Property") {
                propertyId = match$2.VAL;
                exit = 2;
              } else if (variant$1 === "Event") {
                eventId = match$2.VAL;
                exit = 1;
              } else {
                eventId = match$2.VAL[0];
                exit = 1;
              }
            } else if (variant === "RemoveEvent") {
              eventId = action.VAL;
              exit = 1;
            } else if (variant === "CreateLocalWorkspace" || variant === "CreateSource" || variant === "DeleteSource" || variant === "DeleteLocalWorkspace") {
              sourceId = action.VAL;
              exit = 3;
            } else if (variant === "UpdateGroupTypeName" || variant === "CreateGroupType") {
              id$8 = action.VAL[0];
              exit = 17;
            } else if (variant === "UpdateMetricDescription" || variant === "UpdateMetricType" || variant === "UpdateMetricName" || variant === "ReorderEventsInMetric" || variant === "ReorderEventsInMetricV2") {
              metricId = action.VAL[0];
              exit = 6;
            } else if (variant === "RemoveMetricWhere" || variant === "SetMetricWhere" || variant === "SetMetricGroupBy" || variant === "RemoveMetricGroupBy") {
              var match$3 = action.VAL;
              metricId$1 = match$3[0];
              eventId$1 = match$3[1];
              exit = 7;
            } else if (variant === "Archive") {
              var match$4 = action.VAL;
              var variant$2 = match$4.NAME;
              if (variant$2 === "Destination") {
                id$3 = match$4.VAL;
                exit = 12;
              } else {
                if (variant$2 === "Integration") {
                  return /* [] */0;
                }
                if (variant$2 === "GroupType") {
                  id$8 = match$4.VAL;
                  exit = 17;
                } else if (variant$2 === "Property") {
                  id$1 = match$4.VAL;
                  exit = 10;
                } else if (variant$2 === "Event") {
                  id = match$4.VAL;
                  exit = 8;
                } else if (variant$2 === "PropertyGroup") {
                  id$6 = match$4.VAL;
                  exit = 15;
                } else if (variant$2 === "Goal") {
                  id$4 = match$4.VAL;
                  exit = 13;
                } else if (variant$2 === "EventVariant") {
                  variantIdentifier = match$4.VAL;
                  exit = 9;
                } else if (variant$2 === "Metric") {
                  id$5 = match$4.VAL;
                  exit = 14;
                } else {
                  id$2 = match$4.VAL;
                  exit = 11;
                }
              }
            } else if (variant === "CreateIntegration" || variant === "GenerateWebhookSecret" || variant === "RemoveIntegration") {
              id$7 = action.VAL;
              exit = 16;
            } else if (variant === "UpdateRule") {
              var match$5 = action.VAL[1];
              var variant$3 = match$5.NAME;
              if (variant$3 === "Property") {
                propertyId = match$5.VAL;
                exit = 2;
              } else if (variant$3 === "Event") {
                eventId = match$5.VAL;
                exit = 1;
              } else {
                eventId = match$5.VAL[0];
                exit = 1;
              }
            } else if (variant === "DeleteDestination" || variant === "CreateDestination") {
              destinationId = action.VAL;
              exit = 4;
            } else if (variant === "AddPropertyToGroup" || variant === "UpdatePropertyGroupName" || variant === "CreatePropertyGroup" || variant === "RemovePropertyFromGroup" || variant === "UpdatePropertyGroupDescription") {
              id$6 = action.VAL[0];
              exit = 15;
            } else {
              if (variant === "PushGlobalRequirementsToTrackingPlan") {
                return BeltListExtensions.flatMap(action.VAL[2], singularActionByObject);
              }
              if (variant === "DuplicateEvent") {
                eventId = action.VAL[0][1];
                exit = 1;
              } else {
                if (variant === "ConfigureInspectorValidation") {
                  return Belt_List.map(action.VAL[0], (function (eventId) {
                                return [
                                        {
                                          NAME: "Event",
                                          VAL: eventId
                                        },
                                        action
                                      ];
                              }));
                }
                if (variant === "Unarchive") {
                  var match$6 = action.VAL;
                  var variant$4 = match$6.NAME;
                  if (variant$4 === "Destination") {
                    id$3 = match$6.VAL;
                    exit = 12;
                  } else {
                    if (variant$4 === "Integration") {
                      return /* [] */0;
                    }
                    if (variant$4 === "GroupType") {
                      id$8 = match$6.VAL;
                      exit = 17;
                    } else if (variant$4 === "Property") {
                      id$1 = match$6.VAL;
                      exit = 10;
                    } else if (variant$4 === "Event") {
                      id = match$6.VAL;
                      exit = 8;
                    } else if (variant$4 === "PropertyGroup") {
                      id$6 = match$6.VAL;
                      exit = 15;
                    } else if (variant$4 === "Goal") {
                      id$4 = match$6.VAL;
                      exit = 13;
                    } else if (variant$4 === "EventVariant") {
                      variantIdentifier = match$6.VAL;
                      exit = 9;
                    } else if (variant$4 === "Metric") {
                      id$5 = match$6.VAL;
                      exit = 14;
                    } else {
                      id$2 = match$6.VAL;
                      exit = 11;
                    }
                  }
                } else if (variant === "RemoveRule") {
                  var match$7 = action.VAL[1];
                  var variant$5 = match$7.NAME;
                  if (variant$5 === "Property") {
                    propertyId = match$7.VAL;
                    exit = 2;
                  } else if (variant$5 === "Event") {
                    eventId = match$7.VAL;
                    exit = 1;
                  } else {
                    eventId = match$7.VAL[0];
                    exit = 1;
                  }
                } else {
                  metricId = action.VAL;
                  exit = 6;
                }
              }
            }
          }
        }
      }
    }
  }
  switch (exit) {
    case 1 :
        return {
                hd: [
                  {
                    NAME: "Event",
                    VAL: eventId
                  },
                  action
                ],
                tl: /* [] */0
              };
    case 2 :
        return {
                hd: [
                  {
                    NAME: "Property",
                    VAL: propertyId
                  },
                  action
                ],
                tl: /* [] */0
              };
    case 3 :
        return {
                hd: [
                  {
                    NAME: "Source",
                    VAL: sourceId
                  },
                  action
                ],
                tl: /* [] */0
              };
    case 4 :
        return {
                hd: [
                  {
                    NAME: "Destination",
                    VAL: destinationId
                  },
                  action
                ],
                tl: /* [] */0
              };
    case 5 :
        return {
                hd: [
                  {
                    NAME: "Goal",
                    VAL: goalId
                  },
                  action
                ],
                tl: /* [] */0
              };
    case 6 :
        return {
                hd: [
                  {
                    NAME: "Metric",
                    VAL: metricId
                  },
                  action
                ],
                tl: /* [] */0
              };
    case 7 :
        return {
                hd: [
                  {
                    NAME: "Metric",
                    VAL: metricId$1
                  },
                  action
                ],
                tl: {
                  hd: [
                    {
                      NAME: "Event",
                      VAL: eventId$1
                    },
                    action
                  ],
                  tl: /* [] */0
                }
              };
    case 8 :
        return {
                hd: [
                  {
                    NAME: "Event",
                    VAL: id
                  },
                  action
                ],
                tl: /* [] */0
              };
    case 9 :
        return {
                hd: [
                  {
                    NAME: "Event",
                    VAL: variantIdentifier.baseEventId
                  },
                  action
                ],
                tl: {
                  hd: [
                    {
                      NAME: "EventVariant",
                      VAL: variantIdentifier
                    },
                    action
                  ],
                  tl: /* [] */0
                }
              };
    case 10 :
        return {
                hd: [
                  {
                    NAME: "Property",
                    VAL: id$1
                  },
                  action
                ],
                tl: /* [] */0
              };
    case 11 :
        return {
                hd: [
                  {
                    NAME: "Source",
                    VAL: id$2
                  },
                  action
                ],
                tl: /* [] */0
              };
    case 12 :
        return {
                hd: [
                  {
                    NAME: "Destination",
                    VAL: id$3
                  },
                  action
                ],
                tl: /* [] */0
              };
    case 13 :
        return {
                hd: [
                  {
                    NAME: "Goal",
                    VAL: id$4
                  },
                  action
                ],
                tl: /* [] */0
              };
    case 14 :
        return {
                hd: [
                  {
                    NAME: "Metric",
                    VAL: id$5
                  },
                  action
                ],
                tl: /* [] */0
              };
    case 15 :
        return {
                hd: [
                  {
                    NAME: "PropertyGroup",
                    VAL: id$6
                  },
                  action
                ],
                tl: /* [] */0
              };
    case 16 :
        return {
                hd: [
                  {
                    NAME: "Integration",
                    VAL: id$7
                  },
                  action
                ],
                tl: /* [] */0
              };
    case 17 :
        return {
                hd: [
                  {
                    NAME: "GroupType",
                    VAL: id$8
                  },
                  action
                ],
                tl: /* [] */0
              };
    
  }
}

function actionByObject(actions) {
  return BeltListExtensions.flatMap(actions, singularActionByObject);
}

function groupActionsByObjectId(actions) {
  return BeltListExtensions.group((function (a, b) {
                var match = a[0];
                var match$1 = b[0];
                if (Caml_obj.caml_greaterthan(match, match$1)) {
                  return 1;
                } else if (Caml_obj.caml_equal(match, match$1)) {
                  return 0;
                } else {
                  return -1;
                }
              }), actions);
}

function getMetricTypeLabel(metricType) {
  if (metricType === "EventSegmentation") {
    return "Segmentation";
  } else if (metricType === "Retention") {
    return "Retention";
  } else if (metricType === "Funnel") {
    return "Funnel";
  } else {
    return "Proportion";
  }
}

function getPropertyOptions(model, $$event) {
  var availableProperties = Belt_List.toArray(AvoModel.getAllPropertiesAvailableOnEventForMetricView(model, $$event));
  var match = AvoModel.classifyProperties(AvoModel.resolveProperties(model, Belt_List.keep(model.properties, (function (x) {
                  var id;
                  id = x._0.id;
                  return !Belt_Array.some(availableProperties, (function (prop) {
                                return prop.id === id;
                              }));
                }))));
  return [
          [
            "Properties on " + $$event.name,
            Belt_Array.map(availableProperties, (function (item) {
                    return {
                            NAME: "Property",
                            VAL: item
                          };
                  }))
          ],
          [
            "Other Properties",
            Belt_Array.map(Belt_List.toArray(Belt_List.concat(match[0], match[1])), (function (item) {
                    return {
                            NAME: "Property",
                            VAL: item
                          };
                  }))
          ]
        ];
}

function hasSegmentDestination(model, source) {
  return Belt_List.someU(source.destinations, (function (param) {
                var destination = ModelUtils.getDestinationById(param.destinationId, model);
                return Belt_Option.mapWithDefaultU(destination, false, (function (destination) {
                              return Caml_obj.caml_equal(destination.type_, "Segment");
                            }));
              }));
}

function hasInspectorSupport(model, source) {
  var match = hasSegmentDestination(model, source);
  var match$1 = source.platform;
  if (match) {
    return true;
  } else if (match$1 !== undefined) {
    if (match$1 === "Node" || match$1 === "Java" || match$1 === "Expo" || match$1 === "Web" || match$1 === "IOS" || match$1 === "Android") {
      return true;
    } else {
      return match$1 === "ReactNative";
    }
  } else {
    return false;
  }
}

function toInspectorIssueStatusValidateIn(validation) {
  var variant = validation.NAME;
  if (variant === "oneHourAfterMerge") {
    return {
            TAG: /* Date */3,
            _0: DateFns.addHours(1, validation.VAL)
          };
  } else if (variant === "currentVersion") {
    return {
            TAG: /* CurrentAppVersion */0,
            _0: validation.VAL
          };
  } else if (variant === "twentyFourHoursAfterMerge") {
    return {
            TAG: /* Date */3,
            _0: DateFns.addHours(24, validation.VAL)
          };
  } else if (variant === "customVersion") {
    return {
            TAG: /* CustomAppVersion */2,
            _0: validation.VAL
          };
  } else if (variant === "customTime") {
    return {
            TAG: /* Date */3,
            _0: validation.VAL
          };
  } else {
    return {
            TAG: /* NextAppVersion */1,
            _0: validation.VAL
          };
  }
}

function plural(plural$1, includeNumberOpt, n, word) {
  var includeNumber = includeNumberOpt !== undefined ? includeNumberOpt : true;
  if (n === 1) {
    return (
            includeNumber ? "1 " : ""
          ) + word;
  } else {
    return (
            includeNumber ? $$Number.toLocaleString(n) + " " : ""
          ) + (
            plural$1 !== undefined ? plural$1 : word + "s"
          );
  }
}

export {
  renderLanguageFileExtension ,
  renderFilename ,
  renderLibraryDestination ,
  getDestinationsSupport ,
  isDestinationSupportedForSource ,
  filterInvalidSources ,
  filterInvalidDestinations ,
  singularActionByObject ,
  actionByObject ,
  groupActionsByObjectId ,
  getMetricTypeLabel ,
  getPropertyOptions ,
  hasSegmentDestination ,
  hasInspectorSupport ,
  toInspectorIssueStatusValidateIn ,
  plural ,
  
}
/* DateFns Not a pure module */
