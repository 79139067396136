// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as $$Window from "./externals/window.mjs";
import * as Changes from "../../shared/Changes.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Intercom from "./externals/intercom.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as QuillDelta from "../../model/src/externals/quillDelta.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import QuillDelta$1 from "quill-delta";
import * as QueryString from "query-string";

function useDebounced(getValue, delay) {
  var match = React.useState(function () {
        return Curry._1(getValue, undefined);
      });
  var setDebouncedValue = match[1];
  React.useEffect((function () {
          var handler = setTimeout((function (param) {
                  return Curry._1(setDebouncedValue, (function (param) {
                                return Curry._1(getValue, undefined);
                              }));
                }), delay);
          return (function (param) {
                    clearTimeout(handler);
                    
                  });
        }), [Curry._1(getValue, undefined)]);
  return match[0];
}

function useTrailingDebounced(getValue, delay) {
  var match = React.useState(function () {
        
      });
  var setDebouncedValue = match[1];
  React.useEffect((function () {
          var handler = setTimeout((function (param) {
                  return Curry._1(setDebouncedValue, (function (param) {
                                return Caml_option.some(Curry._1(getValue, undefined));
                              }));
                }), delay);
          return (function (param) {
                    clearTimeout(handler);
                    
                  });
        }), [Curry._1(getValue, undefined)]);
  return match[0];
}

function useInterval(delay, callback) {
  var savedCallback = React.useRef(undefined);
  React.useEffect((function () {
          savedCallback.current = callback;
          
        }), [callback]);
  React.useEffect((function () {
          var cb = savedCallback.current;
          var tick = cb !== undefined ? cb : (function (param) {
                
              });
          if (delay === undefined) {
            return ;
          }
          if (delay === 0) {
            return ;
          }
          var id = setInterval(tick, delay);
          return (function (param) {
                    clearInterval(id);
                    
                  });
        }), [delay]);
  
}

function useRetained(value) {
  var retained = React.useRef(value);
  React.useEffect((function () {
          retained.current = value;
          
        }), [value]);
  return retained.current;
}

function useDocumentVisibility(param) {
  var match = React.useState(function () {
        return document.visibilityState;
      });
  var setVisibility = match[1];
  React.useEffect((function () {
          var handler = function (param) {
            return Curry._1(setVisibility, (function (param) {
                          return document.visibilityState;
                        }));
          };
          document.addEventListener("visibilitychange", handler);
          return (function (param) {
                    document.removeEventListener("visibilitychange", handler);
                    
                  });
        }), []);
  return match[0];
}

function keyHandler(keys, onKey, metaKey, param) {
  var eventHandler = function ($$event) {
    var key = $$event.key;
    var metaKey$1 = metaKey ? $$event.metaKey : true;
    if (metaKey$1 && Belt_List.someU(keys, (function (item) {
              return item === key;
            }))) {
      return Curry._2(onKey, key, $$event);
    }
    
  };
  window.addEventListener("keydown", eventHandler);
  return (function (param) {
            window.removeEventListener("keydown", eventHandler);
            
          });
}

function useKeys0(keys, onKey, metaKeyOpt, param) {
  var metaKey = metaKeyOpt !== undefined ? metaKeyOpt : false;
  React.useEffect((function () {
          return keyHandler(keys, onKey, metaKey, undefined);
        }), []);
  
}

function useKeys1(keys, onKey, metaKeyOpt, dependencies) {
  var metaKey = metaKeyOpt !== undefined ? metaKeyOpt : false;
  React.useEffect((function () {
          return keyHandler(keys, onKey, metaKey, undefined);
        }), dependencies);
  
}

function useKeys2(keys, onKey, metaKeyOpt, dependencies) {
  var metaKey = metaKeyOpt !== undefined ? metaKeyOpt : false;
  React.useEffect((function () {
          return keyHandler(keys, onKey, metaKey, undefined);
        }), dependencies);
  
}

function useKeys3(keys, onKey, metaKeyOpt, dependencies) {
  var metaKey = metaKeyOpt !== undefined ? metaKeyOpt : false;
  React.useEffect((function () {
          return keyHandler(keys, onKey, metaKey, undefined);
        }), dependencies);
  
}

function useKeys4(keys, onKey, metaKeyOpt, dependencies) {
  var metaKey = metaKeyOpt !== undefined ? metaKeyOpt : false;
  React.useEffect((function () {
          return keyHandler(keys, onKey, metaKey, undefined);
        }), dependencies);
  
}

function useOpsWithDeltaState(value, onFlush, onChangeCompleted) {
  var match = React.useState(function () {
        return value;
      });
  var setValueBefore = match[1];
  var valueBefore = match[0];
  var match$1 = React.useState(function () {
        return [
                value,
                new QuillDelta$1()
              ];
      });
  var setState = match$1[1];
  var match$2 = match$1[0];
  var delta = match$2[1];
  var currentValue = match$2[0];
  var debouncedDelta = useDebounced((function (param) {
          return delta;
        }), 750);
  React.useEffect((function () {
          if (JSON.stringify(currentValue) !== JSON.stringify(value)) {
            Curry._1(setState, (function (param) {
                    return [
                            value,
                            param[1]
                          ];
                  }));
          }
          
        }), [JSON.stringify(value)]);
  React.useEffect((function () {
          var nextValue = currentValue.compose(delta);
          if (delta.ops.length !== 0 && JSON.stringify(nextValue) !== JSON.stringify(currentValue)) {
            Curry._1(setState, (function (param) {
                    return [
                            nextValue,
                            new QuillDelta$1()
                          ];
                  }));
            Curry._1(onFlush, nextValue);
          }
          
        }), [debouncedDelta]);
  var onChange = function (value) {
    return Curry._1(setState, (function (param) {
                  var currentValue = param[0];
                  return [
                          currentValue,
                          QuillDelta.diff(currentValue, value)
                        ];
                }));
  };
  var onBlur = function (value) {
    if (onChangeCompleted !== undefined && value !== valueBefore) {
      return Curry._2(onChangeCompleted, value, valueBefore);
    }
    
  };
  var onFocus = function (value) {
    return Curry._1(setValueBefore, (function (param) {
                  return value;
                }));
  };
  return [
          currentValue.compose(delta),
          onChange,
          onBlur,
          onFocus
        ];
}

function useOpsWithState(value, onFlush, onChangeCompleted) {
  var match = useOpsWithDeltaState(new QuillDelta$1().insert(value), (function (value) {
          return Curry._1(onFlush, QuillDelta.toStr(value));
        }), Belt_Option.map(onChangeCompleted, (function (onChangeCompleted, value, valueBefore) {
              return Curry._2(onChangeCompleted, QuillDelta.toStr(value), QuillDelta.toStr(valueBefore));
            })));
  var onFocus = match[3];
  var onBlur = match[2];
  var onChange = match[1];
  return [
          QuillDelta.toStr(match[0]),
          (function (value) {
              return Curry._1(onChange, new QuillDelta$1().insert(value));
            }),
          (function (value) {
              return Curry._1(onBlur, new QuillDelta$1().insert(value));
            }),
          (function (value) {
              return Curry._1(onFocus, new QuillDelta$1().insert(value));
            })
        ];
}

function useAutoOffSwitch(timeout) {
  var match = React.useState(function () {
        
      });
  var setClickTimestamp = match[1];
  var clickTimestamp = match[0];
  React.useEffect((function () {
          if (!Belt_Option.isSome(clickTimestamp)) {
            return ;
          }
          var timeout$1 = setTimeout((function (param) {
                  return Curry._1(setClickTimestamp, (function (param) {
                                
                              }));
                }), timeout);
          return (function (param) {
                    clearTimeout(timeout$1);
                    
                  });
        }), [clickTimestamp]);
  var turnOn = React.useCallback((function (param) {
          return Curry._1(setClickTimestamp, (function (param) {
                        return Date.now();
                      }));
        }), [timeout]);
  return [
          Belt_Option.isSome(clickTimestamp),
          turnOn
        ];
}

function usePrevious1(a) {
  var prevState = React.useRef(a);
  React.useEffect((function () {
          prevState.current = a;
          
        }), [a]);
  return prevState.current;
}

function usePrevious2(param) {
  var b = param[1];
  var a = param[0];
  var prevState = React.useRef([
        a,
        b
      ]);
  React.useEffect((function () {
          prevState.current = [
            a,
            b
          ];
          
        }), [
        a,
        b
      ]);
  return prevState.current;
}

function usePrevious5(param) {
  var e = param[4];
  var d = param[3];
  var c = param[2];
  var b = param[1];
  var a = param[0];
  var prevState = React.useRef([
        a,
        b,
        c,
        d,
        e
      ]);
  React.useEffect((function () {
          prevState.current = [
            a,
            b,
            c,
            d,
            e
          ];
          
        }), [
        a,
        b,
        c,
        d,
        e
      ]);
  return prevState.current;
}

function useDidUpdate1(cb, condition) {
  var hasMountedRef = React.useRef(false);
  React.useEffect((function () {
          if (hasMountedRef.current) {
            return Curry._1(cb, undefined);
          } else {
            hasMountedRef.current = true;
            return ;
          }
        }), condition);
  
}

function useDidUpdate2(cb, condition) {
  var hasMountedRef = React.useRef(false);
  React.useEffect((function () {
          if (hasMountedRef.current) {
            return Curry._1(cb, undefined);
          } else {
            hasMountedRef.current = true;
            return ;
          }
        }), condition);
  
}

function getQueryParams(param) {
  return QueryString.parse(decodeURI(window.location.search.replace("?", "")));
}

function useUrlQueryParam(key, fromQuery, toQuery) {
  var initial = Curry._1(fromQuery, Js_dict.get(getQueryParams(undefined), key));
  var match = React.useState(function () {
        return initial;
      });
  var param = match[0];
  useDidUpdate1((function (param$1) {
          var params = getQueryParams(undefined);
          var newParam = Curry._1(toQuery, param);
          var updatedParams = newParam !== undefined ? (params[key] = newParam, params) : Js_dict.fromArray(Belt_Array.keep(Js_dict.entries(params), (function (param) {
                        return param[0] !== key;
                      })));
          $$Window.$$History.replaceStateSilent("?" + encodeURI(QueryString.stringify(updatedParams)));
          
        }), [param]);
  return [
          param,
          match[1]
        ];
}

function useIntercomVisibility(show) {
  React.useEffect((function () {
          Intercom.update({
                hide_default_launcher: !show
              });
          
        }), [show]);
  
}

function useDisclosure(initialState) {
  var match = React.useState(function () {
        return initialState;
      });
  var setOpened = match[1];
  var opened = match[0];
  var open_ = function (param) {
    if (!opened) {
      return Curry._1(setOpened, (function (param) {
                    return true;
                  }));
    }
    
  };
  var close = function (param) {
    if (opened) {
      return Curry._1(setOpened, (function (param) {
                    return false;
                  }));
    }
    
  };
  var toggle = function (param) {
    return Curry._1(setOpened, (function (opened) {
                  return !opened;
                }));
  };
  return [
          opened,
          {
            open_: open_,
            close: close,
            toggle: toggle
          }
        ];
}

function useBreakingChangesReport(changes) {
  var match = React.useState(function () {
        return Changes.getBreakingChangesReport(changes);
      });
  var setReport = match[1];
  useDidUpdate1((function (param) {
          Curry._1(setReport, (function (param) {
                  return Changes.getBreakingChangesReport(changes);
                }));
          
        }), [changes]);
  return match[0];
}

export {
  useDebounced ,
  useTrailingDebounced ,
  useInterval ,
  useRetained ,
  useDocumentVisibility ,
  keyHandler ,
  useKeys0 ,
  useKeys1 ,
  useKeys2 ,
  useKeys3 ,
  useKeys4 ,
  useOpsWithDeltaState ,
  useOpsWithState ,
  useAutoOffSwitch ,
  usePrevious1 ,
  usePrevious2 ,
  usePrevious5 ,
  useDidUpdate1 ,
  useDidUpdate2 ,
  getQueryParams ,
  useUrlQueryParam ,
  useIntercomVisibility ,
  useDisclosure ,
  useBreakingChangesReport ,
  
}
/* react Not a pure module */
