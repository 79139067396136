// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "../../../app/src/ModelUtils.mjs";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as TrackingPlanModel from "../TrackingPlanModel.mjs";
import * as BeltListExtensions from "../../../app/src/BeltListExtensions.mjs";

function filterOutRemovals(overrides) {
  return Belt_List.keepU(Belt_MapString.toList(overrides), (function (param) {
                if (param[1]) {
                  return true;
                } else {
                  return false;
                }
              }));
}

function computeIncludedSourcesUnion(baseEvent) {
  var variants = baseEvent.variants;
  if (variants.length === 0) {
    return baseEvent.includeSources;
  }
  var includedSourcesOverridesNotRemovals = BeltListExtensions.flatMap(Belt_List.fromArray(variants), (function (variant) {
          return filterOutRemovals(variant.sourceOverrides);
        }));
  var match = Belt_List.partitionU(baseEvent.includeSources, (function (includedSource) {
          return Belt_List.getByU(includedSourcesOverridesNotRemovals, (function (param) {
                        return param[0] === includedSource.id;
                      })) !== undefined;
        }));
  var newIncludedSourcesInVariants = Belt_List.mapU(Belt_List.keepU(includedSourcesOverridesNotRemovals, (function (param) {
              var sourceId = param[0];
              return Belt_List.getByU(baseEvent.includeSources, (function (includedSource) {
                            return includedSource.id === sourceId;
                          })) === undefined;
            })), (function (param) {
          var sourceOverride = param[1];
          var tmp;
          if (sourceOverride) {
            var includeInCodegen = sourceOverride._0.includeInCodegen;
            tmp = includeInCodegen ? includeInCodegen._0 : false;
          } else {
            tmp = false;
          }
          return {
                  id: param[0],
                  includeInCodegen: tmp,
                  inspectorValidation: undefined
                };
        }));
  var overriddenIncludedSources = Belt_List.mapU(match[0], (function (baseIncludedSource) {
          var hasIncludedSourcesInAtLeastOneVariant = Belt_List.someU(includedSourcesOverridesNotRemovals, (function (param) {
                  var sourceOverride = param[1];
                  if (param[0] !== baseIncludedSource.id) {
                    return false;
                  }
                  if (!sourceOverride) {
                    return false;
                  }
                  var includeInCodegenInVariant = sourceOverride._0.includeInCodegen;
                  if (includeInCodegenInVariant) {
                    return includeInCodegenInVariant._0;
                  } else {
                    return false;
                  }
                }));
          return {
                  id: baseIncludedSource.id,
                  includeInCodegen: baseIncludedSource.includeInCodegen || hasIncludedSourcesInAtLeastOneVariant,
                  inspectorValidation: baseIncludedSource.inspectorValidation
                };
        }));
  return Belt_List.concatMany([
              match[1],
              newIncludedSourcesInVariants,
              overriddenIncludedSources
            ]);
}

function computePropertyRefsUnion(baseEvent) {
  var variants = baseEvent.variants;
  if (variants.length === 0) {
    return baseEvent.properties;
  }
  var propertyOverridesNotRemovals = BeltListExtensions.flatMap(Belt_List.fromArray(variants), (function (variant) {
          return filterOutRemovals(variant.propertyOverrides);
        }));
  var match = Belt_List.partitionU(Belt_List.keepMapU(baseEvent.properties, ModelUtils.getPropertyRefFromProperty), (function (propertyRef) {
          return Belt_List.getByU(propertyOverridesNotRemovals, (function (param) {
                        return param[0] === propertyRef.id;
                      })) !== undefined;
        }));
  var overriddenProperties = Belt_List.reduceU(propertyOverridesNotRemovals, match[0], (function (overriddenPropertyRefs, param) {
          var propertyOverride = param[1];
          var propertyId = param[0];
          var match = Belt_List.getByU(overriddenPropertyRefs, (function (overridenPropertyRef) {
                  return overridenPropertyRef.id === propertyId;
                }));
          if (match !== undefined) {
            return Belt_List.mapU(overriddenPropertyRefs, (function (overridenPropertyRef) {
                          if (overridenPropertyRef.id !== propertyId) {
                            return overridenPropertyRef;
                          }
                          var tmp;
                          if (propertyOverride) {
                            var pinnedOverride = propertyOverride._0.pinnedValue;
                            if (pinnedOverride) {
                              var pinnedOverride$1 = pinnedOverride._0;
                              tmp = Caml_obj.caml_equal(overridenPropertyRef.pinnedValue, pinnedOverride$1) ? pinnedOverride$1 : undefined;
                            } else {
                              tmp = overridenPropertyRef.pinnedValue;
                            }
                          } else {
                            tmp = undefined;
                          }
                          return {
                                  id: overridenPropertyRef.id,
                                  description: overridenPropertyRef.description,
                                  pinnedValue: tmp
                                };
                        }));
          }
          var init = TrackingPlanModel.emptyPropertyRef(propertyId);
          var tmp;
          if (propertyOverride) {
            var pinnedOverride = propertyOverride._0.pinnedValue;
            tmp = pinnedOverride ? pinnedOverride._0 : undefined;
          } else {
            tmp = undefined;
          }
          return {
                  hd: {
                    id: init.id,
                    description: init.description,
                    pinnedValue: tmp
                  },
                  tl: overriddenPropertyRefs
                };
        }));
  return Belt_List.mapU(Belt_List.concat(match[1], overriddenProperties), (function (propertyRef) {
                return {
                        TAG: /* PropertyRef */0,
                        _0: propertyRef
                      };
              }));
}

function unionSingleEventVariants(model, $$event) {
  if (Caml_obj.caml_equal($$event.variants, [])) {
    return model;
  }
  var modelWithUnionEvent = ModelUtils.updateEvent(model, $$event.id, (function ($$event) {
          return {
                  id: $$event.id,
                  name: $$event.name,
                  uniqueName: $$event.uniqueName,
                  description: $$event.description,
                  properties: computePropertyRefsUnion($$event),
                  propertyBundles: $$event.propertyBundles,
                  variants: [],
                  types: $$event.types,
                  tags: $$event.tags,
                  excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                  includeSources: computeIncludedSourcesUnion($$event),
                  includeDestinations: $$event.includeDestinations,
                  hashes: $$event.hashes,
                  propertyWhitelist: $$event.propertyWhitelist,
                  eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                  userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                  triggers: $$event.triggers,
                  globalRequirementsMetadata: $$event.globalRequirementsMetadata
                };
        }));
  var modelWithUnionEventAndAbsences = Belt_Array.reduceU($$event.variants, modelWithUnionEvent, (function (modelToEnrichWithAllVariantAbsences, variant) {
          return Belt_Array.reduceU(Belt_MapString.toArray(variant.propertyOverrides), modelToEnrichWithAllVariantAbsences, (function (modelToEnrichWithSinglePropertyOverrideAbsence, param) {
                        var propertyId = param[0];
                        var propertyOverride = param[1];
                        var property = ModelUtils.resolvePropertyById(propertyId, modelToEnrichWithSinglePropertyOverrideAbsence);
                        if (property === undefined) {
                          return modelToEnrichWithSinglePropertyOverrideAbsence;
                        }
                        var isPropertyIncludedOnEvent = ModelUtils.isPropertyIncludedOnEventAndSources(undefined, property, $$event.id, Belt_List.map($$event.includeSources, (function (includedSource) {
                                    return includedSource.id;
                                  })), model, undefined);
                        var match = isPropertyIncludedOnEvent ? "PropertyIsIncludedInBase" : "PropertyNotIncludedInBase";
                        var exit = 0;
                        var absenceOverride;
                        if (propertyOverride) {
                          var absenceOverride$1 = propertyOverride._0.absence;
                          if (absenceOverride$1) {
                            switch (absenceOverride$1._0) {
                              case /* AlwaysSent */0 :
                                  if (match === "PropertyIsIncludedInBase") {
                                    return modelToEnrichWithSinglePropertyOverrideAbsence;
                                  }
                                  absenceOverride = propertyOverride._0.absence;
                                  exit = 2;
                                  break;
                              case /* SometimesSent */1 :
                                  if (match === "PropertyIsIncludedInBase") {
                                    absenceOverride = absenceOverride$1;
                                    exit = 2;
                                  } else {
                                    absenceOverride = propertyOverride._0.absence;
                                    exit = 2;
                                  }
                                  break;
                              case /* NeverSent */2 :
                                  if (match === "PropertyNotIncludedInBase") {
                                    return ModelUtils.updateProperty(modelToEnrichWithSinglePropertyOverrideAbsence, propertyId, (function (property) {
                                                  var match = property.absence;
                                                  var tmp;
                                                  tmp = match !== undefined ? (
                                                      typeof match === "number" ? ({
                                                            TAG: /* Mixed */1,
                                                            _0: Belt_MapString.fromArray([[
                                                                    $$event.id,
                                                                    {
                                                                      TAG: /* AllSources */0,
                                                                      _0: /* NeverSent */0
                                                                    }
                                                                  ]])
                                                          }) : (
                                                          match.TAG === /* SometimesSent */0 ? ({
                                                                TAG: /* SometimesSent */0,
                                                                _0: match._0
                                                              }) : ({
                                                                TAG: /* Mixed */1,
                                                                _0: Belt_MapString.set(match._0, $$event.id, {
                                                                      TAG: /* AllSources */0,
                                                                      _0: /* NeverSent */0
                                                                    })
                                                              })
                                                        )
                                                    ) : undefined;
                                                  return {
                                                          id: property.id,
                                                          name: property.name,
                                                          uniqueName: property.uniqueName,
                                                          description: property.description,
                                                          type_: property.type_,
                                                          sendAs: property.sendAs,
                                                          validations: property.validations,
                                                          eventSpecificAllowedPropertyValues: property.eventSpecificAllowedPropertyValues,
                                                          auto: property.auto,
                                                          builtIn: property.builtIn,
                                                          analyticsTools: property.analyticsTools,
                                                          devPlatforms: property.devPlatforms,
                                                          list: property.list,
                                                          operation: property.operation,
                                                          optionalDeprecated: property.optionalDeprecated,
                                                          excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                                          optionalWhenInObject: property.optionalWhenInObject,
                                                          absence: tmp,
                                                          globalRequirementsMetadata: property.globalRequirementsMetadata
                                                        };
                                                }));
                                  }
                                  exit = 1;
                                  break;
                              
                            }
                          } else {
                            if (match === "PropertyIsIncludedInBase") {
                              return modelToEnrichWithSinglePropertyOverrideAbsence;
                            }
                            absenceOverride = propertyOverride._0.absence;
                            exit = 2;
                          }
                        } else {
                          if (match === "PropertyNotIncludedInBase") {
                            return modelToEnrichWithSinglePropertyOverrideAbsence;
                          }
                          exit = 1;
                        }
                        switch (exit) {
                          case 1 :
                              return ModelUtils.updateProperty(modelToEnrichWithSinglePropertyOverrideAbsence, propertyId, (function (property) {
                                            var absence = property.absence;
                                            var tmp;
                                            if (absence !== undefined) {
                                              if (typeof absence === "number") {
                                                tmp = {
                                                  TAG: /* Mixed */1,
                                                  _0: Belt_MapString.fromArray([[
                                                          $$event.id,
                                                          {
                                                            TAG: /* AllSources */0,
                                                            _0: /* SometimesSent */{
                                                              _0: "Never sent in a variant"
                                                            }
                                                          }
                                                        ]])
                                                };
                                              } else if (absence.TAG === /* SometimesSent */0) {
                                                tmp = absence;
                                              } else {
                                                var absence$1 = absence._0;
                                                tmp = Belt_MapString.get(absence$1, $$event.id) === undefined ? ({
                                                      TAG: /* Mixed */1,
                                                      _0: Belt_MapString.set(absence$1, $$event.id, {
                                                            TAG: /* AllSources */0,
                                                            _0: /* SometimesSent */{
                                                              _0: "Never sent in a variant"
                                                            }
                                                          })
                                                    }) : absence;
                                              }
                                            } else {
                                              tmp = undefined;
                                            }
                                            return {
                                                    id: property.id,
                                                    name: property.name,
                                                    uniqueName: property.uniqueName,
                                                    description: property.description,
                                                    type_: property.type_,
                                                    sendAs: property.sendAs,
                                                    validations: property.validations,
                                                    eventSpecificAllowedPropertyValues: property.eventSpecificAllowedPropertyValues,
                                                    auto: property.auto,
                                                    builtIn: property.builtIn,
                                                    analyticsTools: property.analyticsTools,
                                                    devPlatforms: property.devPlatforms,
                                                    list: property.list,
                                                    operation: property.operation,
                                                    optionalDeprecated: property.optionalDeprecated,
                                                    excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                                    optionalWhenInObject: property.optionalWhenInObject,
                                                    absence: tmp,
                                                    globalRequirementsMetadata: property.globalRequirementsMetadata
                                                  };
                                          }));
                          case 2 :
                              var message;
                              if (absenceOverride) {
                                switch (absenceOverride._0) {
                                  case /* AlwaysSent */0 :
                                      message = "Always sent in a variant";
                                      break;
                                  case /* SometimesSent */1 :
                                      message = "Sometimes sent in a variant";
                                      break;
                                  case /* NeverSent */2 :
                                      message = "Never sent in a variant";
                                      break;
                                  
                                }
                              } else {
                                message = "Present in a variant";
                              }
                              return ModelUtils.updateProperty(modelToEnrichWithSinglePropertyOverrideAbsence, propertyId, (function (property) {
                                            var match = property.absence;
                                            var tmp;
                                            tmp = match !== undefined ? (
                                                typeof match === "number" ? ({
                                                      TAG: /* Mixed */1,
                                                      _0: Belt_MapString.fromArray([[
                                                              $$event.id,
                                                              {
                                                                TAG: /* AllSources */0,
                                                                _0: /* SometimesSent */{
                                                                  _0: message
                                                                }
                                                              }
                                                            ]])
                                                    }) : (
                                                    match.TAG === /* SometimesSent */0 ? ({
                                                          TAG: /* SometimesSent */0,
                                                          _0: match._0
                                                        }) : ({
                                                          TAG: /* Mixed */1,
                                                          _0: Belt_MapString.set(match._0, $$event.id, {
                                                                TAG: /* AllSources */0,
                                                                _0: /* SometimesSent */{
                                                                  _0: message
                                                                }
                                                              })
                                                        })
                                                  )
                                              ) : undefined;
                                            return {
                                                    id: property.id,
                                                    name: property.name,
                                                    uniqueName: property.uniqueName,
                                                    description: property.description,
                                                    type_: property.type_,
                                                    sendAs: property.sendAs,
                                                    validations: property.validations,
                                                    eventSpecificAllowedPropertyValues: property.eventSpecificAllowedPropertyValues,
                                                    auto: property.auto,
                                                    builtIn: property.builtIn,
                                                    analyticsTools: property.analyticsTools,
                                                    devPlatforms: property.devPlatforms,
                                                    list: property.list,
                                                    operation: property.operation,
                                                    optionalDeprecated: property.optionalDeprecated,
                                                    excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                                    optionalWhenInObject: property.optionalWhenInObject,
                                                    absence: tmp,
                                                    globalRequirementsMetadata: property.globalRequirementsMetadata
                                                  };
                                          }));
                          
                        }
                      }));
        }));
  return Belt_Array.reduceU($$event.variants, modelWithUnionEventAndAbsences, (function (modelToEnrichWithAllVariantAllowedValues, variant) {
                return Belt_Array.reduceU(Belt_MapString.toArray(variant.propertyOverrides), modelToEnrichWithAllVariantAllowedValues, (function (modelToEnrichWithSinglePropertyOverrideAllowedValues, param) {
                              var propertyId = param[0];
                              var propertyOverride = param[1];
                              var property = ModelUtils.resolvePropertyById(propertyId, modelToEnrichWithSinglePropertyOverrideAllowedValues);
                              if (property === undefined) {
                                return modelToEnrichWithSinglePropertyOverrideAllowedValues;
                              }
                              ModelUtils.isPropertyIncludedOnEventAndSources(undefined, property, $$event.id, Belt_List.map($$event.includeSources, (function (includedSource) {
                                          return includedSource.id;
                                        })), model, undefined);
                              if (!propertyOverride) {
                                return modelToEnrichWithSinglePropertyOverrideAllowedValues;
                              }
                              var allowedValuesOverride = propertyOverride._0.allowedValues;
                              if (!allowedValuesOverride) {
                                return modelToEnrichWithSinglePropertyOverrideAllowedValues;
                              }
                              var allowedValuesOverride$1 = allowedValuesOverride._0;
                              return ModelUtils.updateProperty(modelToEnrichWithSinglePropertyOverrideAllowedValues, propertyId, (function (property) {
                                            return {
                                                    id: property.id,
                                                    name: property.name,
                                                    uniqueName: property.uniqueName,
                                                    description: property.description,
                                                    type_: property.type_,
                                                    sendAs: property.sendAs,
                                                    validations: property.validations,
                                                    eventSpecificAllowedPropertyValues: Belt_Array.mapU(property.eventSpecificAllowedPropertyValues, (function (param) {
                                                            var disallowedEventsToSources = param[1];
                                                            var allowedValueLiteral = param[0];
                                                            var allowedValuesString = typeof allowedValueLiteral === "object" && allowedValueLiteral.NAME === "StringLit" ? allowedValueLiteral.VAL : Pervasives.failwith("Only string literals are supported for allowed values");
                                                            var maybeAllowedValueDisallowedSourcesOverride = Belt_MapString.get(allowedValuesOverride$1, allowedValuesString);
                                                            var newDisallowedEventsToSources = maybeAllowedValueDisallowedSourcesOverride !== undefined ? Belt_MapString.update(disallowedEventsToSources, $$event.id, (function (baseEventDisallowedSources) {
                                                                      if (baseEventDisallowedSources === undefined) {
                                                                        return ;
                                                                      }
                                                                      if (baseEventDisallowedSources) {
                                                                        if (!maybeAllowedValueDisallowedSourcesOverride) {
                                                                          return baseEventDisallowedSources;
                                                                        }
                                                                        
                                                                      } else if (!maybeAllowedValueDisallowedSourcesOverride) {
                                                                        return /* Disallowed */0;
                                                                      }
                                                                      if (Belt_SetString.isEmpty(maybeAllowedValueDisallowedSourcesOverride._0)) {
                                                                        return ;
                                                                      } else if (baseEventDisallowedSources) {
                                                                        return /* DisallowedSources */{
                                                                                _0: Belt_SetString.intersect(baseEventDisallowedSources._0, maybeAllowedValueDisallowedSourcesOverride._0)
                                                                              };
                                                                      } else {
                                                                        return maybeAllowedValueDisallowedSourcesOverride;
                                                                      }
                                                                    })) : disallowedEventsToSources;
                                                            return [
                                                                    allowedValueLiteral,
                                                                    newDisallowedEventsToSources
                                                                  ];
                                                          })),
                                                    auto: property.auto,
                                                    builtIn: property.builtIn,
                                                    analyticsTools: property.analyticsTools,
                                                    devPlatforms: property.devPlatforms,
                                                    list: property.list,
                                                    operation: property.operation,
                                                    optionalDeprecated: property.optionalDeprecated,
                                                    excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                                    optionalWhenInObject: property.optionalWhenInObject,
                                                    absence: property.absence,
                                                    globalRequirementsMetadata: property.globalRequirementsMetadata
                                                  };
                                          }));
                            }));
              }));
}

function unionAllEventVariants(model) {
  return Belt_List.reduceU(model.events, model, unionSingleEventVariants);
}

export {
  unionSingleEventVariants ,
  unionAllEventVariants ,
  
}
/* ModelUtils Not a pure module */
