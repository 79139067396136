// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Models from "./Models.mjs";
import * as DateFns from "./DateFns.mjs";
import * as Printer from "../../model/src/Printer.mjs";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as ArrayExt from "./ArrayExt.mjs";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as ActionUtils from "../../model/src/ActionUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as App from "firebase/app";
import * as ActionsReducer from "./actionsReducer.mjs";
import * as BranchStateWriter from "./BranchStateWriter.mjs";
import * as BeltListExtensions from "./BeltListExtensions.mjs";

function branchToId(branch) {
  if (typeof branch === "object") {
    return branch.VAL;
  } else {
    return "master";
  }
}

function wantsToWriteBranchLifecycleActionToMain(actions) {
  return Belt_Array.someU(actions, (function (param) {
                var action = param[0];
                if (typeof action !== "object") {
                  return false;
                }
                var variant = action.NAME;
                if (variant === "CloseBranch" || variant === "OpenBranch" || variant === "CreateDemoBranch") {
                  return true;
                } else {
                  return variant === "MergeBranch";
                }
              }));
}

function stringToByteArray(string) {
  return new TextEncoder().encode(string);
}

function splitStringIntoChunks(chunkSize, _chunksOpt, _string) {
  while(true) {
    var chunksOpt = _chunksOpt;
    var string = _string;
    var chunks = chunksOpt !== undefined ? chunksOpt : [];
    var stringByteArray = new TextEncoder().encode(string);
    var chunkByteArray = stringByteArray.slice(0, chunkSize);
    var chunk = new TextDecoder().decode(chunkByteArray);
    var nextChunks = chunks.concat([chunk]);
    var restByteArray = stringByteArray.slice(chunkSize, stringByteArray.length);
    if (restByteArray.length === 0) {
      return nextChunks;
    }
    var rest = new TextDecoder().decode(restByteArray);
    _string = rest;
    _chunksOpt = nextChunks;
    continue ;
  };
}

function snapshot(schemaId, branchId, model, latestAction) {
  var match = latestAction.branchId;
  var tmp;
  if (match !== undefined && match === branchId) {
    var jsonModel = Printer.printModelString(model);
    var versionRef = Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("versions").doc();
    var versionId = versionRef.id;
    var versionDocSize = new TextEncoder().encode(jsonModel).length;
    var isChunked = versionDocSize > 524288;
    if (isChunked) {
      var chunks = splitStringIntoChunks(524288, undefined, jsonModel);
      var version = {
        id: versionId,
        createdAt: App.default.firestore.FieldValue.serverTimestamp(),
        lastActionId: latestAction.id,
        lastActionTimestamp: latestAction.createdAt,
        branchId: branchId,
        chunks: true,
        chunksCount: chunks.length
      };
      var chunksCollection = versionRef.collection("chunks");
      var batch = Firebase.app(undefined).firestore().batch();
      batch.set(versionRef, version);
      if (versionDocSize < 10000000) {
        Belt_Array.forEachWithIndexU(chunks, (function (index, shardString) {
                var shard = {
                  index: index,
                  contentsStr: shardString
                };
                var shardRef = chunksCollection.doc(String(index));
                batch.set(shardRef, shard);
                
              }));
        tmp = batch.commit();
      } else {
        tmp = Promise.reject(Js_exn.raiseError("Provided tracking plan is too large. Try to import in chunks."));
      }
    } else {
      var version$1 = {
        id: versionId,
        contentsStr: jsonModel,
        createdAt: App.default.firestore.FieldValue.serverTimestamp(),
        lastActionId: latestAction.id,
        lastActionTimestamp: latestAction.createdAt,
        branchId: branchId,
        chunks: false,
        chunksCount: 0
      };
      tmp = versionRef.set(version$1);
    }
  } else {
    tmp = Promise.resolve(undefined);
  }
  return $$Promise.$$catch(tmp, (function (error) {
                return Promise.resolve((console.error("Could not write new snapshot", error), undefined));
              }));
}

function buildAction(timestampOpt, action, schemaId, branchId, userId, context, isCreateDemoBranchAutoAction, audit, order) {
  var timestamp = timestampOpt !== undefined ? Caml_option.valFromOption(timestampOpt) : App.default.firestore.FieldValue.serverTimestamp();
  var actionJson = Printer.printAction(action);
  var newActionRef = Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("actions").doc();
  var actionId = newActionRef.id;
  var actionObject = {
    id: actionId,
    contentsJson: actionJson,
    createdAt: timestamp,
    createdBy: userId,
    context: context,
    branchId: branchId,
    audit: audit,
    actionImpactLevel: ActionUtils.getActionImpactLevelFromAction(action),
    order: order,
    isCreateDemoBranchAutoAction: isCreateDemoBranchAutoAction
  };
  return [
          newActionRef,
          actionObject,
          actionId
        ];
}

function getWriteOpsForActions(actions, schemaId, branchId, userId, audit, isCreateDemoBranchAutoAction) {
  return Belt_Array.concatMany(Belt_Array.mapWithIndexU(actions, (function (index, param) {
                    var action = param[0];
                    var match = buildAction(undefined, action, schemaId, branchId, userId, param[1], isCreateDemoBranchAutoAction, audit, index);
                    var actionId = match[2];
                    var actionObject = match[1];
                    var maybeNotificationTriggersWrite;
                    if (typeof action === "object") {
                      var variant = action.NAME;
                      if (variant === "CollaboratorRemoved" || variant === "CloseBranch" || variant === "PropertyComment" || variant === "GoalComment" || variant === "IntegrationComment" || variant === "InspectorIssueComment" || variant === "MetricComment" || variant === "CollaboratorAdded" || variant === "PropertyGroupComment" || variant === "EventComment" || variant === "OpenBranch" || variant === "BranchComment" || variant === "SetBranchStatus" || variant === "MergeBranch") {
                        var notificationTriggerRef = Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("notificationTriggers").doc(actionId);
                        maybeNotificationTriggersWrite = [
                          notificationTriggerRef,
                          actionObject
                        ];
                      } else {
                        maybeNotificationTriggersWrite = undefined;
                      }
                    } else {
                      maybeNotificationTriggersWrite = undefined;
                    }
                    var maybeIntegrationTriggersWrite;
                    if (typeof action === "object" && action.NAME === "MergeBranch") {
                      var integrationTriggerRef = Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("integrationTriggers").doc(actionId);
                      maybeIntegrationTriggersWrite = [
                        integrationTriggerRef,
                        actionObject
                      ];
                    } else {
                      maybeIntegrationTriggersWrite = undefined;
                    }
                    var maybeActionWrite = [
                      match[0],
                      actionObject
                    ];
                    return Belt_Array.keepMap([
                                maybeNotificationTriggersWrite,
                                maybeIntegrationTriggersWrite,
                                maybeActionWrite
                              ], (function (a) {
                                  return a;
                                }));
                  })));
}

function writeToFirebase(userId, schemaId, currentBranch, isCreateDemoBranchAutoAction, numberOfActions, numberOfMasterActions, latestAction, latestMasterAction, model, masterModel, audit, batch, batchSizeOpt, actions) {
  var batchSize = batchSizeOpt !== undefined ? batchSizeOpt : 200;
  if (Belt_Option.isSome(batch) && actions.length > 100) {
    Pervasives.failwith("Actions.re: Can't write more than 100 actions to a custom batch");
  }
  var writeBatchWithActionsToFirestore = function (writeOps) {
    var match = Belt_Array.reduce(ArrayExt.splitIntoChunks(writeOps, batchSize), [
          [],
          0
        ], (function (param, writeOps) {
            var batch$1 = Belt_Option.getWithDefault(batch, Firebase.app(undefined).firestore().batch());
            Belt_Array.forEachU(writeOps, (function (param) {
                    batch$1.set(param[0], param[1]);
                    
                  }));
            return [
                    Belt_Array.concat(param[0], [batch$1]),
                    param[1] + actions.length | 0
                  ];
          }));
    return Belt_Array.reduce(match[0], Promise.resolve(undefined), (function (promise, batch) {
                  return promise.then(function (param) {
                              return batch.commit();
                            });
                }));
  };
  var writeTransactionWithBranchStateUpdateToFirestore = function (branchId, writeOps) {
    return BranchStateWriter.writeBranchStateToFirestoreFromActionsInTransaction(schemaId, branchId, actions, userId, model, masterModel, writeOps);
  };
  var writeOps = getWriteOpsForActions(actions, schemaId, branchToId(currentBranch), userId, audit, isCreateDemoBranchAutoAction);
  var branchId = branchToId(currentBranch);
  var match = BranchStateWriter.wantsToUpdateBranchStatus(actions);
  return (
            currentBranch === "Master" ? (
                match ? writeTransactionWithBranchStateUpdateToFirestore(branchId, writeOps) : writeBatchWithActionsToFirestore(writeOps)
              ) : (
                match ? writeTransactionWithBranchStateUpdateToFirestore(branchId, writeOps) : writeBatchWithActionsToFirestore(writeOps).then(function (param) {
                        return writeTransactionWithBranchStateUpdateToFirestore(branchId, []);
                      })
              )
          ).then(function (param) {
              if (typeof currentBranch === "object") {
                var maxNumOfActionsExceeded = numberOfActions > 100 && numberOfActions % 10 === 0;
                if (!maxNumOfActionsExceeded) {
                  return Promise.resolve(undefined);
                }
                var match = Belt_Option.flatMap(latestAction, (function (action) {
                        return Models.toDateOption(action.createdAt);
                      }));
                if (latestAction !== undefined && match !== undefined) {
                  return snapshot(schemaId, currentBranch.VAL, model, Caml_option.valFromOption(latestAction));
                } else {
                  return Promise.resolve(undefined);
                }
              }
              var maxNumOfMasterActionsExceeded = numberOfMasterActions > 100 && (numberOfMasterActions % 10 === 0 || Belt_Array.some(actions, (function (action) {
                        var match = action[0];
                        if (typeof match === "object") {
                          return match.NAME === "MergeBranch";
                        } else {
                          return false;
                        }
                      }))) && !Belt_Array.some(actions, (function (action) {
                      var match = action[0];
                      if (typeof match === "object") {
                        return match.NAME === "OpenBranch";
                      } else {
                        return false;
                      }
                    }));
              if (!maxNumOfMasterActionsExceeded) {
                return Promise.resolve(undefined);
              }
              var match$1 = Belt_Option.flatMap(latestMasterAction, (function (action) {
                      return Models.toDateOption(action.createdAt);
                    }));
              if (latestMasterAction !== undefined && match$1 !== undefined) {
                return snapshot(schemaId, "master", masterModel, Caml_option.valFromOption(latestMasterAction));
              } else {
                return Promise.resolve(undefined);
              }
            });
}

function groupCompare(a, b) {
  var match = a.contents;
  var match$1 = b.contents;
  if (typeof match !== "object") {
    if (match === "StartedImport") {
      return match$1 === "StartedImport";
    } else {
      return false;
    }
  }
  var variant = match.NAME;
  if (variant === "UpdateIntegrationAutoPublish") {
    if (typeof match$1 === "object" && match$1.NAME === "UpdateIntegrationAutoPublish") {
      return match.VAL[0] === match$1.VAL[0];
    } else {
      return false;
    }
  } else if (variant === "UpdateGoalDescription") {
    if (typeof match$1 === "object" && match$1.NAME === "UpdateGoalDescription") {
      return match.VAL[0] === match$1.VAL[0];
    } else {
      return false;
    }
  } else if (variant === "UpdatePropertyName") {
    if (typeof match$1 === "object" && match$1.NAME === "UpdatePropertyName") {
      return match.VAL[0] === match$1.VAL[0];
    } else {
      return false;
    }
  } else if (variant === "UpdatePropertyDescription") {
    if (typeof match$1 === "object" && match$1.NAME === "UpdatePropertyDescription") {
      return match.VAL[0] === match$1.VAL[0];
    } else {
      return false;
    }
  } else if (variant === "ReorderEventsInGoalV2") {
    if (typeof match$1 === "object" && match$1.NAME === "ReorderEventsInGoalV2") {
      return match.VAL[0] === match$1.VAL[0];
    } else {
      return false;
    }
  } else if (variant === "ReorderMetricsInGoal") {
    if (typeof match$1 === "object" && match$1.NAME === "ReorderMetricsInGoal") {
      return match.VAL[0] === match$1.VAL[0];
    } else {
      return false;
    }
  } else if (variant === "UpdateSourceName") {
    if (typeof match$1 === "object" && match$1.NAME === "UpdateSourceName") {
      return match.VAL[0] === match$1.VAL[0];
    } else {
      return false;
    }
  } else if (variant === "ReorderEventsInGoal") {
    if (typeof match$1 === "object" && match$1.NAME === "ReorderEventsInGoal") {
      return match.VAL[0] === match$1.VAL[0];
    } else {
      return false;
    }
  } else if (variant === "ReorderMetricsInGoalV2") {
    if (typeof match$1 === "object" && match$1.NAME === "ReorderMetricsInGoalV2") {
      return match.VAL[0] === match$1.VAL[0];
    } else {
      return false;
    }
  } else if (variant === "ReorderEventsInMetricV2") {
    if (typeof match$1 === "object" && match$1.NAME === "ReorderEventsInMetricV2") {
      return match.VAL[0] === match$1.VAL[0];
    } else {
      return false;
    }
  } else if (variant === "UpdateIntegrationConfig") {
    if (typeof match$1 === "object" && match$1.NAME === "UpdateIntegrationConfig") {
      return match.VAL[0] === match$1.VAL[0];
    } else {
      return false;
    }
  } else if (variant === "UpdateDestinationName") {
    if (typeof match$1 === "object" && match$1.NAME === "UpdateDestinationName") {
      return match.VAL[0] === match$1.VAL[0];
    } else {
      return false;
    }
  } else if (variant === "UpdateEventDescription") {
    if (typeof match$1 === "object" && match$1.NAME === "UpdateEventDescription") {
      return match.VAL[0] === match$1.VAL[0];
    } else {
      return false;
    }
  } else if (variant === "ReorderEventsInMetric") {
    if (typeof match$1 === "object" && match$1.NAME === "ReorderEventsInMetric") {
      return match.VAL[0] === match$1.VAL[0];
    } else {
      return false;
    }
  } else if (variant === "UpdatePropertyGroupDescription") {
    if (typeof match$1 === "object" && match$1.NAME === "UpdatePropertyGroupDescription") {
      return match.VAL[0] === match$1.VAL[0];
    } else {
      return false;
    }
  } else if (variant === "UpdateGoalName") {
    if (typeof match$1 === "object" && match$1.NAME === "UpdateGoalName") {
      return match.VAL[0] === match$1.VAL[0];
    } else {
      return false;
    }
  } else if (variant === "UpdateMetricName") {
    if (typeof match$1 === "object" && match$1.NAME === "UpdateMetricName") {
      return match.VAL[0] === match$1.VAL[0];
    } else {
      return false;
    }
  } else if (variant === "UpdateIntegrationName") {
    if (typeof match$1 === "object" && match$1.NAME === "UpdateIntegrationName") {
      return match.VAL[0] === match$1.VAL[0];
    } else {
      return false;
    }
  } else if (variant === "UpdatePropertyGroupName") {
    if (typeof match$1 === "object" && match$1.NAME === "UpdatePropertyGroupName") {
      return match.VAL[0] === match$1.VAL[0];
    } else {
      return false;
    }
  } else if (variant === "UpdateEventName") {
    if (typeof match$1 === "object" && match$1.NAME === "UpdateEventName") {
      return match.VAL[0] === match$1.VAL[0];
    } else {
      return false;
    }
  } else if (variant === "UpdateIntegrationFilters") {
    if (typeof match$1 === "object" && match$1.NAME === "UpdateIntegrationFilters") {
      return match.VAL[0] === match$1.VAL[0];
    } else {
      return false;
    }
  } else if (variant === "UpdateMetricDescription") {
    if (typeof match$1 === "object" && match$1.NAME === "UpdateMetricDescription") {
      return match.VAL[0] === match$1.VAL[0];
    } else {
      return false;
    }
  } else if (variant === "UpdateDestinationDevApiKey") {
    if (typeof match$1 === "object" && match$1.NAME === "UpdateDestinationDevApiKey") {
      return match.VAL[0] === match$1.VAL[0];
    } else {
      return false;
    }
  } else if (variant === "UpdateLibraryName") {
    if (typeof match$1 === "object" && match$1.NAME === "UpdateLibraryName") {
      return match.VAL[0] === match$1.VAL[0];
    } else {
      return false;
    }
  } else if (variant === "UpdateDestinationProdApiKey" && typeof match$1 === "object" && match$1.NAME === "UpdateDestinationProdApiKey") {
    return match.VAL[0] === match$1.VAL[0];
  } else {
    return false;
  }
}

function filterSystemActions(actions) {
  return Belt_List.keep(actions, (function (item) {
                return !Belt_Option.getWithDefault(Caml_option.undefined_to_opt(item.system), false);
              }));
}

function groupActions(actions) {
  return BeltListExtensions.groupConsecutive(actions, groupCompare);
}

function groupActionsByDate(actions) {
  return BeltListExtensions.groupConsecutive(actions, (function (a, b) {
                return DateFns.format("yyyy.MM.dd", Belt_Option.getWithDefault(Models.toDateOption(a.createdAt), new Date())) === DateFns.format("yyyy.MM.dd", Belt_Option.getWithDefault(Models.toDateOption(b.createdAt), new Date()));
              }));
}

var reduce = ActionsReducer.reduce;

var reduceBoxed = ActionsReducer.reduceFunction;

export {
  branchToId ,
  wantsToWriteBranchLifecycleActionToMain ,
  reduce ,
  reduceBoxed ,
  stringToByteArray ,
  splitStringIntoChunks ,
  snapshot ,
  buildAction ,
  getWriteOpsForActions ,
  writeToFirebase ,
  groupCompare ,
  filterSystemActions ,
  groupActions ,
  groupActionsByDate ,
  
}
/* DateFns Not a pure module */
