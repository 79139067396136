// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Link from "./Link.mjs";
import * as $$Text from "./Text.mjs";
import * as Kbar from "kbar";
import * as Canny from "./externals/canny.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Icons from "./Icons.mjs";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as DateFns from "./DateFns.mjs";
import * as AvoAdmin from "./AvoAdmin.mjs";
import * as IconSearch from "./IconSearch.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as RouterStore from "./RouterStore.mjs";
import * as ProductTours from "./ProductTours.mjs";
import * as ProfilePhoto from "./ProfilePhoto.mjs";
import * as TrialRequest from "./TrialRequest.mjs";
import * as IconLightning from "./IconLightning.mjs";
import * as SandboxBanner from "./SandboxBanner.mjs";
import * as ViewerContext from "./ViewerContext.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as AppFeatureFlag from "./AppFeatureFlag.mjs";
import * as GettingStarted from "./GettingStarted.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as SidebarNavigation from "./SidebarNavigation.mjs";
import * as SidebarSettingsCog from "./SidebarSettingsCog.mjs";
import * as OrganizationContext from "./OrganizationContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as SidebarBranchControl from "./SidebarBranchControl.mjs";
import * as SetupIssuesAlertsFlow from "./SetupIssuesAlertsFlow.mjs";
import * as GettingStarted__SidebarProgress from "./GettingStarted__SidebarProgress.mjs";
import * as OnboardingAndTrialProgressInSidebar from "./OnboardingAndTrialProgressInSidebar.mjs";

var bannerStyle = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.button),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.padding2(Css.px(6), Css.px(6)),
                  tl: {
                    hd: Css.borderRadius(Styles.Border.radius),
                    tl: {
                      hd: Css.backgroundColor(Styles.Color.avoPink),
                      tl: {
                        hd: Css.color(Styles.Color.white),
                        tl: {
                          hd: Css.textAlign("left"),
                          tl: {
                            hd: Css.fontWeight(Styles.FontWeight.medium),
                            tl: {
                              hd: Css.fontSize(Styles.FontSize.small),
                              tl: {
                                hd: Css.marginBottom(Css.px(16)),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var upgradeStyle = Curry._1(Css.style, {
      hd: Css.paddingLeft(Css.px(5)),
      tl: {
        hd: Css.textDecoration("underline"),
        tl: {
          hd: Css.fontWeight(Styles.FontWeight.bold),
          tl: /* [] */0
        }
      }
    });

function Sidebar$SidebarTrialBanner(Props) {
  var role = Props.role;
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var trial = workspace.trial;
  if (trial !== undefined) {
    return React.createElement("button", {
                className: bannerStyle,
                onClick: (function (param) {
                    Router.Schema.pushSchemaRoute(undefined, undefined, "billing");
                    return AnalyticsRe.upgradeBannerClicked(schemaBundle, AnalyticsUtils.roleToAnalyticsRole(role), schemaBundle.branchId, schemaBundle.schemaId);
                  })
              }, React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css_Legacy_Core.SVG.fill(Styles.Color.white),
                          tl: {
                            hd: Css.margin4(Css.px(0), Css.px(10), Css.px(0), Css.px(5)),
                            tl: /* [] */0
                          }
                        })
                  }, React.createElement(IconLightning.make, {
                        size: 16
                      })), React.createElement("div", undefined, DateFns.formatDistanceToNow(false, false, trial.endAt) + " left of your free trial.", React.createElement("span", {
                        className: upgradeStyle
                      }, "Upgrade now!")));
  } else {
    return null;
  }
}

var SidebarTrialBanner = {
  bannerStyle: bannerStyle,
  upgradeStyle: upgradeStyle,
  make: Sidebar$SidebarTrialBanner
};

function containerStyle(hidden, withSandboxBanner) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.flexDirection("column"),
                tl: {
                  hd: Css.position("fixed"),
                  tl: {
                    hd: Css.top(Css.px(SandboxBanner.getHeight(withSandboxBanner))),
                    tl: {
                      hd: Css.bottom(Css.px(0)),
                      tl: {
                        hd: Css.left(Css.px(0)),
                        tl: {
                          hd: Css.padding2("zero", Css.px(8)),
                          tl: {
                            hd: Css.transform(Css.translateX(Css.px(hidden ? -250 : 0))),
                            tl: {
                              hd: Css.transition({
                                    NAME: "ms",
                                    VAL: Styles.Duration.$$long
                                  }, undefined, undefined, "transform"),
                              tl: {
                                hd: Css.width(Css.px(250)),
                                tl: {
                                  hd: Css.backgroundColor(Styles.Color.dark02),
                                  tl: {
                                    hd: Css.zIndex(Styles.ZIndex.sidebar),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function Sidebar$SidebarContainer(Props) {
  var hidden = Props.hidden;
  var children = Props.children;
  var workspace = WorkspaceContext.use(undefined);
  return React.createElement("aside", {
              className: containerStyle(hidden, workspace.isSandbox),
              id: "sidebar-tour"
            }, children);
}

var SidebarContainer = {
  containerStyle: containerStyle,
  make: Sidebar$SidebarContainer
};

var headerStyle = Curry._1(Css.style, {
      hd: Css.media("(max-height: 660px)", {
            hd: Css.padding2(Css.px(12), Css.px(0)),
            tl: /* [] */0
          }),
      tl: {
        hd: Css.display("grid"),
        tl: {
          hd: Css.gridTemplateColumns({
                hd: "auto",
                tl: {
                  hd: Css.px(32),
                  tl: /* [] */0
                }
              }),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.padding2(Css.px(16), "zero"),
              tl: {
                hd: Css.margin2("zero", Css.px(12)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var profileContainerStyle = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.cursor("pointer"),
              tl: {
                hd: Css.margin(Css.px(0)),
                tl: {
                  hd: Css.padding(Css.px(0)),
                  tl: {
                    hd: Css.backgroundColor(Css.transparent),
                    tl: {
                      hd: Css.borderWidth(Css.px(0)),
                      tl: {
                        hd: Css.cursor("pointer"),
                        tl: {
                          hd: Css.borderRadius(Css.px(100)),
                          tl: {
                            hd: Css.active({
                                  hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, undefined, undefined, "transparent")),
                                  tl: /* [] */0
                                }),
                            tl: {
                              hd: Css.focus({
                                    hd: Css.outlineStyle("none"),
                                    tl: {
                                      hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.darkBlue)),
                                      tl: /* [] */0
                                    }
                                  }),
                              tl: {
                                hd: Css.unsafe("appearance", "none"),
                                tl: {
                                  hd: Css.transition({
                                        NAME: "ms",
                                        VAL: Styles.Duration.$$default
                                      }, undefined, undefined, "box-shadow"),
                                  tl: {
                                    hd: Css.hover({
                                          hd: Css.boxShadow(Styles.Shadow.glow(Styles.Color.light10)),
                                          tl: /* [] */0
                                        }),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

function Sidebar$SidebarHeader(Props) {
  var role = Props.role;
  var user = ViewerContext.use(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var hideTrialBanner = AppFeatureFlag.useFeatureFlag("HideTrialBanner");
  var match = RouterStore.Schema.useSlices(function (param) {
        return [
                param.baseRoute,
                param.schemaRoute,
                param.drawerItems
              ];
      });
  var drawerItems = match[2];
  var schemaRoute = match[1];
  var baseRoute = match[0];
  return React.createElement(React.Fragment, undefined, React.createElement("header", {
                  className: headerStyle
                }, React.createElement(Link.make, {
                      path: Router.Link.getSchemaRouteLink(undefined, "dashboard"),
                      className: Curry._1(Css.style, {
                            hd: Css.display("inlineFlex"),
                            tl: /* [] */0
                          }),
                      onClick: (function (_event) {
                          return AnalyticsRe.logoClicked(schemaBundle, Router.baseRouteLocationToAnalyticsLocation(baseRoute, schemaRoute, drawerItems));
                        }),
                      id: "sidebar-avo-logo",
                      children: React.createElement(Icons.AvoLogo.make, {
                            width: 60,
                            fill: Styles.Color.white
                          })
                    }), React.createElement("button", {
                      className: profileContainerStyle,
                      id: "sidebar-tour-profile",
                      onClick: (function (param) {
                          return Curry._1(globalSend, {
                                      TAG: /* OpenModal */4,
                                      _0: {
                                        NAME: "Profile",
                                        VAL: user.id
                                      }
                                    });
                        })
                    }, React.createElement(ProfilePhoto.make, {
                          withBorder: false,
                          user: user,
                          size: 32
                        }))), hideTrialBanner ? null : React.createElement(Sidebar$SidebarTrialBanner, {
                    role: role
                  }));
}

var SidebarHeader = {
  headerStyle: headerStyle,
  profileContainerStyle: profileContainerStyle,
  make: Sidebar$SidebarHeader
};

var footerContainerStyle = Curry._1(Css.style, {
      hd: Css.media("(max-height: 660px)", {
            hd: Css.marginTop(Css.px(12)),
            tl: {
              hd: Css.marginBottom(Css.px(12)),
              tl: /* [] */0
            }
          }),
      tl: {
        hd: Css.width(Css.pct(100)),
        tl: {
          hd: Css.marginTop(Css.px(22)),
          tl: {
            hd: Css.marginBottom(Css.px(22)),
            tl: {
              hd: Css.flexGrow(1.0),
              tl: {
                hd: Css.flexDirection("column"),
                tl: {
                  hd: Css.alignItems("stretch"),
                  tl: {
                    hd: Css.display("flex"),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var footerItemStyle = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.button),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.media("(max-height: 660px)", {
                    hd: Css.fontSize(Styles.FontSize.small),
                    tl: /* [] */0
                  }),
              tl: {
                hd: Css.display("block"),
                tl: {
                  hd: Css.margin(Css.px(0)),
                  tl: {
                    hd: Css.padding2(Css.px(4), Css.px(0)),
                    tl: {
                      hd: Css.color(Styles.Color.light08),
                      tl: {
                        hd: Css.fontWeight(Styles.FontWeight.medium),
                        tl: {
                          hd: Css.fontSize(Styles.FontSize.regular),
                          tl: {
                            hd: Css.borderRadius(Css.px(2)),
                            tl: {
                              hd: Css.active({
                                    hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, undefined, undefined, "transparent")),
                                    tl: /* [] */0
                                  }),
                              tl: {
                                hd: Css.focus({
                                      hd: Css.outlineStyle("none"),
                                      tl: {
                                        hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.darkBlue)),
                                        tl: /* [] */0
                                      }
                                    }),
                                tl: {
                                  hd: Css.hover({
                                        hd: Css.color(Styles.Color.light04),
                                        tl: /* [] */0
                                      }),
                                  tl: {
                                    hd: Css.transition({
                                          NAME: "ms",
                                          VAL: Styles.Duration.$$short
                                        }, undefined, undefined, "color"),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

Curry._2(Css.$$global, " .Canny_BadgeContainer .Canny_Badge", {
      hd: Css.top(Css.px(-1)),
      tl: {
        hd: Css.right(Css.px(-9)),
        tl: {
          hd: Css.backgroundColor(Styles.Color.avoPink),
          tl: {
            hd: Css.borderColor(Styles.Color.light12),
            tl: /* [] */0
          }
        }
      }
    });

function Sidebar$SidebarFooter(Props) {
  var schema = Props.schema;
  var role = Props.role;
  var globalSend = GlobalSendContext.use(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var hasGettingStarted = GettingStarted.useHasGettingStarted(undefined);
  var isGlobalWorkspace = OrganizationContext.useIsGlobalWorkspace(undefined);
  var activeRoute = RouterStore.Schema.useSchemaRoute(undefined);
  var match = TrialRequest.useTrialRequest(undefined);
  var gettingStartedIsActiveRoute = activeRoute === "gettingStarted";
  React.useEffect((function () {
          Canny.initChangelog({
                appID: Canny.appID,
                position: "right",
                align: "bottom"
              });
          
        }), []);
  var match$1 = workspace.plan.name;
  var tmp;
  var exit = 0;
  switch (match$1) {
    case /* Empty */0 :
    case /* Free */1 :
    case /* Scholarship */3 :
    case /* Growth */4 :
    case /* Enterprise */6 :
    case /* TeamV2 */8 :
    case /* TeamV2Annual */9 :
        tmp = null;
        break;
    case /* FreeV2 */2 :
    case /* GrowthV2 */5 :
    case /* Starter */7 :
    case /* FreeV3 */10 :
    case /* TeamV3 */11 :
    case /* TeamV3Annual */12 :
    case /* EnterpriseV2 */13 :
        exit = 1;
        break;
    
  }
  if (exit === 1) {
    tmp = !hasGettingStarted && !isGlobalWorkspace ? React.createElement(OnboardingAndTrialProgressInSidebar.make, {
            schema: schema,
            globalSend: globalSend,
            viewerIsAdmin: role === "Admin"
          }) : null;
  }
  return React.createElement("footer", {
              className: footerContainerStyle
            }, React.createElement(GettingStarted__SidebarProgress.make, {}), React.createElement(Spacer.make, {
                  height: 8,
                  grow: 1.0
                }), React.createElement(SetupIssuesAlertsFlow.make, {}), tmp, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding(Css.px(8)),
                        tl: /* [] */0
                      })
                }, React.createElement("button", {
                      className: footerItemStyle,
                      id: "product-tour-invite-people",
                      onClick: (function (param) {
                          return SidebarSettingsCog.openMembersModal(schemaBundle, globalSend);
                        })
                    }, role === "Admin" ? "Invite people" : "Workspace members"), role === "Admin" && match[0] === /* Available */2 ? React.createElement("button", {
                        className: footerItemStyle,
                        id: "sidebar-start-team-trial",
                        onClick: (function (param) {
                            return Curry._1(globalSend, {
                                        TAG: /* OpenModal */4,
                                        _0: {
                                          NAME: "BillingPrompt",
                                          VAL: "TryForFree"
                                        }
                                      });
                          })
                      }, "Start Team Trial") : null, React.cloneElement(React.createElement("button", {
                          className: footerItemStyle
                        }, "What's new?"), {
                      "data-canny-changelog": ""
                    }), hasGettingStarted ? React.createElement("button", {
                        className: Curry._1(Css.merge, {
                              hd: footerItemStyle,
                              tl: {
                                hd: gettingStartedIsActiveRoute ? Curry._1(Css.style, {
                                        hd: Css.color(Styles.Color.avoPink),
                                        tl: /* [] */0
                                      }) : "",
                                tl: /* [] */0
                              }
                            }),
                        onClick: (function (param) {
                            return Router.Schema.pushSchemaRoute(undefined, undefined, "gettingStarted");
                          })
                      }, "Getting started") : null));
}

var SidebarFooter = {
  footerContainerStyle: footerContainerStyle,
  footerItemStyle: footerItemStyle,
  make: Sidebar$SidebarFooter
};

var searchContainerStyle = Curry._1(Css.style, {
      hd: Css.marginTop(Css.px(0)),
      tl: {
        hd: Css.marginRight(Css.px(0)),
        tl: {
          hd: Css.marginBottom(Css.px(12)),
          tl: {
            hd: Css.marginLeft(Css.px(0)),
            tl: {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.justifyContent("spaceBetween"),
                  tl: {
                    hd: Css.borderRadius(Css.px(16)),
                    tl: {
                      hd: Css.padding2(Css.px(8), Css.px(8)),
                      tl: {
                        hd: Css.backgroundColor(Styles.Color.light12),
                        tl: {
                          hd: Css.color(Styles.Color.light08),
                          tl: {
                            hd: Css.fontSize(Styles.FontSize.regular),
                            tl: {
                              hd: Css.fontWeight(Styles.FontWeight.medium),
                              tl: {
                                hd: Css.cursor("text"),
                                tl: {
                                  hd: Css.backgroundColor(Css.transparent),
                                  tl: {
                                    hd: Css.border(Css.px(1), "solid", Styles.Color.dark07),
                                    tl: {
                                      hd: Css.cursor("pointer"),
                                      tl: {
                                        hd: Css.borderRadius(Css.px(100)),
                                        tl: {
                                          hd: Css.active({
                                                hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, undefined, undefined, "transparent")),
                                                tl: /* [] */0
                                              }),
                                          tl: {
                                            hd: Css.focus({
                                                  hd: Css.outlineStyle("none"),
                                                  tl: {
                                                    hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.darkBlue)),
                                                    tl: /* [] */0
                                                  }
                                                }),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var searchContentStyle = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: /* [] */0
      }
    });

var searchTextStyle = Curry._1(Css.style, {
      hd: Css.marginLeft(Css.px(8)),
      tl: /* [] */0
    });

var searchShortcutStyle = Curry._1(Css.style, {
      hd: Css.color(Styles.Color.light07),
      tl: {
        hd: Css.marginRight(Css.px(2)),
        tl: /* [] */0
      }
    });

function Sidebar$SidebarSearch(Props) {
  var match = Kbar.useKBar();
  var query = match.query;
  return React.createElement("button", {
              className: searchContainerStyle,
              id: "sidebar-tour-search",
              onClick: (function (param) {
                  return Curry._1(query.toggle, undefined);
                })
            }, React.createElement("div", {
                  className: searchContentStyle
                }, React.createElement(IconSearch.make, {
                      size: 16,
                      color: Styles.Color.light08
                    }), React.createElement("span", {
                      className: searchTextStyle
                    }, "Search")), React.createElement("span", {
                  className: searchShortcutStyle
                }, "cmd+k"));
}

var SidebarSearch = {
  searchContainerStyle: searchContainerStyle,
  searchContentStyle: searchContentStyle,
  searchTextStyle: searchTextStyle,
  searchShortcutStyle: searchShortcutStyle,
  make: Sidebar$SidebarSearch
};

var containerStyles = Curry._1(Css.style, {
      hd: Css.marginBottom(Css.px(12)),
      tl: {
        hd: Css.border(Css.px(1), "solid", Styles.Color.dark07),
        tl: {
          hd: Css.borderRadius(Css.px(10)),
          tl: {
            hd: Css.backgroundColor(Styles.Color.light12),
            tl: /* [] */0
          }
        }
      }
    });

var topStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.padding(Css.px(8)),
        tl: {
          hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.dark07),
          tl: {
            hd: Css.unsafe("wordBreak", "break-word"),
            tl: /* [] */0
          }
        }
      }
    });

function Sidebar$SidebarWorkspace(Props) {
  var currentBranch = Props.currentBranch;
  var model = Props.model;
  var openBranches = Props.openBranches;
  var role = Props.role;
  var branchStatus = Props.branchStatus;
  var globalSend = GlobalSendContext.use(undefined);
  var workspace = WorkspaceContext.use(undefined);
  return React.createElement("div", {
              className: containerStyles
            }, React.createElement("div", {
                  className: topStyles
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      lines: 3,
                      color: Styles.Color.white,
                      title: workspace.name,
                      children: workspace.name
                    }), React.createElement(Spacer.make, {
                      width: 8,
                      grow: 1.0
                    }), React.createElement(SidebarSettingsCog.make, {
                      globalSend: globalSend,
                      model: model,
                      role: role
                    })), React.createElement(SidebarBranchControl.make, {
                  currentBranch: currentBranch,
                  openBranches: openBranches,
                  globalSend: globalSend,
                  role: role,
                  branchStatus: branchStatus
                }));
}

var SidebarWorkspace = {
  containerStyles: containerStyles,
  topStyles: topStyles,
  make: Sidebar$SidebarWorkspace
};

function Sidebar(Props) {
  var currentBranch = Props.currentBranch;
  var hidden = Props.hidden;
  var openBranches = Props.openBranches;
  var role = Props.role;
  var schema = Props.schema;
  var model = Props.model;
  var branchStatus = Props.branchStatus;
  return React.createElement(Sidebar$SidebarContainer, {
              hidden: hidden,
              children: null
            }, React.createElement(Sidebar$SidebarHeader, {
                  role: role
                }), React.createElement(Sidebar$SidebarWorkspace, {
                  currentBranch: currentBranch,
                  model: model,
                  openBranches: openBranches,
                  role: role,
                  branchStatus: branchStatus
                }), React.createElement(Sidebar$SidebarSearch, {}), React.createElement(SidebarNavigation.make, {}), React.createElement(Sidebar$SidebarFooter, {
                  schema: schema,
                  role: role
                }), React.createElement(AvoAdmin.make, {}), React.createElement(ProductTours.make, {}));
}

var sidebarWidth = 250;

var make = Sidebar;

export {
  sidebarWidth ,
  SidebarTrialBanner ,
  SidebarContainer ,
  SidebarHeader ,
  SidebarFooter ,
  SidebarSearch ,
  SidebarWorkspace ,
  make ,
  
}
/* bannerStyle Not a pure module */
