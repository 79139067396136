// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Uuid from "uuid";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Icons from "./Icons.mjs";
import * as React from "react";
import * as Portal from "./Portal.mjs";
import * as Styles from "./styles.mjs";
import * as Mantine from "./Mantine.mjs";
import * as Zustand from "./Zustand.mjs";
import * as Zustand$1 from "zustand";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as TextButton from "./TextButton.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IconWarning from "./IconWarning.mjs";
import * as FramerMotion from "framer-motion";
import * as IconCheckmarkCircle from "./IconCheckmarkCircle.mjs";

var StoreConfig = {};

var ZustandStore = Zustand.MakeStore(StoreConfig);

var store = Zustand$1.create(function (set, _get) {
      return {
              toasts: [],
              addToast: (function (toast) {
                  var id = Uuid.v4();
                  set(function (state) {
                        var newToasts = Belt_Array.concat(state.toasts, [{
                                id: id,
                                toastType: Belt_Option.getWithDefault(Caml_option.undefined_to_opt(toast.toastType), /* Info */2),
                                message: toast.message,
                                action: Caml_option.undefined_to_opt(toast.action),
                                persist: Belt_Option.getWithDefault(Caml_option.undefined_to_opt(toast.persist), false),
                                destroy: (function (param) {
                                    return Curry._1(state.destroyToast, id);
                                  })
                              }]);
                        return {
                                toasts: newToasts,
                                addToast: state.addToast,
                                destroyToast: state.destroyToast
                              };
                      });
                  return id;
                }),
              destroyToast: (function (idOfToastToDestroy) {
                  return set(function (state) {
                              return {
                                      toasts: Belt_Array.keep(state.toasts, (function (param) {
                                              return param.id !== idOfToastToDestroy;
                                            })),
                                      addToast: state.addToast,
                                      destroyToast: state.destroyToast
                                    };
                            });
                })
            };
    });

function useAddToastWithId(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.addToast;
              }));
}

function useAddToast(param) {
  var useAddToastWithId = Curry._2(ZustandStore.use, store, (function (state) {
          return state.addToast;
        }));
  return function (toast) {
    Curry._1(useAddToastWithId, toast);
    
  };
}

function useAddToastWithDestroy(param) {
  var addToastWithId = Curry._2(ZustandStore.use, store, (function (state) {
          return state.addToast;
        }));
  var destroy = Curry._2(ZustandStore.use, store, (function (state) {
          return state.destroyToast;
        }));
  return function (toast) {
    var id = Curry._1(addToastWithId, toast);
    return function (param) {
      return Curry._1(destroy, id);
    };
  };
}

var container = Curry._1(Css.style, {
      hd: Css.borderRadius(Css.px(5)),
      tl: {
        hd: Css.backgroundColor(Styles.Color.light12),
        tl: {
          hd: Css.minHeight(Css.px(48)),
          tl: {
            hd: Css.display("flex"),
            tl: {
              hd: Css.alignItems("center"),
              tl: {
                hd: Css.marginTop(Css.px(16)),
                tl: {
                  hd: Css.paddingLeft(Css.px(16)),
                  tl: {
                    hd: Css.maxWidth(Css.px(395)),
                    tl: {
                      hd: Css.width("maxContent"),
                      tl: {
                        hd: Css.overflow("hidden"),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var iconContainer = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.height(Css.pct(100.0)),
          tl: /* [] */0
        }
      }
    });

var messageContainer = Curry._1(Css.style, {
      hd: Css.padding(Css.px(12)),
      tl: {
        hd: Css.wordBreak("breakAll"),
        tl: /* [] */0
      }
    });

var line = Curry._1(Css.style, {
      hd: Css.marginLeft(Css.px(6)),
      tl: {
        hd: Css.height(Css.pct(100.0)),
        tl: {
          hd: Css.width(Css.px(1)),
          tl: {
            hd: Css.backgroundColor(Styles.Color.light11),
            tl: /* [] */0
          }
        }
      }
    });

var buttonContainer = Curry._1(Css.style, {
      hd: Css.hover({
            hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.white, 0.1)),
            tl: /* [] */0
          }),
      tl: {
        hd: Css.transition({
              NAME: "ms",
              VAL: Styles.Duration.$$short
            }, undefined, undefined, "background-color"),
        tl: {
          hd: Css.cursor("pointer"),
          tl: {
            hd: Css.selector(" button", {
                  hd: Css.padding(Css.px(16)),
                  tl: {
                    hd: Css.paddingBottom(Css.px(15)),
                    tl: /* [] */0
                  }
                }),
            tl: /* [] */0
          }
        }
      }
    });

var Style = {
  container: container,
  iconContainer: iconContainer,
  messageContainer: messageContainer,
  line: line,
  buttonContainer: buttonContainer
};

function Toast$Toast(Props) {
  var id = Props.id;
  var toastType = Props.toastType;
  var message = Props.message;
  var action = Props.action;
  var destroy = Props.destroy;
  var persist = Props.persist;
  var destroyTimeoutId = React.useRef(undefined);
  var match = React.useState(function () {
        return false;
      });
  var setMounted = match[1];
  var mounted = match[0];
  React.useEffect((function () {
          Curry._1(setMounted, (function (param) {
                  return true;
                }));
          destroyTimeoutId.current = persist ? undefined : Caml_option.some(setTimeout(destroy, 5000));
          return (function (param) {
                    return Belt_Option.forEach(destroyTimeoutId.current, (function (prim) {
                                  clearTimeout(prim);
                                  
                                }));
                  });
        }), []);
  var color = toastType !== 1 ? (
      toastType !== 0 ? Styles.Color.blue : Styles.Color.green
    ) : Styles.Color.orange;
  var actionText = Belt_Option.mapWithDefault(action, "OK", (function (param) {
          return param.message;
        }));
  var onClick = Belt_Option.mapWithDefault(action, (function (param) {
          return Curry._1(destroy, undefined);
        }), (function (param, param$1) {
          Curry._1(destroy, undefined);
          return Curry._1(param.toastCallback, undefined);
        }));
  var tmp;
  switch (toastType) {
    case /* Success */0 :
        tmp = React.createElement(IconCheckmarkCircle.make, {
              size: 17,
              color: color
            });
        break;
    case /* Error */1 :
        tmp = React.createElement(IconWarning.make, {
              color: color,
              size: 17
            });
        break;
    case /* Info */2 :
        tmp = React.createElement(Icons.Information.make, {
              size: 21
            });
        break;
    case /* Loader */3 :
        tmp = React.createElement(Mantine.Loader.make, {
              variant: "oval",
              size: 21,
              color: Styles.Color.darkBlue
            });
        break;
    
  }
  return React.createElement(FramerMotion.motion.div, {
              animate: {
                height: "auto"
              },
              transition: {
                duration: 0.15,
                delay: mounted ? 0.2 : 0.0
              },
              initial: {
                height: "0px"
              },
              exit: {
                height: "0px"
              },
              children: React.createElement(FramerMotion.motion.div, {
                    animate: {
                      opacity: 1,
                      y: 0,
                      x: 0
                    },
                    transition: {
                      delay: mounted ? 0.0 : 0.1,
                      type: "spring",
                      damping: 15,
                      stiffness: 200
                    },
                    initial: {
                      opacity: 0,
                      y: 15,
                      x: 0
                    },
                    exit: {
                      opacity: 0,
                      y: 0,
                      x: 54
                    },
                    className: container,
                    key: id,
                    children: null,
                    onMouseOver: (function (param) {
                        return Belt_Option.forEach(destroyTimeoutId.current, (function (prim) {
                                      clearTimeout(prim);
                                      
                                    }));
                      }),
                    onMouseLeave: (function (param) {
                        destroyTimeoutId.current = persist ? undefined : Caml_option.some(setTimeout((function (param) {
                                      return Curry._1(destroy, undefined);
                                    }), 2500));
                        
                      })
                  }, React.createElement("div", {
                        className: iconContainer
                      }, tmp), React.createElement("div", {
                        className: messageContainer
                      }, React.createElement($$Text.make, {
                            size: "Medium",
                            color: Styles.Color.white,
                            children: message
                          })), React.createElement("div", {
                        className: line
                      }), React.createElement("div", {
                        className: buttonContainer
                      }, React.createElement(TextButton.make, {
                            onClick: onClick,
                            children: React.createElement($$Text.make, {
                                  size: "Medium",
                                  weight: "Semi",
                                  color: color,
                                  children: actionText
                                })
                          })))
            });
}

var Toast = {
  destroyTime: 5000,
  Style: Style,
  make: Toast$Toast
};

var container$1 = Curry._1(Css.style, {
      hd: Css.position("fixed"),
      tl: {
        hd: Css.right(Css.px(100)),
        tl: {
          hd: Css.bottom(Css.px(30)),
          tl: {
            hd: Css.display("flex"),
            tl: {
              hd: Css.flexDirection("column"),
              tl: {
                hd: Css.alignItems("flexEnd"),
                tl: {
                  hd: Css.zIndex(Styles.ZIndex.aboveAll + 1 | 0),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var Style$1 = {
  container: container$1
};

function Toast$Manager(Props) {
  var toasts = Curry._2(ZustandStore.use, store, (function (state) {
          return state.toasts;
        }));
  return React.createElement(Portal.make, {
              children: React.createElement("div", {
                    className: container$1
                  }, React.createElement(FramerMotion.AnimatePresence, {
                        children: Belt_Array.map(toasts, (function (param) {
                                var id = param.id;
                                return React.createElement(Toast$Toast, {
                                            id: id,
                                            toastType: param.toastType,
                                            message: param.message,
                                            action: param.action,
                                            destroy: param.destroy,
                                            persist: param.persist,
                                            key: id
                                          });
                              }))
                      }))
            });
}

var Manager = {
  Style: Style$1,
  make: Toast$Manager
};

export {
  StoreConfig ,
  ZustandStore ,
  store ,
  useAddToastWithId ,
  useAddToast ,
  useAddToastWithDestroy ,
  Toast ,
  Manager ,
  
}
/* ZustandStore Not a pure module */
