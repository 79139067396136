// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Case from "../../bs-case/src/case.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Parser from "../../model/src/Parser.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Printer from "../../model/src/Printer.mjs";
import * as AvoModel from "./avoModel.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as NameUtils from "../../shared/utils/NameUtils.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as DiscrepancyTypes from "./DiscrepancyTypes.mjs";
import * as BeltListExtensions from "./BeltListExtensions.mjs";
import * as EventNameComponent from "../../shared/models/EventNameComponent.mjs";
import * as ValidationConfigV2 from "./guardrails/ValidationConfigV2.mjs";
import * as VariantEventIdUseCase from "../../model/src/variants/variantEventIdUseCase.mjs";
import * as GetEventNamespaceUseCase from "./getEventNamespaceUseCase.mjs";

var _map = {"PropertyConflictOnEvent":"PropertyConflictOnEvent","SameEventName":"SameEventName","SamePropertyName":"SamePropertyName","EventsWithUnexpectedCase":"EventsWithUnexpectedCase","EventsWithoutExpectedValues":"EventsWithoutExpectedValues","PropertiesWithUnexpectedCase":"PropertiesWithUnexpectedCase","PropertiesWithAnyType":"PropertiesWithAnyType","EventsWithoutDescription":"EventsWithoutDescription","PropertiesWithoutDescription":"PropertiesWithoutDescription"};

function keyToJs(param) {
  return param;
}

function keyFromJs(param) {
  return _map[param];
}

function enabled_(enabled) {
  return [
          "enabled",
          enabled
        ];
}

function enforced_(enforced) {
  return [
          "enforced",
          enforced
        ];
}

function events_(events) {
  return [
          "events",
          Json_encode.list(Printer.printEvent, events)
        ];
}

function properties_(properties) {
  return [
          "properties",
          Json_encode.list(Printer.printProperty, Belt_List.mapU(properties, (function (property) {
                      return {
                              TAG: /* Property */1,
                              _0: property
                            };
                    })))
        ];
}

function eventsDiscrepancy(enabled, enforced, events) {
  return Json_encode.object_({
              hd: [
                "enabled",
                enabled
              ],
              tl: {
                hd: [
                  "enforced",
                  enforced
                ],
                tl: {
                  hd: events_(events),
                  tl: /* [] */0
                }
              }
            });
}

function propertiesDiscrepancy(enabled, enforced, properties) {
  return Json_encode.object_({
              hd: [
                "enabled",
                enabled
              ],
              tl: {
                hd: [
                  "enforced",
                  enforced
                ],
                tl: {
                  hd: properties_(properties),
                  tl: /* [] */0
                }
              }
            });
}

function discrepancyKey(key) {
  return [
          "key",
          key
        ];
}

function unexpectedValuesDiscrepancy(enabled, enforced, unexpectedValues) {
  return Json_encode.object_({
              hd: [
                "enabled",
                enabled
              ],
              tl: {
                hd: [
                  "enforced",
                  enforced
                ],
                tl: {
                  hd: [
                    "unexpectedValues",
                    Json_encode.list(DiscrepancyTypes.UnexpectedValues.encodeByEvent, unexpectedValues)
                  ],
                  tl: /* [] */0
                }
              }
            });
}

function discrepancy(discrepancy$1) {
  var variant = discrepancy$1.NAME;
  if (variant === "SameEventName") {
    var match = discrepancy$1.VAL;
    return Json_encode.object_({
                hd: [
                  "key",
                  "SameEventName"
                ],
                tl: {
                  hd: [
                    "value",
                    eventsDiscrepancy(match[0], match[1], match[2])
                  ],
                  tl: /* [] */0
                }
              });
  }
  if (variant === "SamePropertyName") {
    var match$1 = discrepancy$1.VAL;
    return Json_encode.object_({
                hd: [
                  "key",
                  "SamePropertyName"
                ],
                tl: {
                  hd: [
                    "value",
                    propertiesDiscrepancy(match$1[0], match$1[1], match$1[2])
                  ],
                  tl: /* [] */0
                }
              });
  }
  if (variant === "PropertiesWithAnyType") {
    var match$2 = discrepancy$1.VAL;
    return Json_encode.object_({
                hd: [
                  "key",
                  "PropertiesWithAnyType"
                ],
                tl: {
                  hd: [
                    "value",
                    propertiesDiscrepancy(match$2[0], match$2[1], match$2[2])
                  ],
                  tl: /* [] */0
                }
              });
  }
  if (variant === "EventsWithoutDescription") {
    var match$3 = discrepancy$1.VAL;
    return Json_encode.object_({
                hd: [
                  "key",
                  "EventsWithoutDescription"
                ],
                tl: {
                  hd: [
                    "value",
                    eventsDiscrepancy(match$3[0], match$3[1], match$3[2])
                  ],
                  tl: /* [] */0
                }
              });
  }
  if (variant === "EventsWithoutExpectedValues") {
    var match$4 = discrepancy$1.VAL;
    return Json_encode.object_({
                hd: [
                  "key",
                  "EventsWithoutExpectedValues"
                ],
                tl: {
                  hd: [
                    "value",
                    unexpectedValuesDiscrepancy(match$4[0], match$4[1], match$4[2])
                  ],
                  tl: /* [] */0
                }
              });
  }
  if (variant === "EventsWithUnexpectedCase") {
    var match$5 = discrepancy$1.VAL;
    return Json_encode.object_({
                hd: [
                  "key",
                  "EventsWithUnexpectedCase"
                ],
                tl: {
                  hd: [
                    "value",
                    eventsDiscrepancy(match$5[0], match$5[1], match$5[2])
                  ],
                  tl: /* [] */0
                }
              });
  }
  if (variant === "PropertyConflictOnEvent") {
    var match$6 = discrepancy$1.VAL;
    return Json_encode.object_({
                hd: [
                  "key",
                  "PropertyConflictOnEvent"
                ],
                tl: {
                  hd: [
                    "value",
                    eventsDiscrepancy(match$6[0], match$6[1], match$6[2])
                  ],
                  tl: /* [] */0
                }
              });
  }
  if (variant === "PropertiesWithoutDescription") {
    var match$7 = discrepancy$1.VAL;
    return Json_encode.object_({
                hd: [
                  "key",
                  "PropertiesWithoutDescription"
                ],
                tl: {
                  hd: [
                    "value",
                    propertiesDiscrepancy(match$7[0], match$7[1], match$7[2])
                  ],
                  tl: /* [] */0
                }
              });
  }
  var match$8 = discrepancy$1.VAL;
  return Json_encode.object_({
              hd: [
                "key",
                "PropertiesWithUnexpectedCase"
              ],
              tl: {
                hd: [
                  "value",
                  propertiesDiscrepancy(match$8[0], match$8[1], match$8[2])
                ],
                tl: /* [] */0
              }
            });
}

function discrepancies(discrepancies$1) {
  return Json_encode.array(discrepancy, discrepancies$1);
}

var Encode = {
  enabled_: enabled_,
  enforced_: enforced_,
  events_: events_,
  properties_: properties_,
  eventsDiscrepancy: eventsDiscrepancy,
  propertiesDiscrepancy: propertiesDiscrepancy,
  discrepancyKey: discrepancyKey,
  unexpectedValuesDiscrepancy: unexpectedValuesDiscrepancy,
  discrepancy: discrepancy,
  discrepancies: discrepancies
};

function events_$1(json) {
  return Json_decode.list(Parser.parseEvent, json);
}

function properties_$1(json) {
  return Belt_List.keepMapU(Json_decode.list(Parser.parseProperty, json), (function (property) {
                if (property.TAG === /* PropertyRef */0) {
                  return ;
                } else {
                  return property._0;
                }
              }));
}

function eventsDiscrepancy$1(json) {
  return [
          Json_decode.field("enabled", Json_decode.bool, json),
          Json_decode.field("enforced", Json_decode.bool, json),
          Json_decode.field("events", events_$1, json)
        ];
}

function propertiesDiscrepancy$1(json) {
  return [
          Json_decode.field("enabled", Json_decode.bool, json),
          Json_decode.field("enforced", Json_decode.bool, json),
          Json_decode.field("properties", properties_$1, json)
        ];
}

function unexpectedValuesDiscrepancy$1(json) {
  return [
          Json_decode.field("enabled", Json_decode.bool, json),
          Json_decode.field("enforced", Json_decode.bool, json),
          Json_decode.field("unexpectedValues", (function (param) {
                  return Json_decode.list(DiscrepancyTypes.UnexpectedValues.decodeByEvent, param);
                }), json)
        ];
}

function discrepancy$1(json) {
  var match = _map[Json_decode.field("key", Json_decode.string, json)];
  if (match !== undefined) {
    if (match === "SameEventName") {
      return {
              NAME: "SameEventName",
              VAL: Json_decode.field("value", eventsDiscrepancy$1, json)
            };
    } else if (match === "SamePropertyName") {
      return {
              NAME: "SamePropertyName",
              VAL: Json_decode.field("value", propertiesDiscrepancy$1, json)
            };
    } else if (match === "PropertiesWithAnyType") {
      return {
              NAME: "PropertiesWithAnyType",
              VAL: Json_decode.field("value", propertiesDiscrepancy$1, json)
            };
    } else if (match === "EventsWithoutDescription") {
      return {
              NAME: "EventsWithoutDescription",
              VAL: Json_decode.field("value", eventsDiscrepancy$1, json)
            };
    } else if (match === "EventsWithoutExpectedValues") {
      return {
              NAME: "EventsWithoutExpectedValues",
              VAL: Json_decode.field("value", unexpectedValuesDiscrepancy$1, json)
            };
    } else if (match === "EventsWithUnexpectedCase") {
      return {
              NAME: "EventsWithUnexpectedCase",
              VAL: Json_decode.field("value", eventsDiscrepancy$1, json)
            };
    } else if (match === "PropertyConflictOnEvent") {
      return {
              NAME: "PropertyConflictOnEvent",
              VAL: Json_decode.field("value", eventsDiscrepancy$1, json)
            };
    } else if (match === "PropertiesWithoutDescription") {
      return {
              NAME: "PropertiesWithoutDescription",
              VAL: Json_decode.field("value", propertiesDiscrepancy$1, json)
            };
    } else {
      return {
              NAME: "PropertiesWithUnexpectedCase",
              VAL: Json_decode.field("value", propertiesDiscrepancy$1, json)
            };
    }
  }
  
}

function discrepancies$1(json) {
  return Belt_Array.keepMapU(Json_decode.array(discrepancy$1, json), (function (x) {
                return x;
              }));
}

var Decode = {
  events_: events_$1,
  properties_: properties_$1,
  eventsDiscrepancy: eventsDiscrepancy$1,
  propertiesDiscrepancy: propertiesDiscrepancy$1,
  unexpectedValuesDiscrepancy: unexpectedValuesDiscrepancy$1,
  discrepancy: discrepancy$1,
  discrepancies: discrepancies$1
};

var Json = {
  keyToJs: keyToJs,
  keyFromJs: keyFromJs,
  Encode: Encode,
  Decode: Decode
};

function getUnexpectedValues(eventComponentsWithNames, nameComponents, text) {
  var eventComponents = Belt_MapString.get(eventComponentsWithNames, text);
  var eventComponents$1 = eventComponents !== undefined ? eventComponents : EventNameComponent.getEventNameComponentFromName(text, nameComponents);
  return Belt_Array.reduce(nameComponents, {
              blocked: [],
              predefined: []
            }, (function (acc, nameComponent) {
                if (nameComponent.NAME === "separator") {
                  return acc;
                }
                var match = nameComponent.VAL;
                var name = match.name;
                var allowedValues = Curry._1(ValidationConfigV2.Value.getAllowedValues, match.allowedValues.value);
                var blockedValues = Belt_SetString.fromArray(Curry._1(ValidationConfigV2.Value.getBlockedValues, match.blockedValues.value));
                var match$1 = Belt_Option.flatMap(eventComponents$1, (function (eventComponents) {
                        return Belt_Array.getBy(eventComponents, (function (eventComponent) {
                                      return eventComponent.componentName === name;
                                    }));
                      }));
                var usingBlockedValue = match$1 !== undefined ? Belt_SetString.has(blockedValues, match$1.componentValue) : false;
                var missingAllowedValues = allowedValues.length !== 0 && Belt_Array.every(allowedValues, (function (allowedValue) {
                        return !text.includes(allowedValue);
                      }));
                if (missingAllowedValues) {
                  if (usingBlockedValue) {
                    return {
                            blocked: Belt_Array.concat(acc.blocked, [name]),
                            predefined: Belt_Array.concat(acc.predefined, [name])
                          };
                  } else {
                    return {
                            blocked: acc.blocked,
                            predefined: Belt_Array.concat(acc.predefined, [name])
                          };
                  }
                } else if (usingBlockedValue) {
                  return {
                          blocked: Belt_Array.concat(acc.blocked, [name]),
                          predefined: acc.predefined
                        };
                } else {
                  return acc;
                }
              }));
}

function getWarningsCount(hasSimpleNameCasing, hasValueChecking, discrepancy) {
  var variant = discrepancy.NAME;
  if (variant === "EventsWithoutExpectedValues") {
    var match = discrepancy.VAL;
    if (match[0] && hasValueChecking) {
      return Belt_List.length(match[2]);
    } else {
      return 0;
    }
  }
  if (variant === "EventsWithUnexpectedCase") {
    var match$1 = discrepancy.VAL;
    if (match$1[0] && hasSimpleNameCasing) {
      return Belt_List.length(match$1[2]);
    } else {
      return 0;
    }
  }
  var match$2 = discrepancy.VAL;
  if (match$2[0]) {
    return Belt_List.length(match$2[2]);
  } else {
    return 0;
  }
}

function getErrorCount(hasSimpleNameCasing, hasValueChecking, discrepancy) {
  var variant = discrepancy.NAME;
  if (variant === "EventsWithoutExpectedValues") {
    var match = discrepancy.VAL;
    if (match[1] && hasValueChecking) {
      return Belt_List.length(match[2]);
    } else {
      return 0;
    }
  }
  if (variant === "EventsWithUnexpectedCase") {
    var match$1 = discrepancy.VAL;
    if (match$1[1] && hasSimpleNameCasing) {
      return Belt_List.length(match$1[2]);
    } else {
      return 0;
    }
  }
  var match$2 = discrepancy.VAL;
  if (match$2[1]) {
    return Belt_List.length(match$2[2]);
  } else {
    return 0;
  }
}

function getTotalWarningsCount(hasSimpleNameCasing, hasValueChecking, discrepancies) {
  return Belt_Array.reduce(Belt_Array.map(discrepancies, (function (param) {
                    return getWarningsCount(hasSimpleNameCasing, hasValueChecking, param);
                  })), 0, (function (sum, count) {
                return sum + count | 0;
              }));
}

function getTotalErrorsCount(hasSimpleNameCasing, hasValueChecking, discrepancies) {
  return Belt_Array.reduce(Belt_Array.map(discrepancies, (function (param) {
                    return getErrorCount(hasSimpleNameCasing, hasValueChecking, param);
                  })), 0, (function (sum, count) {
                return sum + count | 0;
              }));
}

function hasDiscrepancies(hasSimpleNameCasing, hasValueChecking, discrepancies) {
  return getTotalWarningsCount(hasSimpleNameCasing, hasValueChecking, discrepancies) > 0;
}

function hasDiscrepencyErrors(hasSimpleNameCasing, hasValueChecking, discrepancies) {
  return getTotalErrorsCount(hasSimpleNameCasing, hasValueChecking, discrepancies) > 0;
}

function getDiscrepancies(hasGuardrailsForAll, eventComponentsWithNames, config, model) {
  var model_types = model.types;
  var model_properties = model.properties;
  var model_propertyBundles = model.propertyBundles;
  var model_events = Belt_List.keepU(model.events, (function ($$event) {
          return !VariantEventIdUseCase.isVariantEventId($$event.id);
        }));
  var model_migrations = model.migrations;
  var model_sources = model.sources;
  var model_destinations = model.destinations;
  var model_groupTypes = model.groupTypes;
  var model_goals = model.goals;
  var model_metrics = model.metrics;
  var model_archive = model.archive;
  var model_openBranches = model.openBranches;
  var model_branchPointer = model.branchPointer;
  var model_rules = model.rules;
  var model_integrations = model.integrations;
  var model_globalRequirements = model.globalRequirements;
  var model$1 = {
    types: model_types,
    properties: model_properties,
    propertyBundles: model_propertyBundles,
    events: model_events,
    migrations: model_migrations,
    sources: model_sources,
    destinations: model_destinations,
    groupTypes: model_groupTypes,
    goals: model_goals,
    metrics: model_metrics,
    archive: model_archive,
    openBranches: model_openBranches,
    branchPointer: model_branchPointer,
    rules: model_rules,
    integrations: model_integrations,
    globalRequirements: model_globalRequirements
  };
  var sendAsToString = function (sendAs) {
    if (typeof sendAs === "number") {
      switch (sendAs) {
        case /* SystemProperty */0 :
            return "system";
        case /* EventProperty */1 :
            return "event";
        case /* UserProperty */2 :
            return "user";
        
      }
    } else {
      var groupName = Belt_Option.getWithDefault(ModelUtils.getGroupTypeName(sendAs._0, model$1), "Untitled");
      return groupName + " group";
    }
  };
  var propertyConflicts = Belt_List.keepMapU(model_events, (function ($$event) {
          var __x = AvoModel.resolveProperties(model$1, $$event.properties);
          var hasPropertyConflicts = Belt_List.someU(BeltListExtensions.group((function (a, b) {
                      var a$1 = sendAsToString(a.sendAs) + a.name;
                      var b$1 = sendAsToString(b.sendAs) + b.name;
                      return Caml.caml_string_compare(a$1, b$1);
                    }), __x), (function (props) {
                  return Belt_List.length(props) > 1;
                }));
          if (hasPropertyConflicts) {
            return $$event;
          }
          
        }));
  var match = ValidationConfigV2.EventsV2.getCase(config.events);
  var expectedEventCase;
  if (typeof match === "object") {
    var $$case = match.VAL;
    expectedEventCase = $$case === "None" ? NameUtils.getCommonCase(ModelUtils.getEventNames(model_events)) : $$case;
  } else {
    expectedEventCase = Curry._1(ValidationConfigV2.Value.getCase, ValidationConfigV2.EventsV2.getSimplestNameCasing(config.events).value);
  }
  var eventCaseWarnings = Belt_List.keepMapU(model_events, (function ($$event) {
          var notExpectedCase = $$event.name !== Case.toWithSeperators(expectedEventCase, $$event.name) && Case.of_($$event.name) !== expectedEventCase;
          if (notExpectedCase) {
            return $$event;
          }
          
        }));
  var eventsWithoutExpectedValuesWarnings = hasGuardrailsForAll && ValidationConfigV2.EventsV2.hasValueChecking(config.events) ? Belt_List.keepMapU(model_events, (function ($$event) {
            var unexpectedValues = getUnexpectedValues(eventComponentsWithNames, ValidationConfigV2.EventsV2.getNameComponents(config.events), $$event.name);
            if (unexpectedValues.blocked.length !== 0 || unexpectedValues.predefined.length !== 0) {
              return [
                      $$event,
                      unexpectedValues
                    ];
            }
            
          })) : /* [] */0;
  var match$1 = config.properties.case.value;
  var expectedPropertyCase;
  if (typeof match$1 === "object" && match$1.NAME === "case") {
    var $$case$1 = match$1.VAL;
    expectedPropertyCase = $$case$1 === "None" ? NameUtils.getCommonCase(ModelUtils.getPropertyNames(model_properties)) : $$case$1;
  } else {
    expectedPropertyCase = NameUtils.getCommonCase(ModelUtils.getPropertyNames(model_properties));
  }
  var propertyCaseWarnings = Belt_List.keepMapU(model_properties, (function (property) {
          if (property.TAG === /* PropertyRef */0) {
            return ;
          }
          var property$1 = property._0;
          var notExpectedCase = property$1.name !== Case.to_(expectedPropertyCase, property$1.name) && Case.of_(property$1.name) !== expectedPropertyCase;
          if (notExpectedCase) {
            return property$1;
          }
          
        }));
  var propertyTypeWarnings = Belt_List.keepMapU(model_properties, (function (property) {
          if (property.TAG === /* PropertyRef */0) {
            return ;
          }
          var property$1 = property._0;
          var hasAnyType = property$1.type_ === "any";
          if (hasAnyType) {
            return property$1;
          }
          
        }));
  var eventNameConflicts = Belt_List.flatten(Belt_List.keepMapU(BeltListExtensions.group((function (a, b) {
                  var a$1 = Case.camel(a.name);
                  var b$1 = Case.camel(b.name);
                  if (a$1 === b$1) {
                    return 0;
                  } else if (a$1 > b$1) {
                    return -1;
                  } else {
                    return 1;
                  }
                }), model_events), (function (events) {
              if (Belt_List.length(events) > 1) {
                return events;
              }
              
            })));
  var __x = Belt_List.keepMapU(model_properties, (function (property) {
          if (property.TAG === /* PropertyRef */0) {
            return ;
          } else {
            return property._0;
          }
        }));
  var propertyNameConflicts = Belt_List.flatten(Belt_List.keepMapU(BeltListExtensions.group((function (a, b) {
                  var a$1 = Case.camel(a.name);
                  var b$1 = Case.camel(b.name);
                  if (a$1 === b$1) {
                    return 0;
                  } else if (a$1 > b$1) {
                    return -1;
                  } else {
                    return 1;
                  }
                }), __x), (function (properties) {
              if (Belt_List.length(properties) > 1) {
                return properties;
              }
              
            })));
  var globalPropertyDescriptions = Belt_Option.mapWithDefault(model_globalRequirements, undefined, (function (param) {
          return Belt_MapString.fromArray(Belt_Array.keepMapU(Belt_List.toArray(param.properties), (function (property) {
                            if (property.TAG === /* PropertyRef */0) {
                              return ;
                            }
                            var match = property._0;
                            var match$1 = match.globalRequirementsMetadata;
                            if (match$1 !== undefined) {
                              return [
                                      match$1.fromLocalItemId,
                                      match.description
                                    ];
                            }
                            
                          })));
        }));
  var eventsWithoutDescription = Belt_List.keepU(model_events, (function ($$event) {
          if ($$event.description.trim() === "") {
            return Belt_Option.mapWithDefault(model_globalRequirements, true, (function (param) {
                          var match = Belt_List.getByU(param.events, (function (globalEvent) {
                                  return globalEvent.id === $$event.id;
                                }));
                          if (match !== undefined) {
                            return match.description.trim() === "";
                          } else {
                            return true;
                          }
                        }));
          } else {
            return false;
          }
        }));
  var propertiesWithoutDescription = Belt_List.keepMapU(model_properties, (function (property) {
          if (property.TAG === /* PropertyRef */0) {
            return ;
          }
          var property$1 = property._0;
          var tmp = false;
          if (property$1.description.trim() === "") {
            var description = Belt_MapString.get(globalPropertyDescriptions, property$1.id);
            tmp = description !== undefined ? description.trim() === "" : true;
          }
          if (tmp) {
            return property$1;
          }
          
        }));
  var discrepancies = [
    {
      NAME: "PropertyConflictOnEvent",
      VAL: [
        Curry._1(ValidationConfigV2.Rule.isChecked, config.properties.conflictsOnEvent),
        Curry._1(ValidationConfigV2.Rule.isEnforced, config.properties.conflictsOnEvent),
        propertyConflicts
      ]
    },
    {
      NAME: "SameEventName",
      VAL: [
        Curry._1(ValidationConfigV2.Rule.isChecked, config.events.uniqueName),
        Curry._1(ValidationConfigV2.Rule.isEnforced, config.events.uniqueName),
        eventNameConflicts
      ]
    },
    {
      NAME: "EventsWithUnexpectedCase",
      VAL: [
        ValidationConfigV2.EventsV2.hasConsistentNameCasing(config.events),
        ValidationConfigV2.EventsV2.hasEnforcedNameCasing(config.events),
        eventCaseWarnings
      ]
    },
    {
      NAME: "EventsWithoutExpectedValues",
      VAL: [
        (ValidationConfigV2.EventsV2.getAllowedValues(config.events).length !== 0 || ValidationConfigV2.EventsV2.getBlockedValues(config.events).length !== 0) && hasGuardrailsForAll,
        ValidationConfigV2.EventsV2.hasEnforcedNameCasing(config.events) && hasGuardrailsForAll,
        eventsWithoutExpectedValuesWarnings
      ]
    },
    {
      NAME: "SamePropertyName",
      VAL: [
        Curry._1(ValidationConfigV2.Rule.isChecked, config.properties.uniqueName),
        Curry._1(ValidationConfigV2.Rule.isEnforced, config.properties.uniqueName),
        propertyNameConflicts
      ]
    },
    {
      NAME: "PropertiesWithUnexpectedCase",
      VAL: [
        Curry._1(ValidationConfigV2.Rule.isChecked, config.properties.case),
        Curry._1(ValidationConfigV2.Rule.isEnforced, config.properties.case),
        propertyCaseWarnings
      ]
    },
    {
      NAME: "PropertiesWithAnyType",
      VAL: [
        Curry._1(ValidationConfigV2.Rule.isChecked, config.properties.anyType),
        Curry._1(ValidationConfigV2.Rule.isEnforced, config.properties.anyType),
        propertyTypeWarnings
      ]
    },
    {
      NAME: "EventsWithoutDescription",
      VAL: [
        Curry._1(ValidationConfigV2.Rule.isChecked, config.events.description),
        Curry._1(ValidationConfigV2.Rule.isEnforced, config.events.description),
        eventsWithoutDescription
      ]
    },
    {
      NAME: "PropertiesWithoutDescription",
      VAL: [
        Curry._1(ValidationConfigV2.Rule.isChecked, config.properties.description),
        Curry._1(ValidationConfigV2.Rule.isEnforced, config.properties.description),
        propertiesWithoutDescription
      ]
    }
  ];
  var match$2 = ValidationConfigV2.EventsV2.getCase(config.events);
  var hasSimpleNameCasing = typeof match$2 === "object" ? true : false;
  var hasValueChecking = ValidationConfigV2.EventsV2.hasValueChecking(config.events);
  return {
          discrepancies: discrepancies,
          config: config,
          warningCount: getTotalWarningsCount(hasSimpleNameCasing, hasValueChecking, discrepancies),
          errorCount: getTotalErrorsCount(hasSimpleNameCasing, hasValueChecking, discrepancies),
          expectedEventCase: expectedEventCase,
          expectedPropertyCase: expectedPropertyCase
        };
}

function getDiscrepanciesDiff(mainDiscrepancies, branchDiscrepancies) {
  return Belt_Array.mapU(branchDiscrepancies, (function (branchDiscrepancy) {
                var variant = branchDiscrepancy.NAME;
                if (variant === "SameEventName") {
                  var match = branchDiscrepancy.VAL;
                  var branchL = match[2];
                  var match$1 = Belt_Array.getByU(mainDiscrepancies, (function (mainDiscrepancy) {
                          if (typeof mainDiscrepancy === "object") {
                            return mainDiscrepancy.NAME === "SameEventName";
                          } else {
                            return false;
                          }
                        }));
                  var branchList;
                  if (match$1 !== undefined && typeof match$1 === "object" && match$1.NAME === "SameEventName") {
                    var mainDict = Js_dict.fromList(Belt_List.mapU(match$1.VAL[2], (function (param) {
                                return [
                                        param.id,
                                        "Present"
                                      ];
                              })));
                    branchList = Belt_List.keepU(branchL, (function (branchItem) {
                            var match = Js_dict.get(mainDict, branchItem.id);
                            return match === undefined;
                          }));
                  } else {
                    branchList = branchL;
                  }
                  return {
                          NAME: "SameEventName",
                          VAL: [
                            match[0],
                            match[1],
                            branchList
                          ]
                        };
                }
                if (variant === "SamePropertyName") {
                  var match$2 = branchDiscrepancy.VAL;
                  var branchL$1 = match$2[2];
                  var match$3 = Belt_Array.getByU(mainDiscrepancies, (function (mainDiscrepancy) {
                          if (typeof mainDiscrepancy === "object") {
                            return mainDiscrepancy.NAME === "SamePropertyName";
                          } else {
                            return false;
                          }
                        }));
                  var branchList$1;
                  if (match$3 !== undefined && typeof match$3 === "object" && match$3.NAME === "SamePropertyName") {
                    var mainDict$1 = Js_dict.fromList(Belt_List.mapU(match$3.VAL[2], (function (param) {
                                return [
                                        param.id,
                                        "Present"
                                      ];
                              })));
                    branchList$1 = Belt_List.keepU(branchL$1, (function (branchItem) {
                            var match = Js_dict.get(mainDict$1, branchItem.id);
                            return match === undefined;
                          }));
                  } else {
                    branchList$1 = branchL$1;
                  }
                  return {
                          NAME: "SamePropertyName",
                          VAL: [
                            match$2[0],
                            match$2[1],
                            branchList$1
                          ]
                        };
                }
                if (variant === "PropertiesWithAnyType") {
                  var match$4 = branchDiscrepancy.VAL;
                  var branchL$2 = match$4[2];
                  var match$5 = Belt_Array.getByU(mainDiscrepancies, (function (mainDiscrepancy) {
                          if (typeof mainDiscrepancy === "object") {
                            return mainDiscrepancy.NAME === "PropertiesWithAnyType";
                          } else {
                            return false;
                          }
                        }));
                  var branchList$2;
                  if (match$5 !== undefined && typeof match$5 === "object" && match$5.NAME === "PropertiesWithAnyType") {
                    var mainDict$2 = Js_dict.fromList(Belt_List.mapU(match$5.VAL[2], (function (param) {
                                return [
                                        param.id,
                                        "Present"
                                      ];
                              })));
                    branchList$2 = Belt_List.keepU(branchL$2, (function (branchItem) {
                            var match = Js_dict.get(mainDict$2, branchItem.id);
                            return match === undefined;
                          }));
                  } else {
                    branchList$2 = branchL$2;
                  }
                  return {
                          NAME: "PropertiesWithAnyType",
                          VAL: [
                            match$4[0],
                            match$4[1],
                            branchList$2
                          ]
                        };
                }
                if (variant === "EventsWithoutDescription") {
                  var match$6 = branchDiscrepancy.VAL;
                  var branchL$3 = match$6[2];
                  var match$7 = Belt_Array.getByU(mainDiscrepancies, (function (mainDiscrepancy) {
                          if (typeof mainDiscrepancy === "object") {
                            return mainDiscrepancy.NAME === "EventsWithoutDescription";
                          } else {
                            return false;
                          }
                        }));
                  var branchList$3;
                  if (match$7 !== undefined && typeof match$7 === "object" && match$7.NAME === "EventsWithoutDescription") {
                    var mainDict$3 = Js_dict.fromList(Belt_List.mapU(match$7.VAL[2], (function (param) {
                                return [
                                        param.id,
                                        "Present"
                                      ];
                              })));
                    branchList$3 = Belt_List.keepU(branchL$3, (function (branchItem) {
                            var match = Js_dict.get(mainDict$3, branchItem.id);
                            return match === undefined;
                          }));
                  } else {
                    branchList$3 = branchL$3;
                  }
                  return {
                          NAME: "EventsWithoutDescription",
                          VAL: [
                            match$6[0],
                            match$6[1],
                            branchList$3
                          ]
                        };
                }
                if (variant === "EventsWithoutExpectedValues") {
                  var match$8 = branchDiscrepancy.VAL;
                  var branchL$4 = match$8[2];
                  var match$9 = Belt_Array.getByU(mainDiscrepancies, (function (mainDiscrepancy) {
                          if (typeof mainDiscrepancy === "object") {
                            return mainDiscrepancy.NAME === "EventsWithoutExpectedValues";
                          } else {
                            return false;
                          }
                        }));
                  var branchList$4;
                  if (match$9 !== undefined && typeof match$9 === "object" && match$9.NAME === "EventsWithoutExpectedValues") {
                    var mainDict$4 = Js_dict.fromList(Belt_List.mapU(match$9.VAL[2], (function (param) {
                                return [
                                        param[0].id,
                                        "Present"
                                      ];
                              })));
                    branchList$4 = Belt_List.keepU(branchL$4, (function (param) {
                            var match = Js_dict.get(mainDict$4, param[0].id);
                            return match === undefined;
                          }));
                  } else {
                    branchList$4 = branchL$4;
                  }
                  return {
                          NAME: "EventsWithoutExpectedValues",
                          VAL: [
                            match$8[0],
                            match$8[1],
                            branchList$4
                          ]
                        };
                }
                if (variant === "EventsWithUnexpectedCase") {
                  var match$10 = branchDiscrepancy.VAL;
                  var branchL$5 = match$10[2];
                  var match$11 = Belt_Array.getByU(mainDiscrepancies, (function (mainDiscrepancy) {
                          if (typeof mainDiscrepancy === "object") {
                            return mainDiscrepancy.NAME === "EventsWithUnexpectedCase";
                          } else {
                            return false;
                          }
                        }));
                  var branchList$5;
                  if (match$11 !== undefined && typeof match$11 === "object" && match$11.NAME === "EventsWithUnexpectedCase") {
                    var mainDict$5 = Js_dict.fromList(Belt_List.mapU(match$11.VAL[2], (function (param) {
                                return [
                                        param.id,
                                        "Present"
                                      ];
                              })));
                    branchList$5 = Belt_List.keepU(branchL$5, (function (branchItem) {
                            var match = Js_dict.get(mainDict$5, branchItem.id);
                            return match === undefined;
                          }));
                  } else {
                    branchList$5 = branchL$5;
                  }
                  return {
                          NAME: "EventsWithUnexpectedCase",
                          VAL: [
                            match$10[0],
                            match$10[1],
                            branchList$5
                          ]
                        };
                }
                if (variant === "PropertyConflictOnEvent") {
                  var match$12 = branchDiscrepancy.VAL;
                  var branchL$6 = match$12[2];
                  var match$13 = Belt_Array.getByU(mainDiscrepancies, (function (mainDiscrepancy) {
                          if (typeof mainDiscrepancy === "object") {
                            return mainDiscrepancy.NAME === "PropertyConflictOnEvent";
                          } else {
                            return false;
                          }
                        }));
                  var branchList$6;
                  if (match$13 !== undefined && typeof match$13 === "object" && match$13.NAME === "PropertyConflictOnEvent") {
                    var mainDict$6 = Js_dict.fromList(Belt_List.mapU(match$13.VAL[2], (function (param) {
                                return [
                                        param.id,
                                        "Present"
                                      ];
                              })));
                    branchList$6 = Belt_List.keepU(branchL$6, (function (branchItem) {
                            var match = Js_dict.get(mainDict$6, branchItem.id);
                            return match === undefined;
                          }));
                  } else {
                    branchList$6 = branchL$6;
                  }
                  return {
                          NAME: "PropertyConflictOnEvent",
                          VAL: [
                            match$12[0],
                            match$12[1],
                            branchList$6
                          ]
                        };
                }
                if (variant === "PropertiesWithoutDescription") {
                  var match$14 = branchDiscrepancy.VAL;
                  var branchL$7 = match$14[2];
                  var match$15 = Belt_Array.getByU(mainDiscrepancies, (function (mainDiscrepancy) {
                          if (typeof mainDiscrepancy === "object") {
                            return mainDiscrepancy.NAME === "PropertiesWithoutDescription";
                          } else {
                            return false;
                          }
                        }));
                  var branchList$7;
                  if (match$15 !== undefined && typeof match$15 === "object" && match$15.NAME === "PropertiesWithoutDescription") {
                    var mainDict$7 = Js_dict.fromList(Belt_List.mapU(match$15.VAL[2], (function (param) {
                                return [
                                        param.id,
                                        "Present"
                                      ];
                              })));
                    branchList$7 = Belt_List.keepU(branchL$7, (function (branchItem) {
                            var match = Js_dict.get(mainDict$7, branchItem.id);
                            return match === undefined;
                          }));
                  } else {
                    branchList$7 = branchL$7;
                  }
                  return {
                          NAME: "PropertiesWithoutDescription",
                          VAL: [
                            match$14[0],
                            match$14[1],
                            branchList$7
                          ]
                        };
                }
                var match$16 = branchDiscrepancy.VAL;
                var branchL$8 = match$16[2];
                var match$17 = Belt_Array.getByU(mainDiscrepancies, (function (mainDiscrepancy) {
                        if (typeof mainDiscrepancy === "object") {
                          return mainDiscrepancy.NAME === "PropertiesWithUnexpectedCase";
                        } else {
                          return false;
                        }
                      }));
                var branchList$8;
                if (match$17 !== undefined && typeof match$17 === "object" && match$17.NAME === "PropertiesWithUnexpectedCase") {
                  var mainDict$8 = Js_dict.fromList(Belt_List.mapU(match$17.VAL[2], (function (param) {
                              return [
                                      param.id,
                                      "Present"
                                    ];
                            })));
                  branchList$8 = Belt_List.keepU(branchL$8, (function (branchItem) {
                          var match = Js_dict.get(mainDict$8, branchItem.id);
                          return match === undefined;
                        }));
                } else {
                  branchList$8 = branchL$8;
                }
                return {
                        NAME: "PropertiesWithUnexpectedCase",
                        VAL: [
                          match$16[0],
                          match$16[1],
                          branchList$8
                        ]
                      };
              }));
}

function getBranchDiscrepancies_forWorker(hasGuardrailsForAll, eventComponentsWithNames, config, mainModelToCompareTo, branchModelToCompareTo) {
  var match = getDiscrepancies(hasGuardrailsForAll, Belt_MapString.fromArray(eventComponentsWithNames), config, branchModelToCompareTo);
  var match$1 = getDiscrepancies(hasGuardrailsForAll, Belt_MapString.fromArray(eventComponentsWithNames), config, mainModelToCompareTo);
  var match$2 = ValidationConfigV2.EventsV2.getCase(config.events);
  var hasSimpleNameCasing = typeof match$2 === "object" ? true : false;
  var hasValueChecking = ValidationConfigV2.EventsV2.hasValueChecking(config.events);
  var branchAuditChecks = getDiscrepanciesDiff(match$1.discrepancies, match.discrepancies);
  var branchAuditChecksCount = getTotalWarningsCount(hasSimpleNameCasing, hasValueChecking, branchAuditChecks);
  var branchAuditErrorCount = getTotalErrorsCount(hasSimpleNameCasing, hasValueChecking, branchAuditChecks);
  var dict = {};
  Belt_List.forEach(GetEventNamespaceUseCase.getEventNamespaceWithIds(branchModelToCompareTo.events), (function (param) {
          var id = param[1];
          var normalizedName = Case.to_("Camel", param[0]);
          var ids = Js_dict.get(dict, normalizedName);
          if (ids !== undefined) {
            dict[normalizedName] = Belt_List.concat(ids, {
                  hd: id,
                  tl: /* [] */0
                });
          } else {
            dict[normalizedName] = {
              hd: id,
              tl: /* [] */0
            };
          }
          
        }));
  var dict$1 = {};
  Belt_List.forEach(ModelUtils.getPropertyNamespaceWithIds(branchModelToCompareTo), (function (param) {
          var id = param[1];
          var normalizedName = Case.to_("Camel", param[0]);
          var ids = Js_dict.get(dict$1, normalizedName);
          if (ids !== undefined) {
            dict$1[normalizedName] = Belt_List.concat(ids, {
                  hd: id,
                  tl: /* [] */0
                });
          } else {
            dict$1[normalizedName] = {
              hd: id,
              tl: /* [] */0
            };
          }
          
        }));
  return {
          branchAuditChecks: branchAuditChecks,
          branchAuditChecksCount: branchAuditChecksCount,
          branchAuditErrorCount: branchAuditErrorCount,
          existingEventNames: dict,
          existingPropertyNames: dict$1
        };
}

export {
  Json ,
  getUnexpectedValues ,
  getWarningsCount ,
  getErrorCount ,
  getTotalWarningsCount ,
  getTotalErrorsCount ,
  hasDiscrepancies ,
  hasDiscrepencyErrors ,
  getDiscrepancies ,
  getDiscrepanciesDiff ,
  getBranchDiscrepancies_forWorker ,
  
}
/* Case Not a pure module */
