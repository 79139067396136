// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Case from "../../../bs-case/src/case.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as AnalyticsRe from "../analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as CmdKProvider from "../CmdKProvider.mjs";
import * as AvoEngagement from "../avoEngagement/AvoEngagement.mjs";
import * as ProductTour__Modal from "./ProductTour__Modal.mjs";
import * as SchemaBundleContext from "../SchemaBundleContext.mjs";
import * as AvoEngagement__Types from "../avoEngagement/AvoEngagement__Types.mjs";
import * as ProductTour__Windowed from "./ProductTour__Windowed.mjs";
import * as ProductTour__OnElement from "./ProductTour__OnElement.mjs";
import * as ProductTour__OnElements from "./ProductTour__OnElements.mjs";
import * as AvoProductTour__StepProvider from "./AvoProductTour__StepProvider.mjs";
import * as AvoProductTour__TourProvider from "./AvoProductTour__TourProvider.mjs";

function useCmdKHandler(disable) {
  var match = CmdKProvider.useDisabled(undefined);
  var setCmdkDisabled = match[1];
  var match$1 = React.useState(function () {
        return disable;
      });
  var setDisabledByStep = match$1[1];
  var disabledByStep = match$1[0];
  React.useEffect((function () {
          if (disable) {
            Curry._1(setDisabledByStep, (function (param) {
                    return true;
                  }));
            Curry._1(setCmdkDisabled, (function (param) {
                    return true;
                  }));
          }
          return (function (param) {
                    if (disabledByStep) {
                      Curry._1(setDisabledByStep, (function (param) {
                              return false;
                            }));
                      return Curry._1(setCmdkDisabled, (function (param) {
                                    return false;
                                  }));
                    }
                    
                  });
        }), [disable]);
  
}

function AvoProductTour__Step(Props) {
  var blockId = Props.blockId;
  var arrowOffset = Props.arrowOffset;
  var borderRadius = Props.borderRadius;
  var delay = Props.delay;
  var disableCmdKOpt = Props.disableCmdK;
  var elementClickable = Props.elementClickable;
  var elementPadding = Props.elementPadding;
  var lockBackground = Props.lockBackground;
  var offset = Props.offset;
  var onClick = Props.onClick;
  var onOutsideClick = Props.onOutsideClick;
  var onStepActive = Props.onStepActive;
  var onTransition = Props.onTransition;
  var position = Props.position;
  var positionDependencies = Props.positionDependencies;
  var simulateClick = Props.simulateClick;
  var stepType = Props.stepType;
  var transition = Props.transition;
  var transitionDuration = Props.transitionDuration;
  var transitions = Props.transitions;
  var waitForId = Props.waitForId;
  var withOutline = Props.withOutline;
  var children = Props.children;
  var disableCmdK = disableCmdKOpt !== undefined ? disableCmdKOpt : false;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var match = AvoProductTour__TourProvider.use(undefined);
  var engagementId = match.engagementId;
  var version = match.engagement.version;
  var match$1 = AvoEngagement.Block.use(engagementId, blockId);
  var blockTransition = match$1[1];
  var block = match$1[0];
  var stepNumber = Belt_Option.getWithDefault(Curry._1(match.getStepNumber, blockId), 0);
  var transitionCallback = React.useCallback((function (transitionKey) {
          Belt_Option.forEach(onTransition, (function (cb) {
                  return Curry._1(cb, transitionKey);
                }));
          if (Curry._2(AvoEngagement__Types.Block.Transition.keyAdvances, block.transitions, transitionKey)) {
            AnalyticsRe.productFlowStepCompleted(AnalyticsRe.Bundle.productFlows(Case.title(engagementId), engagementId, String(version)), schemaBundle, block.id, stepNumber, schemaBundle.schemaId);
          }
          setTimeout((function (param) {
                  return Curry._1(blockTransition, transitionKey);
                }), 0);
          
        }), [
        blockTransition,
        onTransition
      ]);
  var onClick$1 = Belt_Option.map(onClick, (function (onClick, e) {
          return Curry._2(onClick, transitionCallback, e);
        }));
  useCmdKHandler(block.state.active && disableCmdK);
  if (!block.state.active) {
    return null;
  }
  var tmp;
  if (typeof stepType === "number") {
    tmp = children;
  } else {
    switch (stepType.TAG | 0) {
      case /* OnElementId */0 :
          var tmp$1 = {
            forId: stepType._0,
            opened: block.state.active,
            children: children
          };
          if (arrowOffset !== undefined) {
            tmp$1.arrowOffset = Caml_option.valFromOption(arrowOffset);
          }
          if (borderRadius !== undefined) {
            tmp$1.borderRadius = Caml_option.valFromOption(borderRadius);
          }
          if (delay !== undefined) {
            tmp$1.delay = Caml_option.valFromOption(delay);
          }
          if (elementClickable !== undefined) {
            tmp$1.elementClickable = Caml_option.valFromOption(elementClickable);
          }
          if (elementPadding !== undefined) {
            tmp$1.elementPadding = Caml_option.valFromOption(elementPadding);
          }
          if (lockBackground !== undefined) {
            tmp$1.lockBackground = Caml_option.valFromOption(lockBackground);
          }
          if (offset !== undefined) {
            tmp$1.offset = Caml_option.valFromOption(offset);
          }
          if (onClick$1 !== undefined) {
            tmp$1.onClick = Caml_option.valFromOption(onClick$1);
          }
          if (onOutsideClick !== undefined) {
            tmp$1.onOutsideClick = Caml_option.valFromOption(onOutsideClick);
          }
          if (position !== undefined) {
            tmp$1.position = Caml_option.valFromOption(position);
          }
          if (positionDependencies !== undefined) {
            tmp$1.positionDependencies = Caml_option.valFromOption(positionDependencies);
          }
          if (onStepActive !== undefined) {
            tmp$1.onStepActive = Caml_option.valFromOption(onStepActive);
          }
          if (simulateClick !== undefined) {
            tmp$1.simulateClick = Caml_option.valFromOption(simulateClick);
          }
          if (transition !== undefined) {
            tmp$1.transition = Caml_option.valFromOption(transition);
          }
          if (transitionDuration !== undefined) {
            tmp$1.transitionDuration = Caml_option.valFromOption(transitionDuration);
          }
          if (waitForId !== undefined) {
            tmp$1.waitForId = Caml_option.valFromOption(waitForId);
          }
          if (withOutline !== undefined) {
            tmp$1.withOutline = Caml_option.valFromOption(withOutline);
          }
          tmp = React.createElement(ProductTour__OnElement.make, tmp$1);
          break;
      case /* OnElementIds */1 :
          var tmp$2 = {
            forIds: stepType._0,
            opened: block.state.active,
            children: children
          };
          if (arrowOffset !== undefined) {
            tmp$2.arrowOffset = Caml_option.valFromOption(arrowOffset);
          }
          if (borderRadius !== undefined) {
            tmp$2.borderRadius = Caml_option.valFromOption(borderRadius);
          }
          if (delay !== undefined) {
            tmp$2.delay = Caml_option.valFromOption(delay);
          }
          if (elementClickable !== undefined) {
            tmp$2.elementClickable = Caml_option.valFromOption(elementClickable);
          }
          if (elementPadding !== undefined) {
            tmp$2.elementPadding = Caml_option.valFromOption(elementPadding);
          }
          if (lockBackground !== undefined) {
            tmp$2.lockBackground = Caml_option.valFromOption(lockBackground);
          }
          if (onClick$1 !== undefined) {
            tmp$2.onClick = Caml_option.valFromOption(onClick$1);
          }
          if (onOutsideClick !== undefined) {
            tmp$2.onOutsideClick = Caml_option.valFromOption(onOutsideClick);
          }
          if (offset !== undefined) {
            tmp$2.offset = Caml_option.valFromOption(offset);
          }
          if (position !== undefined) {
            tmp$2.position = Caml_option.valFromOption(position);
          }
          if (positionDependencies !== undefined) {
            tmp$2.positionDependencies = Caml_option.valFromOption(positionDependencies);
          }
          if (onStepActive !== undefined) {
            tmp$2.onStepActive = Caml_option.valFromOption(onStepActive);
          }
          if (simulateClick !== undefined) {
            tmp$2.simulateClick = Caml_option.valFromOption(simulateClick);
          }
          if (transition !== undefined) {
            tmp$2.transition = Caml_option.valFromOption(transition);
          }
          if (transitionDuration !== undefined) {
            tmp$2.transitionDuration = Caml_option.valFromOption(transitionDuration);
          }
          if (waitForId !== undefined) {
            tmp$2.waitForId = Caml_option.valFromOption(waitForId);
          }
          if (withOutline !== undefined) {
            tmp$2.withOutline = Caml_option.valFromOption(withOutline);
          }
          tmp = React.createElement(ProductTour__OnElements.make, tmp$2);
          break;
      case /* Window */2 :
          var match$2 = stepType._0;
          var tmp$3 = {
            height: match$2.height,
            left: match$2.left,
            opened: block.state.active,
            top: match$2.top,
            width: match$2.width,
            children: children
          };
          if (arrowOffset !== undefined) {
            tmp$3.arrowOffset = Caml_option.valFromOption(arrowOffset);
          }
          if (borderRadius !== undefined) {
            tmp$3.borderRadius = Caml_option.valFromOption(borderRadius);
          }
          if (delay !== undefined) {
            tmp$3.delay = Caml_option.valFromOption(delay);
          }
          if (elementClickable !== undefined) {
            tmp$3.elementClickable = Caml_option.valFromOption(elementClickable);
          }
          if (lockBackground !== undefined) {
            tmp$3.lockBackground = Caml_option.valFromOption(lockBackground);
          }
          if (onClick$1 !== undefined) {
            tmp$3.onClick = Caml_option.valFromOption(onClick$1);
          }
          if (onOutsideClick !== undefined) {
            tmp$3.onOutsideClick = Caml_option.valFromOption(onOutsideClick);
          }
          if (offset !== undefined) {
            tmp$3.offset = Caml_option.valFromOption(offset);
          }
          if (position !== undefined) {
            tmp$3.position = Caml_option.valFromOption(position);
          }
          if (positionDependencies !== undefined) {
            tmp$3.positionDependencies = Caml_option.valFromOption(positionDependencies);
          }
          if (onStepActive !== undefined) {
            tmp$3.onStepActive = Caml_option.valFromOption(onStepActive);
          }
          if (simulateClick !== undefined) {
            tmp$3.simulateClick = Caml_option.valFromOption(simulateClick);
          }
          if (transition !== undefined) {
            tmp$3.transition = Caml_option.valFromOption(transition);
          }
          if (transitionDuration !== undefined) {
            tmp$3.transitionDuration = Caml_option.valFromOption(transitionDuration);
          }
          if (waitForId !== undefined) {
            tmp$3.waitForId = Caml_option.valFromOption(waitForId);
          }
          if (withOutline !== undefined) {
            tmp$3.withOutline = Caml_option.valFromOption(withOutline);
          }
          tmp = React.createElement(ProductTour__Windowed.make, tmp$3);
          break;
      case /* Modal */3 :
          var tmp$4 = {
            opened: block.state.active,
            children: children
          };
          if (lockBackground !== undefined) {
            tmp$4.closeOnClickOutside = Caml_option.valFromOption(lockBackground);
          }
          if (delay !== undefined) {
            tmp$4.delay = Caml_option.valFromOption(delay);
          }
          if (onStepActive !== undefined) {
            tmp$4.onStepActive = Caml_option.valFromOption(onStepActive);
          }
          if (stepType._0 !== undefined) {
            tmp$4.size = Caml_option.valFromOption(stepType._0);
          }
          tmp = React.createElement(ProductTour__Modal.make, tmp$4);
          break;
      
    }
  }
  return React.createElement(AvoProductTour__StepProvider.make, {
              block: block,
              transition: transitionCallback,
              transitions: transitions,
              children: tmp
            });
}

var make = AvoProductTour__Step;

export {
  useCmdKHandler ,
  make ,
  
}
/* Case Not a pure module */
