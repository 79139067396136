// Generated by ReScript, PLEASE EDIT WITH CARE


var _map = {"SecurityAuditLog":"SecurityAuditLog","ActivityLogs":"ActivityLogs","TrackingPlanModelChange":"TrackingPlanModelChange"};

function tToJs(param) {
  return param;
}

function tFromJs(param) {
  return _map[param];
}

var ActionImpactLevel = {
  tToJs: tToJs,
  tFromJs: tFromJs
};

function getActionImpactLevelFromAction(action) {
  if (typeof action !== "object") {
    if (action === "StartedImport") {
      return "ActivityLogs";
    } else if (action === "SchemaAccessed") {
      return "SecurityAuditLog";
    } else {
      return "TrackingPlanModelChange";
    }
  }
  var variant = action.NAME;
  if (variant === "UpdateTrackingPlanAuditConfig" || variant === "UpdateIntegrationDestinationIds" || variant === "CollaboratorRemoved" || variant === "CreateServiceAccount" || variant === "AutoPublishIntegration" || variant === "ArchiveSavedView" || variant === "CodeGenerated" || variant === "CreateInspectorIssue" || variant === "PropertyComment" || variant === "DeleteComment" || variant === "CreatePublicBranchImplementation" || variant === "PublishIntegration" || variant === "GenerateWebhookSecret" || variant === "GoalComment" || variant === "UpdateSavedView" || variant === "IntegrationComment" || variant === "InspectorIssueComment" || variant === "DeleteServiceAccount" || variant === "MetricComment" || variant === "DeletePublicBranchImplementation" || variant === "CollaboratorAdded" || variant === "PropertyGroupComment" || variant === "UpdateInspectorIssueStatus" || variant === "EventComment" || variant === "BranchComment" || variant === "PaymentCompleted" || variant === "SetBranchStatus" || variant === "CreateSavedView") {
    return "ActivityLogs";
  } else if (variant === "RemoveMember" || variant === "ResendInvite" || variant === "SubscriptionStarted" || variant === "SubscriptionCancelled" || variant === "SubscriptionUpdated" || variant === "UpdateMemberRole" || variant === "RemoveInvite" || variant === "InviteMember") {
    return "SecurityAuditLog";
  } else {
    return "TrackingPlanModelChange";
  }
}

export {
  ActionImpactLevel ,
  getActionImpactLevelFromAction ,
  
}
/* No side effect */
