// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Models from "./Models.mjs";
import * as SsoUtils from "./SsoUtils.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Workspace from "../../model/src/Workspace.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ViewerContext from "./ViewerContext.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";

var workspaceContext = React.createContext(Workspace.empty);

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = workspaceContext.Provider;

var Provider = {
  makeProps: makeProps,
  make: make
};

function WorkspaceContext(Props) {
  var schema = Props.schema;
  var children = Props.children;
  var trial = FirebaseFetcherHooks.useTrial(schema.id);
  var members = FirebaseFetcherHooks.useMembers(schema.id);
  var serviceAccounts = FirebaseFetcherHooks.useServiceAccounts(schema.id);
  var ssoConfig = FirebaseFetcherHooks.useCurrentSsoConfig(schema.id);
  var authMethod = typeof ssoConfig === "object" ? SsoUtils.ssoModeToAuthMethod(ssoConfig.VAL) : "Default";
  var workspace = Workspace.fromSchema(schema, trial, members, authMethod, serviceAccounts);
  return React.createElement(make, makeProps(workspace, children, undefined));
}

function use(param) {
  return React.useContext(workspaceContext);
}

function useMemberWithUserId(userId) {
  return Belt_List.getBy(React.useContext(workspaceContext).members, (function (member) {
                return member.id === userId;
              }));
}

function useMember(param) {
  var match = ViewerContext.use(undefined);
  var userId = match.id;
  var match$1 = React.useContext(workspaceContext);
  var members = match$1.members;
  return React.useMemo((function () {
                return Belt_List.getByU(members, (function (member) {
                              return member.id === userId;
                            }));
              }), [
              members,
              userId
            ]);
}

function useMemberRole(param) {
  var member = useMember(undefined);
  return React.useMemo((function () {
                return Belt_Option.flatMap(member, (function (member) {
                              return Models.Role.tFromJs(member.role);
                            }));
              }), [member]);
}

function useLastSeenCodegenForSourceIds(param) {
  var match = React.useContext(workspaceContext);
  var lastSeenCodegen = match.lastSeenCodegen;
  if (typeof lastSeenCodegen === "object") {
    return Belt_MapString.fromArray(lastSeenCodegen.VAL);
  }
  
}

var make$1 = WorkspaceContext;

export {
  workspaceContext ,
  Provider ,
  make$1 as make,
  use ,
  useMemberWithUserId ,
  useMember ,
  useMemberRole ,
  useLastSeenCodegenForSourceIds ,
  
}
/* workspaceContext Not a pure module */
