// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json from "@glennsl/bs-json/src/Json.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as AvoConfigTypes from "../../shared/models/AvoConfigTypes.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as TrackingPlanModel from "./TrackingPlanModel.mjs";
import * as InspectorIssueStatus from "../../shared/models/InspectorIssueStatus.mjs";
import * as WebhookPayloadFormat from "../../shared/models/WebhookPayloadFormat.mjs";
import * as TrackingPlanMappedModel from "./TrackingPlanMappedModel.mjs";

function decodeOptionalKey(key, decoder, jsonDict) {
  return Belt_Option.map(Js_dict.get(jsonDict, key), decoder);
}

function parseLiteral(json) {
  var dict = Js_json.classify(json);
  if (typeof dict === "number") {
    return Pervasives.failwith("parseLiteral expected object but got " + JSON.stringify(json));
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Pervasives.failwith("parseLiteral expected object but got " + JSON.stringify(json));
  }
  var match = Belt_Array.getExn(Js_dict.entries(dict._0), 0);
  var json$1 = match[1];
  var key = match[0];
  switch (key) {
    case "bool" :
        return {
                NAME: "BooleanLit",
                VAL: Json_decode.bool(json$1)
              };
    case "float" :
        return {
                NAME: "FloatLit",
                VAL: Json_decode.$$float(json$1)
              };
    case "int" :
        return {
                NAME: "IntLit",
                VAL: Json_decode.$$int(json$1)
              };
    case "string" :
        return {
                NAME: "StringLit",
                VAL: Json_decode.string(json$1)
              };
    default:
      return Pervasives.failwith("unexpected literal type: " + key + ", json: " + JSON.stringify(json$1));
  }
}

function parseVariantSourceOverrideType(json) {
  var unexpectedVariantSourceOverrideType = Json_decode.string(json);
  switch (unexpectedVariantSourceOverrideType) {
    case "Overridden" :
        return /* Overridden */{
                _0: undefined
              };
    case "Removed" :
        return /* Removed */0;
    default:
      return Pervasives.failwith("unexpected variant source override type: " + unexpectedVariantSourceOverrideType);
  }
}

function parseBinOp(json) {
  var match = Json_decode.string(json);
  switch (match) {
    case "eq" :
        return "EQ";
    case "gt" :
        return "GT";
    case "gte" :
        return "GTE";
    case "le" :
        return "LE";
    case "leq" :
        return "LEQ";
    case "neq" :
        return "NEQ";
    default:
      return Pervasives.failwith("unexpected binOp, json: " + JSON.stringify(json));
  }
}

function partial_arg_0(param) {
  return Json_decode.field("literal", (function (json) {
                return {
                        NAME: "Literal",
                        VAL: parseLiteral(json)
                      };
              }), param);
}

var partial_arg = {
  hd: partial_arg_0,
  tl: /* [] */0
};

function parseExp(param) {
  return Json_decode.oneOf(partial_arg, param);
}

function parsePropertyRef(json) {
  var dict = Js_json.classify(json);
  if (typeof dict === "number") {
    return Pervasives.failwith("parsePropertyRef expected object but got " + JSON.stringify(json));
  } else if (dict.TAG === /* JSONObject */2) {
    return {
            id: Json_decode.field("id", Json_decode.string, json),
            description: Json_decode.field("description", Json_decode.string, json),
            pinnedValue: Belt_Option.map(Js_dict.get(dict._0, "pinnedValue"), parseLiteral)
          };
  } else {
    return Pervasives.failwith("parsePropertyRef expected object but got " + JSON.stringify(json));
  }
}

function parseInspectorIssueMetadata(json) {
  return {
          issueId: Json_decode.field("issueId", Json_decode.string, json),
          eventName: Json_decode.field("eventName", Json_decode.string, json),
          eventId: Json_decode.optional((function (param) {
                  return Json_decode.field("eventId", Json_decode.string, param);
                }), json),
          propertyName: Json_decode.optional((function (param) {
                  return Json_decode.field("propertyName", Json_decode.string, param);
                }), json),
          propertyId: Json_decode.optional((function (param) {
                  return Json_decode.field("propertyId", Json_decode.string, param);
                }), json),
          sourceId: Json_decode.field("sourceId", Json_decode.string, json),
          issueStatus: Json_decode.field("issueStatus", InspectorIssueStatus.decode, json),
          regression: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("regression", Json_decode.bool, param);
                    }), json), false),
          trigger: Belt_Option.flatMap(Json_decode.optional((function (param) {
                      return Json_decode.field("trigger", Json_decode.string, param);
                    }), json), TrackingPlanModel.issueTriggerFromJs)
        };
}

function parseRegexValidationRule(ruleJson) {
  return {
          regex: Json_decode.field("regex", Json_decode.string, ruleJson),
          testValue: Json_decode.field("testValue", Json_decode.string, ruleJson)
        };
}

function parseCoreType(json) {
  var s = Json_decode.string(json);
  switch (s) {
    case "any" :
        return "Any";
    case "bool" :
        return "Bool";
    case "float" :
        return "Float";
    case "int" :
        return "Int";
    case "long" :
        return "Long";
    case "object" :
        return "Object";
    case "string" :
        return "String";
    default:
      return Pervasives.failwith("cannot parse core type " + s);
  }
}

function parseShapeValueType(json) {
  return {
          type_: Json_decode.field("type_", parseCoreType, json),
          validations: Json_decode.field("validations", (function (param) {
                  return Json_decode.list(parseValidation, param);
                }), json)
        };
}

function parseShapeValue(json) {
  return Json_decode.oneOf({
              hd: (function (param) {
                  return Json_decode.field("value", (function (json) {
                                return {
                                        NAME: "Value",
                                        VAL: parseShapeValueType(json)
                                      };
                              }), param);
                }),
              tl: /* [] */0
            }, json);
}

function parseShape(json) {
  return {
          key: Json_decode.field("key", Json_decode.string, json),
          list: Json_decode.field("list", Json_decode.bool, json),
          optional: Json_decode.field("optional", Json_decode.bool, json),
          value: Json_decode.field("value", parseShapeValue, json)
        };
}

function parseValidation(parentJson) {
  var dict = Js_json.classify(parentJson);
  if (typeof dict === "number") {
    return Pervasives.failwith("parseValidation expected object but got " + JSON.stringify(parentJson));
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Pervasives.failwith("parseValidation expected object but got " + JSON.stringify(parentJson));
  }
  var match = Belt_Array.getExn(Js_dict.entries(dict._0), 0);
  var json = match[1];
  var key = match[0];
  switch (key) {
    case "contains" :
        return {
                NAME: "Contains",
                VAL: parseLiteral(json)
              };
    case "endsWith" :
        return {
                NAME: "EndsWith",
                VAL: parseLiteral(json)
              };
    case "matches" :
        var partial_arg_0 = function (json) {
          return [
                  Json_decode.field("exp", parseExp, json),
                  Json_decode.field("excludedSources", (function (json) {
                          return Json_decode.list(Json_decode.string, json);
                        }), json)
                ];
        };
        var partial_arg_1 = {
          hd: (function (json) {
              return [
                      parseExp(json),
                      /* [] */0
                    ];
            }),
          tl: /* [] */0
        };
        var partial_arg = {
          hd: partial_arg_0,
          tl: partial_arg_1
        };
        return {
                NAME: "Matches",
                VAL: Json_decode.list((function (param) {
                        return Json_decode.oneOf(partial_arg, param);
                      }), json)
              };
    case "max" :
        return {
                NAME: "Max",
                VAL: parseLiteral(json)
              };
    case "min" :
        return {
                NAME: "Min",
                VAL: parseLiteral(json)
              };
    case "nestedProperty" :
        return {
                NAME: "NestedProperty",
                VAL: Belt_List.fromArray(Json_decode.array(parsePropertyRef, json))
              };
    case "regex" :
        var jsonDict = Js_json.classify(json);
        if (typeof jsonDict === "number") {
          return Pervasives.failwith("parseValidation expected object but got " + JSON.stringify(json));
        }
        if (jsonDict.TAG !== /* JSONObject */2) {
          return Pervasives.failwith("parseValidation expected object but got " + JSON.stringify(json));
        }
        var jsonDict$1 = jsonDict._0;
        var propertyRuleJson = Js_dict.get(jsonDict$1, "propertyRule");
        var tmp;
        if (propertyRuleJson !== undefined) {
          var propertyRuleJson$1 = Caml_option.valFromOption(propertyRuleJson);
          tmp = Caml_obj.caml_notequal(propertyRuleJson$1, null) ? parseRegexValidationRule(propertyRuleJson$1) : undefined;
        } else {
          tmp = undefined;
        }
        var eventOverridesJson = Js_dict.get(jsonDict$1, "eventOverrides");
        return {
                NAME: "Regex",
                VAL: {
                  propertyRule: tmp,
                  eventOverrides: eventOverridesJson !== undefined ? Belt_MapString.fromArray(Js_dict.entries(Json_decode.dict(parseRegexValidationRule, Caml_option.valFromOption(eventOverridesJson)))) : undefined
                }
              };
    case "shape" :
        return Json_decode.oneOf({
                    hd: (function (param) {
                        return Json_decode.field("shape", (function (json) {
                                      return {
                                              NAME: "Shape",
                                              VAL: Belt_List.fromArray(Json_decode.array(parseShape, json))
                                            };
                                    }), param);
                      }),
                    tl: {
                      hd: (function (param) {
                          return Json_decode.field("shape", (function (string) {
                                        return {
                                                NAME: "Shape",
                                                VAL: Belt_List.fromArray(Json_decode.array(parseShape, Belt_Option.getExn(Json.parse(string))))
                                              };
                                      }), param);
                        }),
                      tl: /* [] */0
                    }
                  }, parentJson);
    case "startsWith" :
        return {
                NAME: "StartsWith",
                VAL: parseLiteral(json)
              };
    default:
      return Pervasives.failwith("Unexpected validation type: " + key);
  }
}

function partial_arg_0$1(param) {
  return Json_decode.field("event", (function (json) {
                return {
                        NAME: "Event",
                        VAL: Json_decode.string(json)
                      };
              }), param);
}

var partial_arg_1 = {
  hd: (function (param) {
      return Json_decode.field("eventVariant", (function (json) {
                    return {
                            NAME: "EventVariant",
                            VAL: {
                              baseEventId: Json_decode.field("eventId", Json_decode.string, json),
                              variantId: Json_decode.field("variantId", Json_decode.string, json)
                            }
                          };
                  }), param);
    }),
  tl: {
    hd: (function (param) {
        return Json_decode.field("property", (function (json) {
                      return {
                              NAME: "Property",
                              VAL: Json_decode.string(json)
                            };
                    }), param);
      }),
    tl: {
      hd: (function (param) {
          return Json_decode.field("propertyGroup", (function (json) {
                        return {
                                NAME: "PropertyGroup",
                                VAL: Json_decode.string(json)
                              };
                      }), param);
        }),
      tl: {
        hd: (function (param) {
            return Json_decode.field("source", (function (json) {
                          return {
                                  NAME: "Source",
                                  VAL: Json_decode.string(json)
                                };
                        }), param);
          }),
        tl: {
          hd: (function (param) {
              return Json_decode.field("destination", (function (json) {
                            return {
                                    NAME: "Destination",
                                    VAL: Json_decode.string(json)
                                  };
                          }), param);
            }),
          tl: {
            hd: (function (param) {
                return Json_decode.field("goal", (function (json) {
                              return {
                                      NAME: "Goal",
                                      VAL: Json_decode.string(json)
                                    };
                            }), param);
              }),
            tl: {
              hd: (function (param) {
                  return Json_decode.field("metric", (function (json) {
                                return {
                                        NAME: "Metric",
                                        VAL: Json_decode.string(json)
                                      };
                              }), param);
                }),
              tl: {
                hd: (function (param) {
                    return Json_decode.field("groupType", (function (json) {
                                  return {
                                          NAME: "GroupType",
                                          VAL: Json_decode.string(json)
                                        };
                                }), param);
                  }),
                tl: /* [] */0
              }
            }
          }
        }
      }
    }
  }
};

var partial_arg$1 = {
  hd: partial_arg_0$1,
  tl: partial_arg_1
};

function parseItem(param) {
  return Json_decode.oneOf(partial_arg$1, param);
}

function partial_arg_0$2(param) {
  return Json_decode.field("branch", (function (json) {
                return {
                        NAME: "CollaborationItemBranch",
                        VAL: Json_decode.string(json)
                      };
              }), param);
}

var partial_arg_1$1 = {
  hd: (function (param) {
      return Json_decode.field("event", (function (json) {
                    return {
                            NAME: "CollaborationItemEvent",
                            VAL: Json_decode.string(json)
                          };
                  }), param);
    }),
  tl: /* [] */0
};

var partial_arg$2 = {
  hd: partial_arg_0$2,
  tl: partial_arg_1$1
};

function parseCollaborationItem(param) {
  return Json_decode.oneOf(partial_arg$2, param);
}

function partial_arg_0$3(param) {
  return Json_decode.field("property", (function (json) {
                return {
                        NAME: "Property",
                        VAL: Json_decode.string(json)
                      };
              }), param);
}

var partial_arg_1$2 = {
  hd: (function (param) {
      return Json_decode.field("event", (function (json) {
                    return {
                            NAME: "Event",
                            VAL: Json_decode.string(json)
                          };
                  }), param);
    }),
  tl: {
    hd: (function (param) {
        return Json_decode.field("eventObjectField", (function (json) {
                      return {
                              NAME: "EventObjectField",
                              VAL: [
                                Json_decode.field("eventId", Json_decode.string, json),
                                Json_decode.field("propertyId", Json_decode.string, json),
                                Json_decode.field("nestedPropertyId", Json_decode.string, json)
                              ]
                            };
                    }), param);
      }),
    tl: {
      hd: (function (param) {
          return Json_decode.field("propertyRef", (function (json) {
                        return {
                                NAME: "PropertyRef",
                                VAL: [
                                  Json_decode.field("eventId", Json_decode.string, json),
                                  Json_decode.field("propertyId", Json_decode.string, json)
                                ]
                              };
                      }), param);
        }),
      tl: /* [] */0
    }
  }
};

var partial_arg$3 = {
  hd: partial_arg_0$3,
  tl: partial_arg_1$2
};

function parseRuleItem(param) {
  return Json_decode.oneOf(partial_arg$3, param);
}

function parseRuleIdTuple(json) {
  return [
          Json_decode.field("fromRuleId", Json_decode.string, json),
          Json_decode.field("toRuleId", Json_decode.string, json)
        ];
}

function parseRuleIdTuples(json) {
  return Json_decode.array(parseRuleIdTuple, json);
}

function partial_arg_0$4(param) {
  return Json_decode.field("destinationId", (function (json) {
                return {
                        NAME: "Destination",
                        VAL: Json_decode.string(json)
                      };
              }), param);
}

var partial_arg_1$3 = {
  hd: (function (param) {
      return Json_decode.field("allDestinations", (function (param) {
                    return "AllDestinations";
                  }), param);
    }),
  tl: /* [] */0
};

var partial_arg$4 = {
  hd: partial_arg_0$4,
  tl: partial_arg_1$3
};

function parserMappingDestination(param) {
  return Json_decode.oneOf(partial_arg$4, param);
}

function partial_arg_0$5(param) {
  return Json_decode.field("workspaceId", (function (json) {
                return {
                        NAME: "Workspace",
                        VAL: Json_decode.string(json)
                      };
              }), param);
}

var partial_arg_1$4 = {
  hd: (function (param) {
      return Json_decode.field("allWorkspaces", (function (param) {
                    return "AllWorkspaces";
                  }), param);
    }),
  tl: /* [] */0
};

var partial_arg$5 = {
  hd: partial_arg_0$5,
  tl: partial_arg_1$4
};

function parseMappingWorkspace(param) {
  return Json_decode.oneOf(partial_arg$5, param);
}

function partial_arg_0$6(json) {
  return {
          destination: {
            NAME: "Destination",
            VAL: Json_decode.field("destinationId", Json_decode.string, json)
          },
          name: Json_decode.optional((function (param) {
                  return Json_decode.field("name", Json_decode.string, param);
                }), json)
        };
}

var partial_arg_1$5 = {
  hd: (function (json) {
      return {
              destination: Json_decode.optional((function (param) {
                      return Json_decode.field("destination", parserMappingDestination, param);
                    }), json),
              name: Json_decode.optional((function (param) {
                      return Json_decode.field("name", Json_decode.string, param);
                    }), json)
            };
    }),
  tl: /* [] */0
};

var partial_arg$6 = {
  hd: partial_arg_0$6,
  tl: partial_arg_1$5
};

function parseNameMapping(param) {
  return Json_decode.oneOf(partial_arg$6, param);
}

function parseWorkspaceNameMapping(json) {
  return {
          workspace: Json_decode.optional((function (param) {
                  return Json_decode.field("workspace", parseMappingWorkspace, param);
                }), json),
          name: Json_decode.optional((function (param) {
                  return Json_decode.field("name", Json_decode.string, param);
                }), json)
        };
}

function parseSegmentIntegrationOption(json) {
  return [
          Json_decode.field("key", Json_decode.string, json),
          Json_decode.field("enabled", Json_decode.bool, json)
        ];
}

function partial_arg_0$7(param) {
  return Json_decode.field("nameMapping", (function (json) {
                return {
                        NAME: "NameMapping",
                        VAL: parseNameMapping(json)
                      };
              }), param);
}

var partial_arg_1$6 = {
  hd: (function (param) {
      return Json_decode.field("segmentIntegrationOption", (function (json) {
                    return {
                            NAME: "SegmentIntegrationOption",
                            VAL: parseSegmentIntegrationOption(json)
                          };
                  }), param);
    }),
  tl: {
    hd: (function (param) {
        return Json_decode.field("pinnedValue", (function (json) {
                      return {
                              NAME: "PinnedValue",
                              VAL: parseLiteral(json)
                            };
                    }), param);
      }),
    tl: {
      hd: (function (param) {
          return Json_decode.field("nameInLocalWorkspace", (function (json) {
                        return {
                                NAME: "NameInLocalWorkspace",
                                VAL: parseWorkspaceNameMapping(json)
                              };
                      }), param);
        }),
      tl: /* [] */0
    }
  }
};

var partial_arg$7 = {
  hd: partial_arg_0$7,
  tl: partial_arg_1$6
};

function parseRuleDefinition(param) {
  return Json_decode.oneOf(partial_arg$7, param);
}

function parseRule(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          item: Json_decode.field("item", parseRuleItem, json),
          definition: Json_decode.field("definition", parseRuleDefinition, json)
        };
}

function partial_arg_0$8(json) {
  return {
          TAG: /* Protocols */0,
          _0: Json_decode.field("protocols", (function (json) {
                  return {
                          accessKey: Json_decode.optional((function (param) {
                                  return Json_decode.field("accessKey", Json_decode.string, param);
                                }), json),
                          trackingPlanId: Json_decode.optional((function (param) {
                                  return Json_decode.field("trackingPlanId", Json_decode.string, param);
                                }), json),
                          workspace: Json_decode.optional((function (param) {
                                  return Json_decode.field("workspace", Json_decode.string, param);
                                }), json),
                          usePublicApi: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                                      return Json_decode.field("usePublicApi", Json_decode.bool, param);
                                    }), json), false),
                          euDataResidency: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                                      return Json_decode.field("euDataResidency", Json_decode.bool, param);
                                    }), json), false)
                        };
                }), json)
        };
}

var partial_arg_1$7 = {
  hd: (function (json) {
      return {
              TAG: /* RudderstackTrackingPlans */6,
              _0: Json_decode.field("rudderstackTrackingPlans", (function (json) {
                      return {
                              accessToken: Json_decode.optional((function (param) {
                                      return Json_decode.field("accessToken", Json_decode.string, param);
                                    }), json),
                              trackingPlanDisplayName: Json_decode.optional((function (param) {
                                      return Json_decode.field("trackingPlanDisplayName", Json_decode.string, param);
                                    }), json),
                              emailAddress: Json_decode.optional((function (param) {
                                      return Json_decode.field("emailAddress", Json_decode.string, param);
                                    }), json)
                            };
                    }), json)
            };
    }),
  tl: {
    hd: (function (json) {
        return {
                TAG: /* Taxonomy */1,
                _0: Json_decode.field("taxonomy", (function (json) {
                        return {
                                apiKey: Json_decode.optional((function (param) {
                                        return Json_decode.field("apiKey", Json_decode.string, param);
                                      }), json),
                                secretKey: Json_decode.optional((function (param) {
                                        return Json_decode.field("secretKey", Json_decode.string, param);
                                      }), json),
                                euDataResidency: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                                            return Json_decode.field("euDataResidency", Json_decode.bool, param);
                                          }), json), false)
                              };
                      }), json)
              };
      }),
    tl: {
      hd: (function (json) {
          return {
                  TAG: /* Lexicon */2,
                  _0: Json_decode.field("lexicon", (function (json) {
                          return {
                                  userName: Json_decode.optional((function (param) {
                                          return Json_decode.field("userName", Json_decode.string, param);
                                        }), json),
                                  secretKey: Json_decode.optional((function (param) {
                                          return Json_decode.field("secretKey", Json_decode.string, param);
                                        }), json),
                                  projectId: Json_decode.optional((function (param) {
                                          return Json_decode.field("projectId", Json_decode.string, param);
                                        }), json),
                                  euDataResidency: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                                              return Json_decode.field("euDataResidency", Json_decode.bool, param);
                                            }), json), false),
                                  categoriesAsTags: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                                              return Json_decode.field("categoriesAsTags", Json_decode.bool, param);
                                            }), json), false)
                                };
                        }), json)
                };
        }),
      tl: {
        hd: (function (json) {
            return {
                    TAG: /* MParticleDataMaster */5,
                    _0: Json_decode.field("mParticleDataMaster", (function (json) {
                            return {
                                    clientId: Json_decode.optional((function (param) {
                                            return Json_decode.field("clientId", Json_decode.string, param);
                                          }), json),
                                    clientSecret: Json_decode.optional((function (param) {
                                            return Json_decode.field("clientSecret", Json_decode.string, param);
                                          }), json),
                                    workspaceId: Json_decode.optional((function (param) {
                                            return Json_decode.field("workspaceId", Json_decode.string, param);
                                          }), json),
                                    planId: Json_decode.optional((function (param) {
                                            return Json_decode.field("planId", Json_decode.string, param);
                                          }), json)
                                  };
                          }), json)
                  };
          }),
        tl: {
          hd: (function (json) {
              return {
                      TAG: /* Webhook */3,
                      _0: Json_decode.field("webhook", (function (json) {
                              return {
                                      webhookUrl: Json_decode.optional((function (param) {
                                              return Json_decode.field("webhookUrl", Json_decode.string, param);
                                            }), json),
                                      payloadFormat: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                                                  return Json_decode.field("payloadFormat", WebhookPayloadFormat.decode, param);
                                                }), json), /* JsonSchema */0)
                                    };
                            }), json)
                    };
            }),
          tl: {
            hd: (function (json) {
                return {
                        TAG: /* SnowplowDataStructures */4,
                        _0: Json_decode.field("snowplowDataStructures", (function (json) {
                                return {
                                        accessToken: Json_decode.optional((function (param) {
                                                return Json_decode.field("accessToken", Json_decode.string, param);
                                              }), json),
                                        organizationId: Json_decode.optional((function (param) {
                                                return Json_decode.field("organizationId", Json_decode.string, param);
                                              }), json),
                                        vendor: Json_decode.optional((function (param) {
                                                return Json_decode.field("vendor", Json_decode.string, param);
                                              }), json)
                                      };
                              }), json)
                      };
              }),
            tl: {
              hd: (function (json) {
                  return {
                          TAG: /* Taxonomy */1,
                          _0: {
                            apiKey: Json_decode.optional((function (param) {
                                    return Json_decode.field("apiKey", Json_decode.string, param);
                                  }), json),
                            secretKey: Json_decode.optional((function (param) {
                                    return Json_decode.field("secretKey", Json_decode.string, param);
                                  }), json),
                            euDataResidency: false
                          }
                        };
                }),
              tl: {
                hd: (function (json) {
                    return {
                            TAG: /* Protocols */0,
                            _0: {
                              accessKey: Json_decode.optional((function (param) {
                                      return Json_decode.field("accessKey", Json_decode.string, param);
                                    }), json),
                              trackingPlanId: Json_decode.optional((function (param) {
                                      return Json_decode.field("trackingPlanId", Json_decode.string, param);
                                    }), json),
                              workspace: Json_decode.optional((function (param) {
                                      return Json_decode.field("workspace", Json_decode.string, param);
                                    }), json),
                              usePublicApi: false,
                              euDataResidency: false
                            }
                          };
                  }),
                tl: /* [] */0
              }
            }
          }
        }
      }
    }
  }
};

var partial_arg$8 = {
  hd: partial_arg_0$8,
  tl: partial_arg_1$7
};

function parseIntegrationConfig(param) {
  return Json_decode.oneOf(partial_arg$8, param);
}

function partial_arg_0$9(json) {
  return {
          NAME: "Source",
          VAL: Json_decode.field("source", Json_decode.string, json)
        };
}

var partial_arg_1$8 = {
  hd: (function (json) {
      return {
              NAME: "Destination",
              VAL: Json_decode.field("destination", Json_decode.string, json)
            };
    }),
  tl: {
    hd: (function (json) {
        return {
                NAME: "Tag",
                VAL: Json_decode.field("tag", Json_decode.string, json)
              };
      }),
    tl: /* [] */0
  }
};

var partial_arg$9 = {
  hd: partial_arg_0$9,
  tl: partial_arg_1$8
};

function parseIntegrationFilter(param) {
  return Json_decode.oneOf(partial_arg$9, param);
}

function parseIntegrationFilters(json) {
  return Json_decode.list(parseIntegrationFilter, json);
}

function parseIntegration(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          name: Json_decode.optional((function (param) {
                  return Json_decode.field("name", Json_decode.string, param);
                }), json),
          config: Json_decode.optional((function (param) {
                  return Json_decode.field("config", parseIntegrationConfig, param);
                }), json),
          filters: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("filters", parseIntegrationFilters, param);
                    }), json), /* [] */0),
          autoPublish: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("autoPublish", Json_decode.bool, param);
                    }), json), false)
        };
}

function parseIntegrations(json) {
  return Json_decode.field("integrations", (function (param) {
                return Json_decode.list(parseIntegration, param);
              }), json);
}

function parsePropertyAbsenceUpdateType(json) {
  return Json_decode.oneOf({
              hd: (function (json) {
                  return {
                          TAG: /* EventSource */2,
                          _0: Json_decode.field("eventId", Json_decode.string, json),
                          _1: Json_decode.field("sourceId", Json_decode.string, json)
                        };
                }),
              tl: {
                hd: (function (json) {
                    return {
                            TAG: /* Event */0,
                            _0: Json_decode.field("eventId", Json_decode.string, json)
                          };
                  }),
                tl: {
                  hd: (function (json) {
                      return {
                              TAG: /* Source */1,
                              _0: Json_decode.field("sourceId", Json_decode.string, json)
                            };
                    }),
                  tl: {
                    hd: (function (param) {
                        return Json_decode.field("all", (function (_json) {
                                      return /* All */0;
                                    }), param);
                      }),
                    tl: /* [] */0
                  }
                }
              }
            }, json);
}

function parseEventSourceAbsence(json) {
  return Json_decode.oneOf({
              hd: (function (param) {
                  return Json_decode.field("sometimesSent", (function (sentWhenDescriptionJson) {
                                return /* SometimesSent */{
                                        _0: Json_decode.string(sentWhenDescriptionJson)
                                      };
                              }), param);
                }),
              tl: {
                hd: (function (param) {
                    return Json_decode.field("neverSent", (function (_json) {
                                  return /* NeverSent */0;
                                }), param);
                  }),
                tl: /* [] */0
              }
            }, json);
}

function parsePropertyAbsenceEventSourceMap(json) {
  return Json_decode.oneOf({
              hd: (function (param) {
                  return Json_decode.field("allSources", (function (json) {
                                return {
                                        TAG: /* AllSources */0,
                                        _0: parseEventSourceAbsence(json)
                                      };
                              }), param);
                }),
              tl: {
                hd: (function (param) {
                    return Json_decode.field("mixedSources", (function (json) {
                                  return {
                                          TAG: /* MixedSources */1,
                                          _0: Belt_MapString.fromArray(Js_dict.entries(Json_decode.dict(parseEventSourceAbsence, json)))
                                        };
                                }), param);
                  }),
                tl: /* [] */0
              }
            }, json);
}

function parsePropertyAbsenceEventMap(json) {
  return Belt_MapString.fromArray(Js_dict.entries(Json_decode.dict(parsePropertyAbsenceEventSourceMap, json)));
}

function parsePropertyAbsence(json) {
  var dict = Js_json.classify(json);
  if (typeof dict === "number") {
    return Pervasives.failwith("parsePropertyAbsence expected object but got " + JSON.stringify(json));
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Pervasives.failwith("parsePropertyAbsence expected object but got " + JSON.stringify(json));
  }
  var match = Belt_Array.getExn(Js_dict.entries(dict._0), 0);
  var json$1 = match[1];
  var key = match[0];
  switch (key) {
    case "alwaysSent" :
        return /* AlwaysSent */0;
    case "mixed" :
        return {
                TAG: /* Mixed */1,
                _0: parsePropertyAbsenceEventMap(json$1)
              };
    case "sometimesSent" :
        return {
                TAG: /* SometimesSent */0,
                _0: Json_decode.string(json$1)
              };
    default:
      return Pervasives.failwith("Unexpected property absence key: " + key);
  }
}

function parseDisallowedState(json) {
  return Json_decode.oneOf({
              hd: (function (param) {
                  return Json_decode.field("disallowed", (function (_json) {
                                return /* Disallowed */0;
                              }), param);
                }),
              tl: {
                hd: (function (param) {
                    return Json_decode.field("disallowedSources", (function (json) {
                                  return /* DisallowedSources */{
                                          _0: Belt_SetString.fromArray(Json_decode.array(Json_decode.string, json))
                                        };
                                }), param);
                  }),
                tl: /* [] */0
              }
            }, json);
}

function parseDisallowedEvents(json) {
  var dict = Js_json.classify(json);
  if (typeof dict === "number" || dict.TAG !== /* JSONObject */2) {
    return ;
  } else {
    return Belt_MapString.fromArray(Belt_Array.mapU(Js_dict.entries(dict._0), (function (param) {
                      var disallowedState = parseDisallowedState(param[1]);
                      return [
                              param[0],
                              disallowedState
                            ];
                    })));
  }
}

function parseEventSpecificPropertyValues(json) {
  var propertyValueMatchesArray = Js_json.classify(json);
  if (typeof propertyValueMatchesArray === "number") {
    return Pervasives.failwith("parseEventSpecificPropertyValues expected array but got " + JSON.stringify(json));
  } else if (propertyValueMatchesArray.TAG === /* JSONArray */3) {
    return Belt_Array.map(propertyValueMatchesArray._0, (function (propertyValueMatchJson) {
                  var literal = Json_decode.field("matchLiteral", parseLiteral, propertyValueMatchJson);
                  var propertyValueDisallowedEvents = Json_decode.field("disallowedEvents", parseDisallowedEvents, propertyValueMatchJson);
                  return [
                          literal,
                          propertyValueDisallowedEvents
                        ];
                }));
  } else {
    return Pervasives.failwith("parseEventSpecificPropertyValues expected array but got " + JSON.stringify(json));
  }
}

function parsePropertyOptional(json) {
  return Json_decode.field("optional", Json_decode.bool, json);
}

function parsePropertyOptionalWhenInObject(json) {
  return Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                    return Json_decode.field("optionalWhenInObject", Json_decode.bool, param);
                  }), json), Json_decode.field("optional", Json_decode.bool, json));
}

function parsePropertyExcludedSources(json) {
  return Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                    return Json_decode.field("excludedSources", (function (param) {
                                  return Json_decode.list(Json_decode.string, param);
                                }), param);
                  }), json), /* [] */0);
}

function parseSendAs(parentJson) {
  var dict = Js_json.classify(parentJson);
  if (typeof dict !== "number" && dict.TAG === /* JSONObject */2) {
    var match = Belt_Array.getExn(Js_dict.entries(dict._0), 0);
    var key = match[0];
    if (key === "group") {
      return /* GroupProperty */{
              _0: Json_decode.string(match[1])
            };
    } else {
      return Pervasives.failwith("parseSendAs expected group key but got " + key);
    }
  }
  var unexpectedSendAs = Json_decode.string(parentJson);
  switch (unexpectedSendAs) {
    case "EventProperty" :
    case "event" :
        return /* EventProperty */1;
    case "SystemProperty" :
    case "system" :
        return /* SystemProperty */0;
    case "UserProperty" :
    case "user" :
        return /* UserProperty */2;
    default:
      return Pervasives.failwith("unexpected sendAs: " + unexpectedSendAs);
  }
}

function parseGlobalMetadata(json) {
  var _jsonDict = Js_json.classify(json);
  if (typeof _jsonDict === "number") {
    if (_jsonDict === /* JSONNull */2) {
      return ;
    } else {
      return Pervasives.failwith("parseGlobalMetadata expected object but got " + JSON.stringify(json));
    }
  } else if (_jsonDict.TAG === /* JSONObject */2) {
    return {
            fromLocalItemId: Json_decode.field("fromLocalItemId", Json_decode.string, json),
            globalWorkspaceId: Json_decode.field("globalWorkspaceId", Json_decode.string, json)
          };
  } else {
    return Pervasives.failwith("parseGlobalMetadata expected object but got " + JSON.stringify(json));
  }
}

function parseTrackingPlanProperty(json) {
  var other = Json_decode.optional((function (__x) {
          return Json_decode.field("uniqueName", Json_decode.string, __x);
        }), json);
  var tmp;
  tmp = other === "" ? undefined : other;
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          uniqueName: tmp,
          description: Json_decode.field("description", Json_decode.string, json),
          type_: Json_decode.field("type", Json_decode.string, json),
          sendAs: Json_decode.field("sendAs", parseSendAs, json),
          validations: Belt_List.fromArray(Json_decode.field("validations", (function (param) {
                      return Json_decode.array(parseValidation, param);
                    }), json)),
          eventSpecificAllowedPropertyValues: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("eventSpecificAllowedPropertyValues", parseEventSpecificPropertyValues, param);
                    }), json), []),
          auto: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("auto", Json_decode.bool, param);
                    }), json), false),
          builtIn: Belt_Option.getWithDefault(Belt_Option.flatMap(Json_decode.optional((function (param) {
                          return Json_decode.field("builtIn", Json_decode.string, param);
                        }), json), TrackingPlanModel.builtInTypesFromJs), "NotBuiltIn"),
          analyticsTools: Belt_Option.mapWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("analyticsTools", (function (param) {
                                    return Json_decode.array(Json_decode.string, param);
                                  }), param);
                    }), json), /* [] */0, (function (tools) {
                  return Belt_List.fromArray(Belt_Array.map(tools, (function (t) {
                                    return Belt_Option.getExn(AvoConfigTypes.analyticsToolFromJs(t));
                                  })));
                })),
          devPlatforms: Belt_Option.mapWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("devPlatforms", (function (param) {
                                    return Json_decode.array(Json_decode.string, param);
                                  }), param);
                    }), json), /* [] */0, (function (tools) {
                  return Belt_List.fromArray(Belt_Array.map(tools, (function (t) {
                                    return Belt_Option.getExn(AvoConfigTypes.devPlatformFromJs(t));
                                  })));
                })),
          list: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("list", Json_decode.bool, param);
                    }), json), false),
          operation: Belt_Option.getWithDefault(Belt_Option.flatMap(Json_decode.optional((function (param) {
                          return Json_decode.field("operation", Json_decode.string, param);
                        }), json), TrackingPlanModel.operationFromJs), "Set"),
          optionalDeprecated: Json_decode.field("optional", Json_decode.bool, json),
          excludedSourcesDeprecated: parsePropertyExcludedSources(json),
          optionalWhenInObject: parsePropertyOptionalWhenInObject(json),
          absence: Json_decode.optional((function (param) {
                  return Json_decode.field("absence", parsePropertyAbsence, param);
                }), json),
          globalRequirementsMetadata: Belt_Option.flatMap(Json_decode.optional((function (param) {
                      return Json_decode.field("globalRequirementsMetadata", parseGlobalMetadata, param);
                    }), json), (function (x) {
                  return x;
                }))
        };
}

function parseProperty(json) {
  var jsonDict = Js_json.classify(json);
  if (typeof jsonDict === "number") {
    return Pervasives.failwith("parseProperty expected object but got " + JSON.stringify(json));
  }
  if (jsonDict.TAG !== /* JSONObject */2) {
    return Pervasives.failwith("parseProperty expected object but got " + JSON.stringify(json));
  }
  var jsonDict$1 = jsonDict._0;
  if (Belt_Option.isSome(Js_dict.get(jsonDict$1, "name")) && Belt_Option.isSome(Js_dict.get(jsonDict$1, "type"))) {
    return {
            TAG: /* Property */1,
            _0: parseTrackingPlanProperty(json)
          };
  } else {
    return {
            TAG: /* PropertyRef */0,
            _0: parsePropertyRef(json)
          };
  }
}

function parseProperties(json) {
  return Belt_List.fromArray(Js_dict.values(Json_decode.field("properties", (function (param) {
                        return Json_decode.dict(parseProperty, param);
                      }), json)));
}

function parsePropertyBundleRef(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          description: Json_decode.field("description", Json_decode.string, json)
        };
}

function parsePropertyBundleRefs(json) {
  return Belt_List.fromArray(Js_dict.values(Belt_Option.getWithDefault(Json_decode.optional((function (__x) {
                            return Json_decode.field("propertyGroups", (function (param) {
                                          return Json_decode.dict(parsePropertyBundleRef, param);
                                        }), __x);
                          }), json), {})));
}

function parsePropertyGroup(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          description: Json_decode.field("description", Json_decode.string, json),
          properties: Json_decode.field("properties", (function (param) {
                  return Json_decode.list(Json_decode.string, param);
                }), json),
          globalRequirementsMetadata: Belt_Option.flatMap(Json_decode.optional((function (param) {
                      return Json_decode.field("globalRequirementsMetadata", parseGlobalMetadata, param);
                    }), json), (function (x) {
                  return x;
                }))
        };
}

function parsePropertyGroups(json) {
  var bundles = Json_decode.optional((function (__x) {
          return Json_decode.field("propertyBundles", (function (param) {
                        return Json_decode.dict(parsePropertyGroup, param);
                      }), __x);
        }), json);
  var groups = Json_decode.optional((function (__x) {
          return Json_decode.field("propertyGroups", (function (param) {
                        return Json_decode.dict(parsePropertyGroup, param);
                      }), __x);
        }), json);
  return Belt_List.fromArray(Js_dict.values(groups !== undefined ? Caml_option.valFromOption(groups) : (
                    bundles !== undefined ? Caml_option.valFromOption(bundles) : ({})
                  )));
}

function parsePropertyBundlesWithBundlesInJson(json) {
  var bundles = Json_decode.optional((function (__x) {
          return Json_decode.field("propertyBundles", (function (param) {
                        return Json_decode.dict(parsePropertyGroup, param);
                      }), __x);
        }), json);
  var groups = Json_decode.optional((function (__x) {
          return Json_decode.field("propertyGroups", (function (param) {
                        return Json_decode.dict(parsePropertyGroup, param);
                      }), __x);
        }), json);
  return Belt_List.fromArray(Js_dict.values(bundles !== undefined ? Caml_option.valFromOption(bundles) : (
                    groups !== undefined ? Caml_option.valFromOption(groups) : ({})
                  )));
}

function parseEventHash(json) {
  return /* Hash */{
          _0: Json_decode.field("sourceId", Json_decode.string, json),
          _1: Json_decode.field("hash", Json_decode.string, json)
        };
}

function parsePropertyWhitelist(json) {
  return [
          Belt_Option.getExn(AvoConfigTypes.analyticsToolFromJs(Json_decode.field("analyticsPlatform", Json_decode.string, json))),
          Json_decode.field("propertyId", Json_decode.string, json)
        ];
}

function parseSourceDestinationRefs(json) {
  return {
          NAME: "IncludeSourceDestination",
          VAL: [
            Json_decode.field("sourceId", Json_decode.string, json),
            Json_decode.field("destinationIds", (function (param) {
                    return Json_decode.list(Json_decode.string, param);
                  }), json)
          ]
        };
}

function parseInspectorValidation(validation) {
  var match = Belt_List.fromArray(validation.split("-"));
  if (!match) {
    return Pervasives.failwith("parseInspectorValidation expected object but got " + validation);
  }
  var value = match.hd;
  var key = Belt_List.toArray(match.tl).join("-");
  switch (value) {
    case "currentVersion" :
        return {
                NAME: "currentVersion",
                VAL: key
              };
    case "customTime" :
        return {
                NAME: "customTime",
                VAL: new Date(key)
              };
    case "customVersion" :
        return {
                NAME: "customVersion",
                VAL: key
              };
    case "nextVersion" :
        return {
                NAME: "nextVersion",
                VAL: key
              };
    case "oneHourAfterMerge" :
        return {
                NAME: "oneHourAfterMerge",
                VAL: new Date(key)
              };
    case "twentyFourHoursAfterMerge" :
        return {
                NAME: "twentyFourHoursAfterMerge",
                VAL: new Date(key)
              };
    default:
      return Pervasives.failwith("unexpected validation type: " + value);
  }
}

function parseSourceValidationMap(json) {
  var sourceId = Json_decode.field("sourceId", Json_decode.string, json);
  var validation = Json_decode.optional((function (param) {
          return Json_decode.field("validation", (function (json) {
                        return parseInspectorValidation(Json_decode.string(json));
                      }), param);
        }), json);
  return [
          sourceId,
          validation
        ];
}

function parseIncludedSource(json) {
  var jsonDict = Js_json.classify(json);
  if (typeof jsonDict === "number" || jsonDict.TAG !== /* JSONObject */2) {
    return {
            id: Json_decode.string(json),
            includeInCodegen: true,
            inspectorValidation: undefined
          };
  } else {
    return {
            id: Json_decode.field("sourceId", Json_decode.string, json),
            includeInCodegen: Json_decode.field("includeInCodegen", Json_decode.bool, json),
            inspectorValidation: Belt_Option.flatMap(Js_dict.get(jsonDict._0, "inspectorValidation"), (function (json) {
                    var string = Js_json.classify(json);
                    if (typeof string === "number" || string.TAG !== /* JSONString */0) {
                      return ;
                    } else {
                      return parseInspectorValidation(string._0);
                    }
                  }))
          };
  }
}

function parseImageMetadata(json) {
  return {
          url: Json_decode.field("url", Json_decode.string, json),
          storagePath: Json_decode.field("storagePath", Json_decode.string, json),
          width: Json_decode.field("width", Json_decode.$$int, json),
          height: Json_decode.field("height", Json_decode.$$int, json)
        };
}

function parseFigmaMetadata(json) {
  return {
          url: Json_decode.field("url", Json_decode.string, json)
        };
}

function parseTriggerContent(json) {
  return Json_decode.oneOf({
              hd: (function (param) {
                  return Json_decode.field("Image", (function (json) {
                                return {
                                        TAG: /* Image */0,
                                        _0: parseImageMetadata(json)
                                      };
                              }), param);
                }),
              tl: {
                hd: (function (param) {
                    return Json_decode.field("Figma", (function (json) {
                                  return {
                                          TAG: /* Figma */1,
                                          _0: parseFigmaMetadata(json)
                                        };
                                }), param);
                  }),
                tl: /* [] */0
              }
            }, json);
}

function parseTriggerSources(json) {
  var dict = Js_json.classify(json);
  if (typeof dict !== "number") {
    switch (dict.TAG | 0) {
      case /* JSONString */0 :
          if (dict._0 === "SourceIndependent") {
            return /* SourceIndependent */0;
          }
          break;
      case /* JSONObject */2 :
          var match = Belt_Array.getExn(Js_dict.entries(dict._0), 0);
          var key = match[0];
          if (key === "SourceIds") {
            return /* SourceIds */{
                    _0: Json_decode.array(Json_decode.string, match[1])
                  };
          } else {
            return Pervasives.failwith("unexpected source: " + key);
          }
      default:
        
    }
  }
  return Pervasives.failwith("parseTriggerSources expected object but got " + JSON.stringify(json));
}

function parseEventTrigger(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          sources: Json_decode.field("sources", parseTriggerSources, json),
          description: Json_decode.field("description", Json_decode.string, json),
          content: Json_decode.field("content", (function (param) {
                  return Json_decode.optional(parseTriggerContent, param);
                }), json)
        };
}

function parseEventTriggers(json) {
  return Json_decode.array(parseEventTrigger, json);
}

function parseTriggerIdTuple(json) {
  return [
          Json_decode.field("fromTriggerId", Json_decode.string, json),
          Json_decode.field("toTriggerId", Json_decode.string, json)
        ];
}

function parseTriggerIdTuples(json) {
  return Json_decode.array(parseTriggerIdTuple, json);
}

function parseVariantPropertyAbsenceOverride(maybeAbsence) {
  if (maybeAbsence === undefined) {
    return /* NoneOverride */0;
  }
  var unexpectedAbsence = Json_decode.string(Caml_option.valFromOption(maybeAbsence));
  var tmp;
  switch (unexpectedAbsence) {
    case "AlwaysSent" :
        tmp = /* AlwaysSent */0;
        break;
    case "NeverSent" :
        tmp = /* NeverSent */2;
        break;
    case "SometimesSent" :
        tmp = /* SometimesSent */1;
        break;
    default:
      tmp = Pervasives.failwith("unexpected absence: " + unexpectedAbsence);
  }
  return /* SomeOverride */{
          _0: tmp
        };
}

function parseVariantPropertyAllowedValuesOverride(maybePropertyAllowedValues) {
  if (maybePropertyAllowedValues === undefined) {
    return /* NoneOverride */0;
  }
  var dict = Js_json.classify(Caml_option.valFromOption(maybePropertyAllowedValues));
  if (typeof dict === "number" || dict.TAG !== /* JSONObject */2) {
    return Pervasives.failwith("Wrong format for allowedValuesOverride");
  } else {
    return /* SomeOverride */{
            _0: Belt_MapString.fromArray(Belt_Array.mapU(Js_dict.entries(dict._0), (function (param) {
                        return [
                                param[0],
                                parseDisallowedState(param[1])
                              ];
                      })))
          };
  }
}

function parseVariantPropertyOverride(json) {
  var dict = Js_json.classify(json);
  if (typeof dict === "number") {
    if (dict === /* JSONNull */2) {
      return /* Removed */0;
    } else {
      return Pervasives.failwith("parseVariantPropertyOverride expected object but got " + JSON.stringify(json));
    }
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Pervasives.failwith("parseVariantPropertyOverride expected object but got " + JSON.stringify(json));
  }
  var dict$1 = dict._0;
  var literalJson = Js_dict.get(dict$1, "pinnedValue");
  var tmp;
  if (literalJson !== undefined) {
    var literalJson$1 = Caml_option.valFromOption(literalJson);
    var match = Js_json.classify(literalJson$1);
    tmp = typeof match === "number" && match >= 2 ? /* SomeOverride */({
          _0: undefined
        }) : /* SomeOverride */({
          _0: parseLiteral(literalJson$1)
        });
  } else {
    tmp = /* NoneOverride */0;
  }
  var regexValidationJson = Js_dict.get(dict$1, "regexValidation");
  var tmp$1;
  if (regexValidationJson !== undefined) {
    var regexValidationJson$1 = Caml_option.valFromOption(regexValidationJson);
    var match$1 = Js_json.classify(regexValidationJson$1);
    tmp$1 = typeof match$1 === "number" && match$1 >= 2 ? /* SomeOverride */({
          _0: undefined
        }) : /* SomeOverride */({
          _0: parseRegexValidationRule(regexValidationJson$1)
        });
  } else {
    tmp$1 = /* NoneOverride */0;
  }
  return /* Overridden */{
          _0: {
            pinnedValue: tmp,
            absence: parseVariantPropertyAbsenceOverride(Js_dict.get(dict$1, "absence")),
            allowedValues: parseVariantPropertyAllowedValuesOverride(Js_dict.get(dict$1, "allowedValues")),
            regexValidation: tmp$1
          }
        };
}

function parseVariantSourceOverride(json) {
  var dict = Js_json.classify(json);
  if (typeof dict === "number") {
    if (dict === /* JSONNull */2) {
      return /* Removed */0;
    } else {
      return Pervasives.failwith("parseVariantSourceOverride expected object but got " + JSON.stringify(json));
    }
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Pervasives.failwith("parseVariantSourceOverride expected object but got " + JSON.stringify(json));
  }
  var boolJson = Js_dict.get(dict._0, "includeInCodegen");
  return /* Overridden */{
          _0: {
            includeInCodegen: boolJson !== undefined ? /* SomeOverride */({
                  _0: Json_decode.bool(Caml_option.valFromOption(boolJson))
                }) : /* NoneOverride */0
          }
        };
}

function parseEventVariant(json) {
  var jsonDict = Js_json.classify(json);
  if (typeof jsonDict === "number") {
    return Pervasives.failwith("parseEventVariant expected object but got " + JSON.stringify(json));
  }
  if (jsonDict.TAG !== /* JSONObject */2) {
    return Pervasives.failwith("parseEventVariant expected object but got " + JSON.stringify(json));
  }
  var jsonDict$1 = jsonDict._0;
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          baseEventId: Json_decode.field("baseEventId", Json_decode.string, json),
          nameSuffix: Json_decode.field("nameSuffix", Json_decode.string, json),
          description: Json_decode.field("description", Json_decode.string, json),
          propertyOverrides: Belt_MapString.fromArray(Js_dict.entries(Json_decode.field("propertyOverrides", (function (param) {
                          return Json_decode.dict(parseVariantPropertyOverride, param);
                        }), json))),
          sourceOverrides: Belt_MapString.fromArray(Js_dict.entries(Belt_Option.getWithDefault(decodeOptionalKey("sourceOverrides", (function (param) {
                              return Json_decode.dict(parseVariantSourceOverride, param);
                            }), jsonDict$1), {}))),
          triggers: Belt_Option.getWithDefault(decodeOptionalKey("triggers", parseEventTriggers, jsonDict$1), [])
        };
}

function parseEvent(json) {
  var jsonDict = Js_json.classify(json);
  if (typeof jsonDict === "number") {
    return Pervasives.failwith("parseEvent expected object but got " + JSON.stringify(json));
  }
  if (jsonDict.TAG !== /* JSONObject */2) {
    return Pervasives.failwith("parseEvent expected object but got " + JSON.stringify(json));
  }
  var jsonDict$1 = jsonDict._0;
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          uniqueName: Belt_Option.flatMap(Js_dict.get(jsonDict$1, "uniqueName"), (function (json) {
                  var other = Json_decode.string(json);
                  if (other === "") {
                    return ;
                  } else {
                    return other;
                  }
                })),
          description: Json_decode.field("description", Json_decode.string, json),
          properties: Belt_List.keepU(parseProperties(json), (function (property) {
                  if (property.TAG === /* PropertyRef */0) {
                    return true;
                  } else {
                    return false;
                  }
                })),
          propertyBundles: parsePropertyBundleRefs(json),
          variants: Belt_Option.getWithDefault(decodeOptionalKey("variants", (function (param) {
                      return Json_decode.array(parseEventVariant, param);
                    }), jsonDict$1), []),
          types: Belt_List.fromArray(Belt_Option.getWithDefault(decodeOptionalKey("types", (function (param) {
                          return Json_decode.array((function (json) {
                                        return Belt_Option.getExn(TrackingPlanModel.eventTypeFromJs(Json_decode.string(json)));
                                      }), param);
                        }), jsonDict$1), [])),
          tags: Belt_List.fromArray(Belt_Option.getWithDefault(decodeOptionalKey("tags", (function (param) {
                          return Json_decode.array(Json_decode.string, param);
                        }), jsonDict$1), [])),
          excludeSourcesDeprecated: Belt_Option.mapWithDefault(decodeOptionalKey("excludeSources", (function (param) {
                      return Json_decode.array(Json_decode.string, param);
                    }), jsonDict$1), /* [] */0, Belt_List.fromArray),
          includeSources: Belt_Option.getWithDefault(decodeOptionalKey("includeSources", (function (param) {
                      return Json_decode.list(parseIncludedSource, param);
                    }), jsonDict$1), /* [] */0),
          includeDestinations: Belt_Option.mapWithDefault(decodeOptionalKey("includeDestinations", (function (param) {
                      return Json_decode.array(parseSourceDestinationRefs, param);
                    }), jsonDict$1), /* [] */0, Belt_List.fromArray),
          hashes: Belt_Option.getWithDefault(decodeOptionalKey("hashes", (function (param) {
                      return Json_decode.list(parseEventHash, param);
                    }), jsonDict$1), /* [] */0),
          propertyWhitelist: Belt_Option.getWithDefault(decodeOptionalKey("propertyWhitelist", (function (param) {
                      return Json_decode.list(parsePropertyWhitelist, param);
                    }), jsonDict$1), /* [] */0),
          eventGroupTypeIdsWithArchive: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("eventGroupTypeIds", (function (param) {
                                    return Json_decode.array(Json_decode.string, param);
                                  }), param);
                    }), json), []),
          userGroupTypeIdsWithArchive: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("userGroupTypeIds", (function (param) {
                                    return Json_decode.array(Json_decode.string, param);
                                  }), param);
                    }), json), []),
          triggers: Belt_Option.getWithDefault(decodeOptionalKey("triggers", parseEventTriggers, jsonDict$1), []),
          globalRequirementsMetadata: Belt_Option.flatMap(decodeOptionalKey("globalRequirementsMetadata", parseGlobalMetadata, jsonDict$1), (function (x) {
                  return x;
                }))
        };
}

function parseEventIdTuple(json) {
  return [
          Json_decode.field("fromEventId", Json_decode.string, json),
          Json_decode.field("toEventId", Json_decode.string, json)
        ];
}

function parseType(param) {
  return Json_decode.field("type", (function (json) {
                var match = Json_decode.string(json);
                switch (match) {
                  case "any" :
                      return {
                              TAG: /* BuiltInType */0,
                              _0: "Any"
                            };
                  case "bool" :
                      return {
                              TAG: /* BuiltInType */0,
                              _0: "Bool"
                            };
                  case "float" :
                      return {
                              TAG: /* BuiltInType */0,
                              _0: "Float"
                            };
                  case "int" :
                      return {
                              TAG: /* BuiltInType */0,
                              _0: "Int"
                            };
                  case "long" :
                      return {
                              TAG: /* BuiltInType */0,
                              _0: "Long"
                            };
                  case "string" :
                      return {
                              TAG: /* BuiltInType */0,
                              _0: "String"
                            };
                  default:
                    return Pervasives.failwith("unexpected type");
                }
              }), param);
}

function parseEvents(json) {
  return Belt_List.fromArray(Js_dict.values(Json_decode.field("events", (function (param) {
                        return Json_decode.dict(parseEvent, param);
                      }), json)));
}

function parseMigration(json) {
  return Belt_Option.getExn(TrackingPlanModel.migrationFromJs(Json_decode.string(json)));
}

function parseMigrations(json) {
  return Belt_List.fromArray(Json_decode.field("migrations", (function (param) {
                    return Json_decode.array(parseMigration, param);
                  }), json));
}

function parseSegmentDestinationOption(json) {
  return [
          Json_decode.field("key", Json_decode.string, json),
          Json_decode.field("enabled", Json_decode.bool, json)
        ];
}

function parseLegacySource(json) {
  var sourceLanguage = Belt_Option.flatMap(Json_decode.optional((function (param) {
              return Json_decode.field("language", Json_decode.string, param);
            }), json), AvoConfigTypes.languageFromJs);
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          name: Json_decode.optional((function (param) {
                  return Json_decode.field("name", Json_decode.string, param);
                }), json),
          language: sourceLanguage,
          platform: Belt_Option.flatMap(Json_decode.optional((function (param) {
                      return Json_decode.field("platform", Json_decode.string, param);
                    }), json), AvoConfigTypes.devPlatformFromJs),
          destinations: Belt_Option.mapWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("destinations", (function (param) {
                                    return Json_decode.dict(Json_decode.bool, param);
                                  }), param);
                    }), json), /* [] */0, (function (dict) {
                  return Belt_List.fromArray(Belt_Array.keepMap(Js_dict.entries(dict), (function (param) {
                                    if (param[1]) {
                                      return {
                                              destinationId: param[0],
                                              destinationMode: /* Legacy */2
                                            };
                                    }
                                    
                                  })));
                })),
          filename: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("filename", Json_decode.string, param);
                    }), json), AvoConfig.defaultFilename),
          path: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("path", Json_decode.string, param);
                    }), json), AvoConfig.defaultPath),
          segmentDestinationOptions: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("segmentDestinationOptions", (function (param) {
                                    return Json_decode.list(parseSegmentDestinationOption, param);
                                  }), param);
                    }), json), /* [] */0),
          config: /* [] */0
        };
}

function parseLegacySources(json) {
  return Belt_List.fromArray(Json_decode.field("sources", (function (param) {
                    return Json_decode.array(parseLegacySource, param);
                  }), json));
}

function parseSourceConfig(json) {
  return Json_decode.oneOf({
              hd: (function (param) {
                  return Json_decode.field("ValidateAvoEventFunction", (function (param) {
                                return "ValidateAvoEventFunction";
                              }), param);
                }),
              tl: {
                hd: (function (param) {
                    return Json_decode.field("IosSwiftUseAmplitudeOver5", (function (param) {
                                  return "IosSwiftUseAmplitudeOver5";
                                }), param);
                  }),
                tl: /* [] */0
              }
            }, json);
}

function parseSourceDestinationMode(json) {
  var other = Json_decode.string(json);
  switch (other) {
    case "AvoManaged" :
        return /* AvoManaged */0;
    case "DestinationInterface" :
        return /* DestinationInterface */1;
    case "Legacy" :
        return /* Legacy */2;
    default:
      return Pervasives.failwith("Unexpected destinationMode in source<>destination: " + other);
  }
}

function parseSourceDestination(json) {
  return Json_decode.oneOf({
              hd: (function (json) {
                  return {
                          destinationId: Json_decode.field("destinationId", Json_decode.string, json),
                          destinationMode: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                                      return Json_decode.field("destinationMode", parseSourceDestinationMode, param);
                                    }), json), /* Legacy */2)
                        };
                }),
              tl: {
                hd: (function (json) {
                    return {
                            destinationId: Json_decode.string(json),
                            destinationMode: /* Legacy */2
                          };
                  }),
                tl: /* [] */0
              }
            }, json);
}

function parseSource(json) {
  var sourceLanguage = Belt_Option.flatMap(Json_decode.optional((function (param) {
              return Json_decode.field("language", Json_decode.string, param);
            }), json), AvoConfigTypes.languageFromJs);
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          name: Json_decode.optional((function (param) {
                  return Json_decode.field("name", Json_decode.string, param);
                }), json),
          language: sourceLanguage,
          platform: Belt_Option.flatMap(Json_decode.optional((function (param) {
                      return Json_decode.field("platform", Json_decode.string, param);
                    }), json), AvoConfigTypes.devPlatformFromJs),
          destinations: Json_decode.field("destinations", (function (param) {
                  return Json_decode.list(parseSourceDestination, param);
                }), json),
          filename: Json_decode.field("filename", Json_decode.string, json),
          path: Json_decode.field("path", Json_decode.string, json),
          segmentDestinationOptions: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("segmentDestinationOptions", (function (param) {
                                    return Json_decode.list(parseSegmentDestinationOption, param);
                                  }), param);
                    }), json), /* [] */0),
          config: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("config", (function (param) {
                                    return Json_decode.list(parseSourceConfig, param);
                                  }), param);
                    }), json), /* [] */0)
        };
}

function parseSources(json) {
  return Belt_List.fromArray(Json_decode.field("sources", (function (param) {
                    return Json_decode.array(parseSource, param);
                  }), json));
}

function parseOtherEnvKey(json) {
  return [
          Json_decode.field("envName", Json_decode.string, json),
          Json_decode.field("apiKey", Json_decode.string, json)
        ];
}

function parseGroupType(json) {
  return {
          id: Json_decode.field("groupTypeId", Json_decode.string, json),
          name: Json_decode.field("groupTypeName", Json_decode.string, json),
          description: Json_decode.field("groupTypeDescription", Json_decode.string, json)
        };
}

function parseDestination(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          name: Json_decode.optional((function (param) {
                  return Json_decode.field("name", Json_decode.string, param);
                }), json),
          type_: Belt_Option.flatMap(Json_decode.optional((function (param) {
                      return Json_decode.field("type_", Json_decode.string, param);
                    }), json), AvoConfigTypes.analyticsToolFromJs),
          apiKeys: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("apiKeys", (function (json) {
                                    return {
                                            dev: Json_decode.optional((function (param) {
                                                    return Json_decode.field("dev", Json_decode.string, param);
                                                  }), json),
                                            prod: Json_decode.optional((function (param) {
                                                    return Json_decode.field("prod", Json_decode.string, param);
                                                  }), json),
                                            other: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                                                        return Json_decode.field("other", (function (param) {
                                                                      return Json_decode.list(parseOtherEnvKey, param);
                                                                    }), param);
                                                      }), json), /* [] */0)
                                          };
                                  }), param);
                    }), json), {
                dev: undefined,
                prod: undefined,
                other: /* [] */0
              }),
          includeUserPropsWithEventProps: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("includeUserPropsWithEventProps", Json_decode.bool, param);
                    }), json), false),
          disabledByDefault: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("disabledByDefault", Json_decode.bool, param);
                    }), json), false)
        };
}

function parseDestinations(json) {
  return Belt_List.fromArray(Json_decode.field("destinations", (function (param) {
                    return Json_decode.array(parseDestination, param);
                  }), json));
}

function parseGroupTypes(json) {
  return Json_decode.field("groupTypes", (function (param) {
                return Json_decode.array(parseGroupType, param);
              }), json);
}

function parseGoal(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          description: Json_decode.field("description", Json_decode.string, json),
          metrics: Json_decode.field("metrics", (function (param) {
                  return Json_decode.list(Json_decode.string, param);
                }), json),
          events: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("events", (function (param) {
                                    return Json_decode.list(Json_decode.string, param);
                                  }), param);
                    }), json), /* [] */0)
        };
}

function parseGoals(json) {
  return Json_decode.field("goals", (function (param) {
                return Json_decode.list(parseGoal, param);
              }), json);
}

function parseMetricEventItemWhere(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          propertyId: Json_decode.field("propertyId", Json_decode.string, json),
          binOp: Json_decode.field("binOp", parseBinOp, json),
          literals: Json_decode.field("literals", (function (param) {
                  return Json_decode.list(parseLiteral, param);
                }), json)
        };
}

function parseMetricEventItemGroupBy(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          propertyId: Json_decode.field("propertyId", Json_decode.string, json)
        };
}

function parseMetricEventItem(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          eventId: Json_decode.field("eventId", Json_decode.string, json),
          where: Json_decode.field("where", (function (param) {
                  return Json_decode.list(parseMetricEventItemWhere, param);
                }), json),
          groupBy: Json_decode.field("groupBy", (function (param) {
                  return Json_decode.list(parseMetricEventItemGroupBy, param);
                }), json)
        };
}

function partial_arg_0$10(json) {
  return {
          NAME: "Event",
          VAL: Json_decode.field("event", parseMetricEventItem, json)
        };
}

var partial_arg$10 = {
  hd: partial_arg_0$10,
  tl: /* [] */0
};

function parseMetricItem(param) {
  return Json_decode.oneOf(partial_arg$10, param);
}

function parseMetricType(json) {
  return Belt_Option.getExn(TrackingPlanModel.metricTypeFromJs(Json_decode.string(json)));
}

function parseBranchStatus(json) {
  return Belt_Option.getExn(TrackingPlanModel.branchStatusFromJs(Json_decode.string(json)));
}

function partial_arg_0$11(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          description: Json_decode.field("description", Json_decode.string, json),
          type_: Json_decode.field("type", parseMetricType, json),
          items: Json_decode.field("items", (function (param) {
                  return Json_decode.list(parseMetricItem, param);
                }), json)
        };
}

var partial_arg_1$9 = {
  hd: (function (json) {
      return {
              id: Json_decode.field("id", Json_decode.string, json),
              name: Json_decode.field("name", Json_decode.string, json),
              description: Json_decode.field("description", Json_decode.string, json),
              type_: "Funnel",
              items: Belt_List.map(Json_decode.field("events", (function (param) {
                          return Json_decode.list(Json_decode.string, param);
                        }), json), (function (eventId) {
                      return {
                              NAME: "Event",
                              VAL: {
                                id: eventId,
                                eventId: eventId,
                                where: /* [] */0,
                                groupBy: /* [] */0
                              }
                            };
                    }))
            };
    }),
  tl: /* [] */0
};

var partial_arg$11 = {
  hd: partial_arg_0$11,
  tl: partial_arg_1$9
};

function parseMetric(param) {
  return Json_decode.oneOf(partial_arg$11, param);
}

function parseMetrics(json) {
  return Json_decode.field("metrics", (function (param) {
                return Json_decode.list(parseMetric, param);
              }), json);
}

function parseArchive(json) {
  return Json_decode.field("archive", (function (json) {
                return {
                        events: parseEvents(json),
                        properties: parseProperties(json),
                        propertyBundles: parsePropertyGroups(json),
                        sources: parseSources(json),
                        destinations: parseDestinations(json),
                        goals: parseGoals(json),
                        metrics: parseMetrics(json),
                        groupTypes: parseGroupTypes(json),
                        eventVariants: Json_decode.field("eventVariants", (function (param) {
                                return Json_decode.array(parseEventVariant, param);
                              }), json)
                      };
              }), json);
}

function parseBranch(json) {
  return [
          Json_decode.field("branchId", Json_decode.string, json),
          Json_decode.field("branchName", Json_decode.string, json)
        ];
}

function parseBranches(json) {
  return Json_decode.field("openBranches", (function (param) {
                return Json_decode.list(parseBranch, param);
              }), json);
}

function parseBranchPointer(json) {
  return Json_decode.field("branchPointer", (function (json) {
                return [
                        Json_decode.field("actionId", Json_decode.string, json),
                        Json_decode.field("actionTimestamp", Json_decode.string, json)
                      ];
              }), json);
}

function parsePublishingSuccessStats(json) {
  return {
          version: Json_decode.field("version", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json),
          numSuccessfulEvents: Json_decode.field("numSuccessfulEvents", Json_decode.$$int, json),
          numSuccessfulProperties: Json_decode.field("numSuccessfulProperties", Json_decode.$$int, json),
          numSuccessfulCategories: Json_decode.field("numSuccessfulCategories", Json_decode.$$int, json)
        };
}

function parseGlobalRequirementsMetadata(json) {
  return {
          schemaId: Json_decode.field("schemaId", Json_decode.string, json),
          actionId: Json_decode.field("actionId", Json_decode.string, json),
          branchId: Json_decode.field("branchId", Json_decode.string, json),
          lastUpdatedAt: new Date(Json_decode.field("lastUpdatedAt", Json_decode.string, json))
        };
}

function parseGlobalRequirements(json) {
  return {
          metadata: Json_decode.field("metadata", parseGlobalRequirementsMetadata, json),
          events: parseEvents(json),
          properties: parseProperties(json),
          propertyBundles: parsePropertyBundlesWithBundlesInJson(json),
          goals: parseGoals(json),
          groupTypes: parseGroupTypes(json),
          rules: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("rules", (function (param) {
                                    return Json_decode.list(parseRule, param);
                                  }), param);
                    }), json), /* [] */0)
        };
}

function parseTrackingPlanAuditConfigItem(json) {
  var match = Json_decode.string(json);
  switch (match) {
    case "AllEventsHaveDescriptions" :
        return "AllEventsHaveDescriptions";
    case "AllPropertiesHaveDescriptions" :
        return "AllPropertiesHaveDescriptions";
    case "AllPropertiesHaveTypes" :
        return "AllPropertiesHaveTypes";
    case "BranchEnforcementStatus" :
        return "BranchEnforcementStatus";
    case "ConsistentEventNameCasing" :
        return "ConsistentEventNameCasing";
    case "ConsistentPropertyNameCasing" :
        return "ConsistentPropertyNameCasing";
    case "ForceEventCasing" :
        return "ForceEventCasing";
    case "ForcePropertyCasing" :
        return "ForcePropertyCasing";
    case "NoConflictingProperties" :
        return "NoConflictingProperties";
    case "PreferredEventNameCasing" :
        return "PreferredEventNameCasing";
    case "PreferredPropertyNameCasing" :
        return "PreferredPropertyNameCasing";
    case "UniqueEventNames" :
        return "UniqueEventNames";
    case "UniquePropertyNames" :
        return "UniquePropertyNames";
    default:
      return Pervasives.failwith("Unexpected configItem in Parser.parseTrackingPlanAuditConfigItem");
  }
}

function parseTrackingPlanAuditRuleSeverity(json) {
  var match = Json_decode.string(json);
  switch (match) {
    case "enforce" :
        return "enforce";
    case "none" :
        return "none";
    case "warning" :
        return "warning";
    default:
      return Pervasives.failwith("Unexpected severity in Parser.parseTrackingPlanAuditRuleSeverity");
  }
}

function parseTrackingPlanAuditPointOfBlock(json) {
  var match = Json_decode.string(json);
  switch (match) {
    case "beforeMerge" :
        return "beforeMerge";
    case "beforeRequestForReview" :
        return "beforeRequestForReview";
    default:
      return Pervasives.failwith("Unexpected pointOfBlock in Parser.parseTrackingPlanAuditPointOfBlock");
  }
}

function parseSchema(json) {
  return Belt_Option.map(json, (function (json) {
                return {
                        types: Belt_List.fromArray(Json_decode.field("types", (function (param) {
                                    return Json_decode.array(parseType, param);
                                  }), json)),
                        properties: parseProperties(json),
                        propertyBundles: parsePropertyGroups(json),
                        events: parseEvents(json),
                        migrations: Belt_Option.getWithDefault(Json_decode.optional(parseMigrations, json), /* [] */0),
                        sources: Belt_Option.getWithDefault(Json_decode.optional(parseSources, json), /* [] */0),
                        destinations: Belt_Option.getWithDefault(Json_decode.optional(parseDestinations, json), /* [] */0),
                        groupTypes: Belt_Option.getWithDefault(Json_decode.optional(parseGroupTypes, json), []),
                        goals: Belt_Option.getWithDefault(Json_decode.optional(parseGoals, json), /* [] */0),
                        metrics: Belt_Option.getWithDefault(Json_decode.optional(parseMetrics, json), /* [] */0),
                        archive: Belt_Option.getWithDefault(Json_decode.optional(parseArchive, json), {
                              events: /* [] */0,
                              properties: /* [] */0,
                              propertyBundles: /* [] */0,
                              sources: /* [] */0,
                              destinations: /* [] */0,
                              goals: /* [] */0,
                              metrics: /* [] */0,
                              groupTypes: [],
                              eventVariants: []
                            }),
                        openBranches: Belt_Option.getWithDefault(Json_decode.optional(parseBranches, json), /* [] */0),
                        branchPointer: Json_decode.optional(parseBranchPointer, json),
                        rules: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                                    return Json_decode.field("rules", (function (param) {
                                                  return Json_decode.list(parseRule, param);
                                                }), param);
                                  }), json), /* [] */0),
                        integrations: Belt_Option.getWithDefault(Json_decode.optional(parseIntegrations, json), /* [] */0),
                        globalRequirements: Json_decode.optional((function (param) {
                                return Json_decode.field("globalRequirements", parseGlobalRequirements, param);
                              }), json)
                      };
              }));
}

function parseSchemaMapped(json) {
  return Belt_Option.map(parseSchema(json), TrackingPlanMappedModel.fromOld);
}

function parseSchemaExn(json) {
  return Belt_Option.getExn(parseSchema(Caml_option.some(json)));
}

function parseSchemaMappedExn(json) {
  return Belt_Option.getExn(Belt_Option.map(parseSchema(Caml_option.some(json)), TrackingPlanMappedModel.fromOld));
}

function parseSchemaString(str) {
  return parseSchema(Json.parse(str));
}

function parseSchemaStringExn(str) {
  return Belt_Option.getExn(parseSchema(Json.parse(str)));
}

function parseAction(json) {
  var dict = Js_json.classify(json);
  if (typeof dict === "number") {
    return Pervasives.failwith("parseAction expected object but got " + JSON.stringify(json));
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Pervasives.failwith("parseAction expected object but got " + JSON.stringify(json));
  }
  var match = Belt_Array.getExn(Js_dict.entries(dict._0), 0);
  var json$1 = match[1];
  var key = match[0];
  switch (key) {
    case "AddEvent" :
        return {
                NAME: "AddEvent",
                VAL: [
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("name", Json_decode.string, json$1)
                ]
              };
    case "AddEventSpecificPropertyValue" :
        return {
                NAME: "AddEventSpecificPropertyValue",
                VAL: [
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("propertyId", Json_decode.string, json$1),
                  Json_decode.field("literal", parseLiteral, json$1),
                  Json_decode.field("addedAllowedSourcesSet", (function (json) {
                          return Belt_SetString.fromArray(Json_decode.array(Json_decode.string, json));
                        }), json$1)
                ]
              };
    case "AddEventSpecificPropertyValueForAllEvents" :
        return {
                NAME: "AddEventSpecificPropertyValueForAllEvents",
                VAL: [
                  Json_decode.field("propertyId", Json_decode.string, json$1),
                  Json_decode.field("literal", parseLiteral, json$1)
                ]
              };
    case "AddEventSpecificPropertyValueForSomeEvents" :
        return {
                NAME: "AddEventSpecificPropertyValueForSomeEvents",
                VAL: [
                  Json_decode.field("propertyId", Json_decode.string, json$1),
                  Json_decode.field("literal", parseLiteral, json$1),
                  Json_decode.field("disallowedEvents", parseDisallowedEvents, json$1)
                ]
              };
    case "AddEventToGoal" :
        return {
                NAME: "AddEventToGoal",
                VAL: [
                  Json_decode.field("goalId", Json_decode.string, json$1),
                  Json_decode.field("eventId", Json_decode.string, json$1)
                ]
              };
    case "AddEventToMetric" :
        return Json_decode.oneOf({
                    hd: (function (json) {
                        return {
                                NAME: "AddEventToMetric",
                                VAL: [
                                  Json_decode.field("metricId", Json_decode.string, json),
                                  Json_decode.field("id", Json_decode.string, json),
                                  Json_decode.field("eventId", Json_decode.string, json)
                                ]
                              };
                      }),
                    tl: {
                      hd: (function (json) {
                          return {
                                  NAME: "AddEventToMetric",
                                  VAL: [
                                    Json_decode.field("metricId", Json_decode.string, json),
                                    Json_decode.field("eventId", Json_decode.string, json),
                                    Json_decode.field("eventId", Json_decode.string, json)
                                  ]
                                };
                        }),
                      tl: /* [] */0
                    }
                  }, json$1);
    case "AddEventType" :
        return {
                NAME: "AddEventType",
                VAL: [
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Belt_Option.getExn(TrackingPlanModel.eventTypeFromJs(Json_decode.field("type", Json_decode.string, json$1)))
                ]
              };
    case "AddEventVariantTriggerSource" :
        return {
                NAME: "AddEventVariantTriggerSource",
                VAL: [
                  {
                    baseEventId: Json_decode.field("eventId", Json_decode.string, json$1),
                    variantId: Json_decode.field("variantId", Json_decode.string, json$1)
                  },
                  Json_decode.field("triggerId", Json_decode.string, json$1),
                  Json_decode.field("sourceId", Json_decode.string, json$1)
                ]
              };
    case "AddGoal" :
        return {
                NAME: "AddGoal",
                VAL: Json_decode.field("goalId", Json_decode.string, json$1)
              };
    case "AddGroupTypeToLogEvent" :
        return {
                NAME: "AddGroupTypeToLogEvent",
                VAL: [
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("groupTypeId", Json_decode.string, json$1)
                ]
              };
    case "AddMetric" :
        return {
                NAME: "AddMetric",
                VAL: Json_decode.field("metricId", Json_decode.string, json$1)
              };
    case "AddMetricToGoal" :
        return {
                NAME: "AddMetricToGoal",
                VAL: [
                  Json_decode.field("goalId", Json_decode.string, json$1),
                  Json_decode.field("metricId", Json_decode.string, json$1)
                ]
              };
    case "AddProperty" :
        return {
                NAME: "AddProperty",
                VAL: [
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("propertyId", Json_decode.string, json$1),
                  Json_decode.field("name", Json_decode.string, json$1),
                  Json_decode.field("sendAs", parseSendAs, json$1)
                ]
              };
    case "AddPropertyGroupToEvent" :
        return {
                NAME: "AddPropertyGroupToEvent",
                VAL: [
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("groupId", Json_decode.string, json$1)
                ]
              };
    case "AddPropertyRef" :
        return {
                NAME: "AddPropertyRef",
                VAL: [
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("propertyId", Json_decode.string, json$1)
                ]
              };
    case "AddPropertyToEventVariant" :
        return {
                NAME: "AddPropertyToEventVariant",
                VAL: [
                  {
                    baseEventId: Json_decode.field("eventId", Json_decode.string, json$1),
                    variantId: Json_decode.field("variantId", Json_decode.string, json$1)
                  },
                  Json_decode.field("propertyId", Json_decode.string, json$1)
                ]
              };
    case "AddPropertyToGroup" :
        return {
                NAME: "AddPropertyToGroup",
                VAL: [
                  Json_decode.field("groupId", Json_decode.string, json$1),
                  Json_decode.field("propertyId", Json_decode.string, json$1)
                ]
              };
    case "AddPropertyToWhitelist" :
        return {
                NAME: "AddPropertyToWhitelist",
                VAL: [
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("groupId", Json_decode.string, json$1),
                  Belt_Option.getExn(AvoConfigTypes.analyticsToolFromJs(Json_decode.field("analyticsTool", Json_decode.string, json$1)))
                ]
              };
    case "AddPropertyValidation" :
        return {
                NAME: "AddPropertyValidation",
                VAL: [
                  Json_decode.field("propertyId", Json_decode.string, json$1),
                  Json_decode.field("validation", parseValidation, json$1)
                ]
              };
    case "AddPropertyValidationMatch" :
        return {
                NAME: "DeprecatedAddPropertyValidationMatch",
                VAL: [
                  Json_decode.field("propertyId", Json_decode.string, json$1),
                  Json_decode.field("match", Json_decode.string, json$1)
                ]
              };
    case "AddPropertyValidationMinOrMax" :
        return {
                NAME: "DeprecatedAddPropertyValidationMinOrMax",
                VAL: [
                  Json_decode.field("propertyId", Json_decode.string, json$1),
                  Belt_Option.getExn(TrackingPlanModel.constraint_FromJs(Json_decode.field("validationType", Json_decode.string, json$1)))
                ]
              };
    case "AddRule" :
        return {
                NAME: "AddRule",
                VAL: [
                  Json_decode.field("ruleId", Json_decode.string, json$1),
                  Json_decode.field("item", parseRuleItem, json$1),
                  Json_decode.field("definition", parseRuleDefinition, json$1)
                ]
              };
    case "AddSourceConfig" :
        return {
                NAME: "AddSourceConfig",
                VAL: [
                  Json_decode.field("sourceId", Json_decode.string, json$1),
                  Json_decode.field("sourceConfig", parseSourceConfig, json$1)
                ]
              };
    case "AddSourceSegmentIntegration" :
        return {
                NAME: "AddSourceSegmentIntegration",
                VAL: [
                  Json_decode.field("sourceId", Json_decode.string, json$1),
                  Json_decode.field("segmentIntegration", Json_decode.string, json$1),
                  Json_decode.field("isActive", Json_decode.bool, json$1)
                ]
              };
    case "AddSystemProperty" :
        return {
                NAME: "AddSystemProperty",
                VAL: [
                  Json_decode.field("propertyId", Json_decode.string, json$1),
                  Json_decode.field("name", Json_decode.string, json$1)
                ]
              };
    case "AddTag" :
        return {
                NAME: "AddTag",
                VAL: [
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("tag", Json_decode.string, json$1)
                ]
              };
    case "AddTriggerSource" :
        return {
                NAME: "AddTriggerSource",
                VAL: [
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("triggerId", Json_decode.string, json$1),
                  Json_decode.field("sourceId", Json_decode.string, json$1)
                ]
              };
    case "AddUserToGroup" :
        return {
                NAME: "AddUserToGroup",
                VAL: [
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("groupTypeId", Json_decode.string, json$1)
                ]
              };
    case "AllowAllEventSpecificPropertyValuesOnAllEventsAndSources" :
        return {
                NAME: "AllowAllEventSpecificPropertyValuesOnAllEventsAndSources",
                VAL: Json_decode.field("propertyId", Json_decode.string, json$1)
              };
    case "Archive" :
        return {
                NAME: "Archive",
                VAL: parseItem(json$1)
              };
    case "ArchiveSavedView" :
        return {
                NAME: "ArchiveSavedView",
                VAL: [
                  Json_decode.field("savedViewId", Json_decode.string, json$1),
                  Json_decode.field("savedViewName", Json_decode.string, json$1)
                ]
              };
    case "AutoPublishIntegration" :
        return {
                NAME: "AutoPublishIntegration",
                VAL: [
                  Json_decode.field("integrationId", Json_decode.string, json$1),
                  Json_decode.field("success", Json_decode.bool, json$1),
                  Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                              return Json_decode.field("warnings", (function (param) {
                                            return Json_decode.array(Json_decode.string, param);
                                          }), param);
                            }), json$1), []),
                  Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                              return Json_decode.field("messages", (function (param) {
                                            return Json_decode.array(Json_decode.string, param);
                                          }), param);
                            }), json$1), []),
                  Json_decode.optional((function (param) {
                          return Json_decode.field("successStats", parsePublishingSuccessStats, param);
                        }), json$1)
                ]
              };
    case "BranchComment" :
        return {
                NAME: "BranchComment",
                VAL: [
                  Json_decode.field("branchId", Json_decode.string, json$1),
                  Json_decode.field("commentId", Json_decode.string, json$1)
                ]
              };
    case "BranchInit" :
        return {
                NAME: "BranchInit",
                VAL: [
                  Json_decode.field("branchId", Json_decode.string, json$1),
                  Json_decode.field("branchName", Json_decode.string, json$1)
                ]
              };
    case "ClearEventVariantPropertyOverride" :
        return {
                NAME: "ClearEventVariantPropertyOverride",
                VAL: [
                  {
                    baseEventId: Json_decode.field("eventId", Json_decode.string, json$1),
                    variantId: Json_decode.field("variantId", Json_decode.string, json$1)
                  },
                  Json_decode.field("propertyId", Json_decode.string, json$1)
                ]
              };
    case "ClearEventVariantSpecificPropertyValueOverride" :
        return {
                NAME: "ClearEventVariantSpecificPropertyValueOverride",
                VAL: [
                  {
                    baseEventId: Json_decode.field("eventId", Json_decode.string, json$1),
                    variantId: Json_decode.field("variantId", Json_decode.string, json$1)
                  },
                  Json_decode.field("propertyId", Json_decode.string, json$1),
                  Json_decode.field("literal", parseLiteral, json$1)
                ]
              };
    case "ClearPropertyExcludedSources" :
        return {
                NAME: "ClearPropertyExcludedSources",
                VAL: Json_decode.field("propertyId", Json_decode.string, json$1)
              };
    case "ClearPropertyPinnedValueFromEventVariant" :
        return {
                NAME: "ClearPropertyPinnedValueFromEventVariant",
                VAL: [
                  {
                    baseEventId: Json_decode.field("eventId", Json_decode.string, json$1),
                    variantId: Json_decode.field("variantId", Json_decode.string, json$1)
                  },
                  Json_decode.field("propertyId", Json_decode.string, json$1)
                ]
              };
    case "ClearPropertyRegexOverrideFromEventVariant" :
        return {
                NAME: "ClearPropertyRegexOverrideFromEventVariant",
                VAL: [
                  {
                    baseEventId: Json_decode.field("eventId", Json_decode.string, json$1),
                    variantId: Json_decode.field("variantId", Json_decode.string, json$1)
                  },
                  Json_decode.field("propertyId", Json_decode.string, json$1)
                ]
              };
    case "ClearSourceOverrideOnVariant" :
        return {
                NAME: "ClearSourceOverrideOnVariant",
                VAL: [
                  {
                    baseEventId: Json_decode.field("eventId", Json_decode.string, json$1),
                    variantId: Json_decode.field("variantId", Json_decode.string, json$1)
                  },
                  Json_decode.field("sourceId", Json_decode.string, json$1)
                ]
              };
    case "CloseBranch" :
        return {
                NAME: "CloseBranch",
                VAL: [
                  Json_decode.field("branchId", Json_decode.string, json$1),
                  Json_decode.field("branchName", Json_decode.string, json$1)
                ]
              };
    case "CodeGenerated" :
        return {
                NAME: "CodeGenerated",
                VAL: [
                  Json_decode.field("branchId", Json_decode.string, json$1),
                  Json_decode.field("sourceIds", (function (param) {
                          return Json_decode.list(Json_decode.string, param);
                        }), json$1)
                ]
              };
    case "CollaboratorAdded" :
        return {
                NAME: "CollaboratorAdded",
                VAL: [
                  Json_decode.field("collaborationItem", parseCollaborationItem, json$1),
                  Json_decode.field("addedByUserId", Json_decode.string, json$1),
                  Json_decode.field("userId", Json_decode.string, json$1)
                ]
              };
    case "CollaboratorRemoved" :
        return {
                NAME: "CollaboratorRemoved",
                VAL: [
                  Json_decode.field("collaborationItem", parseCollaborationItem, json$1),
                  Json_decode.field("removedByUserId", Json_decode.string, json$1),
                  Json_decode.field("userId", Json_decode.string, json$1)
                ]
              };
    case "CompletedImport" :
        return {
                NAME: "CompletedImport",
                VAL: [
                  Json_decode.field("eventCount", Json_decode.$$int, json$1),
                  Json_decode.field("propertyCount", Json_decode.$$int, json$1),
                  Json_decode.field("sourceCount", Json_decode.$$int, json$1),
                  Belt_Option.getWithDefault(Belt_Option.flatMapU(Json_decode.optional((function (param) {
                                  return Json_decode.field("importLocation", (function (param) {
                                                return Json_decode.optional(Json_decode.string, param);
                                              }), param);
                                }), json$1), (function (importLocationJs) {
                              return Belt_Option.flatMap(importLocationJs, TrackingPlanModel.importLocationFromJs);
                            })), "unregistered")
                ]
              };
    case "ConfigureInspectorValidation" :
        return {
                NAME: "ConfigureInspectorValidation",
                VAL: [
                  Belt_List.fromArray(Json_decode.field("eventIds", (function (param) {
                              return Json_decode.array(Json_decode.string, param);
                            }), json$1)),
                  Belt_MapString.fromArray(Json_decode.field("sourceValidationMap", (function (param) {
                              return Json_decode.array(parseSourceValidationMap, param);
                            }), json$1))
                ]
              };
    case "ConvertEventToGlobalEvent" :
        return {
                NAME: "ConvertEventToGlobalEvent",
                VAL: [
                  Json_decode.field("fromEventId", Json_decode.string, json$1),
                  Json_decode.field("toEventId", Json_decode.string, json$1),
                  Json_decode.field("globalSchemaId", Json_decode.string, json$1),
                  Json_decode.field("metadata", parseGlobalRequirementsMetadata, json$1),
                  Json_decode.field("globalEvent", parseEvent, json$1)
                ]
              };
    case "ConvertEventToGlobalEventV2" :
        return {
                NAME: "ConvertEventToGlobalEventV2",
                VAL: [
                  Json_decode.field("fromEventId", Json_decode.string, json$1),
                  Json_decode.field("toEventId", Json_decode.string, json$1),
                  Json_decode.field("globalSchemaId", Json_decode.string, json$1),
                  Json_decode.field("metadata", parseGlobalRequirementsMetadata, json$1),
                  Json_decode.field("globalEvent", parseEvent, json$1)
                ]
              };
    case "ConvertPropertyBundleToGlobalPropertyBundle" :
        return {
                NAME: "ConvertPropertyBundleToGlobalPropertyBundle",
                VAL: [
                  Json_decode.field("fromPropertyBundleId", Json_decode.string, json$1),
                  Json_decode.field("toPropertyBundleId", Json_decode.string, json$1),
                  Json_decode.field("globalSchemaId", Json_decode.string, json$1),
                  Json_decode.field("metadata", parseGlobalRequirementsMetadata, json$1),
                  Json_decode.field("globalPropertyBundle", parsePropertyGroup, json$1)
                ]
              };
    case "ConvertPropertyToGlobalProperty" :
        return {
                NAME: "ConvertPropertyToGlobalProperty",
                VAL: [
                  Json_decode.field("fromPropertyId", Json_decode.string, json$1),
                  Json_decode.field("toPropertyId", Json_decode.string, json$1),
                  Json_decode.field("globalSchemaId", Json_decode.string, json$1),
                  Json_decode.field("globalProperty", parseTrackingPlanProperty, json$1),
                  Json_decode.field("metadata", parseGlobalRequirementsMetadata, json$1)
                ]
              };
    case "ConvertPropertyToGlobalPropertyV2" :
        return {
                NAME: "ConvertPropertyToGlobalPropertyV2",
                VAL: [
                  Json_decode.field("fromPropertyId", Json_decode.string, json$1),
                  Json_decode.field("toPropertyId", Json_decode.string, json$1),
                  Json_decode.field("globalSchemaId", Json_decode.string, json$1),
                  Json_decode.field("globalProperty", parseTrackingPlanProperty, json$1),
                  Json_decode.field("metadata", parseGlobalRequirementsMetadata, json$1)
                ]
              };
    case "CreateDemoBranch" :
        return {
                NAME: "CreateDemoBranch",
                VAL: Json_decode.field("actions", (function (param) {
                        return Json_decode.list(parseAction, param);
                      }), json$1)
              };
    case "CreateDestination" :
        return {
                NAME: "CreateDestination",
                VAL: Json_decode.field("destinationId", Json_decode.string, json$1)
              };
    case "CreateEventTrigger" :
        return {
                NAME: "CreateEventTrigger",
                VAL: [
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("triggerId", Json_decode.string, json$1),
                  Json_decode.field("sources", parseTriggerSources, json$1),
                  Json_decode.field("description", Json_decode.string, json$1),
                  Json_decode.field("content", (function (param) {
                          return Json_decode.optional(parseTriggerContent, param);
                        }), json$1)
                ]
              };
    case "CreateEventVariant" :
        return {
                NAME: "CreateEventVariant",
                VAL: [
                  {
                    baseEventId: Json_decode.field("eventId", Json_decode.string, json$1),
                    variantId: Json_decode.field("variantId", Json_decode.string, json$1)
                  },
                  Json_decode.field("variantName", Json_decode.string, json$1)
                ]
              };
    case "CreateEventVariantTrigger" :
        return {
                NAME: "CreateEventVariantTrigger",
                VAL: [
                  {
                    baseEventId: Json_decode.field("eventId", Json_decode.string, json$1),
                    variantId: Json_decode.field("variantId", Json_decode.string, json$1)
                  },
                  Json_decode.field("triggerId", Json_decode.string, json$1),
                  Json_decode.field("sources", parseTriggerSources, json$1),
                  Json_decode.field("description", Json_decode.string, json$1),
                  Json_decode.field("content", (function (param) {
                          return Json_decode.optional(parseTriggerContent, param);
                        }), json$1)
                ]
              };
    case "CreateGroupType" :
        return {
                NAME: "CreateGroupType",
                VAL: [
                  Json_decode.field("groupTypeId", Json_decode.string, json$1),
                  Json_decode.field("groupTypeName", Json_decode.string, json$1),
                  Json_decode.field("groupTypeDescription", Json_decode.string, json$1)
                ]
              };
    case "CreateInspectorIssue" :
        return {
                NAME: "CreateInspectorIssue",
                VAL: parseInspectorIssueMetadata(json$1)
              };
    case "CreateIntegration" :
        return {
                NAME: "CreateIntegration",
                VAL: Json_decode.field("integrationId", Json_decode.string, json$1)
              };
    case "CreateLocalWorkspace" :
        return {
                NAME: "CreateLocalWorkspace",
                VAL: Json_decode.field("workspaceId", Json_decode.string, json$1)
              };
    case "CreateProperty" :
        return {
                NAME: "CreateProperty",
                VAL: [
                  Json_decode.field("propertyId", Json_decode.string, json$1),
                  Json_decode.field("name", Json_decode.string, json$1),
                  Json_decode.field("sendAs", parseSendAs, json$1)
                ]
              };
    case "CreatePropertyGroup" :
        return {
                NAME: "CreatePropertyGroup",
                VAL: [
                  Json_decode.field("groupId", Json_decode.string, json$1),
                  Json_decode.field("name", Json_decode.string, json$1)
                ]
              };
    case "CreatePublicBranchImplementation" :
        return {
                NAME: "CreatePublicBranchImplementation",
                VAL: Json_decode.field("shareId", Json_decode.string, json$1)
              };
    case "CreateSavedView" :
        return {
                NAME: "CreateSavedView",
                VAL: [
                  Json_decode.field("savedViewId", Json_decode.string, json$1),
                  Json_decode.field("savedViewName", Json_decode.string, json$1)
                ]
              };
    case "CreateServiceAccount" :
        return {
                NAME: "CreateServiceAccount",
                VAL: Json_decode.field("serviceAccountName", Json_decode.string, json$1)
              };
    case "CreateSource" :
        return {
                NAME: "CreateSource",
                VAL: Json_decode.field("sourceId", Json_decode.string, json$1)
              };
    case "DeleteComment" :
        return {
                NAME: "DeleteComment",
                VAL: [
                  Json_decode.field("threadId", Json_decode.string, json$1),
                  Json_decode.field("commentId", Json_decode.string, json$1)
                ]
              };
    case "DeleteDestination" :
        return {
                NAME: "DeleteDestination",
                VAL: Json_decode.field("destinationId", Json_decode.string, json$1)
              };
    case "DeleteLocalWorkspace" :
        return {
                NAME: "DeleteLocalWorkspace",
                VAL: Json_decode.field("workspaceId", Json_decode.string, json$1)
              };
    case "DeletePublicBranchImplementation" :
        return {
                NAME: "DeletePublicBranchImplementation",
                VAL: Json_decode.field("shareId", Json_decode.string, json$1)
              };
    case "DeleteServiceAccount" :
        return {
                NAME: "DeleteServiceAccount",
                VAL: Json_decode.field("serviceAccountName", Json_decode.string, json$1)
              };
    case "DeleteSource" :
        return {
                NAME: "DeleteSource",
                VAL: Json_decode.field("sourceId", Json_decode.string, json$1)
              };
    case "DuplicateEvent" :
        return {
                NAME: "DuplicateEvent",
                VAL: [
                  Json_decode.field("eventIdArray", parseEventIdTuple, json$1),
                  Json_decode.field("triggerIdArrays", parseTriggerIdTuples, json$1),
                  Json_decode.field("ruleIdArrays", parseRuleIdTuples, json$1)
                ]
              };
    case "EventComment" :
        return {
                NAME: "EventComment",
                VAL: [
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("commentId", Json_decode.string, json$1)
                ]
              };
    case "ExcludeDestinationFromEventSource" :
        return {
                NAME: "ExcludeDestinationFromEventSource",
                VAL: [
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("sourceId", Json_decode.string, json$1),
                  Json_decode.field("destinationId", Json_decode.string, json$1)
                ]
              };
    case "ExcludeDestinationFromSource" :
        return {
                NAME: "ExcludeDestinationFromSource",
                VAL: [
                  Json_decode.field("sourceId", Json_decode.string, json$1),
                  Json_decode.field("destinationId", Json_decode.string, json$1)
                ]
              };
    case "ExcludeEventFromSource" :
        return {
                NAME: "ExcludeEventFromSource",
                VAL: [
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("sourceId", Json_decode.string, json$1)
                ]
              };
    case "ExcludeEventFromSourceV2" :
        return {
                NAME: "ExcludeEventFromSourceV2",
                VAL: [
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("sourceId", Json_decode.string, json$1)
                ]
              };
    case "GenerateWebhookSecret" :
        return {
                NAME: "GenerateWebhookSecret",
                VAL: Json_decode.field("integrationId", Json_decode.string, json$1)
              };
    case "GoalComment" :
        return {
                NAME: "GoalComment",
                VAL: [
                  Json_decode.field("goalId", Json_decode.string, json$1),
                  Json_decode.field("commentId", Json_decode.string, json$1)
                ]
              };
    case "Import" :
        return {
                NAME: "ImportDeprecated",
                VAL: Json_decode.field("actions", (function (param) {
                        return Json_decode.list(parseAction, param);
                      }), json$1)
              };
    case "IncludeDestinationInEventSource" :
        return {
                NAME: "IncludeDestinationInEventSource",
                VAL: [
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("sourceId", Json_decode.string, json$1),
                  Json_decode.field("destinationId", Json_decode.string, json$1)
                ]
              };
    case "IncludeDestinationInSource" :
        return {
                NAME: "LegacyIncludeDestinationInSource",
                VAL: [
                  Json_decode.field("sourceId", Json_decode.string, json$1),
                  Json_decode.field("destinationId", Json_decode.string, json$1),
                  Json_decode.field("eventIds", (function (param) {
                          return Json_decode.list(Json_decode.string, param);
                        }), json$1)
                ]
              };
    case "IncludeDestinationInSourceV2" :
        return {
                NAME: "IncludeDestinationInSourceV2",
                VAL: [
                  Json_decode.field("sourceId", Json_decode.string, json$1),
                  Json_decode.field("destinationId", Json_decode.string, json$1),
                  Json_decode.field("eventIds", (function (param) {
                          return Json_decode.list(Json_decode.string, param);
                        }), json$1)
                ]
              };
    case "IncludeEventInSource" :
        return {
                NAME: "IncludeEventInSource",
                VAL: [
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("sourceId", Json_decode.string, json$1)
                ]
              };
    case "IncludeEventInSourceV2" :
        return {
                NAME: "IncludeEventInSourceV2",
                VAL: [
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("sourceId", Json_decode.string, json$1),
                  Json_decode.field("destinationIds", (function (param) {
                          return Json_decode.list(Json_decode.string, param);
                        }), json$1),
                  Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                              return Json_decode.field("includeInCodegen", Json_decode.bool, param);
                            }), json$1), true)
                ]
              };
    case "InspectorIssueComment" :
        return {
                NAME: "InspectorIssueComment",
                VAL: [
                  Json_decode.field("inspectorIssueId", Json_decode.string, json$1),
                  Json_decode.field("commentId", Json_decode.string, json$1)
                ]
              };
    case "IntegrationComment" :
        return {
                NAME: "IntegrationComment",
                VAL: [
                  Json_decode.field("integrationId", Json_decode.string, json$1),
                  Json_decode.field("commentId", Json_decode.string, json$1)
                ]
              };
    case "InviteMember" :
        return {
                NAME: "InviteMember",
                VAL: [
                  Json_decode.field("email", Json_decode.string, json$1),
                  Json_decode.field("role", Json_decode.string, json$1)
                ]
              };
    case "MergeBranch" :
        return {
                NAME: "MergeBranch",
                VAL: [
                  Json_decode.field("branchId", Json_decode.string, json$1),
                  Json_decode.field("branchName", Json_decode.string, json$1),
                  Json_decode.field("actions", (function (param) {
                          return Json_decode.list(parseAction, param);
                        }), json$1)
                ]
              };
    case "MetricComment" :
        return {
                NAME: "MetricComment",
                VAL: [
                  Json_decode.field("metricId", Json_decode.string, json$1),
                  Json_decode.field("commentId", Json_decode.string, json$1)
                ]
              };
    case "MigrateExcludeEventsToIncludeEvents" :
        return {
                NAME: "MigrateExcludeEventsToIncludeEvents",
                VAL: Belt_List.fromArray(Json_decode.field("sourceIds", (function (param) {
                            return Json_decode.array(Json_decode.string, param);
                          }), json$1))
              };
    case "MigratePropertyOptionalAndExcludedSourcesToAbsence" :
        return "MigratePropertyOptionalAndExcludedSourcesToAbsence";
    case "MigrateRemoveUnarchivedItemsFromArchive" :
        return "MigrateRemoveUnarchivedItemsFromArchive";
    case "MigrateSkipWebFnTagToIncludeInCodegen" :
        return "MigrateSkipWebFnTagToIncludeInCodegen";
    case "MigrateSourceDestinationMapping" :
        return "MigrateSourceDestinationMapping";
    case "MigrateSourcesAndDestinationsToModel" :
        return {
                NAME: "MigrateSourcesAndDestinationsToModel",
                VAL: [
                  parseLegacySources(json$1),
                  parseDestinations(json$1)
                ]
              };
    case "MigrateToEventSpecificAllowedPropertyValues" :
        return "MigrateToEventSpecificAllowedPropertyValues";
    case "OpenBranch" :
        return {
                NAME: "OpenBranch",
                VAL: [
                  Json_decode.field("branchId", Json_decode.string, json$1),
                  Json_decode.field("branchName", Json_decode.string, json$1)
                ]
              };
    case "OverrideEventVariantSpecificPropertyValueToBeAllowedOnAllSources" :
        return {
                NAME: "OverrideEventVariantSpecificPropertyValueToBeAllowedOnAllSources",
                VAL: [
                  {
                    baseEventId: Json_decode.field("eventId", Json_decode.string, json$1),
                    variantId: Json_decode.field("variantId", Json_decode.string, json$1)
                  },
                  Json_decode.field("propertyId", Json_decode.string, json$1),
                  Json_decode.field("literal", parseLiteral, json$1)
                ]
              };
    case "OverrideEventVariantSpecificPropertyValueToBeDisallowed" :
        return {
                NAME: "OverrideEventVariantSpecificPropertyValueToBeDisallowed",
                VAL: [
                  {
                    baseEventId: Json_decode.field("eventId", Json_decode.string, json$1),
                    variantId: Json_decode.field("variantId", Json_decode.string, json$1)
                  },
                  Json_decode.field("propertyId", Json_decode.string, json$1),
                  Json_decode.field("literal", parseLiteral, json$1)
                ]
              };
    case "PaymentCompleted" :
        return {
                NAME: "PaymentCompleted",
                VAL: [
                  Json_decode.field("paymentSource", Json_decode.string, json$1),
                  Json_decode.field("subscription", Json_decode.string, json$1)
                ]
              };
    case "PropertyComment" :
        return {
                NAME: "PropertyComment",
                VAL: [
                  Json_decode.field("propertyId", Json_decode.string, json$1),
                  Json_decode.field("commentId", Json_decode.string, json$1)
                ]
              };
    case "PropertyGroupComment" :
        return {
                NAME: "PropertyGroupComment",
                VAL: [
                  Json_decode.field("propertyGroupId", Json_decode.string, json$1),
                  Json_decode.field("commentId", Json_decode.string, json$1)
                ]
              };
    case "PublishIntegration" :
        return {
                NAME: "PublishIntegration",
                VAL: [
                  Json_decode.field("integrationId", Json_decode.string, json$1),
                  Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                              return Json_decode.field("success", Json_decode.bool, param);
                            }), json$1), true),
                  Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                              return Json_decode.field("warnings", (function (param) {
                                            return Json_decode.array(Json_decode.string, param);
                                          }), param);
                            }), json$1), []),
                  Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                              return Json_decode.field("messages", (function (param) {
                                            return Json_decode.array(Json_decode.string, param);
                                          }), param);
                            }), json$1), []),
                  Json_decode.optional((function (param) {
                          return Json_decode.field("successStats", parsePublishingSuccessStats, param);
                        }), json$1)
                ]
              };
    case "PullMaster" :
        return {
                NAME: "PullMaster",
                VAL: [
                  Json_decode.field("branchId", Json_decode.string, json$1),
                  Json_decode.field("masterActionId", Json_decode.string, json$1),
                  Json_decode.field("masterActionTimestamp", Json_decode.string, json$1),
                  Json_decode.field("actions", (function (param) {
                          return Json_decode.list(parseAction, param);
                        }), json$1)
                ]
              };
    case "PushGlobalRequirementsToTrackingPlan" :
        return {
                NAME: "PushGlobalRequirementsToTrackingPlan",
                VAL: [
                  Json_decode.field("globalSchemaId", Json_decode.string, json$1),
                  Json_decode.field("globalBranchId", Json_decode.string, json$1),
                  Json_decode.field("actions", (function (param) {
                          return Json_decode.list(parseAction, param);
                        }), json$1)
                ]
              };
    case "RemoveDestinationApiKey" :
        return {
                NAME: "RemoveDestinationApiKey",
                VAL: [
                  Json_decode.field("destinationId", Json_decode.string, json$1),
                  Json_decode.field("envName", Json_decode.string, json$1)
                ]
              };
    case "RemoveEvent" :
        return {
                NAME: "RemoveEvent",
                VAL: Json_decode.field("eventId", Json_decode.string, json$1)
              };
    case "RemoveEventFromGoal" :
        return {
                NAME: "RemoveEventFromGoal",
                VAL: [
                  Json_decode.field("goalId", Json_decode.string, json$1),
                  Json_decode.field("eventId", Json_decode.string, json$1)
                ]
              };
    case "RemoveEventFromMetric" :
        return Json_decode.oneOf({
                    hd: (function (json) {
                        return {
                                NAME: "RemoveEventFromMetric",
                                VAL: [
                                  Json_decode.field("metricId", Json_decode.string, json),
                                  Json_decode.field("id", Json_decode.string, json),
                                  Json_decode.field("eventId", Json_decode.string, json)
                                ]
                              };
                      }),
                    tl: {
                      hd: (function (json) {
                          return {
                                  NAME: "RemoveEventFromMetric",
                                  VAL: [
                                    Json_decode.field("metricId", Json_decode.string, json),
                                    Json_decode.field("eventId", Json_decode.string, json),
                                    Json_decode.field("eventId", Json_decode.string, json)
                                  ]
                                };
                        }),
                      tl: /* [] */0
                    }
                  }, json$1);
    case "RemoveEventSpecificPropertyValue" :
        return {
                NAME: "RemoveEventSpecificPropertyValue",
                VAL: [
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("propertyId", Json_decode.string, json$1),
                  Json_decode.field("literal", parseLiteral, json$1),
                  Json_decode.field("removedAllowedSourcesSet", (function (json) {
                          return Belt_SetString.fromArray(Json_decode.array(Json_decode.string, json));
                        }), json$1)
                ]
              };
    case "RemoveEventSpecificPropertyValueForAllEvents" :
        return {
                NAME: "RemoveEventSpecificPropertyValueForAllEvents",
                VAL: [
                  Json_decode.field("propertyId", Json_decode.string, json$1),
                  Json_decode.field("literal", parseLiteral, json$1)
                ]
              };
    case "RemoveEventTrigger" :
        return {
                NAME: "RemoveEventTrigger",
                VAL: [
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("triggerId", Json_decode.string, json$1)
                ]
              };
    case "RemoveEventType" :
        return {
                NAME: "RemoveEventType",
                VAL: [
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Belt_Option.getExn(TrackingPlanModel.eventTypeFromJs(Json_decode.field("type", Json_decode.string, json$1)))
                ]
              };
    case "RemoveEventVariantTrigger" :
        return {
                NAME: "RemoveEventVariantTrigger",
                VAL: [
                  {
                    baseEventId: Json_decode.field("eventId", Json_decode.string, json$1),
                    variantId: Json_decode.field("variantId", Json_decode.string, json$1)
                  },
                  Json_decode.field("triggerId", Json_decode.string, json$1)
                ]
              };
    case "RemoveEventVariantTriggerSource" :
        return {
                NAME: "RemoveEventVariantTriggerSource",
                VAL: [
                  {
                    baseEventId: Json_decode.field("eventId", Json_decode.string, json$1),
                    variantId: Json_decode.field("variantId", Json_decode.string, json$1)
                  },
                  Json_decode.field("triggerId", Json_decode.string, json$1),
                  Json_decode.field("sourceId", Json_decode.string, json$1)
                ]
              };
    case "RemoveGoal" :
        return {
                NAME: "RemoveGoal",
                VAL: Json_decode.field("goalId", Json_decode.string, json$1)
              };
    case "RemoveGroupTypeFromLogEvent" :
        return {
                NAME: "RemoveGroupTypeFromLogEvent",
                VAL: [
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("groupTypeId", Json_decode.string, json$1)
                ]
              };
    case "RemoveIntegration" :
        return {
                NAME: "RemoveIntegration",
                VAL: Json_decode.field("integrationId", Json_decode.string, json$1)
              };
    case "RemoveInvite" :
        return {
                NAME: "RemoveInvite",
                VAL: [
                  Json_decode.field("email", Json_decode.string, json$1),
                  Json_decode.field("role", Json_decode.string, json$1)
                ]
              };
    case "RemoveMember" :
        return {
                NAME: "RemoveMember",
                VAL: [
                  Json_decode.field("userId", Json_decode.string, json$1),
                  Json_decode.field("email", Json_decode.string, json$1),
                  Json_decode.field("role", Json_decode.string, json$1)
                ]
              };
    case "RemoveMetric" :
        return {
                NAME: "RemoveMetric",
                VAL: Json_decode.field("metricId", Json_decode.string, json$1)
              };
    case "RemoveMetricFromGoal" :
        return {
                NAME: "RemoveMetricFromGoal",
                VAL: [
                  Json_decode.field("goalId", Json_decode.string, json$1),
                  Json_decode.field("metricId", Json_decode.string, json$1)
                ]
              };
    case "RemoveMetricGroupBy" :
        return {
                NAME: "RemoveMetricGroupBy",
                VAL: [
                  Json_decode.field("metricId", Json_decode.string, json$1),
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("id", Json_decode.string, json$1)
                ]
              };
    case "RemoveMetricGroupByV2" :
        return {
                NAME: "RemoveMetricGroupByV2",
                VAL: [
                  Json_decode.field("metricId", Json_decode.string, json$1),
                  Json_decode.field("metricItemId", Json_decode.string, json$1),
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("id", Json_decode.string, json$1)
                ]
              };
    case "RemoveMetricWhere" :
        return {
                NAME: "RemoveMetricWhere",
                VAL: [
                  Json_decode.field("metricId", Json_decode.string, json$1),
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("id", Json_decode.string, json$1)
                ]
              };
    case "RemoveMetricWhereV2" :
        return {
                NAME: "RemoveMetricWhereV2",
                VAL: [
                  Json_decode.field("metricId", Json_decode.string, json$1),
                  Json_decode.field("metricItemId", Json_decode.string, json$1),
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("id", Json_decode.string, json$1)
                ]
              };
    case "RemoveNestedPropertyPinnedValue" :
        return {
                NAME: "RemoveNestedPropertyPinnedValue",
                VAL: [
                  Json_decode.field("propertyId", Json_decode.string, json$1),
                  Json_decode.field("nestedPropertyId", Json_decode.string, json$1)
                ]
              };
    case "RemovePropertyFromEventVariant" :
        return {
                NAME: "RemovePropertyFromEventVariant",
                VAL: [
                  {
                    baseEventId: Json_decode.field("eventId", Json_decode.string, json$1),
                    variantId: Json_decode.field("variantId", Json_decode.string, json$1)
                  },
                  Json_decode.field("propertyId", Json_decode.string, json$1)
                ]
              };
    case "RemovePropertyFromGroup" :
        return {
                NAME: "RemovePropertyFromGroup",
                VAL: [
                  Json_decode.field("groupId", Json_decode.string, json$1),
                  Json_decode.field("propertyId", Json_decode.string, json$1)
                ]
              };
    case "RemovePropertyFromWhitelist" :
        return {
                NAME: "RemovePropertyFromWhitelist",
                VAL: [
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("groupId", Json_decode.string, json$1),
                  Belt_Option.getExn(AvoConfigTypes.analyticsToolFromJs(Json_decode.field("analyticsTool", Json_decode.string, json$1)))
                ]
              };
    case "RemovePropertyGroupFromEvent" :
        return {
                NAME: "RemovePropertyGroupFromEvent",
                VAL: [
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("groupId", Json_decode.string, json$1)
                ]
              };
    case "RemovePropertyPermanently" :
        return {
                NAME: "RemovePropertyPermanently",
                VAL: Json_decode.field("propertyId", Json_decode.string, json$1)
              };
    case "RemovePropertyPinnedValue" :
        return {
                NAME: "RemovePropertyPinnedValue",
                VAL: [
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("propertyId", Json_decode.string, json$1)
                ]
              };
    case "RemovePropertyRef" :
        return {
                NAME: "RemovePropertyRefV3",
                VAL: [
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("propertyId", Json_decode.string, json$1)
                ]
              };
    case "RemovePropertyRefV2" :
        return {
                NAME: "RemovePropertyRefV2Deprecated",
                VAL: [
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("propertyId", Json_decode.string, json$1)
                ]
              };
    case "RemovePropertyValidation" :
        return {
                NAME: "RemovePropertyValidation",
                VAL: [
                  Json_decode.field("propertyId", Json_decode.string, json$1),
                  Json_decode.field("validation", parseValidation, json$1)
                ]
              };
    case "RemovePropertyValidationMatch" :
        return {
                NAME: "DeprecatedRemovePropertyValidationMatch",
                VAL: [
                  Json_decode.field("propertyId", Json_decode.string, json$1),
                  Json_decode.field("match", Json_decode.string, json$1)
                ]
              };
    case "RemovePropertyValidationMatches" :
        return {
                NAME: "DeprecatedRemovePropertyValidationMatches",
                VAL: Json_decode.field("propertyId", Json_decode.string, json$1)
              };
    case "RemovePropertyValidationMinOrMax" :
        return {
                NAME: "DeprecatedRemovePropertyValidationMinOrMax",
                VAL: [
                  Json_decode.field("propertyId", Json_decode.string, json$1),
                  Json_decode.field("validationIndex", Json_decode.$$int, json$1)
                ]
              };
    case "RemoveRule" :
        return {
                NAME: "RemoveRule",
                VAL: [
                  Json_decode.field("ruleId", Json_decode.string, json$1),
                  Json_decode.field("item", parseRuleItem, json$1)
                ]
              };
    case "RemoveSourceConfig" :
        return {
                NAME: "RemoveSourceConfig",
                VAL: [
                  Json_decode.field("sourceId", Json_decode.string, json$1),
                  Json_decode.field("sourceConfig", parseSourceConfig, json$1)
                ]
              };
    case "RemoveSourceSegmentIntegration" :
        return {
                NAME: "RemoveSourceSegmentIntegration",
                VAL: [
                  Json_decode.field("sourceId", Json_decode.string, json$1),
                  Json_decode.field("segmentIntegration", Json_decode.string, json$1)
                ]
              };
    case "RemoveTag" :
        return {
                NAME: "RemoveTag",
                VAL: [
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("tag", Json_decode.string, json$1)
                ]
              };
    case "RemoveTriggerSource" :
        return {
                NAME: "RemoveTriggerSource",
                VAL: [
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("triggerId", Json_decode.string, json$1),
                  Json_decode.field("sourceId", Json_decode.string, json$1)
                ]
              };
    case "RemoveUserFromGroup" :
        return {
                NAME: "RemoveUserFromGroup",
                VAL: [
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("groupTypeId", Json_decode.string, json$1)
                ]
              };
    case "ReorderEventsInGoal" :
        return {
                NAME: "ReorderEventsInGoal",
                VAL: [
                  Json_decode.field("goalId", Json_decode.string, json$1),
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("maybePredecessorId", (function (param) {
                          return Json_decode.optional(Json_decode.string, param);
                        }), json$1)
                ]
              };
    case "ReorderEventsInGoalV2" :
        return {
                NAME: "ReorderEventsInGoalV2",
                VAL: [
                  Json_decode.field("goalId", Json_decode.string, json$1),
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("maybePredecessorId", (function (param) {
                          return Json_decode.optional(Json_decode.string, param);
                        }), json$1)
                ]
              };
    case "ReorderEventsInMetric" :
        return {
                NAME: "ReorderEventsInMetric",
                VAL: [
                  Json_decode.field("metricId", Json_decode.string, json$1),
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("maybePredecessorId", (function (param) {
                          return Json_decode.optional(Json_decode.string, param);
                        }), json$1)
                ]
              };
    case "ReorderEventsInMetricV2" :
        return {
                NAME: "ReorderEventsInMetricV2",
                VAL: [
                  Json_decode.field("metricId", Json_decode.string, json$1),
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("maybePredecessorId", (function (param) {
                          return Json_decode.optional(Json_decode.string, param);
                        }), json$1)
                ]
              };
    case "ReorderGoals" :
        return {
                NAME: "ReorderGoals",
                VAL: [
                  Json_decode.field("goalId", Json_decode.string, json$1),
                  Json_decode.field("maybePredecessorId", (function (param) {
                          return Json_decode.optional(Json_decode.string, param);
                        }), json$1)
                ]
              };
    case "ReorderGoalsV2" :
        return {
                NAME: "ReorderGoalsV2",
                VAL: [
                  Json_decode.field("goalId", Json_decode.string, json$1),
                  Json_decode.field("maybePredecessorId", (function (param) {
                          return Json_decode.optional(Json_decode.string, param);
                        }), json$1)
                ]
              };
    case "ReorderMetricsInGoal" :
        return {
                NAME: "ReorderMetricsInGoal",
                VAL: [
                  Json_decode.field("goalId", Json_decode.string, json$1),
                  Json_decode.field("metricId", Json_decode.string, json$1),
                  Json_decode.field("maybePredecessorId", (function (param) {
                          return Json_decode.optional(Json_decode.string, param);
                        }), json$1)
                ]
              };
    case "ReorderMetricsInGoalV2" :
        return {
                NAME: "ReorderMetricsInGoalV2",
                VAL: [
                  Json_decode.field("goalId", Json_decode.string, json$1),
                  Json_decode.field("metricId", Json_decode.string, json$1),
                  Json_decode.field("maybePredecessorId", (function (param) {
                          return Json_decode.optional(Json_decode.string, param);
                        }), json$1)
                ]
              };
    case "ResendInvite" :
        return {
                NAME: "ResendInvite",
                VAL: [
                  Json_decode.field("email", Json_decode.string, json$1),
                  Json_decode.field("role", Json_decode.string, json$1)
                ]
              };
    case "ResetTrackingPlan" :
        return "ResetTrackingPlan";
    case "SchemaAccessed" :
        return "SchemaAccessed";
    case "SetBranchStatus" :
        return {
                NAME: "SetBranchStatus",
                VAL: [
                  Json_decode.field("branchId", Json_decode.string, json$1),
                  Json_decode.field("branchName", Json_decode.string, json$1),
                  Json_decode.field("branchStatus", parseBranchStatus, json$1)
                ]
              };
    case "SetEventSpecificPropertyValueSources" :
        return {
                NAME: "SetEventSpecificPropertyValueSources",
                VAL: [
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("propertyId", Json_decode.string, json$1),
                  Json_decode.field("literal", parseLiteral, json$1),
                  Json_decode.field("addedAllowedSourcesSet", (function (json) {
                          return Belt_SetString.fromArray(Json_decode.array(Json_decode.string, json));
                        }), json$1),
                  Json_decode.field("removedAllowedSourcesSet", (function (json) {
                          return Belt_SetString.fromArray(Json_decode.array(Json_decode.string, json));
                        }), json$1)
                ]
              };
    case "SetMetricGroupBy" :
        return {
                NAME: "SetMetricGroupBy",
                VAL: [
                  Json_decode.field("metricId", Json_decode.string, json$1),
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("id", Json_decode.string, json$1),
                  Json_decode.field("propertyId", Json_decode.string, json$1)
                ]
              };
    case "SetMetricGroupByV2" :
        return {
                NAME: "SetMetricGroupByV2",
                VAL: [
                  Json_decode.field("metricId", Json_decode.string, json$1),
                  Json_decode.field("metricItemId", Json_decode.string, json$1),
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("id", Json_decode.string, json$1),
                  Json_decode.field("propertyId", Json_decode.string, json$1)
                ]
              };
    case "SetMetricWhere" :
        return {
                NAME: "SetMetricWhere",
                VAL: [
                  Json_decode.field("metricId", Json_decode.string, json$1),
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("id", Json_decode.string, json$1),
                  Json_decode.field("propertyId", Json_decode.string, json$1),
                  Json_decode.field("binOp", parseBinOp, json$1),
                  Json_decode.field("literals", (function (param) {
                          return Json_decode.list(parseLiteral, param);
                        }), json$1)
                ]
              };
    case "SetMetricWhereV2" :
        return {
                NAME: "SetMetricWhereV2",
                VAL: [
                  Json_decode.field("metricId", Json_decode.string, json$1),
                  Json_decode.field("metricItemId", Json_decode.string, json$1),
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("id", Json_decode.string, json$1),
                  Json_decode.field("propertyId", Json_decode.string, json$1),
                  Json_decode.field("binOp", parseBinOp, json$1),
                  Json_decode.field("literals", (function (param) {
                          return Json_decode.list(parseLiteral, param);
                        }), json$1)
                ]
              };
    case "SetPropertyAbsenceOnVariant" :
        var dict$1 = Js_json.classify(json$1);
        var tmp;
        tmp = typeof dict$1 === "number" || dict$1.TAG !== /* JSONObject */2 ? Pervasives.failwith("Expected object in SetPropertyAbsenceOnVariant") : parseVariantPropertyAbsenceOverride(Js_dict.get(dict$1._0, "absence"));
        return {
                NAME: "SetPropertyAbsenceOnVariant",
                VAL: [
                  {
                    baseEventId: Json_decode.field("eventId", Json_decode.string, json$1),
                    variantId: Json_decode.field("variantId", Json_decode.string, json$1)
                  },
                  Json_decode.field("propertyId", Json_decode.string, json$1),
                  tmp
                ]
              };
    case "SetSourceOverrideOnVariant" :
        return {
                NAME: "SetSourceOverrideOnVariant",
                VAL: [
                  {
                    baseEventId: Json_decode.field("eventId", Json_decode.string, json$1),
                    variantId: Json_decode.field("variantId", Json_decode.string, json$1)
                  },
                  Json_decode.field("sourceId", Json_decode.string, json$1),
                  Json_decode.field("sourceOverrideType", parseVariantSourceOverrideType, json$1)
                ]
              };
    case "StartedImport" :
        return "StartedImport";
    case "SubscriptionCancelled" :
        return {
                NAME: "SubscriptionCancelled",
                VAL: [
                  Json_decode.field("paymentSource", Json_decode.string, json$1),
                  Json_decode.field("subscription", Json_decode.string, json$1)
                ]
              };
    case "SubscriptionStarted" :
        return {
                NAME: "SubscriptionStarted",
                VAL: [
                  Json_decode.field("paymentSource", Json_decode.string, json$1),
                  Json_decode.field("subscription", Json_decode.string, json$1)
                ]
              };
    case "SubscriptionUpdated" :
        return {
                NAME: "SubscriptionUpdated",
                VAL: [
                  Json_decode.field("paymentSource", Json_decode.string, json$1),
                  Json_decode.field("subscription", Json_decode.string, json$1)
                ]
              };
    case "ToggleDestination" :
        return {
                NAME: "LegacyToggleDestination",
                VAL: [
                  Json_decode.field("sourceId", Json_decode.string, json$1),
                  Json_decode.field("destinationId", Json_decode.string, json$1),
                  Json_decode.field("isActive", Json_decode.bool, json$1)
                ]
              };
    case "ToggleIncludeEventInCodegenForSource" :
        return {
                NAME: "ToggleIncludeEventInCodegenForSource",
                VAL: [
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("sourceId", Json_decode.string, json$1),
                  Json_decode.field("includeInCodegen", Json_decode.bool, json$1)
                ]
              };
    case "TogglePropertyExcludedSource" :
        return {
                NAME: "TogglePropertyExcludedSource",
                VAL: [
                  Json_decode.field("propertyId", Json_decode.string, json$1),
                  Json_decode.field("sourceId", Json_decode.string, json$1),
                  Json_decode.field("isActive", Json_decode.bool, json$1)
                ]
              };
    case "TogglePropertyValidationMatchSource" :
        return {
                NAME: "TogglePropertyValidationMatchSource",
                VAL: [
                  Json_decode.field("propertyId", Json_decode.string, json$1),
                  Json_decode.field("match", Json_decode.string, json$1),
                  Json_decode.field("sourceId", Json_decode.string, json$1),
                  Json_decode.field("isActive", Json_decode.bool, json$1)
                ]
              };
    case "Unarchive" :
        return {
                NAME: "Unarchive",
                VAL: parseItem(json$1)
              };
    case "UpdateDestinationAnalyticsTool" :
        return {
                NAME: "UpdateDestinationAnalyticsTool",
                VAL: [
                  Json_decode.field("destinationId", Json_decode.string, json$1),
                  Belt_Option.getExn(AvoConfigTypes.analyticsToolFromJs(Json_decode.field("analyticsTool", Json_decode.string, json$1)))
                ]
              };
    case "UpdateDestinationApiKey" :
        return {
                NAME: "UpdateDestinationApiKey",
                VAL: [
                  Json_decode.field("destinationId", Json_decode.string, json$1),
                  Json_decode.field("envName", Json_decode.string, json$1),
                  Json_decode.field("key", Json_decode.string, json$1)
                ]
              };
    case "UpdateDestinationDevApiKey" :
        return {
                NAME: "UpdateDestinationDevApiKey",
                VAL: [
                  Json_decode.field("destinationId", Json_decode.string, json$1),
                  Json_decode.field("key", Json_decode.string, json$1)
                ]
              };
    case "UpdateDestinationDisabledByDefault" :
        return {
                NAME: "UpdateDestinationDisabledByDefault",
                VAL: [
                  Json_decode.field("destinationId", Json_decode.string, json$1),
                  Json_decode.field("disabledByDefault", Json_decode.bool, json$1)
                ]
              };
    case "UpdateDestinationIncludeUserPropsWithEventProps" :
        return {
                NAME: "UpdateDestinationIncludeUserPropsWithEventProps",
                VAL: [
                  Json_decode.field("destinationId", Json_decode.string, json$1),
                  Json_decode.field("includeUserPropsWithEventProps", Json_decode.bool, json$1)
                ]
              };
    case "UpdateDestinationName" :
        return {
                NAME: "UpdateDestinationName",
                VAL: [
                  Json_decode.field("destinationId", Json_decode.string, json$1),
                  Json_decode.field("name", Json_decode.string, json$1)
                ]
              };
    case "UpdateDestinationProdApiKey" :
        return {
                NAME: "UpdateDestinationProdApiKey",
                VAL: [
                  Json_decode.field("destinationId", Json_decode.string, json$1),
                  Json_decode.field("key", Json_decode.string, json$1)
                ]
              };
    case "UpdateEventDescription" :
        return {
                NAME: "UpdateEventDescription",
                VAL: [
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("description", Json_decode.string, json$1)
                ]
              };
    case "UpdateEventName" :
        return {
                NAME: "UpdateEventName",
                VAL: [
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("name", Json_decode.string, json$1)
                ]
              };
    case "UpdateEventUniqueName" :
        return {
                NAME: "UpdateEventUniqueName",
                VAL: [
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("name", Json_decode.string, json$1)
                ]
              };
    case "UpdateEventUniqueNameV2" :
        return {
                NAME: "UpdateEventUniqueNameV2",
                VAL: [
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("name", Json_decode.string, json$1)
                ]
              };
    case "UpdateEventVariantDescription" :
        return {
                NAME: "UpdateEventVariantDescription",
                VAL: [
                  {
                    baseEventId: Json_decode.field("eventId", Json_decode.string, json$1),
                    variantId: Json_decode.field("variantId", Json_decode.string, json$1)
                  },
                  Json_decode.field("description", Json_decode.string, json$1)
                ]
              };
    case "UpdateEventVariantTriggerContent" :
        return {
                NAME: "UpdateEventVariantTriggerContent",
                VAL: [
                  {
                    baseEventId: Json_decode.field("eventId", Json_decode.string, json$1),
                    variantId: Json_decode.field("variantId", Json_decode.string, json$1)
                  },
                  Json_decode.field("triggerId", Json_decode.string, json$1),
                  Json_decode.field("content", (function (param) {
                          return Json_decode.optional(parseTriggerContent, param);
                        }), json$1)
                ]
              };
    case "UpdateEventVariantTriggerDescription" :
        return {
                NAME: "UpdateEventVariantTriggerDescription",
                VAL: [
                  {
                    baseEventId: Json_decode.field("eventId", Json_decode.string, json$1),
                    variantId: Json_decode.field("variantId", Json_decode.string, json$1)
                  },
                  Json_decode.field("triggerId", Json_decode.string, json$1),
                  Json_decode.field("description", Json_decode.string, json$1)
                ]
              };
    case "UpdateEventVariantTriggerSources" :
        return {
                NAME: "UpdateEventVariantTriggerSources",
                VAL: [
                  {
                    baseEventId: Json_decode.field("eventId", Json_decode.string, json$1),
                    variantId: Json_decode.field("variantId", Json_decode.string, json$1)
                  },
                  Json_decode.field("triggerId", Json_decode.string, json$1),
                  Json_decode.field("sources", parseTriggerSources, json$1)
                ]
              };
    case "UpdateGlobalRequirements" :
        return {
                NAME: "UpdateGlobalRequirements",
                VAL: parseGlobalRequirements(json$1)
              };
    case "UpdateGoalDescription" :
        return {
                NAME: "UpdateGoalDescription",
                VAL: [
                  Json_decode.field("goalId", Json_decode.string, json$1),
                  Json_decode.field("description", Json_decode.string, json$1)
                ]
              };
    case "UpdateGoalName" :
        return {
                NAME: "UpdateGoalName",
                VAL: [
                  Json_decode.field("goalId", Json_decode.string, json$1),
                  Json_decode.field("name", Json_decode.string, json$1)
                ]
              };
    case "UpdateGroupTypeName" :
        return {
                NAME: "UpdateGroupTypeName",
                VAL: [
                  Json_decode.field("groupTypeId", Json_decode.string, json$1),
                  Json_decode.field("groupTypeName", Json_decode.string, json$1)
                ]
              };
    case "UpdateInspectorIssueStatus" :
        return {
                NAME: "UpdateInspectorIssueStatus",
                VAL: parseInspectorIssueMetadata(json$1)
              };
    case "UpdateIntegrationAutoPublish" :
        return {
                NAME: "UpdateIntegrationAutoPublish",
                VAL: [
                  Json_decode.field("integrationId", Json_decode.string, json$1),
                  Json_decode.field("autoPublish", Json_decode.bool, json$1)
                ]
              };
    case "UpdateIntegrationConfig" :
        return {
                NAME: "UpdateIntegrationConfig",
                VAL: [
                  Json_decode.field("integrationId", Json_decode.string, json$1),
                  Json_decode.field("config", parseIntegrationConfig, json$1)
                ]
              };
    case "UpdateIntegrationDestinationIds" :
        return {
                NAME: "UpdateIntegrationDestinationIds",
                VAL: [
                  Json_decode.field("integrationId", Json_decode.string, json$1),
                  Json_decode.field("destinationIds", (function (param) {
                          return Json_decode.list(Json_decode.string, param);
                        }), json$1)
                ]
              };
    case "UpdateIntegrationFilters" :
        return {
                NAME: "UpdateIntegrationFilters",
                VAL: [
                  Json_decode.field("integrationId", Json_decode.string, json$1),
                  Json_decode.field("filters", parseIntegrationFilters, json$1)
                ]
              };
    case "UpdateIntegrationName" :
        return {
                NAME: "UpdateIntegrationName",
                VAL: [
                  Json_decode.field("integrationId", Json_decode.string, json$1),
                  Json_decode.field("name", Json_decode.string, json$1)
                ]
              };
    case "UpdateIntegrationType" :
        return {
                NAME: "UpdateIntegrationType",
                VAL: [
                  Json_decode.field("integrationId", Json_decode.string, json$1),
                  Json_decode.field("type", (function (json) {
                          return Belt_Option.getExn(TrackingPlanModel.integrationTypeFromJs(Json_decode.string(json)));
                        }), json$1)
                ]
              };
    case "UpdateLibraryDestination" :
        return {
                NAME: "UpdateLibraryDestination",
                VAL: [
                  Json_decode.field("sourceId", Json_decode.string, json$1),
                  Json_decode.field("path", Json_decode.string, json$1)
                ]
              };
    case "UpdateLibraryName" :
        return {
                NAME: "UpdateLibraryName",
                VAL: [
                  Json_decode.field("sourceId", Json_decode.string, json$1),
                  Json_decode.field("filename", Json_decode.string, json$1)
                ]
              };
    case "UpdateLocalWorkspaceName" :
        return {
                NAME: "UpdateLocalWorkspaceName",
                VAL: [
                  Json_decode.field("workspaceId", Json_decode.string, json$1),
                  Json_decode.field("workspaceName", Json_decode.string, json$1)
                ]
              };
    case "UpdateMemberRole" :
        return {
                NAME: "UpdateMemberRole",
                VAL: [
                  Json_decode.field("userId", Json_decode.string, json$1),
                  Json_decode.field("email", Json_decode.string, json$1),
                  Json_decode.field("fromRole", Json_decode.string, json$1),
                  Json_decode.field("toRole", Json_decode.string, json$1)
                ]
              };
    case "UpdateMetricDescription" :
        return {
                NAME: "UpdateMetricDescription",
                VAL: [
                  Json_decode.field("metricId", Json_decode.string, json$1),
                  Json_decode.field("description", Json_decode.string, json$1)
                ]
              };
    case "UpdateMetricName" :
        return {
                NAME: "UpdateMetricName",
                VAL: [
                  Json_decode.field("metricId", Json_decode.string, json$1),
                  Json_decode.field("name", Json_decode.string, json$1)
                ]
              };
    case "UpdateMetricType" :
        return {
                NAME: "UpdateMetricType",
                VAL: [
                  Json_decode.field("metricId", Json_decode.string, json$1),
                  Json_decode.field("type", parseMetricType, json$1)
                ]
              };
    case "UpdateNestedPropertyPinnedValue" :
        return {
                NAME: "UpdateNestedPropertyPinnedValue",
                VAL: [
                  Json_decode.field("propertyId", Json_decode.string, json$1),
                  Json_decode.field("nestedPropertyId", Json_decode.string, json$1),
                  Json_decode.field("pinnedValue", parseLiteral, json$1)
                ]
              };
    case "UpdateProgrammingLanguage" :
        return {
                NAME: "UpdateProgrammingLanguage",
                VAL: [
                  Json_decode.field("sourceId", Json_decode.string, json$1),
                  Belt_Option.getExn(AvoConfigTypes.languageFromJs(Json_decode.field("language", Json_decode.string, json$1)))
                ]
              };
    case "UpdatePropertyAbsence" :
        return {
                NAME: "UpdatePropertyAbsence",
                VAL: [
                  Json_decode.field("propertyId", Json_decode.string, json$1),
                  Json_decode.field("propertyAbsenceUpdateType", parsePropertyAbsenceUpdateType, json$1),
                  Json_decode.field("maybeEventSourceAbsence", (function (param) {
                          return Json_decode.optional(parseEventSourceAbsence, param);
                        }), json$1)
                ]
              };
    case "UpdatePropertyDescription" :
        return {
                NAME: "UpdatePropertyDescription",
                VAL: [
                  Json_decode.field("propertyId", Json_decode.string, json$1),
                  Json_decode.field("description", Json_decode.string, json$1)
                ]
              };
    case "UpdatePropertyGroupDescription" :
        return {
                NAME: "UpdatePropertyGroupDescription",
                VAL: [
                  Json_decode.field("groupId", Json_decode.string, json$1),
                  Json_decode.field("description", Json_decode.string, json$1)
                ]
              };
    case "UpdatePropertyGroupName" :
        return {
                NAME: "UpdatePropertyGroupName",
                VAL: [
                  Json_decode.field("groupId", Json_decode.string, json$1),
                  Json_decode.field("name", Json_decode.string, json$1)
                ]
              };
    case "UpdatePropertyList" :
        return {
                NAME: "UpdatePropertyList",
                VAL: [
                  Json_decode.field("propertyId", Json_decode.string, json$1),
                  Json_decode.field("list", Json_decode.bool, json$1)
                ]
              };
    case "UpdatePropertyName" :
        return {
                NAME: "UpdatePropertyName",
                VAL: [
                  Json_decode.field("propertyId", Json_decode.string, json$1),
                  Json_decode.field("name", Json_decode.string, json$1)
                ]
              };
    case "UpdatePropertyOperation" :
        return {
                NAME: "UpdatePropertyOperation",
                VAL: [
                  Json_decode.field("propertyId", Json_decode.string, json$1),
                  Belt_Option.getExn(TrackingPlanModel.operationFromJs(Json_decode.field("operation", Json_decode.string, json$1)))
                ]
              };
    case "UpdatePropertyOptional" :
        return {
                NAME: "UpdatePropertyOptional",
                VAL: [
                  Json_decode.field("propertyId", Json_decode.string, json$1),
                  Json_decode.field("optional", Json_decode.bool, json$1)
                ]
              };
    case "UpdatePropertyOptionalWhenInObject" :
        return {
                NAME: "UpdatePropertyOptionalWhenInObject",
                VAL: [
                  Json_decode.field("propertyId", Json_decode.string, json$1),
                  Json_decode.field("optional", Json_decode.bool, json$1)
                ]
              };
    case "UpdatePropertyPinnedValue" :
        return {
                NAME: "UpdatePropertyPinnedValue",
                VAL: [
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("propertyId", Json_decode.string, json$1),
                  Json_decode.field("pinnedValue", parseLiteral, json$1)
                ]
              };
    case "UpdatePropertyPinnedValueOnEventVariant" :
        return {
                NAME: "UpdatePropertyPinnedValueOnEventVariant",
                VAL: [
                  {
                    baseEventId: Json_decode.field("eventId", Json_decode.string, json$1),
                    variantId: Json_decode.field("variantId", Json_decode.string, json$1)
                  },
                  Json_decode.field("propertyId", Json_decode.string, json$1),
                  Json_decode.field("literal", parseLiteral, json$1)
                ]
              };
    case "UpdatePropertyRegexOverrideOnEventVariant" :
        return {
                NAME: "UpdatePropertyRegexOverrideOnEventVariant",
                VAL: [
                  {
                    baseEventId: Json_decode.field("eventId", Json_decode.string, json$1),
                    variantId: Json_decode.field("variantId", Json_decode.string, json$1)
                  },
                  Json_decode.field("propertyId", Json_decode.string, json$1),
                  Json_decode.field("regexValidationRule", (function (param) {
                          return Json_decode.optional(parseRegexValidationRule, param);
                        }), json$1)
                ]
              };
    case "UpdatePropertyRegexValidation" :
        return {
                NAME: "UpdatePropertyRegexValidation",
                VAL: [
                  Json_decode.field("propertyId", Json_decode.string, json$1),
                  Json_decode.optional((function (param) {
                          return Json_decode.field("eventId", Json_decode.string, param);
                        }), json$1),
                  Json_decode.optional((function (param) {
                          return Json_decode.field("rule", parseRegexValidationRule, param);
                        }), json$1)
                ]
              };
    case "UpdatePropertySendAs" :
        return {
                NAME: "UpdatePropertySendAs",
                VAL: [
                  Json_decode.field("propertyId", Json_decode.string, json$1),
                  Json_decode.field("sendAs", parseSendAs, json$1)
                ]
              };
    case "UpdatePropertyType" :
        return {
                NAME: "UpdatePropertyType",
                VAL: [
                  Json_decode.field("propertyId", Json_decode.string, json$1),
                  Json_decode.field("type", Json_decode.string, json$1)
                ]
              };
    case "UpdatePropertyUniqueName" :
        return {
                NAME: "UpdatePropertyUniqueName",
                VAL: [
                  Json_decode.field("propertyId", Json_decode.string, json$1),
                  Json_decode.field("name", Json_decode.string, json$1)
                ]
              };
    case "UpdatePropertyValidationMinOrMax" :
        return {
                NAME: "DeprecatedUpdatePropertyValidationMinOrMax",
                VAL: [
                  Json_decode.field("propertyId", Json_decode.string, json$1),
                  Json_decode.field("newValue", Json_decode.string, json$1),
                  Belt_Option.getExn(TrackingPlanModel.constraint_FromJs(Json_decode.field("validationType", Json_decode.string, json$1))),
                  Json_decode.field("validationIndex", Json_decode.$$int, json$1)
                ]
              };
    case "UpdateRule" :
        return {
                NAME: "UpdateRule",
                VAL: [
                  Json_decode.field("ruleId", Json_decode.string, json$1),
                  Json_decode.field("item", parseRuleItem, json$1),
                  Json_decode.field("definition", parseRuleDefinition, json$1)
                ]
              };
    case "UpdateSavedView" :
        return {
                NAME: "UpdateSavedView",
                VAL: [
                  Json_decode.field("savedViewId", Json_decode.string, json$1),
                  Json_decode.field("savedViewName", Json_decode.string, json$1)
                ]
              };
    case "UpdateSourceDestinationMode" :
        return {
                NAME: "UpdateSourceDestinationMode",
                VAL: [
                  Json_decode.field("sourceId", Json_decode.string, json$1),
                  Json_decode.field("destinationId", Json_decode.string, json$1),
                  Json_decode.field("destinationMode", parseSourceDestinationMode, json$1)
                ]
              };
    case "UpdateSourceName" :
        return {
                NAME: "UpdateSourceName",
                VAL: [
                  Json_decode.field("sourceId", Json_decode.string, json$1),
                  Json_decode.field("name", Json_decode.string, json$1)
                ]
              };
    case "UpdateSourcePlatform" :
        return {
                NAME: "UpdateSourcePlatform",
                VAL: [
                  Json_decode.field("sourceId", Json_decode.string, json$1),
                  Belt_Option.getExn(AvoConfigTypes.devPlatformFromJs(Json_decode.field("platform", Json_decode.string, json$1)))
                ]
              };
    case "UpdateSourcePlatformV2" :
        return {
                NAME: "UpdateSourcePlatformV2",
                VAL: [
                  Json_decode.field("sourceId", Json_decode.string, json$1),
                  Belt_Option.getExn(AvoConfigTypes.devPlatformFromJs(Json_decode.field("platform", Json_decode.string, json$1)))
                ]
              };
    case "UpdateSourcePlatformV3" :
        return {
                NAME: "UpdateSourcePlatformV3",
                VAL: [
                  Json_decode.field("sourceId", Json_decode.string, json$1),
                  Belt_Option.flatMap(Json_decode.field("platform", (function (param) {
                              return Json_decode.optional(Json_decode.string, param);
                            }), json$1), AvoConfigTypes.devPlatformFromJs)
                ]
              };
    case "UpdateSourceSegmentIntegration" :
        return {
                NAME: "UpdateSourceSegmentIntegration",
                VAL: [
                  Json_decode.field("sourceId", Json_decode.string, json$1),
                  Json_decode.field("segmentIntegration", Json_decode.string, json$1),
                  Json_decode.field("isActive", Json_decode.bool, json$1)
                ]
              };
    case "UpdateTrackingPlanAuditConfig" :
        return {
                NAME: "UpdateTrackingPlanAuditConfig",
                VAL: [
                  Json_decode.field("configItem", parseTrackingPlanAuditConfigItem, json$1),
                  Json_decode.optional((function (param) {
                          return Json_decode.field("ruleSeverity", parseTrackingPlanAuditRuleSeverity, param);
                        }), json$1),
                  Json_decode.optional((function (param) {
                          return Json_decode.field("pointOfBlock", parseTrackingPlanAuditPointOfBlock, param);
                        }), json$1)
                ]
              };
    case "UpdateTriggerContent" :
        return {
                NAME: "UpdateTriggerContent",
                VAL: [
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("triggerId", Json_decode.string, json$1),
                  Json_decode.field("content", (function (param) {
                          return Json_decode.optional(parseTriggerContent, param);
                        }), json$1)
                ]
              };
    case "UpdateTriggerDescription" :
        return {
                NAME: "UpdateTriggerDescription",
                VAL: [
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("triggerId", Json_decode.string, json$1),
                  Json_decode.field("description", Json_decode.string, json$1)
                ]
              };
    case "UpdateTriggerSources" :
        return {
                NAME: "UpdateTriggerSources",
                VAL: [
                  Json_decode.field("eventId", Json_decode.string, json$1),
                  Json_decode.field("triggerId", Json_decode.string, json$1),
                  Json_decode.field("sources", parseTriggerSources, json$1)
                ]
              };
    default:
      return Pervasives.failwith("Unexpected action in Parser.parseAction: " + key);
  }
}

export {
  decodeOptionalKey ,
  parseLiteral ,
  parseVariantSourceOverrideType ,
  parseBinOp ,
  parseExp ,
  parsePropertyRef ,
  parseInspectorIssueMetadata ,
  parseRegexValidationRule ,
  parseCoreType ,
  parseShapeValueType ,
  parseShapeValue ,
  parseShape ,
  parseValidation ,
  parseItem ,
  parseCollaborationItem ,
  parseRuleItem ,
  parseRuleIdTuple ,
  parseRuleIdTuples ,
  parserMappingDestination ,
  parseMappingWorkspace ,
  parseNameMapping ,
  parseWorkspaceNameMapping ,
  parseSegmentIntegrationOption ,
  parseRuleDefinition ,
  parseRule ,
  parseIntegrationConfig ,
  parseIntegrationFilter ,
  parseIntegrationFilters ,
  parseIntegration ,
  parseIntegrations ,
  parsePropertyAbsenceUpdateType ,
  parseEventSourceAbsence ,
  parsePropertyAbsenceEventSourceMap ,
  parsePropertyAbsenceEventMap ,
  parsePropertyAbsence ,
  parseDisallowedState ,
  parseDisallowedEvents ,
  parseEventSpecificPropertyValues ,
  parsePropertyOptional ,
  parsePropertyOptionalWhenInObject ,
  parsePropertyExcludedSources ,
  parseSendAs ,
  parseGlobalMetadata ,
  parseTrackingPlanProperty ,
  parseProperty ,
  parseProperties ,
  parsePropertyBundleRef ,
  parsePropertyBundleRefs ,
  parsePropertyGroup ,
  parsePropertyGroups ,
  parsePropertyBundlesWithBundlesInJson ,
  parseEventHash ,
  parsePropertyWhitelist ,
  parseSourceDestinationRefs ,
  parseInspectorValidation ,
  parseSourceValidationMap ,
  parseIncludedSource ,
  parseImageMetadata ,
  parseFigmaMetadata ,
  parseTriggerContent ,
  parseTriggerSources ,
  parseEventTrigger ,
  parseEventTriggers ,
  parseTriggerIdTuple ,
  parseTriggerIdTuples ,
  parseVariantPropertyAbsenceOverride ,
  parseVariantPropertyAllowedValuesOverride ,
  parseVariantPropertyOverride ,
  parseVariantSourceOverride ,
  parseEventVariant ,
  parseEvent ,
  parseEventIdTuple ,
  parseType ,
  parseEvents ,
  parseMigration ,
  parseMigrations ,
  parseSegmentDestinationOption ,
  parseLegacySource ,
  parseLegacySources ,
  parseSourceConfig ,
  parseSourceDestinationMode ,
  parseSourceDestination ,
  parseSource ,
  parseSources ,
  parseOtherEnvKey ,
  parseGroupType ,
  parseDestination ,
  parseDestinations ,
  parseGroupTypes ,
  parseGoal ,
  parseGoals ,
  parseMetricEventItemWhere ,
  parseMetricEventItemGroupBy ,
  parseMetricEventItem ,
  parseMetricItem ,
  parseMetricType ,
  parseBranchStatus ,
  parseMetric ,
  parseMetrics ,
  parseArchive ,
  parseBranch ,
  parseBranches ,
  parseBranchPointer ,
  parsePublishingSuccessStats ,
  parseGlobalRequirementsMetadata ,
  parseGlobalRequirements ,
  parseTrackingPlanAuditConfigItem ,
  parseTrackingPlanAuditRuleSeverity ,
  parseTrackingPlanAuditPointOfBlock ,
  parseSchema ,
  parseSchemaMapped ,
  parseSchemaExn ,
  parseSchemaMappedExn ,
  parseSchemaString ,
  parseSchemaStringExn ,
  parseAction ,
  
}
/* AvoConfig Not a pure module */
