// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";

function encode(propertyType) {
  switch (propertyType) {
    case /* String */0 :
        return "string";
    case /* Int */1 :
        return "int";
    case /* Float */2 :
        return "float";
    case /* Bool */3 :
        return "bool";
    case /* Number */4 :
        return "number";
    case /* Object */5 :
        return "object";
    case /* Any */6 :
        return "any";
    
  }
}

function decode(json) {
  var match = Json_decode.string(json);
  switch (match) {
    case "any" :
        return /* Any */6;
    case "bool" :
        return /* Bool */3;
    case "float" :
        return /* Float */2;
    case "int" :
        return /* Int */1;
    case "number" :
        return /* Number */4;
    case "object" :
        return /* Object */5;
    case "string" :
        return /* String */0;
    default:
      return Pervasives.failwith("Unknown Property Type");
  }
}

function toPropertyType(str) {
  switch (str) {
    case "any" :
        return /* Any */6;
    case "bool" :
        return /* Bool */3;
    case "float" :
        return /* Float */2;
    case "int" :
        return /* Int */1;
    case "number" :
        return /* Number */4;
    case "object" :
        return /* Object */5;
    case "string" :
        return /* String */0;
    default:
      return ;
  }
}

function toString(t) {
  switch (t) {
    case /* String */0 :
        return "string";
    case /* Int */1 :
        return "int";
    case /* Float */2 :
        return "float";
    case /* Bool */3 :
        return "bool";
    case /* Number */4 :
        return "number";
    case /* Object */5 :
        return "object";
    case /* Any */6 :
        return "any";
    
  }
}

function consolidatePropertyType(propertyType) {
  var propertyType$1 = propertyType.toLowerCase();
  switch (propertyType$1) {
    case "any" :
        return "any";
    case "bool" :
    case "boolean" :
        return "bool";
    case "float" :
        return "float";
    case "int" :
    case "integer" :
        return "int";
    case "list(bool)" :
    case "list(boolean)" :
    case "list<bool>" :
    case "list<boolean>" :
        return "list(bool)";
    case "list(float)" :
    case "list<float>" :
        return "list(float)";
    case "list(int)" :
    case "list(integer)" :
    case "list<int>" :
    case "list<integer>" :
        return "list(int)";
    case "list(number)" :
    case "list<number>" :
        return "list(number)";
    case "list(object)" :
    case "list<object>" :
        return "list(object)";
    case "list(string)" :
    case "list<string>" :
        return "list(string)";
    case "number" :
        return "number";
    case "object" :
        return "object";
    case "string" :
        return "string";
    case "none" :
    case "null" :
    case "undefined" :
        return ;
    default:
      if (propertyType$1.startsWith("list({")) {
        return "list(object)";
      }
      if (propertyType$1.startsWith("{")) {
        return "object";
      }
      if (propertyType$1.startsWith("list<{")) {
        return "list(object)";
      }
      if (propertyType$1.startsWith("list(unknown)")) {
        return "list(object)";
      }
      switch (propertyType$1) {
        case "list(any)" :
            return "list(any)";
        case "list" :
        case "list<>" :
            return "list";
        case "unknown" :
            return "any";
        default:
          console.error("Unkown Property Type, Defaulting to any", {
                propertyType: propertyType$1
              });
          return "any";
      }
  }
}

function isList(propertyType) {
  try {
    var type_ = consolidatePropertyType(propertyType);
    if (type_ !== undefined) {
      return type_.startsWith("list");
    } else {
      return false;
    }
  }
  catch (_error){
    return false;
  }
}

function extractRawTypeFromList(propertyType) {
  var match = propertyType.split("(");
  if (match.length !== 2) {
    return ;
  }
  var rawType = match[1];
  var match$1 = rawType.split(")");
  if (match$1.length !== 2) {
    return ;
  } else {
    return match$1[0];
  }
}

function enrichListPropertyTypeWithTrackingPlan(propertyType, trackingPlanPropertyType) {
  if (propertyType === "list") {
    if (trackingPlanPropertyType !== undefined) {
      return "list(" + trackingPlanPropertyType + ")";
    } else {
      return "list(any)";
    }
  } else {
    return propertyType;
  }
}

function getRawType(propertyType, trackingPlanPropertyType) {
  var type_ = consolidatePropertyType(propertyType);
  if (type_ === undefined) {
    return ;
  }
  var type_$1 = enrichListPropertyTypeWithTrackingPlan(type_, trackingPlanPropertyType);
  if (!type_$1.startsWith("list(")) {
    return type_$1;
  }
  var match = type_$1.split("(");
  if (match.length !== 2) {
    return ;
  }
  var rawType = match[1];
  var match$1 = rawType.split(")");
  if (match$1.length !== 2) {
    return ;
  } else {
    return match$1[0];
  }
}

function fromConsolidatedString(str) {
  if (isList(str)) {
    return Belt_Option.flatMap(extractRawTypeFromList(str), toPropertyType);
  } else {
    return toPropertyType(str);
  }
}

function fromString(str) {
  try {
    return Belt_Option.flatMap(consolidatePropertyType(str), fromConsolidatedString);
  }
  catch (_error){
    return ;
  }
}

function validatePropertyType(propertyType) {
  try {
    consolidatePropertyType(propertyType);
    return true;
  }
  catch (_error){
    return false;
  }
}

function comparePropertyTypes(propertyTypeA, propertyTypeB) {
  var consolidatedPropertyTypeA = consolidatePropertyType(propertyTypeA);
  var consolidatedPropertyTypeB = consolidatePropertyType(propertyTypeB);
  if (consolidatedPropertyTypeA !== undefined && !(consolidatedPropertyTypeA === "null" || !(consolidatedPropertyTypeB !== undefined && consolidatedPropertyTypeB !== "null"))) {
    return consolidatedPropertyTypeA === consolidatedPropertyTypeB;
  } else {
    return true;
  }
}

function addListToRawType(rawType) {
  return "list(" + rawType + ")";
}

export {
  encode ,
  decode ,
  toPropertyType ,
  toString ,
  consolidatePropertyType ,
  isList ,
  extractRawTypeFromList ,
  enrichListPropertyTypeWithTrackingPlan ,
  getRawType ,
  fromConsolidatedString ,
  fromString ,
  validatePropertyType ,
  comparePropertyTypes ,
  addListToRawType ,
  
}
/* No side effect */
