// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as React from "react";
import * as Zustand from "./Zustand.mjs";
import Shortid from "shortid";
import * as Zustand$1 from "zustand";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RouterTypes from "./RouterTypes.mjs";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.mjs";

function getSchemaIdFromBaseRoute(baseRoute) {
  if (typeof baseRoute === "object" && baseRoute.NAME === "schema") {
    return baseRoute.VAL;
  }
  
}

var StoreConfig = {};

var ZustandStore = Zustand.MakeStore(StoreConfig);

var store = Zustand$1.create(function (set, _get) {
      var initialUrl = RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined);
      return {
              baseRoute: RouterTypes.BaseRoute.get(initialUrl.path),
              branch: RouterTypes.Branch.get(initialUrl.path),
              schemaRoute: RouterTypes.SchemaRoute.get(initialUrl.path),
              drawerItems: RouterTypes.DrawerItems.get(initialUrl.path),
              schemaId: getSchemaIdFromBaseRoute(RouterTypes.BaseRoute.get(initialUrl.path)),
              setRoute: (function (path) {
                  return set(function (state) {
                              var baseRoute = Caml_obj.caml_equal(RouterTypes.BaseRoute.get(path), state.baseRoute) ? state.baseRoute : RouterTypes.BaseRoute.get(path);
                              var branch = Caml_obj.caml_equal(RouterTypes.Branch.get(path), state.branch) ? state.branch : RouterTypes.Branch.get(path);
                              var schemaRoute = Caml_obj.caml_equal(RouterTypes.SchemaRoute.get(path), state.schemaRoute) ? state.schemaRoute : RouterTypes.SchemaRoute.get(path);
                              var drawerItems = Caml_obj.caml_equal(Belt_List.head(RouterTypes.DrawerItems.get(path)), Belt_List.head(state.drawerItems)) ? state.drawerItems : RouterTypes.DrawerItems.get(path);
                              var schemaId = Caml_obj.caml_equal(getSchemaIdFromBaseRoute(baseRoute), state.schemaId) ? state.schemaId : getSchemaIdFromBaseRoute(baseRoute);
                              return {
                                      baseRoute: baseRoute,
                                      branch: branch,
                                      schemaRoute: schemaRoute,
                                      drawerItems: drawerItems,
                                      schemaId: schemaId,
                                      setRoute: state.setRoute,
                                      routeChangePreventionSubscribers: state.routeChangePreventionSubscribers,
                                      addRouteChangePreventionSubscriber: state.addRouteChangePreventionSubscriber,
                                      removeRouteChangePreventionSubscriber: state.removeRouteChangePreventionSubscriber
                                    };
                            });
                }),
              routeChangePreventionSubscribers: [],
              addRouteChangePreventionSubscriber: (function (id, routeThreshold, callback) {
                  return set(function (state) {
                              return {
                                      baseRoute: state.baseRoute,
                                      branch: state.branch,
                                      schemaRoute: state.schemaRoute,
                                      drawerItems: state.drawerItems,
                                      schemaId: state.schemaId,
                                      setRoute: state.setRoute,
                                      routeChangePreventionSubscribers: Belt_Array.concat(state.routeChangePreventionSubscribers, [[
                                              id,
                                              routeThreshold,
                                              callback
                                            ]]),
                                      addRouteChangePreventionSubscriber: state.addRouteChangePreventionSubscriber,
                                      removeRouteChangePreventionSubscriber: state.removeRouteChangePreventionSubscriber
                                    };
                            });
                }),
              removeRouteChangePreventionSubscriber: (function (id) {
                  return set(function (state) {
                              return {
                                      baseRoute: state.baseRoute,
                                      branch: state.branch,
                                      schemaRoute: state.schemaRoute,
                                      drawerItems: state.drawerItems,
                                      schemaId: state.schemaId,
                                      setRoute: state.setRoute,
                                      routeChangePreventionSubscribers: Belt_Array.keepU(state.routeChangePreventionSubscribers, (function (param) {
                                              return param[0] !== id;
                                            })),
                                      addRouteChangePreventionSubscriber: state.addRouteChangePreventionSubscriber,
                                      removeRouteChangePreventionSubscriber: state.removeRouteChangePreventionSubscriber
                                    };
                            });
                })
            };
    });

function getState(param) {
  return Curry._1(ZustandStore.getState, store);
}

function useBaseRoute(param) {
  var baseRoute = Curry._2(ZustandStore.use, store, (function (state) {
          return state.baseRoute;
        }));
  return React.useMemo((function () {
                return baseRoute;
              }), [baseRoute]);
}

function useBranch(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.branch;
              }));
}

function useSchemaRoute(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.schemaRoute;
              }));
}

function useSavedViewId(param) {
  var schemaRoute = Curry._2(ZustandStore.use, store, (function (state) {
          return state.schemaRoute;
        }));
  var tmp;
  if (typeof schemaRoute === "object" && schemaRoute.NAME === "inspector") {
    var match = schemaRoute.VAL;
    tmp = typeof match === "object" && match.NAME === "savedView" ? match.VAL : "NoId";
  } else {
    tmp = "NoId";
  }
  return React.useMemo((function () {
                if (typeof schemaRoute !== "object") {
                  return ;
                }
                if (schemaRoute.NAME !== "inspector") {
                  return ;
                }
                var match = schemaRoute.VAL;
                if (typeof match === "object" && match.NAME === "savedView") {
                  return match.VAL;
                }
                
              }), [tmp]);
}

function useDrawerItems(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.drawerItems;
              }));
}

function useHasDrawerItems(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return Belt_List.length(state.drawerItems) > 0;
              }));
}

function useSchemaId(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return Belt_Option.getExn(state.schemaId);
              }));
}

function useSlices(cb) {
  return Curry._2(ZustandStore.useShallow, store, Curry.__1(cb));
}

function useBranchAndSchemaId(param) {
  return Curry._2(ZustandStore.useShallow, store, (function (state) {
                return [
                        state.branch,
                        Belt_Option.getExn(state.schemaId)
                      ];
              }));
}

function usePreventRoute(active, route, cb) {
  var subscriberId = React.useMemo((function () {
          return Shortid();
        }), []);
  var addRouteChangePreventionSubscriber = Curry._2(ZustandStore.use, store, (function (state) {
          return state.addRouteChangePreventionSubscriber;
        }));
  var removeRouteChangePreventionSubscriber = Curry._2(ZustandStore.use, store, (function (state) {
          return state.removeRouteChangePreventionSubscriber;
        }));
  React.useEffect((function () {
          if (active) {
            Curry._3(addRouteChangePreventionSubscriber, subscriberId, route, (function (pushCb) {
                    return Curry._1(cb, (function (param) {
                                  Curry._1(removeRouteChangePreventionSubscriber, subscriberId);
                                  return Curry._1(pushCb, undefined);
                                }));
                  }));
          } else {
            Curry._1(removeRouteChangePreventionSubscriber, subscriberId);
          }
          return (function (param) {
                    return Curry._1(removeRouteChangePreventionSubscriber, subscriberId);
                  });
        }), [active]);
  
}

var Schema = {
  getState: getState,
  useBaseRoute: useBaseRoute,
  useBranch: useBranch,
  useSchemaRoute: useSchemaRoute,
  useSavedViewId: useSavedViewId,
  useDrawerItems: useDrawerItems,
  useHasDrawerItems: useHasDrawerItems,
  useSchemaId: useSchemaId,
  useSlices: useSlices,
  useBranchAndSchemaId: useBranchAndSchemaId,
  usePreventRoute: usePreventRoute
};

function useWatchUrl(url) {
  var setRoute = Curry._2(ZustandStore.use, store, (function (state) {
          return state.setRoute;
        }));
  return Hooks.useDidUpdate1((function (param) {
                Curry._1(setRoute, url.path);
                
              }), [Belt_List.toArray(url.path).join("/")]);
}

function RouterStore$Watcher(Props) {
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  useWatchUrl(url);
  return null;
}

var Watcher = {
  make: RouterStore$Watcher
};

export {
  getSchemaIdFromBaseRoute ,
  StoreConfig ,
  ZustandStore ,
  store ,
  Schema ,
  useWatchUrl ,
  Watcher ,
  
}
/* ZustandStore Not a pure module */
