// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import Shortid from "shortid";
import * as AvoModel from "../../../app/src/avoModel.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "../../../app/src/ModelUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as ModelUtils_mapped from "../../../app/src/ModelUtils_mapped.mjs";
import * as BeltListExtensions from "../../../app/src/BeltListExtensions.mjs";
import * as TrackingPlanMappedModel from "../../../model/src/TrackingPlanMappedModel.mjs";
import * as ComputeUnionAllowedValuesUseCase from "../../../model/src/eventSpecificPropertyValues/ComputeUnionAllowedValuesUseCase.mjs";
import * as ComputeUniqueAllowedValuesSetsUseCase from "../../../model/src/eventSpecificPropertyValues/ComputeUniqueAllowedValuesSetsUseCase.mjs";

function enrichWithVirtualProperties(mappedModel, sourceId) {
  return Curry._3(TrackingPlanMappedModel.Properties.reduce, mappedModel.properties, mappedModel, (function (mappedModel, property) {
                if (!(ModelUtils_mapped.hasMigrated(mappedModel, "EventSpecificAllowedPropertyValues") && property.type_ === "string")) {
                  return mappedModel;
                }
                var handleNoVirtualPropertiesNeeded = function (model) {
                  return {
                          archive: model.archive,
                          branchPointer: model.branchPointer,
                          migrations: model.migrations,
                          rules: model.rules,
                          types: model.types,
                          categories: model.categories,
                          destinations: model.destinations,
                          events: model.events,
                          groupTypes: model.groupTypes,
                          integrations: model.integrations,
                          metrics: model.metrics,
                          openBranches: model.openBranches,
                          properties: Curry._3(TrackingPlanMappedModel.Properties.updateExisting, model.properties, property.id, (function (property) {
                                  var allowedValues = ComputeUnionAllowedValuesUseCase.computeWithMappedModel(model, [sourceId], property);
                                  var newMatches = Belt_List.fromArray(Belt_Array.mapU(allowedValues, (function (value) {
                                              return [
                                                      {
                                                        NAME: "Literal",
                                                        VAL: {
                                                          NAME: "StringLit",
                                                          VAL: value
                                                        }
                                                      },
                                                      /* [] */0
                                                    ];
                                            })));
                                  var existingValidationsWithoutMatches = Belt_List.keepU(property.validations, (function (item) {
                                          if (typeof item === "object") {
                                            return item.NAME !== "Matches";
                                          } else {
                                            return true;
                                          }
                                        }));
                                  var validations = Belt_List.concat(existingValidationsWithoutMatches, Belt_List.length(newMatches) > 0 ? ({
                                            hd: {
                                              NAME: "Matches",
                                              VAL: newMatches
                                            },
                                            tl: /* [] */0
                                          }) : /* [] */0);
                                  return {
                                          id: property.id,
                                          name: property.name,
                                          uniqueName: property.uniqueName,
                                          description: property.description,
                                          type_: property.type_,
                                          sendAs: property.sendAs,
                                          validations: validations,
                                          eventSpecificAllowedPropertyValues: property.eventSpecificAllowedPropertyValues,
                                          auto: property.auto,
                                          builtIn: property.builtIn,
                                          analyticsTools: property.analyticsTools,
                                          devPlatforms: property.devPlatforms,
                                          list: property.list,
                                          operation: property.operation,
                                          optionalDeprecated: property.optionalDeprecated,
                                          excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                          optionalWhenInObject: property.optionalWhenInObject,
                                          absence: property.absence,
                                          globalRequirementsMetadata: property.globalRequirementsMetadata
                                        };
                                })),
                          propertyBundles: model.propertyBundles,
                          sources: model.sources,
                          globalRequirements: model.globalRequirements
                        };
                };
                if (property.eventSpecificAllowedPropertyValues.length === 0 || ModelUtils_mapped.isPropertyIdNested(mappedModel, property.id)) {
                  return handleNoVirtualPropertiesNeeded(mappedModel);
                }
                var modelPropertiesArray = Curry._1(TrackingPlanMappedModel.Properties.toArray, mappedModel.properties);
                var uniqueAllowedValuesSets = ComputeUniqueAllowedValuesSetsUseCase.compute(mappedModel, modelPropertiesArray, [sourceId], undefined, property);
                if (uniqueAllowedValuesSets.allowedValuesWithNames.length === 0) {
                  return handleNoVirtualPropertiesNeeded(mappedModel);
                }
                var newProperties = Belt_Array.mapWithIndexU(uniqueAllowedValuesSets.allowedValuesWithNames, (function (index, param) {
                        var newMatches = Belt_List.fromArray(Belt_Array.mapU(param[0], (function (value) {
                                    return [
                                            {
                                              NAME: "Literal",
                                              VAL: {
                                                NAME: "StringLit",
                                                VAL: value
                                              }
                                            },
                                            /* [] */0
                                          ];
                                  })));
                        return {
                                id: index === 0 ? property.id : Shortid(),
                                name: param[1],
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: {
                                  hd: {
                                    NAME: "Matches",
                                    VAL: newMatches
                                  },
                                  tl: /* [] */0
                                },
                                eventSpecificAllowedPropertyValues: property.eventSpecificAllowedPropertyValues,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence,
                                globalRequirementsMetadata: property.globalRequirementsMetadata
                              };
                      }));
                return {
                        archive: mappedModel.archive,
                        branchPointer: mappedModel.branchPointer,
                        migrations: mappedModel.migrations,
                        rules: mappedModel.rules,
                        types: mappedModel.types,
                        categories: mappedModel.categories,
                        destinations: mappedModel.destinations,
                        events: Curry._2(TrackingPlanMappedModel.Events.mapToT, mappedModel.events, (function ($$event) {
                                return {
                                        id: $$event.id,
                                        name: $$event.name,
                                        uniqueName: $$event.uniqueName,
                                        description: $$event.description,
                                        properties: Belt_List.mapU($$event.properties, (function (propertyRef) {
                                                var propertyId = ModelUtils.getPropertyId(propertyRef);
                                                if (propertyId !== property.id) {
                                                  return propertyRef;
                                                }
                                                var newPropertyName = Belt_MapString.get(uniqueAllowedValuesSets.eventIdToAllowedValuesDataStructureName, $$event.id);
                                                var newProperty = Belt_Array.getByU(newProperties, (function (property) {
                                                        return Caml_obj.caml_equal(property.name, newPropertyName);
                                                      }));
                                                if (newProperty === undefined) {
                                                  return propertyRef;
                                                }
                                                if (propertyRef.TAG !== /* PropertyRef */0) {
                                                  return propertyRef;
                                                }
                                                var propertyRef$1 = propertyRef._0;
                                                var newPropertyRef_id = newProperty.id;
                                                var newPropertyRef_description = propertyRef$1.description;
                                                var newPropertyRef_pinnedValue = propertyRef$1.pinnedValue;
                                                var newPropertyRef = {
                                                  id: newPropertyRef_id,
                                                  description: newPropertyRef_description,
                                                  pinnedValue: newPropertyRef_pinnedValue
                                                };
                                                return {
                                                        TAG: /* PropertyRef */0,
                                                        _0: newPropertyRef
                                                      };
                                              })),
                                        propertyBundles: $$event.propertyBundles,
                                        variants: $$event.variants,
                                        types: $$event.types,
                                        tags: $$event.tags,
                                        excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                        includeSources: $$event.includeSources,
                                        includeDestinations: $$event.includeDestinations,
                                        hashes: $$event.hashes,
                                        propertyWhitelist: $$event.propertyWhitelist,
                                        eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                        userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                        triggers: $$event.triggers,
                                        globalRequirementsMetadata: $$event.globalRequirementsMetadata
                                      };
                              })),
                        groupTypes: mappedModel.groupTypes,
                        integrations: mappedModel.integrations,
                        metrics: mappedModel.metrics,
                        openBranches: mappedModel.openBranches,
                        properties: Curry._2(TrackingPlanMappedModel.Properties.mergeMany, Curry._2(TrackingPlanMappedModel.Properties.keep, mappedModel.properties, (function (modelProperty) {
                                    return modelProperty.id !== property.id;
                                  })), Curry._1(TrackingPlanMappedModel.Properties.fromArray, newProperties)),
                        propertyBundles: mappedModel.propertyBundles,
                        sources: mappedModel.sources,
                        globalRequirements: mappedModel.globalRequirements
                      };
              }));
}

function enrichWithVirtualPropertiesSlow(model, mappedModel, sourceId) {
  return Belt_List.reduceU(model.properties, model, (function (model, property) {
                if (property.TAG === /* PropertyRef */0) {
                  return model;
                } else {
                  var property$1 = property._0;
                  if (!(ModelUtils_mapped.hasMigrated(mappedModel, "EventSpecificAllowedPropertyValues") && property$1.type_ === "string")) {
                    return model;
                  }
                  var handleNoVirtualPropertiesNeeded = function (model) {
                    return ModelUtils.updateProperty(model, property$1.id, (function (property) {
                                  var allowedValues = ComputeUnionAllowedValuesUseCase.computeWithSlowModel(model, [sourceId], property);
                                  var newMatches = Belt_List.fromArray(Belt_Array.mapU(allowedValues, (function (value) {
                                              return [
                                                      {
                                                        NAME: "Literal",
                                                        VAL: {
                                                          NAME: "StringLit",
                                                          VAL: value
                                                        }
                                                      },
                                                      /* [] */0
                                                    ];
                                            })));
                                  var existingValidationsWithoutMatches = Belt_List.keepU(property.validations, (function (item) {
                                          if (typeof item === "object") {
                                            return item.NAME !== "Matches";
                                          } else {
                                            return true;
                                          }
                                        }));
                                  var validations = Belt_List.concat(existingValidationsWithoutMatches, Belt_List.length(newMatches) > 0 ? ({
                                            hd: {
                                              NAME: "Matches",
                                              VAL: newMatches
                                            },
                                            tl: /* [] */0
                                          }) : /* [] */0);
                                  return {
                                          id: property.id,
                                          name: property.name,
                                          uniqueName: property.uniqueName,
                                          description: property.description,
                                          type_: property.type_,
                                          sendAs: property.sendAs,
                                          validations: validations,
                                          eventSpecificAllowedPropertyValues: property.eventSpecificAllowedPropertyValues,
                                          auto: property.auto,
                                          builtIn: property.builtIn,
                                          analyticsTools: property.analyticsTools,
                                          devPlatforms: property.devPlatforms,
                                          list: property.list,
                                          operation: property.operation,
                                          optionalDeprecated: property.optionalDeprecated,
                                          excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                          optionalWhenInObject: property.optionalWhenInObject,
                                          absence: property.absence,
                                          globalRequirementsMetadata: property.globalRequirementsMetadata
                                        };
                                }));
                  };
                  if (property$1.eventSpecificAllowedPropertyValues.length === 0 || ModelUtils_mapped.isPropertyIdNested(mappedModel, property$1.id)) {
                    return handleNoVirtualPropertiesNeeded(model);
                  }
                  var modelPropertiesArray = Belt_List.toArray(AvoModel.resolveProperties(model, model.properties));
                  var uniqueAllowedValuesSets = ComputeUniqueAllowedValuesSetsUseCase.compute(mappedModel, modelPropertiesArray, [sourceId], undefined, property$1);
                  if (uniqueAllowedValuesSets.allowedValuesWithNames.length === 0) {
                    return handleNoVirtualPropertiesNeeded(model);
                  }
                  var newProperties = Belt_Array.mapWithIndexU(uniqueAllowedValuesSets.allowedValuesWithNames, (function (index, param) {
                          var newMatches = Belt_List.fromArray(Belt_Array.mapU(param[0], (function (value) {
                                      return [
                                              {
                                                NAME: "Literal",
                                                VAL: {
                                                  NAME: "StringLit",
                                                  VAL: value
                                                }
                                              },
                                              /* [] */0
                                            ];
                                    })));
                          return {
                                  TAG: /* Property */1,
                                  _0: {
                                    id: index === 0 ? property$1.id : Shortid(),
                                    name: param[1],
                                    uniqueName: property$1.uniqueName,
                                    description: property$1.description,
                                    type_: property$1.type_,
                                    sendAs: property$1.sendAs,
                                    validations: {
                                      hd: {
                                        NAME: "Matches",
                                        VAL: newMatches
                                      },
                                      tl: /* [] */0
                                    },
                                    eventSpecificAllowedPropertyValues: property$1.eventSpecificAllowedPropertyValues,
                                    auto: property$1.auto,
                                    builtIn: property$1.builtIn,
                                    analyticsTools: property$1.analyticsTools,
                                    devPlatforms: property$1.devPlatforms,
                                    list: property$1.list,
                                    operation: property$1.operation,
                                    optionalDeprecated: property$1.optionalDeprecated,
                                    excludedSourcesDeprecated: property$1.excludedSourcesDeprecated,
                                    optionalWhenInObject: property$1.optionalWhenInObject,
                                    absence: property$1.absence,
                                    globalRequirementsMetadata: property$1.globalRequirementsMetadata
                                  }
                                };
                        }));
                  return {
                          types: model.types,
                          properties: BeltListExtensions.flatMapU(model.properties, (function (sproperty) {
                                  if (sproperty.TAG === /* PropertyRef */0 || sproperty._0.id !== property$1.id) {
                                    return {
                                            hd: sproperty,
                                            tl: /* [] */0
                                          };
                                  } else {
                                    return Belt_List.fromArray(newProperties);
                                  }
                                })),
                          propertyBundles: model.propertyBundles,
                          events: Belt_List.mapU(model.events, (function ($$event) {
                                  return {
                                          id: $$event.id,
                                          name: $$event.name,
                                          uniqueName: $$event.uniqueName,
                                          description: $$event.description,
                                          properties: Belt_List.mapU($$event.properties, (function (propertyRef) {
                                                  var propertyId = ModelUtils.getPropertyId(propertyRef);
                                                  if (propertyId !== property$1.id) {
                                                    return propertyRef;
                                                  }
                                                  var newPropertyName = Belt_MapString.get(uniqueAllowedValuesSets.eventIdToAllowedValuesDataStructureName, $$event.id);
                                                  var newProperty = Belt_Array.getByU(newProperties, (function (property) {
                                                          return Caml_obj.caml_equal(Belt_Option.mapU(ModelUtils.resolveProperty(model, property), (function (x) {
                                                                            return x.name;
                                                                          })), newPropertyName);
                                                        }));
                                                  if (newProperty === undefined) {
                                                    return propertyRef;
                                                  }
                                                  if (propertyRef.TAG !== /* PropertyRef */0) {
                                                    return propertyRef;
                                                  }
                                                  var propertyRef$1 = propertyRef._0;
                                                  var newPropertyRef_id = ModelUtils.getPropertyId(newProperty);
                                                  var newPropertyRef_description = propertyRef$1.description;
                                                  var newPropertyRef_pinnedValue = propertyRef$1.pinnedValue;
                                                  var newPropertyRef = {
                                                    id: newPropertyRef_id,
                                                    description: newPropertyRef_description,
                                                    pinnedValue: newPropertyRef_pinnedValue
                                                  };
                                                  return {
                                                          TAG: /* PropertyRef */0,
                                                          _0: newPropertyRef
                                                        };
                                                })),
                                          propertyBundles: $$event.propertyBundles,
                                          variants: $$event.variants,
                                          types: $$event.types,
                                          tags: $$event.tags,
                                          excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                          includeSources: $$event.includeSources,
                                          includeDestinations: $$event.includeDestinations,
                                          hashes: $$event.hashes,
                                          propertyWhitelist: $$event.propertyWhitelist,
                                          eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                          userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                          triggers: $$event.triggers,
                                          globalRequirementsMetadata: $$event.globalRequirementsMetadata
                                        };
                                })),
                          migrations: model.migrations,
                          sources: model.sources,
                          destinations: model.destinations,
                          groupTypes: model.groupTypes,
                          goals: model.goals,
                          metrics: model.metrics,
                          archive: model.archive,
                          openBranches: model.openBranches,
                          branchPointer: model.branchPointer,
                          rules: model.rules,
                          integrations: model.integrations,
                          globalRequirements: model.globalRequirements
                        };
                }
              }));
}

export {
  enrichWithVirtualProperties ,
  enrichWithVirtualPropertiesSlow ,
  
}
/* shortid Not a pure module */
