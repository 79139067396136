// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Format from "rescript/lib/es6/format.js";
import * as AvoModel from "./avoModel.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as LinkUrls from "../../shared/utils/LinkUrls.mjs";
import * as Printing from "../../codegen/src/Printing.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as PropertyUtils from "./PropertyUtils.mjs";
import * as ModelDiffUtils from "../../api/functions/src/ModelDiffUtils.mjs";
import * as QuillToPlainText from "../../shared/utils/QuillToPlainText.mjs";
import * as TrackingPlanModel from "../../model/src/TrackingPlanModel.mjs";
import * as BeltListExtensions from "./BeltListExtensions.mjs";
import * as ValidatePinnedValueWithPropertyValuesUseCase from "../../model/src/eventSpecificPropertyValues/ValidatePinnedValueWithPropertyValuesUseCase.mjs";

function stylingFormatToJs(sf) {
  if (sf) {
    return "Markdown";
  } else {
    return "Jira Snippet";
  }
}

function stylingFormatFromJs(sfs) {
  switch (sfs) {
    case "Jira" :
    case "Jira Snippet" :
        return /* Jira */0;
    case "Markdown" :
        return /* Markdown */1;
    default:
      return /* Markdown */1;
  }
}

function instructionsFormatToAnalyticsFormat(instructionsFormat) {
  if (instructionsFormat) {
    return "Markdown";
  } else {
    return "JiraMarkdown";
  }
}

function Styling(S) {
  var h1 = S.format ? "# " : "h1. ";
  var h2 = S.format ? "## " : "h2. ";
  var h3 = S.format ? "### " : "h3. ";
  var h4 = S.format ? "#### " : "h4. ";
  var b = function (str) {
    if (S.format) {
      return "**" + str + "**";
    } else {
      return "*" + str + "*";
    }
  };
  var listItem = function (indentationLevel) {
    if (S.format) {
      return Belt_Array.makeBy(indentationLevel, (function (param) {
                      return "  ";
                    })).join("") + "- ";
    } else {
      return Belt_Array.makeBy(indentationLevel + 1 | 0, (function (param) {
                      return "*";
                    })).join("") + " ";
    }
  };
  var link = function (linkText, url) {
    if (S.format) {
      return "[" + linkText + "](" + url + ")";
    } else {
      return "[" + linkText + "|" + url + "]";
    }
  };
  return {
          h1: h1,
          h2: h2,
          h3: h3,
          h4: h4,
          b: b,
          listItem: listItem,
          link: link
        };
}

function allPropertyChanges(sourceWithChanges) {
  return Belt_List.concat(sourceWithChanges.propertyMatchValidationChanges, sourceWithChanges.otherPropertyChanges);
}

function isSystemProp(propertyId, model) {
  return Caml_obj.caml_equal(Belt_Option.mapU(ModelUtils.resolvePropertyById(propertyId, model), (function (prop) {
                    return prop.sendAs;
                  })), /* SystemProperty */0);
}

function isPropertyRelevantForSource(propertyId, sourceId, model) {
  if (sourceId === "") {
    return true;
  }
  var isSystemProp$1 = isSystemProp(propertyId, model);
  if (isSystemProp$1) {
    return true;
  } else {
    return ModelUtils.isPropertySentFromSource(model, propertyId, sourceId);
  }
}

function isPropertyValidationAction(action) {
  if (typeof action !== "object") {
    return false;
  }
  var variant = action.NAME;
  if (variant === "DeprecatedAddPropertyValidationMinOrMax" || variant === "DeprecatedRemovePropertyValidationMinOrMax" || variant === "AddPropertyValidation" || variant === "RemovePropertyValidation" || variant === "DeprecatedUpdatePropertyValidationMinOrMax" || variant === "DeprecatedRemovePropertyValidationMatches" || variant === "DeprecatedAddPropertyValidationMatch") {
    return true;
  } else {
    return variant === "DeprecatedRemovePropertyValidationMatch";
  }
}

function calculateSourceWithEventsChanges(branchModel, sourcesToAttachedEventsActions, actions) {
  return Belt_Array.keepU(Belt_Array.mapU(sourcesToAttachedEventsActions, (function (param) {
                    var source = param[0];
                    return {
                            source: source,
                            eventsChanges: Belt_List.toArray(Belt_List.keepMapU(param[1], (function (eventActionsById) {
                                        if (!eventActionsById) {
                                          return ;
                                        }
                                        var match = eventActionsById.hd[0];
                                        if (typeof match === "object" && match.NAME === "Event") {
                                          return {
                                                  eventId: match.VAL,
                                                  eventActions: Belt_List.toArray(Belt_List.keepMapU(eventActionsById, (function (eventAndEventAction) {
                                                              var eventAction = eventAndEventAction[1];
                                                              if (ModelDiffUtils.isEventActionRelevantForSource(branchModel, eventAction, source.id)) {
                                                                return eventAction;
                                                              }
                                                              
                                                            })))
                                                };
                                        }
                                        
                                      }))),
                            propertyMatchValidationChanges: Belt_List.flatten(Belt_List.keepMap(actions, (function (action) {
                                        if (!action) {
                                          return ;
                                        }
                                        var match = action.hd[0];
                                        if (typeof match === "object" && match.NAME === "Property" && isPropertyRelevantForSource(match.VAL, source.id, branchModel)) {
                                          return Belt_List.keep(action, (function (param) {
                                                        return isPropertyValidationAction(param[1]);
                                                      }));
                                        }
                                        
                                      }))),
                            otherPropertyChanges: Belt_List.flatten(Belt_List.keepMap(actions, (function (action) {
                                        if (!action) {
                                          return ;
                                        }
                                        var match = action.hd[0];
                                        if (typeof match === "object" && match.NAME === "Property" && isPropertyRelevantForSource(match.VAL, source.id, branchModel)) {
                                          return Belt_List.keep(action, (function (param) {
                                                        return !isPropertyValidationAction(param[1]);
                                                      }));
                                        }
                                        
                                      })))
                          };
                  })), (function (sourceWithChanges) {
                if (sourceWithChanges.eventsChanges.length !== 0 && Belt_Array.someU(sourceWithChanges.eventsChanges, (function (eventWithPropertiesChanges) {
                          return eventWithPropertiesChanges.eventActions.length !== 0;
                        }))) {
                  return true;
                } else {
                  return Belt_List.length(allPropertyChanges(sourceWithChanges)) > 0;
                }
              }));
}

var sourceUsedWhenNoSourcesAreSetUp = TrackingPlanModel.emptySource(undefined, "", "", "");

function formatDescription(description) {
  var trimmedDescription = description.trim();
  if (trimmedDescription === "") {
    return "-  ";
  } else {
    return trimmedDescription + "  ";
  }
}

function hasDestinationPropertyNameMappingOnEvent(model, propertyId, eventId, possibleDestinationIds) {
  return Belt_List.someU(model.rules, (function (rule) {
                var match = rule.item;
                var match$1 = rule.definition;
                if (typeof match !== "object") {
                  return false;
                }
                var variant = match.NAME;
                if (variant === "PropertyRef") {
                  if (typeof match$1 !== "object") {
                    return false;
                  }
                  if (match$1.NAME !== "NameMapping") {
                    return false;
                  }
                  var match$2 = match$1.VAL;
                  var match$3 = match$2.destination;
                  if (match$3 === undefined) {
                    return false;
                  }
                  if (typeof match$3 !== "object") {
                    return false;
                  }
                  if (match$3.NAME !== "Destination") {
                    return false;
                  }
                  if (match$2.name === undefined) {
                    return false;
                  }
                  var mappedDestinationId = match$3.VAL;
                  var match$4 = match.VAL;
                  if (match$4[0] === eventId && match$4[1] === propertyId) {
                    return Belt_List.someU(possibleDestinationIds, (function (possibleDestId) {
                                  return possibleDestId === mappedDestinationId;
                                }));
                  } else {
                    return false;
                  }
                }
                if (variant !== "Property") {
                  return false;
                }
                if (typeof match$1 !== "object") {
                  return false;
                }
                if (match$1.NAME !== "NameMapping") {
                  return false;
                }
                var match$5 = match$1.VAL;
                var match$6 = match$5.destination;
                if (match$6 === undefined) {
                  return false;
                }
                if (typeof match$6 !== "object") {
                  return false;
                }
                if (match$6.NAME !== "Destination") {
                  return false;
                }
                if (match$5.name === undefined) {
                  return false;
                }
                var mappedDestinationId$1 = match$6.VAL;
                if (match.VAL === propertyId) {
                  return Belt_List.someU(possibleDestinationIds, (function (possibleDestId) {
                                return possibleDestId === mappedDestinationId$1;
                              }));
                } else {
                  return false;
                }
              }));
}

function getPropertyMappingName(model, destination, propertyId, eventId) {
  return Belt_List.head(Belt_List.keepMap(model.rules, (function (rule) {
                    var match = rule.item;
                    var match$1 = rule.definition;
                    if (typeof match !== "object") {
                      return ;
                    }
                    var variant = match.NAME;
                    if (variant === "PropertyRef") {
                      if (typeof match$1 !== "object") {
                        return ;
                      }
                      if (match$1.NAME !== "NameMapping") {
                        return ;
                      }
                      var match$2 = match$1.VAL;
                      var mappedDestination = match$2.destination;
                      if (mappedDestination === undefined) {
                        return ;
                      }
                      var name = match$2.name;
                      if (name === undefined) {
                        return ;
                      }
                      var match$3 = match.VAL;
                      if (match$3[0] === eventId && match$3[1] === propertyId && Caml_obj.caml_equal(mappedDestination, destination)) {
                        return name;
                      } else {
                        return ;
                      }
                    }
                    if (variant !== "Property") {
                      return ;
                    }
                    if (typeof match$1 !== "object") {
                      return ;
                    }
                    if (match$1.NAME !== "NameMapping") {
                      return ;
                    }
                    var match$4 = match$1.VAL;
                    var mappedDestination$1 = match$4.destination;
                    if (mappedDestination$1 === undefined) {
                      return ;
                    }
                    var name$1 = match$4.name;
                    if (name$1 !== undefined && match.VAL === propertyId && Caml_obj.caml_equal(mappedDestination$1, destination)) {
                      return name$1;
                    }
                    
                  })));
}

function getPropertyName(model, destination, property, eventId) {
  return Belt_Option.getWithDefault(getPropertyMappingName(model, destination, property.id, eventId), property.name);
}

function hasDestinationEventNameMappingOnEvent(model, eventId, possibleDestinationIds) {
  return Belt_List.someU(model.rules, (function (rule) {
                var match = rule.item;
                var match$1 = rule.definition;
                if (typeof match !== "object") {
                  return false;
                }
                if (match.NAME !== "Event") {
                  return false;
                }
                if (typeof match$1 !== "object") {
                  return false;
                }
                if (match$1.NAME !== "NameMapping") {
                  return false;
                }
                var match$2 = match$1.VAL;
                var match$3 = match$2.destination;
                if (match$3 === undefined) {
                  return false;
                }
                if (typeof match$3 !== "object") {
                  return false;
                }
                if (match$3.NAME !== "Destination") {
                  return false;
                }
                if (match$2.name === undefined) {
                  return false;
                }
                var mappedDestinationId = match$3.VAL;
                if (match.VAL === eventId) {
                  return Belt_List.someU(possibleDestinationIds, (function (possibleDestId) {
                                return possibleDestId === mappedDestinationId;
                              }));
                } else {
                  return false;
                }
              }));
}

function getEventMappingName(model, destination, eventId) {
  return Belt_List.head(Belt_List.keepMap(model.rules, (function (rule) {
                    var match = rule.item;
                    var match$1 = rule.definition;
                    if (typeof match !== "object") {
                      return ;
                    }
                    if (match.NAME !== "Event") {
                      return ;
                    }
                    if (typeof match$1 !== "object") {
                      return ;
                    }
                    if (match$1.NAME !== "NameMapping") {
                      return ;
                    }
                    var match$2 = match$1.VAL;
                    var mappedDestination = match$2.destination;
                    if (mappedDestination === undefined) {
                      return ;
                    }
                    var name = match$2.name;
                    if (name !== undefined && match.VAL === eventId && Caml_obj.caml_equal(mappedDestination, destination)) {
                      return name;
                    }
                    
                  })));
}

function getEventName(model, destination, eventId) {
  var $$event = ModelUtils.getEventById(eventId, model);
  return Belt_Option.getWithDefault(getEventMappingName(model, destination, eventId), Belt_Option.mapWithDefaultU($$event, "Unnamed event", (function ($$event) {
                    return $$event.name;
                  })));
}

function printPropertyInstructions(ppf, property, model, masterModel, $$event, sourceId, format, isNew, hasAnyValuesFeatureFlag, hasLongValuesFeatureFlag) {
  var b = function (str) {
    if (format) {
      return "**" + str + "**";
    } else {
      return "*" + str + "*";
    }
  };
  var listItem = function (indentationLevel) {
    if (format) {
      return Belt_Array.makeBy(indentationLevel, (function (param) {
                      return "  ";
                    })).join("") + "- ";
    } else {
      return Belt_Array.makeBy(indentationLevel + 1 | 0, (function (param) {
                      return "*";
                    })).join("") + " ";
    }
  };
  var oldPropertyName = getPropertyName(masterModel, "AllDestinations", property, $$event.id);
  var propertyName = getPropertyName(model, "AllDestinations", property, $$event.id);
  Curry._2(Format.fprintf(ppf, /* Format */{
            _0: {
              TAG: /* Formatting_lit */17,
              _0: {
                TAG: /* Break */0,
                _0: "@,",
                _1: 0,
                _2: 0
              },
              _1: {
                TAG: /* String */2,
                _0: /* No_padding */0,
                _1: {
                  TAG: /* String_literal */11,
                  _0: "property ",
                  _1: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: /* End_of_format */0
                  }
                }
              }
            },
            _1: "@,%sproperty %s"
          }), listItem(0), isNew || oldPropertyName === propertyName ? "named \"" + propertyName + "\"" : "renamed from \"" + oldPropertyName + "\" to \"" + propertyName + "\"");
  var possibleDestinationIds = BeltListExtensions.flatMapU($$event.includeDestinations, (function (includedDest) {
          var match = includedDest.VAL;
          if (match[0] === sourceId) {
            return match[1];
          } else {
            return /* [] */0;
          }
        }));
  var hadDestinationNameMappingBefore = hasDestinationPropertyNameMappingOnEvent(masterModel, property.id, $$event.id, possibleDestinationIds);
  var hasDestinationNameMapping = hasDestinationPropertyNameMappingOnEvent(model, property.id, $$event.id, possibleDestinationIds);
  if (hasDestinationNameMapping || hadDestinationNameMappingBefore) {
    Curry._1(Format.fprintf(ppf, /* Format */{
              _0: {
                TAG: /* String_literal */11,
                _0: ", also this property ",
                _1: {
                  TAG: /* String */2,
                  _0: /* No_padding */0,
                  _1: {
                    TAG: /* String_literal */11,
                    _0: " destination specific names, see in the Destinations section below",
                    _1: /* End_of_format */0
                  }
                }
              },
              _1: ", also this property %s destination specific names, see in the Destinations section below"
            }), hasDestinationNameMapping ? "has" : "had");
  }
  var groupTypeId = property.sendAs;
  if (typeof groupTypeId === "number") {
    
  } else {
    Curry._2(Format.fprintf(ppf, /* Format */{
              _0: {
                TAG: /* Formatting_lit */17,
                _0: {
                  TAG: /* Break */0,
                  _0: "@,",
                  _1: 0,
                  _2: 0
                },
                _1: {
                  TAG: /* String */2,
                  _0: /* No_padding */0,
                  _1: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: /* End_of_format */0
                  }
                }
              },
              _1: "@,%s%s"
            }), listItem(1), "A \"" + (ModelUtils.getGroupTypeNameWithArchiveWithDefaultDeletedName(groupTypeId._0, model) + "\" group property"));
  }
  Curry._3(Format.fprintf(ppf, /* Format */{
            _0: {
              TAG: /* Formatting_lit */17,
              _0: {
                TAG: /* Break */0,
                _0: "@,",
                _1: 0,
                _2: 0
              },
              _1: {
                TAG: /* String */2,
                _0: /* No_padding */0,
                _1: {
                  TAG: /* String */2,
                  _0: /* No_padding */0,
                  _1: {
                    TAG: /* String_literal */11,
                    _0: ": ",
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    }
                  }
                }
              }
            },
            _1: "@,%s%s: %s"
          }), listItem(1), b("Description"), formatDescription(property.description));
  var pinnedValue = ModelUtils.getPropertyPinnedValueFromEvent($$event, property.id);
  var tmp;
  if (pinnedValue !== undefined) {
    var isValidPinnedValue = ValidatePinnedValueWithPropertyValuesUseCase.validate(pinnedValue, property, ModelUtils.hasMigrated(model, "EventSpecificAllowedPropertyValues"));
    tmp = "Pinned to " + PropertyUtils.printPinnedValue(pinnedValue) + (
      isValidPinnedValue ? "" : ". Warning: The pinned value does not match one of the allowed strings for the property."
    );
  } else {
    tmp = PropertyUtils.printPropertyType(property, undefined, $$event.id, model, hasAnyValuesFeatureFlag, hasLongValuesFeatureFlag, undefined, undefined);
  }
  return Curry._3(Format.fprintf(ppf, /* Format */{
                  _0: {
                    TAG: /* Formatting_lit */17,
                    _0: {
                      TAG: /* Break */0,
                      _0: "@,",
                      _1: 0,
                      _2: 0
                    },
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* String */2,
                        _0: /* No_padding */0,
                        _1: {
                          TAG: /* String_literal */11,
                          _0: ": ",
                          _1: {
                            TAG: /* String */2,
                            _0: /* No_padding */0,
                            _1: /* End_of_format */0
                          }
                        }
                      }
                    }
                  },
                  _1: "@,%s%s: %s"
                }), listItem(1), b("Value type"), tmp);
}

function printDestinationInstructions(ppf, destinationId, eventId, $$event, model, masterModel, format) {
  var listItem = function (indentationLevel) {
    if (format) {
      return Belt_Array.makeBy(indentationLevel, (function (param) {
                      return "  ";
                    })).join("") + "- ";
    } else {
      return Belt_Array.makeBy(indentationLevel + 1 | 0, (function (param) {
                      return "*";
                    })).join("") + " ";
    }
  };
  var destination = ModelUtils.getDestinationById(destinationId, model);
  if (destination === undefined) {
    return ;
  }
  if (destination.name === undefined) {
    return ;
  }
  Curry._2(Format.fprintf(ppf, /* Format */{
            _0: {
              TAG: /* Formatting_lit */17,
              _0: {
                TAG: /* Break */0,
                _0: "@,",
                _1: 0,
                _2: 0
              },
              _1: {
                TAG: /* String */2,
                _0: /* No_padding */0,
                _1: {
                  TAG: /* String */2,
                  _0: /* No_padding */0,
                  _1: /* End_of_format */0
                }
              }
            },
            _1: "@,%s%s"
          }), listItem(0), Belt_Option.getExn(destination.name));
  var eventDestinationNameMapping = getEventMappingName(model, {
        NAME: "Destination",
        VAL: destination.id
      }, $$event.id);
  if (eventDestinationNameMapping !== undefined) {
    var prevNameMapping = getEventMappingName(masterModel, {
          NAME: "Destination",
          VAL: destination.id
        }, $$event.id);
    Curry._2(Format.fprintf(ppf, /* Format */{
              _0: {
                TAG: /* Formatting_lit */17,
                _0: {
                  TAG: /* Break */0,
                  _0: "@,",
                  _1: 0,
                  _2: 0
                },
                _1: {
                  TAG: /* String */2,
                  _0: /* No_padding */0,
                  _1: {
                    TAG: /* String_literal */11,
                    _0: "Send the event as \"",
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* Char_literal */12,
                        _0: /* '"' */34,
                        _1: /* End_of_format */0
                      }
                    }
                  }
                }
              },
              _1: "@,%sSend the event as \"%s\""
            }), listItem(1), eventDestinationNameMapping);
    if (Caml_obj.caml_notequal(prevNameMapping, eventDestinationNameMapping)) {
      Curry._1(Format.fprintf(ppf, /* Format */{
                _0: {
                  TAG: /* String_literal */11,
                  _0: ", ",
                  _1: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: /* End_of_format */0
                  }
                },
                _1: ", %s"
              }), prevNameMapping !== undefined ? "changed from \"" + prevNameMapping + "\"" : "NEW!");
    }
    
  }
  var propertyNameMappings = BeltListExtensions.flatMapU($$event.properties, (function (property) {
          var realProperty = ModelUtils.resolveProperty(model, property);
          if (realProperty === undefined) {
            return /* [] */0;
          }
          var propertyToPropNameMapping = function (property) {
            var propMappingName = getPropertyMappingName(model, {
                  NAME: "Destination",
                  VAL: destination.id
                }, property.id, eventId);
            if (propMappingName !== undefined) {
              return {
                      hd: [
                        property.id,
                        property.name,
                        propMappingName
                      ],
                      tl: /* [] */0
                    };
            } else {
              return /* [] */0;
            }
          };
          var nestedProperties = BeltListExtensions.flatMapU(realProperty.validations, (function (validation) {
                  if (typeof validation === "object" && validation.NAME === "NestedProperty") {
                    return validation.VAL;
                  } else {
                    return /* [] */0;
                  }
                }));
          var nestedPropNameMappings = BeltListExtensions.flatMapU(nestedProperties, (function (nestedProp) {
                  var realNestedProp = ModelUtils.resolvePropertyById(nestedProp.id, model);
                  if (realNestedProp !== undefined) {
                    return propertyToPropNameMapping(realNestedProp);
                  } else {
                    return /* [] */0;
                  }
                }));
          return Belt_List.concat(propertyToPropNameMapping(realProperty), nestedPropNameMappings);
        }));
  return Belt_List.forEachU(propertyNameMappings, (function (param) {
                var mappedName = param[2];
                var oldMappedName = getPropertyMappingName(masterModel, {
                      NAME: "Destination",
                      VAL: destination.id
                    }, param[0], eventId);
                Curry._3(Format.fprintf(ppf, /* Format */{
                          _0: {
                            TAG: /* Formatting_lit */17,
                            _0: {
                              TAG: /* Break */0,
                              _0: "@,",
                              _1: 0,
                              _2: 0
                            },
                            _1: {
                              TAG: /* String */2,
                              _0: /* No_padding */0,
                              _1: {
                                TAG: /* String_literal */11,
                                _0: "Send the \"",
                                _1: {
                                  TAG: /* String */2,
                                  _0: /* No_padding */0,
                                  _1: {
                                    TAG: /* String_literal */11,
                                    _0: "\" property as \"",
                                    _1: {
                                      TAG: /* String */2,
                                      _0: /* No_padding */0,
                                      _1: {
                                        TAG: /* Char_literal */12,
                                        _0: /* '"' */34,
                                        _1: /* End_of_format */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          },
                          _1: "@,%sSend the \"%s\" property as \"%s\""
                        }), listItem(1), param[1], mappedName);
                if (Caml_obj.caml_notequal(oldMappedName, mappedName)) {
                  return Curry._1(Format.fprintf(ppf, /* Format */{
                                  _0: {
                                    TAG: /* String_literal */11,
                                    _0: ", ",
                                    _1: {
                                      TAG: /* String */2,
                                      _0: /* No_padding */0,
                                      _1: /* End_of_format */0
                                    }
                                  },
                                  _1: ", %s"
                                }), oldMappedName !== undefined ? "changed from \"" + oldMappedName + "\"" : "NEW!");
                }
                
              }));
}

function propertyActionsToPropertyIds(propertyActions, model) {
  return Belt_Array.reduceU(propertyActions, [], (function (acc, propertyAction) {
                if (typeof propertyAction !== "object") {
                  return acc;
                }
                var variant = propertyAction.NAME;
                if (variant === "RemovePropertyRefV3" || variant === "RemovePropertyRefV2Deprecated" || variant === "AddPropertyRef") {
                  acc.push(propertyAction.VAL[1]);
                  return acc;
                }
                if (!(variant === "AddPropertyGroupToEvent" || variant === "RemovePropertyGroupFromEvent")) {
                  return acc;
                }
                var propertyIds = Belt_List.toArray(Belt_Option.mapWithDefaultU(ModelUtils.getPropertyGroupById(propertyAction.VAL[1], model), /* [] */0, (function (group) {
                            return group.properties;
                          })));
                return Belt_Array.concat(propertyIds, acc);
              }));
}

function calculateTriggersDiff(eventWithPropertiesChanges, model) {
  var newTriggerIds = Belt_Array.keepMapU(eventWithPropertiesChanges.eventActions, (function (action) {
          if (typeof action === "object" && action.NAME === "CreateEventTrigger") {
            return action.VAL[1];
          }
          
        }));
  var removedTriggerIds = Belt_Array.keepMapU(eventWithPropertiesChanges.eventActions, (function (action) {
          if (typeof action === "object" && action.NAME === "RemoveEventTrigger") {
            return action.VAL[1];
          }
          
        }));
  var updatedTriggerIds = Belt_Array.keepMapU(eventWithPropertiesChanges.eventActions, (function (action) {
          if (typeof action !== "object") {
            return ;
          }
          var variant = action.NAME;
          if (variant === "RemoveTriggerSource" || variant === "AddTriggerSource" || variant === "UpdateTriggerDescription" || variant === "UpdateTriggerSources" || variant === "UpdateTriggerContent") {
            return action.VAL[1];
          }
          
        }));
  var eventTriggers = Belt_Array.mapU(Belt_Option.getWithDefault(Belt_Option.map(ModelUtils.getEventById(eventWithPropertiesChanges.eventId, model), (function (it) {
                  return it.triggers;
                })), []), (function (trigger) {
          return trigger.id;
        }));
  var unchangedTriggerIds = Belt_Array.keepU(eventTriggers, (function (existingTriggerId) {
          if (Belt_Array.everyU(newTriggerIds, (function (newTriggerId) {
                    return newTriggerId !== existingTriggerId;
                  })) && Belt_Array.everyU(removedTriggerIds, (function (removedTriggerId) {
                    return removedTriggerId !== existingTriggerId;
                  }))) {
            return Belt_Array.everyU(updatedTriggerIds, (function (changedTriggerId) {
                          return changedTriggerId !== existingTriggerId;
                        }));
          } else {
            return false;
          }
        }));
  return {
          newTriggerIds: newTriggerIds,
          removedTriggerIds: removedTriggerIds,
          updatedTriggerIds: updatedTriggerIds,
          unchangedTriggerIds: unchangedTriggerIds
        };
}

function calculateDestinationsDiff(eventWithPropertiesChanges, sourceWithChanges, model) {
  var newDestinationIds = Belt_Array.keepMapU(eventWithPropertiesChanges.eventActions, (function (action) {
          if (typeof action === "object" && action.NAME === "IncludeDestinationInEventSource") {
            return action.VAL[2];
          }
          
        }));
  var removedDestinationIds = Belt_Array.keepMapU(eventWithPropertiesChanges.eventActions, (function (action) {
          if (typeof action === "object" && action.NAME === "ExcludeDestinationFromEventSource") {
            return action.VAL[2];
          }
          
        }));
  var oldDestinationIds = Belt_Array.keepU(Belt_List.toArray(ModelUtils.getDefaultDestinationsForSource(sourceWithChanges.source, model)), (function (existingDestination) {
          if (Belt_Array.everyU(newDestinationIds, (function (newDestination) {
                    return newDestination !== existingDestination;
                  }))) {
            return Belt_Array.everyU(removedDestinationIds, (function (removedDestination) {
                          return removedDestination !== existingDestination;
                        }));
          } else {
            return false;
          }
        }));
  return {
          newDestinationIds: newDestinationIds,
          removedDestinationIds: removedDestinationIds,
          oldDestinationIds: oldDestinationIds
        };
}

function calculateSystemPropertiesDiff(sourceWithChanges, model) {
  var newSystemPropertyIds = Belt_List.toArray(Belt_List.keepMapU(sourceWithChanges.otherPropertyChanges, (function (param) {
              var action = param[1];
              var propertyId;
              var sendAs;
              if (typeof action !== "object") {
                return ;
              }
              var variant = action.NAME;
              if (variant === "AddProperty") {
                var match = action.VAL;
                propertyId = match[1];
                sendAs = match[3];
              } else if (variant === "UpdatePropertySendAs") {
                var match$1 = action.VAL;
                propertyId = match$1[0];
                sendAs = match$1[1];
              } else {
                if (variant === "AddSystemProperty") {
                  return action.VAL[0];
                }
                if (variant === "CreateProperty") {
                  var match$2 = action.VAL;
                  propertyId = match$2[0];
                  sendAs = match$2[2];
                } else {
                  if (variant !== "Unarchive") {
                    return ;
                  }
                  var match$3 = action.VAL;
                  if (typeof match$3 !== "object") {
                    return ;
                  }
                  if (match$3.NAME !== "Property") {
                    return ;
                  }
                  var propertyId$1 = match$3.VAL;
                  if (Caml_obj.caml_equal(Belt_Option.mapU(ModelUtils.resolvePropertyById(propertyId$1, model), (function (prop) {
                                return prop.sendAs;
                              })), /* SystemProperty */0)) {
                    return propertyId$1;
                  } else {
                    return ;
                  }
                }
              }
              if (sendAs === /* SystemProperty */0) {
                return propertyId;
              }
              
            })));
  var removedSystemPropertyIds = Belt_List.toArray(Belt_List.keepMapU(sourceWithChanges.otherPropertyChanges, (function (param) {
              var action = param[1];
              var propertyId;
              if (typeof action !== "object") {
                return ;
              }
              var variant = action.NAME;
              if (variant === "RemovePropertyPermanently") {
                propertyId = action.VAL;
              } else {
                if (variant !== "Archive") {
                  return ;
                }
                var match = action.VAL;
                if (typeof match !== "object") {
                  return ;
                }
                if (match.NAME !== "Property") {
                  return ;
                }
                propertyId = match.VAL;
              }
              if (Caml_obj.caml_equal(Belt_Option.mapU(ModelUtils.resolvePropertyById(propertyId, model), (function (x) {
                            return x.sendAs;
                          })), /* SystemProperty */0)) {
                return propertyId;
              }
              
            })));
  var allSystemPropertyIdsExceptDeletedAndNew = Belt_Array.mapU(Belt_List.toArray(Belt_List.keepMapU(model.properties, (function (prop) {
                  var maybeProp = ModelUtils.resolveProperty(model, prop);
                  if (maybeProp !== undefined && maybeProp.sendAs === /* SystemProperty */0 && !Belt_Array.someU(newSystemPropertyIds, (function (newPropId) {
                            return newPropId === maybeProp.id;
                          }))) {
                    return maybeProp;
                  }
                  
                }))), (function (prop) {
          return prop.id;
        }));
  var match = Belt_Array.partitionU(allSystemPropertyIdsExceptDeletedAndNew, (function (propertyId) {
          return Belt_List.someU(allPropertyChanges(sourceWithChanges), (function (param) {
                        var item = param[0];
                        if (typeof item === "object" && item.NAME === "Property") {
                          return propertyId === item.VAL;
                        } else {
                          return false;
                        }
                      }));
        }));
  return {
          newSystemPropertyIds: newSystemPropertyIds,
          removedSystemPropertyIds: removedSystemPropertyIds,
          updatedSystemPropertyIds: match[0],
          oldSystemPropertyIds: match[1]
        };
}

function matchHasLiteral(avoEventProp, literal) {
  return Belt_List.someU(avoEventProp.validations, (function (validation) {
                if (typeof validation === "object" && validation.NAME === "Matches") {
                  return Belt_List.someU(validation.VAL, (function (param) {
                                return Caml_obj.caml_equal(param[0].VAL, literal);
                              }));
                } else {
                  return false;
                }
              }));
}

function updatedPropertyIdsOnSourceWithPinnedPropsFilter($$event, model, updatedMatchValidationsPropertyIdsOnSource, updatedOtherPropertyIdsOnSource) {
  return Belt_List.toArray(Belt_List.concat(Belt_List.keepU(updatedMatchValidationsPropertyIdsOnSource, (function (propertyId) {
                        var pinnedValue = ModelUtils.getPropertyPinnedValueFromEvent($$event, propertyId);
                        if (pinnedValue === undefined) {
                          return true;
                        }
                        var property = Belt_Option.flatMapU(ModelUtils.getPropertyById(propertyId, model), (function (prop) {
                                return ModelUtils.resolveProperty(model, prop);
                              }));
                        if (property !== undefined) {
                          return !matchHasLiteral(property, pinnedValue);
                        } else {
                          return true;
                        }
                      })), updatedOtherPropertyIdsOnSource));
}

function calculatePropertiesDiffWithoutSystemProps(eventWithPropertiesChanges, model, $$event, updatedMatchValidationsPropertyIdsOnSource, updatedOtherPropertyIdsOnSource) {
  var updatedPropertyIdsOnSource = updatedPropertyIdsOnSourceWithPinnedPropsFilter($$event, model, updatedMatchValidationsPropertyIdsOnSource, updatedOtherPropertyIdsOnSource);
  var newPropertyActions = Belt_Array.keepU(eventWithPropertiesChanges.eventActions, (function (action) {
          if (typeof action !== "object") {
            return false;
          }
          var variant = action.NAME;
          if (variant === "AddPropertyGroupToEvent") {
            return true;
          } else {
            return variant === "AddPropertyRef";
          }
        }));
  var newPropertyIds = propertyActionsToPropertyIds(newPropertyActions, model);
  var removedPropertyActions = Belt_Array.keepU(eventWithPropertiesChanges.eventActions, (function (action) {
          if (typeof action !== "object") {
            return false;
          }
          var variant = action.NAME;
          if (variant === "RemovePropertyGroupFromEvent" || variant === "RemovePropertyRefV3") {
            return true;
          } else {
            return variant === "RemovePropertyRefV2Deprecated";
          }
        }));
  var removedPropertyIds = propertyActionsToPropertyIds(removedPropertyActions, model);
  var duplicateProperties = Belt_Array.keepU(newPropertyIds, (function (newPropertyId) {
          return Belt_Array.someU(removedPropertyIds, (function (removedPropertyId) {
                        return newPropertyId === removedPropertyId;
                      }));
        }));
  var newPropertyIds$1 = Belt_Array.keepU(newPropertyIds, (function (newPropertyId) {
          return Belt_Array.everyU(duplicateProperties, (function (duplicatePropertyId) {
                        return newPropertyId !== duplicatePropertyId;
                      }));
        }));
  var removedPropertyIds$1 = Belt_Array.keepU(removedPropertyIds, (function (removedPropertyId) {
          return Belt_Array.everyU(duplicateProperties, (function (duplicatePropertyId) {
                        return removedPropertyId !== duplicatePropertyId;
                      }));
        }));
  var oldPropertyIds = Belt_List.toArray(Belt_List.keepU(Belt_List.mapU($$event.properties, (function (prop) {
                  return prop._0.id;
                })), (function (propertyId) {
              if (Belt_Array.everyU(newPropertyIds$1, (function (newPropertyId) {
                        return propertyId !== newPropertyId;
                      }))) {
                return Belt_Array.everyU(removedPropertyIds$1, (function (removedPropertyId) {
                              return propertyId !== removedPropertyId;
                            }));
              } else {
                return false;
              }
            })));
  var updatedPropertyIds = Belt_Array.keepU(oldPropertyIds, (function (oldPropertyId) {
          return Belt_Array.someU(updatedPropertyIdsOnSource, (function (updatedPropertyId) {
                        return updatedPropertyId === oldPropertyId;
                      }));
        }));
  var oldPropertyIds$1 = Belt_Array.keepU(oldPropertyIds, (function (oldPropertyId) {
          return Belt_Array.everyU(updatedPropertyIdsOnSource, (function (updatedPropertyId) {
                        return updatedPropertyId !== oldPropertyId;
                      }));
        }));
  return {
          newPropertyIds: newPropertyIds$1,
          removedPropertyIds: removedPropertyIds$1,
          updatedPropertyIds: updatedPropertyIds,
          oldPropertyIds: oldPropertyIds$1
        };
}

function prepareModel(model, source) {
  var model$1 = source.id !== sourceUsedWhenNoSourcesAreSetUp.id ? AvoModel.removeExcludedPropertiesOnSource(AvoModel.filterExcludedEventsBySourcesFromModel(model, {
              hd: source,
              tl: /* [] */0
            }), source) : model;
  var model$2 = AvoModel.filterUnusedPropertyBundles(model$1);
  return AvoModel.resolvePropertyBundlesPropertiesToEventProperties(model$2, source.id === sourceUsedWhenNoSourcesAreSetUp.id, Belt_List.map(model$2.sources, (function (it) {
                    return it.id;
                  })));
}

function propertyIdsToModelPropertyTs(propertyIds, model) {
  return Belt_Array.keepMapU(propertyIds, (function (propertyId) {
                return ModelUtils.resolvePropertyById(propertyId, model);
              }));
}

function propertyIdsToModelPropertyTsWithArchive(propertyIds, model) {
  return Belt_Array.keepMapU(propertyIds, (function (propertyId) {
                return Belt_Option.flatMapU(ModelUtils.getPropertyByIdWithArchive(propertyId, model), (function (modelProperty) {
                              return ModelUtils.resolveProperty(model, modelProperty);
                            }));
              }));
}

function printEventInstructions(ppf, model, masterModel, eventWithPropertiesChanges, schemaId, sourceWithChanges, format, branchId, updatedMatchValidationsPropertyIdsOnSource, updatedOtherPropertyIdsOnSource, isNew, systemPropertiesDiff, hasAnyValuesFeatureFlag, hasLongValuesFeatureFlag) {
  var h3 = format ? "### " : "h3. ";
  var h4 = format ? "#### " : "h4. ";
  var link = function (linkText, url) {
    if (format) {
      return "[" + linkText + "](" + url + ")";
    } else {
      return "[" + linkText + "|" + url + "]";
    }
  };
  var source = Belt_Option.getWithDefault(Belt_Option.flatMapU(AvoConfig.sourceById(model, sourceWithChanges.source.id), (function (source) {
              return AvoConfig.tryToConvertSourceToValidSourceMaybe(source, model);
            })), TrackingPlanModel.emptyValidSource(sourceUsedWhenNoSourcesAreSetUp.id, ""));
  var model$1 = prepareModel(model, source);
  var masterModel$1 = prepareModel(masterModel, source);
  var printEventTriggerInstructions = function (ppf, $$event, model, sourceWithChanges, triggerId, schemaId, branchId, index) {
    var eventTriggers = Belt_Option.mapWithDefault(ModelUtils.getEventById($$event.id, model), [], (function ($$event) {
            return $$event.triggers;
          }));
    var sourceTriggers = Belt_Array.keepU(eventTriggers, (function (trigger) {
            var sourceIds = trigger.sources;
            if (!sourceIds) {
              return true;
            }
            var sourceIds$1 = sourceIds._0;
            if (sourceIds$1.length !== 0) {
              return Belt_Array.someU(sourceIds$1, (function (triggerSourceId) {
                            return triggerSourceId === sourceWithChanges.source.id;
                          }));
            } else {
              return false;
            }
          }));
    var targetTrigger = Belt_Array.getByU(sourceTriggers, (function (trigger) {
            return trigger.id === triggerId;
          }));
    if (targetTrigger === undefined) {
      return ;
    }
    var descriptionString = QuillToPlainText.quillToPlainText(targetTrigger.description);
    Curry._2(Format.fprintf(ppf, /* Format */{
              _0: {
                TAG: /* Formatting_lit */17,
                _0: {
                  TAG: /* Break */0,
                  _0: "@,",
                  _1: 0,
                  _2: 0
                },
                _1: {
                  TAG: /* Int */4,
                  _0: /* Int_d */0,
                  _1: /* No_padding */0,
                  _2: /* No_precision */0,
                  _3: {
                    TAG: /* String_literal */11,
                    _0: ". ",
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    }
                  }
                }
              },
              _1: "@,%d. %s"
            }), index, descriptionString);
    return Curry._1(Format.fprintf(ppf, /* Format */{
                    _0: {
                      TAG: /* Char_literal */12,
                      _0: /* ' ' */32,
                      _1: {
                        TAG: /* String */2,
                        _0: /* No_padding */0,
                        _1: /* End_of_format */0
                      }
                    },
                    _1: " %s"
                  }), link("View screenshot in Avo", LinkUrls.triggerLink(schemaId, branchId, $$event.id, triggerId)));
  };
  var eventId = eventWithPropertiesChanges.eventId;
  var maybeEvent = ModelUtils.getEventById(eventWithPropertiesChanges.eventId, model$1);
  if (maybeEvent === undefined) {
    return ;
  }
  var oldEventName = getEventName(masterModel$1, "AllDestinations", eventId);
  var eventName = getEventName(model$1, "AllDestinations", eventId);
  var eventDescription = Belt_Option.getWithDefault(Belt_Option.mapU(maybeEvent, (function ($$event) {
              return $$event.description;
            })), "");
  Curry._2(Format.fprintf(ppf, /* Format */{
            _0: {
              TAG: /* Formatting_lit */17,
              _0: {
                TAG: /* Break */0,
                _0: "@,",
                _1: 0,
                _2: 0
              },
              _1: {
                TAG: /* Formatting_lit */17,
                _0: {
                  TAG: /* Break */0,
                  _0: "@,",
                  _1: 0,
                  _2: 0
                },
                _1: {
                  TAG: /* String */2,
                  _0: /* No_padding */0,
                  _1: {
                    TAG: /* String_literal */11,
                    _0: "Event ",
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    }
                  }
                }
              }
            },
            _1: "@,@,%sEvent %s"
          }), h3, isNew || oldEventName === eventName ? "named \"" + eventName + "\"" : "renamed from \"" + oldEventName + "\" to \"" + eventName + "\"");
  var possibleDestinationIds = BeltListExtensions.flatMapU(maybeEvent.includeDestinations, (function (includedDest) {
          var match = includedDest.VAL;
          if (match[0] === sourceWithChanges.source.id) {
            return match[1];
          } else {
            return /* [] */0;
          }
        }));
  var hadDestinationNameMappingBefore = hasDestinationEventNameMappingOnEvent(masterModel$1, maybeEvent.id, possibleDestinationIds);
  var hasDestinationNameMapping = hasDestinationEventNameMappingOnEvent(model$1, maybeEvent.id, possibleDestinationIds);
  if (hasDestinationNameMapping || hadDestinationNameMappingBefore) {
    Curry._1(Format.fprintf(ppf, /* Format */{
              _0: {
                TAG: /* String_literal */11,
                _0: ", also this event ",
                _1: {
                  TAG: /* String */2,
                  _0: /* No_padding */0,
                  _1: {
                    TAG: /* String_literal */11,
                    _0: " destination specific names, see in the Destinations section below",
                    _1: /* End_of_format */0
                  }
                }
              },
              _1: ", also this event %s destination specific names, see in the Destinations section below"
            }), hasDestinationNameMapping ? "has" : "had");
  }
  Curry._1(Format.fprintf(ppf, /* Format */{
            _0: {
              TAG: /* String_literal */11,
              _0: " (",
              _1: {
                TAG: /* String */2,
                _0: /* No_padding */0,
                _1: {
                  TAG: /* Char_literal */12,
                  _0: /* ')' */41,
                  _1: /* End_of_format */0
                }
              }
            },
            _1: " (%s)"
          }), link("View event in Avo", "https://www.avo.app/schemas/" + schemaId + "/branches/" + branchId + "/events/" + eventWithPropertiesChanges.eventId));
  var isEventIncludedInCodegen = Belt_List.someU(maybeEvent.includeSources, (function (includedSource) {
          if (includedSource.id === sourceWithChanges.source.id) {
            return includedSource.includeInCodegen;
          } else {
            return false;
          }
        }));
  if (isEventIncludedInCodegen) {
    Curry._2(Format.fprintf(ppf, /* Format */{
              _0: {
                TAG: /* Formatting_lit */17,
                _0: {
                  TAG: /* Break */0,
                  _0: "@,",
                  _1: 0,
                  _2: 0
                },
                _1: {
                  TAG: /* Formatting_lit */17,
                  _0: {
                    TAG: /* Break */0,
                    _0: "@,",
                    _1: 0,
                    _2: 0
                  },
                  _1: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String_literal */11,
                      _0: "This event should be implemented with Avo Codegen. ",
                      _1: {
                        TAG: /* String */2,
                        _0: /* No_padding */0,
                        _1: {
                          TAG: /* String_literal */11,
                          _0: "  ",
                          _1: {
                            TAG: /* Formatting_lit */17,
                            _0: {
                              TAG: /* Break */0,
                              _0: "@,",
                              _1: 0,
                              _2: 0
                            },
                            _1: /* End_of_format */0
                          }
                        }
                      }
                    }
                  }
                }
              },
              _1: "@,@,%sThis event should be implemented with Avo Codegen. %s  @,"
            }), h4, link("See implement instructions here", "https://avo.app/schemas/" + schemaId + "/branches/" + branchId + "/implement/sources/" + sourceWithChanges.source.id + "#" + maybeEvent.id));
  }
  Curry._1(Format.fprintf(ppf, /* Format */{
            _0: {
              TAG: /* Formatting_lit */17,
              _0: {
                TAG: /* Break */0,
                _0: "@,",
                _1: 0,
                _2: 0
              },
              _1: {
                TAG: /* String_literal */11,
                _0: "Description: ",
                _1: {
                  TAG: /* String */2,
                  _0: /* No_padding */0,
                  _1: {
                    TAG: /* Formatting_lit */17,
                    _0: {
                      TAG: /* Break */0,
                      _0: "@,",
                      _1: 0,
                      _2: 0
                    },
                    _1: /* End_of_format */0
                  }
                }
              }
            },
            _1: "@,Description: %s@,"
          }), formatDescription(eventDescription));
  var triggerssDiff = calculateTriggersDiff(eventWithPropertiesChanges, model$1);
  if (isNew && (Caml_obj.caml_notequal(triggerssDiff.newTriggerIds, []) || Caml_obj.caml_notequal(triggerssDiff.removedTriggerIds, []) || Caml_obj.caml_notequal(triggerssDiff.updatedTriggerIds, []) || Caml_obj.caml_notequal(triggerssDiff.unchangedTriggerIds, []))) {
    Curry._1(Format.fprintf(ppf, /* Format */{
              _0: {
                TAG: /* Formatting_lit */17,
                _0: {
                  TAG: /* Break */0,
                  _0: "@,",
                  _1: 0,
                  _2: 0
                },
                _1: {
                  TAG: /* String */2,
                  _0: /* No_padding */0,
                  _1: {
                    TAG: /* String_literal */11,
                    _0: "New triggers on this event",
                    _1: {
                      TAG: /* Formatting_lit */17,
                      _0: {
                        TAG: /* Break */0,
                        _0: "@,",
                        _1: 0,
                        _2: 0
                      },
                      _1: /* End_of_format */0
                    }
                  }
                }
              },
              _1: "@,%sNew triggers on this event@,"
            }), h4);
  }
  if (Caml_obj.caml_notequal(triggerssDiff.newTriggerIds, [])) {
    if (!isNew) {
      Curry._1(Format.fprintf(ppf, /* Format */{
                _0: {
                  TAG: /* Formatting_lit */17,
                  _0: {
                    TAG: /* Break */0,
                    _0: "@,",
                    _1: 0,
                    _2: 0
                  },
                  _1: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String_literal */11,
                      _0: "New triggers on this event",
                      _1: {
                        TAG: /* Formatting_lit */17,
                        _0: {
                          TAG: /* Break */0,
                          _0: "@,",
                          _1: 0,
                          _2: 0
                        },
                        _1: /* End_of_format */0
                      }
                    }
                  }
                },
                _1: "@,%sNew triggers on this event@,"
              }), h4);
    }
    Belt_Array.forEachWithIndexU(triggerssDiff.newTriggerIds, (function (index, newTriggerId) {
            return printEventTriggerInstructions(ppf, maybeEvent, model$1, sourceWithChanges, newTriggerId, schemaId, branchId, index + 1 | 0);
          }));
  }
  if (Caml_obj.caml_notequal(triggerssDiff.removedTriggerIds, [])) {
    if (!isNew) {
      Curry._1(Format.fprintf(ppf, /* Format */{
                _0: {
                  TAG: /* Formatting_lit */17,
                  _0: {
                    TAG: /* Break */0,
                    _0: "@,",
                    _1: 0,
                    _2: 0
                  },
                  _1: {
                    TAG: /* Formatting_lit */17,
                    _0: {
                      TAG: /* Break */0,
                      _0: "@,",
                      _1: 0,
                      _2: 0
                    },
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* String_literal */11,
                        _0: "Removed triggers on this event",
                        _1: {
                          TAG: /* Formatting_lit */17,
                          _0: {
                            TAG: /* Break */0,
                            _0: "@,",
                            _1: 0,
                            _2: 0
                          },
                          _1: /* End_of_format */0
                        }
                      }
                    }
                  }
                },
                _1: "@,@,%sRemoved triggers on this event@,"
              }), h4);
    }
    Belt_Array.forEachWithIndexU(triggerssDiff.removedTriggerIds, (function (index, removedTriggerId) {
            return printEventTriggerInstructions(ppf, maybeEvent, masterModel$1, sourceWithChanges, removedTriggerId, schemaId, branchId, index + 1 | 0);
          }));
  }
  if (Caml_obj.caml_notequal(triggerssDiff.updatedTriggerIds, [])) {
    if (!isNew) {
      Curry._1(Format.fprintf(ppf, /* Format */{
                _0: {
                  TAG: /* Formatting_lit */17,
                  _0: {
                    TAG: /* Break */0,
                    _0: "@,",
                    _1: 0,
                    _2: 0
                  },
                  _1: {
                    TAG: /* Formatting_lit */17,
                    _0: {
                      TAG: /* Break */0,
                      _0: "@,",
                      _1: 0,
                      _2: 0
                    },
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* String_literal */11,
                        _0: "Updated triggers on this event",
                        _1: {
                          TAG: /* Formatting_lit */17,
                          _0: {
                            TAG: /* Break */0,
                            _0: "@,",
                            _1: 0,
                            _2: 0
                          },
                          _1: /* End_of_format */0
                        }
                      }
                    }
                  }
                },
                _1: "@,@,%sUpdated triggers on this event@,"
              }), h4);
    }
    Belt_Array.forEachWithIndexU(triggerssDiff.updatedTriggerIds, (function (index, updatedTriggerId) {
            return printEventTriggerInstructions(ppf, maybeEvent, model$1, sourceWithChanges, updatedTriggerId, schemaId, branchId, index + 1 | 0);
          }));
  }
  if (Caml_obj.caml_notequal(triggerssDiff.unchangedTriggerIds, [])) {
    if (!isNew) {
      Curry._1(Format.fprintf(ppf, /* Format */{
                _0: {
                  TAG: /* Formatting_lit */17,
                  _0: {
                    TAG: /* Break */0,
                    _0: "@,",
                    _1: 0,
                    _2: 0
                  },
                  _1: {
                    TAG: /* Formatting_lit */17,
                    _0: {
                      TAG: /* Break */0,
                      _0: "@,",
                      _1: 0,
                      _2: 0
                    },
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* String_literal */11,
                        _0: "Existing triggers on this event",
                        _1: {
                          TAG: /* Formatting_lit */17,
                          _0: {
                            TAG: /* Break */0,
                            _0: "@,",
                            _1: 0,
                            _2: 0
                          },
                          _1: /* End_of_format */0
                        }
                      }
                    }
                  }
                },
                _1: "@,@,%sExisting triggers on this event@,"
              }), h4);
    }
    Belt_Array.forEachWithIndexU(triggerssDiff.unchangedTriggerIds, (function (index, unchangedTriggerId) {
            return printEventTriggerInstructions(ppf, maybeEvent, model$1, sourceWithChanges, unchangedTriggerId, schemaId, branchId, index + 1 | 0);
          }));
  }
  var hasSystemProperties = Caml_obj.caml_notequal(systemPropertiesDiff.newSystemPropertyIds, []) || Caml_obj.caml_notequal(systemPropertiesDiff.removedSystemPropertyIds, []) || Caml_obj.caml_notequal(systemPropertiesDiff.updatedSystemPropertyIds, []) || Caml_obj.caml_notequal(systemPropertiesDiff.oldSystemPropertyIds, []);
  var hasSystemPropertiesChanges = Caml_obj.caml_notequal(systemPropertiesDiff.newSystemPropertyIds, []) || Caml_obj.caml_notequal(systemPropertiesDiff.removedSystemPropertyIds, []) || Caml_obj.caml_notequal(systemPropertiesDiff.updatedSystemPropertyIds, []);
  if (hasSystemProperties) {
    Format.fprintf(ppf, /* Format */{
          _0: {
            TAG: /* Formatting_lit */17,
            _0: {
              TAG: /* Break */0,
              _0: "@,",
              _1: 0,
              _2: 0
            },
            _1: {
              TAG: /* String_literal */11,
              _0: "Some event properties are configured to be sent with all events. Make sure to include those on this event. Learn more about the properties sent with all events above.",
              _1: /* End_of_format */0
            }
          },
          _1: "@,Some event properties are configured to be sent with all events. Make sure to include those on this event. Learn more about the properties sent with all events above."
        });
  }
  if (hasSystemPropertiesChanges) {
    Format.fprintf(ppf, /* Format */{
          _0: {
            TAG: /* Formatting_lit */17,
            _0: {
              TAG: /* Break */0,
              _0: "@,",
              _1: 0,
              _2: 0
            },
            _1: {
              TAG: /* String_literal */11,
              _0: "There are changes in some event properties that are sent with all events. Learn more about the properties sent with all events above.",
              _1: /* End_of_format */0
            }
          },
          _1: "@,There are changes in some event properties that are sent with all events. Learn more about the properties sent with all events above."
        });
  }
  var propertiesDiff = calculatePropertiesDiffWithoutSystemProps(eventWithPropertiesChanges, model$1, maybeEvent, updatedMatchValidationsPropertyIdsOnSource, updatedOtherPropertyIdsOnSource);
  var printEventUserAndGroupPropertiesSeparately = function (properties, isNew) {
    var eventProperties = Belt_Array.keepU(properties, (function (prop) {
            if (prop.sendAs === /* EventProperty */1) {
              return true;
            } else {
              return prop.sendAs === /* SystemProperty */0;
            }
          }));
    var userProperties = Belt_Array.keepU(properties, (function (prop) {
            return prop.sendAs === /* UserProperty */2;
          }));
    var groupProperties = Belt_Array.keepU(properties, (function (prop) {
            var match = prop.sendAs;
            if (typeof match === "number") {
              return false;
            } else {
              return true;
            }
          }));
    if (Caml_obj.caml_notequal(eventProperties, [])) {
      Format.fprintf(ppf, /* Format */{
            _0: {
              TAG: /* Formatting_lit */17,
              _0: {
                TAG: /* Break */0,
                _0: "@,",
                _1: 0,
                _2: 0
              },
              _1: {
                TAG: /* Formatting_lit */17,
                _0: {
                  TAG: /* Break */0,
                  _0: "@,",
                  _1: 0,
                  _2: 0
                },
                _1: {
                  TAG: /* String_literal */11,
                  _0: "Event properties:",
                  _1: /* End_of_format */0
                }
              }
            },
            _1: "@,@,Event properties:"
          });
      Belt_Array.forEachU(eventProperties, (function (newProperty) {
              return printPropertyInstructions(ppf, newProperty, model$1, masterModel$1, maybeEvent, sourceWithChanges.source.id, format, isNew, hasAnyValuesFeatureFlag, hasLongValuesFeatureFlag);
            }));
    }
    if (Caml_obj.caml_notequal(userProperties, [])) {
      Format.fprintf(ppf, /* Format */{
            _0: {
              TAG: /* Formatting_lit */17,
              _0: {
                TAG: /* Break */0,
                _0: "@,",
                _1: 0,
                _2: 0
              },
              _1: {
                TAG: /* Formatting_lit */17,
                _0: {
                  TAG: /* Break */0,
                  _0: "@,",
                  _1: 0,
                  _2: 0
                },
                _1: {
                  TAG: /* String_literal */11,
                  _0: "User properties:",
                  _1: /* End_of_format */0
                }
              }
            },
            _1: "@,@,User properties:"
          });
      Belt_Array.forEachU(userProperties, (function (newProperty) {
              return printPropertyInstructions(ppf, newProperty, model$1, masterModel$1, maybeEvent, sourceWithChanges.source.id, format, isNew, hasAnyValuesFeatureFlag, hasLongValuesFeatureFlag);
            }));
    }
    if (Caml_obj.caml_notequal(groupProperties, [])) {
      Format.fprintf(ppf, /* Format */{
            _0: {
              TAG: /* Formatting_lit */17,
              _0: {
                TAG: /* Break */0,
                _0: "@,",
                _1: 0,
                _2: 0
              },
              _1: {
                TAG: /* Formatting_lit */17,
                _0: {
                  TAG: /* Break */0,
                  _0: "@,",
                  _1: 0,
                  _2: 0
                },
                _1: {
                  TAG: /* String_literal */11,
                  _0: "Group properties:",
                  _1: /* End_of_format */0
                }
              }
            },
            _1: "@,@,Group properties:"
          });
      return Belt_Array.forEachU(groupProperties, (function (newProperty) {
                    return printPropertyInstructions(ppf, newProperty, model$1, masterModel$1, maybeEvent, sourceWithChanges.source.id, format, isNew, hasAnyValuesFeatureFlag, hasLongValuesFeatureFlag);
                  }));
    }
    
  };
  if (isNew && (Caml_obj.caml_notequal(propertiesDiff.newPropertyIds, []) || Caml_obj.caml_notequal(propertiesDiff.removedPropertyIds, []) || Caml_obj.caml_notequal(propertiesDiff.updatedPropertyIds, []) || Caml_obj.caml_notequal(propertiesDiff.oldPropertyIds, []))) {
    Curry._1(Format.fprintf(ppf, /* Format */{
              _0: {
                TAG: /* Formatting_lit */17,
                _0: {
                  TAG: /* Break */0,
                  _0: "@,",
                  _1: 0,
                  _2: 0
                },
                _1: {
                  TAG: /* Formatting_lit */17,
                  _0: {
                    TAG: /* Break */0,
                    _0: "@,",
                    _1: 0,
                    _2: 0
                  },
                  _1: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String_literal */11,
                      _0: "New properties on this event",
                      _1: /* End_of_format */0
                    }
                  }
                }
              },
              _1: "@,@,%sNew properties on this event"
            }), h4);
  }
  if (Caml_obj.caml_notequal(propertiesDiff.newPropertyIds, [])) {
    if (!isNew) {
      Curry._1(Format.fprintf(ppf, /* Format */{
                _0: {
                  TAG: /* Formatting_lit */17,
                  _0: {
                    TAG: /* Break */0,
                    _0: "@,",
                    _1: 0,
                    _2: 0
                  },
                  _1: {
                    TAG: /* Formatting_lit */17,
                    _0: {
                      TAG: /* Break */0,
                      _0: "@,",
                      _1: 0,
                      _2: 0
                    },
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* String_literal */11,
                        _0: "New properties on this event",
                        _1: /* End_of_format */0
                      }
                    }
                  }
                },
                _1: "@,@,%sNew properties on this event"
              }), h4);
    }
    var properties = propertyIdsToModelPropertyTs(propertiesDiff.newPropertyIds, model$1);
    printEventUserAndGroupPropertiesSeparately(properties, true);
  }
  if (Caml_obj.caml_notequal(propertiesDiff.removedPropertyIds, [])) {
    if (!isNew) {
      Curry._1(Format.fprintf(ppf, /* Format */{
                _0: {
                  TAG: /* Formatting_lit */17,
                  _0: {
                    TAG: /* Break */0,
                    _0: "@,",
                    _1: 0,
                    _2: 0
                  },
                  _1: {
                    TAG: /* Formatting_lit */17,
                    _0: {
                      TAG: /* Break */0,
                      _0: "@,",
                      _1: 0,
                      _2: 0
                    },
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* String_literal */11,
                        _0: "Removed properties on this event",
                        _1: /* End_of_format */0
                      }
                    }
                  }
                },
                _1: "@,@,%sRemoved properties on this event"
              }), h4);
    }
    var properties$1 = propertyIdsToModelPropertyTsWithArchive(propertiesDiff.removedPropertyIds, model$1);
    printEventUserAndGroupPropertiesSeparately(properties$1, isNew);
  }
  if (Caml_obj.caml_notequal(propertiesDiff.updatedPropertyIds, [])) {
    if (!isNew) {
      Curry._1(Format.fprintf(ppf, /* Format */{
                _0: {
                  TAG: /* Formatting_lit */17,
                  _0: {
                    TAG: /* Break */0,
                    _0: "@,",
                    _1: 0,
                    _2: 0
                  },
                  _1: {
                    TAG: /* Formatting_lit */17,
                    _0: {
                      TAG: /* Break */0,
                      _0: "@,",
                      _1: 0,
                      _2: 0
                    },
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* String_literal */11,
                        _0: "Updated properties on this event",
                        _1: /* End_of_format */0
                      }
                    }
                  }
                },
                _1: "@,@,%sUpdated properties on this event"
              }), h4);
    }
    var properties$2 = propertyIdsToModelPropertyTs(propertiesDiff.updatedPropertyIds, model$1);
    printEventUserAndGroupPropertiesSeparately(properties$2, isNew);
  }
  if (Caml_obj.caml_notequal(propertiesDiff.oldPropertyIds, [])) {
    if (!isNew) {
      Curry._1(Format.fprintf(ppf, /* Format */{
                _0: {
                  TAG: /* Formatting_lit */17,
                  _0: {
                    TAG: /* Break */0,
                    _0: "@,",
                    _1: 0,
                    _2: 0
                  },
                  _1: {
                    TAG: /* Formatting_lit */17,
                    _0: {
                      TAG: /* Break */0,
                      _0: "@,",
                      _1: 0,
                      _2: 0
                    },
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* String_literal */11,
                        _0: "Existing properties on this event",
                        _1: /* End_of_format */0
                      }
                    }
                  }
                },
                _1: "@,@,%sExisting properties on this event"
              }), h4);
    }
    var properties$3 = propertyIdsToModelPropertyTs(propertiesDiff.oldPropertyIds, model$1);
    printEventUserAndGroupPropertiesSeparately(properties$3, isNew);
  }
  var destinationsDiff = calculateDestinationsDiff(eventWithPropertiesChanges, sourceWithChanges, model$1);
  if (isNew && (Caml_obj.caml_notequal(destinationsDiff.newDestinationIds, []) || Caml_obj.caml_notequal(destinationsDiff.removedDestinationIds, []) || Caml_obj.caml_notequal(destinationsDiff.oldDestinationIds, []))) {
    Curry._1(Format.fprintf(ppf, /* Format */{
              _0: {
                TAG: /* Formatting_lit */17,
                _0: {
                  TAG: /* Break */0,
                  _0: "@,",
                  _1: 0,
                  _2: 0
                },
                _1: {
                  TAG: /* Formatting_lit */17,
                  _0: {
                    TAG: /* Break */0,
                    _0: "@,",
                    _1: 0,
                    _2: 0
                  },
                  _1: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String_literal */11,
                      _0: "New destinations on this event",
                      _1: {
                        TAG: /* Formatting_lit */17,
                        _0: {
                          TAG: /* Break */0,
                          _0: "@,",
                          _1: 0,
                          _2: 0
                        },
                        _1: /* End_of_format */0
                      }
                    }
                  }
                }
              },
              _1: "@,@,%sNew destinations on this event@,"
            }), h4);
  }
  if (Caml_obj.caml_notequal(destinationsDiff.newDestinationIds, [])) {
    if (!isNew) {
      Curry._1(Format.fprintf(ppf, /* Format */{
                _0: {
                  TAG: /* Formatting_lit */17,
                  _0: {
                    TAG: /* Break */0,
                    _0: "@,",
                    _1: 0,
                    _2: 0
                  },
                  _1: {
                    TAG: /* Formatting_lit */17,
                    _0: {
                      TAG: /* Break */0,
                      _0: "@,",
                      _1: 0,
                      _2: 0
                    },
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* String_literal */11,
                        _0: "New destinations on this event",
                        _1: {
                          TAG: /* Formatting_lit */17,
                          _0: {
                            TAG: /* Break */0,
                            _0: "@,",
                            _1: 0,
                            _2: 0
                          },
                          _1: /* End_of_format */0
                        }
                      }
                    }
                  }
                },
                _1: "@,@,%sNew destinations on this event@,"
              }), h4);
    }
    Belt_Array.forEachU(destinationsDiff.newDestinationIds, (function (newDestinationId) {
            return printDestinationInstructions(ppf, newDestinationId, maybeEvent.id, maybeEvent, model$1, masterModel$1, format);
          }));
  }
  if (Caml_obj.caml_notequal(destinationsDiff.removedDestinationIds, [])) {
    if (!isNew) {
      Curry._1(Format.fprintf(ppf, /* Format */{
                _0: {
                  TAG: /* Formatting_lit */17,
                  _0: {
                    TAG: /* Break */0,
                    _0: "@,",
                    _1: 0,
                    _2: 0
                  },
                  _1: {
                    TAG: /* Formatting_lit */17,
                    _0: {
                      TAG: /* Break */0,
                      _0: "@,",
                      _1: 0,
                      _2: 0
                    },
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* String_literal */11,
                        _0: "Removed destinations on this event",
                        _1: {
                          TAG: /* Formatting_lit */17,
                          _0: {
                            TAG: /* Break */0,
                            _0: "@,",
                            _1: 0,
                            _2: 0
                          },
                          _1: /* End_of_format */0
                        }
                      }
                    }
                  }
                },
                _1: "@,@,%sRemoved destinations on this event@,"
              }), h4);
    }
    Belt_Array.forEachU(destinationsDiff.removedDestinationIds, (function (removedDestinationId) {
            return printDestinationInstructions(ppf, removedDestinationId, maybeEvent.id, maybeEvent, model$1, masterModel$1, format);
          }));
  }
  if (Caml_obj.caml_notequal(destinationsDiff.oldDestinationIds, [])) {
    if (!isNew) {
      Curry._1(Format.fprintf(ppf, /* Format */{
                _0: {
                  TAG: /* Formatting_lit */17,
                  _0: {
                    TAG: /* Break */0,
                    _0: "@,",
                    _1: 0,
                    _2: 0
                  },
                  _1: {
                    TAG: /* Formatting_lit */17,
                    _0: {
                      TAG: /* Break */0,
                      _0: "@,",
                      _1: 0,
                      _2: 0
                    },
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* String_literal */11,
                        _0: "Existing destinations on this event",
                        _1: {
                          TAG: /* Formatting_lit */17,
                          _0: {
                            TAG: /* Break */0,
                            _0: "@,",
                            _1: 0,
                            _2: 0
                          },
                          _1: /* End_of_format */0
                        }
                      }
                    }
                  }
                },
                _1: "@,@,%sExisting destinations on this event@,"
              }), h4);
    }
    return Belt_Array.forEachU(destinationsDiff.oldDestinationIds, (function (oldDestinationId) {
                  return printDestinationInstructions(ppf, oldDestinationId, maybeEvent.id, maybeEvent, model$1, masterModel$1, format);
                }));
  }
  
}

function calculateEventsDiff(sourceWithChanges, model, updatedMatchValidationsPropertyIdsOnSource, updatedOtherPropertyIdsOnSource) {
  var newEvents = Belt_Array.keepU(sourceWithChanges.eventsChanges, (function (eventWithPropertiesChanges) {
          return Belt_Array.someU(eventWithPropertiesChanges.eventActions, (function (action) {
                        if (typeof action !== "object") {
                          return false;
                        }
                        var variant = action.NAME;
                        if (variant === "AddEvent") {
                          return true;
                        } else if (variant === "IncludeEventInSourceV2") {
                          return action.VAL[1] === sourceWithChanges.source.id;
                        } else {
                          return false;
                        }
                      }));
        }));
  var removedEvents = Belt_Array.keepU(sourceWithChanges.eventsChanges, (function (eventWithPropertiesChanges) {
          return Belt_Array.someU(eventWithPropertiesChanges.eventActions, (function (action) {
                        if (typeof action !== "object") {
                          return false;
                        }
                        var variant = action.NAME;
                        if (variant === "RemoveEvent") {
                          return true;
                        } else if (variant === "ExcludeEventFromSource" || variant === "ExcludeEventFromSourceV2") {
                          return action.VAL[1] === sourceWithChanges.source.id;
                        } else {
                          return false;
                        }
                      }));
        }));
  var updatedEvents = Belt_Array.keepU(sourceWithChanges.eventsChanges, (function (eventWithPropertiesChanges) {
          if (Belt_Array.everyU(newEvents, (function (newEvent) {
                    return Caml_obj.caml_notequal(newEvent, eventWithPropertiesChanges);
                  }))) {
            return Belt_Array.everyU(removedEvents, (function (removedEvent) {
                          return Caml_obj.caml_notequal(removedEvent, eventWithPropertiesChanges);
                        }));
          } else {
            return false;
          }
        }));
  var eventsRelevantForPropertyAndSource = function (propertyId, model) {
    if (isSystemProp(propertyId, model)) {
      return ModelUtils.getSourceEvents(undefined, model, sourceWithChanges.source.id);
    } else if (sourceUsedWhenNoSourcesAreSetUp.id === sourceWithChanges.source.id) {
      return ModelUtils.eventsSendingProperty(model, undefined, propertyId);
    } else {
      return ModelUtils.eventsSendingPropertyFromSources(model, {
                  hd: sourceWithChanges.source.id,
                  tl: /* [] */0
                }, propertyId);
    }
  };
  var filterHandledEvents = function (eventId) {
    if (Belt_Array.everyU(newEvents, (function (newEvent) {
              return newEvent.eventId !== eventId;
            })) && Belt_Array.everyU(removedEvents, (function (removedEvent) {
              return removedEvent.eventId !== eventId;
            }))) {
      return Belt_Array.everyU(updatedEvents, (function (removedEvent) {
                    return removedEvent.eventId !== eventId;
                  }));
    } else {
      return false;
    }
  };
  var updatedEventIdsDerivedFromMatchValidationsPropertyUpdates = Belt_List.flatten(Belt_List.mapU(updatedMatchValidationsPropertyIdsOnSource, (function (updatedPropertyId) {
              return Belt_List.keepU(Belt_List.mapU(Belt_List.keepU(eventsRelevantForPropertyAndSource(updatedPropertyId, model), (function ($$event) {
                                    var property = Belt_Option.flatMapU(ModelUtils.getPropertyById(updatedPropertyId, model), (function (prop) {
                                            return ModelUtils.resolveProperty(model, prop);
                                          }));
                                    var pinnedValue = ModelUtils.getPropertyPinnedValueFromEvent($$event, updatedPropertyId);
                                    var validatedPinnedValue = pinnedValue !== undefined && property !== undefined && ValidatePinnedValueWithPropertyValuesUseCase.validate(pinnedValue, property, ModelUtils.hasMigrated(model, "EventSpecificAllowedPropertyValues")) ? pinnedValue : undefined;
                                    if (validatedPinnedValue !== undefined && property !== undefined) {
                                      return !matchHasLiteral(property, validatedPinnedValue);
                                    } else {
                                      return true;
                                    }
                                  })), (function ($$event) {
                                return $$event.id;
                              })), filterHandledEvents);
            })));
  var updatedEventIdsDerivedFromOtherPropertyUpdates = Belt_List.flatten(Belt_List.mapU(updatedOtherPropertyIdsOnSource, (function (updatedPropertyId) {
              return Belt_List.keepU(Belt_List.mapU(eventsRelevantForPropertyAndSource(updatedPropertyId, model), (function ($$event) {
                                return $$event.id;
                              })), filterHandledEvents);
            })));
  var updatedEventsDerivedFromPropertyUpdates = Belt_List.toArray(Belt_List.mapU(BeltListExtensions.dedupeString(Belt_List.concat(updatedEventIdsDerivedFromMatchValidationsPropertyUpdates, updatedEventIdsDerivedFromOtherPropertyUpdates)), (function (eventId) {
              return {
                      eventId: eventId,
                      eventActions: []
                    };
            })));
  var updatedEvents$1 = Belt_Array.concat(updatedEvents, updatedEventsDerivedFromPropertyUpdates);
  return {
          newEvents: newEvents,
          removedEvents: removedEvents,
          updatedEvents: updatedEvents$1
        };
}

function printSourceInstructions(ppf, model, masterModel, sourceWithChanges, schemaId, branchId, format, hasAnyValuesFeatureFlag, hasLongValuesFeatureFlag) {
  var h1 = format ? "# " : "h1. ";
  var h2 = format ? "## " : "h2. ";
  var sourceName = Belt_Option.getWithDefault(Belt_Option.map(ModelUtils.getSourceById(sourceWithChanges.source.id, model), AvoConfig.getSourceName), "Untitled Source");
  Format.fprintf(ppf, /* Format */{
        _0: {
          TAG: /* Formatting_gen */18,
          _0: {
            TAG: /* Open_box */1,
            _0: /* Format */{
              _0: {
                TAG: /* String_literal */11,
                _0: "<v 0>",
                _1: /* End_of_format */0
              },
              _1: "<v 0>"
            }
          },
          _1: /* End_of_format */0
        },
        _1: "@[<v 0>"
      });
  Curry._2(Format.fprintf(ppf, /* Format */{
            _0: {
              TAG: /* Formatting_lit */17,
              _0: {
                TAG: /* Break */0,
                _0: "@,",
                _1: 0,
                _2: 0
              },
              _1: {
                TAG: /* String */2,
                _0: /* No_padding */0,
                _1: {
                  TAG: /* String_literal */11,
                  _0: "Source: ",
                  _1: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: /* End_of_format */0
                  }
                }
              }
            },
            _1: "@,%sSource: %s"
          }), h1, sourceName);
  var updatedMatchValidationsPropertyIdsOnSource = BeltListExtensions.dedupeString(Belt_List.keepMapU(sourceWithChanges.propertyMatchValidationChanges, (function (param) {
              var item = param[0];
              if (typeof item === "object" && item.NAME === "Property") {
                return item.VAL;
              }
              
            })));
  var updatedOtherPropertyIdsOnSource = BeltListExtensions.dedupeString(Belt_List.keepMapU(sourceWithChanges.otherPropertyChanges, (function (param) {
              var item = param[0];
              if (typeof item === "object" && item.NAME === "Property") {
                return item.VAL;
              }
              
            })));
  var systemPropertiesDiff = calculateSystemPropertiesDiff(sourceWithChanges, model);
  if (Caml_obj.caml_notequal(systemPropertiesDiff.newSystemPropertyIds, [])) {
    Curry._1(Format.fprintf(ppf, /* Format */{
              _0: {
                TAG: /* Formatting_lit */17,
                _0: {
                  TAG: /* Break */0,
                  _0: "@,",
                  _1: 0,
                  _2: 0
                },
                _1: {
                  TAG: /* Formatting_lit */17,
                  _0: {
                    TAG: /* Break */0,
                    _0: "@,",
                    _1: 0,
                    _2: 0
                  },
                  _1: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String_literal */11,
                      _0: "New event properties on all events",
                      _1: {
                        TAG: /* Formatting_lit */17,
                        _0: {
                          TAG: /* Break */0,
                          _0: "@,",
                          _1: 0,
                          _2: 0
                        },
                        _1: /* End_of_format */0
                      }
                    }
                  }
                }
              },
              _1: "@,@,%sNew event properties on all events@,"
            }), h2);
    var properties = propertyIdsToModelPropertyTs(systemPropertiesDiff.newSystemPropertyIds, model);
    Belt_Array.forEachU(properties, (function (property) {
            return printPropertyInstructions(ppf, property, model, masterModel, TrackingPlanModel.emptyEvent("", ""), sourceWithChanges.source.id, format, true, hasAnyValuesFeatureFlag, hasLongValuesFeatureFlag);
          }));
  }
  if (Caml_obj.caml_notequal(systemPropertiesDiff.removedSystemPropertyIds, [])) {
    Curry._1(Format.fprintf(ppf, /* Format */{
              _0: {
                TAG: /* Formatting_lit */17,
                _0: {
                  TAG: /* Break */0,
                  _0: "@,",
                  _1: 0,
                  _2: 0
                },
                _1: {
                  TAG: /* Formatting_lit */17,
                  _0: {
                    TAG: /* Break */0,
                    _0: "@,",
                    _1: 0,
                    _2: 0
                  },
                  _1: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String_literal */11,
                      _0: "Event properties removed from all events",
                      _1: {
                        TAG: /* Formatting_lit */17,
                        _0: {
                          TAG: /* Break */0,
                          _0: "@,",
                          _1: 0,
                          _2: 0
                        },
                        _1: /* End_of_format */0
                      }
                    }
                  }
                }
              },
              _1: "@,@,%sEvent properties removed from all events@,"
            }), h2);
    var properties$1 = propertyIdsToModelPropertyTs(systemPropertiesDiff.removedSystemPropertyIds, model);
    Belt_Array.forEachU(properties$1, (function (property) {
            return printPropertyInstructions(ppf, property, model, masterModel, TrackingPlanModel.emptyEvent("", ""), sourceWithChanges.source.id, format, false, hasAnyValuesFeatureFlag, hasLongValuesFeatureFlag);
          }));
  }
  if (Caml_obj.caml_notequal(systemPropertiesDiff.updatedSystemPropertyIds, [])) {
    Curry._1(Format.fprintf(ppf, /* Format */{
              _0: {
                TAG: /* Formatting_lit */17,
                _0: {
                  TAG: /* Break */0,
                  _0: "@,",
                  _1: 0,
                  _2: 0
                },
                _1: {
                  TAG: /* Formatting_lit */17,
                  _0: {
                    TAG: /* Break */0,
                    _0: "@,",
                    _1: 0,
                    _2: 0
                  },
                  _1: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String_literal */11,
                      _0: "Event properties updated on all events",
                      _1: {
                        TAG: /* Formatting_lit */17,
                        _0: {
                          TAG: /* Break */0,
                          _0: "@,",
                          _1: 0,
                          _2: 0
                        },
                        _1: /* End_of_format */0
                      }
                    }
                  }
                }
              },
              _1: "@,@,%sEvent properties updated on all events@,"
            }), h2);
    var properties$2 = propertyIdsToModelPropertyTs(systemPropertiesDiff.updatedSystemPropertyIds, model);
    Belt_Array.forEachU(properties$2, (function (property) {
            return printPropertyInstructions(ppf, property, model, masterModel, TrackingPlanModel.emptyEvent("", ""), sourceWithChanges.source.id, format, false, hasAnyValuesFeatureFlag, hasLongValuesFeatureFlag);
          }));
  }
  if (Caml_obj.caml_notequal(systemPropertiesDiff.oldSystemPropertyIds, [])) {
    Curry._1(Format.fprintf(ppf, /* Format */{
              _0: {
                TAG: /* Formatting_lit */17,
                _0: {
                  TAG: /* Break */0,
                  _0: "@,",
                  _1: 0,
                  _2: 0
                },
                _1: {
                  TAG: /* Formatting_lit */17,
                  _0: {
                    TAG: /* Break */0,
                    _0: "@,",
                    _1: 0,
                    _2: 0
                  },
                  _1: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String_literal */11,
                      _0: "Existing event properties sent from all events",
                      _1: {
                        TAG: /* Formatting_lit */17,
                        _0: {
                          TAG: /* Break */0,
                          _0: "@,",
                          _1: 0,
                          _2: 0
                        },
                        _1: /* End_of_format */0
                      }
                    }
                  }
                }
              },
              _1: "@,@,%sExisting event properties sent from all events@,"
            }), h2);
    var properties$3 = propertyIdsToModelPropertyTs(systemPropertiesDiff.oldSystemPropertyIds, model);
    Belt_Array.forEachU(properties$3, (function (property) {
            return printPropertyInstructions(ppf, property, model, masterModel, TrackingPlanModel.emptyEvent("", ""), sourceWithChanges.source.id, format, false, hasAnyValuesFeatureFlag, hasLongValuesFeatureFlag);
          }));
  }
  var eventsDiff = calculateEventsDiff(sourceWithChanges, model, updatedMatchValidationsPropertyIdsOnSource, updatedOtherPropertyIdsOnSource);
  if (Caml_obj.caml_notequal(eventsDiff.newEvents, [])) {
    Curry._1(Format.fprintf(ppf, /* Format */{
              _0: {
                TAG: /* Formatting_lit */17,
                _0: {
                  TAG: /* Break */0,
                  _0: "@,",
                  _1: 0,
                  _2: 0
                },
                _1: {
                  TAG: /* Formatting_lit */17,
                  _0: {
                    TAG: /* Break */0,
                    _0: "@,",
                    _1: 0,
                    _2: 0
                  },
                  _1: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String_literal */11,
                      _0: "New Events:",
                      _1: /* End_of_format */0
                    }
                  }
                }
              },
              _1: "@,@,%sNew Events:"
            }), h2);
    Belt_Array.forEach(eventsDiff.newEvents, (function (eventChange) {
            printEventInstructions(ppf, model, masterModel, eventChange, schemaId, sourceWithChanges, format, branchId, updatedMatchValidationsPropertyIdsOnSource, updatedOtherPropertyIdsOnSource, true, systemPropertiesDiff, hasAnyValuesFeatureFlag, hasLongValuesFeatureFlag);
            
          }));
  }
  if (Caml_obj.caml_notequal(eventsDiff.removedEvents, [])) {
    Curry._1(Format.fprintf(ppf, /* Format */{
              _0: {
                TAG: /* Formatting_lit */17,
                _0: {
                  TAG: /* Break */0,
                  _0: "@,",
                  _1: 0,
                  _2: 0
                },
                _1: {
                  TAG: /* Formatting_lit */17,
                  _0: {
                    TAG: /* Break */0,
                    _0: "@,",
                    _1: 0,
                    _2: 0
                  },
                  _1: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String_literal */11,
                      _0: "Removed Events:",
                      _1: /* End_of_format */0
                    }
                  }
                }
              },
              _1: "@,@,%sRemoved Events:"
            }), h2);
    Belt_Array.forEach(eventsDiff.removedEvents, (function (eventChange) {
            printEventInstructions(ppf, model, masterModel, eventChange, schemaId, sourceWithChanges, format, branchId, updatedMatchValidationsPropertyIdsOnSource, updatedOtherPropertyIdsOnSource, false, systemPropertiesDiff, hasAnyValuesFeatureFlag, hasLongValuesFeatureFlag);
            
          }));
  }
  if (Caml_obj.caml_notequal(eventsDiff.updatedEvents, [])) {
    Curry._1(Format.fprintf(ppf, /* Format */{
              _0: {
                TAG: /* Formatting_lit */17,
                _0: {
                  TAG: /* Break */0,
                  _0: "@,",
                  _1: 0,
                  _2: 0
                },
                _1: {
                  TAG: /* Formatting_lit */17,
                  _0: {
                    TAG: /* Break */0,
                    _0: "@,",
                    _1: 0,
                    _2: 0
                  },
                  _1: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String_literal */11,
                      _0: "Updated Events:",
                      _1: /* End_of_format */0
                    }
                  }
                }
              },
              _1: "@,@,%sUpdated Events:"
            }), h2);
    Belt_Array.forEach(eventsDiff.updatedEvents, (function (eventChange) {
            printEventInstructions(ppf, model, masterModel, eventChange, schemaId, sourceWithChanges, format, branchId, updatedMatchValidationsPropertyIdsOnSource, updatedOtherPropertyIdsOnSource, false, systemPropertiesDiff, hasAnyValuesFeatureFlag, hasLongValuesFeatureFlag);
            
          }));
  }
  return Format.fprintf(ppf, /* Format */{
              _0: {
                TAG: /* Formatting_lit */17,
                _0: {
                  TAG: /* Break */0,
                  _0: "@,",
                  _1: 0,
                  _2: 0
                },
                _1: {
                  TAG: /* Formatting_lit */17,
                  _0: {
                    TAG: /* Break */0,
                    _0: "@;<1 -2>",
                    _1: 1,
                    _2: -2
                  },
                  _1: {
                    TAG: /* Formatting_lit */17,
                    _0: /* Close_box */0,
                    _1: /* End_of_format */0
                  }
                }
              },
              _1: "@,@;<1 -2>@]"
            });
}

function printInstructions(format, branchModel, masterModel, actions, schemaId, branchId, hasAnyValuesFeatureFlag, hasLongValuesFeatureFlag) {
  var eventActions = ModelUtils.getEventActions(actions);
  var sourcesToAttachedEventsActions = ModelUtils.getSourcesToAttachedEventsActions(branchModel, eventActions);
  var sourcesToAttachedEventsActions$1 = Caml_obj.caml_equal(sourcesToAttachedEventsActions, []) ? [[
        sourceUsedWhenNoSourcesAreSetUp,
        eventActions
      ]] : sourcesToAttachedEventsActions;
  var match = Printing.str_formatter(undefined);
  var ppf = match[1];
  Belt_Array.forEach(calculateSourceWithEventsChanges(branchModel, sourcesToAttachedEventsActions$1, actions), (function (sourceWithChanges) {
          printSourceInstructions(ppf, branchModel, masterModel, sourceWithChanges, schemaId, branchId, format, hasAnyValuesFeatureFlag, hasLongValuesFeatureFlag);
          
        }));
  return Printing.flush_str_formatter(match[0], ppf).trim();
}

export {
  stylingFormatToJs ,
  stylingFormatFromJs ,
  instructionsFormatToAnalyticsFormat ,
  Styling ,
  allPropertyChanges ,
  isSystemProp ,
  isPropertyRelevantForSource ,
  isPropertyValidationAction ,
  calculateSourceWithEventsChanges ,
  sourceUsedWhenNoSourcesAreSetUp ,
  formatDescription ,
  hasDestinationPropertyNameMappingOnEvent ,
  getPropertyMappingName ,
  getPropertyName ,
  hasDestinationEventNameMappingOnEvent ,
  getEventMappingName ,
  getEventName ,
  printPropertyInstructions ,
  printDestinationInstructions ,
  propertyActionsToPropertyIds ,
  calculateTriggersDiff ,
  calculateDestinationsDiff ,
  calculateSystemPropertiesDiff ,
  matchHasLiteral ,
  updatedPropertyIdsOnSourceWithPinnedPropsFilter ,
  calculatePropertiesDiffWithoutSystemProps ,
  prepareModel ,
  propertyIdsToModelPropertyTs ,
  propertyIdsToModelPropertyTsWithArchive ,
  printEventInstructions ,
  calculateEventsDiff ,
  printSourceInstructions ,
  printInstructions ,
  
}
/* sourceUsedWhenNoSourcesAreSetUp Not a pure module */
