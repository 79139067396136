// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "../../../app/src/ModelUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";

function get(model, $$event) {
  var basePropertyBundledPropertyIds = Belt_List.toArray(Belt_List.flatten(Belt_List.mapU($$event.propertyBundles, (function (bundleRef) {
                  var bundle = Belt_List.getByU(model.propertyBundles, (function (bundle) {
                          return bundle.id === bundleRef.id;
                        }));
                  return Belt_Option.getWithDefault(Belt_Option.map(bundle, (function (bundle) {
                                    return bundle.properties;
                                  })), /* [] */0);
                }))));
  var baseEventPropertyRefsIds = Belt_List.toArray(Belt_List.map($$event.properties, ModelUtils.getPropertyId));
  return Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.concat(baseEventPropertyRefsIds, basePropertyBundledPropertyIds)));
}

export {
  get ,
  
}
/* ModelUtils Not a pure module */
