// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Styles from "../styles.mjs";
import * as UserBlot from "../UserBlot.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as EventBlot from "../EventBlot.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as QuillDelta from "../../../model/src/externals/quillDelta.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import QuillDelta$1 from "quill-delta";
import QuillDeltaToHtml from "quill-delta-to-html";
import * as ServerJs from "react-dom/server.js";
import * as QuillMention from "../../../quill-mention";

var RenderItem = {};

var mention = QuillMention;

var Mention = {
  RenderItem: RenderItem,
  mention: mention
};

var Attributes = {};

var Op = {};

var Ops = {};

function diff(t1, t2) {
  return QuillDelta.cleanNonInsert(t1).diff(QuillDelta.cleanNonInsert(t2));
}

var Delta = {
  diff: diff
};

var HTMLConverter = {};

var Editor = {};

var Toolbar = {};

var Modules = {};

function toHTML(delta, events, param) {
  var converter = new QuillDeltaToHtml(delta.ops);
  converter.renderCustomWith(function (customOp) {
        var insert = customOp.insert;
        if (insert !== undefined) {
          if (insert.type === "event-mention") {
            var value = insert.value;
            var eventId = value.id;
            return ServerJs.renderToString(React.createElement(EventBlot.make, {
                            eventId: eventId,
                            schemaId: value.schemaId,
                            eventName: events !== undefined ? Belt_Option.mapWithDefault(Belt_List.head(Belt_List.keep(events, (function ($$event) {
                                              return $$event.id === eventId;
                                            }))), "[DELETED] " + value.value, (function ($$event) {
                                      return $$event.name;
                                    })) : value.value
                          }));
          }
          if (insert.type === "user-mention") {
            var value$1 = insert.value;
            var userName = value$1.value;
            return ServerJs.renderToString(React.createElement(UserBlot.make, {
                            userName: userName
                          }));
          }
          
        }
        var element = document.createElement("div");
        element.id = "unknown-blob";
        return element.outerHTML;
      });
  return converter.convert();
}

function toHTMLWithDiff(from, to_, events, param) {
  var ops = diff(from, to_).ops;
  var diff$1 = Belt_Array.map(ops, (function (op) {
          var match = op.insert;
          var match$1 = op.delete;
          if (match !== undefined) {
            op.attributes = {
              background: Styles.Color.toString(Styles.Color.lightGreen),
              color: Styles.Color.toString(Styles.Color.darkGreen)
            };
            return op;
          } else if (match$1 !== undefined) {
            return {
                    attributes: {
                      background: Styles.Color.toString(Styles.Color.lightRed),
                      color: Styles.Color.toString(Styles.Color.darkRed)
                    },
                    retain: match$1
                  };
          } else {
            return op;
          }
        }));
  var content = from.compose(new QuillDelta$1(diff$1)).ops;
  var converter = new QuillDeltaToHtml(content);
  converter.renderCustomWith(function (customOp) {
        var insert = customOp.insert;
        if (insert !== undefined) {
          if (insert.type === "event-mention") {
            var value = insert.value;
            var eventId = value.id;
            return ServerJs.renderToString(React.createElement(EventBlot.make, {
                            eventId: eventId,
                            schemaId: value.schemaId,
                            eventName: events !== undefined ? Belt_Option.mapWithDefault(Belt_List.head(Belt_List.keep(events, (function ($$event) {
                                              return $$event.id === eventId;
                                            }))), "[DELETED] " + value.value, (function ($$event) {
                                      return $$event.name;
                                    })) : value.value
                          }));
          }
          if (insert.type === "user-mention") {
            var value$1 = insert.value;
            var userName = value$1.value;
            return ServerJs.renderToString(React.createElement(UserBlot.make, {
                            userName: userName
                          }));
          }
          
        }
        var element = document.createElement("div");
        element.id = "unknown-blob";
        return element.outerHTML;
      });
  return converter.convert();
}

export {
  Mention ,
  Attributes ,
  Op ,
  Ops ,
  Delta ,
  HTMLConverter ,
  Editor ,
  Toolbar ,
  Modules ,
  toHTML ,
  toHTMLWithDiff ,
  
}
/* mention Not a pure module */
