// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "./Icon.mjs";
import * as $$Text from "./Text.mjs";
import * as Asset from "./Asset.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as Icons from "./Icons.mjs";
import * as Title from "./Title.mjs";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Router from "./Router.mjs";
import * as Sentry from "./externals/Sentry.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as AvoUtils from "../../shared/utils/AvoUtils.mjs";
import * as DidMount from "./DidMount.mjs";
import * as Accordion from "./Accordion.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as DemoBranch from "./DemoBranch.mjs";
import * as IconImport from "./IconImport.mjs";
import * as ModelStore from "./ModelStore.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Collapsible from "./Collapsible.mjs";
import * as NamedBranch from "./NamedBranch.mjs";
import * as RouterStore from "./RouterStore.mjs";
import * as DoptMigration from "./DoptMigration.mjs";
import * as IconCheckmark from "./IconCheckmark.mjs";
import * as ViewerContext from "./ViewerContext.mjs";
import * as FramerMotion from "framer-motion";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as InspectorStats from "./inspector/InspectorStats.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as CreateButtonIcon from "./emptystates/CreateButtonIcon.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as InspectorIssuesStore from "./inspector/InspectorIssuesStore.mjs";
import * as GettingStarted__Types from "./GettingStarted__Types.mjs";
import * as InspectorIssuesView__ImportEventsButton from "./InspectorIssuesView__ImportEventsButton.mjs";

function GettingStarted__Steps$LinkButtonWithIcon(Props) {
  var url = Props.url;
  var icon = Props.icon;
  var label = Props.label;
  return React.createElement("a", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.padding(Css.px(4)),
                        tl: {
                          hd: Css.paddingLeft(Css.px(8)),
                          tl: {
                            hd: Css.paddingRight(Css.px(8)),
                            tl: {
                              hd: Css.background(Styles.Color.white),
                              tl: {
                                hd: Css.borderRadius(Css.px(5)),
                                tl: {
                                  hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                                  tl: {
                                    hd: Css.textDecoration("none"),
                                    tl: {
                                      hd: Css.color(Styles.Color.light10),
                                      tl: {
                                        hd: Css.hover({
                                              hd: Css.borderColor(Styles.Color.light07),
                                              tl: {
                                                hd: Css.color(Styles.Color.light12),
                                                tl: /* [] */0
                                              }
                                            }),
                                        tl: {
                                          hd: Css.transition({
                                                NAME: "ms",
                                                VAL: Styles.Duration.$$short
                                              }, undefined, undefined, "border-color"),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }),
              href: url,
              rel: "noopener",
              target: "_blank"
            }, icon, React.createElement(Spacer.make, {
                  width: 8
                }), React.createElement($$Text.make, {
                  size: "Tiny",
                  weight: "Semi",
                  children: label
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.marginLeft(Css.px(2)),
                        tl: /* [] */0
                      })
                }, React.createElement("svg", {
                      height: "15",
                      width: "11",
                      fill: Styles.Color.toString(Styles.Color.light08),
                      xmlns: "http://www.w3.org/2000/svg"
                    }, React.createElement("path", {
                          d: "M2.95 10.1l4.14-4.14-.01 3.13h1.14V4h-5.1v1.13h3.14L2.13 9.27l.82.83z"
                        }))));
}

var LinkButtonWithIcon = {
  make: GettingStarted__Steps$LinkButtonWithIcon
};

var container = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.width(Css.pct(100.0)),
        tl: {
          hd: Css.height(Css.px(136)),
          tl: /* [] */0
        }
      }
    });

var background = Curry._1(Css.style, {
      hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.light12, 0.9)),
      tl: {
        hd: Css.position("fixed"),
        tl: {
          hd: Css.top(Css.px(0)),
          tl: {
            hd: Css.left(Css.px(0)),
            tl: {
              hd: Css.right(Css.px(0)),
              tl: {
                hd: Css.bottom(Css.px(0)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

function motionContainer(expanded) {
  return Curry._1(Css.style, {
              hd: Css.border(Css.px(1), "solid", Styles.Color.light07),
              tl: {
                hd: Css.borderRadius(Styles.Border.radius),
                tl: {
                  hd: Css.backgroundColor(Styles.Color.light04),
                  tl: {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.justifyContent("center"),
                        tl: {
                          hd: Css.top(Css.px(0)),
                          tl: {
                            hd: Css.left(Css.px(0)),
                            tl: {
                              hd: Css.right(Css.px(0)),
                              tl: {
                                hd: Css.bottom(Css.px(0)),
                                tl: {
                                  hd: Css.width(expanded ? ({
                                            NAME: "subtract",
                                            VAL: [
                                              Css.pct(100.0),
                                              Css.px(320)
                                            ]
                                          }) : Css.pct(100.0)),
                                  tl: {
                                    hd: Css.maxWidth(expanded ? Css.px(1920) : Css.pct(100.0)),
                                    tl: {
                                      hd: Css.height(expanded ? "fitContent" : Css.px(136)),
                                      tl: {
                                        hd: Css.position(expanded ? "fixed" : "absolute"),
                                        tl: {
                                          hd: Css.unsafe("maxWidth", expanded ? "auto" : "unset"),
                                          tl: {
                                            hd: Css.margin(expanded ? "auto" : Css.px(0)),
                                            tl: {
                                              hd: Css.zIndex(expanded ? 2000 : 0),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function playButton(expanded) {
  return Curry._1(Css.style, {
              hd: Css.zIndex(1),
              tl: {
                hd: Css.opacity(expanded ? 0.0 : 1.0),
                tl: {
                  hd: Css.pointerEvents(expanded ? "none" : "auto"),
                  tl: {
                    hd: Css.transition({
                          NAME: "ms",
                          VAL: Styles.Duration.$$default
                        }, undefined, undefined, "all"),
                    tl: {
                      hd: Css.position("absolute"),
                      tl: {
                        hd: Css.left({
                              NAME: "subtract",
                              VAL: [
                                Css.pct(50.0),
                                Css.px(36)
                              ]
                            }),
                        tl: {
                          hd: Css.top({
                                NAME: "subtract",
                                VAL: [
                                  Css.pct(50.0),
                                  Css.px(36)
                                ]
                              }),
                          tl: {
                            hd: Css.width(Css.px(72)),
                            tl: {
                              hd: Css.height(Css.px(72)),
                              tl: {
                                hd: Css.paddingLeft(Css.px(5)),
                                tl: {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.alignItems("center"),
                                    tl: {
                                      hd: Css.justifyContent("center"),
                                      tl: {
                                        hd: Css.borderRadius(Css.px(36)),
                                        tl: {
                                          hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.light11, 0.6)),
                                          tl: {
                                            hd: Css.backdropFilter({
                                                  hd: {
                                                    NAME: "blur",
                                                    VAL: Css.px(1)
                                                  },
                                                  tl: /* [] */0
                                                }),
                                            tl: {
                                              hd: Css.hover({
                                                    hd: Css.cursor("pointer"),
                                                    tl: {
                                                      hd: Css.transform({
                                                            NAME: "scale",
                                                            VAL: [
                                                              1.1,
                                                              1.1
                                                            ]
                                                          }),
                                                      tl: {
                                                        hd: Css.filter({
                                                              hd: {
                                                                NAME: "dropShadow",
                                                                VAL: [
                                                                  Css.px(0),
                                                                  Css.px(0),
                                                                  Css.px(8),
                                                                  Styles.Color.light07
                                                                ]
                                                              },
                                                              tl: /* [] */0
                                                            }),
                                                        tl: /* [] */0
                                                      }
                                                    }
                                                  }),
                                              tl: {
                                                hd: Css.active({
                                                      hd: Css.transform({
                                                            NAME: "scale",
                                                            VAL: [
                                                              0.9,
                                                              0.9
                                                            ]
                                                          }),
                                                      tl: {
                                                        hd: Css.filter({
                                                              hd: {
                                                                NAME: "dropShadow",
                                                                VAL: [
                                                                  Css.px(0),
                                                                  Css.px(0),
                                                                  Css.px(8),
                                                                  Styles.Color.white
                                                                ]
                                                              },
                                                              tl: /* [] */0
                                                            }),
                                                        tl: /* [] */0
                                                      }
                                                    }),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var backgroundImage = Curry._1(Css.style, {
      hd: Css.width(Css.pct(100.0)),
      tl: {
        hd: Css.height(Css.pct(100.0)),
        tl: {
          hd: Css.objectFit("cover"),
          tl: {
            hd: Css.position("absolute"),
            tl: {
              hd: Css.top(Css.px(0)),
              tl: {
                hd: Css.left(Css.px(0)),
                tl: {
                  hd: Css.right(Css.px(0)),
                  tl: {
                    hd: Css.bottom(Css.px(0)),
                    tl: {
                      hd: Css.zIndex(0),
                      tl: {
                        hd: Css.borderRadius(Css.px(4)),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function duration(expanded) {
  return Curry._1(Css.style, {
              hd: Css.transition({
                    NAME: "ms",
                    VAL: Styles.Duration.$$default
                  }, undefined, undefined, "opacity"),
              tl: {
                hd: Css.opacity(expanded ? 0.0 : 1.0),
                tl: {
                  hd: Css.pointerEvents(expanded ? "none" : "auto"),
                  tl: {
                    hd: Css.position("absolute"),
                    tl: {
                      hd: Css.bottom(Css.px(6)),
                      tl: {
                        hd: Css.right(Css.px(6)),
                        tl: {
                          hd: Css.padding2(Css.px(4), Css.px(8)),
                          tl: {
                            hd: Css.borderRadius(Css.px(12)),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.light11, 0.6)),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var video = Curry._1(Css.style, {
      hd: Css.width(Css.pct(100.0)),
      tl: {
        hd: Css.height("auto"),
        tl: {
          hd: Css.zIndex(1),
          tl: {
            hd: Css.borderRadius(Css.px(4)),
            tl: /* [] */0
          }
        }
      }
    });

var Style = {
  container: container,
  background: background,
  motionContainer: motionContainer,
  playButton: playButton,
  backgroundImage: backgroundImage,
  duration: duration,
  video: video
};

function GettingStarted__Steps$Video(Props) {
  var mins = Props.mins;
  var src = Props.src;
  var onClick = Props.onClick;
  var match = Hooks.useDisclosure(false);
  var actions = match[1];
  var expanded = match[0];
  return React.createElement("div", {
              className: container
            }, React.createElement(FramerMotion.motion.div, {
                  animate: {
                    opacity: expanded ? 1.0 : 0.0,
                    zIndex: "2000",
                    pointerEvents: expanded ? "auto" : "none"
                  },
                  transition: {
                    type: "spring",
                    damping: 20,
                    stiffness: 200
                  },
                  className: background,
                  onClick: (function (param) {
                      return Curry._1(actions.toggle, undefined);
                    })
                }), React.createElement(FramerMotion.motion.div, {
                  animate: "expansion",
                  transition: {
                    type: "spring",
                    damping: 30,
                    stiffness: 300
                  },
                  className: motionContainer(expanded),
                  onClick: (function (param) {
                      if (expanded) {
                        return ;
                      } else {
                        Curry._1(actions.open_, undefined);
                        return Curry._1(onClick, undefined);
                      }
                    }),
                  children: null,
                  layout: true
                }, expanded ? React.createElement("video", {
                        className: video,
                        autoPlay: true,
                        controls: true,
                        src: src
                      }) : null, React.createElement("img", {
                      className: backgroundImage,
                      alt: "Screenshot from avo app",
                      src: Asset.getPath("screenshot.png")
                    }), React.createElement("div", {
                      className: playButton(expanded)
                    }, React.createElement("svg", {
                          height: "24",
                          width: "21",
                          fill: "none"
                        }, React.createElement("path", {
                              d: "M20 10.3a2 2 0 0 1 0 3.4L3.5 23.3a2 2 0 0 1-3-1.8v-19a2 2 0 0 1 3-1.8L20 10.3Z",
                              fill: "#fff"
                            }))), React.createElement("div", {
                      className: duration(expanded)
                    }, React.createElement($$Text.make, {
                          size: "Tiny",
                          color: Styles.Color.white,
                          children: AvoUtils.plural(undefined, undefined, mins, "min")
                        }))));
}

var Video = {
  Style: Style,
  make: GettingStarted__Steps$Video
};

var linkStyles = Curry._1(Css.style, {
      hd: Css.color(Styles.Color.deepBlue),
      tl: {
        hd: Css.fontSize(Css.px(12)),
        tl: {
          hd: Css.transition({
                NAME: "ms",
                VAL: Styles.Duration.$$default
              }, undefined, undefined, "all"),
          tl: {
            hd: Css.hover({
                  hd: Css.fontWeight({
                        NAME: "num",
                        VAL: 600
                      }),
                  tl: /* [] */0
                }),
            tl: /* [] */0
          }
        }
      }
    });

function GettingStarted__Steps$Callout(Props) {
  var iconOpt = Props.icon;
  var colorOpt = Props.color;
  var children = Props.children;
  var ctaOpt = Props.cta;
  var icon = iconOpt !== undefined ? Caml_option.valFromOption(iconOpt) : null;
  var color = colorOpt !== undefined ? colorOpt : Styles.Color.lightBlue;
  var cta = ctaOpt !== undefined ? Caml_option.valFromOption(ctaOpt) : null;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.gap(Css.px(8)),
                      tl: {
                        hd: Css.flexDirection("row"),
                        tl: {
                          hd: Css.backgroundColor(color),
                          tl: {
                            hd: Css.borderRadius(Css.px(8)),
                            tl: {
                              hd: Css.padding(Css.px(16)),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  })
            }, icon, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.flexDirection("column"),
                          tl: {
                            hd: Css.gap(Css.px(4)),
                            tl: /* [] */0
                          }
                        }
                      })
                }, children), cta);
}

var Callout = {
  linkStyles: linkStyles,
  make: GettingStarted__Steps$Callout
};

function root(isPresent) {
  return Curry._1(Css.style, {
              hd: Css.position(isPresent ? "static" : "absolute"),
              tl: {
                hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                tl: {
                  hd: Css.borderRadius(Css.px(8)),
                  tl: {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.flexDirection("column"),
                      tl: {
                        hd: Css.backgroundColor(Styles.Color.white),
                        tl: {
                          hd: Css.overflow("hidden"),
                          tl: {
                            hd: Css.marginBottom(Css.px(16)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var complete = Curry._1(Css.style, {
      hd: Css.width(Css.px(24)),
      tl: {
        hd: Css.height(Css.px(24)),
        tl: {
          hd: Css.borderRadius(Css.px(12)),
          tl: {
            hd: Css.backgroundColor(Styles.Color.green),
            tl: {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.justifyContent("center"),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var incomplete = Curry._1(Css.style, {
      hd: Css.width(Css.px(24)),
      tl: {
        hd: Css.height(Css.px(24)),
        tl: {
          hd: Css.borderRadius(Css.px(12)),
          tl: {
            hd: Css.backgroundColor(Styles.Color.white),
            tl: {
              hd: Css.border(Css.px(1), "solid", Styles.Color.light07),
              tl: /* [] */0
            }
          }
        }
      }
    });

var headerButton = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.fontSize("unset"),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.flexGrow(1.0),
                  tl: {
                    hd: Css.gap(Css.px(12)),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.justifyContent("spaceBetween"),
                        tl: {
                          hd: Css.padding2(Css.px(20), Css.px(16)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.white),
                            tl: {
                              hd: Css.transition({
                                    NAME: "ms",
                                    VAL: Styles.Duration.$$default
                                  }, undefined, undefined, "all"),
                              tl: {
                                hd: Css.hover({
                                      hd: Css.backgroundColor(Styles.Color.light02),
                                      tl: /* [] */0
                                    }),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var content = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: {
            hd: Css.paddingTop(Css.px(16)),
            tl: {
              hd: Css.paddingRight(Css.px(24)),
              tl: {
                hd: Css.paddingBottom(Css.px(32)),
                tl: {
                  hd: Css.paddingLeft(Css.px(52)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var aside = Curry._1(Css.style, {
      hd: Css.width(Css.px(200)),
      tl: {
        hd: Css.marginLeft(Css.px(32)),
        tl: {
          hd: Css.flexShrink(0.0),
          tl: /* [] */0
        }
      }
    });

var docsLink = Curry._1(Css.style, {
      hd: Css.display("inlineFlex"),
      tl: {
        hd: Css.gap(Css.px(6)),
        tl: {
          hd: Css.color(Styles.Color.primaryPurple),
          tl: {
            hd: Css.textDecoration("none"),
            tl: {
              hd: Icon.color(Styles.Color.primaryPurple),
              tl: {
                hd: Css.hover({
                      hd: Css.textDecoration("underline"),
                      tl: /* [] */0
                    }),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var body = Curry._1(Css.style, {
      hd: Css.maxWidth(Css.px(480)),
      tl: /* [] */0
    });

var Style$1 = {
  root: root,
  complete: complete,
  incomplete: incomplete,
  headerButton: headerButton,
  content: content,
  aside: aside,
  docsLink: docsLink,
  body: body
};

function trackInteracted(schemaBundle, stepId, completed, type_, name) {
  return AnalyticsRe.onboardingChecklistStepDetailsInteraction(schemaBundle, GettingStarted__Types.StepId.toAnalytics(stepId), completed ? "Finished" : "ToDo", type_, name, "GettingStarted", schemaBundle.branchId, schemaBundle.schemaId);
}

function GettingStarted__Steps$Step(Props) {
  var completed = Props.completed;
  var children = Props.children;
  var footer = Props.footer;
  var docs = Props.docs;
  var stepId = Props.stepId;
  var video = Props.video;
  var match = React.useState(function () {
        return false;
      });
  var setIsOpen = match[1];
  var isOpen = match[0];
  var isPresent = FramerMotion.useIsPresent();
  var schemaBundle = SchemaBundleContext.use(undefined);
  var match$1 = React.useState(function () {
        return false;
      });
  var setMounted = match$1[1];
  var mounted = match$1[0];
  React.useEffect((function () {
          if (isOpen) {
            AnalyticsRe.onboardingChecklistStepDetailsOpened(schemaBundle, GettingStarted__Types.StepId.toAnalytics(stepId), completed ? "Finished" : "ToDo", "GettingStarted", schemaBundle.branchId, schemaBundle.schemaId);
          } else if (mounted) {
            trackInteracted(schemaBundle, stepId, completed, "ClickBack", "click");
          } else {
            Curry._1(setMounted, (function (param) {
                    return true;
                  }));
          }
          
        }), [isOpen]);
  return React.createElement(FramerMotion.motion.section, {
              animate: {
                opacity: 1
              },
              transition: {
                type: "spring",
                stiffness: 200,
                damping: 20
              },
              initial: {
                opacity: 0
              },
              exit: {
                opacity: 0
              },
              className: root(isPresent),
              children: null,
              layout: true
            }, React.createElement("button", {
                  className: headerButton,
                  onClick: (function (param) {
                      return Curry._1(setIsOpen, (function (isOpen) {
                                    return !isOpen;
                                  }));
                    })
                }, completed ? React.createElement("div", {
                        className: complete
                      }, React.createElement(IconCheckmark.make, {
                            size: 14,
                            color: Styles.Color.white
                          })) : React.createElement("div", {
                        className: incomplete
                      }), React.createElement($$Text.make, {
                      size: "Large",
                      weight: "Semi",
                      color: Styles.Color.light11,
                      children: GettingStarted__Types.StepId.toDisplayString(stepId)
                    }), React.createElement(Spacer.make, {
                      grow: 1.0
                    }), React.createElement(Accordion.OpeningIcon.make, {
                      size: "large",
                      isOpen: isOpen
                    })), React.createElement(Collapsible.make, {
                  collapsed: !isOpen,
                  loadingStyle: "eager",
                  withFade: false,
                  children: null
                }, React.createElement("div", {
                      className: content
                    }, React.createElement("div", {
                          className: body
                        }, children), React.createElement("div", {
                          className: aside
                        }, Belt_Option.mapWithDefault(video, null, (function (video) {
                                return React.createElement(React.Fragment, undefined, React.createElement(GettingStarted__Steps$Video, {
                                                mins: video.mins,
                                                src: video.src,
                                                onClick: (function (param) {
                                                    return trackInteracted(schemaBundle, stepId, completed, "PlayVideo", "click");
                                                  }),
                                                key: video.src
                                              }), React.createElement(Spacer.make, {
                                                height: 16
                                              }));
                              })), React.createElement($$Text.make, {
                              size: "Small",
                              weight: "Semi",
                              color: Styles.Color.light11,
                              children: "Read the docs"
                            }), Belt_Array.map(docs, (function (doc) {
                                return React.createElement(React.Fragment, {
                                            children: null,
                                            key: doc.href
                                          }, React.createElement(Spacer.make, {
                                                height: 6
                                              }), React.createElement("a", {
                                                className: docsLink,
                                                href: doc.href,
                                                rel: "noopener",
                                                target: "_blank",
                                                onClick: (function (param) {
                                                    return trackInteracted(schemaBundle, stepId, completed, "ClickLinkToDocs", doc.label);
                                                  })
                                              }, React.createElement(Icon.make, {
                                                    type_: "outsideLink"
                                                  }), React.createElement($$Text.make, {
                                                    element: "Span",
                                                    size: "Small",
                                                    weight: "Semi",
                                                    children: doc.label
                                                  })));
                              })))), Belt_Option.mapWithDefault(footer, null, (function (footer) {
                        return React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.display("flex"),
                                          tl: {
                                            hd: Css.flexDirection("column"),
                                            tl: {
                                              hd: Css.paddingRight(Css.px(24)),
                                              tl: {
                                                hd: Css.paddingBottom(Css.px(32)),
                                                tl: {
                                                  hd: Css.paddingLeft(Css.px(52)),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }
                                        })
                                  }, footer);
                      }))));
}

var Step = {
  Style: Style$1,
  trackInteracted: trackInteracted,
  make: GettingStarted__Steps$Step
};

function GettingStarted__Steps$SetUpInspector(Props) {
  var completed = Props.completed;
  var inspectorStats = Props.inspectorStats;
  var hasInspectorOnProd = Props.hasInspectorOnProd;
  var globalSend = GlobalSendContext.use(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var model = ModelStore.useModel(undefined);
  var hasSourcesWithoutInspector = Belt_List.some(model.sources, (function (source) {
          var match = InspectorStats.getSourceStatus(source, inspectorStats);
          if (typeof match === "number") {
            return match !== 0;
          } else {
            return false;
          }
        }));
  return React.createElement(GettingStarted__Steps$Step, {
              completed: completed,
              children: null,
              footer: React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                        size: "Medium",
                        children: "Learn more about setting up Inspector via your Data platform or SDK:"
                      }), React.createElement(Spacer.make, {
                        height: 12
                      }), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.flexWrap("wrap"),
                                tl: {
                                  hd: Css.gap(Css.px(8)),
                                  tl: /* [] */0
                                }
                              }
                            })
                      }, React.createElement(GettingStarted__Steps$LinkButtonWithIcon, {
                            url: "https://www.avo.app/docs/workspace/connect-inspector-to-segment",
                            icon: React.createElement(Icons.Destinations.Segment.make, {
                                  size: 16
                                }),
                            label: "Segment"
                          }), React.createElement(GettingStarted__Steps$LinkButtonWithIcon, {
                            url: "https://www.avo.app/docs/workspace/connect-inspector-to-rudderstack",
                            icon: React.createElement(Icons.Destinations.Rudderstack.make, {
                                  size: 16
                                }),
                            label: "Rudderstack"
                          }), React.createElement(GettingStarted__Steps$LinkButtonWithIcon, {
                            url: "https://www.avo.app/docs/workspace/connect-inspector-to-posthog",
                            icon: React.createElement(Icons.Destinations.Posthog.make, {
                                  size: 16
                                }),
                            label: "PostHog"
                          }), React.createElement(GettingStarted__Steps$LinkButtonWithIcon, {
                            url: "https://www.avo.app/docs/inspector/connect-inspector-to-gtm",
                            icon: React.createElement(Icons.Destinations.GoogleTagManager.make, {
                                  size: 16
                                }),
                            label: "Google Tag Manager"
                          }), React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.display("none"),
                                  tl: {
                                    hd: Css.selector(" + a > svg:first-child path", {
                                          hd: Css_Legacy_Core.SVG.fill(Styles.Color.avoPink),
                                          tl: /* [] */0
                                        }),
                                    tl: /* [] */0
                                  }
                                })
                          }), React.createElement(GettingStarted__Steps$LinkButtonWithIcon, {
                            url: "https://www.avo.app/docs/implementation/avo-inspector-overview",
                            icon: React.createElement(Icon.make, {
                                  type_: "folder",
                                  size: "small"
                                }),
                            label: "SDK"
                          }))),
              docs: [{
                  href: "https://www.avo.app/docs/inspector/start-using-inspector",
                  label: "Get started with Inspector"
                }],
              stepId: "setUpInspector",
              video: {
                src: "https://cdn.avo.app/assets/MeetInspector.mp4",
                mins: 2
              }
            }, React.createElement($$Text.make, {
                  size: "Medium",
                  color: Styles.Color.light11,
                  children: "Avo Inspector is a tracking observability tool that extracts your data structures and shows all your data inconsistencies in one place."
                }), React.createElement(Spacer.make, {
                  height: 24
                }), React.createElement($$Text.make, {
                  size: "Medium",
                  color: Styles.Color.light11,
                  children: "Set up Inspector to find, fix, and prevent data quality issues and build a rigorous tracking plan from your live data."
                }), React.createElement(Spacer.make, {
                  height: 24
                }), React.createElement(GettingStarted__Steps$Callout, {
                  icon: React.createElement(Icon.make, {
                        type_: "information",
                        size: "medium",
                        color: Styles.Color.light11
                      }),
                  children: null
                }, React.createElement($$Text.make, {
                      size: "Small",
                      weight: "Semi",
                      color: Styles.Color.deepBlue,
                      children: "Avo receives none of your customers’ data"
                    }), React.createElement($$Text.make, {
                      size: "Small",
                      color: Styles.Color.deepBlue,
                      children: "Inspector extracts the schemas of the events you trigger on the client. No property values or PII data is received or processed, only event names, property names and property types."
                    }), React.createElement("a", {
                      className: linkStyles,
                      href: "https://www.avo.app/docs/faqs/faq-inspector#do-i-need-to-add-avo-to-my-privacy-policy",
                      rel: "noopener",
                      target: "_blank"
                    }, "Learn more")), React.createElement(Spacer.make, {
                  height: 24
                }), completed ? (
                hasInspectorOnProd ? React.createElement(React.Fragment, undefined, React.createElement(Title.make, {
                            children: "Inspector successfully set up 🎉",
                            size: "Small"
                          }), React.createElement(Spacer.make, {
                            height: 24
                          }), React.createElement(Button.make, {
                            label: "Take me to Inspector",
                            onClick: (function (param) {
                                Router.Schema.pushSchemaRoute(undefined, undefined, {
                                      NAME: "inspector",
                                      VAL: "issues"
                                    });
                                return trackInteracted(schemaBundle, "setUpInspector", completed, "ClickCTA", "Take me to Inspector");
                              }),
                            size: "large",
                            style: "outline"
                          })) : React.createElement(React.Fragment, undefined, React.createElement(Title.make, {
                            children: "Inspector successfully set up 🎉",
                            size: "Small"
                          }), React.createElement(Spacer.make, {
                            height: 8
                          }), React.createElement($$Text.make, {
                            size: "Medium",
                            color: Styles.Color.light10,
                            children: "You’ve set up Inspector in development and can get started building your tracking plan from your event data."
                          }), React.createElement(Spacer.make, {
                            height: 16
                          }), React.createElement($$Text.make, {
                            size: "Medium",
                            color: Styles.Color.light10,
                            children: "Set up Inspector in production to unlock inspector issues and get started fixing your data!"
                          }), React.createElement(Spacer.make, {
                            height: 24
                          }), React.createElement(Button.make, {
                            label: "Set up Inspector in Production",
                            onClick: (function (param) {
                                Curry._1(globalSend, {
                                      TAG: /* OpenModal */4,
                                      _0: {
                                        NAME: "SourceSetup",
                                        VAL: [
                                          "inspectorSetup",
                                          "gettingStarted"
                                        ]
                                      }
                                    });
                                return trackInteracted(schemaBundle, "setUpInspector", completed, "ClickCTA", "Set up Inspector in Production");
                              }),
                            size: "large"
                          }))
              ) : React.createElement(Button.make, {
                    label: "Set up Inspector",
                    onClick: (function (param) {
                        trackInteracted(schemaBundle, "setUpInspector", completed, "ClickCTA", "Set up Inspector");
                        if (hasSourcesWithoutInspector) {
                          return Curry._1(globalSend, {
                                      TAG: /* OpenModal */4,
                                      _0: {
                                        NAME: "SourceSetup",
                                        VAL: [
                                          "inspectorSetup",
                                          "gettingStarted"
                                        ]
                                      }
                                    });
                        } else {
                          return Curry._1(globalSend, {
                                      TAG: /* OpenModal */4,
                                      _0: {
                                        NAME: "CreateSource",
                                        VAL: "inspectorSetup"
                                      }
                                    });
                        }
                      }),
                    size: "large"
                  }));
}

var SetUpInspector = {
  make: GettingStarted__Steps$SetUpInspector
};

function GettingStarted__Steps$ImportEventsFromInspector(Props) {
  var completed = Props.completed;
  var blocked = Props.blocked;
  var inspectorStats = Props.inspectorStats;
  var globalSend = GlobalSendContext.use(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var model = ModelStore.useModel(undefined);
  var hasSourcesWithoutInspector = Belt_List.some(model.sources, (function (source) {
          var match = InspectorStats.getSourceStatus(source, inspectorStats);
          if (typeof match === "number") {
            return match !== 0;
          } else {
            return false;
          }
        }));
  var match = InspectorIssuesStore.useFilteredIssues(/* Unresolved */0, undefined, undefined);
  var tmp;
  var exit = 0;
  if (blocked) {
    if (completed) {
      exit = 1;
    } else {
      tmp = React.createElement(React.Fragment, undefined, React.createElement(GettingStarted__Steps$Callout, {
                color: Styles.Color.paleOrange,
                children: React.createElement($$Text.make, {
                      size: "Small",
                      color: Styles.Color.deepOrange,
                      children: "Set up Inspector to unlock this step."
                    })
              }), React.createElement(Spacer.make, {
                height: 24
              }), React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.flexDirection("row"),
                        tl: {
                          hd: Css.gap(Css.px(8)),
                          tl: /* [] */0
                        }
                      }
                    })
              }, React.createElement(Button.make, {
                    disabled: true,
                    label: "Review and import events",
                    size: "large"
                  }), React.createElement(Button.make, {
                    label: "Set up Inspector",
                    onClick: (function (param) {
                        if (hasSourcesWithoutInspector) {
                          Curry._1(globalSend, {
                                TAG: /* OpenModal */4,
                                _0: {
                                  NAME: "SourceSetup",
                                  VAL: [
                                    "inspectorSetup",
                                    "gettingStarted"
                                  ]
                                }
                              });
                        } else {
                          Curry._1(globalSend, {
                                TAG: /* OpenModal */4,
                                _0: {
                                  NAME: "CreateSource",
                                  VAL: "inspectorSetup"
                                }
                              });
                        }
                        return trackInteracted(schemaBundle, "addEventsFromInspector", completed, "ClickCTA", "Set up Inspector");
                      }),
                    size: "large"
                  })));
    }
  } else if (completed) {
    exit = 1;
  } else {
    tmp = React.createElement(React.Fragment, undefined, React.createElement(GettingStarted__Steps$Callout, {
              icon: React.createElement($$Text.make, {
                    size: "Large",
                    children: "💡"
                  }),
              children: React.createElement($$Text.make, {
                    size: "Small",
                    color: Styles.Color.deepBlue,
                    children: "We recommend starting small and just adding a few priority events. You can always import more later."
                  })
            }), React.createElement(Spacer.make, {
              height: 24
            }), React.createElement(InspectorIssuesView__ImportEventsButton.make, {
              filteredIssues: match.filteredIssues,
              size: "large",
              style: "primary",
              postClick: (function (param) {
                  return trackInteracted(schemaBundle, "addEventsFromInspector", completed, "ClickCTA", "Import events");
                })
            }));
  }
  if (exit === 1) {
    tmp = React.createElement(React.Fragment, undefined, React.createElement(Title.make, {
              children: "Import complete 🎉",
              size: "Small"
            }), React.createElement(Spacer.make, {
              height: 8
            }), React.createElement($$Text.make, {
              size: "Medium",
              color: Styles.Color.light10,
              children: "New events were successfully added to your Tracking Plan!"
            }));
  }
  return React.createElement(GettingStarted__Steps$Step, {
              completed: completed,
              children: null,
              docs: [{
                  href: "https://www.avo.app/docs/inspector/add-events-from-inspector",
                  label: "Importing events from Inspector"
                }],
              stepId: "addEventsFromInspector"
            }, React.createElement($$Text.make, {
                  size: "Medium",
                  color: Styles.Color.light11,
                  children: "Use Inspector to build a rigorous tracking plan from your live data. No need to start your data design from scratch."
                }), React.createElement(Spacer.make, {
                  height: 24
                }), tmp);
}

var ImportEventsFromInspector = {
  make: GettingStarted__Steps$ImportEventsFromInspector
};

var actionsStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.width(Css.pct(100.0)),
        tl: {
          hd: Css.gap(Css.px(16)),
          tl: {
            hd: Css.alignItems("stretch"),
            tl: /* [] */0
          }
        }
      }
    });

var actionStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.flexGrow(1.0),
                tl: {
                  hd: Css.width(Css.pct(100.0)),
                  tl: {
                    hd: Css.flexDirection("column"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.minHeight(Css.px(120)),
                        tl: {
                          hd: Css.padding4(Css.px(24), Css.px(16), Css.px(24), Css.px(16)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.light01),
                            tl: {
                              hd: Css.borderRadius(Css.px(10)),
                              tl: {
                                hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                                tl: {
                                  hd: Css.transition({
                                        NAME: "ms",
                                        VAL: Styles.Duration.$$default
                                      }, undefined, undefined, "box-shadow"),
                                  tl: {
                                    hd: Css.hover({
                                          hd: Css.boxShadow(Styles.Shadow.standard),
                                          tl: /* [] */0
                                        }),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var iconStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: {
            hd: Css.width(Css.px(64)),
            tl: {
              hd: Css.height(Css.px(64)),
              tl: {
                hd: Css.borderRadius(Css.px(32)),
                tl: {
                  hd: Css.marginBottom(Css.px(8)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

function GettingStarted__Steps$AddYourFirstEvent(Props) {
  var completed = Props.completed;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var model = ModelStore.useModel(undefined);
  return React.createElement(GettingStarted__Steps$Step, {
              completed: completed,
              children: null,
              docs: [
                {
                  href: "https://www.avo.app/docs/data-design/avo-tracking-plan/events",
                  label: "Defining events in Avo"
                },
                {
                  href: "https://www.avo.app/docs/publishing/importing",
                  label: "Importing your tracking plan"
                }
              ],
              stepId: "addYourFirstEventToTheTrackingPlan"
            }, React.createElement($$Text.make, {
                  size: "Medium",
                  color: Styles.Color.light11,
                  children: "You can easily get started by importing your existing tracking plan (we support most common formats) or start a new one and create your first event."
                }), React.createElement(Spacer.make, {
                  height: 24
                }), React.createElement(GettingStarted__Steps$Callout, {
                  icon: "💡",
                  children: null
                }, React.createElement($$Text.make, {
                      size: "Small",
                      color: Styles.Color.deepBlue,
                      children: "Avo will audit your event and property definitions and flag issues such as naming inconsistencies or inconsistent property types."
                    }), React.createElement("a", {
                      className: linkStyles,
                      href: "https://www.avo.app/docs/audit/overview",
                      rel: "noopener",
                      target: "_blank"
                    }, "Learn more")), React.createElement(Spacer.make, {
                  height: 24
                }), completed ? React.createElement(React.Fragment, undefined, React.createElement(Title.make, {
                        children: "Event added 🎉",
                        size: "Small"
                      }), React.createElement(Spacer.make, {
                        height: 8
                      }), React.createElement($$Text.make, {
                        size: "Medium",
                        color: Styles.Color.light10,
                        children: "You have successfully added your first event!"
                      })) : React.createElement("div", {
                    className: actionsStyles,
                    id: "empty-events-actions"
                  }, React.createElement("button", {
                        className: actionStyles,
                        onClick: (function (param) {
                            Curry._1(globalSend, {
                                  TAG: /* OpenModal */4,
                                  _0: {
                                    NAME: "NewEvent",
                                    VAL: [
                                      "",
                                      (function (eventId, _name) {
                                          return Router.Schema.pushDrawerItem(undefined, undefined, {
                                                      NAME: "event",
                                                      VAL: [
                                                        eventId,
                                                        undefined,
                                                        undefined,
                                                        false
                                                      ]
                                                    });
                                        }),
                                      undefined,
                                      undefined,
                                      "EventsView"
                                    ]
                                  }
                                });
                            return trackInteracted(schemaBundle, "addYourFirstEventToTheTrackingPlan", completed, "ClickCTA", "Create an event");
                          })
                      }, React.createElement("div", {
                            className: iconStyles
                          }, CreateButtonIcon.icon), React.createElement($$Text.make, {
                            element: "Span",
                            size: "Medium",
                            weight: "Semi",
                            color: Styles.Color.light11,
                            children: "Create an event"
                          })), React.createElement("button", {
                        className: actionStyles,
                        onClick: (function (param) {
                            Router.Schema.pushImportRoute(undefined, "TrackingPlanEventsEmptyState", {
                                  NAME: "import",
                                  VAL: "index"
                                });
                            return trackInteracted(schemaBundle, "addYourFirstEventToTheTrackingPlan", completed, "ClickCTA", "Import tracking plan");
                          })
                      }, React.createElement("div", {
                            className: iconStyles
                          }, React.createElement(IconImport.make, {
                                color: Styles.Color.light08,
                                size: 60
                              })), React.createElement($$Text.make, {
                            element: "Span",
                            size: "Medium",
                            weight: "Semi",
                            color: Styles.Color.light11,
                            children: "Import tracking plan"
                          })), React.createElement(DidMount.make, {
                        didMount: (function (param) {
                            return AnalyticsRe.importCallToActionSeen(schemaBundle, Belt_List.length(model.events), Belt_List.length(model.properties), schemaBundle.branchId, schemaBundle.schemaId);
                          })
                      })));
}

var AddYourFirstEvent = {
  actionsStyles: actionsStyles,
  actionStyles: actionStyles,
  iconStyles: iconStyles,
  make: GettingStarted__Steps$AddYourFirstEvent
};

function GettingStarted__Steps$SetUpSlackAlerts(Props) {
  var completed = Props.completed;
  var blocked = Props.blocked;
  var globalSend = GlobalSendContext.use(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var tmp;
  var exit = 0;
  if (blocked) {
    if (completed) {
      exit = 1;
    } else {
      tmp = React.createElement(React.Fragment, undefined, React.createElement(GettingStarted__Steps$Callout, {
                color: Styles.Color.orange,
                children: React.createElement($$Text.make, {
                      size: "Small",
                      color: Styles.Color.deepOrange,
                      children: "Set up Inspector in production to unlock setting up alerts."
                    }),
                cta: React.createElement(Button.make, {
                      label: "Set up Inspector in Production",
                      onClick: (function (param) {
                          return Curry._1(globalSend, {
                                      TAG: /* OpenModal */4,
                                      _0: {
                                        NAME: "SourceSetup",
                                        VAL: [
                                          "inspectorSetup",
                                          "gettingStarted"
                                        ]
                                      }
                                    });
                        }),
                      size: "large"
                    })
              }), React.createElement(Spacer.make, {
                height: 24
              }), React.createElement(Button.make, {
                disabled: true,
                label: "Go to Inspector issues",
                size: "large"
              }));
    }
  } else if (completed) {
    exit = 1;
  } else {
    tmp = React.createElement(React.Fragment, undefined, React.createElement(GettingStarted__Steps$Callout, {
              icon: "💡",
              children: React.createElement($$Text.make, {
                    size: "Small",
                    color: Styles.Color.deepBlue,
                    children: "You can set up multiple alerts for different events and issue criteria. These can be sent to separate Slack channels to make sure each team gets relevant alerts."
                  })
            }), React.createElement(DoptMigration.Hidden.make, {
              children: null
            }, React.createElement(Spacer.make, {
                  height: 24
                }), React.createElement(Button.make, {
                  label: "Show me how",
                  onClick: (function (param) {
                      Router.Schema.pushSchemaRoute(undefined, "start_tour=set-up-alerts", {
                            NAME: "inspector",
                            VAL: "issues"
                          });
                      return trackInteracted(schemaBundle, "setUpAlertsForYourMostImportantEvents", completed, "ClickCTA", "Show me how");
                    }),
                  size: "large"
                })));
  }
  if (exit === 1) {
    tmp = React.createElement(React.Fragment, undefined, React.createElement(Title.make, {
              children: "Set up complete 🎉",
              size: "Small"
            }), React.createElement(Spacer.make, {
              height: 8
            }), React.createElement($$Text.make, {
              size: "Medium",
              color: Styles.Color.light10,
              children: "You have successfully set up Slack alerts!"
            }));
  }
  return React.createElement(GettingStarted__Steps$Step, {
              completed: completed,
              children: null,
              docs: [{
                  href: "https://www.avo.app/docs/inspector/inspector-slack-alerts",
                  label: "Inspector alerts for Slack"
                }],
              stepId: "setUpAlertsForYourMostImportantEvents",
              video: {
                src: "https://cdn.avo.app/assets/Alerts.mp4",
                mins: 1
              }
            }, React.createElement($$Text.make, {
                  size: "Medium",
                  color: Styles.Color.light11,
                  children: "Stop manually hunting down data discrepancies (or worse, letting them catch you by surprise)."
                }), React.createElement(Spacer.make, {
                  height: 8
                }), React.createElement($$Text.make, {
                  size: "Medium",
                  color: Styles.Color.light11,
                  children: "Navigate to Inspector Issues and filter your most critical data quality issues. Then click “Set up alerts” to connect your Slack account and choose a Slack channel where you’d like to receive alerts."
                }), React.createElement(Spacer.make, {
                  height: 24
                }), tmp);
}

var SetUpSlackAlerts = {
  make: GettingStarted__Steps$SetUpSlackAlerts
};

function GettingStarted__Steps$TriageIssue(Props) {
  var completed = Props.completed;
  var blocked = Props.blocked;
  var schemaBundle = SchemaBundleContext.use(undefined);
  return React.createElement(GettingStarted__Steps$Step, {
              completed: completed,
              children: null,
              docs: [{
                  href: "https://www.avo.app/docs/inspector/inspector-fix-issues",
                  label: "Inspector issues status"
                }],
              stepId: "triageAnIssue",
              video: {
                src: "https://cdn.avo.app/assets/ResolvingIssues.mp4",
                mins: 2
              }
            }, React.createElement($$Text.make, {
                  size: "Medium",
                  color: Styles.Color.light11,
                  children: "Start your journey to better data, one resolved issue at a time. Use Inspector to quickly find, triage, and resolve data quality issues."
                }), React.createElement(Spacer.make, {
                  height: 24
                }), React.createElement(GettingStarted__Steps$Callout, {
                  children: null
                }, React.createElement($$Text.make, {
                      size: "Small",
                      color: Styles.Color.deepBlue,
                      children: "Step 1: Head into Inspector Issues and select an issue."
                    }), React.createElement($$Text.make, {
                      size: "Small",
                      color: Styles.Color.deepBlue,
                      children: "Step 2: Depending on the urgency of the issue, either set the issue’s status to “Ignored” or attempt to resolve either by adding to tracking, or by tagging a teammate in the comments to update your tracking."
                    }), React.createElement($$Text.make, {
                      size: "Small",
                      color: Styles.Color.deepBlue,
                      children: "Step 3: If the issue is immediately resolved, change the issue’s status to “Resolved in 1 hour”. Otherwise specify if the issue will resolve in 24 hours, or in a certain release."
                    }), React.createElement($$Text.make, {
                      size: "Small",
                      color: Styles.Color.deepBlue,
                      children: "And that’s it! Resolved issues will continue to be monitored by Inspector in case they reappear as a regression."
                    })), React.createElement(Spacer.make, {
                  height: 24
                }), completed ? React.createElement(React.Fragment, undefined, React.createElement(Title.make, {
                        children: "Issue resolved 🎉",
                        size: "Small"
                      }), React.createElement(Spacer.make, {
                        height: 8
                      }), React.createElement($$Text.make, {
                        size: "Medium",
                        color: Styles.Color.light10,
                        children: "You have successfully resolved your first issue!"
                      })) : (
                blocked ? React.createElement(React.Fragment, undefined, React.createElement(GettingStarted__Steps$Callout, {
                            color: Styles.Color.paleOrange,
                            children: React.createElement($$Text.make, {
                                  size: "Small",
                                  color: Styles.Color.deepOrange,
                                  children: "Set up Inspector to unlock this step."
                                })
                          }), React.createElement(Spacer.make, {
                            height: 24
                          }), React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.flexDirection("row"),
                                    tl: {
                                      hd: Css.gap(Css.px(8)),
                                      tl: /* [] */0
                                    }
                                  }
                                })
                          }, React.createElement(Button.make, {
                                disabled: true,
                                label: "Review and import events",
                                size: "large"
                              }), React.createElement(Button.make, {
                                label: "Triage issue",
                                onClick: (function (param) {
                                    Router.push(true, Router.Link.getSchemaRouteLink(undefined, {
                                              NAME: "inspector",
                                              VAL: "issues"
                                            }));
                                    return trackInteracted(schemaBundle, "triageAnIssue", completed, "ClickCTA", "Triage issue");
                                  }),
                                size: "large"
                              }))) : React.createElement(Button.make, {
                        label: "Triage issue",
                        onClick: (function (param) {
                            Router.push(true, Router.Link.getSchemaRouteLink(undefined, {
                                      NAME: "inspector",
                                      VAL: "issues"
                                    }));
                            return trackInteracted(schemaBundle, "triageAnIssue", completed, "ClickCTA", "Triage issue");
                          }),
                        size: "large"
                      })
              ));
}

var TriageIssue = {
  make: GettingStarted__Steps$TriageIssue
};

function GettingStarted__Steps$BranchedWorkflows(Props) {
  var completed = Props.completed;
  var branch = RouterStore.Schema.useBranch(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var sources = ModelStore.useSources(undefined);
  var events = ModelStore.useEvents(undefined);
  var openBranches = ModelStore.useOpenBranches(undefined);
  var user = ViewerContext.use(undefined);
  var addToast = Toast.useAddToast(undefined);
  var modelMigrations = ModelStore.useMigrations(undefined);
  var hasCreatedDemoBranch = Belt_Option.isSome(FirebaseFetcherHooks.useBranchOpenAction(schemaBundle.schemaId, "demo-" + user.id, "Demo", undefined, undefined));
  var currentBranchIsDemoBranch = typeof branch === "object" && branch.NAME === "branch" ? branch.VAL.startsWith("demo-") : false;
  var match = Router.QueryParams.useStringKey("start_tour");
  var setStartTour = match.replace;
  var match$1 = React.useState(function () {
        return false;
      });
  var setCreatingDemoBranch = match$1[1];
  var creatingDemoBranch = match$1[0];
  var onExploreDemo = function (param) {
    Curry._1(setCreatingDemoBranch, (function (param) {
            return true;
          }));
    return DemoBranch.create(user, hasCreatedDemoBranch, sources, openBranches, schemaBundle, sendActions, (function (demoBranch) {
                  Curry._1(setStartTour, "demo-branch-product-tour");
                  Router.Schema.pushSchemaRoute({
                        NAME: "branch",
                        VAL: NamedBranch.getId(demoBranch)
                      }, undefined, "events");
                  Curry._1(setCreatingDemoBranch, (function (param) {
                          return false;
                        }));
                  AnalyticsRe.demoBranchCreated(AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, demoBranch), Belt_List.length(events), NamedBranch.getId(demoBranch), schemaBundle.schemaId);
                  
                }), (function (error) {
                  Curry._1(setCreatingDemoBranch, (function (param) {
                          return false;
                        }));
                  console.warn("Error creating demo branch", error);
                  Sentry.captureException(error, {
                        message: "Error creating demo branch"
                      });
                  return Curry._1(addToast, {
                              message: "Could not create demo branch",
                              toastType: /* Error */1
                            });
                }), Belt_List.some(modelMigrations, (function (migration) {
                      return migration === "EventSpecificAllowedPropertyValues";
                    })));
  };
  return React.createElement(GettingStarted__Steps$Step, {
              completed: completed,
              children: null,
              docs: [{
                  href: "https://www.avo.app/docs/workflow/overview",
                  label: "The Avo workflow"
                }],
              stepId: "completeDemoBranchTour"
            }, React.createElement($$Text.make, {
                  size: "Medium",
                  color: Styles.Color.light11,
                  children: "Collaborate in real-time on defining and updating your Tracking Plan. Make changes on a branch for an easy overview and automatic consistency audits."
                }), React.createElement(Spacer.make, {
                  height: 24
                }), React.createElement($$Text.make, {
                  size: "Medium",
                  color: Styles.Color.light11,
                  children: "Design quality data faster than ever, with real-time feedback based on your standards. Learn how to leverage branched workflows, define events in Avo and share event specs for implementation."
                }), React.createElement(DoptMigration.Hidden.make, {
                  children: React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                            height: 24
                          }), React.createElement($$Text.make, {
                            size: "Medium",
                            color: Styles.Color.light11,
                            children: "Complete our Demo branch tour and learn all about the Avo workflow."
                          }))
                }), React.createElement(Spacer.make, {
                  height: 24
                }), React.createElement(GettingStarted__Steps$Callout, {
                  icon: "💡",
                  children: React.createElement($$Text.make, {
                        size: "Small",
                        color: Styles.Color.deepBlue,
                        children: "We recommend keeping your first “full-circle” release in Avo small scale. For example, a product release that includes a handful of new or updated events. This will enable you to quickly try out your first tracking update in Avo, from start to finish."
                      })
                }), React.createElement(Spacer.make, {
                  height: 24
                }), completed ? React.createElement(React.Fragment, undefined, React.createElement(Title.make, {
                        children: "Tour completed 🎉",
                        size: "Small"
                      }), React.createElement(Spacer.make, {
                        height: 8
                      }), React.createElement($$Text.make, {
                        size: "Medium",
                        color: Styles.Color.light10,
                        children: "Now that you have completed the tour you are ready to start your journey towards better data quality!"
                      })) : (
                (hasCreatedDemoBranch || currentBranchIsDemoBranch) && !creatingDemoBranch ? React.createElement(Button.make, {
                        label: "Take tour",
                        onClick: (function (param) {
                            Curry._1(setStartTour, "demo-branch-product-tour");
                            if (!currentBranchIsDemoBranch) {
                              return Router.Schema.swapBranches(undefined, {
                                          NAME: "branch",
                                          VAL: "demo-" + user.id
                                        });
                            }
                            
                          }),
                        size: "large"
                      }) : React.createElement(Button.make, {
                        disabled: hasCreatedDemoBranch,
                        label: "Create Demo branch",
                        loading: creatingDemoBranch,
                        onClick: onExploreDemo,
                        size: "large"
                      })
              ));
}

var BranchedWorkflows = {
  make: GettingStarted__Steps$BranchedWorkflows
};

export {
  LinkButtonWithIcon ,
  Video ,
  Callout ,
  Step ,
  SetUpInspector ,
  ImportEventsFromInspector ,
  AddYourFirstEvent ,
  SetUpSlackAlerts ,
  TriageIssue ,
  BranchedWorkflows ,
  
}
/* container Not a pure module */
